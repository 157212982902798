import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Filter } from '../../workbook';
import { GlobalFiltersStore } from '../stores';
import { FilterCollectionServiceBase } from './filter-collection-base.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalActiveFiltersService extends FilterCollectionServiceBase<Filter> {
  constructor(store: GlobalFiltersStore) {
    super(store);
  }

  toggleFilter(dashboardId: string, filter: Filter, contextId?: string): void {
    super.toggleValue(dashboardId, filter, contextId);
  }

  addFilter(dashboardId: string, filter: Filter, contextId?: string): void {
    super.addValue(dashboardId, filter, contextId);
  }

  removeFilter(dashboardId: string, filter: Filter, contextId?: string): void {
    super.removeValue(dashboardId, filter, contextId);
  }

  setFilters(dashboardId: string, filters: Filter[], contextId?: string): void {
    super.setFields(dashboardId, filters, contextId);
  }

  getFilters(dashboardId: string, contextId?: string): Observable<Filter[]> {
    return super.getFields(dashboardId, contextId);
  }

  resetAllFilters(dashboardId: string, contextId?: string): void {
    super.resetAllFields(dashboardId, contextId);
  }
}
