import { Injectable } from '@angular/core';

import {
  Dashboard,
  Datasource,
  DatasourceField as Field,
  FieldRole,
  Filter,
  FilterWidgetConfiguration,
  TimeFilter,
  Widget,
  createFilterWidget,
} from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../../../common/constant/common.constant';
import { PopupService } from '../../../../common/service/popup.service';
import { WidgetService } from '../../../../dashboard/service/widget.service';
import { FilterUtil } from '../../../../dashboard/util/filter.util';
import { DatasourceService } from '../../../../datasource/service/datasource.service';
import { getMainDataSources } from '../../../util/get-main-datasources';

import { initializeBoardFilters } from './initialize-board-filters';

@Injectable()
export class DashboardLayoutFilterService {
  constructor(
    private readonly popupService: PopupService,
    private readonly datasourceService: DatasourceService,
    private readonly widgetService: WidgetService,
  ) {}

  initFilters(boardInfo: Dashboard) {
    return new Promise<void>((resolve, reject) => {
      this.initializeFilter(boardInfo).then((boardData) => {
        boardInfo = boardData;
        let promises = [];
        if (boardInfo.configuration.filters) {
          const filterRemovePromises = this.removeCurrentTimeTimestampFilter(boardInfo);
          const filterAdjustErrorPromises = this.adjustFilterWidgetInformationError(boardInfo);
          promises = [...promises, ...filterRemovePromises, ...filterAdjustErrorPromises];
        }
        return Promise.all(promises)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    });
  }

  initializeFilter(boardInfo: Dashboard): Promise<Dashboard> {
    return new Promise<Dashboard>((res1, rej1) => {
      const promises = [];
      boardInfo = initializeBoardFilters(boardInfo);

      const boardFilters: Filter[] = boardInfo.configuration.filters;
      const dsList: Datasource[] = getMainDataSources(boardInfo);

      dsList.forEach((dsInfo: Datasource) => {
        promises.push(
          new Promise<void>((res2, rej2) => {
            const dsFilters: Filter[] = boardFilters.filter((filter) => filter.dataSource === dsInfo.engineName);

            const firstFilter: Filter = dsFilters.find((filter) => {
              return 'recommended' === filter.ui.importanceType && 1 === filter.ui.filteringSeq;
            });

            if (
              firstFilter &&
              ((firstFilter['valueList'] && 0 === firstFilter['valueList'].length) ||
                (firstFilter['intervals'] && 0 === firstFilter['intervals'].length))
            ) {
              const prevFilters: Filter[] = dsFilters.filter(
                (item) => item.ui.filteringSeq < firstFilter.ui.filteringSeq,
              );
              this.setRecommandedFilter(firstFilter, prevFilters, dsFilters, boardInfo)
                .then(() => {
                  res2();
                })
                .catch(() => rej2());
            } else {
              res2();
            }
          }),
        );
      });

      Promise.all(promises)
        .then(() => {
          res1(boardInfo);
        })
        .catch((error) => {
          rej1(error);
        });
    });
  }

  private removeCurrentTimeTimestampFilter(boardInfo: Dashboard): Promise<void>[] {
    const promises: Promise<void>[] = [];
    boardInfo.configuration.filters = boardInfo.configuration.filters.filter((filter: Filter) => {
      if (FilterUtil.isTimeFilter(filter) && (<TimeFilter>filter).clzField) {
        const filterField: Field = (<TimeFilter>filter).clzField;
        if (FieldRole.TIMESTAMP === filterField.role && CommonConstant.COL_NAME_CURRENT_DATETIME === filterField.name) {
          const filterId: string = filter.dataSource + '_' + filter.field;
          const filterWidgets: Widget[] = boardInfo.widgets.filter((widget) => {
            if ('filter' === widget.type) {
              const filterInWidget: Filter = (<FilterWidgetConfiguration>widget.configuration).filter;
              return filterInWidget.dataSource + '_' + filterInWidget.field === filterId;
            }
          });

          filterWidgets.forEach((item) => {
            promises.push(
              new Promise<void>((res) => {
                this.widgetService.deleteWidget(item.id).then(() => {
                  boardInfo.widgets = boardInfo.widgets.filter((widgetItem) => widgetItem.id !== item.id);
                  res();
                });
              }),
            );
          });

          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
    return promises;
  }

  private adjustFilterWidgetInformationError(boardInfo: Dashboard): Promise<void>[] {
    const promises: Promise<void>[] = [];
    boardInfo.configuration.filters.forEach((filter: Filter) => {
      const filterId: string = filter.dataSource + '_' + filter.field;
      const filterWidgets: Widget[] = boardInfo.widgets.filter((widget) => {
        if ('filter' === widget.type) {
          const filterInWidget: Filter = (<FilterWidgetConfiguration>widget.configuration).filter;
          return filterInWidget.dataSource + '_' + filterInWidget.field === filterId;
        }
      });

      if (0 === filterWidgets.length) {
        promises.push(
          new Promise<void>((res) => {
            this.widgetService.createWidget(createFilterWidget(filter, boardInfo.id), boardInfo.id).then((result) => {
              boardInfo.widgets.push(result as any);
              res();
            });
          }),
        );
      } else {
        filterWidgets.forEach((item, index) => {
          if (0 < index) {
            promises.push(
              new Promise<void>((res) => {
                this.widgetService.deleteWidget(item.id).then(() => {
                  boardInfo.widgets = boardInfo.widgets.filter((widgetItem) => widgetItem.id !== item.id);
                  res();
                });
              }),
            );
          }
        });
      }
    });
    return promises;
  }

  private setRecommandedFilter(
    targetFilter: Filter,
    prevFilters: Filter[],
    dsFilters: Filter[],
    dashboard: Dashboard,
  ): Promise<any> {
    return this.datasourceService.getCandidateForFilter(targetFilter, dashboard, prevFilters).then((result) => {
      if (targetFilter.type === 'include') {
        if (result && result.length > 0) {
          if (result[0].field) targetFilter['valueList'] = [result[0].field];
          else targetFilter['valueList'] = [result[0][targetFilter.field]];
        } else {
          targetFilter['valueList'] = [];
        }
      } else {
        targetFilter['intervals'] = [result['minTime'] + '/' + result['maxTime']];
      }

      this.popupService.notiFilter({
        name: 'change-recommended-filter-value',
        data: targetFilter,
      });

      prevFilters.push(targetFilter);

      const nextFilter: Filter = dsFilters.find((item) => item.ui.filteringSeq === targetFilter.ui.filteringSeq + 1);
      if (nextFilter) {
        return this.setRecommandedFilter(nextFilter, prevFilters, dsFilters, dashboard);
      } else {
        return result;
      }
    });
  }
}
