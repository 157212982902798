export interface Stats {
  count?: number;

  cardinality?: number;

  outliers?: number;

  missing?: number;

  min?: string;

  median?: string;

  max?: string;

  iqr?: any[];

  mean?: string;

  stddev?: string;

  skewness?: string;

  type?: string;

  typeDetail?: any[];

  variance?: string;
  zeros?: string;

  cdf?: any[];

  pmf?: any[];
  outlierThreshold?: any[];
  quantiles?: any[];

  frequentItems?: any[];

  segments?: Segments[];
}

export function createStats(statsOptions: Partial<Stats> = {}): Stats {
  return {
    ...statsOptions,
  };
}

export interface Segments {
  interval: string;

  lastAccessTime: string;

  rows: number;
  ingestedRows: number;
  serializedSize: number;
}
