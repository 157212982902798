import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { AggregatorRoute, JsonPathFormGroup, StepParallelFormGroup } from '@selfai-platform/pipeline-common';
import { orderIndentDown, orderIndentUp } from '@selfai-platform/shared';
import { map, Observable, of } from 'rxjs';
import { JSON_PATH_INIT_FORM_VALUE } from '../../../const';
import { AggregatorActiveItemService } from '../../../services/aggregator-active-item.service';
import { AggregatorRouteListService } from '../../../services/aggregator-camel-list.service';

type Item = Pick<AggregatorRoute, 'name' | 'uuid'>;

@Component({
  selector: 'selfai-platform-step-parallel',
  templateUrl: './step-parallel.component.html',
  styleUrls: ['./step-parallel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepParallelComponent implements OnInit {
  @Input()
  form!: FormGroup<StepParallelFormGroup> | UntypedFormGroup;

  toItems$: Observable<Item[]> = of([]);

  get partitionKeyFormArray(): FormArray<FormGroup<JsonPathFormGroup>> {
    return this.form.controls.partitionKey as FormArray<FormGroup<JsonPathFormGroup>>;
  }

  get partitionKeyControls(): FormGroup<JsonPathFormGroup>[] | undefined {
    return this.partitionKeyFormArray?.controls;
  }

  constructor(
    private readonly aggregatorCamelListService: AggregatorRouteListService,
    private readonly aggregatorActiveItemService: AggregatorActiveItemService,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.toItems$ = this.aggregatorCamelListService
      .getRoutes()
      .pipe(
        map((aggregatorRoutes) =>
          aggregatorRoutes
            .filter(({ uuid }) => uuid !== this.aggregatorActiveItemService.getActiveItemId())
            .map(({ name, uuid }) => ({ name, uuid })),
        ),
      );
  }

  addPartitionKeyControl(): void {
    this.partitionKeyFormArray.push(this.fb.group(JSON_PATH_INIT_FORM_VALUE) as FormGroup<JsonPathFormGroup>);
  }

  removePartitionKeyControl(index: number): void {
    this.partitionKeyFormArray.removeAt(index);
  }

  orderUp(index: number): void {
    orderIndentUp(this.partitionKeyFormArray, index);
  }

  orderDown(index: number): void {
    orderIndentDown(this.partitionKeyFormArray, index);
  }
}
