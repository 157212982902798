import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WorkflowSettingsPresetsBinding } from '../interface/workflow-settings-presets-binding.interface';
import { WorkflowSettingsPresetsBindingService } from './workflow-settings-presets-binding.service';
import { KE_WORKFLOW_SETTINGS_PRESETS_BINDING_ENTITY_NAME } from '@selfai-platform/store';

@Injectable({ providedIn: 'root' })
export class WorkflowSettingsPresetsBindingStore extends EntityCollectionServiceBase<WorkflowSettingsPresetsBinding> {
  constructor(
    public entityDataService: EntityDataService,
    public workflowSettingsPresetsBindingService: WorkflowSettingsPresetsBindingService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(
      KE_WORKFLOW_SETTINGS_PRESETS_BINDING_ENTITY_NAME,
      workflowSettingsPresetsBindingService,
    );
    super(KE_WORKFLOW_SETTINGS_PRESETS_BINDING_ENTITY_NAME, serviceElementsFactory);
  }
}
