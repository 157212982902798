import { InjectionToken } from '@angular/core';
import { ChartType } from '../enums';

export type AvaliableUiCharts =
  | ChartType.BAR
  | ChartType.GRID
  | ChartType.LINE
  | ChartType.SCATTER
  | ChartType.HEATMAP
  | ChartType.PIE
  | ChartType.LABEL
  | ChartType.BOXPLOT
  | ChartType.WATERFALL
  | ChartType.WORDCLOUD
  | ChartType.COMBINE
  | ChartType.TREEMAP
  | ChartType.RADAR
  | ChartType.GRAPH
  | ChartType.GRAPHV2
  | ChartType.GAUGE
  | ChartType.SANKEY;

export const CHART_TYPE_LIST = new InjectionToken<AvaliableUiCharts[]>('KdAvaliableChartTypes', {
  providedIn: 'root',
  factory: () => [
    ChartType.BAR,
    ChartType.GRID,
    ChartType.LINE,
    ChartType.SCATTER,
    ChartType.HEATMAP,
    ChartType.PIE,
    ChartType.LABEL,
    ChartType.BOXPLOT,
    ChartType.WATERFALL,
    ChartType.WORDCLOUD,
    ChartType.COMBINE,
    ChartType.TREEMAP,
    ChartType.RADAR,
    ChartType.GRAPH,
    ChartType.GRAPHV2,
    ChartType.GAUGE,
    ChartType.SANKEY,
  ],
});
