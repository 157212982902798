import { Component, ElementRef, Injector, OnInit, OnDestroy, Input } from '@angular/core';

import { BoardDataSourceRelation } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';

@Component({
  selector: 'create-board-ds-relation',
  templateUrl: './create-board-ds-relation.component.html',
})
export class CreateBoardDsRelationComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input()
  public relation: BoardDataSourceRelation;

  constructor(protected elementRef: ElementRef, protected injector: Injector, protected broadCaster: EventBroadcaster) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_SELECT_REL').subscribe((data: { relation: BoardDataSourceRelation }) => {
        this.relation = data.relation;
      }),
    );

    this.subscriptions.push(
      this.broadCaster.on('CREATE_BOARD_UPDATE_REL').subscribe((data: { relation: BoardDataSourceRelation }) => {
        this.relation = data.relation;
      }),
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public editRelation() {
    this.broadCaster.broadcast('CREATE_BOARD_EDIT_REL', { relationId: this.relation.id });
  }

  public deleteRelation() {
    this.broadCaster.broadcast('CREATE_BOARD_REMOVE_REL', { relationId: this.relation.id });
  }
}
