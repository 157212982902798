import { EntityMetadataMap } from '@ngrx/data';
import { WIDGET_ENTITY_NAME } from '../constants';

const entityMetadata: EntityMetadataMap = {
  [WIDGET_ENTITY_NAME]: {},
};

export const biDomainWidgetEntityConfig = {
  entityMetadata,
};
