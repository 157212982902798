<p-toast></p-toast>
<p-card>
  <form class="pt-4" [formGroup]="form">
    <div class="p-float-label mb-5">
      <textarea rows="1" class="w-full" pInputTextarea [autoResize]="true" formControlName="params"></textarea>
      <label>Additional Application Parameters</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText formControlName="name" />
      <label>Preset Name</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText formControlName="userIP" />
      <label>Driver Hostname</label>
    </div>
    <div class="mb-5 flex flex-row flex-nowrap gap-5 align-items-baseline">
      <div>
        <p-floatLabel> 
          <p-dropdown 
            optionLabel="name" 
            optionValue="value" 
            [options]="clusterType" 
            formControlName="clusterType"
            inputId="float-label-cluster">
          </p-dropdown>
          <label for="float-label-cluster">Cluster Type</label>
        </p-floatLabel>  
      </div>
      <div>
        <p-checkbox 
          formControlName="isDefault" 
          value="isDefault" 
          inputId="isDefault" 
          [binary]="true"
          />
        <label class="ml-3" for="isDefault">Default</label>
      </div>
      <div>
        <p-checkbox 
          formControlName="isEditable" 
          value="isEditable" 
          inputId="isEditable"
          [binary]="true"
          />
        <label class="ml-3" for="isEditable">Editable</label>
      </div>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText formControlName="uri" />
      <label>Master URI</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText formControlName="driverMemory" />
      <label>Driver Memory</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText formControlName="executorMemory" />
      <label>Executor Memory</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText type="number" formControlName="executorCores" />
      <label>Executor Cores</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText type="number" formControlName="numExecutors" />
      <label>Number of Executors</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText type="number" formControlName="totalExecutorCores" />
      <label>Total Executor Cores</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText formControlName="hadoopUser" />
      <label>Hadoop User</label>
    </div>
  </form>
</p-card>

<p-toolbar aria-label="Actions">
  <ng-template pTemplate="start">
    <p-button label="Cancel" [outlined]="true" severity="secondary" (click)="onBackEvent()"></p-button>
    <p-button label="Save" (click)="onSubmit()"></p-button>
  </ng-template>
</p-toolbar>
