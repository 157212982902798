import {
  ChartType,
  UIMapOption,
  DatasourceField as Field,
  PageWidgetConfiguration,
  Widget,
} from '@selfai-platform/bi-domain';

import { PageComponent } from '../../page/page.component';

export function syncDatasourceAliasInWidgetPivot(widget: Widget, fields: Field[]) {
  const widgetConfig = <PageWidgetConfiguration>widget.configuration;

  const mapFl = ChartType.MAP === widgetConfig.chart.type;

  if (fields) {
    fields
      .filter((field) => field.nameAlias)
      .forEach((field: Field) => {
        if (mapFl) {
          PageComponent.updateShelfAliasFromField(
            widgetConfig.shelf,
            field,
            (<UIMapOption>widgetConfig.chart).layerNum,
          );
        } else {
          PageComponent.updatePivotAliasFromField(widgetConfig.pivot, field);
        }
      });
  }
  return mapFl ? widgetConfig.shelf : widgetConfig.pivot;
}
