import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { UserProfile } from './user-profile';
import { ProviderUserProfile, SelfaiAuthService } from '../auth';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BehaviorSubject<UserProfile | null> {
  constructor(private readonly selfaiAuthService: SelfaiAuthService) {
    super(null);
    this.setUserProfile();
  }

  getUserProfile(): Observable<UserProfile> {
    return this.asObservable().pipe(filter(Boolean));
  }

  private async setUserProfile() {
    let profile;
    try {
      profile = (await this.selfaiAuthService.getProvider().loadUserProfile()) as ProviderUserProfile;
    } catch (err) {
      console.error('Cannot load user profile', err);
    }

    this.next(new UserProfile(profile));
  }
}
