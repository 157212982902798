import { Injectable } from "@angular/core";
import { BackendInfoApiService } from "@selfai-platform/bi-api";
import { Observable, map } from "rxjs";
import { BackendInfoModel } from "../model";

@Injectable({
    providedIn: 'root',
})
export class BackendInfoService {
    
    constructor(private readonly backendInfoApiService: BackendInfoApiService) {}

    getVersion(): Observable<BackendInfoModel> {
        return this.backendInfoApiService.getVersion().pipe(
            map((backendInfoApiModel) => backendInfoApiModel.build),
        );
    }
}