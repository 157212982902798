import { Injectable } from '@angular/core';
import { DatasourceApiService } from '@selfai-platform/bi-api';
import { SearchQueryResponse } from '@selfai-platform/bi-domain';
import { getIdContextKey } from '@selfai-platform/shared';
import { Observable, map, switchMap, take, tap } from 'rxjs';
import { Column, WidgetData, WidgetNode } from '../models';
import { WidgetDataStore } from '../stores';
import { WidgetQueryBuilderService } from './widget-query-builder.service';

// TODO: this typings just for development I don't want to have a conflict with other developers

export interface ChartData extends Omit<SearchQueryResponse, 'columns'> {
  columns?: ChartDataColumn[];
  rows?: string[];
  nodes?: WidgetNode[];
  links?: ApiChartLink[];
  aggregations?: unknown[];
  categories?: { value: number[]; percentage: number[] }[];
  totalFeatures?: number;
}

export interface ChartDataColumn extends ApiChartDataColumn {
  categoryName?: string[];
  categoryValue?: number[];
  categoryPercent?: number[];
  seriesName?: string | string[];
  seriesValue?: number[];
  seriesPercent?: number[];
}

interface ApiChartNode {
  fields: string[];
  name: string;
  originalName?: string;
  value: string | number;
}

interface ApiChartLink {
  originalSource: string;
  originalTarget: string;
  source: string;
  sourceField: string;
  target: string;
  targetField: string;
  value: string | number;
}

export interface ApiChartData {
  columns?: ApiChartDataColumn[];
  rows?: string[];
  categories?: { value: number[]; percentage: number[] }[];
  nodes?: ApiChartNode[];
  links?: ApiChartLink[];
}

export interface ApiChartDataColumn {
  value?: (number | 'null')[];
  percentage?: number[];
  name?: string;
  rows?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ChartDataService {
  constructor(
    protected readonly store: WidgetDataStore,
    private readonly widgetQueryBuilderService: WidgetQueryBuilderService,
    private readonly datasourceApiService: DatasourceApiService,
  ) {}

  mapApiData(apiWidgetData: ApiChartData): ChartData {
    const addAllValues = (columns: ApiChartDataColumn[], type: keyof Column): number[] => {
      const list: number[] = Array(apiWidgetData.columns[0][type]?.length || 0).fill(0);
      columns.forEach((item) => {
        if (!item[type]) return;
        item[type].forEach((value, index) => {
          list[index] += value;
        });
      });

      return list;
    };

    return {
      ...apiWidgetData,
      columns: apiWidgetData.columns?.map((data, index) => {
        const newData: ChartDataColumn = { ...data, value: data.value.map((val) => (val === 'null' ? null : val)) };

        newData.categoryName = apiWidgetData.rows;

        newData.categoryValue = [];
        newData.categoryPercent = [];

        if (!apiWidgetData.categories || (apiWidgetData.categories && apiWidgetData.categories.length == 0)) {
          newData.categoryValue = addAllValues(apiWidgetData.columns, 'value');
          newData.categoryPercent = addAllValues(apiWidgetData.columns, 'percentage');
          newData.seriesName = apiWidgetData.rows;
        } else {
          if (apiWidgetData.categories) {
            for (const category of apiWidgetData.categories) {
              newData.categoryValue = [category.value[index]];
              newData.categoryPercent = [category.percentage[index]];
            }
          }
          newData.seriesName = data.name?.split('CHART_STRING_DELIMITER')[0];
        }

        newData.seriesValue = newData.value as number[];
        newData.seriesPercent = [...(data.percentage || [])];

        return newData;
      }),
    } as ChartData;
  }

  // TODO: rename to noDataAvailable
  hasNoData(data: ChartData): boolean {
    return (
      (!(data instanceof Array) && data?.columns?.length === 0 && data?.rows?.length === 0) ||
      (!(data instanceof Array) && data?.nodes?.length === 0 && data?.links?.length === 0) ||
      (data instanceof Array && data.length === 0)
    );
  }

  getWidgetData(widgetId: string, contextId?: string): Observable<ChartData | undefined> {
    return this.store.entityMap$.pipe(
      map((entityMap) => {
        return entityMap[getIdContextKey(widgetId, contextId)]?.widgetData;
      }),
    );
  }

  setWidgetData(widgetId: string, widgetData: ChartData, contextId?: string): void {
    this.store.upsertOneInCache({
      widgetId,
      contextId,
      widgetData,
    });
  }

  loadWidgetData({ widgetId, contextId }: { widgetId: string; contextId?: string }): Observable<WidgetData> {
    return this.widgetQueryBuilderService.getQuery({ widgetId, contextId }).pipe(
      take(1),
      switchMap((query) => this.datasourceApiService.getQuerySearch(query)),
      tap((data) => {
        this.setWidgetData(widgetId, this.mapApiData(data), contextId);
      }),
    );
  }
}
