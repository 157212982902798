import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphChartComponent } from './graph-chart.component';

@NgModule({
  imports: [CommonModule],
  declarations: [GraphChartComponent],
  exports: [GraphChartComponent],
})
export class GraphChartComponentModule {}
