import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { DrawChartService, reducers } from './chart';
import { ChartsComponentModule } from './chart/components/charts/charts-component.module';

@NgModule({
  imports: [StoreModule.forFeature('bi-chart-engine', reducers), ChartsComponentModule],
  exports: [ChartsComponentModule],
  providers: [DrawChartService],
})
export class BiChartEngineModule {}
