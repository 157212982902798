<div class="ddp-type-dropdown" [ngClass]="{ 'ddp-selected': isShowSelect }">
  <a href="javascript:" class="ddp-data-dropdown" (click)="isShowSelect = !isShowSelect">
    {{ isStringArray ? selectedItem : selectedItem[viewKey] }}
  </a>

  <div class="ddp-wrap-popup2">
    <ul class="ddp-list-popup">
      <li
        *ngFor="let item of array"
        [ngClass]="{
          'ddp-selected':
            (isStringArray ? item : item[viewKey]) === (isStringArray ? selectedItem : selectedItem[viewKey])
        }"
      >
        <a href="javascript:" (click)="selected(item)">
          {{ isStringArray ? item : item[viewKey] }}
          <em class="ddp-icon-check"></em>
        </a>
      </li>
    </ul>
  </div>
</div>
