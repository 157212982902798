import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { RxjsOfPipeModule } from '../../pipes/rxjs-of/rxjs-of.module';
import { FavoriteCubeListStorageService } from '../../services/favorite-cubes/favorite-cube-list-storage.service';
import { FavoriteCubeListAdapter } from '../../services/favorite-cubes/favorite-cube-list.adapter';
import { FavoriteCubeListService } from '../../services/favorite-cubes/favorite-cube-list.service';
import { CubeDndPlaceholderComponentModule } from '../cube-dnd-placeholder/cube-dnd-placeholder.module';
import { FavoriteCubeListComponent } from './favorite-cube-list.component';

@NgModule({
  imports: [
    CommonModule,
    SidebarModule,
    DragDropModule,
    ButtonModule,
    CubeDndPlaceholderComponentModule,
    RxjsOfPipeModule,
  ],
  declarations: [FavoriteCubeListComponent],
  exports: [FavoriteCubeListComponent],
  providers: [{ provide: FavoriteCubeListAdapter, useClass: FavoriteCubeListStorageService }, FavoriteCubeListService],
})
export class FavoriteCubeListComponentModule {}
