import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { merge } from 'lodash';
import { BehaviorSubject, Observable, firstValueFrom, tap } from 'rxjs';
import { CONFIG_PATH } from '../tokens';
import { Config } from './config.model';

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

@Injectable({ providedIn: 'root' })
export class ConfigService {
  configLoaded$ = new BehaviorSubject<boolean>(false);

  private config!: Config;

  constructor(@Inject(CONFIG_PATH) private readonly configPath: string, private http: HttpClient) {}

  init(): Promise<Config> {
    return firstValueFrom(
      this.load().pipe(
        tap((config: Config) => {
          this.config = config;
          this.configLoaded$.next(true);
        }),
      ),
    );
  }

  load(): Observable<Config> {
    const currentDate = new Date();

    return this.http.get<Config>(`${this.configPath}?ts=${currentDate.getTime()}`);
  }

  getConfig(): Config {
    return this.config;
  }

  updateConfig(config: DeepPartial<Config>): void {
    this.config = merge(this.config, config);
  }
}
