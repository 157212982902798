export enum ImplementorType {
  H2 = 'H2',
  MYSQL = 'MYSQL',
  ORACLE = 'ORACLE',
  TIBERO = 'TIBERO',
  HIVE = 'HIVE',
  HAWQ = 'HAWQ',
  POSTGRESQL = 'POSTGRESQL',
  MSSQL = 'MSSQL',
  PRESTO = 'PRESTO',
  PHOENIX = 'PHOENIX',
  NVACCEL = 'NVACCEL',
  STAGE = 'STAGE',
  DRUID = 'DRUID',
  FILE = 'FILE',
  NONE = 'NONE',
  ALL = 'ALL',
}
