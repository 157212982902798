<h5>Additional parameters</h5>
<div class="p-fluid grid">
  <div class="field-radiobutton field col-12 md:col-2">
    <p-radioButton [formControl]="form.controls.type" value="none" inputId="additional1"></p-radioButton>
    <label for="additional1">None</label>
  </div>
  <div class="field-radiobutton field col-12 md:col-2">
    <p-radioButton [formControl]="form.controls.type" value="text" inputId="additional2"></p-radioButton>
    <label for="additional2">Text</label>
  </div>
  <div class="field-radiobutton field col-12 md:col-2">
    <p-radioButton [formControl]="form.controls.type" value="key-value" inputId="additional3"></p-radioButton>
    <label for="additional3">Key-value</label>
  </div>
</div>

<form [formGroup]="form">
  <ng-container *ngIf="form.controls.type.value === 'text'">
    <textarea [rows]="5" pInputTextarea [autoResize]="true" [formControl]="form.controls.propertiesString"></textarea>
  </ng-container>
  <ng-container *ngIf="form.controls.type.value === 'key-value'">
    <form formArrayName="propertiesArray">
      <div
        *ngFor="let propertyFormGroup of additions.controls; let i = index"
        [formGroup]="propertyFormGroup"
        class="p-fluid grid"
      >
        <div class="p-fluid grid field col-12 md:col-11 mar-0">
          <div class="field col-12 md:col-4">
            @if(additionalList.length > 0) {
            <p-autoComplete
              [formControl]="propertyFormGroup.controls.key"
              [suggestions]="filteredAdditionalList"
              [dropdown]="true"
              (completeMethod)="additionalSearch($event)"
              (onSelect)="selectedParam($event.value, i)"
            ></p-autoComplete>
            } @else {
            <input type="text" pInputText [formControl]="propertyFormGroup.controls.key" />
            }
          </div>
          <div class="field col-12 md:col-4">
            <p-dropdown #type [formControl]="propertyFormGroup.controls.type" [options]="keyTypeOptions"></p-dropdown>
          </div>
          <ng-container [ngSwitch]="type.value">
            <div class="field col-12 md:col-4" *ngSwitchCase="'string'">
              <input type="text" pInputText [formControl]="propertyFormGroup.controls.value" />
            </div>
            <div class="field col-12 md:col-4" *ngSwitchCase="'numeric'">
              <input type="text" mode="decimal" pInputText [formControl]="propertyFormGroup.controls.value" />
            </div>
            <div class="field col-12 md:col-4 switchButtonBlock flex" *ngSwitchCase="'boolean'">
              <p-inputSwitch #bool [formControl]="propertyFormGroup.controls.value"></p-inputSwitch>
              <span>{{ bool.checked() }}</span>
            </div>
            <div class="field col-12 md:col-4" *ngSwitchDefault>
              <input type="text" pInputText [formControl]="propertyFormGroup.controls.value" />
            </div>
          </ng-container>
        </div>
        <div class="field col-12 md:col-1 mar-0">
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            (click)="deleteParamsItem(i)"
          ></button>
        </div>
      </div>
    </form>
    <button pButton (click)="addParamsItem()" type="button">Add</button>
  </ng-container>
</form>
