import { cloneDeep, eq, omit } from 'lodash';

import { BoardDataSource, ChartType, Filter, MapLayerType, PageWidgetConfiguration } from '@selfai-platform/bi-domain';

import { convertToServerSpecForDashboard } from './convert-to-server-spec-for-dashboard';

//TODO create special datatype for result of this function and rewrite this function
export function convertPageWidgetSpecToServer(sourceConfiguration: PageWidgetConfiguration) {

  const configuration = cloneDeep(sourceConfiguration);

  const chart = omit(configuration.chart, [
    'fieldList',
    'fielDimensionList',
    'fieldMeasureList',
    'fieldDimensionDataList',
    'maxValue',
    'minValue',
    'stackedMinValue',
    'stackedMaxvalue',
  ]);

  if (chart.dataLabel) {
    chart.dataLabel = omit(chart.dataLabel, ['previewList']);
  }

  if (eq(chart.type, ChartType.MAP)) {
    (<any>chart).layers.forEach((layer) => {
      if (layer.clustering && layer.type == MapLayerType.CLUSTER) {
        layer.type = MapLayerType.SYMBOL;
      }
    });
  }
  

  configuration.dataSource = omit(configuration.dataSource, ['fields']) as Omit<BoardDataSource, 'fields'>;

  if (configuration.customFields) {
    if(!Array.isArray(configuration.customFields)) {
      const fieldsArray = [];
      for (const key in configuration.customFields as any) {
        (configuration.customFields as any)[key] = fieldsArray.push(configuration.customFields[key]);
      }
      configuration.customFields = fieldsArray;
    }
    configuration.fields = cloneDeep(configuration.customFields) as any[];
    delete configuration.customFields;
  }

  if (configuration.filters) {
    for (let filter of configuration.filters) {
      filter = convertToServerSpecForDashboard(filter) as Filter;
    }
  }

  return configuration;
}
