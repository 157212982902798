import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { Datasource, Filter, Widget, Dashboard, PageWidget, PageWidgetConfiguration } from '@selfai-platform/bi-domain';

import { AbstractFilterPopupComponent } from '../../dashboard/filters/abstract-filter-popup.component';
import { getMainDataSources } from '../util';

import { ConfigureFiltersSelectComponent } from './configure-filters-select.component';
import { ConfigureFiltersUpdateComponent } from './configure-filters-update.component';

@Component({
  selector: 'app-config-filter',
  templateUrl: './configure-filters.component.html',
})
export class ConfigureFiltersComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(ConfigureFiltersSelectComponent, { static: true })
  private _selectFieldComp: ConfigureFiltersSelectComponent;

  @ViewChild(ConfigureFiltersUpdateComponent, { static: true })
  private _updateFilterComp: ConfigureFiltersUpdateComponent;

  private _board: Dashboard;
  private _widget: PageWidget;
  private _chartFilters: Filter[];

  @Output()
  public done: EventEmitter<Filter> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public open(board: Dashboard, chartFilters: Filter[], targetFilter?: Filter, widget?: Widget) {
    this._board = board;
    this._widget = <PageWidget>widget;
    if (targetFilter) {
      this._updateFilterComp.openForEditFilter(board, { key: 'EDIT', filter: targetFilter }, true, this._widget);
    } else {
      this._chartFilters = chartFilters;
      const dataSources: Datasource[] = getMainDataSources(this._board);
      this._selectFieldComp.open(
        board.configuration,
        dataSources,
        this._getTargetDataSource(dataSources, this._widget),
        this._chartFilters,
        this._widget,
      );
    }
  }

  public close() {
    this._updateFilterComp.close();
    this._selectFieldComp.close();
  }

  public emitUpdateFilter(filter: Filter) {
    this.done.emit(filter);
  }

  public setFilter(data: { key: string; filter: Filter }) {
    if ('ADD' === data.key) {
      this._updateFilterComp.openForAddFilter(this._board, data, this._widget);
    } else {
      this._updateFilterComp.openForEditFilter(this._board, data, false, this._widget);
    }
    this._selectFieldComp.close();
  }

  public showSelectFieldComp(selectedFilter: Filter) {
    const dataSources: Datasource[] = getMainDataSources(this._board);
    this._selectFieldComp.open(
      this._board.configuration,
      dataSources,
      dataSources.find((ds) => ds.engineName === selectedFilter.dataSource),
      this._chartFilters,
      this._widget,
    );
    this._updateFilterComp.close();
  }

  private _getTargetDataSource(dataSources: Datasource[], widget: PageWidget) {
    let targetDataSource: Datasource = dataSources[0];
    if (widget) {
      const widgetConf: PageWidgetConfiguration = widget.configuration as PageWidgetConfiguration;
      targetDataSource = dataSources.find((item) => item.id === widgetConf.dataSource.id);
    }
    return targetDataSource;
  }
}
