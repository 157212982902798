import { Injectable } from '@angular/core';
import {
  CreateDataFrameRawParameters,
  CubeComment,
  CubeEditResult,
  CubeWorkflowData,
  ExternalSqlQueryRawParameters,
  GraphNodeOptions,
  ReadDataFrameRawParameters,
  WorkflowExpressionData,
  WorkflowJsonToColumnTransformationData,
  WorkflowTriggerData,
  WriteDataFrameRawParameters,
  WriteDataFrameTransitRawParameters,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import {
  CmsFormCreatorComponent,
  CodeEditorTransformationComponent,
  CommentComponent,
  CreateTriggerComponent,
  ExpressionToolComponent,
  FavoriteCubeAddComponent,
  JsonToColumnTransformationComponent,
  ReadDataFrameComponent,
} from '../components';
import { CreateDataFrameComponent } from '../components/dialogs/create-data-frame';
import { ExternalSqlQueryComponent } from '../components/dialogs/external-sql-query';
import { WriteDataFrameComponent } from '../components/dialogs/write-data-frame';
import { WriteDataFrameTransitComponent } from '../components/dialogs/write-data-frame-transit';

@Injectable()
export class CubeDialogService {
  constructor(
    private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData | CubeComment>,
    private readonly favoriteDialogService: DialogService<never, GraphNodeOptions>,
  ) {}

  showCreateTrigger(data: CubeWorkflowData<WorkflowTriggerData>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CreateTriggerComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      maximizable: true,
    });
  }

  showReadDataFrame(data: CubeWorkflowData<ReadDataFrameRawParameters>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(ReadDataFrameComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '50%',
    });
  }

  showWriteDataFrameTransit(data: CubeWorkflowData<WriteDataFrameTransitRawParameters>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(WriteDataFrameTransitComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '50%',
    });
  }

  showWriteDataFrame(data: CubeWorkflowData<WriteDataFrameRawParameters>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(WriteDataFrameComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '50%',
    });
  }

  showCreateDataFrame(data: CubeWorkflowData<CreateDataFrameRawParameters>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CreateDataFrameComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '50%',
    });
  }

  showExternalSqlQuery(data: CubeWorkflowData<ExternalSqlQueryRawParameters>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(ExternalSqlQueryComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '50%',
    });
  }

  showJsonToColumnTransformation(
    data: CubeWorkflowData<WorkflowJsonToColumnTransformationData>,
  ): Observable<CubeEditResult> {
    return this.dialogService.showDialog(JsonToColumnTransformationComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      maximizable: true,
    });
  }

  showComment(data: CubeComment): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CommentComponent, {
      header: 'Comment',
      data,
    });
  }

  showExpressionTool(data: CubeWorkflowData<WorkflowExpressionData>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(ExpressionToolComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      maximizable: true,
    });
  }

  showCodeEditorTransformation(data: CubeWorkflowData): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CodeEditorTransformationComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      height: '90vh',
      maximizable: true,
    });
  }

  showCmsFormCreator(data: CubeWorkflowData): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CmsFormCreatorComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      height: '90vh',
      maximizable: true,
    });
  }

  showFavoriteAddForm(data: GraphNodeOptions): Observable<void> {
    return this.favoriteDialogService.showDialog(FavoriteCubeAddComponent, {
      data,
      width: '40%',
      header: 'Add to favorites',
    });
  }
}
