<div class="ddp-ui-boardside-in">
  <div class="ddp-area-boardside">
    <div class="ddp-ui-boardside-title">
      {{ 'msg.board.th.Layout' | translate }}
    </div>

    <div class="ddp-wrap-downmenu" style="padding-right: 20px">
      <div class="ddp-box-down">
        <div class="ddp-ui-option-down ddp-selected">
          <div class="ddp-ui-down-title">
            <div class="ddp-ui-down-title-in">
              <span class="ddp-txt-down-title">
                <em class="ddp-icon-menumove"></em>
                {{ 'msg.comm.ui.project' | translate }}
              </span>
            </div>
          </div>

          <div class="ddp-ui-down-contents">
            <div class="ddp-list-part">
              <div class="ddp-label-txt2">{{ 'msg.board.th.board' | translate }}</div>
              <ul class="ddp-list-checktype ddp-overflow-visible">
                <li>
                  <label class="ddp-label-radio">
                    <input type="radio" name="BoardFitType" [checked]="isFitToScreen()" (click)="setFitToScreen()" />
                    <i class="ddp-icon-radio"></i>
                    <span class="ddp-txt-radio">{{ 'msg.board.li.fit-to-screen' | translate }}</span>
                  </label>
                </li>

                <li>
                  <div class="ddp-wrap-option-multy ddp-clear">
                    <div class="ddp-col-8">
                      <span class="ddp-label-txt ddp-info">
                        <label class="ddp-label-radio">
                          <input
                            type="radio"
                            name="BoardFitType"
                            [checked]="isFitToHeight()"
                            (click)="setFitToHeight()"
                          />
                          <i class="ddp-icon-radio"></i>
                          <span class="ddp-txt-radio">{{ 'msg.board.li.fit-to-height' | translate }}</span>
                        </label>

                        <span class="ddp-txt-det ddp-det-cursor ddp-hover-tooltip">
                          <div class="ddp-ui-tooltip-info ddp-down">
                            <em class="ddp-icon-view-top"></em>
                            {{ 'msg.board.ui.fit-to-height.tooltip' | translate }}
                          </div>
                        </span>
                      </span>
                    </div>
                    <div class="ddp-col-4">
                      <div class="ddp-box-option-input">
                        <component-input
                          [value]="layoutHeight"
                          [valueType]="'number'"
                          [disabled]="isFitToScreen()"
                          (changeValue)="setLayoutHeight($event)"
                        ></component-input>
                        <span class="ddp-txt-input">{{ 'msg.board.ui.px' | translate }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div class="ddp-label-txt2">{{ 'msg.board.th.margin' | translate }}</div>
              <div class="ddp-box-area">
                <div class="ddp-box-option-input">
                  <component-input
                    [value]="layoutMargin"
                    [valueType]="'number'"
                    (changeValue)="setLayoutMargin($event)"
                  ></component-input>
                  <span class="ddp-txt-input">{{ 'msg.board.ui.px' | translate }}</span>
                </div>
              </div>
              <div *ngIf="isLiveDatasource" class="ddp-label-txt2">{{ 'msg.board.ui.sync_interval' | translate }}</div>
              <div *ngIf="isLiveDatasource" class="ddp-box-selectbox">
                <div
                  class="ddp-type-selectbox"
                  [class.ddp-selected]="isShowIntervalList"
                  (click)="isShowIntervalList = !isShowIntervalList"
                >
                  <span class="ddp-txt-selectbox">{{ selectedInterval.name }}</span>
                  <ul class="ddp-list-selectbox ddp-selectdown">
                    <li *ngFor="let interval of syncIntervals" (click)="selectSyncInterval(interval)">
                      <a href="javascript:">{{ interval.name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ddp-ui-option-down ddp-selected">
          <div class="ddp-ui-down-title">
            <div class="ddp-ui-down-title-in">
              <span class="ddp-txt-down-title">
                <em class="ddp-icon-menumove"></em>
                {{ 'msg.board.th.chart.layout' | translate }}
              </span>
            </div>
          </div>

          <div class="ddp-ui-down-contents ddp-list-sideoption">
            <div class="ddp-list-sidesub">
              <div class="ddp-txt-label">{{ 'msg.board.th.chart.title' | translate }}</div>
              <div class="ddp-checkbox-auto">
                <div
                  class="ddp-checkbox-slide ddp-slide-text"
                  [ngClass]="{ 'ddp-middle': isByWidgetFeature('showTitle') }"
                >
                  <span class="“ddp-txt-slide”">{{ getFeatureLabel('showTitle') }}</span>
                  <input
                    id="chartTitleFlag"
                    type="checkbox"
                    value="None"
                    name="check"
                    [checked]="isCheckedFeature('showTitle')"
                    (click)="featureClickHandler('showTitle')"
                  />
                  <label for="chartTitleFlag">
                    <span class="ddp-slide"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="ddp-list-sidesub">
              <div class="ddp-txt-label">{{ 'msg.board.th.legend' | translate }}</div>

              <div class="ddp-checkbox-auto">
                <div
                  class="ddp-checkbox-slide ddp-slide-text"
                  [ngClass]="{ 'ddp-middle': isByWidgetFeature('showLegend') }"
                >
                  <span class="“ddp-txt-slide”">{{ getFeatureLabel('showLegend') }}</span>
                  <input
                    id="legendFlag"
                    type="checkbox"
                    value="None"
                    name="check"
                    [checked]="isCheckedFeature('showLegend')"
                    (click)="featureClickHandler('showLegend')"
                  />
                  <label for="legendFlag">
                    <span class="ddp-slide"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="ddp-list-sidesub">
              <div class="ddp-txt-label">{{ 'msg.board.th.mini.map' | translate }}</div>

              <div class="ddp-checkbox-auto">
                <div
                  class="ddp-checkbox-slide ddp-slide-text"
                  [ngClass]="{ 'ddp-middle': isByWidgetFeature('showMinimap') }"
                >
                  <span class="“ddp-txt-slide”">{{ getFeatureLabel('showMinimap') }}</span>
                  <input
                    id="miniMapFlag"
                    type="checkbox"
                    value="None"
                    name="check"
                    [checked]="isCheckedFeature('showMinimap')"
                    (click)="featureClickHandler('showMinimap')"
                  />
                  <label for="miniMapFlag">
                    <span class="ddp-slide"></span>
                  </label>
                </div>
              </div>
            </div>

            <span class="ddp-txt-det">{{ 'msg.board.ui.layout.description' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
