import {
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  Directive,
} from '@angular/core';

import { Dashboard, Filter, ConnectionType, Datasource, DatasourceField as Field } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';
import { DashboardUtil } from '../util/dashboard.util';
import { FilterUtil } from '../util/filter.util';

@Directive()
export class AbstractFilterPanelComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input()
  public dashboard: Dashboard;

  @Input()
  public chartFilters: Filter[];

  @Input()
  public isDashboardMode = true;

  @Input()
  public isWidgetInLayout = false;

  @Input()
  public isDraggable = false;

  @Input()
  public isChangeable = true;

  @Output()
  public updateFilterEvent: EventEmitter<Filter> = new EventEmitter();

  @Output()
  public deleteFilterEvent = new EventEmitter();

  @Output()
  public openUpdateFilterPopupEvent = new EventEmitter();

  @Output()
  public changeChartToGlobalEvent = new EventEmitter();

  @Output()
  public changeGlobalToChartEvent = new EventEmitter();

  @Output()
  public resetFilterEvent = new EventEmitter();

  public field: Field;
  public dataSource: Datasource;
  public isShowDetailMenu = false;
  public isShowFilter = false;
  public isBoardFilter = true;
  public isDeletable = false;
  public isEditable = false;
  public boardFilters: Filter[];

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const boardChanges: SimpleChange = changes.dashboard;
    if (boardChanges && boardChanges.currentValue) {
      this.boardFilters = DashboardUtil.getBoardFilters(boardChanges.currentValue);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public changeGlobalToChart(filter: Filter): void {
    this.changeGlobalToChartEvent.emit(filter);
  }

  public changeChartToGlobal(filter: Filter): void {
    this.changeChartToGlobalEvent.emit(filter);
  }

  public openUpdateFilterPopup(filter: Filter) {
    this.openUpdateFilterPopupEvent.emit(filter);
  }

  public resetFilter(filter: Filter) {
    throw new Error('SubClass should implements changeLock method');
  }

  public showFilter(isShow?: boolean) {
    if (isShow != null) {
      this.isShowFilter = isShow;
    } else {
      this.isShowFilter = !this.isShowFilter;
    }
  }

  protected setPanelData(filter: Filter) {
    this.dataSource = FilterUtil.getDataSourceForFilter(filter, this.dashboard);

    if (this.dataSource) {
      this.isBoardFilter = !filter.ui.widgetId;

      this.field = DashboardUtil.getFieldByName(this.dashboard, filter.dataSource, filter.field, filter.ref);

      if ('recommended' === filter.ui.importanceType || 'timestamp' === filter.ui.importanceType) {
        this.isChangeable = false;
      }

      this.isDeletable = this.isChangeable && this.isDashboardMode === this.isBoardFilter;
      this.isEditable =
        this.isDashboardMode === this.isBoardFilter &&
        !(ConnectionType.LINK === this.dataSource.connType && 'recommended' === filter.ui.importanceType);
    } else {
      this.isDeletable = true;
    }
  }

  protected getFiltersParam(filter: Filter): Filter[] {
    if (!this.boardFilters) this.boardFilters = [];
    if (!this.chartFilters) this.chartFilters = [];

    let filters: Filter[] = [];

    if (filter.ui.widgetId) {
      filters = this.boardFilters;

      filters = filters.concat(
        this.chartFilters.filter(
          (item) =>
            item.field !== filter.field &&
            item.ui.widgetId === filter.ui.widgetId &&
            !(item.type === 'bound' && item['min'] == null),
        ),
      );
    } else if (filter.ui.importanceType === 'general') {
      filters = filters.concat(
        this.boardFilters.filter(
          (item) => item.field !== filter.field && !(item.type === 'bound' && item['min'] == null),
        ),
      );

      filters = filters.concat(this.chartFilters);
    } else if (filter.ui.importanceType === 'timestamp') {
      filters = [];
    } else {
      filters = this.boardFilters.filter((item) => {
        return item.ui.importanceType === 'timestamp' || item.ui.filteringSeq < filter.ui.filteringSeq;
      });
    }

    if (!filters) filters = [];
    return filters;
  }
}
