<selfai-platform-data-list-view
  [layoutTypes]="['table']"
  [columns]="columns"
  [groupActions]="groupActions$ | async"
  [emptyMessage]="'shell.board.filter.ui.search-all.nodata' | translate"
>
  <ng-container slot="toolbarActions">
    <p-multiSelect
      optionLabel="name"
      placeholder="Select tags"
      [(ngModel)]="selectedTags"
      [options]="tags"
      [showClear]="true"
      (onClear)="onClearTagsFilter()"
      (onChange)="onFilterByTags($event)"
    />

    <p-button
      *ngIf="canCreate"
      class="p-button-success"
      severity="success"
      type="button"
      routerLink="./create"
      icon="pi pi-plus-circle"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.create' | translate }}"
    ></p-button>

    <p-fileUpload
      #fileUpload
      mode="basic"
      chooseIcon="pi pi-upload"
      name="functions"
      accept="application/json"
      maxFileSize="1000000"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.import' | translate }}"
      [auto]="true"
      [customUpload]="true"
      (uploadHandler)="importUserFunction($event); fileUpload.clear()"
    />

    <p-button
      type="button"
      icon="pi pi-download"
      tooltipPosition="top"
      pTooltip="{{ 'user-functions-list.toolbar.actions.export' | translate }}"
      (click)="exportUserFunction()"
    ></p-button>
  </ng-container>
</selfai-platform-data-list-view>
