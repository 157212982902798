import { Injectable } from '@angular/core';
import { SelectionColumnItem } from '@selfai-platform/pipeline-common';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectionStoreService extends ReplaySubject<SelectionColumnItem[]> {
  constructor() {
    super(1);
  }

  getSelectionColumns(): Observable<SelectionColumnItem[]> {
    return this.asObservable();
  }

  setSelectionsColumns(columns: SelectionColumnItem[]): void {
    this.next(columns);
  }
}
