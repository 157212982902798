import { InclusionFilter, InclusionSelectorType } from '../../workbook';

export type AssociationFilter = Pick<InclusionFilter, 'dataSource' | 'field' | 'type' | 'valueList' | 'selector'>;

export function createAssociationFilter(dataSource: string, field: string, valueList: string[]): AssociationFilter {
  if (valueList.length === 0) {
    return {
      dataSource,
      field,
      type: 'empty',
      valueList: [],
      selector: InclusionSelectorType.MULTI_LIST,
    };
  }

  return {
    dataSource,
    field,
    type: 'include',
    valueList,
    selector: InclusionSelectorType.MULTI_LIST,
  };
}
