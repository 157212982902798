import { WorkflowSettingsPresetsStore } from '../store/workflow-settings-presets.store';
import { Observable } from 'rxjs';
import { WorkflowPresetsInterface } from '../../workflow-presets/interfaces/workflow-presets.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkflowSettingsPresetsDomainService {
  workflowSettingsPresets$: Observable<WorkflowPresetsInterface[]>;

  constructor(private workflowSettingsPresetsStore: WorkflowSettingsPresetsStore) {
    this.workflowSettingsPresets$ = workflowSettingsPresetsStore.entities$;
  }

  getItem(id: string): void {
    this.workflowSettingsPresetsStore.getByKey(id);
  }
}
