import { Datasource, BoardDataSource } from '@selfai-platform/bi-domain';

export function isSameDataSource(
  boardDs: BoardDataSource,
  dataSource: Partial<Pick<Datasource, 'id' | 'engineName' | 'name'>>,
): boolean {
  return (
    boardDs.name === dataSource.name ||
    boardDs.name === dataSource.engineName ||
    (boardDs.temporary && boardDs.id === dataSource.id)
  );
}
