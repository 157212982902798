import { DatasourceType } from '@selfai-platform/pipeline-common';

export interface DataSourceApi {
  accessLevel: 'write' | 'read';
  creationDateTime: string;
  id: string;
  ownerId: string;
  ownerName: string;
  params: DataSourceParamsType;
}
export interface DataSourceParamsBasic {
  datasourceType: DatasourceType;
  name: string;
  visibility: 'privateVisibility' | 'publicVisibility';
  properties: string;
}

export type DataSourceParamsType = Partial<DataSourceLibraryParams> &
  Partial<DataSourceS3Params> &
  Partial<DataSourceKafkaParams> &
  Partial<DataSourceHdParams> &
  Partial<DataSourceGoogleSpParams> &
  Partial<DataSourceExFilesParams> &
  Partial<DataSourceJdbcParams>;

interface DataSourceLibraryParams extends DataSourceParamsBasic {
  [DataSourceParamsName.LIBRARY]: {
    libraryPath: string;
    fileFormat: 'csv';
    csvFileFormatParams: {
      includeHeader: boolean;
      convert01ToBoolean: boolean;
      separatorType: 'comma';
      customSeparator: string;
    };
  };
}

interface DataSourceS3Params extends DataSourceParamsBasic {
  [DataSourceParamsName.S3]: {
    endpoint: string;
    accessKey: string;
    secretKey: string;
    bucket: string;
    pattern: string;
    withoutSSL: boolean;
  };
}

interface DataSourceKafkaParams extends DataSourceParamsBasic {
  [DataSourceParamsName.KAFKA]: {
    brokers: string;
    topic: string;
    sslEnabled: boolean;
  };
}

interface DataSourceHdParams extends DataSourceParamsBasic {
  [DataSourceParamsName.HDFS]: {
    hdfsPath: string;
    fileFormat: string;
    csvFileFormatParams: {
      includeHeader: boolean;
      convert01ToBoolean: boolean;
      separatorType: 'comma';
      customSeparator: string;
    };
  };
}

interface DataSourceGoogleSpParams extends DataSourceParamsBasic {
  [DataSourceParamsName.GOOGLE_SP]: {
    googleSpreadsheetId: string;
    googleServiceAccountCredentials: string;
    includeHeader: boolean;
    convert01ToBoolean: boolean;
  };
}

interface DataSourceExFilesParams extends DataSourceParamsBasic {
  [DataSourceParamsName.EX_FILE]: {
    url: string;
    fileFormat: 'csv';
    csvFileFormatParams: {
      includeHeader: boolean;
      convert01ToBoolean: boolean;
      separatorType: 'comma';
      customSeparator: string;
    };
  };
}

interface DataSourceJdbcParams extends DataSourceParamsBasic {
  [DataSourceParamsName.JDBC]: {
    driver: string;
    url: string;
    query: string;
    table: string;
  };
}

export const DataSourceParamsName = {
  JDBC: 'jdbcParams',
  EX_FILE: 'externalFileParams',
  GOOGLE_SP: 'googleSpreadsheetParams',
  HDFS: 'hdfsParams',
  KAFKA: 'kafkaParams',
  LIBRARY: 'libraryFileParams',
  S3: 's3Params',
} as const;

export type DataSourceParamsName = typeof DataSourceParamsName[keyof typeof DataSourceParamsName];
