import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { OperationParameter, OperationType } from '@selfai-platform/pipeline-common';

export function mapParametersToFormGroup(
  parameters: OperationParameter[],
  parametersSerialized: Record<string, unknown> = {},
  form = new FormGroup({}),
): UntypedFormGroup {
  Object.keys(form.controls).forEach((controlName: string) => {
    form.removeControl(controlName);
  });

  parameters.forEach((param) => {
    if (param.type === OperationType.CHOICE) {
      const choiceParametersSerialized = parametersSerialized?.[param.name] as Record<string, Record<string, unknown>>;

      const choiceValue: string = choiceParametersSerialized
        ? (Object.keys(choiceParametersSerialized)[0] as string)
        : (param.default as string);
      const fields = param.values?.find(({ name }) => name === choiceValue);

      form.setControl(
        param.name,
        new FormGroup({
          choiceValue: new FormControl(choiceValue),
          fields: mapParametersToFormGroup(
            fields?.schema as OperationParameter[],
            choiceParametersSerialized?.[choiceValue],
          ),
        }),
      );
    } else {
      form.setControl(param.name, new FormControl(parametersSerialized[param.name] || param.default));
    }
  });

  return form;
}

export function mapFormGroupValueToSerializedParameters(
  formValue: Record<string, unknown | ChoiceField>,
): Record<string, unknown> {
  return Object.fromEntries(
    Object.entries(formValue).map(([key, fieldValue]) => {
      if (isChoiceField(fieldValue)) {
        return [key, { [fieldValue.choiceValue]: mapFormGroupValueToSerializedParameters(fieldValue.fields) }];
      }

      return [key, fieldValue];
    }),
  );
}

function isChoiceField(field: unknown | ChoiceField): field is ChoiceField {
  return Boolean(field && Object.prototype.hasOwnProperty.call(field, 'choiceValue'));
}

interface ChoiceField {
  choiceValue: string;
  fields: Record<string, unknown>;
}
