<div class="flex between mb-3">
  <h5>{{ 'msg.workspace.settings.schedules.title' | translate }}</h5>
  <p-button
    class="overlay"
    icon="pi pi-plus-circle"
    [rounded]="false"
    severity="success"
    pTooltip="{{ 'msg.workspace.settings.schedules.tooltip.add-schedule' | translate }}"
    (click)="addScheduleChange()">
  </p-button>
</div>
<div class="grid mb-2">
  <div class="col-3 text-lg">{{ 'msg.workspace.settings.schedules.column.name' | translate }}</div>
  <div class="col-3 text-lg">{{ 'msg.workspace.settings.schedules.column.timezone' | translate }}</div>
  <div class="col-3 text-lg">{{ 'msg.workspace.settings.schedules.column.cron' | translate }}</div>
  <div class="col-3 text-lg"></div>
</div>
<selfai-platform-workflow-settings-schedules-form
  *ngIf="showCreateForm"
  [presets]="presets"
  [workflowId]="workflowId"
  (cancelEvent)="addScheduleChange()"
  (submitEvent)="onAddSchedule($event)"
></selfai-platform-workflow-settings-schedules-form>
<div class="scheduleList">
  <ng-container *ngFor="let schedule of schedules">
    <div class="grid" *ngIf="!showEditForms[schedule.id]">
      <div class="col-3">{{ getPresetName(schedule.executionInfo.presetId) }}</div>
      <div class="col-3">{{ schedule.schedule.timezone }}</div>
      <div class="col-3">{{ schedule.schedule.cron }}</div>
      <div class="col-3 flex justify-content-end actions">
        <button
          pButton
          type="button"
          class="p-button-text p-button-link"
          appearance="icon"
          icon="pi pi-pen-to-square"
          pTooltip="{{ 'msg.workspace.settings.schedules.button.edit' | translate }}"
          tooltipPosition="top"
          (click)="editScheduleChange(schedule.id)"
        ></button>
        <button
          pButton
          class="p-button-text p-button-link"
          icon="pi pi-trash"
          appearance="icon"
          severity="danger"
          pTooltip="{{ 'msg.workspace.settings.schedules.button.delete' | translate }}"
          tooltipPosition="top"
          (click)="onDeleteSchedule(schedule.id)"
        ></button>
      </div>
    </div>
    <selfai-platform-workflow-settings-schedules-form
      *ngIf="showEditForms[schedule.id]"
      [presets]="presets"
      [schedule]="schedule"
      [workflowId]="workflowId"
      (cancelEvent)="editScheduleChange(schedule.id)"
      (submitEvent)="onEditSchedule($event)"
    ></selfai-platform-workflow-settings-schedules-form>
  </ng-container>
</div>
