import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable, takeUntil } from 'rxjs';
import { WorkflowPresetsListComponent } from '../../components/workflow-presets-list/workflow-presets-list.component';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService } from '@selfai-platform/shell';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { getPresetsPath } from '../../functions';

@Component({
  selector: 'selfai-platform-workflow-presets-list-container',
  standalone: true,
  imports: [CommonModule, WorkflowPresetsListComponent],
  templateUrl: './workflow-presets-list-container.component.html',
  styleUrl: './workflow-presets-list-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPresetsListContainerComponent implements OnInit {
  breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translate.stream('shell.menu.presets').pipe(
    map((label) => [
      {
        label,
        routerLink: getPresetsPath(),
      },
    ]),
  );

  constructor(
    private breadcrumbsMenuService: BreadcrumbsMenuService,
    private translate: TranslateService,
    private destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.breadcrumbItems$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbItems) => {
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
  }
}
