import { Injectable } from '@angular/core';
import { TreeNode } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { JsonSchemaTreeViewComponent } from '../../json-path/components/json-schema-tree-view/json-schema-tree-view.component';

@Injectable()
export class AggregatorCamelTreeService {
  constructor(private readonly dialogService: DialogService<TreeNode>) {}

  showJsonTreeSchemaView(): Observable<TreeNode> {
    return this.dialogService.showDialog(JsonSchemaTreeViewComponent, {
      width: '95%',
    });
  }
}
