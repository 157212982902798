import { EntityMetadataMap } from '@ngrx/data';

export const KE_USER_FUNCTIONS_TAGS_ENTITY_NAME = 'keUserFunctionsTags';

const entityMetadata: EntityMetadataMap = {
  [KE_USER_FUNCTIONS_TAGS_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keUserFunctionsTagsEntityConfig = {
  entityMetadata,
};
