import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { FormatOptionConverter, OptionGenerator } from '@selfai-platform/bi-chart-engine';
import {
  DashboardField as AbstractField,
  ChartType,
  CustomSymbol,
  Format,
  GridViewType,
  UIFormatNumericAliasType,
  UIFormatSymbolPosition,
  UIGridChart,
  UIOption,
  UiChartFormatSymbolPosition,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { SelectComponent } from '../../../common/component/select/select.component';

import UI = OptionGenerator.UI;

@Component({
  selector: '[format-item]',
  templateUrl: './format-item.component.html',
})
export class FormatItemComponent extends AbstractComponent implements OnInit, OnDestroy {
  @ViewChild('typeListSelect', { static: true })
  private typeListComp: SelectComponent;

  @ViewChild('signListSelect')
  private signListComp: SelectComponent;

  @ViewChild('numericAliasListSelect', { static: true })
  private numericAliasListComp: SelectComponent;

  private _orgTypeList: Object[] = [
    { name: this.translateService.instant('msg.page.li.num'), value: 'number' },
    { name: this.translateService.instant('msg.page.li.currency'), value: 'currency' },
    { name: this.translateService.instant('msg.page.li.percent'), value: 'percent' },
    { name: this.translateService.instant('msg.page.li.exponent'), value: 'exponent10' },
  ];

  @Output('changeFormat')
  public changeEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  public field: AbstractField;

  public uiOption: UIOption;

  public format: Format;

  public typeList: Object[] = [
    { name: this.translateService.instant('msg.page.li.num'), value: 'number' },
    { name: this.translateService.instant('msg.page.li.currency'), value: 'currency' },
    { name: this.translateService.instant('msg.page.li.percent'), value: 'percent' },
    { name: this.translateService.instant('msg.page.li.exponent'), value: 'exponent10' },
  ];

  public selectedType: Object = this.typeList[0];

  public currencySignList: Object[] = [
    { name: '₽ (RUB)', value: 'RUB' },
    { name: '₩ (KRW)', value: 'KRW' },
    { name: '$ (USD)', value: 'USD' },
    { name: '£ (GBP)', value: 'GBP' },
    { name: '¥ (JPY)', value: 'JPY' },
    { name: '€ (EUR)', value: 'EUR' },
    { name: '¥ (CNY)', value: 'CNY' },
  ];

  public selectedSign: Object = this.currencySignList[0];

  public decimal = 2;
  public decimalCopy: number = this.decimal;
  public MIN_DIGIT = 0;
  public MAX_DIGIT = 5;

  public useThousandsSep = false;

  public numericAliasList: Object[] = [
    {
      name: this.translateService.instant('msg.page.format.numeric.alias.none'),
      value: String(UIFormatNumericAliasType.NONE),
    },
    {
      name: this.translateService.instant('msg.page.format.numeric.alias.auto'),
      value: String(UIFormatNumericAliasType.AUTO),
    },
    {
      name: this.translateService.instant('msg.page.format.numeric.alias.kilo'),
      value: String(UIFormatNumericAliasType.KILO),
    },
    {
      name: this.translateService.instant('msg.page.format.numeric.alias.milion'),
      value: String(UIFormatNumericAliasType.MEGA),
    },
    {
      name: this.translateService.instant('msg.page.format.numeric.alias.billion'),
      value: String(UIFormatNumericAliasType.GIGA),
    },
  ];

  public selectedNumericAlias: Object = this.numericAliasList[0];

  public positionList: Object[] = [
    {
      name: this.translateService.instant('msg.page.format.custom.symbol.position.front'),
      value: UIFormatSymbolPosition.BEFORE,
    },
    {
      name: this.translateService.instant('msg.page.format.custom.symbol.position.back'),
      value: UIFormatSymbolPosition.AFTER,
    },
  ];

  public preview: string;

  public customSymbol: CustomSymbol;

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;
    this.typeList = JSON.parse(JSON.stringify(this._orgTypeList));
    if (uiOption.type === ChartType.GRID && (uiOption as UIGridChart).dataType === GridViewType.MASTER) {
      this.typeList.push({ name: this.translateService.instant('msg.page.li.origin'), value: 'origin' });
    }
  }

  @Input('format')
  public set setFormat(format: Format) {
    if (!format) {
      return;
    }

    this.format = format;
    this.setType = this.format.type;
    this.setSign = this.format.sign;
    this.decimal = this.format.decimal;
    this.decimalCopy = this.decimal;
    this.useThousandsSep = this.format.useThousandsSep;
    this.setNumericAlias = this.format.abbr;
    if (this.format.customSymbol) {
      this.customSymbol = {};
      this.customSymbol.value = this.format.customSymbol ? this.format.customSymbol.value : '';
      this.customSymbol.pos = this.format.customSymbol
        ? this.format.customSymbol.pos
        : UiChartFormatSymbolPosition.BEFORE;
      this.customSymbol.abbreviations = this.format.customSymbol ? this.format.customSymbol.abbreviations : false;
    }

    this.preview = FormatOptionConverter.getFormatValue(1000, this.format);
  }

  @Input('type')
  public set setType(type: string) {
    if (!type) {
      return;
    }

    for (let num = 0; num < this.typeList.length; num++) {
      if (this.typeList[num]['value'] == type) {
        this.typeListComp.setDefaultIndex = num;
        this.selectedType = this.typeList[num];
        this.changeDetect.detectChanges();
        break;
      }
    }
  }

  @Input('sign')
  public set setSign(sign: string) {
    if (!sign || !this.signListComp) {
      return;
    }

    const signList: Object[] = this.currencySignList;

    for (let num = 0; num < signList.length; num++) {
      if (signList[num]['value'] == sign) {
        this.signListComp.setDefaultIndex = num;
        this.selectedSign = signList[num];
        break;
      }
    }
  }

  @Input('numericAlias')
  public set setNumericAlias(numericAlias: string) {
    if (!numericAlias || !this.numericAliasListComp) {
      return;
    }

    const aliasList: Object[] = this.numericAliasList;

    for (let num = 0; num < aliasList.length; num++) {
      if (aliasList[num]['value'] == numericAlias) {
        this.numericAliasListComp.setDefaultIndex = num;
        this.selectedNumericAlias = aliasList[num];
        break;
      }
    }
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
    this.typeList = JSON.parse(JSON.stringify(this._orgTypeList));
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onTypeChange(type: Object): void {
    this.selectedType = type;

    this.change();
  }

  public onSignChange(sign: Object): void {
    this.selectedSign = sign;

    this.change();
  }

  public onDigitChange(isPlus: boolean): void {
    if (isPlus) {
      if (this.decimal == this.MAX_DIGIT) {
        return;
      }

      this.decimal = this.decimal + 1;
    } else {
      if (this.decimal == this.MIN_DIGIT) {
        return;
      }

      this.decimal = this.decimal - 1;
    }

    this.decimalCopy = this.decimal;

    this.change();
  }

  public onDigitValid(): void {
    if (this.decimalCopy == this.decimal) {
      return;
    }

    if (this.decimalCopy < this.MIN_DIGIT || this.decimalCopy > this.MAX_DIGIT) {
      this.decimalCopy = 2;
    }

    this.decimal = this.decimalCopy;

    this.change();
  }

  public onThousandsSepChange(): void {
    this.useThousandsSep = !this.useThousandsSep;

    this.change();
  }

  public onNumericAliasChange(numericAlias: Object): void {
    this.selectedNumericAlias = numericAlias;

    this.change();
  }

  public change(): void {
    if (!this.format) {
      this.format = {};
    }

    this.format.type = this.selectedType['value'];
    this.format.sign = this.selectedSign['value'];
    this.format.decimal = this.decimal;
    this.format.useThousandsSep = this.useThousandsSep;
    this.format.abbr = this.selectedNumericAlias['value'];
    this.format.customSymbol = this.customSymbol;

    this.preview = FormatOptionConverter.getFormatValue(1000, this.format);

    if (this.field) {
      this.field.format = this.format;
      this.changeEvent.emit(this.field);
    } else {
      this.changeEvent.emit(this.format);
    }
  }

  public changePosition(position: any): void {
    this.customSymbol.pos = position.value;

    this.change();
  }

  public changeSymbol(): void {
    this.change();
  }

  public showCustomSymbol(): void {
    if (this.customSymbol) {
      this.customSymbol = null;
    } else {
      this.customSymbol = UI.Format.customSymbol(UiChartFormatSymbolPosition.BEFORE);
    }

    this.change();
  }

  public changeNumberSymbol(): void {
    this.customSymbol.abbreviations = !this.customSymbol.abbreviations;

    this.change();
  }
}
