<p-dropdown
  *ngIf="items$ | async as items; else noData"
  [options]="items"
  [ngModel]="value"
  (ngModelChange)="updateValue($event)"
  optionValue="name"
  styleClass="w-20rem"
  [filter]="true"
  filterBy="name,index"
  [autofocusFilter]="true"
  appendTo="body"
  filterPlaceholder="Search by column name or index"
  [placeholder]="label || ''"
  [autoDisplayFirst]="false"
  [showClear]="true"
>
  <ng-template let-item pTemplate="selectedItem">
    <div>
      <b>{{ item.index }}</b>
      {{ item.name }}
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <div>
      <b>{{ item.index }}</b>
      {{ item.name }}
    </div>
  </ng-template>
</p-dropdown>

<ng-template #noData>
  <p-message
    severity="warn"
    text="Can't be calculated! Please execute the previous workflow steps to get the Data Schema."
  ></p-message>
</ng-template>
