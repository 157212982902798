export enum DataZoomType {
  INSIDE = 'inside',
  SLIDER = 'slider',
}

export enum FilterMode {
  FILTER = 'filter',
  WEAKFILTER = 'weakfilter',
  EMPTY = 'empty',
  NONE = 'none',
}
