import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class UnloadConfirmService {
  constructor(private translateSvc: TranslateService) {}

  public confirm(message?: string): Observable<boolean> {
    const confirmation = window.confirm(message || this.translateSvc.instant('msg.comm.ui.beforeunload'));
    return of(confirmation);
  }
}
