export class CommonCode {
  public id: number;
  public categoryCode: string;
  public commonCodeType: string;
  public commonValue: string;
  public categoryCommonCodeNm: string;
  public commonCodeNm: string;
  public commonCodeSNO: string;
  public commonUseFl: string;
  public description: string;
  public syntax: string;
  public param: string;
  public example: string;
  /**
 * @deprecated Not used anymore
 */
  public descriptionEn: string;
    /**
 * @deprecated Not used anymore
 */
  public paramEn: string;
    /**
 * @deprecated Not used anymore
 */
  public exampleEn: string;
}
