import { Session } from '@selfai-platform/pipeline-common';
import { Observable } from 'rxjs';

export abstract class SessionsManagerAdapter {
  abstract loadList(): Observable<Session[]>;
  abstract loadByWorkflowId(workflowId: string): Observable<Session>;
  abstract stopByWorkflowId(workflowId: string): Observable<void>;
  // TODO: should typing this method
  abstract start(config: unknown): Observable<unknown>;
}
