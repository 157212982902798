import { AfterViewInit, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { TableCellSizingService } from './table-cell-sizing.service';

@Directive({
  selector: '[selfaiPlatformCellSizing]',
  standalone: true,
})
export class TableCellSizingDirective implements AfterViewInit {
  thArr!: NodeListOf<HTMLElementTagNameMap['th']>;

  @Input({ required: true }) selfaiPlatformCellSizing: string;

  @HostListener('onColResize') onColResize(): void {
    const widthArr: number[] = [];
    this.getThArr()?.forEach((el) => {
      widthArr.push(el.offsetWidth);
    });
    this.tableCellSizingService.setSizing(this.selfaiPlatformCellSizing, widthArr);
  }

  constructor(private el: ElementRef, private tableCellSizingService: TableCellSizingService) {}

  ngAfterViewInit(): void {
    if (this.selfaiPlatformCellSizing && this.getThArr()?.length) {
      this.initSizingCol();
    }
  }

  initSizingCol(): void {
    const tableColSizing = this.tableCellSizingService.getSizing();
    if (tableColSizing && JSON.parse(tableColSizing)?.[this.selfaiPlatformCellSizing]) {
      this.getThArr()?.forEach((el, i) => {
        el.style.setProperty('width', JSON.parse(tableColSizing)?.[this.selfaiPlatformCellSizing][i] + 'px');
        el.style.setProperty('max-width', JSON.parse(tableColSizing)?.[this.selfaiPlatformCellSizing][i] + 'px');
      });
    }
  }

  getThArr(): NodeListOf<HTMLElementTagNameMap['th']> {
    if (!this.thArr) {
      this.thArr = this.el.nativeElement.querySelector('thead')?.querySelectorAll('th');
    }

    return this.thArr;
  }
}
