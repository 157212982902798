import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldType } from '@selfai-platform/pipeline-common';
import { JsonPathFieldComponent } from '../../../../json-path/components/json-path-field/json-path-field.component';
import { ConstantValueFieldComponent } from '../constant-value-field/constant-value-field.component';
import { SpelValueFieldComponent } from '../spel-value-field/spel-value-field.component';

@Component({
  selector: 'selfai-platform-type-field-switch',
  templateUrl: './type-field-switch.component.html',
  styleUrls: ['./type-field-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeFieldSwitchComponent),
      multi: true,
    },
  ],
})
export class TypeFieldSwitchComponent implements AfterContentInit, ControlValueAccessor {
  fieldTypes: FieldType[] = [];
  readonly fieldType = FieldType;
  activeFieldType!: FieldType;

  @ContentChild(forwardRef(() => JsonPathFieldComponent))
  readonly jsonPathField?: JsonPathFieldComponent;
  @ContentChild(forwardRef(() => SpelValueFieldComponent))
  readonly spelValueField?: SpelValueFieldComponent;
  @ContentChild(forwardRef(() => ConstantValueFieldComponent))
  readonly constantValueField?: ConstantValueFieldComponent;

  private onChange!: (_: FieldType) => void;
  private onTouched!: () => void;

  ngAfterContentInit(): void {
    this.collectFields();
  }

  writeValue(value: FieldType): void {
    this.activeFieldType = value;
  }

  registerOnChange(fn: (_: FieldType) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  changeType(type: FieldType): void {
    this.activeFieldType = type;
    this.onChange(type);
    this.onTouched();
  }

  private collectFields(): void {
    const fieldTypes = [];
    if (this.jsonPathField) {
      fieldTypes.push(FieldType.JSONPATH);
    }

    if (this.spelValueField) {
      fieldTypes.push(FieldType.SPEL);
    }

    if (this.constantValueField) {
      fieldTypes.push(FieldType.CONSTANT);
    }

    this.fieldTypes = fieldTypes;
  }
}
