export enum PipelineFeatureName {
  // aggregates Apache Camel
  AGGREGATION = 'aggregation',
  // interpolation of variables in the editor of Workflow
  VARIABLES = 'variables',
  // versions workflow changes
  VERSIONS = 'versions',
  // experimental features. they are not tested yet
  EXPIREMENTAL = 'expiremental',
  LIBRARY = 'library'
}
