import { concat, isUndefined } from 'lodash';

import { SearchQueryRequest } from '@selfai-platform/bi-domain';

import { CommonUtil } from '../../../../common/util';
import { FilterUtil } from '../../../util/filter.util';

export function makeSearchQueryParam(cloneQuery): SearchQueryRequest {
  for (const field of concat(cloneQuery.pivot.columns, cloneQuery.pivot.rows, cloneQuery.pivot.aggregations)) {
    delete field['field'];
    delete field['currentPivot'];
    delete field['granularity'];
    delete field['segGranularity'];
  }

  if (cloneQuery.shelf && cloneQuery.shelf.layers && cloneQuery.shelf.layers.length > 0) {
    for (const layers of cloneQuery.shelf.layers) {
      for (const layer of layers.fields) {
        delete layer['field'];
        delete layer['currentPivot'];
        delete layer['granularity'];
        delete layer['segGranularity'];
      }
    }

    if (!isUndefined(cloneQuery.analysis)) {
      if (cloneQuery.analysis.use == true) {
        delete cloneQuery.analysis.operation.unit;
        delete cloneQuery.analysis.layer;
        delete cloneQuery.analysis.layerNum;
        delete cloneQuery.analysis.use;
      } else {
        delete cloneQuery.analysis;
      }
    }
  }

  for (let filter of cloneQuery.filters) {
    filter = FilterUtil.convertToServerSpec(filter);
  }

  cloneQuery.filters = cloneQuery.filters.filter((item) => {
    return (
      item.type === 'empty' ||
      (item.type === 'include' && item['valueList'] && 0 < item['valueList'].length) ||
      (item.type === 'bound' && item['min'] != null) ||
      item.type === 'spatial_bbox' ||
      FilterUtil.isTimeAllFilter(item) ||
      FilterUtil.isTimeRelativeFilter(item) ||
      FilterUtil.isTimeRangeFilter(item) ||
      (FilterUtil.isTimeListFilter(item) && item['valueList'] && 0 < item['valueList'].length)
    );
  });

  cloneQuery.userFields = CommonUtil.objectToArray(cloneQuery.userFields);

  return cloneQuery;
}
