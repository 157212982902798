<div class="ddp-wrap-popup" *ngIf="isShow">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-ui-popup-in">
    <div class="ddp-ui-popup">
      <a href="javascript:" class="ddp-btn-close" (click)="close()" *ngIf="useCloseBtn"></a>
      <span class="ddp-ui-pop-title">{{ modal.name }}</span>
      <div class="ddp-txt-pop-info ddp-padt0">
        {{ modal.description }}
        <br *ngIf="modal.description" />
        {{ modal.subDescription }}
      </div>

      <div class="ddp-ui-buttons2" *ngIf="!useCancelBtn">
        <a #doneElm href="javascript:" class="ddp-btn-type-popup ddp-bg-black" (click)="done()">
          {{ 'msg.comm.btn.close' | translate }}
        </a>
      </div>
      <div class="ddp-ui-buttons2" *ngIf="useCancelBtn">
        <a href="javascript:" class="ddp-btn-type-popup" (click)="close()">
          {{ modal.btnCancel || ('msg.comm.btn.cancl' | translate) }}
        </a>
        <a #doneElm href="javascript:" class="ddp-btn-type-popup ddp-bg-black" (click)="done()">
          {{ modal.btnName || ('msg.comm.btn.modal.done' | translate) }}
        </a>
      </div>
    </div>
  </div>
</div>
