import { ValueProvider } from '@angular/core';
import { DefaultPageParams } from './page-params.model';
import { SearchQueryService } from './search-query.service';
import { UrlPageParamsService } from './url-page-params.service';
import { DEFAULT_PAGE_PARAMS } from '../tokens';

export function providePageParams(
  defaultPageParams: DefaultPageParams,
): [typeof UrlPageParamsService, typeof SearchQueryService, ValueProvider] {
  return [
    UrlPageParamsService,
    SearchQueryService,
    {
      provide: DEFAULT_PAGE_PARAMS,
      useValue: defaultPageParams,
    },
  ];
}
