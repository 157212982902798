import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { PipelineConfigService } from '@selfai-platform/shared';
import { KE_DATA_SOURCES_LIBRARY_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';
import { DataSourcesLibraryFilesInterface } from '../model/data-sources-library-files.model';

@Injectable({
  providedIn: 'root',
})
export class KeDataSourcesLibraryService extends DefaultDataService<DataSourcesLibraryFilesInterface> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private pipelineConfigService: PipelineConfigService,
  ) {
    super(KE_DATA_SOURCES_LIBRARY_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<DataSourcesLibraryFilesInterface[]> {
    return this.http.get<DataSourcesLibraryFilesInterface>(`${this.apiUrl}/library`).pipe(
      map((data) => {
        return data.children;
      }),
    );
  }

  override add(files: any): Observable<DataSourcesLibraryFilesInterface> {
    const file: File = files[0];
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(`${this.apiUrl}/library`, formData).pipe(
      map(() => {
        return { name: file.name, kind: 'file', children: [] };
      }),
    );
  }

  override delete(name: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/library/${name}`);
  }
}
