export interface PivotTableInfo {
  cols: string[];
  rows: string[];
  aggs: string[];
}

export function createPivotTableInfo(cols: string[], rows: string[], aggs: string[]): PivotTableInfo {
  return {
    cols,
    rows,
    aggs,
  };
}
