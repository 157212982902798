import { Injectable, Injector } from '@angular/core';

import { FieldNameAlias, FieldValueAlias } from '@selfai-platform/bi-domain';

import { AbstractService } from '../../common/service/abstract.service';

@Injectable()
export class DatasourceAliasService extends AbstractService {
  private aliasPath: string = this.API_URL + 'datasources/aliases/';

  constructor(protected injector: Injector) {
    super(injector);
  }

  public createAliases(params: any): Promise<FieldNameAlias | FieldValueAlias> {
    const url = this.aliasPath;
    return this.post(url, params);
  }

  public updateAliases(aliasId: number, params: any): Promise<FieldNameAlias | FieldValueAlias> {
    const url = this.aliasPath + aliasId;
    return this.patch(url, params);
  }

  public deleteAliases(aliasId: number): Promise<any> {
    const url = this.aliasPath + aliasId;
    return this.delete(url);
  }

  public getAliases(aliasId: string): Promise<any> {
    const url = this.aliasPath + aliasId;
    return this.get(url);
  }
}
