import { HttpClient } from '@angular/common/http';
import { AlertService, HistoryBackService, PipelineConfigService } from '@selfai-platform/shared';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { map, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { Update } from '@ngrx/entity/src/models';
import { WorkflowPresetsInterface } from '../interfaces/workflow-presets.interface';
import { KE_WORKFLOW_PRESETS_ENTITY_NAME } from '@selfai-platform/store';
import { getPresetsPath } from '../functions';
import { WorkflowPresetsApiService } from './workflow-presets-api.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsService extends DefaultDataService<WorkflowPresetsInterface> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/${this.config.versions.url}/presets`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private router: Router,
    private history: HistoryBackService,
    private pipelineConfigService: PipelineConfigService,
    private alertService: AlertService,
    private readonly workflowPresetsApiService: WorkflowPresetsApiService,
  ) {
    super(KE_WORKFLOW_PRESETS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<WorkflowPresetsInterface[]> {
    return this.workflowPresetsApiService.getAllPresets();
  }

  override getById(id: number): Observable<WorkflowPresetsInterface> {
    return this.workflowPresetsApiService.gePresetById(id);
  }

  override add(data: WorkflowPresetsInterface): Observable<WorkflowPresetsInterface> {
    return this.workflowPresetsApiService.addPreset(data).pipe(
      tap({
        next: () => {
          this.router.navigate(getPresetsPath()).then();
          this.alertService.success(`Workflow preset added`);
        },
        error: () => {
          this.alertService.error(`Creation Error`);
        },
      }),
    );
  }

  override update(data: Update<WorkflowPresetsInterface>): Observable<WorkflowPresetsInterface> {
    return this.workflowPresetsApiService.updatePreset(data.changes).pipe(
      tap({
        next: () => {
          this.history.back(getPresetsPath());
          this.alertService.success(`Workflow preset changed`);
        },
        error: () => {
          this.alertService.error(`Editing error`);
        },
      }),
    );
  }

  override delete(id: string): Observable<string> {
    return this.workflowPresetsApiService.deletePreset(id).pipe(
      tap({
        next: () => {
          this.alertService.success(`Successful removal`);
        },
        error: () => {
          this.alertService.error(`Uninstall error`);
        },
      }),
    );
  }
}
