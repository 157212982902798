import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFunctionsListComponent } from '../../components/user-functions-list/user-functions-list.component';
import { map, Observable, takeUntil } from 'rxjs';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_ROOT_ROUTE,
  KE_USER_FUNCTIONS_PATH,
} from '@selfai-platform/shell';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { KeCurrentUserStore } from '@selfai-platform/pipeline-module';
import { UserFunctionsTagsStore } from '../../store/user-functions-tags.store';
import { IFunctionTag } from '../../models';

@Component({
  selector: 'selfai-platform-user-functions-container',
  standalone: true,
  imports: [CommonModule, UserFunctionsListComponent],
  templateUrl: 'user-functions-container.component.html',
  styleUrls: [ './user-functions-container.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFunctionsContainerComponent implements OnInit {
  public tags$: Observable<IFunctionTag[]>;

  public breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translate.stream('shell.menu.user-functions').pipe(
    map((label) => [
      {
        label,
        routerLink: ['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH],
      },
    ]),
  );

  constructor(
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly translate: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly keUserStore: KeCurrentUserStore,
    private readonly tagsStore: UserFunctionsTagsStore,
  ) {
    this.tags$ = this.tagsStore.entities$;
  }

  public ngOnInit(): void {
    this.keUserStore.getByKey('');
    this.tagsStore.getAll();

    this.breadcrumbItems$
      .pipe(takeUntil(this.destroy$))
      .subscribe((breadcrumbItems) => {
        this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
      });
  }
}
