export enum KdFeatureName {
  MAIN_DASHBOARD = 'mainDashboard',
  WORKBOOKS = 'workbooks',
  DASHBOARDS = 'dashboards',
  CHARTS = 'charts',
  DATASOURCES = 'datsources',
  CONNECTIONS = 'connections',
  DATAFLOWS = 'dataflows',
  DATASETS = 'datasets',
}
