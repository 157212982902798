import { Directive, Input } from '@angular/core';
import { TemplateNameDirective } from '@selfai-platform/shared';
import { DataListViewTemplateName } from '../constants';

type DataType<TData extends DataListViewTemplateName, TFields extends object> = TData extends 'fieldValue'
  ? { fieldCode: keyof TFields }
  : undefined;

@Directive({
  selector: '[selfaiPlatformDataListViewTemplateName]',
  standalone: true,
})
export class DataListViewTemplateNameDirective<TFields extends object> extends TemplateNameDirective<
  DataListViewTemplateName,
  DataType<DataListViewTemplateName, TFields>
> {
  @Input({ required: true, alias: 'selfaiPlatformDataListViewTemplateName' })
  override selfaiPlatformTemplateName: DataListViewTemplateName;
}
