import { UIOption } from '../../chart';
import { BoardDataSource } from '../../dashboard';
import { DatasourceField } from '../../datasource';
import { CustomField, Filter, Format, Limit, Pivot, Shelf } from '../../workbook';
import { WidgetConfiguration } from './widget.model';

export interface PageWidgetConfiguration extends WidgetConfiguration {
  filters: Filter[];
  pivot?: Pivot;
  customFields?: CustomField[];
  chart?: UIOption;
  format?: Format;
  fields?: DatasourceField[];
  limit: Limit;
  analysis?: any;
  dataSource?: BoardDataSource;
  shelf?: Shelf;
  customFunction?: string;
  sync?: boolean;
}

export function createPageWidgetConfiguration(
  createPageWidgetConfigurationOptions: Partial<PageWidgetConfiguration> = {},
): PageWidgetConfiguration {
  return {
    filters: [],
    type: 'page',
    chart: {},
    limit: {
      limit: 100000,
      sort: [],
    },
    ...createPageWidgetConfigurationOptions,
  };
}
