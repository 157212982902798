import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { KeDataSourcesLibraryStore } from '../../store/data-sources-library.store';
import { DataSourcesLibraryDataFrameListComponent } from '../../components/data-sources-library-data-frame-list/data-sources-library-data-frame-list.component';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PipelineConfigService } from '@selfai-platform/shared';

@Component({
  selector: 'selfai-platform-data-sources-library-files-list-container',
  standalone: true,
  imports: [CommonModule, DataSourcesLibraryDataFrameListComponent],
  templateUrl: './data-sources-library-files-list-container.component.html',
  styleUrl: './data-sources-library-files-list-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesLibraryFilesListContainerComponent implements AfterViewInit {
  loading$: Observable<boolean>;
  libraries: Observable<any>;
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}`;

  constructor(
    public keDataSourcesLibraryStore: KeDataSourcesLibraryStore,
    private dynamicDialogRef: DynamicDialogRef,
    private pipelineConfigService: PipelineConfigService,
  ) {
    this.libraries = keDataSourcesLibraryStore.entities$;
    this.loading$ = keDataSourcesLibraryStore.loading$;
  }

  ngAfterViewInit(): void {
    this.keDataSourcesLibraryStore.getAll();
  }

  onSelectFile(name: string): void {
    this.dynamicDialogRef.close(name);
  }

  onFileDownload(name: string): void {
    let a = document.createElement('a');
    a.href = `${this.apiUrl}/${name}`;
    a.download = name;
    a.click();
  }

  onFileUpload(data: { files: File[] }): void {
    this.keDataSourcesLibraryStore.add(data.files as any);
  }

  onDelete(name: string): void {
    this.keDataSourcesLibraryStore.delete(name);
  }
}
