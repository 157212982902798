<selfai-platform-form-field
  label="{{ 'workflow.cubes.create-data-frame.column-mapping.field-label.column-name' | translate }}"
>
  <input type="text" pInputText [formControl]="form.controls.columnName" class="w-full" />
</selfai-platform-form-field>
<selfai-platform-form-field
  label="{{ 'workflow.cubes.create-data-frame.column-mapping.field-label.column-type' | translate }}"
>
  <p-dropdown
    [formControl]="form.controls.columnType"
    styleClass="w-10rem"
    appendTo="body"
    [options]="saveModeOptions"
  ></p-dropdown>
</selfai-platform-form-field>
