<div *ngIf="datasource" [ngClass]="{ 'ddp-type': !hasHeader }" class="ddp-box-grid-detail">
  <a (click)="closeBtn()" href="javascript:" class="ddp-btn-close-s"></a>

  <div class="ddp-data-detailinfo">
    <span class="ddp-data-name">{{ datasource.name }}</span>
    <div *ngIf="isEnabled" (click)="isShowDataPreview = true" class="ddp-ui-detail">
      <em class="ddp-icon-detail"></em>
      <div class="ddp-ui-tooltip-info">
        <em class="ddp-icon-view-top"></em>
        {{ 'msg.comm.detail.details' | translate }}
      </div>
    </div>
  </div>

  <div class="ddp-wrap-datadetail">
    <table class="ddp-dl-detail">
      <colgroup>
        <col width="120px" />
        <col width="*" />
      </colgroup>
      <tbody>
        <tr *ngIf="showMetadataName">
          <th>{{ 'msg.comm.detail.metadata-name' | translate }}</th>
          <td>{{ metadata?.name }}</td>
        </tr>
        <tr>
          <th>{{ 'msg.comm.detail.desc' | translate }}</th>
          <td>
            <div
              class="ddp-data-det"
              title="{{ metadata && metadata.description ? metadata.description : datasource.description }}"
            >
              {{ metadata && metadata.description ? metadata.description : datasource.description }}
            </div>
          </td>
        </tr>

        <tr>
          <th>{{ 'msg.comm.detail.published' | translate }}</th>
          <td>
            {{ datasource.published ? ('msg.comm.name.public' | translate) : ('msg.comm.name.private' | translate) }}
          </td>
        </tr>
        <tr>
          <th>{{ 'msg.comm.detail.created' | translate }}</th>
          <td>{{ datasource.createdTime | mdate: 'YYYY-MM-DD' }}</td>
        </tr>
        <tr *ngIf="isEnabled && datasource.summary">
          <th>{{ 'msg.comm.detail.size' | translate }}</th>
          <td>{{ datasource.summary.size | fileSize: 2 }}</td>
        </tr>
        <tr *ngIf="isEnabled && datasource.summary">
          <th>{{ 'msg.comm.detail.rows' | translate }}</th>
          <td>{{ datasource.summary.count | numberCommas }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="isEnabled" class="ddp-data-listview">
    <table class="ddp-table-listview">
      <colgroup>
        <col width="30%" />
        <col width="10%" />
        <col width="*" />
      </colgroup>
      <tbody>
        <tr *ngFor="let item of datasource.fields">
          <td class="ddp-txt-center">
            <span
              class="ddp-box-tag-value"
              [class.ddp-measure]="item.role.toString() == 'MEASURE'"
              [class.ddp-dimension]="item.role.toString() != 'MEASURE'"
            >
              {{
                item.role.toString() == 'MEASURE'
                  ? ('msg.comm.name.mea' | translate)
                  : ('msg.comm.name.dim' | translate)
              }}
            </span>
          </td>
          <td class="ddp-txt-center">
            <em ngClass="{{ getIconClass(item.logicalType) }}"></em>
          </td>
          <td>
            {{ item.logicalName }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<data-preview *ngIf="isShowDataPreview" [source]="datasource" (close)="isShowDataPreview = false"></data-preview>
