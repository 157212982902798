<form (submit)="$event.preventDefault(); onSubmit()">
  <div class="field mt-4">
    <label class="block" for="name">Name</label>
    <input
      pAutoFocus
      [autofocus]="true"
      class="w-full"
      id="name"
      pInputText
      type="text"
      name="name"
      [formControl]="formControlName"
      placeholder="Input name"
    />
    <small *ngIf="formControlName.hasError('required')" class="p-error block">Name is required</small>
    <small *ngIf="formControlName.hasError('notUnique')" class="p-error block">Name must be unique</small>
  </div>
  <div class="mt-3">
    <button type="submit" class="mr-3 p-button-success" pRipple pButton label="Add"></button>
  </div>
</form>
