import { CustomSymbol } from '../../../workbook';

export enum UiChartFormatNumericAlias {
  NONE = 'NONE',
  AUTO = 'AUTO',
  KILO = 'KILO',
  MEGA = 'MEGA',
  GIGA = 'GIGA',
}

export enum UiChartFormatSymbolPosition {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export interface UiChartFormatSettings {
  type: string;
  abbr?: UiChartFormatNumericAlias;
  useThousandsSep?: boolean;
  customSymbol?: CustomSymbol;
  sign?: string;
  decimal?: number;
}

export interface UiChartFormatNumberSettings extends UiChartFormatSettings {
  type: 'number' | 'exponent' | 'percent';
  decimal: number;
}

export interface UiChartFormatCurrencySettings extends UiChartFormatSettings {
  type: 'currency';
  // RUB, KRW, USD, GBP, JPY, EUR, CNY or any other currency code
  sign: string;
}
