
import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

import {
  ChartUtil,
  ColorOptionConverter,
  FormatOptionConverter,
  OptionGenerator,
} from '@selfai-platform/bi-chart-engine';
import {
  AggregationType,
  ChartColorList,
  ColorRange,
  ColorRangeType,
  EventType,
  FieldRole,
  GeoField,
  LogicalType,
  MapBy,
  MapLayerType,
  MapLineStyle,
  MapOutline,
  MapSymbolType,
  MapThickness,
  PivotField,
  Shelf,
  ShelveFieldType,
  UIHeatmapLayer,
  UILayers,
  UILineLayer,
  UIMapOption,
  UIPolygonLayer,
  UISymbolLayer,
  UITileLayer,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { ColorTemplateComponent } from '../../../common/component/color-picker/color-template.component';
import { BaseOptionComponent } from '../base-option.component';

import UI = OptionGenerator.UI;

@Component({
  selector: 'map-layer-option',
  templateUrl: './map-layer-option.component.html',
  styles: ['.sys-inverted {transform: scaleX(-1);}'],
})
export class MapLayerOptionComponent extends BaseOptionComponent implements AfterViewChecked {
  public index = 0;

  @Input()
  public data: Object[];

  public rnbMenu: string;

  public ngAfterViewChecked() {
    this.changeDetect.detectChanges();
  }

  @Input('rnbMenu')
  public set setRnbMenu(rnbMenu: string) {
    this.rnbMenu = rnbMenu;
    this.getLayerIndex();
  }

  @Input('uiOption')
  public set setUiOption(uiOption: UIMapOption) {
    this.uiOption = uiOption;

    this.setClusterOption();

    this.rangesViewList = this.setRangeViewByDecimal(this.uiOption.layers[this.index].color['ranges']);

    if (this.uiOption.valueFormat && undefined !== this.uiOption.valueFormat.decimal) {
      this.minValue = FormatOptionConverter.getDecimalValue(
        this.uiOption.layers[this.index].color.minValue,
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
      this.maxValue = FormatOptionConverter.getDecimalValue(
        this.uiOption.layers[this.index].color.maxValue,
        this.uiOption.valueFormat.decimal,
        this.uiOption.valueFormat.useThousandsSep,
      );
    }
  }

  @Input('shelf')
  public set setShelf(shelf: Shelf) {
    this.setByType(shelf);

    this.shelf = shelf;

    this.setPointOption();

    this.changeDetect.detectChanges();
  }

  @ViewChild('colorTemplate')
  public colorTemplate: ColorTemplateComponent;

  @Output()
  public setZIndex: EventEmitter<boolean> = new EventEmitter();

  public shelf: Shelf;

  public uiOption: UIMapOption;

  public symbolLayerTypes = [
    { name: this.translateService.instant('msg.page.layer.map.type.point'), value: MapLayerType.SYMBOL },
    { name: this.translateService.instant('msg.page.layer.map.type.heatmap'), value: MapLayerType.HEATMAP },
    { name: this.translateService.instant('msg.page.layer.map.type.tile'), value: MapLayerType.TILE },
    { name: this.translateService.instant('msg.page.layer.map.type.cluster'), value: MapLayerType.CLUSTER },
  ];

  public symbolLayerSymbols = [
    { name: this.translateService.instant('msg.page.layer.map.point.circle'), value: MapSymbolType.CIRCLE },
    { name: this.translateService.instant('msg.page.layer.map.point.square'), value: MapSymbolType.SQUARE },
    { name: this.translateService.instant('msg.page.layer.map.point.triangle'), value: MapSymbolType.TRIANGLE },
  ];

  public thicknessList = [{ value: MapThickness.THIN }, { value: MapThickness.NORMAL }, { value: MapThickness.THICK }];

  public lineStyleList = [
    { name: this.translateService.instant('msg.page.layer.map.line.type.solid'), value: MapLineStyle.SOLID },
    { name: this.translateService.instant('msg.page.layer.map.line.type.dotted'), value: MapLineStyle.DOTTED },
    { name: this.translateService.instant('msg.page.layer.map.line.type.dashed'), value: MapLineStyle.DASHED },
  ];

  public colorListFlag = false;

  public fieldList = [];
  public fieldMeasureList = [];

  public rangesViewList = [];

  public availableRangeValue: string;

  public minValue: string;
  public maxValue: string;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public changeLayerName(name: string, layerIndex: number) {
    this.uiOption.layers[layerIndex].name = name;

    this.applyLayers();
  }

  public changeSymbolLayerType(layerType: MapLayerType, layerIndex: number) {
    if (this.uiOption.layers[layerIndex].type === layerType) return;

    let measureList = [];
    let layer: UILayers = this.uiOption.layers[layerIndex];

    const preLayerType: MapLayerType = _.cloneDeep(this.uiOption.layers[layerIndex].type);
    const cloneLayer = _.cloneDeep(this.uiOption.layers[layerIndex]);
    if (MapLayerType.HEATMAP === preLayerType) {
      layer.heatMapRadius = cloneLayer.heatMapRadius;
      layer.color.heatMapSchema = cloneLayer.color.schema;
      layer.color.heatMapTransparency = cloneLayer.color.transparency;
    } else if (MapLayerType.SYMBOL === preLayerType) {
      layer.color.symbolSchema = cloneLayer.color.schema;
      layer.color.symbolTransparency = cloneLayer.color.transparency;
      layer.symbolPointType = (<UISymbolLayer>cloneLayer).symbol;
      layer.symbolOutline = (<UISymbolLayer>cloneLayer).outline;
      layer['symbolPointRadiusFrom'] = (<UISymbolLayer>cloneLayer)['pointRadiusFrom'];
      layer['symbolPointRadiusTo'] = (<UISymbolLayer>cloneLayer)['pointRadiusTo'];
      layer.color['symbolSettingUseFl'] = (<UISymbolLayer>cloneLayer).color.settingUseFl;
      layer.color['symbolRanges'] = (<UISymbolLayer>cloneLayer).color.ranges;
      layer.color['symbolChangeRange'] = (<UISymbolLayer>cloneLayer).color.changeRange;
      layer.color['symbolColumn'] = (<UISymbolLayer>cloneLayer).color.column;
    } else if (MapLayerType.TILE === preLayerType) {
      layer.tileRadius = cloneLayer.tileRadius;
      layer.color.tileSchema = cloneLayer.color.schema;
      layer.color.tranTransparency = cloneLayer.color.transparency;
      layer.color['tileSettingUseFl'] = (<UISymbolLayer>cloneLayer).color.settingUseFl;
      layer.color['tileRanges'] = (<UISymbolLayer>cloneLayer).color.ranges;
      layer.color['tileChangeRange'] = (<UISymbolLayer>cloneLayer).color.changeRange;
      layer.color['tileColumn'] = (<UISymbolLayer>cloneLayer).color.column;
    } else if (MapLayerType.POLYGON === preLayerType) {
      layer.color.polygonSchema = cloneLayer.color.schema;
      layer.color.tranTransparency = cloneLayer.color.transparency;
    } else if (MapLayerType.CLUSTER === preLayerType) {
      layer.color.clusterSchema = cloneLayer.color.schema;
      layer.color.clusterTransparency = cloneLayer.color.transparency;
      layer.clusterPointType = (<UISymbolLayer>cloneLayer).symbol;
      layer.clusterOutline = (<UISymbolLayer>cloneLayer).outline;
      layer.color['clusterSettingUseFl'] = (<UISymbolLayer>cloneLayer).color.settingUseFl;
      layer.color['clusterRanges'] = (<UISymbolLayer>cloneLayer).color.ranges;
      layer.color['clusterChangeRange'] = (<UISymbolLayer>cloneLayer).color.changeRange;
    }

    delete this.uiOption.layers[layerIndex].noneColor;
    delete this.uiOption.layers[layerIndex].dimensionColor;
    delete this.uiOption.layers[layerIndex].measureColor;

    this.uiOption.layers[layerIndex].type = layerType;

    if (layerType === MapLayerType.CLUSTER) {
      this.uiOption.layers[layerIndex]['clustering'] = true;
    } else {
      this.uiOption.layers[layerIndex]['clustering'] = false;
    }

    this.initOptionSymbolLayer(layerIndex);
    this.setMeasureDimensions(this.shelf);

    if (MapLayerType.HEATMAP === layerType) {
      layer = this.setColorByShelf(false, layerIndex);
      if (isNullOrUndefined(layer.color.heatMapTransparency)) {
        layer.color.heatMapTransparency = 10;
      }
      layer.color.transparency = layer.color.heatMapTransparency;
      if (isNullOrUndefined(layer['blur'])) {
        layer['blur'] = 20;
      }
      if (isNullOrUndefined(layer.heatMapRadius)) {
        layer.heatMapRadius = 20;
      }
      layer['radius'] = layer.heatMapRadius;

      measureList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.MEASURE.toString());

      if (!measureList || 0 === measureList.length) {
        this.uiOption.legend.auto = false;
      } else {
        layer.color.column = measureList[0]['name'];
      }

      this.removeAggregationType();
    } else if (MapLayerType.SYMBOL === layerType) {
      layer = this.setColorByShelf(false, layerIndex);
      if (isNullOrUndefined(layer.color.symbolTransparency)) {
        layer.color.symbolTransparency = 50;
      }
      layer.color.transparency = layer.color.symbolTransparency;
      if (isNullOrUndefined(layer.symbolPointType)) {
        layer.symbolPointType = MapSymbolType.CIRCLE;
      }
      (<UISymbolLayer>layer).symbol = layer.symbolPointType;
      if (isNullOrUndefined(layer.symbolOutline)) {
        layer.symbolOutline = null;
      }
      (<UISymbolLayer>layer).outline = layer.symbolOutline;
      (<UISymbolLayer>layer)['pointRadiusFrom'] = layer['symbolPointRadiusFrom'];
      (<UISymbolLayer>layer)['pointRadiusTo'] = layer['symbolPointRadiusTo'];
      (<UISymbolLayer>layer).color.settingUseFl = layer.color['symbolSettingUseFl'];
      (<UISymbolLayer>layer).color.ranges = layer.color['symbolRanges'];
      (<UISymbolLayer>layer).color.changeRange = layer.color['symbolChangeRange'];
      (<UISymbolLayer>layer).color.column = layer.color['symbolColumn'];

      this.rangesViewList = this.setRangeViewByDecimal(layer.color.ranges);

      this.removeAggregationType();
    } else if (MapLayerType.CLUSTER === layerType) {
      layer = this.setColorByShelf(false, layerIndex);
      if (isNullOrUndefined(layer.color.clusterTransparency)) {
        layer.color.clusterTransparency = 10;
      }
      layer.color.transparency = layer.color.clusterTransparency;
      if (isNullOrUndefined(layer.clusterPointType)) {
        layer.clusterPointType = MapSymbolType.CIRCLE;
      }
      (<UISymbolLayer>layer).symbol = layer.clusterPointType;
      if (isNullOrUndefined(layer.clusterOutline)) {
        layer.clusterOutline = null;
      }
      (<UISymbolLayer>layer).outline = layer.clusterOutline;

      layer.color.settingUseFl = layer.color['clusterSettingUseFl'];
      layer.color.ranges = layer.color['clusterRanges'];
      layer.color.changeRange = layer.color['clusterChangeRange'];
      this.rangesViewList = this.setRangeViewByDecimal(layer.color.ranges);

      this.removeAggregationType();
    } else if (MapLayerType.TILE === layerType) {
      layer = this.setColorByShelf(true, layerIndex);
      if (isNullOrUndefined(layer.color.tranTransparency)) {
        layer.color.tranTransparency = 10;
      }
      layer.color.transparency = layer.color.tranTransparency;
      if (isNullOrUndefined(layer.tileRadius)) {
        layer.tileRadius = 20;
      }
      layer['radius'] = layer.tileRadius;
      layer.color.settingUseFl = layer.color['tileSettingUseFl'];
      layer.color.ranges = layer.color['tileRanges'];
      layer.color.changeRange = layer.color['tileChangeRange'];
      layer.color.column = layer.color['tileColumn'];

      this.rangesViewList = this.setRangeViewByDecimal(layer.color.ranges);

      this.addAggregationType();
    }

    this.setMeasureDimensions(this.shelf);

    this.setPointOption();

    this.applyLayers({ type: EventType.MAP_CHANGE_OPTION });
  }

  public changeSymbolType(symbolType: MapSymbolType, layerIndex: number) {
    (<UISymbolLayer>this.uiOption.layers[layerIndex]).symbol = symbolType;

    this.applyLayers();
  }

  public changeTransparency(obj: any, slider: any, index: number) {
    const layer = this.uiOption.layers[index];
    if (MapLayerType.HEATMAP === layer.type) {
      layer.color.heatMapTransparency = slider.from;
      layer.color.transparency = layer.color.heatMapTransparency;
    } else if (MapLayerType.SYMBOL === layer.type) {
      layer.color.symbolTransparency = slider.from;
      layer.color.transparency = layer.color.symbolTransparency;
    } else if (MapLayerType.TILE === layer.type) {
      layer.color.tranTransparency = slider.from;
      layer.color.transparency = layer.color.tranTransparency;
    } else if (MapLayerType.POLYGON === layer.type) {
      layer.color.tranTransparency = slider.from;
      layer.color.transparency = layer.color.tranTransparency;
    } else if (MapLayerType.CLUSTER === layer.type) {
      layer.color.clusterTransparency = slider.from;
      layer.color.transparency = layer.color.clusterTransparency;
    }
    this.applyLayers();
  }

  public changeTransparencyText($event: any, index: number) {
    const inputValue = parseFloat($event.target.value);

    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      $event.target.value = this.uiOption.layers[index].color.transparency;
      return;
    } else {
      const layer = this.uiOption.layers[index];
      if (MapLayerType.HEATMAP === layer.type) {
        layer.color.heatMapTransparency = inputValue;
        layer.color.transparency = layer.color.heatMapTransparency;
      } else if (MapLayerType.SYMBOL === layer.type) {
        layer.color.symbolTransparency = inputValue;
        layer.color.transparency = layer.color.symbolTransparency;
      } else if (MapLayerType.TILE === layer.type) {
        layer.color.tranTransparency = inputValue;
        layer.color.transparency = layer.color.tranTransparency;
      } else if (MapLayerType.POLYGON === layer.type) {
        layer.color.tranTransparency = inputValue;
        layer.color.transparency = layer.color.tranTransparency;
      } else if (MapLayerType.CLUSTER === layer.type) {
        layer.color.clusterTransparency = inputValue;
        layer.color.transparency = layer.color.clusterTransparency;
      }
      this.applyLayers();
    }
  }

  public toggleOutline(outline: MapOutline, layerIndex: number) {
    if (outline) {
      outline = null;
    } else {
      outline = <any>{ color: '#4f4f4f', thickness: MapThickness.NORMAL };
    }

    if (
      MapLayerType.SYMBOL === this.uiOption.layers[layerIndex].type ||
      MapLayerType.CLUSTER === this.uiOption.layers[layerIndex].type
    ) {
      (<UISymbolLayer>this.uiOption.layers[layerIndex]).outline = outline;
    } else if (MapLayerType.POLYGON === this.uiOption.layers[layerIndex].type) {
      (<UIPolygonLayer>this.uiOption.layers[layerIndex]).outline = outline;
    }

    this.applyLayers();
  }

  public changeThick(thickness: MapThickness, layerIndex: number) {
    if (
      MapLayerType.SYMBOL === this.uiOption.layers[layerIndex].type ||
      MapLayerType.CLUSTER === this.uiOption.layers[layerIndex].type
    ) {
      (<UISymbolLayer>this.uiOption.layers[layerIndex]).outline.thickness = thickness;
    } else if (MapLayerType.POLYGON === this.uiOption.layers[layerIndex].type) {
      (<UIPolygonLayer>this.uiOption.layers[layerIndex]).outline.thickness = thickness;
    }

    this.applyLayers();
  }

  public isEnableClustering(layerIndex: number) {
    (<UISymbolLayer>this.uiOption.layers[layerIndex]).clustering = !(<UISymbolLayer>this.uiOption.layers[layerIndex])
      .clustering;
    this.applyLayers({ type: EventType.MAP_CHANGE_OPTION });
  }

  public changeClustering(obj: any, $event: any, index: number) {
    this.uiOption.layers[index]['coverage'] = $event.from;
    (<UIMapOption>this.uiOption).layers[index]['changeCoverage'] = true;
    this.applyLayers({ type: EventType.MAP_CHANGE_OPTION });
  }

  public changeClusteringText($event: any, index: number) {
    const inputValue = parseFloat($event.target.value);
    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      $event.target.value = (<UISymbolLayer>this.uiOption.layers[index])['coverage'];
      return;
    } else {
      (<UISymbolLayer>this.uiOption.layers[index])['coverage'] = inputValue;
      (<UIMapOption>this.uiOption).layers[index]['changeCoverage'] = true;
      this.applyLayers({ type: EventType.MAP_CHANGE_OPTION });
    }
  }

  public changePointSize(obj: any, $event: any, index: number, isSingle: boolean) {
    (<UIMapOption>this.uiOption).layers[index]['isChangePointRadius'] = true;
    this.uiOption['isChangeStyle'] = true;

    const fromValue = parseFloat($event.from);
    if (isSingle) {
      this.uiOption.layers[index]['pointRadiusFrom'] = fromValue;
      this.uiOption.layers[index].pointRadius = fromValue;
      delete this.uiOption.layers[index]['pointRadiusTo'];
    } else {
      const toValue = parseFloat($event.to);
      this.uiOption.layers[index]['pointRadiusFrom'] = fromValue;
      this.uiOption.layers[index]['pointRadiusTo'] = toValue;
    }
    this.applyLayers();
  }

  public changePointSizeText($event: any, index: number, sizeValue: string) {
    const inputValue = $event.target.value;

    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue)) {
      $event.target.value = this.uiOption.layers[index][sizeValue];
      return;
    }

    if (!isNullOrUndefined(this.uiOption.layers[index]['pointRadiusTo'])) {
      if (sizeValue == 'pointRadiusFrom' && inputValue > this.uiOption.layers[index]['pointRadiusTo']) {
        $event.target.value = this.uiOption.layers[index][sizeValue];
        return;
      } else if (sizeValue == 'pointRadiusTo' && inputValue < this.uiOption.layers[index]['pointRadiusFrom']) {
        $event.target.value = this.uiOption.layers[index][sizeValue];
        return;
      }
    }
    const value = parseFloat(inputValue);
    this.uiOption.layers[index][sizeValue] = value;
    if (sizeValue == 'pointRadiusFrom') {
      this.uiOption.layers[index].pointRadius = value;
    }
    (<UIMapOption>this.uiOption).layers[index]['isChangePointRadius'] = true;
    this.uiOption['isChangeStyle'] = true;
    this.applyLayers();
  }

  public findIndex(list: Object[], key: string, value: any, type: string, optionalKey?: string, optionalValue?: any) {
    if (!_.isUndefined(list) && list.length == 1) return list.length - 1;

    const fieldType = 'type';

    if (optionalKey && optionalValue) {
      return _.findIndex(list, {
        [key]: value,
        [optionalKey]: optionalValue,
        [fieldType as string]: ShelveFieldType.MEASURE,
      });
    }

    let index = this.index;
    if (this.uiOption.layers.length == 1) index = 0;

    let standardType = this.uiOption.layers[index].color.by;
    if (type == 'thickness') {
      standardType = (<UILineLayer>this.uiOption.layers[index]).thickness.by;
    } else if (type == 'size') {
      standardType = (<UISymbolLayer>this.uiOption.layers[index]).size.by;
    }

    if (this.uiOption.layers[index].type == MapLayerType.CLUSTER) {
      if (standardType == MapBy.MEASURE) {
        return 1;
      } else {
        return 0;
      }
    }

    switch (standardType) {
      case MapBy.NONE:
        return _.findIndex(list, { [key]: value });
      case MapBy.DIMENSION:
        return _.findIndex(list, { [key]: value, [fieldType as string]: ShelveFieldType.DIMENSION });
      case MapBy.MEASURE:
        return _.findIndex(list, { [key]: value, [fieldType as string]: ShelveFieldType.MEASURE });
      default:
        return _.findIndex(list, { [key]: value });
    }
  }

  public findFieldList(list: Object[], fieldTypeValue: string, isDefaultValue?: boolean) {
    const fieldType = 'type';

    if (isDefaultValue) {
      const resultFieldList: any = _.filter(list, { [fieldType]: fieldTypeValue });
      resultFieldList.unshift({
        name: this.translateService.instant('msg.page.layer.map.stroke.none'),
        alias: this.translateService.instant('msg.page.layer.map.stroke.none'),
        value: MapBy.NONE,
      });
      return resultFieldList;
    }

    return _.filter(list, { [fieldType]: fieldTypeValue });
  }

  public changeColorBy(data: PivotField, layerIndex: number) {
    if (this.uiOption.layers[layerIndex].color.column === data.name) return;

    this.uiOption.layers[layerIndex].color.ranges = undefined;

    this.uiOption.layers[layerIndex].color['settingUseFl'] = false;

    switch (data.type) {
      case 'none':
        this.uiOption.layers[layerIndex].color.by = MapBy.NONE;
        break;
      case 'dimension':
        this.uiOption.layers[layerIndex].color.by = MapBy.DIMENSION;
        break;
      case 'measure':
        this.uiOption.layers[layerIndex].color.by = MapBy.MEASURE;
        break;
      default:
        this.uiOption.layers[layerIndex].color.by = MapBy.NONE;
        break;
    }

    this.uiOption.layers[layerIndex].color.aggregationType = undefined;

    const dimensionList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.DIMENSION.toString());
    const measureList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.MEASURE.toString());

    if ('dimension' === data.type) {
      this.uiOption.layers[layerIndex].color.schema = 'SC1';

      if (dimensionList && dimensionList.length > 0) {
        this.uiOption.layers[layerIndex].color.column = dimensionList[0]['name'];
      } else {
        this.uiOption.layers[layerIndex].color.column = '';
      }
    } else if ('measure' === data.type) {
      this.uiOption.layers[layerIndex].color.schema = 'VC1';

      if (measureList && measureList.length > 0) {
        this.uiOption.layers[layerIndex].color.column = measureList[0]['name'];
        this.uiOption.layers[layerIndex].color.aggregationType = measureList[0]['aggregationType'];
      } else {
        this.uiOption.layers[layerIndex].color.column = '';
      }

      const colorList = <any>_.cloneDeep(ChartColorList[this.uiOption.layers[layerIndex].color['schema']]);

      if (
        MapLayerType.HEATMAP !== this.uiOption.layers[layerIndex].type &&
        MapBy.MEASURE === this.uiOption.layers[layerIndex].color.by
      ) {
        if (
          !_.isUndefined(this.uiOption.analysis) &&
          !_.isUndefined(this.uiOption.analysis['use']) &&
          this.uiOption.analysis['use'] == true
        ) {
          if (
            !_.isUndefined(this.uiOption.analysis['includeCompareLayer']) &&
            this.uiOption.analysis['includeCompareLayer'] == true
          ) {
            this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
              this.uiOption,
              this.data[this.uiOption.analysis['layerNum'] + 1],
              colorList,
              layerIndex,
              this.shelf.layers[layerIndex].fields as any,
              [],
            );
          } else {
            this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
              this.uiOption,
              this.data[this.uiOption.analysis['layerNum']],
              colorList,
              layerIndex,
              this.shelf.layers[layerIndex].fields as any,
              [],
            );
          }
        } else {
          this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
            this.uiOption,
            this.data[layerIndex],
            colorList,
            layerIndex,
            this.shelf.layers[layerIndex].fields as any,
            [],
          );
        }
      }
    } else {
      this.uiOption.layers[layerIndex].color.schema = '#6344ad';
      this.uiOption.layers[layerIndex].color.column = '';
    }

    this.changeColorColumn(data, layerIndex);
  }

  public changeColorColumn(data: PivotField, layerIndex: number) {
    this.uiOption.layers[layerIndex].color.column = data.name;

    if ('measure' === data.type) {
      this.uiOption.layers[layerIndex].color.aggregationType = data.aggregationType;
      this.uiOption.layers[layerIndex].color.granularity = null;

      const colorList = <any>_.cloneDeep(ChartColorList[this.uiOption.layers[layerIndex].color['schema']]);

      if (
        !_.isUndefined(this.uiOption.analysis) &&
        !_.isUndefined(this.uiOption.analysis['use']) &&
        this.uiOption.analysis['use'] == true
      ) {
        if (
          !_.isUndefined(this.uiOption.analysis['includeCompareLayer']) &&
          this.uiOption.analysis['includeCompareLayer'] == true
        ) {
          this.uiOption['layers'][this.uiOption['layerNum']]['isColorOptionChanged'] = true;
          this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
            this.uiOption,
            this.data[this.uiOption.analysis['layerNum'] + 1],
            colorList,
            layerIndex,
            this.shelf.layers[layerIndex].fields as any,
            [],
          );
        } else {
          this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
            this.uiOption,
            this.data[this.uiOption.analysis['layerNum']],
            colorList,
            layerIndex,
            this.shelf.layers[layerIndex].fields as any,
            [],
          );
        }
      } else {
        this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.uiOption,
          this.data[layerIndex],
          colorList,
          layerIndex,
          this.shelf.layers[layerIndex].fields as any,
          [],
        );
      }
    } else {
      if (data.format) this.uiOption.layers[layerIndex].color.granularity = data.format.unit.toString();
      this.uiOption.layers[layerIndex].color.aggregationType = null;
    }

    this.uiOption.layers[layerIndex]['isColorOptionChanged'] = true;
    this.applyLayers();
  }

  public changeStrokeBy(data: PivotField, layerIndex: number) {
    switch (data.type) {
      case 'none':
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.by = MapBy.NONE;
        break;
      case 'dimension':
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.by = MapBy.DIMENSION;
        break;
      case 'measure':
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.by = MapBy.MEASURE;
        break;
      default:
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.by = MapBy.NONE;
        break;
    }

    (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.aggregationType = undefined;

    if ('measure' === data.type) {
      const measureList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.MEASURE.toString());

      if (measureList && measureList.length > 0) {
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.column = measureList[0]['name'];
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.aggregationType = measureList[0]['aggregationType'];
      } else {
        (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.column = '';
      }
    } else if (MapBy.NONE === data['value']) {
      (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.column = '';
    }

    this.changeStrokeColumn(data, layerIndex);
  }

  public changeStrokeColumn(data: PivotField, layerIndex: number) {
    (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.column = data.name;
    (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.aggregationType = data.aggregationType;

    this.applyLayers();
  }

  public changeThickMaxValue(event: any, layerIndex: number) {
    const inputValue = parseFloat(event.target.value);

    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue)) {
      event.target.value = (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.maxValue;
      return;
    } else {
      (<UILineLayer>this.uiOption.layers[layerIndex]).thickness.maxValue = inputValue;
      this.applyLayers();
    }
  }

  public changeSizeBy(data: PivotField, layerIndex: number) {
    this.resetPointRadius(layerIndex);
    this.setMinMaxMeasureValue(this.shelf, data, this.data, layerIndex, false);

    switch (data.type) {
      case 'none':
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.by = MapBy.NONE;
        break;
      case 'dimension':
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.by = MapBy.DIMENSION;
        break;
      case 'measure':
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.by = MapBy.MEASURE;
        this.setPointOption();
        this.setMinMaxMeasureValue(this.shelf, data, this.data, layerIndex, true);
        break;
      default:
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.by = MapBy.NONE;
        break;
    }

    if ('measure' === data.type) {
      const measureList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.MEASURE.toString());

      if (measureList && measureList.length > 0) {
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.column = measureList[0]['name'];
      } else {
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.column = '';
      }
    } else {
      (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.column = '';
    }

    this.changeSizeColumn(data, layerIndex);
  }

  public changeSizeColumn(data: PivotField, layerIndex: number) {
    (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.column = data.name;

    this.applyLayers();
  }

  public changeByNoneColor(colorCode: string, layerIndex: number) {
    const layerType = this.uiOption.layers[layerIndex].type;
    if (MapLayerType.HEATMAP === layerType) {
      this.uiOption.layers[layerIndex].color['heatMapSchema'] = colorCode;
    } else if (MapLayerType.SYMBOL === layerType) {
      this.uiOption.layers[layerIndex].color['symbolSchema'] = colorCode;
    } else if (MapLayerType.TILE === layerType) {
      this.uiOption.layers[layerIndex].color['tileSchema'] = colorCode;
    } else if (MapLayerType.POLYGON === layerType) {
      this.uiOption.layers[layerIndex].color['polygonSchema'] = colorCode;
    } else if (MapLayerType.CLUSTER === layerType) {
      this.uiOption.layers[layerIndex].color['clusterSchema'] = colorCode;
    }

    this.uiOption.layers[layerIndex].color.schema = colorCode;
    this.uiOption.layers[layerIndex].noneColor = _.cloneDeep(colorCode);

    this.applyLayers();
  }

  public changeBlur(obj: any, slider: any, index: number) {
    (<UIHeatmapLayer>this.uiOption.layers[index]).blur = slider.from;
    this.applyLayers();
  }

  public changeBlurText($event: any, index: number) {
    const inputValue = parseFloat($event.target.value);
    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      $event.target.value = this.uiOption.layers[index]['blur'];
      return;
    } else {
      (<UIHeatmapLayer>this.uiOption.layers[index]).blur = inputValue;
      this.applyLayers();
    }
  }

  public changeRadius(obj: any, slider: any, index: number) {
    const heatMapLayer = <UIHeatmapLayer>this.uiOption.layers[index];
    heatMapLayer.heatMapRadius = slider.from;
    heatMapLayer.radius = heatMapLayer.heatMapRadius;
    this.applyLayers();
  }

  public changeRadiusText($event: any, index: number) {
    const inputValue = parseFloat($event.target.value);
    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      $event.target.value = this.uiOption.layers[index]['radius'];
      return;
    } else {
      const heatMapLayer = <UIHeatmapLayer>this.uiOption.layers[index];
      heatMapLayer.heatMapRadius = inputValue;
      heatMapLayer.radius = heatMapLayer.heatMapRadius;
      this.applyLayers();
    }
  }

  public changeHexagonRadius(obj: any, slider: any, layerIndex: number) {
    const tileLayer = <UITileLayer>this.uiOption.layers[layerIndex];
    tileLayer.tileRadius = slider.from;
    tileLayer.radius = tileLayer.tileRadius;
    (<UIMapOption>this.uiOption).layers[layerIndex]['changeTileRadius'] = true;
    this.applyLayers({});
  }

  public changeHexagonRadiusText(event: any, layerIndex: number) {
    const inputValue = parseFloat(event.target.value);

    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      event.target.value = (<UITileLayer>this.uiOption.layers[layerIndex]).radius;
      return;
    } else {
      const tileLayer = <UITileLayer>this.uiOption.layers[layerIndex];
      if (tileLayer.radius !== inputValue) {
        tileLayer.tileRadius = inputValue;
        tileLayer.radius = tileLayer.tileRadius;
        (<UIMapOption>this.uiOption).layers[layerIndex]['changeTileRadius'] = true;
        this.applyLayers({});
      }
    }
  }

  public changeCoverage(obj: any, slider: any, index: number) {
    (<UITileLayer>this.uiOption.layers[index]).coverage = slider.from;

    this.applyLayers();
  }

  public changeCoverageText($event: any, index: number) {
    const inputValue = parseFloat($event.target.value);
    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 1 || inputValue < 0) {
      $event.target.value = this.uiOption.layers[index]['coverage'];
      return;
    } else {
      (<UITileLayer>this.uiOption.layers[index]).coverage = inputValue;
      this.applyLayers();
    }
  }

  public changeColor(data: any, layerIndex: number) {
    const layerType = this.uiOption.layers[layerIndex].type;
    if (MapLayerType.HEATMAP === layerType) {
      this.uiOption.layers[layerIndex].color['heatMapSchema'] = data.colorNum;
    } else if (MapLayerType.SYMBOL === layerType) {
      this.uiOption.layers[layerIndex].color['symbolSchema'] = data.colorNum;
    } else if (MapLayerType.TILE === layerType) {
      this.uiOption.layers[layerIndex].color['tileSchema'] = data.colorNum;
    } else if (MapLayerType.POLYGON === layerType) {
      this.uiOption.layers[layerIndex].color['polygonSchema'] = data.colorNum;
    } else if (MapLayerType.CLUSTER === layerType) {
      this.uiOption.layers[layerIndex].color['clusterSchema'] = data.colorNum;
    }
    this.uiOption.layers[layerIndex].color.schema = data.colorNum;

    let isDimension = false;
    let isMeasure = false;
    _.each(this.shelf.layers[layerIndex].fields, (field) => {
      if (
        ('user_expr' === field.field.type ||
          (field.field.logicalType && field.field.logicalType.toString().indexOf('GEO') == -1)) &&
        _.eq(field.type, ShelveFieldType.DIMENSION)
      ) {
        isDimension = true;
      }
      if (_.eq(field.type, ShelveFieldType.MEASURE)) {
        isMeasure = true;
      }
    });

    if (isDimension) {
      this.uiOption.layers[layerIndex].dimensionColor = _.cloneDeep(data.colorNum);
    }
    if (isMeasure) {
      this.uiOption.layers[layerIndex].measureColor = _.cloneDeep(data.colorNum);
    }

    const colorList = <any>_.cloneDeep(ChartColorList[this.uiOption.layers[layerIndex].color['schema']]);

    if (
      MapLayerType.HEATMAP !== this.uiOption.layers[layerIndex].type &&
      MapBy.MEASURE === this.uiOption.layers[layerIndex].color.by
    ) {
      if (
        !_.isUndefined(this.uiOption.analysis) &&
        !_.isUndefined(this.uiOption.analysis['use']) &&
        this.uiOption.analysis['use'] == true
      ) {
        if (
          !_.isUndefined(this.uiOption.analysis['includeCompareLayer']) &&
          this.uiOption.analysis['includeCompareLayer'] == true
        ) {
          this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
            this.uiOption,
            this.data[this.uiOption.analysis['layerNum'] + 1],
            colorList,
            layerIndex,
            this.shelf.layers[layerIndex].fields as any,
            [],
          );
        } else {
          this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
            this.uiOption,
            this.data[this.uiOption.analysis['layerNum']],
            colorList,
            layerIndex,
            this.shelf.layers[layerIndex].fields as any,
            [],
          );
        }
      } else {
        this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.uiOption,
          this.data[layerIndex],
          colorList,
          layerIndex,
          this.shelf.layers[layerIndex].fields as any,
          [],
        );
      }
    } else {
      this.uiOption.layers[layerIndex].color.ranges = undefined;
    }

    this.applyLayers();
  }

  public findColorIndex(colorList: Object[], layerIndex: number) {
    if (this.colorTemplate) {
      const obj = _.find(colorList, { colorNum: this.uiOption.layers[layerIndex].color.schema });
      if (obj) {
        return obj['index'];
      }
      return 1;
    }
    return 1;
  }

  public findMeasureColorIndex(layerIndex: number) {
    if (this.colorTemplate) {
      let colorList: Object[] = [];

      colorList = colorList.concat(this.colorTemplate.measureColorList);
      colorList = colorList.concat(this.colorTemplate.measureReverseColorList);

      for (const item of colorList) {
        if (this.uiOption.layers[layerIndex].color.schema.endsWith(item['colorNum'])) {
          return item['index'];
        }
      }
    }

    return 1;
  }

  public isChartColorInverted(layerIndex: number) {
    return this.uiOption.layers[layerIndex].color.schema.indexOf('R') === 0;
  }

  public changeLineStyle(lineStyle: MapLineStyle, layerIndex: number) {
    (<UILineLayer>this.uiOption.layers[layerIndex]).lineStyle = lineStyle;

    this.applyLayers();
  }

  public changeOutlineColor(colorCode: string, layerIndex: number) {
    (<UISymbolLayer>this.uiOption.layers[layerIndex]).outline.color = colorCode;

    this.applyLayers();
  }

  public changeNoneRadiusRange(obj: any, slider: any, layerIndex: number) {
    (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[0] = slider.from;

    this.applyLayers();
  }

  public changeNoneRadiusRangeText(event: any, layerIndex: number) {
    const inputValue = parseFloat(event.target.value);

    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      event.target.value = (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[0];
      return;
    } else {
      (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[0] = inputValue;
      this.applyLayers();
    }
  }

  public changeMeasureRadiusRange(obj: any, slider: any, layerIndex: number) {
    (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[0] = slider.from;

    this.applyLayers();
  }

  public changeMeasureRadiusRangeText(event: any, index: number, layerIndex: number) {
    const inputValue = parseFloat(event.target.value);

    if (_.isEmpty(inputValue.toString()) || isNaN(inputValue) || inputValue > 100 || inputValue < 0) {
      event.target.value = (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[index];
      return;
    } else {
      if (0 === index) {
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[index] = inputValue;
      } else if (1 === index) {
        (<UISymbolLayer>this.uiOption.layers[layerIndex]).size.radiusRange[index] = inputValue;
      }

      this.applyLayers();
    }
  }

  public changeColorRange(layerIndex: number) {
    this.uiOption.layers[layerIndex].color.settingUseFl = !this.uiOption.layers[layerIndex].color.settingUseFl;

    const colorOption = this.uiOption.layers[layerIndex].color;

    const ranges = this.uiOption.layers[layerIndex].color.settingUseFl ? colorOption.ranges : [];

    if (
      !_.isUndefined(this.uiOption.analysis) &&
      !_.isUndefined(this.uiOption.analysis['use']) &&
      this.uiOption.analysis['use'] == true
    ) {
      if (
        !_.isUndefined(this.uiOption.analysis['includeCompareLayer']) &&
        this.uiOption.analysis['includeCompareLayer'] == true
      ) {
        this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.uiOption,
          this.data[this.uiOption.analysis['layerNum'] + 1],
          <any>ChartColorList[colorOption.schema],
          layerIndex,
          this.shelf.layers[layerIndex].fields as any,
          ranges,
        );
      } else {
        this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.uiOption,
          this.data[this.uiOption.analysis['layerNum']],
          <any>ChartColorList[colorOption.schema],
          layerIndex,
          this.shelf.layers[layerIndex].fields as any,
          ranges,
        );
      }
    } else {
      this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
        this.uiOption,
        this.data[layerIndex],
        <any>ChartColorList[colorOption.schema],
        layerIndex,
        this.shelf.layers[layerIndex].fields as any,
        ranges,
      );
    }

    this.applyLayers();
  }

  public changeRangeMinInput(range: any, index: number, layerIndex: number): void {
    const rangeList = this.uiOption.layers[layerIndex].color.ranges;

    if (!range.gt || isNaN(FormatOptionConverter.getNumberValue(range.gt)) || this.isNumberRegex(range.gt) == false) {
      range.gt = _.cloneDeep(
        FormatOptionConverter.getDecimalValue(
          rangeList[index].fixMin,
          this.uiOption.valueFormat.decimal,
          this.uiOption.valueFormat.useThousandsSep,
        ),
      );
      return;
    }

    range = this.parseStrFloat(range);

    const uiMinValue = _.cloneDeep(this.uiOption.layers[layerIndex].color.minValue);
    const uiMaxValue = _.cloneDeep(this.uiOption.layers[layerIndex].color.maxValue);

    const minValue = rangeList[index + 1]
      ? rangeList[index + 1].gt
        ? rangeList[index + 1].gt
        : uiMinValue
      : rangeList[index].gt
      ? rangeList[index].gt
      : rangeList[index].lte;
    const maxValue = range.lte;

    if (!rangeList[index - 1]) {
      if (uiMaxValue < range.gt || rangeList[index + 1].fixMax > range.gt) {
        range.gt = range.fixMin;
      } else {
        range.fixMin = range.gt;
      }
    } else if (minValue > range.gt || maxValue < range.gt) {
      range.gt = range.fixMin;
    } else {
      range.fixMin = range.gt;
    }

    if (rangeList[index + 1]) {
      rangeList[index + 1].lte = range.gt;
      rangeList[index + 1].fixMax = range.gt;
    }

    rangeList[index] = range;
    this.uiOption.layers[layerIndex].color.ranges = rangeList;
    this.uiOption.layers[layerIndex].color.changeRange = false;

    this.applyLayers();
  }

  public changeRangeMaxInput(range: any, index: number, layerIndex: number): void {
    const rangeList = this.uiOption.layers[layerIndex].color.ranges;

    if (
      !range.lte ||
      isNaN(FormatOptionConverter.getNumberValue(range.lte)) ||
      this.isNumberRegex(range.lte) == false
    ) {
      range.lte = _.cloneDeep(
        FormatOptionConverter.getDecimalValue(
          rangeList[index].fixMax,
          this.uiOption.valueFormat.decimal,
          this.uiOption.valueFormat.useThousandsSep,
        ),
      );
      return;
    }

    range = this.parseStrFloat(range);

    const uiMinValue = _.cloneDeep(this.uiOption.layers[layerIndex].color.minValue);

    const lowerfixMin = rangeList[index + 1]
      ? rangeList[index + 1].fixMin
        ? rangeList[index + 1].fixMin
        : rangeList[index + 1].fixMax
      : null;

    if (!rangeList[index + 1]) {
      if (uiMinValue < range.lte && rangeList[index - 1].fixMin > range.lte) {
        range.fixMax = range.lte;
        rangeList[index - 1].gt = range.lte;
      } else {
        range.lte = range.fixMax;
      }
    } else if (range.fixMax < range.lte || lowerfixMin > range.lte) {
      range.lte = range.fixMax;
    } else {
      range.fixMax = range.lte;
    }

    if (rangeList[index - 1]) {
      rangeList[index - 1].fixMin = range.lte;
      rangeList[index - 1].gt = range.lte;
    }

    if (null != range.fixMin && rangeList[index + 1] && range.fixMin > range.fixMax) {
      range.gt = range.fixMax;
      rangeList[index + 1].lte = range.fixMax;
      rangeList[index + 1].fixMax = range.fixMax;
    }

    rangeList[index] = range;
    this.uiOption.layers[layerIndex].color.ranges = rangeList;
    this.uiOption.layers[layerIndex].color.changeRange = false;

    this.applyLayers();
  }

  public availableRange(currentRnage: any, index: number): void {
    const rangeList = this.rangesViewList;

    let returnString = '';

    if (0 == index) {
      returnString += ': ' + currentRnage.fixMin;
    } else if (rangeList.length - 1 == index) {
      returnString += ': ' + currentRnage.fixMax;
    } else {
      const availableMin = !rangeList[index + 1]
        ? null
        : rangeList[index + 1].fixMin
        ? rangeList[index + 1].fixMin
        : rangeList[index + 1].fixMax;
      const availableMax = currentRnage.fixMax;

      if (null !== availableMin) returnString += ': ' + availableMin.toString() + ' ~ ';
      if (null !== availableMax) returnString += availableMax.toString();
    }

    this.availableRangeValue = returnString;
  }

  public addNewRange(index: number, layerIndex: number) {
    this.removeInputRangeShowStatus();

    const optionMinValue = _.cloneDeep(this.uiOption.layers[layerIndex].color.minValue);

    const rangeList = this.uiOption.layers[layerIndex].color.ranges;

    const uiMinValue =
      Number(optionMinValue) >= 0
        ? 0
        : Math.floor(Number(optionMinValue) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
          Math.pow(10, this.uiOption.valueFormat.decimal);

    const maxValue = rangeList[index - 1].gt;
    let minValue = rangeList[index].gt ? rangeList[index].gt : uiMinValue;

    minValue = minValue + (maxValue - minValue) / 2;

    const formatMinValue =
      Math.floor(Number(minValue) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
      Math.pow(10, this.uiOption.valueFormat.decimal);
    const formatMaxValue =
      Math.floor(Number(maxValue) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
      Math.pow(10, this.uiOption.valueFormat.decimal);

    rangeList[index].lte = formatMinValue;
    rangeList[index].fixMax = formatMinValue;

    const currentColor = rangeList[index].color;

    rangeList.splice(
      index,
      0,
      UI.Range.colorRange(
        ColorRangeType.SECTION,
        currentColor,
        formatMinValue,
        formatMaxValue,
        formatMinValue,
        formatMaxValue,
      ),
    );

    this.applyLayers();
  }

  public colorPaletteSelected(layerIndex: number, colorCode: string, item?: any) {
    if (this.uiOption.layers[layerIndex].color.by == MapBy.MEASURE) {
      const index = this.rangesViewList.indexOf(item);

      this.uiOption.layers[layerIndex].color.ranges[index].color = colorCode;
    }

    this.applyLayers();
  }

  public removeColorRange(range: ColorRange, index: number, layerIndex: number) {
    const rangeList = this.uiOption.layers[layerIndex].color.ranges;

    if (1 == rangeList.length) return;

    const upperValue = rangeList[index - 1] ? rangeList[index - 1] : null;
    const lowerValue = rangeList[index + 1] ? rangeList[index + 1] : null;

    if (upperValue && lowerValue) {
      const upperMaxValue = rangeList[index - 1].lte ? rangeList[index - 1].lte : rangeList[index - 1].gt;

      const lowerMinValue = rangeList[index + 1].gt ? rangeList[index + 1].gt : rangeList[index + 1].lte;

      const autoChangeValue =
        Math.floor(Number((upperMaxValue + lowerMinValue) / 2) * Math.pow(10, this.uiOption.valueFormat.decimal)) /
        Math.pow(10, this.uiOption.valueFormat.decimal);

      rangeList[index - 1].gt = autoChangeValue;
      rangeList[index - 1].fixMin = autoChangeValue;

      rangeList[index + 1].lte = autoChangeValue;
      rangeList[index + 1].fixMax = autoChangeValue;
    }

    rangeList.splice(index, 1);

    this.applyLayers();
  }

  public equalColorRange(layerIndex: number): void {
    const rangeList = this.uiOption.layers[layerIndex].color.ranges;

    const colorList = <any>_.cloneDeep(ChartColorList[this.uiOption.layers[layerIndex].color['schema']]);

    rangeList.reverse().forEach((item, index) => {
      colorList[index] = item.color;
    });

    if (
      !_.isUndefined(this.uiOption.analysis) &&
      !_.isUndefined(this.uiOption.analysis['use']) &&
      this.uiOption.analysis['use'] == true
    ) {
      if (
        !_.isUndefined(this.uiOption.analysis['includeCompareLayer']) &&
        this.uiOption.analysis['includeCompareLayer'] == true
      ) {
        this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.uiOption,
          this.data[this.uiOption.analysis['layerNum'] + 1],
          colorList,
          layerIndex,
          this.shelf.layers[layerIndex].fields as any,
          rangeList,
        );
      } else {
        this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
          this.uiOption,
          this.data[this.uiOption.analysis['layerNum']],
          colorList,
          layerIndex,
          this.shelf.layers[layerIndex].fields as any,
          rangeList,
        );
      }
    } else {
      this.uiOption.layers[layerIndex].color.ranges = ColorOptionConverter.setMapMeasureColorRange(
        this.uiOption,
        this.data[layerIndex],
        colorList,
        layerIndex,
        this.shelf.layers[layerIndex].fields as any,
        rangeList,
      );
    }

    this.applyLayers();
  }

  public toggleColorPalette(colorListFlag: boolean) {
    this.colorListFlag = colorListFlag;

    this.setZIndex.emit(this.colorListFlag);
  }

  public showMinInputColorRange(item, inputShow: boolean, minElement, index?: number) {
    event.stopPropagation();

    this.removeInputRangeShowStatus();

    item.minInputShow = inputShow;

    if (undefined !== index) {
      this.changeDetect.detectChanges();
      this.availableRange(item, index);
      $(minElement).trigger('focus');
    }
  }

  public showMaxInputColorRange(item, inputShow: boolean, maxElement, index?: number) {
    event.stopPropagation();

    this.removeInputRangeShowStatus();

    item.maxInputShow = inputShow;

    if (undefined !== index) {
      this.changeDetect.detectChanges();
      this.availableRange(item, index);
      $(maxElement).trigger('focus');
    }
  }

  private applyLayers(drawChartParam?: Object) {
    this.uiOption = <UIMapOption>_.extend({}, this.uiOption, {
      layers: this.uiOption.layers,
    });

    this.update(drawChartParam);
  }

  private setByType(shelf: Shelf) {
    this.setMeasureDimensions(shelf);
  }

  private setMeasureDimensions(shelf: Shelf) {
    if (shelf.layers[this.index] == null) return;

    this.fieldList = [];
    let tempList = [];

    for (let index = 0; index < shelf.layers.length; index++) {
      const layers = _.cloneDeep(shelf.layers[index].fields);

      const getShelveReturnField = (shelve: any, typeList?: ShelveFieldType[]): PivotField[] => {
        const resultList: any[] = [];
        resultList.push({
          name: this.translateService.instant('msg.page.layer.map.stroke.none'),
          alias: this.translateService.instant('msg.page.layer.map.stroke.none'),
          value: MapBy.NONE,
        });

        const uiOption = this.uiOption;
        let analysisCountAlias: string;
        let analysisUse = false;
        let useChoropleth = false;
        let isUndefinedAggregationType = false;
        if (
          !_.isUndefined(uiOption['analysis']) &&
          !_.isUndefined(uiOption['analysis']['use']) &&
          uiOption['analysis']['use']
        ) {
          analysisUse = true;
          if (uiOption['analysis']['operation']['choropleth']) {
            useChoropleth = true;
            analysisCountAlias = uiOption['analysis']['operation']['aggregation']['column'];
            _.isUndefined(uiOption['analysis']['operation']['aggregation']['type'])
              ? (isUndefinedAggregationType = true)
              : (isUndefinedAggregationType = false);
          }
        }

        shelve.map((item) => {
          if (analysisUse) {
            if (!_.isUndefined(analysisCountAlias) && _.eq(item.type, ShelveFieldType.MEASURE)) {
              if (
                (!_.isUndefined(item['isCustomField']) && item['isCustomField']) ||
                (!isUndefinedAggregationType && analysisCountAlias == item.name)
              ) {
                item['alias'] = ChartUtil.getAlias(item);
                resultList.push(item);
              }
            } else {
              if (
                !useChoropleth &&
                item.field &&
                ('user_expr' === item.field.type ||
                  (item.field.logicalType && -1 == item.field.logicalType.indexOf('GEO')))
              ) {
                item['alias'] = ChartUtil.getAlias(item);
                resultList.push(item);
              }
            }
          } else {
            if (
              item.field &&
              ('user_expr' === item.field.type ||
                (item.field.logicalType && -1 == item.field.logicalType.indexOf('GEO')))
            ) {
              item['alias'] = ChartUtil.getAlias(item);
              resultList.push(item);
            }
          }
        });
        return resultList;
      };

      const symbolList: any[] = [];
      const layerOption = this.uiOption.layers[this.index];
      if (layerOption.type == MapLayerType.CLUSTER && layerOption['clustering']) {
        symbolList.push({
          name: this.translateService.instant('msg.page.layer.map.stroke.none'),
          alias: this.translateService.instant('msg.page.layer.map.stroke.none'),
          value: MapBy.NONE,
        });

        const defaultObject: any = {
          aggregationType: null,
          alias: 'count',
          type: 'measure',
          subRole: 'measure',
          name: 'count',
          isCustomField: true,
          field: {
            role: FieldRole.MEASURE,
            logicalType: LogicalType.INTEGER,
          },
        };

        symbolList.push(defaultObject);
        this.fieldList.push(symbolList);
        this.fieldMeasureList = symbolList;
        continue;
      }

      tempList = getShelveReturnField(layers);
      this.fieldList.push(tempList);
      this.fieldMeasureList = this.findFieldList(this.fieldList[this.index], 'measure', true);
    }
  }

  private removeAggregationType() {
    const layer = this.shelf.layers[this.index].fields;

    const uniMeasureList = _.uniqBy(layer, 'name');

    for (const item of uniMeasureList) {
      if (item.type === 'measure') {
        delete item.aggregationType;
        item.field.pivot = _.uniq(item.field.pivot);
      }
    }

    this.shelf.layers[this.index].fields = uniMeasureList;
  }

  private addAggregationType() {
    const layer = this.shelf.layers[this.index].fields;

    for (const item of layer) {
      if (item.type === 'measure') {
        item.aggregationType = AggregationType.SUM;
      }
    }
  }

  private setColorByShelf(aggregationFl: boolean, layerIndex: number): UILayers {
    const shelf: GeoField[] = this.shelf.layers[layerIndex].fields;

    const preference = this.checkFieldPreference(shelf, layerIndex);

    const isNone = preference['isNone'];
    const isMeasure = preference['isMeasure'];
    const isDimension = preference['isDimension'];

    const layer: UILayers = this.uiOption.layers[layerIndex];
    const layerType = layer.type;

    const dimensionList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.DIMENSION.toString());
    const measureList = this.findFieldList(this.fieldList[layerIndex], ShelveFieldType.MEASURE.toString());

    if (isNone) {
      layer.color.by = MapBy.NONE;
      if (layerType == MapLayerType.HEATMAP) {
        _.isUndefined(layer.color.heatMapSchema) || layer.color.heatMapSchema.indexOf('HC') == -1
          ? (layer.color.heatMapSchema = 'HC1')
          : layer.color.heatMapSchema;
        layer.color.schema = layer.color.heatMapSchema;
      } else if (layerType == MapLayerType.SYMBOL) {
        _.isUndefined(layer.color.symbolSchema) || layer.color.symbolSchema.indexOf('#') == -1
          ? (layer.color.symbolSchema = '#6344ad')
          : layer.color.symbolSchema;
        layer.color.schema = layer.color.symbolSchema;
      } else if (layerType == MapLayerType.TILE) {
        _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('#') == -1
          ? (layer.color.tileSchema = '#6344ad')
          : layer.color.tileSchema;
        layer.color.schema = layer.color.tileSchema;
      } else if (layerType == MapLayerType.POLYGON) {
        _.isUndefined(layer.color.polygonSchema) || layer.color.polygonSchema.indexOf('#') == -1
          ? (layer.color.polygonSchema = '#6344ad')
          : layer.color.polygonSchema;
        layer.color.schema = layer.color.polygonSchema;
      } else if (layerType == MapLayerType.CLUSTER) {
        _.isUndefined(layer.color.clusterSchema) || layer.color.clusterSchema.indexOf('#') == -1
          ? (layer.color.clusterSchema = '#6344ad')
          : layer.color.clusterSchema;
        layer.color.schema = layer.color.clusterSchema;
      } else {
        layer.color.schema = '#6344ad';
      }
      layer.color.column = null;
      layer.color.aggregationType = null;
    } else if (isMeasure) {
      layer.color.by = MapBy.MEASURE;
      if (layerType == MapLayerType.HEATMAP) {
        _.isUndefined(layer.color.heatMapSchema) || layer.color.heatMapSchema.indexOf('HC') == -1
          ? (layer.color.heatMapSchema = 'HC1')
          : layer.color.heatMapSchema;
        layer.color.schema = layer.color.heatMapSchema;
      } else if (layerType == MapLayerType.SYMBOL) {
        _.isUndefined(layer.color.symbolSchema) || layer.color.symbolSchema.indexOf('VC') == -1
          ? (layer.color.symbolSchema = 'VC1')
          : layer.color.symbolSchema;
        layer.color.schema = layer.color.symbolSchema;
      } else if (layerType == MapLayerType.TILE) {
        _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('VC') == -1
          ? (layer.color.tileSchema = 'VC1')
          : layer.color.tileSchema;
        layer.color.schema = layer.color.tileSchema;
      } else if (layerType == MapLayerType.POLYGON) {
        _.isUndefined(layer.color.polygonSchema) || layer.color.polygonSchema.indexOf('VC') == -1
          ? (layer.color.polygonSchema = 'VC1')
          : layer.color.polygonSchema;
        layer.color.schema = layer.color.polygonSchema;
      } else if (layerType == MapLayerType.CLUSTER) {
        _.isUndefined(layer.color.clusterSchema) || layer.color.clusterSchema.indexOf('VC') == -1
          ? (layer.color.clusterSchema = 'VC1')
          : layer.color.clusterSchema;
        layer.color.schema = layer.color.clusterSchema;
      } else {
        layer.color.schema = 'VC1';
      }
      layer.color.column = measureList[0]['name'];
      if (aggregationFl) layer.color.aggregationType = measureList[0]['aggregationType'];
      else layer.color.aggregationType = null;
    } else if (MapLayerType.TILE === layerType && isDimension) {
      layer.color.by = MapBy.NONE;
      _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('#') == -1
        ? (layer.color.tileSchema = '#6344ad')
        : layer.color.tileSchema;
      layer.color.schema = layer.color.tileSchema;
      layer.color.column = null;
      layer.color.aggregationType = null;
    } else if (isDimension) {
      layer.color.by = MapBy.DIMENSION;
      if (layerType == MapLayerType.SYMBOL) {
        _.isUndefined(layer.color.symbolSchema) || layer.color.symbolSchema.indexOf('SC') == -1
          ? (layer.color.symbolSchema = 'SC1')
          : layer.color.symbolSchema;
        layer.color.schema = layer.color.symbolSchema;
      } else if (layerType == MapLayerType.TILE) {
        _.isUndefined(layer.color.tileSchema) || layer.color.tileSchema.indexOf('SC') == -1
          ? (layer.color.tileSchema = 'SC1')
          : layer.color.tileSchema;
        layer.color.schema = layer.color.tileSchema;
      } else if (layerType == MapLayerType.POLYGON) {
        _.isUndefined(layer.color.polygonSchema) || layer.color.polygonSchema.indexOf('SC') == -1
          ? (layer.color.polygonSchema = 'SC1')
          : layer.color.polygonSchema;
        layer.color.schema = layer.color.polygonSchema;
      } else if (layerType == MapLayerType.CLUSTER) {
        _.isUndefined(layer.color.clusterSchema) || layer.color.clusterSchema.indexOf('SC') == -1
          ? (layer.color.clusterSchema = 'SC1')
          : layer.color.clusterSchema;
        layer.color.schema = layer.color.clusterSchema;
      } else {
        layer.color.schema = 'SC1';
      }
      layer.color.column = dimensionList[0]['name'];
      layer.color.aggregationType = null;
      if (dimensionList[0]['format']) layer.color.granularity = dimensionList[0]['format']['unit'].toString();
    }

    return layer;
  }

  private checkFieldPreference(layers: GeoField[], layerIndex: number): Object {
    let isNone = true;
    let isDimension = false;
    let isMeasure = false;
    _.each(layers, (field) => {
      if (
        'user_expr' === field.field.type ||
        (field.field.logicalType && field.field.logicalType.toString().indexOf('GEO') == -1)
      ) {
        isNone = false;
      }

      if (
        ('user_expr' === field.field.type ||
          (field.field.logicalType && field.field.logicalType.toString().indexOf('GEO') == -1)) &&
        _.eq(field.type, ShelveFieldType.DIMENSION)
      ) {
        isDimension = true;
      }
      if (_.eq(field.type, ShelveFieldType.MEASURE)) {
        isMeasure = true;
      }
    });

    if (this.uiOption.layers[layerIndex].type == MapLayerType.CLUSTER) {
      isMeasure = true;
      isNone = false;
      isDimension = false;
    }

    return { isNone: isNone, isDimension: isDimension, isMeasure: isMeasure };
  }

  private setRangeViewByDecimal(ranges: ColorRange[]) {
    if (!ranges || 0 == ranges.length) return;

    const decimal = this.uiOption.valueFormat != null ? this.uiOption.valueFormat.decimal : 0;

    const commaUseFl = this.uiOption.valueFormat != null ? this.uiOption.valueFormat.useThousandsSep : false;

    const returnList: any = _.cloneDeep(ranges);

    for (const item of returnList) {
      item['fixMax'] =
        null == item.fixMax ? null : <any>FormatOptionConverter.getDecimalValue(item.fixMax, decimal, commaUseFl);
      item['fixMin'] =
        null == item.fixMin ? null : <any>FormatOptionConverter.getDecimalValue(item.fixMin, decimal, commaUseFl);
      item['gt'] = null == item.gt ? null : <any>FormatOptionConverter.getDecimalValue(item.gt, decimal, commaUseFl);
      item['lte'] = null == item.lte ? null : <any>FormatOptionConverter.getDecimalValue(item.lte, decimal, commaUseFl);
    }

    return returnList;
  }

  private parseStrFloat(range: any): any {
    range.fixMax = null == range.fixMax ? null : FormatOptionConverter.getNumberValue(range.fixMax);
    range.fixMin = null == range.fixMin ? null : FormatOptionConverter.getNumberValue(range.fixMin);
    range.gt = null == range.gt ? null : FormatOptionConverter.getNumberValue(range.gt);
    range.lte = null == range.lte ? null : FormatOptionConverter.getNumberValue(range.lte);
    return range;
  }

  private initOptionSymbolLayer(layerIndex: number) {
    this.uiOption.layers[layerIndex].color.ranges = undefined;

    this.uiOption.layers[layerIndex].color['settingUseFl'] = false;

    this.uiOption.legend.auto = true;
  }

  private getLayerIndex() {
    this.rnbMenu.indexOf('1') != -1 ? (this.index = 0) : (this.index = Number(this.rnbMenu.split('mapLayer')[1]) - 1);
  }

  public analysisVisibilityBtn() {
    this.uiOption['analysis']['includeCompareLayer'] = !this.uiOption['analysis']['includeCompareLayer'];
    this.applyLayers({ type: EventType.MAP_CHANGE_OPTION });
  }

  private isNumberRegex(value: any): boolean {
    if (value.indexOf(',') != -1) {
      value = value.replace(/,/g, '');
    }
    return !isNaN(Number(value)); // Number(value) !== NaN;
  }

  private removeInputRangeShowStatus() {
    _.each(this.rangesViewList, (item) => {
      if (item['minInputShow']) delete item['minInputShow'];
      if (item['maxInputShow']) delete item['maxInputShow'];
    });
    if (
      !_.isUndefined(this.uiOption.layers[this.index].color.ranges) &&
      this.uiOption.layers[this.index].color.ranges.length > 0
    ) {
      this.uiOption.layers[this.index].color.ranges.forEach((uiItem) => {
        if (uiItem['minInputShow']) delete uiItem['minInputShow'];
        if (uiItem['maxInputShow']) delete uiItem['maxInputShow'];
      });
    }
  }

  private setClusterOption() {
    if (
      !_.isUndefined(this.uiOption) &&
      !_.isUndefined(this.uiOption['layers']) &&
      this.uiOption['layers'].length > 0
    ) {
      for (let layerIndex = 0; this.uiOption['layers'].length > layerIndex; layerIndex++) {
        if (
          this.uiOption['layers'][layerIndex]['type'] == MapLayerType.SYMBOL &&
          !_.isUndefined(this.uiOption['layers'][layerIndex]['clustering']) &&
          this.uiOption['layers'][layerIndex]['clustering']
        ) {
          this.uiOption['layers'][layerIndex]['type'] = MapLayerType.CLUSTER;
        }
      }
    }
  }

  private setPointOption() {
    if (
      !_.isUndefined(this.uiOption) &&
      !_.isUndefined(this.uiOption['layers']) &&
      this.uiOption['layers'].length > 0
    ) {
      for (let layerIndex = 0; this.uiOption['layers'].length > layerIndex; layerIndex++) {
        if (this.uiOption['layers'][layerIndex]['type'] == MapLayerType.SYMBOL) {
          if (isNullOrUndefined(this.uiOption.layers[layerIndex]['pointRadiusFrom'])) {
            this.uiOption.layers[layerIndex]['pointRadiusFrom'] = 5;
            this.uiOption.layers[layerIndex].pointRadius = 5;
          }
          const hasMeasure = this.shelf.layers[layerIndex].fields.find((field) => {
            return field.type == 'measure';
          });

          if (
            this.shelf['layers'][layerIndex].fields.length <= 1 ||
            (this.shelf['layers'][layerIndex].fields.length > 1 &&
              isNullOrUndefined(this.uiOption.layers[layerIndex]['pointRadiusTo']) &&
              isNullOrUndefined(hasMeasure))
          ) {
            delete this.uiOption.layers[layerIndex]['needToCalPointRadius'];
            delete this.uiOption.layers[layerIndex]['pointRadiusTo'];
            delete this.uiOption.layers[layerIndex]['pointRadiusCal'];
            this.uiOption.layers[layerIndex].pointRadius = this.uiOption.layers[layerIndex]['pointRadiusFrom'];
          } else if (this.shelf['layers'][layerIndex].fields.length > 1 && hasMeasure) {
            if (isNullOrUndefined(this.uiOption.layers[layerIndex]['pointRadiusTo'])) {
              if (this.uiOption.layers[layerIndex]['pointRadiusFrom'] < 100) {
                this.uiOption.layers[layerIndex]['pointRadiusTo'] = 20;
              } else {
                this.uiOption.layers[layerIndex]['pointRadiusTo'] = 200;
              }
            }
            this.uiOption.layers[layerIndex]['needToCalPointRadius'] = true;
            this.setMinMaxMeasureValue(this.shelf, hasMeasure, this.data, layerIndex, false);
          }
        } else {
          this.resetPointRadius(layerIndex);
        }
      }
    }
  }

  private resetPointRadius(layerIndex: number) {
    this.uiOption.layers[layerIndex].pointRadius = 5;
    this.uiOption.layers[layerIndex]['pointRadiusFrom'] = 5;
    delete this.uiOption.layers[layerIndex]['needToCalPointRadius'];
    delete this.uiOption.layers[layerIndex]['pointRadiusTo'];
    delete this.uiOption.layers[layerIndex]['pointRadiusCal'];
  }

  private setMinMaxMeasureValue(
    shelf: Shelf,
    selectedField: PivotField,
    data: any,
    layerIndex: number,
    isSet: boolean,
  ) {
    if (isSet) {
      let alias: string = null;
      shelf.layers.forEach((layer) => {
        layer.fields.forEach((field) => {
          if (field.field.id == selectedField.field.id) {
            alias = selectedField.field.name;
          }
        });
      });
      let minValue = 0;
      let maxValue = 0;

      const dataIndex =
        !isNullOrUndefined(this.uiOption.analysis) && this.uiOption.analysis.use == true
          ? this.uiOption.analysis.layerNum
          : layerIndex;
      if (!_.isUndefined(data[dataIndex].valueRange) && !_.isUndefined(data[dataIndex].valueRange[alias])) {
        minValue = _.cloneDeep(data[dataIndex].valueRange[alias].minValue);
        maxValue = _.cloneDeep(data[dataIndex].valueRange[alias].maxValue);
      }
      this.uiOption.layers[layerIndex]['size'].minValue = minValue;
      this.uiOption.layers[layerIndex]['size'].maxValue = maxValue;
    } else {
      delete this.uiOption.layers[layerIndex]['size'].minValue;
      delete this.uiOption.layers[layerIndex]['size'].maxValue;
    }
  }
}
