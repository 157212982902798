<li class="layout-submenu-header">
  <h6 class="header-text"><strong>{{ 'shell.settings.settings' | translate}}</strong></h6>
</li>
<li role="menuitem" class="flex flex-column align-items-start">
  <h6 class="header-text">{{ 'shell.settings.menu-mode' | translate}}</h6>
  <div [formGroup]="menuModeForm" class="flex justify-content-between">
    <div class="flex align-items-center mr-3">
      <p-radioButton
        name="menuMode"
        value="{{ menuModes.VERTICAL }}"
        formControlName="menuMode"
        inputId="menuMode1"
      ></p-radioButton>
      <label for="menuMode1" class="ml-2">{{ 'shell.settings.menu-mode-vertical' | translate }}</label>
    </div>
    <div class="flex align-items-center">
      <p-radioButton
        name="menuMode"
        value="{{ menuModes.HORIZONTAL }}"
        formControlName="menuMode"
        inputId="menuMode2"
      ></p-radioButton>
      <label for="menuMode2" class="ml-2">{{ 'shell.settings.menu-mode-horizontal' | translate }}</label>
    </div>
  </div>
</li>
<li role="menuitem" class="flex flex-column align-items-start">
  <h6 class="header-text">{{ 'shell.settings.language' | translate }}</h6>
  <div [formGroup]="selectLaguageForm" class="flex" *ngIf="(languages | async) as languageObservable">
    <p-dropdown 
      class="w-full"
      formControlName="currentLanguage" 
      [options]="languageObservable"
      optionLabel="label"
      optionValue="value"
    >
    </p-dropdown>
   </div>
</li>
