import { Component, ElementRef, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import { LabelOptionConverter } from '@selfai-platform/bi-chart-engine';
import {
  BarMarkType,
  ChartType,
  DataLabelPosition,
  Pivot,
  UIOption,
  UIOrient,
  UIPosition,
  UiChartDataLabelDisplayType,
} from '@selfai-platform/bi-domain';

import { LabelBaseOptionComponent } from './labelbase-option.component';

@Component({
  selector: 'datalabel-option',
  templateUrl: './datalabel-option.component.html',
})
export class DataLabelOptionComponent extends LabelBaseOptionComponent {
  public pivot: Pivot;

  public positionBarVerticalList: Object[] = [
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.outside.top'),
      value: DataLabelPosition.OUTSIDE_TOP,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.top'),
      value: DataLabelPosition.INSIDE_TOP,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.bottom'),
      value: DataLabelPosition.INSIDE_BOTTOM,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.center'),
      value: DataLabelPosition.CENTER,
    },
  ];

  public positionBarHorizontalList: Object[] = [
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.outside.right'),
      value: DataLabelPosition.OUTSIDE_RIGHT,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.right'),
      value: DataLabelPosition.INSIDE_RIGHT,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.left'),
      value: DataLabelPosition.INSIDE_LEFT,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.center'),
      value: DataLabelPosition.CENTER,
    },
  ];

  public positionGaugeList: Object[] = [
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.top'),
      value: DataLabelPosition.INSIDE_TOP,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.bottom'),
      value: DataLabelPosition.INSIDE_BOTTOM,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.center'),
      value: DataLabelPosition.CENTER,
    },
  ];

  public positionGaugeHorizontalList: Object[] = [
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.right'),
      value: DataLabelPosition.INSIDE_RIGHT,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.left'),
      value: DataLabelPosition.INSIDE_LEFT,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.inside.center'),
      value: DataLabelPosition.CENTER,
    },
  ];

  public positionLineVerticalList: Object[] = [
    { name: this.translateService.instant('msg.page.chart.datalabel.position.top'), value: DataLabelPosition.TOP },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.bottom'),
      value: DataLabelPosition.BOTTOM,
    },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.center'),
      value: DataLabelPosition.CENTER,
    },
  ];

  public positionLineHorizontalList: Object[] = [
    { name: this.translateService.instant('msg.page.chart.datalabel.position.right'), value: DataLabelPosition.RIGHT },
    { name: this.translateService.instant('msg.page.chart.datalabel.position.left'), value: DataLabelPosition.LEFT },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.center'),
      value: DataLabelPosition.CENTER,
    },
  ];

  public positionBoxList: Object[] = [
    { name: this.translateService.instant('msg.page.chart.datalabel.position.top'), value: DataLabelPosition.TOP },
    {
      name: this.translateService.instant('msg.page.chart.datalabel.position.bottom'),
      value: DataLabelPosition.BOTTOM,
    },
  ];

  public displayTypes: UiChartDataLabelDisplayType[] = [];

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    if (!uiOption.dataLabel) {
      uiOption.dataLabel = {};
      uiOption.dataLabel.showValue = false;
    }

    if (true == uiOption.dataLabel.showValue && !uiOption.dataLabel.displayTypes) {
      uiOption.dataLabel.displayTypes = this.setDisplayTypes(uiOption.type);
    }

    if (!uiOption.dataLabel.pos) {
      const positionList = this.getPositionList(uiOption);
      if (positionList && positionList.length > 0)
        uiOption.dataLabel.pos = positionList[positionList.length - 1]['value'];
    }

    if (ChartType.TREEMAP == uiOption.type && !uiOption.dataLabel.hAlign) {
      uiOption.dataLabel.hAlign = UIPosition.CENTER;
    }
    if (ChartType.TREEMAP == uiOption.type && !uiOption.dataLabel.vAlign) {
      uiOption.dataLabel.vAlign = UIPosition.CENTER;
    }

    uiOption.dataLabel.previewList = LabelOptionConverter.setDataLabelPreviewList(uiOption);

    if (uiOption.dataLabel && uiOption.dataLabel.displayTypes) {
      this.displayTypes = _.cloneDeep(uiOption.dataLabel.displayTypes.filter(Boolean));
    }

    if (typeof uiOption.dataLabel.useDefaultFormat === 'undefined') uiOption.dataLabel.useDefaultFormat = true;

    this.uiOption = uiOption;
  }

  @Input('pivot')
  public set setPivot(pivot: Pivot) {
    this.pivot = pivot;

    if (ChartType.BAR == this.uiOption.type && BarMarkType.STACKED == this.uiOption['mark']) {
      const positionList = this.getPositionList(this.uiOption);
      if (positionList && positionList.length > 0)
        this.uiOption.dataLabel.pos = positionList[positionList.length - 1]['value'];
    }
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public showValueLabel(show: boolean): void {
    this.uiOption.dataLabel.showValue = show;

    if (true == this.uiOption.dataLabel.showValue) {
      if (!this.uiOption.dataLabel.displayTypes) {
        this.uiOption.dataLabel.displayTypes = this.setDisplayTypes(this.uiOption.type);

        this.displayTypes = _.cloneDeep(this.uiOption.dataLabel.displayTypes.filter(Boolean));
      }
    } else {
      this.uiOption.dataLabel.displayTypes = this.setDisplayTypes(this.uiOption.type);
      this.displayTypes = _.cloneDeep(this.uiOption.dataLabel.displayTypes.filter(Boolean));
    }
    this.apply();
  }

  public toggleDisplayType(displayType: UiChartDataLabelDisplayType, typeIndex: number): void {
    const displayTypes = this.uiOption.dataLabel.displayTypes ? [...this.uiOption.dataLabel.displayTypes] : [];

    let isFind = false;
    _.each(displayTypes, (type, index) => {
      if (_.eq(type, displayType)) {
        isFind = true;

        displayTypes[index] = null;
      }
    });

    if (!isFind) {
      displayTypes[typeIndex] = displayType;
    }

    this.displayTypes = _.cloneDeep(displayTypes.filter(Boolean));
    this.uiOption.dataLabel.displayTypes = displayTypes;

    this.uiOption.dataLabel.previewList = LabelOptionConverter.setDataLabelPreviewList(this.uiOption);

    this.apply();
  }

  public getPositionList(uiOption: UIOption, chartSecondType?: ChartType): Object[] {
    if (!uiOption) return;

    if (_.eq(uiOption.type, ChartType.BAR)) {
      if (_.eq(uiOption['align'], UIOrient.HORIZONTAL)) {
        return BarMarkType.STACKED == uiOption['mark']
          ? this.positionGaugeHorizontalList
          : this.positionBarHorizontalList;
      } else {
        return BarMarkType.STACKED == uiOption['mark'] ? this.positionGaugeList : this.positionBarVerticalList;
      }
    } else if (
      _.eq(uiOption.type, ChartType.WATERFALL) ||
      (_.eq(uiOption.type, ChartType.COMBINE) && _.eq(chartSecondType, ChartType.BAR))
    ) {
      if (_.eq(uiOption['align'], UIOrient.HORIZONTAL)) {
        return this.positionBarHorizontalList;
      } else {
        return this.positionBarVerticalList;
      }
    } else if (
      _.eq(uiOption.type, ChartType.LINE) ||
      _.eq(uiOption.type, ChartType.SCATTER) ||
      _.eq(uiOption.type, ChartType.SCATTER) ||
      _.eq(uiOption.type, ChartType.COMBINE) ||
      _.eq(uiOption.type, ChartType.RADAR) ||
      _.eq(uiOption.type, ChartType.GRAPH) ||
      _.eq(uiOption.type, ChartType.GRAPHV2) ||
      _.eq(uiOption.type, ChartType.CONTROL)
    ) {
      if (_.eq(uiOption['align'], UIOrient.HORIZONTAL)) {
        return this.positionLineHorizontalList;
      } else {
        return this.positionLineVerticalList;
      }
    } else if (_.eq(uiOption.type, ChartType.BOXPLOT)) {
      return this.positionBoxList;
    } else if (_.eq(uiOption.type, ChartType.GAUGE)) {
      return this.positionGaugeList;
    }

    return [];
  }

  public getPositionIndex(pos: DataLabelPosition): number {
    let index = 0;

    const positionList: Object[] = this.getPositionList(this.uiOption);

    _.each(positionList, (item, idx) => {
      if (_.eq(item['value'], pos)) {
        index = idx;
        return;
      }
    });

    return index;
  }

  public changePosition(position: Object): void {
    this.uiOption.dataLabel.pos = position['value'];
    this.apply();
  }

  public changeLinePosition(position: Object): void {
    this.uiOption.dataLabel.secondaryPos = position['value'];
    this.apply();
  }

  public changeRotation(lotation: boolean): void {
    this.uiOption.dataLabel.enableRotation = lotation;
    this.apply();
  }

  public changeBackgroundColor(): void {
    if (!this.uiOption.dataLabel.textBackgroundColor) {
      this.uiOption.dataLabel.textBackgroundColor = '#000000';
    } else {
      delete this.uiOption.dataLabel.textBackgroundColor;
    }

    this.apply();
  }

  public changeTextOutlineColor(): void {
    if (!this.uiOption.dataLabel.textOutlineColor) {
      this.uiOption.dataLabel.textOutlineColor = '#000000';
    } else {
      delete this.uiOption.dataLabel.textOutlineColor;
    }

    this.apply();
  }

  public changeTextAlign(align: UIPosition): void {
    if (this.uiOption['dataLabel']['showOutside']) return;

    this.uiOption.dataLabel.textAlign = align;
    this.apply();
  }

  public changeUseDefaultFormat(): void {
    this.uiOption.dataLabel.useDefaultFormat = !this.uiOption.dataLabel.useDefaultFormat;
    this.apply();
  }

  public apply(): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { dataLabel: this.uiOption.dataLabel });
    this.update();
  }

  public toggleOutsideLabel(showOutside: boolean): void {
    const dataLabel = this.uiOption.dataLabel;

    dataLabel.showOutside = showOutside;

    delete dataLabel.textAlign;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { dataLabel: this.uiOption.dataLabel });
    this.update();
  }

  public showColorSetting(): void {
    if (this.uiOption.dataLabel && this.uiOption.dataLabel.textColor) {
      delete this.uiOption.dataLabel.textColor;
      delete this.uiOption.dataLabel.textBackgroundColor;
      delete this.uiOption.dataLabel.textOutlineColor;
    } else {
      this.uiOption.dataLabel.textColor = '#FFFFFF';
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { dataLabel: this.uiOption.dataLabel });
    this.update();
  }

  public changeHAlign(hAlign: UIPosition): void {
    this.uiOption.dataLabel.hAlign = hAlign;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { dataLabel: this.uiOption.dataLabel });
    this.update();
  }

  public changeVAlign(vAlign: UIPosition): void {
    this.uiOption.dataLabel.vAlign = vAlign;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { dataLabel: this.uiOption.dataLabel });
    this.update();
  }

  private setDisplayTypes(chartType: ChartType): UiChartDataLabelDisplayType[] {
    const displayTypes = [];

    switch (chartType) {
      case ChartType.BAR:
      case ChartType.LINE:
      case ChartType.COMBINE:
        if (
          (chartType === ChartType.BAR && this.pivot.aggregations.length <= 1 && this.pivot.rows.length < 1) ||
          ((chartType === ChartType.LINE || chartType === ChartType.COMBINE) && this.pivot.aggregations.length <= 1)
        ) {
          displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
          displayTypes[1] = UiChartDataLabelDisplayType.CATEGORY_VALUE;
        } else {
          displayTypes[3] = UiChartDataLabelDisplayType.SERIES_NAME;
          displayTypes[4] = UiChartDataLabelDisplayType.SERIES_VALUE;
        }
        break;
      case ChartType.CONTROL:
      case ChartType.WATERFALL:
        displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        displayTypes[1] = UiChartDataLabelDisplayType.CATEGORY_VALUE;
        break;
      case ChartType.HEATMAP:
      case ChartType.GAUGE:
        displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        break;
      case ChartType.SCATTER:
      case ChartType.PIE:
      case ChartType.TREEMAP:
        displayTypes[3] = UiChartDataLabelDisplayType.SERIES_NAME;
        break;
      case ChartType.RADAR:
        displayTypes[8] = UiChartDataLabelDisplayType.VALUE;
        break;
      case ChartType.SANKEY:
      case ChartType.GRAPH:
      case ChartType.GRAPHV2:
        displayTypes[9] = UiChartDataLabelDisplayType.NODE_NAME;
        break;
    }

    return displayTypes;
  }
}
