import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { DataconnectionApiModel, DataconnectionResponse, DataconnectionSchemaApiModel } from '../models';

@Injectable()
export class DataconnectionApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  checkConnection(params: DataconnectionApiModel): Observable<DataconnectionResponse> {
    const url = this.kdBackendApiService.buildPath('connections/query/check');

    return this.kdBackendApiService.post(url, params);
  }

  loadDataSchema(
    request: { type: string; query: string } & DataconnectionApiModel,
  ): Observable<DataconnectionSchemaApiModel> {
    const url = this.kdBackendApiService.buildPath('connections/query/data');

    return this.kdBackendApiService.post(url, request);
  }
}
