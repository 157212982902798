import { Component, ElementRef, EventEmitter, Injector, Input, Output, ViewChild } from '@angular/core';

import { CommonUtil } from '../../util/common.util';
import { StringUtil } from '../../util/string.util';
import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'selfai-bi-filter-select-box',
  templateUrl: 'filter-select-box.component.html',
  host: {
    '(document:click)': 'onClickHost($event)',
  },
})
export class FilterSelectBoxComponent extends AbstractComponent {
  @Output('changedFilter')
  private readonly _changedFilter = new EventEmitter();

  @ViewChild('wrapElement', { static: true })
  private readonly WRAP_ELEMENT: ElementRef;
  @ViewChild('listElement')
  private readonly LIST_ELEMENT: ElementRef;

  @Input()
  public readonly filterList;

  @Input()
  public readonly isEnableIcon: boolean;

  @Input()
  public readonly isDisableList: boolean;

  @Input()
  public readonly isOnlyStringList: boolean;

  @Input()
  public readonly isEnableFloating: boolean;

  @Input()
  public selectedFilter: any;

  public isListShow: boolean;

  public readonly UUID = CommonUtil.getUUID();
  public readonly SELECT_BOX_WRAP_ELEMENT = this.UUID + '-select-box-wrap-elm';
  public readonly SELECT_BOX_LABEL_ELEMENT = this.UUID + '-select-box-label-elm';
  public readonly SELECT_BOX_ICON_WRAP_ELEMENT = this.UUID + '-select-box-icon-wrap-elm';
  public readonly SELECT_BOX_ICON_ELEMENT = this.UUID + '-select-box-icon-elm';

  constructor(protected element: ElementRef, protected injector: Injector) {
    super(element, injector);
  }

  isNotEmptyValue(value): boolean {
    return StringUtil.isNotEmpty(value);
  }

  public onClickHost(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isListShow = false;
    }
  }

  public onChangedFilter(filter): void {
    this.selectedFilter = filter;

    this.isListShow = false;

    this._changedFilter.emit(filter);
  }

  public onChangeListShow(event: MouseEvent): void {
    const targetElement = event.target['classList'];

    if (
      !this.isDisableList &&
      (targetElement.contains(this.SELECT_BOX_WRAP_ELEMENT) ||
        targetElement.contains(this.SELECT_BOX_LABEL_ELEMENT) ||
        targetElement.contains(this.SELECT_BOX_ICON_WRAP_ELEMENT) ||
        targetElement.contains(this.SELECT_BOX_ICON_ELEMENT))
    ) {
      this.isListShow = !this.isListShow;

      if (this.isListShow && this.isEnableFloating) {
        $(this.LIST_ELEMENT.nativeElement).css({
          position: 'fixed',
          top: $(event.currentTarget).offset().top + 35,
          left: $(event.currentTarget).offset().left,
          width: $(event.currentTarget).outerWidth(),
        });
      }
    }
  }
}
