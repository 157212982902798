<p-card [formGroup]="form">
  <selfai-platform-form-field label="Attribute name">
    <selfai-platform-edit-selection-field formControlName="attrColumnName"></selfai-platform-edit-selection-field>
  </selfai-platform-form-field>
  <selfai-platform-form-field label="Condition">
    <p-dropdown
      appendTo="body"
      [options]="conditionItems"
      formControlName="condition"
      placeholder="Choose condition type"
    ></p-dropdown>
    <div class="mt-3">
      <ng-container [ngSwitch]="form.controls.condition.value">
        <ng-container
          *ngSwitchCase="conditionType.BEGINS_FROM"
          [ngTemplateOutlet]="beginFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.NOT_BEGINS_FROM"
          [ngTemplateOutlet]="beginFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.ENDS_WITH"
          [ngTemplateOutlet]="endWithFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.NOT_ENDS_WITH"
          [ngTemplateOutlet]="endWithFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.CONTAINS"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.LESS_EQUALS_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.LESS_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.MORE_EQUALS_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.MORE_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.REGEXP"
          [ngTemplateOutlet]="regExp"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.EQUALS_TO"
          [ngTemplateOutlet]="equalTo"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.NOT_EQUALS_TO"
          [ngTemplateOutlet]="equalTo"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.BETWEEN"
          [ngTemplateOutlet]="between"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="conditionType.EXPRESSION"
          [ngTemplateOutlet]="expression"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
      </ng-container>
    </div>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="Is primary condition">
    <p-checkbox class="mt-2" name="input" formControlName="primary" [binary]="true"></p-checkbox>
  </selfai-platform-form-field>
  <selfai-platform-form-field label="Result column (optional)">
    <input type="text" pInputText class="p-inputtext-sm w-full" name="resultColumn" formControlName="resultColumn" />
  </selfai-platform-form-field>
</p-card>

<ng-template #beginFrom let-form="form">
  <ng-container [formGroup]="form" ]>
    <selfai-platform-form-field label="Start position">
      <p-inputNumber formControlName="startPosition" mode="decimal"></p-inputNumber>
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Value collection">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #endWithFrom let-form="form">
  <ng-container [formGroup]="form" ]>
    <selfai-platform-form-field label="Value collection">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #compareValue let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Compare value">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #regExp let-form="form">
  <ng-container [formGroup]="form" ]>
    <selfai-platform-form-field label="RegExp pattern">
      <selfai-platform-code-editor
        [language]="codeLanguage.REGEXP"
        formControlName="value"
      ></selfai-platform-code-editor>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #equalTo let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Case option">
      <div class="field-checkbox">
        <p-checkbox
          name="ignoreCase"
          class="mt-2"
          [binary]="true"
          formControlName="ignoreCase"
          inputId="ignoreCase"
        ></p-checkbox>
        <label for="ignoreCase">Ignore case</label>
      </div>
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Value collection">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #filterValueControl let-form="form">
  <ng-container [formGroup]="form">
    <input type="text" pInputText class="p-inputtext-sm w-full" formControlName="value" />
  </ng-container>
</ng-template>

<ng-template #between let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Start of range">
      <input type="text" pInputText class="p-inputtext-sm w-full" formControlName="startRange" />
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Include start of range">
      <p-checkbox name="input" formControlName="includeStartRange" [binary]="true"></p-checkbox>
    </selfai-platform-form-field>
    <selfai-platform-form-field label="End of range">
      <input type="text" pInputText class="p-inputtext-sm w-full" formControlName="endRange" />
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Include end of range">
      <p-checkbox name="input" formControlName="includeEndRange" [binary]="true"></p-checkbox>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #expression let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-code-editor [language]="codeLanguage.SQL" formControlName="value"></selfai-platform-code-editor>
  </ng-container>
</ng-template>
