import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Dashboard, TextWidget, createTextWidget } from '@selfai-platform/bi-domain';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';

@Component({
  selector: 'app-update-text',
  templateUrl: './text-widget.update.component.html',
  styleUrls: ['./text-widget.component.css'],
})
export class TextWidgetUpdateComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  public isShow = false;

  public widget: TextWidget;

  @Input('widget')
  set setWidget(widget: TextWidget) {
    this.widget = widget;
  }

  @Input()
  public readonly = false;

  @Input()
  public placeholder = '';

  @Input()
  public dashboard: Dashboard;

  @Output()
  public changedWidget: EventEmitter<{ name: string; widget: TextWidget }> = new EventEmitter();

  form = new FormGroup({
    editor: new FormControl(''),
  });

  public get editorOptions(): object {
    const editorOptions = {
      placeholder: this.placeholder,
      readOnly: this.readonly,
      bounds: this.elementRef.nativeElement,
    };

    if (this.readonly) {
      const disableToolbar = {
        modules: {
          toolbar: false,
        },
      };

      Object.assign(editorOptions, disableToolbar);
    }

    return editorOptions;
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.widget) {
      this.form.get('editor').setValue(this.widget.configuration['contents']);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public editorCreated(): void {
    this.isShow = true;
  }

  public done(): void {
    if (this.readonly === false) {
      let publishInfo: { name: string; widget: TextWidget };

      const html = this.form.get('editor').value || ''

      if (this.widget) {
        this.widget.configuration['contents'] = html;
        publishInfo = {
          name: 'UPDATE',
          widget: this.widget,
        };
      } else {
        publishInfo = {
          name: 'CREATE',
          widget: createTextWidget(html, this.dashboard.id),
        };
      }

      this.changedWidget.emit(publishInfo);

      this.isShow = false;
    }
  }
}
