import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import {
  BreadcrumbsMenuItem,
  BreadcrumbsMenuService,
  KE_DATASOURCES_PATH,
  KE_ROOT_ROUTE,
} from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { catchError, take, throwError } from 'rxjs';
import { getDataSourceCreateRoute, getDataSourceEditRoute } from '../../../utils';
import { DataSource } from '../../model/data-source.model';
import { DataSourcesAdditions } from '../../model/data-sources-additions.model';
import { DataSourceAdditionsDomainService } from '../../services';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourcesDbComponent } from '../data-sources-db/data-sources-db.component';
import { DataSourcesFileComponent } from '../data-sources-file/data-sources-file.component';
import { DataSourcesGoogleSpreadsheetComponent } from '../data-sources-google-spreadsheet/data-sources-google-spreadsheet.component';
import { DataSourcesHdfsComponent } from '../data-sources-hdfs/data-sources-hdfs.component';
import { DataSourcesKafkaComponent } from '../data-sources-kafka/data-sources-kafka.component';
import { DataSourcesLibraryComponent } from '../data-sources-library/data-sources-library.component';
import { DataSourcesS3Component } from '../data-sources-s3/data-sources-s3.component';
import { DataSourceComponentService } from './data-sources-component.service';
import { DataSourceFormService } from './data-sources-form.service';

@Component({
  selector: 'selfai-platform-data-sources',
  standalone: true,
  imports: [
    CommonModule,
    ToolbarModule,
    ButtonModule,
    CardModule,
    DataSourcesDbComponent,
    DataSourcesFileComponent,
    DataSourcesGoogleSpreadsheetComponent,
    DataSourcesHdfsComponent,
    DataSourcesKafkaComponent,
    DataSourcesS3Component,
    DataSourcesLibraryComponent,
    ToastModule,
    DataSourcesAdditionsComponent,
  ],
  templateUrl: './data-sources.component.html',
  styleUrls: ['./data-sources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataSourceComponentService, DataSourceFormService],
})
export class DataSourcesComponent implements OnChanges, OnInit {
  get templateType(): DatasourceType | undefined {
    return this.dataSource?.params?.datasourceType || this.datasourceType;
  }

  id: string = this.activatedRoute?.snapshot?.params['id'];
  editMode = !!this.id;
  breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.data-sources'),
      routerLink: ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH],
    },
  ];

  form = this.dataSourceFormService.form;

  @Input() dataSource: DataSource;
  @Input() dataSourceList: DataSource[];
  @Input() datasourceType: DatasourceType;
  @Input() additionalList: DataSourcesAdditions[];
  @Output() backEvent = new EventEmitter<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private breadcrumbsMenuService: BreadcrumbsMenuService,
    private dataSourceAdditionsDomainService: DataSourceAdditionsDomainService,
    private dataSourceComponentService: DataSourceComponentService,
    private dataSourceFormService: DataSourceFormService,
    private alertService: AlertService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']?.currentValue && this.editMode && !this.dataSource) {
      this.dataSourceAdditionsDomainService.loadAdditionParams(this.templateType);

      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.data-sources.label.edit')} ${this.dataSource.params?.name}`,
        routerLink: getDataSourceEditRoute(this.dataSource.id),
      });

      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
    }
  }

  ngOnInit(): void {
    if (!this.editMode) {
      this.breadcrumbItems?.push({
        label: `${this.translate.instant('shell.bc.data-sources.label.create')} ${this.templateType}`,
        routerLink: getDataSourceCreateRoute(this.templateType),
      });
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
    }
  }

  onSubmit(): void {
    this.dataSourceComponentService
      .saveDataSource(this.dataSource)
      .pipe(
        take(1),
        catchError((e) => {
          this.alertService.error(this.translate.instant('shell.bc.data-sources.save-status.error'));
          return throwError(() => e);
        }),
      )
      .subscribe((dataSource) => {
        if (dataSource) {
          this.alertService.success(this.translate.instant('shell.bc.data-sources.save-status.success'));
          this.backEvent.emit();
        }
      });
  }

  onBackEvent(): void {
    this.backEvent.emit();
  }
}
