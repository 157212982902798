<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.data.tooltip.title' | translate }}
  </div>
  <div class="ddp-wrap-downmenu">
    <div class="ddp-box-down">
      <div class="ddp-divide2">
        <div class="ddp-list-label">{{ 'msg.page.chart.datalabel.display.label' | translate }}</div>
        <div class="ddp-list-sub2">
          <div class="ddp-part-check-b">
            <label class="ddp-label-checkbox ddp-check-full">
              <input
                type="checkbox"
                [ngModel]="returnMapTooltip('LAYER_NAME')"
                (click)="toggleDisplayType('LAYER_NAME', 17)"
              />
              <i class="ddp-icon-checkbox"></i>
              <span class="ddp-txt-checkbox">{{ 'msg.page.chart.datalabel.map.layer.name' | translate }}</span>
            </label>
          </div>
          <div class="ddp-part-check-b">
            <label class="ddp-label-checkbox">
              <input
                type="checkbox"
                [ngModel]="returnMapTooltip('LOCATION_INFO')"
                (click)="toggleDisplayType('LOCATION_INFO', 18)"
              />
              <i class="ddp-icon-checkbox"></i>
              <span class="ddp-txt-checkbox">{{ 'msg.page.chart.datalabel.map.geo.info' | translate }}</span>
            </label>
          </div>
          <div class="ddp-part-check-b">
            <label class="ddp-label-checkbox">
              <input
                type="checkbox"
                [ngModel]="returnMapTooltip('DATA_VALUE')"
                (click)="toggleDisplayType('DATA_VALUE', 19)"
              />
              <i class="ddp-icon-checkbox"></i>
              <span class="ddp-txt-checkbox">{{ 'msg.page.chart.datalabel.map.data.value' | translate }}</span>
            </label>
          </div>

          <div class="ddp-data-value" *ngIf="returnMapTooltip('DATA_VALUE')">
            <ul class="ddp-list-datavalue">
              <li *ngFor="let item of selectedLayerItems; let index = index">
                <span class="ddp-data-value">
                  <em
                    class="ddp-icon-dimension-ab"
                    *ngIf="
                      (item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'STRING') ||
                      item?.type === 'user_expr'
                    "
                  ></em>

                  <em
                    class="ddp-icon-dimension-local"
                    *ngIf="
                      (item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'LNG') ||
                      item.field?.logicalType?.toString() === 'LNT'
                    "
                  ></em>

                  <em
                    class="ddp-icon-dimension-calen"
                    *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'TIMESTAMP'"
                  ></em>

                  <em
                    class="ddp-icon-measure-float"
                    *ngIf="
                      (item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'DOUBLE') ||
                      item.field?.logicalType?.toString() === 'INTEGER'
                    "
                  ></em>

                  <em
                    class="ddp-icon-dimension-tf"
                    *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'BOOLEAN'"
                  ></em>

                  <em
                    class="ddp-icon-dimension-polygon ddp-icon-map-view"
                    *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'GEO_POLYGON'"
                  ></em>

                  <em
                    class="ddp-icon-dimension-line ddp-icon-map-view"
                    *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'GEO_LINE'"
                  ></em>

                  <em
                    class="ddp-icon-dimension-point ddp-icon-map-view"
                    *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'GEO_POINT'"
                  ></em>
                  {{ returnDataValueName(item) }}
                </span>
                <em class="ddp-icon-listdelete" (click)="deleteSelectedField(index)"></em>
              </li>

              <li
                class="ddp-add"
                (clickOutside)="$event.stopPropagation(); this.addColumnShowFl = false"
                (click)="toggleAddColumn()"
                [ngClass]="{
                  'ddp-selected': addColumnShowFl && uiOption?.toolTip?.displayTypes.indexOf('DATA_VALUE') != -1
                }"
              >
                <span class="ddp-data-value">
                  {{ 'msg.page.chart.datalabel.map.add.column' | translate }}
                </span>
                <em class="ddp-icon-plus"></em>
              </li>
            </ul>

            <div
              [style.display]="
                addColumnShowFl && uiOption?.toolTip?.displayTypes.indexOf('DATA_VALUE') != -1 ? 'block' : 'none'
              "
              class="ddp-box-layout4"
            >
              <div class="ddp-form-search">
                <em class="ddp-icon-search"></em>
                <input
                  type="text"
                  [(ngModel)]="searchText"
                  (keyup.enter)="returnSearchFields()"
                  placeholder="{{ 'msg.bench.ui.search' | translate }}"
                />
                <em class="ddp-btn-search-close" (click)="initSearchFields()"></em>
              </div>

              <div class="ddp-ui-list-type ddp-types">
                <ul class="ddp-list-typebasic ddp-list-popup">
                  <li *ngIf="unselectedLayerItems?.length == 0">
                    <a href="javascript:">{{ 'msg.page.chart.map.tooltip.search.no.column' | translate }}</a>
                  </li>

                  <li *ngFor="let item of unselectedLayerItems" (click)="addColumn(item)">
                    <a href="javascript:">
                      <em
                        class="ddp-icon-dimension-ab"
                        *ngIf="
                          (item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'STRING') ||
                          item?.type === 'user_expr'
                        "
                      ></em>

                      <em
                        class="ddp-icon-dimension-local"
                        *ngIf="
                          (item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'LNG') ||
                          item.field?.logicalType?.toString() === 'LNT'
                        "
                      ></em>

                      <em
                        class="ddp-icon-dimension-calen"
                        *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'TIMESTAMP'"
                      ></em>

                      <em
                        class="ddp-icon-measure-float"
                        *ngIf="
                          (item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'DOUBLE') ||
                          item.field?.logicalType?.toString() === 'INTEGER'
                        "
                      ></em>

                      <em
                        class="ddp-icon-dimension-tf"
                        *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'BOOLEAN'"
                      ></em>

                      <em
                        class="ddp-icon-dimension-polygon ddp-icon-map-view"
                        *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'GEO_POLYGON'"
                      ></em>

                      <em
                        class="ddp-icon-dimension-line ddp-icon-map-view"
                        *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'GEO_LINE'"
                      ></em>

                      <em
                        class="ddp-icon-dimension-point ddp-icon-map-view"
                        *ngIf="item?.type !== 'user_expr' && item.field?.logicalType?.toString() === 'GEO_POINT'"
                      ></em>
                      <span class="ddp-txt-name">{{ returnDataValueName(item) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
