import {
  ConfigurationDataSourceApiModel,
  ConfigurationMappingDatasourceApiModel,
  ConfigurationMultiDatasourceApiModel,
  ConfigurationSingleDataSourceApiModel,
} from '@selfai-platform/bi-api';

export function isMultiDatasource(
  dataSource: ConfigurationDataSourceApiModel,
): dataSource is ConfigurationMultiDatasourceApiModel {
  return dataSource.type === 'multi';
}

export function isMappingDatasource(
  dataSource: ConfigurationDataSourceApiModel,
): dataSource is ConfigurationMappingDatasourceApiModel {
  return dataSource.type === 'mapping';
}

export function isSingleDatasource(
  dataSource: ConfigurationDataSourceApiModel,
): dataSource is ConfigurationSingleDataSourceApiModel {
  return !isMultiDatasource(dataSource) && !isMappingDatasource(dataSource);
}

export function getDatasourceEngineName(dataSource: ConfigurationDataSourceApiModel): string {
  // TODO: I'm not sure about correct logic for mapping and multi datasource
  if (isMultiDatasource(dataSource)) {
    return dataSource.dataSources[0].engineName;
  }

  if (isMappingDatasource(dataSource)) {
    return dataSource.joins[0].engineName;
  }

  return dataSource.engineName;
}
