import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Observable, catchError, throwError } from 'rxjs';
import { AlertService } from '../alert';
import { ConfigService } from '../config';
import { SelfaiAuthService } from './selfai-auth.service';

@Injectable()
export class TokenRefreshExpiredInterceptor implements HttpInterceptor {
  constructor(
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly alertService: AlertService,
    private readonly configService: ConfigService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((errResponse: unknown) => {
        const provider = this.configService.getConfig().identityProvider?.provider;
        switch (provider) {
          case 'none': {
            return throwError(() => errResponse);
          }
          case 'keycloak': {
            return this.keycloakInterception(errResponse);
          }
          default: {
            return throwError(() => errResponse);
          }
        }
      }),
    );
  }

  private keycloakInterception(errResponse: unknown) {
    if (errResponse instanceof HttpErrorResponse) {
      const identityProviderService = this.selfaiAuthService.getProvider<KeycloakService>().getKeycloakInstance();
      switch (errResponse.status) {
        case 403: // Forbidden
          if (errResponse.url && this.isBiApi(errResponse.url)) {
            // this.handleExpiredRefreshToken();
            this.alertService.error('You do not have permission to access this resource');
          }
          break;
        case 401: // Unauthorized
          if (identityProviderService.authServerUrl) {
            if (
              // Refresh the expired token
              // Check if the OAuth endpoint was called and the error is because the refresh token expired

              errResponse.url?.includes(identityProviderService.authServerUrl) &&
              errResponse.error.error === 'invalid_token'
            ) {
              this.handleExpiredRefreshToken();
              break;
            }

            if (errResponse.url && this.isBiApi(errResponse.url) && errResponse.error.error === 'Unauthorized') {
              this.handleExpiredRefreshToken();
              break;
            }
          }

          break;

        case 400: // Bad Request
          if (
            identityProviderService.authServerUrl &&
            errResponse.url?.includes(identityProviderService.authServerUrl) &&
            errResponse.error.error === 'invalid_grant'
          ) {
            this.handleExpiredRefreshToken();
          }
          break;
      }
    }

    return throwError(() => errResponse);
  }

  private isBiApi(url: string) {
    return url.replace(window.location.origin, '').startsWith('/api/');
  }

  handleExpiredRefreshToken(): void {
    this.selfaiAuthService.getProvider().login({
      redirectUri: window.location.href,
    });
  }
}
