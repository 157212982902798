import { BoardDataSource, createBoardSource } from "../board-datasource";

import { QueryParamLimit, createQueryParamLimit } from "./query-param-limit";

export interface QueryParam {
    dataSource: BoardDataSource;
    filters: any[];
    projections: any[];
    limits: QueryParamLimit;
    preview: boolean;
    metaQuery: boolean;
  }
  
  export function createQueryParam(queryParamOptions: Partial<QueryParam> = {}): QueryParam {
    return {
      dataSource: createBoardSource(),
      filters: [],
      projections: [],
      limits: createQueryParamLimit(),
      preview: true,
      metaQuery: false,
      ...queryParamOptions
    }
  }
  