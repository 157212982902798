import { EntityMetadataMap } from '@ngrx/data';

export const KE_USER_FUNCTIONS_LIST_ENTITY_NAME = 'keUserFunctions';

const entityMetadata: EntityMetadataMap = {
  [KE_USER_FUNCTIONS_LIST_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
    filterFn: (entities: any[], tags: { id?: string, name: string }[] = []) =>
      entities.filter((listItem) => {
        if (tags.length) {
          return (tags as { id?: string, name: string }[]).every((el) => (<string[]>listItem.tags.map((tag: {name: string}) => tag.name)).includes(el.name));
        } else {
          return entities;
        }
      })
  },
};

export const keUserFunctionsEntityConfig = {
  entityMetadata,
};
