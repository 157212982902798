import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { WorkflowPresetsInterface } from '../../interfaces/workflow-presets.interface';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService } from '@selfai-platform/shell';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getPresetsPath, getPresetsPathCreatePath, getPresetsPathEditPath } from '../../functions';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'selfai-platform-workflow-presets',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    DropdownModule,
    ToastModule,
    CardModule,
    ToolbarModule,
    ButtonModule,
    FloatLabelModule,
    CheckboxModule,
  ],
  templateUrl: './workflow-presets.component.html',
  styleUrl: './workflow-presets.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPresetsComponent implements OnChanges, OnInit {
  clusterType = [
    { name: 'Standalone', value: 'standalone' },
    { name: 'Local', value: 'local' },
  ];
  id: number = +this.activatedRoute?.snapshot?.params['id'];
  editMode = !!this.id;
  breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.presets'),
      routerLink: getPresetsPath(),
    },
  ];
  currentWorkflowPreset!: WorkflowPresetsInterface;
  form: FormGroup = this.fb.group({
    id: [''],
    params: [''],
    name: ['', [Validators.required]],
    userIP: [''],
    clusterType: [null, [Validators.required]],
    uri: ['', [Validators.required]],
    isDefault: [false],
    isEditable: [true],
    driverMemory: ['', [Validators.required]],
    executorMemory: ['', [Validators.required]],
    executorCores: [1, [Validators.required]],
    numExecutors: [1, [Validators.required]],
    totalExecutorCores: [{ value: '', disabled: true }, Validators.required],
    hadoopUser: [''],
  });

  constructor(
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private breadcrumbsMenuService: BreadcrumbsMenuService,
  ) {}

  @Input() workflowPresets: WorkflowPresetsInterface[];
  @Output() submitEvent = new EventEmitter<WorkflowPresetsInterface>();
  @Output() backEvent = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workflowPresets']?.currentValue?.length) {
      if (this.editMode) {
        this.currentWorkflowPreset = this.workflowPresets.find((wp) => {
          return wp.id === this.id;
        });
        if (this.breadcrumbItems.length < 2) {
          this.breadcrumbItems?.push({
            label: `${this.translate.instant('shell.bc.presets.label.edit')} ${this.currentWorkflowPreset?.name}`,
            routerLink: getPresetsPathEditPath(this.currentWorkflowPreset?.id.toString() || ''),
          });
        }
      } else {
        this.currentWorkflowPreset = changes['workflowPresets']?.currentValue[0];
        this.breadcrumbItems?.push({
          label: `${this.translate.instant('shell.bc.presets.label.create')}`,
          routerLink: getPresetsPathCreatePath(),
        });
      }
      this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
      this.form.setValue(this.currentWorkflowPreset);
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnInit(): void {
    this.form.get('executorCores').valueChanges.subscribe(() => {
      this.setTotalExecutorCores();
    });
    this.form.get('numExecutors').valueChanges.subscribe(() => {
      this.setTotalExecutorCores();
    });
  }

  setTotalExecutorCores(): void {
    this.form
      .get('totalExecutorCores')
      .setValue((this.form.get('executorCores').value || 1) * (this.form.get('numExecutors').value || 1));
  }

  onBackEvent(): void {
    this.backEvent.emit();
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    this.changeDetectorRef.detectChanges();
    if (this.form.valid) {
      this.submitEvent.emit(this.form.value);
    }
  }
}
