<ng-container *ngIf="loadingState$ | async as loadingState">
  <ng-container *ngIf="loadingState.success">
    <ng-container *ngIf="node$ | async as node; else noTree">
      <p-tree *ngIf="node.children; else noTree" [value]="node.children">
        <ng-template let-node pTemplate="default">
          <div class="wrapper">
            <button pButton class="p-button-link p-0" (click)="onClickItem(node)">
              <span class="id">{{ node.id }}</span>
            </button>
            <span class="title">{{ node.title }}</span>
            <span class="types">{{ node.types?.join(' | ') }}</span>
          </div>
        </ng-template>
      </p-tree>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="loadingState.loading">
    <p-skeleton *ngFor="let _ of [].constructor(10)" styleClass="mb-3" width="30%" shape="rectangle"></p-skeleton>
  </ng-container>
  <ng-container *ngIf="loadingState.error">
    <p-messages severity="error">
      <ng-template pTemplate>
        {{ loadingState.error.text }}
      </ng-template>
    </p-messages>
  </ng-container>
</ng-container>

<ng-template #noTree>
  <p-messages severity="warn">
    <ng-template pTemplate>Json Schema is not found</ng-template>
  </p-messages>
</ng-template>
