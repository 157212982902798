import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CodeLanguage, Condition, ConditionFormGroup } from '@selfai-platform/pipeline-common';

@Component({
  selector: 'selfai-platform-condition',
  templateUrl: './condition-edit.component.html',
  styleUrls: ['./condition-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionEditComponent {
  @Input() conditionItems!: Condition[];
  @Input() form!: FormGroup<ConditionFormGroup>;

  conditionType = Condition;
  codeLanguage = CodeLanguage;
}
