import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UrlPageParamsService } from '@selfai-platform/shared';
import {
  DataListViewComponentService,
  convertMessageToHtml,
  GroupAction,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { map, Observable, of, take } from 'rxjs';
import { WorkflowPresetsListItemInterface } from '../interfaces/workflow-presets-list-item.interface';
import { WorkflowPresetsDomainService } from './workflow-presets-domain.service';
import { WorkflowPresetsInterface } from '../interfaces/workflow-presets.interface';
import { getPresetsPathEditPath } from '../functions';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsListViewService extends DataListViewComponentService<any> {
  constructor(
    private workflowPresetsDomainService: WorkflowPresetsDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<any>,
  ) {
    super(urlPageParamsService, selectedItemsService);
  }

  override loadData(): Observable<{ items: WorkflowPresetsListItemInterface[]; totalItems: number }> {
    return this.workflowPresetsDomainService.getListObservable().pipe(
      map(({ presets }) => ({
        items: presets.map((item) => WorkflowPresetsListViewService.normalizeForView(item)),
        totalItems: presets.length,
      })),
    );
  }

  private static normalizeForView(preset: WorkflowPresetsInterface): any {
    return {
      ...preset,
      routerLinkToItem: {
        routerLink: getPresetsPathEditPath(preset.id?.toString() || ''),
      },
    };
  }

  override getData(): Observable<WorkflowPresetsListItemInterface[]> {
    return this.workflowPresetsDomainService.workflowPresets$.pipe(
      map((items) => (items ? items.map((item) => WorkflowPresetsListViewService.normalizeForView(item)) : [])),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.workflowPresetsDomainService.loading$;
  }

  override isLoaded(): Observable<boolean> {
    return this.workflowPresetsDomainService.loaded$;
  }

  override hasError(): Observable<boolean> {
    return this.workflowPresetsDomainService.errors$.pipe(map((error) => !!error));
  }

  getColumns(): TableColumn<any>[] {
    return [
      {
        labelTranslate: 'presets-list.table.columns.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(50% - 41rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.driver-memory',
        fieldCode: 'driverMemory',
        fieldType: 'text',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.executor-memory',
        fieldCode: 'executorMemory',
        fieldType: 'text',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.executor-cores',
        fieldCode: 'executorCores',
        fieldType: 'number',
        width: '12rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.num-executors',
        fieldCode: 'numExecutors',
        fieldType: 'number',
        width: '15rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'presets-list.table.columns.isDefault',
        fieldCode: 'isDefault',
        fieldType: 'boolean',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  getGroupActions(): Observable<GroupAction[]> {
    const actions: GroupAction[] = [];

    actions.push({
      tooltipTranslate: 'workflow-list.toolbar.actions.delete-selected',
      icon: 'pi pi-trash',
      buttonClass: 'p-button-danger',
      action: () => this.deleteSelected(),
    });

    return of(actions);
  }

  private deleteSelected(): void {
    // TODO: Move to the confirmation with key = dialog to data list view
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('workflow.preset.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((id) => {
              this.workflowPresetsDomainService.delete(<number>id);
            });
          });
      },
    });
  }
}
