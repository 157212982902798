<selfai-platform-dialog-header
  [header]="'workflow.cubes.create-data-frame.modal-header' | translate"
  [nodeId]="nodeId"
  (clickSave)="onSubmit()"
  (closeDialog)="onCloseDialog()"
></selfai-platform-dialog-header>
@if(hasParameters) {
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <selfai-platform-form-field label="{{ 'workflow.cubes.create-data-frame.field-label.column mapping' | translate }}">
    <div class="card">
      @for (formColumnMapping of form.controls.columnMapping.controls; track $index) {
      <p-divider *ngIf="$index > 0" type="dashed"></p-divider>
      <div class="flex align-items-center">
        <selfai-platform-create-data-frame-column-maping
          class="flex-1"
          [form]="formColumnMapping"
        ></selfai-platform-create-data-frame-column-maping>
        <selfai-platform-form-array-sort-action-buttons
          [count]="form.controls.columnMapping.length"
          [index]="$index"
          (orderDown)="onOrderDownColumnMapping($event)"
          (orderUp)="onOrderUpColumnMapping($event)"
          (removeStep)="onRemoveColumnMapping($event)"
        ></selfai-platform-form-array-sort-action-buttons>
      </div>
      }
    </div>
    <p-button
      class="mt-2"
      icon="pi pi-plus"
      label="{{ 'workflow.cubes.create-data-frame.add-column-mapping' | translate }}"
      (click)="onAddColumnMapping()"
    ></p-button>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.create-data-frame.field-label.data-of-frame' | translate }}">
    <input pInputText [formControl]="form.controls.dataOfFrame" />
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.create-data-frame.field-label.data-source' | translate }}">
    <selfai-platform-data-source-selector
      [control]="form.controls.dataSourceId"
      class="h-15rem"
    ></selfai-platform-data-source-selector>
  </selfai-platform-form-field>
</form>
} @else {
<p-message text="{{ 'workflow.cubes.create-data-frame.no-parameters' | translate }}" severity="error"></p-message>
}
