import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourceUniqueNameComponent, DataSourceVisibilityComponent } from '../data-source-form-inputs';
import { DataSourcesBasic } from '../data-sources-basic';

@Component({
  selector: 'selfai-platform-data-sources-google-spreadsheet',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CheckboxModule,
    ButtonModule,
    InputTextModule,
    DataSourcesAdditionsComponent,
    DataSourceUniqueNameComponent,
    DataSourceVisibilityComponent,
  ],
  templateUrl: './data-sources-google-spreadsheet.component.html',
  styleUrls: ['./data-sources-google-spreadsheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesGoogleSpreadsheetComponent extends DataSourcesBasic implements OnInit {
  paramsForm = this.fb.group({
    googleSpreadsheetId: ['', Validators.required],
    googleServiceAccountCredentials: ['', Validators.required],
    convert01ToBoolean: [false],
    includeHeader: [false],
  });

  constructor() {
    super('googleSpreadsheet');
  }
}
