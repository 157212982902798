import { DatasourceType } from '@selfai-platform/pipeline-common';
import { DataSourcesIcon } from '../enums/data-sources-icon.enum';

export function getIcon(datasourceType: DatasourceType): string {
  return getIconsByType()[datasourceType] || '';
}

export function getIconsByType(): Record<DatasourceType, string> {
  return {
    libraryFile: DataSourcesIcon.LIBRARY,
    jdbc: DataSourcesIcon.DATABASE,
    externalFile: DataSourcesIcon.EXTERNAL_FILE,
    hdfs: DataSourcesIcon.HD,
    googleSpreadsheet: DataSourcesIcon.GOOGLE_SP,
    kafka: DataSourcesIcon.KAFKA,
    s3: DataSourcesIcon.S3,
  };
}

export function getMapIconsByType(): Map<DatasourceType, string> {
  return new Map([
    ['libraryFile', DataSourcesIcon.LIBRARY],
    ['jdbc', DataSourcesIcon.LIBRARY],
    ['externalFile', DataSourcesIcon.LIBRARY],
    ['hdfs', DataSourcesIcon.LIBRARY],
    ['googleSpreadsheet', DataSourcesIcon.LIBRARY],
    ['kafka', DataSourcesIcon.LIBRARY],
    ['s3', DataSourcesIcon.LIBRARY],
  ]);
}
