import { Route } from '@angular/router';
import { AppAuthGuard } from '@selfai-platform/shared';
import { WorkflowPresetsContainerComponent, WorkflowPresetsListContainerComponent } from './workflow-presets/';
import { DataSourcesContainerComponent, DataSourcesListContainerComponent } from './data-sources';
import { WorkflowEditorComponent } from './workflow-editor';
import { KE_DATASOURCES_PATH, KE_PRESETS_PATH, KE_ROOT_ROUTE, KE_WORKFLOW_PATH, KE_USER_FUNCTIONS_PATH } from '@selfai-platform/shell';
import { WorkflowSettingsContainerComponent } from './workflow-settings';
import {
  UserFunctionsContainerComponent, 
  UserFunctionContainerComponent, 
  UserFunctionManagementGuard
} from './user-functions';

export const pipelineModuleRoutes: Route[] = [
  {
    path: KE_ROOT_ROUTE,
    loadChildren: () =>
      import('./wokflow-list/components/workflow-list/workflow-list.module').then((m) => m.WorkflowListComponentModule),
    canActivate: [AppAuthGuard],
  },
  {
    path: KE_ROOT_ROUTE,
    canActivate: [AppAuthGuard],
    children: [
      {
        path: KE_DATASOURCES_PATH,
        children: [
          {
            path: '',
            component: DataSourcesListContainerComponent,
          },
          {
            path: 'create/:type',
            component: DataSourcesContainerComponent,
          },
          {
            path: 'edit/:id',
            component: DataSourcesContainerComponent,
          },
        ],
      },
      {
        path: KE_WORKFLOW_PATH + '/:id',
        component: WorkflowEditorComponent,
      },
      {
        path: KE_PRESETS_PATH,
        children: [
          {
            path: '',
            component: WorkflowPresetsListContainerComponent,
          },
          {
            path: 'create',
            component: WorkflowPresetsContainerComponent,
          },
          {
            path: 'edit/:id',
            component: WorkflowPresetsContainerComponent,
          },
          {
            path: 'modal',
            component: WorkflowSettingsContainerComponent,
          },
        ],
      },
      {
        path: KE_USER_FUNCTIONS_PATH,
        children: [
          {
            path: '',
            component: UserFunctionsContainerComponent
          },
          {
            path: 'create',
            canActivate: [UserFunctionManagementGuard],
            component: UserFunctionContainerComponent
          },
          {
            path: 'edit/:id',
            component: UserFunctionContainerComponent
          }
        ]
      }
    ],
  },
];
