import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CreateDataColumnType, CreateDataFrameColumnMappingFormGroup } from '@selfai-platform/pipeline-common';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FormFieldComponentModule } from '../../../../../form-field/form-field.module';

@Component({
  selector: 'selfai-platform-create-data-frame-column-maping',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DropdownModule,
    InputTextModule,
    FormFieldComponentModule,
    ReactiveFormsModule,
  ],

  templateUrl: './create-data-frame-column-maping.component.html',
  styleUrl: './create-data-frame-column-maping.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDataFrameColumnMapingComponent {
  @Input({ required: true }) form: FormGroup<CreateDataFrameColumnMappingFormGroup>;

  saveModeOptions: CreateDataColumnType[] = Object.values(CreateDataColumnType);
}
