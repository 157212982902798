import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Update } from '@ngrx/entity/src/models';
import { PipelineConfigService } from '@selfai-platform/shared';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DataSourceApi } from '../model';

@Injectable({
  providedIn: 'root'
})
export class DataSourcesApiService {
  private readonly config = this.pipelineConfigService.getConfig();
  private readonly apiUrl = `${this.config.hosts.api}/datasourcemanager/${this.config.versions.url}`;
  private readonly datasourcesPath = 'datasources';

  constructor(private http: HttpClient, private pipelineConfigService: PipelineConfigService) {}

  loadList(): Observable<DataSourceApi[]> {
    return this.http.get<DataSourceApi[]>(`${this.apiUrl}/${this.datasourcesPath}`);
  }

  loadDatasource(id: string): Observable<DataSourceApi> {
    return this.http.get<DataSourceApi>(`${this.apiUrl}/${this.datasourcesPath}/${id}`);
  }

  addDatasource(datasourceParams: DataSourceApi): Observable<DataSourceApi> {
    return this.http.post<DataSourceApi>(`${this.apiUrl}/${this.datasourcesPath}/${uuidv4()}`, datasourceParams.params);
  }

  updateDatasource(data: Update<DataSourceApi>): Observable<DataSourceApi> {
    return this.http.put<DataSourceApi>(`${this.apiUrl}/${this.datasourcesPath}/${data.id}`, data.changes.params);
  }

  deleteDatasource(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/${this.datasourcesPath}/${id}`);
  }
}
