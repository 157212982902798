  <h5>{{ 'msg.workspace.settings.presets.title' | translate }}</h5>
  <form [formGroup]="form">
    <div class="grid">
      <div class="col-10">
        <p-dropdown
          optionLabel="name"
          optionValue="id"
          [options]="presets"
          formControlName="preset"
          placeholder="{{ 'msg.workspace.settings.presets.placeholder' | translate }}"
          [style]=" {width: '100%' }"
        ></p-dropdown>
      </div>
      <div class="col-2 flex justify-content-end">
        <i class="pi pi-info-circle mr-3" (click)="op.show($event)"></i>
        <p-overlayPanel #op>
          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.additional-parameters' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.params || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.preset-name' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.name || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.driver-hostname' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.userIP || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.cluster-type' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.clusterType || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.master-uri' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.uri || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.driver-memory' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.driverMemory || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.executor-memory' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.executorMemory || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.executor-cores' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.executorCores || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.number-of-executors' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.numExecutors || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.total-executor-cores' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.totalExecutorCores || '&mdash;'}}
            </div>
          </div>

          <div class="grid">
            <div class="col-6 bold">
              {{ 'msg.workspace.settings.presets.info.hadoop-user' | translate }}:
            </div>
            <div class="col-6">
              {{currentPreset?.hadoopUser || '&mdash;'}}
            </div>
          </div>
        </p-overlayPanel>
        <p-button icon="pi pi-save" (click)="onSubmit()"></p-button>
      </div>
    </div>
  </form>
