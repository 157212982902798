import { cloneDeep } from 'lodash';
import moment from 'moment';

import {
  AggregationType,
  BoardDataSource,
  BoundFilter,
  ByTimeUnit,
  ContainsType,
  DIRECTION,
  Dashboard,
  Datasource,
  DatasourceField as Field,
  Filter,
  InclusionFilter,
  InclusionSelectorType,
  InclusionSortBy,
  InequalityType,
  IntervalFilter,
  IntervalRelativeTimeType,
  IntervalSelectorType,
  MeasureInequalityFilter,
  MeasurePositionFilter,
  PositionType,
  RegExprFilter,
  TIME_RANGE_FILTER_EARLIEST_DATETIME,
  TIME_RANGE_FILTER_LATEST_DATETIME,
  TimeAllFilter,
  TimeFilter,
  TimeListFilter,
  TimeRangeFilter,
  TimeRelativeFilter,
  TimeRelativeTense,
  TimeUnit,
  WildCardFilter,
  createBoundFilter,
  createInclusionFilter,
  createInclusionItemSort,
  createMeasureInequalityFilter,
  createMeasurePositionFilter,
  createRegExprFilter,
  createTimeAllFilter,
  createTimeListFilter,
  createTimeRangeFilter,
  createTimeRelativeFilter,
  createWildCardFilter,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';



import { TimezoneService } from '../../data-storage/service/timezone.service';


import { convertToServerSpecForDashboard } from './convert-to-server-spec-for-dashboard';
import { DashboardUtil } from './dashboard.util';
import { getDateTimeFormat } from './getDateTimeFormat';
import { isTimeRangeFilter } from './isTimeRangeFilter';
import { isTimeRelativeFilter } from './isTimeRelativeFilter';

export class FilterUtil {
  public static CANDIDATE_LIMIT = 100;

  public static getPanelContentsList(
    filterList: Filter[],
    dashboard: Dashboard,
    inclusionFilterFunc: (incFilter: InclusionFilter, data: unknown) => void,
  ): Filter[] {
    const clonedFilterList: Filter[] = cloneDeep(filterList);
    clonedFilterList.forEach((filter: Filter) => {
      filter['dsName'] = dashboard.dataSources.find((item) => item.engineName === filter.dataSource || item.name === filter.dataSource).name;
      filter['fieldObj'] = DashboardUtil.getFieldByName(dashboard, filter.dataSource, filter.field, filter.ref);
      if ('include' === filter.type) {
        inclusionFilterFunc && inclusionFilterFunc(<InclusionFilter>filter, filter['fieldObj']);
      } else if ('bound' === filter.type) {
        const boundFilter: BoundFilter = <BoundFilter>filter;
        filter['panelContents'] = boundFilter.min + ' ~ ' + boundFilter.max;
      } else if (FilterUtil.isTimeListFilter(filter)) {
        const vals: string[] = (<TimeListFilter>filter).valueList;
        if (vals && 0 < vals.length) {
          filter['panelContents'] = vals.join(' , ');
        } else {
          filter['panelContents'] = '(No time filtering)';
        }
      } else if (FilterUtil.isTimeRangeFilter(filter)) {
        const timeRangeFilter: TimeRangeFilter = <TimeRangeFilter>filter;
        const intervals: string[] = timeRangeFilter.intervals;
        if (intervals && 0 < intervals.length) {
          filter['panelContents'] = intervals.map((item) => {
            const arrInterval: any[] = item.split('/');

            if (
              TIME_RANGE_FILTER_EARLIEST_DATETIME !== arrInterval[0] &&
              TIME_RANGE_FILTER_LATEST_DATETIME !== arrInterval[0]
            ) {
              arrInterval[0] = FilterUtil.getDateTimeFormat(arrInterval[0], timeRangeFilter.timeUnit, true);
            }
            if (
              TIME_RANGE_FILTER_EARLIEST_DATETIME !== arrInterval[1] &&
              TIME_RANGE_FILTER_LATEST_DATETIME !== arrInterval[1]
            ) {
              arrInterval[1] = FilterUtil.getDateTimeFormat(arrInterval[1], timeRangeFilter.timeUnit, false);
            }

            return arrInterval[0] + '/' + arrInterval[1];
          });
          filter['panelContents'] = filter['panelContents'].join('<br>');
        } else {
          filter['panelContents'] = '(No time filtering)';
        }
      } else if (FilterUtil.isTimeRelativeFilter(filter)) {
        const timeFilter: TimeRelativeFilter = <TimeRelativeFilter>filter;
        filter['panelContents'] = timeFilter.value + ' ' + timeFilter.relTimeUnit.toString();
      } else if (FilterUtil.isTimeAllFilter(filter)) {
        filter['panelContents'] = '(No time filtering)';
      }
    });

    return clonedFilterList;
  }

  public static getBoardDataSourceForFilter(filter: Filter, board: Dashboard): BoardDataSource {
    const boardDataSource: BoardDataSource = board.configuration.dataSource;
    if ('multi' === boardDataSource.type) {
      return boardDataSource.dataSources.find(
        (item) =>
          item.engineName === filter.dataSource ||
          item.name === filter.dataSource ||
          (item.connType == 'LINK' && item.engineName.startsWith(filter.dataSource + '_')),
      );
    } else {
      return boardDataSource;
    }
  }

  public static getEssentialFilters(board: Dashboard, filter?: Filter): Filter[] {
    let essentialFilters: Filter[] = [];
    if (board.configuration.dataSource.temporary) {
      essentialFilters = board.configuration.filters.filter((item) => {
        const filterField = DashboardUtil.getFieldByName(board, item.dataSource, item.field);
        if (filter && filter.ui && filter.ui.filteringSeq) {
          return filter.ui.filteringSeq < filterField.filteringSeq;
        } else {
          return -1 < filterField.filteringSeq;
        }
      });
    }
    return essentialFilters;
  }

  public static getDataSourceForFilter(filter: Filter, board: Dashboard): Datasource {
    return board.dataSources.find((item) => item.engineName === filter.dataSource || item.name === filter.dataSource);
  }

  public static getBasicInclusionFilter(field: Field, importanceType?: string, preFilterData?: any): InclusionFilter {
    const inclusionFilter = createInclusionFilter(field.name);
    inclusionFilter.selector = InclusionSelectorType.SINGLE_COMBO;
    inclusionFilter.preFilters = [];
    inclusionFilter.valueList = [];
    inclusionFilter.ref = field.ref;
    inclusionFilter.dataSource = field.dataSource;

    inclusionFilter.preFilters.push(this.getBasicInequalityFilter(preFilterData));
    inclusionFilter.preFilters.push(this.getBasicPositionFilter(preFilterData));
    inclusionFilter.preFilters.push(this.getBasicWildCardFilter(field.name, preFilterData));
    inclusionFilter.preFilters.push(this.getBasicRegExprFilter(field.name, preFilterData));

    inclusionFilter.sort = createInclusionItemSort(InclusionSortBy.TEXT, DIRECTION.ASC);

    importanceType && (inclusionFilter.ui.importanceType = importanceType);
    -1 < field.filteringSeq && (inclusionFilter.ui.filteringSeq = field.filteringSeq + 1);
    if (field.filteringOptions) {
      inclusionFilter.ui.filteringOptions = field.filteringOptions;

      if (field.filteringOptions.defaultSelector === InclusionSelectorType.SINGLE_LIST) {
        inclusionFilter.selector = InclusionSelectorType.SINGLE_LIST;
      }
    }

    return inclusionFilter;
  }

  public static getBasicBoundFilter(field: Field, importanceType?: string): BoundFilter {
    const boundFilter = createBoundFilter(field.name, {
      max: 0,
      min: 0,
      ref: field.ref,
      dataSource: field.dataSource,
    });

    importanceType && (boundFilter.ui.importanceType = importanceType);

    return boundFilter;
  }

  public static getBasicInequalityFilter(preFilterData: any): MeasureInequalityFilter {
    const measureInequalityFilter = createMeasureInequalityFilter({
      aggregation: AggregationType.SUM,
      inequality: InequalityType.EQUAL_TO,
      value: 10,
    });

    if (preFilterData) {
      measureInequalityFilter.aggregation = preFilterData.aggregation;
      measureInequalityFilter.inequality = preFilterData.inequality;
    }

    return measureInequalityFilter;
  }

  public static getBasicPositionFilter(preFilterData: any): MeasurePositionFilter {
    const measurePositionFilter = createMeasurePositionFilter({
      aggregation: AggregationType.SUM,
      position: PositionType.TOP,
      value: 10,
    });

    if (preFilterData) {
      measurePositionFilter.aggregation = preFilterData.aggregation;
      measurePositionFilter.position = preFilterData.position;
    }

    return measurePositionFilter;
  }

  public static getBasicWildCardFilter(fieldName: string, preFilterData: any): WildCardFilter {
    const wildCardFilter = createWildCardFilter({
      contains: ContainsType.BEFORE,
      field: fieldName,
      value: '',
    });

    if (preFilterData) {
      wildCardFilter.contains = preFilterData.contains;
    }

    return wildCardFilter;
  }

  public static getBasicRegExprFilter(fieldName: string, preFilterData: any): RegExprFilter {
    const regExprFilter = createRegExprFilter({
      expr: '',
      field: fieldName,
    });
    return regExprFilter;
  }

  public static convertToServerSpec(filter: Filter): Filter {
    if (FilterUtil.isTimeRangeFilter(filter)) {
      const timeRangeFilter: TimeRangeFilter = <TimeRangeFilter>filter;
      if (timeRangeFilter.intervals && 0 < timeRangeFilter.intervals.length) {
        timeRangeFilter.intervals.forEach((item: string, idx: number) => {
          const arrInterval: any[] = item.split('/');

          if (
            TIME_RANGE_FILTER_EARLIEST_DATETIME !== arrInterval[0] &&
            TIME_RANGE_FILTER_LATEST_DATETIME !== arrInterval[0]
          ) {
            arrInterval[0] = FilterUtil.getDateTimeFormat(arrInterval[0], timeRangeFilter.timeUnit, true);
          }
          if (
            TIME_RANGE_FILTER_EARLIEST_DATETIME !== arrInterval[1] &&
            TIME_RANGE_FILTER_LATEST_DATETIME !== arrInterval[1]
          ) {
            arrInterval[1] = FilterUtil.getDateTimeFormat(arrInterval[1], timeRangeFilter.timeUnit, false);
          }

          timeRangeFilter.intervals[idx] = arrInterval[0] + '/' + arrInterval[1];
        });

        TimeUnit.QUARTER === timeRangeFilter.timeUnit && (timeRangeFilter.timeUnit = TimeUnit.MONTH);
      }
    } else if (FilterUtil.isTimeRelativeFilter(filter)) {
      const timeRelativeFilter: TimeRelativeFilter = <TimeRelativeFilter>filter;
      if (
        timeRelativeFilter.clzField &&
        timeRelativeFilter.clzField.format &&
        TimezoneService.DISABLE_TIMEZONE_KEY === timeRelativeFilter.clzField.format.timeZone
      ) {
        delete timeRelativeFilter.timeZone;
      } else {
        timeRelativeFilter.timeZone || (timeRelativeFilter.timeZone = (<any>moment).tz.guess());
      }
    }

    let keyMap: string[];
    switch (filter.type) {
      case 'interval':
        keyMap = [
          'selector',
          'startDate',
          'endDate',
          'intervals',
          'timeZone',
          'locale',
          'format',
          'rrule',
          'relValue',
          'timeUnit',
        ];
        break;
      case 'include':
        keyMap = ['selector', 'valueList', 'candidateValues', 'sort'];
        break;
      case 'empty':
        keyMap = ['selector', 'valueList', 'candidateValues', 'sort'];
        break;
      case 'timestamp':
        keyMap = ['selectedTimestamps', 'timeFormat'];
        break;
      case 'bound':
        keyMap = ['min', 'max'];
        break;
      case 'time_all':
        keyMap = [];
        break;
      case 'time_relative':
        keyMap = ['relTimeUnit', 'tense', 'value', 'timeUnit', 'byTimeUnit', 'discontinuous', 'timeZone'];
        break;
      case 'time_range':
        keyMap = ['intervals', 'timeUnit', 'byTimeUnit', 'discontinuous'];
        break;
      case 'time_list':
        keyMap = ['valueList', 'candidateValues', 'timeUnit', 'byTimeUnit', 'discontinuous'];
        break;
      case 'wildcard':
        keyMap = ['contains', 'value'];
        break;
      case 'measure_inequality':
        keyMap = ['aggregation', 'inequality', 'value'];
        break;
      case 'measure_position':
        keyMap = ['aggregation', 'position', 'value'];
        break;
      case 'regexpr':
        keyMap = ['expr'];
        break;
      case 'spatial_bbox':
        keyMap = ['lowerCorner', 'upperCorner'];
        break;
    }
    keyMap = keyMap.concat(['type', 'field', 'ref', 'dataSource']);
    for (const key of Object.keys(filter)) {
      keyMap.some((item) => item === key) || delete filter[key];
    }

    return filter;
  }

  public static convertToServerSpecForDashboard(filter: Filter): Partial<Filter> {
    return convertToServerSpecForDashboard(filter);
  }

  public static getDateTimeFormat(date: Date | string, timeUnit: TimeUnit, isStart: boolean = true): string {
    return getDateTimeFormat(date, timeUnit, isStart);
  }

  public static convertIntervalToTimeFilter(filter: IntervalFilter, boardInfo: Dashboard): TimeFilter {
    let convertFilter: TimeFilter;
    const filterField = DashboardUtil.getFieldByName(boardInfo, filter.dataSource, filter.field);
    if (filter.discontinuous) {
      const newFilter: TimeListFilter = createTimeListFilter(filterField);
      newFilter.discontinuous = filter.discontinuous;
      newFilter.timeUnit = filter.timeUnit ? filter.timeUnit : TimeUnit.NONE;
      newFilter.byTimeUnit = filter.byTimeUnit;
      filter.valueList && (newFilter.valueList = filter.valueList);
      filter.candidateValues && (newFilter.candidateValues = filter.candidateValues);
      convertFilter = newFilter;
    } else {
      switch (filter.selector) {
        case IntervalSelectorType.ALL:
          convertFilter = createTimeAllFilter(filterField);
          break;
        case IntervalSelectorType.RANGE:
          {
            const newRangeFilter = createTimeRangeFilter(filterField);
            newRangeFilter.timeUnit = filter.timeUnit ? filter.timeUnit : TimeUnit.NONE;
            newRangeFilter.intervals = filter.intervals;
            convertFilter = newRangeFilter;
          }

          break;
        case IntervalSelectorType.RELATIVE:
          {
            const newRelativeFilter = createTimeRelativeFilter(filterField);
            newRelativeFilter.timeUnit = filter.timeUnit ? filter.timeUnit : TimeUnit.NONE;
            if (IntervalRelativeTimeType.LAST === filter.timeType) {
              newRelativeFilter.tense = TimeRelativeTense.PREVIOUS;
            } else {
              if (filter.timeType) {
                newRelativeFilter.tense = TimeRelativeTense[filter.timeType.toString()];
              } else {
                newRelativeFilter.tense = TimeRelativeTense.PREVIOUS;
              }
            }
            newRelativeFilter.value = filter.relValue;
            convertFilter = newRelativeFilter;
          }

          break;
      }
    }
    return convertFilter;
  }

  public static isTimeFilter(filter: Filter): boolean {
    return (
      'time_all' === filter.type ||
      'time_list' === filter.type ||
      'time_range' === filter.type ||
      'time_relative' === filter.type
    );
  }

  public static isDiscontinuousTimeFilter(filter: TimeFilter): boolean {
    return filter.discontinuous;
  }

  public static isContinuousByAll(filter: TimeFilter): boolean {
    return !this.isDiscontinuousTimeFilter(filter) && TimeUnit.NONE === filter.timeUnit;
  }

  public static isTimeAllFilter(filter: Filter): boolean {
    return filter.type === 'time_all';
  }

  public static isTimeRelativeFilter(filter: Filter): boolean {
    return isTimeRelativeFilter(filter);
  }

  public static isTimeRangeFilter(filter: Filter): boolean {
    return isTimeRangeFilter(filter);
  }

  public static isTimeListFilter(filter: Filter): boolean {
    return filter.type === 'time_list';
  }

  public static getTimeAllFilter(field: Field, importanceType?: string): TimeAllFilter {
    const timeFilter = createTimeAllFilter(field);
    timeFilter.timeUnit = TimeUnit.NONE;

    if (importanceType) {
      timeFilter.ui.importanceType = importanceType;
      'timestamp' === importanceType && (timeFilter.ui.filteringSeq = 0);
    }

    return timeFilter;
  }

  public static getTimeRangeFilter(
    field: Field,
    timeUnit?: TimeUnit,
    importanceType?: string,
    ds?: Datasource,
  ): TimeRangeFilter {
    const timeFilter = createTimeRangeFilter(field);
    timeFilter.timeUnit = isNullOrUndefined(timeUnit) ? TimeUnit.NONE : timeUnit;

    importanceType && (timeFilter.ui.importanceType = importanceType);

    if (!timeFilter.intervals && ds && ds.summary && ds.summary.ingestionMinTime && ds.summary.ingestionMaxTime) {
      (timeFilter as TimeRangeFilter).intervals = [
        FilterUtil.getDateTimeFormat(ds.summary.ingestionMinTime, TimeUnit.SECOND) +
          '/' +
          FilterUtil.getDateTimeFormat(ds.summary.ingestionMaxTime, TimeUnit.SECOND),
      ];
    }

    return timeFilter;
  }

  public static getTimeRelativeFilter(field: Field, timeUnit?: TimeUnit, importanceType?: string): TimeRelativeFilter {
    const timeFilter = createTimeRelativeFilter(field);
    timeFilter.timeUnit = isNullOrUndefined(timeUnit) ? TimeUnit.NONE : timeUnit;

    importanceType && (timeFilter.ui.importanceType = importanceType);

    return timeFilter;
  }

  public static getTimeListFilter(
    field: Field,
    discontinuous: boolean,
    timeUnit: TimeUnit,
    byTimeUnit?: ByTimeUnit,
    importanceType?: string,
  ): TimeListFilter {
    const timeFilter = createTimeListFilter(field);
    timeFilter.timeUnit = timeUnit;
    timeFilter.discontinuous = discontinuous;
    timeFilter.valueList = [];
    isNullOrUndefined(byTimeUnit) || (timeFilter.byTimeUnit = byTimeUnit);

    importanceType && (timeFilter.ui.importanceType = importanceType);

    return timeFilter;
  }

  static setParameterFilterValue(filter: Filter, key: string, value: any): void {
    if (filter.type === 'include') {
      const inclusionFilter: InclusionFilter = <InclusionFilter>filter;
      if (Array.isArray(value)) {
        inclusionFilter.valueList = value;
      } else {
        inclusionFilter.valueList = [value];
      }
    } else if (filter.type === 'bound') {
      const boundFilter: BoundFilter = <BoundFilter>filter;
      const paramValues: string[] = value.split(',');
      if (paramValues.length === 2) {
        const min = Number(paramValues[0]);
        const max = Number(paramValues[1]);
        if (!isNaN(min) && !isNaN(max)) {
          boundFilter.min = min;
          boundFilter.max = max;
          boundFilter.minValue = min;
          boundFilter.maxValue = max;
        }
      }
    } else if (filter.type === 'time_range') {
      const timeRangeFilter: TimeRangeFilter = <TimeRangeFilter>filter;
      if (Array.isArray(value)) {
        timeRangeFilter.intervals = value;
      } else {
        timeRangeFilter.intervals = [value];
      }
    } else if (filter.type === 'time_relative') {
      const timeRelativeFilter: TimeRelativeFilter = <TimeRelativeFilter>filter;
      const valueAttributes: string[] = value.split(',');
      valueAttributes.forEach((attr) => {
        const keyValue = attr.split(':');
        if (keyValue[0] === 'tense') {
          timeRelativeFilter.tense = TimeRelativeTense[keyValue[1]];
        } else if (keyValue[0] === 'relTimeUnit') {
          timeRelativeFilter.relTimeUnit = TimeUnit[keyValue[1]];
        } else if (keyValue[0] === 'value') {
          timeRelativeFilter.value = +keyValue[1];
        }
      });
    } else if (filter.type === 'time_list') {
      const timeListFilter: TimeListFilter = <TimeListFilter>filter;
      if (Array.isArray(value)) {
        timeListFilter.valueList = value;
      } else {
        timeListFilter.valueList = [value];
      }
    }
  }
}
