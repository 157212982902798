import { Injectable } from '@angular/core';
import { PipelineVariablesEditorOptions, PipelineVariablesEditResult } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { PipelineVariablesEditorComponent } from '../components/pipeline-variables-editor/pipeline-variables-editor.component';
import { VariableItemsService } from './variable-item.service';

@Injectable()
export class PipelineVariablesService {
  constructor(
    private readonly dialogService: DialogService<PipelineVariablesEditResult, PipelineVariablesEditorOptions>,
    private readonly variableService: VariableItemsService,
  ) {}

  public showEditor(data: PipelineVariablesEditorOptions): Observable<PipelineVariablesEditResult> {
    this.variableService.next(data.items);

    return this.dialogService.showDialog(PipelineVariablesEditorComponent, {
      header: 'Variables',
      width: '75%',
      data: data,
    });
  }
}
