import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  AggregationType,
  AggrtMode,
  StepAggregationFormGroup,
  StepType,
  TypeValuesFormGroup,
} from '@selfai-platform/pipeline-common';
import { orderIndentDown, orderIndentUp } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { JSON_PATH_INIT_FORM_VALUE } from '../../../const';
import { AggregatorDictionariesService } from '../../../services/aggregator-dictionaries.service';

@Component({
  selector: 'selfai-platform-step-aggregator',
  templateUrl: './step-aggregator.component.html',
  styleUrls: ['./step-aggregator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepAggregatorComponent implements OnInit {
  @Input()
  form!: FormGroup<StepAggregationFormGroup> | UntypedFormGroup;

  get aggrtPartOfIndexFormArray(): FormArray<FormGroup<TypeValuesFormGroup>> {
    return (this.form as FormGroup<StepAggregationFormGroup>).controls.aggrtPartOfIndex;
  }

  get aggrtPartOfIndexControls(): FormGroup<TypeValuesFormGroup>[] | undefined {
    return this.aggrtPartOfIndexFormArray?.controls;
  }

  aggregationTypeItem = Object.values(AggregationType);
  periodUnitItems$!: Observable<string[]>;
  modeItems = Object.values(AggrtMode);
  stepLabel$!: Observable<string | null | undefined>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly aggregatorDictionariesService: AggregatorDictionariesService,
  ) {}

  ngOnInit(): void {
    this.periodUnitItems$ = this.aggregatorDictionariesService.getDictionary('periodUnits');

    this.stepLabel$ = this.aggregatorDictionariesService.getDictionary('stepType').pipe(
      map((steps) => {
        const stepType: StepType = this.form.controls.type.value;

        return stepType ? steps.find((step) => stepType === step.value)?.name : null;
      }),
    );
  }

  addPartOfIndexControl(): void {
    this.aggrtPartOfIndexFormArray.push(this.fb.group(JSON_PATH_INIT_FORM_VALUE) as FormGroup<TypeValuesFormGroup>);
  }

  removePartOfIndexControl(index: number): void {
    this.aggrtPartOfIndexFormArray.removeAt(index);
  }

  orderUp(index: number): void {
    orderIndentUp(this.aggrtPartOfIndexFormArray, index);
  }

  orderDown(index: number): void {
    orderIndentDown(this.aggrtPartOfIndexFormArray, index);
  }
}
