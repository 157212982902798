import { Injectable } from '@angular/core';
import { DatasourceApiService, DatasourceOneQueryParams } from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { SearchQueryResponse } from '../../associations';
import { Datasource, SearchQueryRequest } from '../models';
import { DatasourceList } from '../models/datasource-list.model';
import { normalizeSearchQueryApi } from '../normalizers';

@Injectable({ providedIn: 'root' })
export class DatasourceDomainApiService {
  constructor(private readonly datasourceApiService: DatasourceApiService) {}

  loadDatasourceList(workspaceId: string, pageParams: PageParams): Observable<DatasourceList> {
    const apiParams = {
      size: pageParams.pageSize,
      page: pageParams.pageNumber - 1,
      sort: pageParams.sortField ? `${pageParams.sortField},${pageParams.sortOrder}` : undefined,
      nameContains: pageParams.query?.trim(),
    };

    return this.datasourceApiService.getDatasourceList(workspaceId, apiParams).pipe(
      map((res) => ({
        // TODO: check mapping of datasources
        datasources: res._embedded.datasources as any,
        page: res.page,
      })),
    );
  }

  loadDatasource(datasourceId: string, queryParams: DatasourceOneQueryParams): Observable<Datasource> {
    // TODO: need to check mapping of datasource
    return this.datasourceApiService.getDatasource(datasourceId, queryParams).pipe(map((res) => res as any));
  }

  searchQuery(query: SearchQueryRequest): Observable<SearchQueryResponse> {
    return this.datasourceApiService.getQuerySearch(query as any).pipe(map(normalizeSearchQueryApi));
  }
}
