import { BoardDataSourceRelation, Dashboard } from '../../dashboard';
import { FilterPathNode, FilterPathRootNode } from '../models';

export function getFilterPath(datasourceId: string, dashboard: Dashboard): FilterPathRootNode {
  const rootNode: FilterPathRootNode = {
    datasourceId: datasourceId,
    relatedNodes: [],
  };

  const associations = dashboard.configuration.dataSource.associations || [];

  getRelatedNodesFromAssociations(associations, dashboard, rootNode);

  return rootNode;
}

function getRelatedNodesFromAssociations(
  associations: BoardDataSourceRelation[],
  dashboard: Dashboard,
  node: FilterPathNode | FilterPathRootNode,
) {
  associations.forEach((association) => {
    const nodeDsPair = [association.source, association.target];
    if (!nodeDsPair.includes(node.datasourceId)) {
      return;
    }
    const relatedDatasourceId = association.source === node.datasourceId ? association.target : association.source;
    if (nodeDsPair.includes(node.datasourceId) && 'parentDsId' in node && nodeDsPair.includes(node.parentDsId)) {
      return;
    }

    const targetNode: FilterPathNode = {
      datasourceId: relatedDatasourceId,
      relatedNodes: [],
      parentDsId: node.datasourceId,
      association: association,
    };
    node.relatedNodes.push(targetNode);
    getRelatedNodesFromAssociations(associations, dashboard, targetNode);
  });
}
