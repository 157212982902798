export function downloadFile(filename: string, content: string | Blob) {
  const fileBlob = content instanceof Blob ? content : new Blob([content], { type: 'application/pdf' });
  const url = URL.createObjectURL(fileBlob);

  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  document.body.appendChild(link); // Firefox requires the element to be in the DOM

  link.click();
  link.remove();

  if (URL.revokeObjectURL) {
    URL.revokeObjectURL(url);
  }
}
