import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CodeEditorComponentModule } from '../../../../code-editor/code-editor.module';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { DateValueFieldComponentModule } from '../../fields/date-value-field/date-value-field.module';
import { TypeFieldSwitchComponentModule } from '../../fields/type-field-switch/type-field-switch.module';
import { StepFilterComponent } from './step-filter.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    FormFieldComponentModule,
    TypeFieldSwitchComponentModule,
    DateValueFieldComponentModule,
    CodeEditorComponentModule,
  ],
  declarations: [StepFilterComponent],
  exports: [StepFilterComponent],
})
export class StepFilterComponentModule {}
