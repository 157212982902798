import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { DestroyService } from '../destroy/destroy.service';
import { LOADING_INITIAL_STATE } from './loading-initial-state.const';
import { LoadingState } from './loading-state';

@Injectable({
  providedIn: 'root',
})
export class LoadingService extends BehaviorSubject<LoadingState> {
  constructor(private readonly destroy$: DestroyService) {
    super(LOADING_INITIAL_STATE);
  }

  getState(): Observable<LoadingState> {
    return this.asObservable();
  }

  isLoading(): Observable<boolean> {
    return this.getState().pipe(map(({ loading }) => loading));
  }

  isSuccess(): Observable<boolean> {
    return this.getState().pipe(map(({ success }) => success));
  }

  isError(): Observable<boolean> {
    return this.getState().pipe(map(({ error }) => Boolean(error)));
  }

  setLoading(): void {
    this.next({
      loading: true,
      error: null,
      success: false,
    });
  }

  setError(error: LoadingState['error']): void {
    this.next({
      error,
      loading: false,
      success: false,
    });
  }

  setSuccess(): void {
    this.next({
      loading: false,
      error: null,
      success: true,
    });
  }

  transformToColdStream(): Subject<LoadingState> {
    const coldLoading$ = new Subject<LoadingState>();
    this.pipe(takeUntil(this.destroy$)).subscribe(coldLoading$);

    return coldLoading$;
  }
}
