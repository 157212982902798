import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { take } from 'rxjs';
import { DataSource } from '../model/data-source.model';
import { DataSourcesAdditions } from '../model/data-sources-additions.model';
import { DataSourcePermissionService } from '../services';
import { uniqueNameValidator } from '../validators';
import { DataSourceComponentService, DataSourceFormService } from './data-sources';

@Directive()
export abstract class DataSourcesBasic implements OnChanges, OnInit {
  abstract paramsForm: FormGroup;

  protected readonly dataSourcePermissionService: DataSourcePermissionService = inject(DataSourcePermissionService);
  protected readonly dataSourceFormService: DataSourceFormService = inject(DataSourceFormService);
  protected readonly dataSourceComponentService: DataSourceComponentService = inject(DataSourceComponentService);
  protected readonly cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  protected readonly fb: FormBuilder = inject(FormBuilder);
  protected readonly destroyRef: DestroyRef = inject(DestroyRef);
  protected readonly form = this.dataSourceFormService.form;

  @Input() editMode: boolean;
  @Input() dataSource: DataSource;
  @Input() dataSourceList: DataSource[];
  @Input() additionalList: DataSourcesAdditions[];

  @Output() dataSourceChange = new EventEmitter<DataSource>();

  constructor(protected dataSourceType: DatasourceType) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSourceList']?.currentValue?.length) {
      this.form.controls.name.clearValidators();
      this.form.controls.name.addValidators([
        uniqueNameValidator(changes['dataSourceList'].currentValue, this.dataSource?.id),
        Validators.required,
      ]);
    }

    if (changes['dataSource']?.currentValue) {
      const dataSource: DataSource = changes['dataSource'].currentValue;
      const prevDataSourceValue: DataSource | undefined = changes['dataSource'].previousValue;

      if (dataSource.id !== prevDataSourceValue?.id) {
        const formValues = this.dataSourceComponentService.normalizeParamsToFormValues(dataSource.params);

        this.addParamsFormIfNotAdded();
        this.form.patchValue(formValues);
      }
    }

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
      this.dataSourceChange.emit({ ...this.dataSource, params: value });
    });
  }

  ngOnInit(): void {
    this.dataSourceFormService.setDataSourceType(this.dataSourceType);
    // for edit mode params form already set in ngOnChanges
    this.addParamsFormIfNotAdded();

    if (this.editMode) {
      this.dataSourcePermissionService
        .canUpdateSource(this.dataSource.ownerId)
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe((canUpdate) => {
          if (!canUpdate) {
            this.form.disable();
          }
        });
    }
  }

  onPropertiesValue(value: string): void {
    this.form.patchValue({ properties: value });
  }

  private addParamsFormIfNotAdded(): void {
    if (!this.form.get('params')) {
      this.dataSourceFormService.addParamsForm(this.paramsForm);
    }
  }
}
