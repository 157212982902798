<div *ngIf="showFl" class="ddp-layout-popuptype">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-ui-popup">
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">
        {{ 'msg.board.datasource.alias.for.value.title' | translate }}
      </span>

      <div class="ddp-ui-pop-buttons">
        <a href="javascript:" (click)="showFl = false" class="ddp-btn-pop">{{ 'msg.comm.btn.cancl' | translate }}</a>
        <a href="javascript:" (click)="done()" class="ddp-btn-pop ddp-bg-black">
          {{ 'msg.comm.btn.save' | translate }}
        </a>
      </div>
    </div>

    <div class="ddp-ui-popup-contents">
      <span class="ddp-data-alias-column">
        <em *ngIf="selectedField.logicalType?.toString() === 'BOOLEAN'" class="ddp-icon-type-tf"></em>
        <em *ngIf="selectedField.logicalType?.toString() === 'TIMESTAMP'" class="ddp-icon-type-calen"></em>
        <em
          *ngIf="
            selectedField.logicalType?.toString() === 'DOUBLE' || selectedField.logicalType?.toString() === 'INTEGER'
          "
          class="ddp-icon-type-float"
        ></em>
        <em
          *ngIf="selectedField.logicalType?.toString() === 'STRING' || selectedField.type === 'user_expr'"
          class="ddp-icon-type-ab"
        ></em>
        {{ selectedField.name }}
        {{
          selectedField.nameAlias.nameAlias !== selectedField.name ? '(' + selectedField.nameAlias.nameAlias + ')' : ''
        }}
      </span>

      <div class="ddp-box-copy-table">
        <a href="javascript:" class="ddp-btn-bg" (click)="resetAll()">
          <em class="ddp-icon-reset"></em>
          {{ 'msg.comm.btn.reset.all' | translate }}
        </a>

        <div class="ddp-view-grid">
          <div grid-component #gridArea style="width: 100%; height: 100%"></div>
        </div>
      </div>
    </div>
  </div>
</div>
