<div class="flex align-items-center justify-content-center mb-2">
  <div class="flex align-items-center gap-2 w-full">
    <p-dropdown
      class="w-full"
      styleClass="w-full"
      [options]="datasources$ | async"
      [formControl]="control"
      [filter]="true"
      optionLabel="name"
      optionValue="id"
      [loading]="datasourcesLoading$ | async"
      placeholder="{{ 'workflow.cubes.data-source-selector.choose-data-source' | translate }}"
      appendTo="body"
    >
      <ng-template let-dataSource pTemplate="selectedItem">
        <div class="inline-flex align-items-center">
          <i class="{{ iconsByDataSourceType.get(dataSource.params.datasourceType) }} mr-2"></i>
          {{ dataSource.name }}
        </div>
      </ng-template>
      <ng-template let-dataSource pTemplate="item">
        <div class="inline-flex align-items-center">
          <i class="{{ iconsByDataSourceType.get(dataSource.params.datasourceType) }} mr-2"></i>
          {{ dataSource.name }}
          <small class="ml-2">{{ dataSource.params.datasourceType }}</small>
        </div>
      </ng-template>
    </p-dropdown>

    <p-button
      (onClick)="onReloadDataSources()"
      class="p-button-primary"
      appearance="icon"
      icon="pi pi-refresh"
      [disabled]="datasourcesLoading$ | async"
      tooltipPosition="top"
      pTooltip="{{ 'workflow.cubes.data-source-selector.reload-data-source-list' | translate }}"
    ></p-button>

    <ng-container *ngIf="canEditMap$ | async as canEditMap">
      <a
        *ngIf="control.value as dataSourceId"
        [routerLink]="getDataSourceEditRoute | executeWith: dataSourceId"
        class="p-button p-button-icon-only p-button-primary"
        appearance="icon"
        tooltipPosition="top"
        pTooltip="{{ 'workflow.cubes.data-source-selector.go-to-edit' | translate }}"
        target="_blank"
      >
        <i class="pi {{ canEditMap.get(dataSourceId) ? 'pi-pencil' : 'pi-search' }}"></i>
      </a>
    </ng-container>
    <a
      [routerLink]="dataSourceListRoute"
      class="p-button p-button-icon-only p-button-primary"
      appearance="icon"
      tooltipPosition="top"
      pTooltip="{{ 'workflow.cubes.data-source-selector.go-to-list' | translate }}"
      target="_blank"
    >
      <i class="pi pi-list"></i>
    </a>
  </div>
</div>

<ng-container *ngIf="selectedDatasourceJson$ | async; let ds">
  <ngx-monaco-editor [disabled]="true" [options]="editorOptions" [ngModel]="ds" class="h-full"></ngx-monaco-editor>
</ng-container>
