import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { SkeletonModule } from 'primeng/skeleton';
import { CodeEditorComponentModule } from '../../../../code-editor/code-editor.module';
import { DialogHeaderComponentModule } from '../../dialog-header/dialog-header.module';
import { CodeEditorTransformationComponent } from './code-editor-transformation.component';

@NgModule({
  imports: [
    CommonModule,
    DialogHeaderComponentModule,
    FormsModule,
    CodeEditorComponentModule,
    MessageModule,
    SkeletonModule,
  ],
  declarations: [CodeEditorTransformationComponent],
  exports: [CodeEditorTransformationComponent],
})
export class CodeEditorTransformationComponentModule {}
