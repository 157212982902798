import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { WorkflowPresetsInterface } from '../../../workflow-presets/interfaces/workflow-presets.interface';
import { WorkflowSettingsSchedulesComponent } from '../../components/workflow-settings-schedules/workflow-settings-schedules.component';
import { WorkflowSettingsPresetsComponent } from '../../components/workflow-settings-presets/workflow-settings-presets.component';
import { WorkflowSettingsMetadataComponent } from '../../components/workflow-settings-metadata/workflow-settings-metadata.component';
import { ActivatedRoute } from '@angular/router';
import { WorkflowSettingsSchedulesInterface } from '../../interface/workflow-settings-schedules.interface';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowSettingsSchedulesDomainService } from '../../service/workflow-settings-schedules-domain.service';
import { WorkflowSettingsPresetsDomainService } from '../../service/workflow-settings-presets-domain.service';
import { WorkflowSettingsPresetsBindingDomainService } from '../../service/workflow-settings-presets-binding-domain.service';
import { WorkflowPresetsDomainService } from '../../../workflow-presets/services/workflow-presets-domain.service';

@Component({
  selector: 'selfai-platform-workflow-settings-container',
  standalone: true,
  imports: [
    CommonModule,
    WorkflowSettingsSchedulesComponent,
    WorkflowSettingsPresetsComponent,
    WorkflowSettingsMetadataComponent,
  ],
  templateUrl: './workflow-settings-container.component.html',
  styleUrl: './workflow-settings-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    WorkflowSettingsPresetsDomainService,
    WorkflowSettingsPresetsBindingDomainService,
    WorkflowSettingsSchedulesDomainService,
  ],
})
export class WorkflowSettingsContainerComponent implements OnInit {
  workflowId = this.activatedRoute.snapshot.params['id'];

  constructor(
    public workflowPresetsDomainService: WorkflowPresetsDomainService,
    public workflowSettingsPresetsDomainService: WorkflowSettingsPresetsDomainService,
    public workflowSettingsSchedulesDomainService: WorkflowSettingsSchedulesDomainService,
    public workflowSettingsPresetsBindingDomainService: WorkflowSettingsPresetsBindingDomainService,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogService<any>,
  ) {}

  ngOnInit(): void {
    this.workflowPresetsDomainService.getList();
    this.workflowSettingsPresetsDomainService.getItem(this.workflowId);
    this.workflowSettingsSchedulesDomainService.getList(this.workflowId);
  }

  setPreset(data: any): void {
    this.workflowSettingsPresetsBindingDomainService.add(data);
  }

  onAddSchedule(data: WorkflowSettingsSchedulesInterface): void {
    this.workflowSettingsSchedulesDomainService.add(data);
  }

  onEditSchedule(data: WorkflowSettingsSchedulesInterface): void {
    this.workflowSettingsSchedulesDomainService.update(data);
  }

  onDeleteSchedule(id: string): void {
    this.workflowSettingsSchedulesDomainService.delete(id);
  }

  editMetadata(data: any): void {}
}
