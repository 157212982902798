import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { INamespace } from '../models';
import { KE_NAMESPACES_ENTITY_NAME } from '@selfai-platform/store';
import { NamespacesDataService } from './namespaces-data.service';

@Injectable({ providedIn: 'root' })
export class NamespacesListStore extends EntityCollectionServiceBase<INamespace> {
  constructor(
    public entityDataService: EntityDataService,
    public namespacesDataService: NamespacesDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_NAMESPACES_ENTITY_NAME, namespacesDataService);
    super(KE_NAMESPACES_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
