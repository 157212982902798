import { Provider, Type } from '@angular/core';
import { SearchQueryService, UrlPageParamsService } from '@selfai-platform/shared';
import { ConfirmationService } from 'primeng/api';
import { DataListViewItem } from './models';
import { ActionsForItemService, LayoutTypeService, SelectedItemsService } from './services';
import { DataListViewBaseService } from './services/data-list-view-base.service';
import { DataListViewLazyLoadService } from './services/data-list-view-lazy-load.service';

export function provideDataListView<
  T extends DataListViewBaseService<DataListViewItem>,
  P extends ActionsForItemService<DataListViewItem>,
>(dataListImplementation: Type<T>, actionsForItemImplementation: Type<P>): Array<Provider> {
  return [
    LayoutTypeService,
    dataListImplementation,
    actionsForItemImplementation,
    {
      provide: DataListViewBaseService,
      useExisting: dataListImplementation,
    },
    {
      provide: ActionsForItemService,
      useExisting: actionsForItemImplementation,
    },
    SelectedItemsService,
    SearchQueryService,
    UrlPageParamsService,
    ConfirmationService,
    DataListViewLazyLoadService,
  ];
}
