<div class="ddp-box-page">
  <div class="ddp-page-num">
    <a
      (click)="prevPagination()"
      href="javascript:"
      class="ddp-btn-prev"
      [ngClass]="{ 'ddp-disabled': range[0] === 0 }"
    ></a>
    <a
      *ngFor="let item of range"
      (click)="changePage(item)"
      [ngClass]="{ 'ddp-selected': item === info.number }"
      href="javascript:"
      class="ddp-selected"
    >
      {{ item + 1 }}
    </a>
    <a
      (click)="nextPagination()"
      href="javascript:"
      class="ddp-btn-next"
      [ngClass]="{ 'ddp-disabled': range[range.length - 1] + 1 === info.totalPages }"
    ></a>
  </div>

  <div class="ddp-page-setting">
    <div class="ddp-wrap-edit">
      <label class="ddp-label-type">{{ 'msg.comm.ui.num.item.list' | translate }}</label>
      <div (click)="isOpenOpts = !isOpenOpts" (clickOutside)="isOpenOpts = false" class="ddp-ui-edit-option ddp-inline">
        <div [class.ddp-selected]="isOpenOpts" class="ddp-type-selectbox">
          <span class="ddp-txt-selectbox">{{ info.size }}</span>
          <ul class="ddp-list-selectbox">
            <li *ngFor="let item of pageSizes" (click)="changePageSize(item)" [class.ddp-selected]="item === info.size">
              <a href="javascript:">{{ item }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
