import { Component, Injector, ElementRef, Input, Output, EventEmitter } from '@angular/core';

import { AbstractComponent } from '../abstract.component';

@Component({
  selector: '[loading]',
  templateUrl: './loading.component.html',
})
export class LoadingComponent extends AbstractComponent {
  @Input()
  public visible = false;

  @Input()
  public cancel = false;

  @Input()
  public canceling = false;

  @Output('cancel')
  public cancelEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.visible ? this.$element.show() : this.$element.hide();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public show(): void {
    if (!this.isLoaded) {
      return;
    }

    this.visible ? this.$element.show() : this.$element.hide();
  }

  public hide(): void {
    if (!this.isLoaded) {
      return;
    }

    this.$element.hide();
  }
}
