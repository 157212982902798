import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '../../../../common/common.module';

import { TrendLineComponent } from './trend.line.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [TrendLineComponent],
  exports: [TrendLineComponent],
})
export class TrendLineModule {}
