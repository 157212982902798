import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AlertService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowListComponentService } from '../../services';
import { Message } from 'primeng/api';
import { HttpResponse } from '@angular/common/http';
import { UploadWorkflowRequest } from '../../models';
import { WorkflowDatasourceStatuses } from '../../enums';
import { FileUploadEvent } from 'primeng/fileupload';

@Component({
  selector: 'selfai-platform-workflow-upload-dialog',
  templateUrl: './workflow-upload-dialog.component.html',
  styleUrls: ['./workflow-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowUploadDialogComponent implements OnInit {
  url!: string;

  importDataSources = false;
  overrideDataSources = false;

  uploadStatuses: Message[] = [];

  constructor(
    private readonly dialogService: DialogService<{ success?: boolean }>,
    private readonly alertService: AlertService,
    private readonly workflowListComponentService: WorkflowListComponentService,
  ) {}

  ngOnInit(): void {
    this.url = this.workflowListComponentService.getWorkflowFileUploadUrl();
  }

  onError(): void {
    this.alertService.error(`Error. Can't upload file`);
  }

  onUpload(data: FileUploadEvent): void {
    const datasources = (data.originalEvent as HttpResponse<UploadWorkflowRequest>).body.datasources;
    this.uploadStatuses = datasources.map((datasource) => {
      const isSuccessStatus = [
        WorkflowDatasourceStatuses.CREATE_SUCCEED,
        WorkflowDatasourceStatuses.UPDATE_SUCCEED,
      ].includes(datasource.status);

      return {
        severity: isSuccessStatus ? 'success' : 'error',
        summary: datasource.datasourceId,
        detail: isSuccessStatus ? 'Update success' : datasource.reason,
      };
    });
  }

  prepareRequest(formData: FormData) {
    formData.append('import_datasources', this.importDataSources.toString());
    formData.append('override_datasources', this.overrideDataSources.toString());
  }
}
