<div class="chart-filter-title-content--composition">
  <div
    class="chart-filter-title-content--composition--content"
    [attr.data-gl-dragsource]="isDraggable ? 'enable' : 'disable'"
    [class.ddp-selected]="isNewFilter"
    [ngStyle]="!isDashboardMode ? { cursor: 'default' } : {}"
  >
    <div *ngIf="filter" class="inclusion-wrapper-title">
      <div class="ddp-data-itemtype ddp-type">
        <span class="ddp-icon-box">
          <selfai-bi-shared-field-icon
            *ngIf="field"
            [view]="'DIMENSION'"
            [type]="field.type"
            [logicalType]="field.logicalType"
          ></selfai-bi-shared-field-icon>
          <em class="ddp-icon-use3" *ngIf="isWidgetInLayout"></em>
        </span>

        <span class="ddp-txt-itemtype">
          <span class="ddp-data-name">{{ filter.field }}</span>
          <span class="ddp-data-sub">{{ dataSource?.name }}</span>
          <span *ngIf="!isBoardFilter" class="ddp-txt-chartname">
            {{ "msg.board.ui.filter.used-this-chart" | translate }}
          </span>
        </span>
      </div>

      <div *ngIf="filter" class="inclusion-wrapper-button">
        <div *ngIf="isDeSelected" class="inclusion-button-content">
          <em class="ddp-icon-sinfo2"></em>
          <div class="ddp-ui-tooltip-info ddp-down">
            <em class="ddp-icon-view-top"></em>
            {{ "msg.comm.ui.no.filtering" | translate }}
          </div>
        </div>

        <em class="ddp-icon-global-s" *ngIf="isBoardFilter"></em>

        <div *ngIf="filter.ui.filteringSeq > 0" class="ddp-wrap-datarecommend">
          {{ filter.ui.filteringSeq }}
          <em class="ddp-icon-recommend-s2"></em>
        </div>

        <div
          class="ddp-wrap-morebutton ddp-hover-tooltip"
          [class.ddp-selected]="isShowDetailMenu"
          (click)="toggleDetailMenu()"
          (clickOutside)="isShowDetailMenu = false"
        >
          <em class="ddp-icon-more"></em>
          <div class="ddp-ui-tooltip-info ddp-down">
            <em class="ddp-icon-view-top"></em>
            {{ "msg.comm.ui.more" | translate }}
          </div>

          <div class="ddp-wrap-popup2 ddp-types" style="min-width: 150px">
            <ul class="ddp-list-popup">
              <li *ngIf="!isDashboardMode && isBoardFilter && isChangeable">
                <a href="javascript:" (click)="changeGlobalToChart(filter)">
                  <em class="ddp-icon-drop-global2"></em>
                  {{ "msg.board.filter.ui.turn.chart" | translate }}
                </a>
              </li>
              <li *ngIf="false">
                <a href="javascript:" (click)="changeChartToGlobal(filter)">
                  <em class="ddp-icon-drop-global"></em>
                  {{ "msg.board.filter.ui.turn.global" | translate }}
                </a>
              </li>
              <li>
                <a href="javascript:" (click)="resetFilter(filter)">
                  <em class="ddp-icon-drop-refresh"></em>
                  {{ "msg.board.filter.ui.reset" | translate }}
                </a>
              </li>
              <li *ngIf="isEditable">
                <a
                  href="javascript:"
                  (click)="editFilterByPopup(originalFilter)"
                >
                  <em class="ddp-icon-drop-editmodify"></em>
                  {{ "msg.board.filter.ui.edit" | translate }}
                </a>
              </li>
              <li *ngIf="isDeletable">
                <a href="javascript:" (click)="deleteFilter(filter)">
                  <em class="ddp-icon-drop-editdel3"></em>
                  {{ "msg.board.filter.ui.remove" | translate }}
                </a>
              </li>
              Limited
            </ul>

            <div class="ddp-wrap-layer">
              <ul class="ddp-list-layer-option type-option type-sub-down">
                <li>
                  <a href="javascript:">
                    <div class="ddp-data-result ddp-type">
                      {{
                        isMultiSelector
                          ? ("msg.board.li.multiple" | translate)
                          : ("msg.board.li.single" | translate)
                      }}
                      <em class="ddp-icon-view"></em>
                    </div>
                    <span class="ddp-txt-data" title="Selection">
                      <em class="ddp-icon-drop-select"></em>
                      {{ "msg.board.li.sel.type" | translate }}
                    </span>
                  </a>
                  <div class="ddp-ui-layer-sub">
                    <ul class="ddp-list-popup">
                      <li [class.ddp-selected]="!isMultiSelector">
                        <a
                          (click)="setSelectorType('SINGLE')"
                          href="javascript:"
                        >
                          {{ "msg.board.li.single" | translate }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                      <li [class.ddp-selected]="isMultiSelector">
                        <a
                          (click)="setSelectorType('MULTI')"
                          href="javascript:"
                        >
                          {{ "msg.board.li.multiple" | translate }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <a href="javascript:">
                    <div class="ddp-data-result ddp-type">
                      {{ currentSortOptLabel }}
                      <em class="ddp-icon-view"></em>
                    </div>
                    <span class="ddp-txt-data" title="Selection">
                      <em class="ddp-icon-drop-sort"></em>
                      {{ "msg.comm.ui.soring.by" | translate }}
                    </span>
                  </a>
                  <div class="ddp-ui-layer-sub">
                    <ul class="ddp-list-popup">
                      <li
                        [class.ddp-selected]="
                          sortBy.TEXT === filter.sort.by &&
                          sortDirection.ASC === filter.sort.direction
                        "
                      >
                        <a
                          (click)="
                            sortCandidateValues(
                              filter,
                              sortBy.TEXT,
                              sortDirection.ASC
                            );
                            updateFilterEvent.emit(filter)
                          "
                          href="javascript:"
                        >
                          {{ "msg.comm.ui.soring.alphnumeric.asc" | translate }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-selected]="
                          sortBy.TEXT === filter.sort.by &&
                          sortDirection.DESC === filter.sort.direction
                        "
                      >
                        <a
                          (click)="
                            sortCandidateValues(
                              filter,
                              sortBy.TEXT,
                              sortDirection.DESC
                            );
                            updateFilterEvent.emit(filter)
                          "
                          href="javascript:"
                        >
                          {{
                            "msg.comm.ui.soring.alphnumeric.desc" | translate
                          }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-selected]="
                          sortBy.COUNT === filter.sort.by &&
                          sortDirection.ASC === filter.sort.direction
                        "
                      >
                        <a
                          (click)="
                            sortCandidateValues(
                              filter,
                              sortBy.COUNT,
                              sortDirection.ASC
                            );
                            updateFilterEvent.emit(filter)
                          "
                          href="javascript:"
                        >
                          {{ "msg.comm.ui.soring.frequency.asc" | translate }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                      <li
                        [class.ddp-selected]="
                          sortBy.COUNT === filter.sort.by &&
                          sortDirection.DESC === filter.sort.direction
                        "
                      >
                        <a
                          (click)="
                            sortCandidateValues(
                              filter,
                              sortBy.COUNT,
                              sortDirection.DESC
                            );
                            updateFilterEvent.emit(filter)
                          "
                          href="javascript:"
                        >
                          {{ "msg.comm.ui.soring.frequency.desc" | translate }}
                          <em class="ddp-icon-check"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowFilter" class="ddp-contents-divide ddp-type">
  <div class="ddp-ui-divide">
    <div
      (clickOutside)="inactiveSearchInput()"
      [class.ddp-search]="'' !== searchText"
      [class.ddp-focus]="isSearchFocus"
      [class.ddp-warning]="isOverCandidateWarning"
      class="ddp-search-value"
    >
      <a
        (click)="candidateFromSearchText()"
        href="javascript:"
        class="ddp-btn-line"
      >
        {{ "msg.comm.ui.search-all" | translate }}
      </a>
      <div class="ddp-data-value">
        <em class="icon-search-p"></em>
        <input
          #inputSearch
          [(ngModel)]="searchText"
          (focus)="isSearchFocus = true"
          (keyup)="setCandidatePage(1, true)"
          type="text"
          [placeholder]="'msg.board.custom.ui.placeholder' | translate" 
        />
        <a
          *ngIf="searchText"
          (click)="searchText = ''; candidateFromSearchText()"
          href="javascript:"
          class="ddp-btn-del"
        ></a>
        <em class="icon-warning"></em>

        <div
          class="ddp-pop-warning"
          [innerHTML]="
            'msg.board.filter.limmited-alert'
              | translate: { value: candidateListSize }
          "
        ></div>
      </div>
    </div>

    <div
      class="ddp-no-result"
      *ngIf="pageCandidateList === null || pageCandidateList.length === 0"
    >
      <div class="ddp-wrap-info" *ngIf="isOverCandidateWarning">
        <div class="ddp-ui-info">
          <em class="ddp-icon-info"></em>
          {{ "msg.board.filter.ui.search-all" | translate }}
        </div>
      </div>
      <span class="ddp-txt-noresult">{{
        "msg.comm.ui.no.rslt" | translate
      }}</span>
    </div>

    <div
      *ngIf="
        filter.showSelectedItem ||
        (pageCandidateList !== null && pageCandidateList.length > 0)
      "
    >
      <div class="ddp-select-setting">
        <ul class="ddp-list-select">
          <li *ngIf="isMultiSelector">
            <a href="javascript:" (click)="checkAll()">{{
              "msg.comm.ui.list.sel.all" | translate
            }}</a>
          </li>
          <li>
            <a href="javascript:" (click)="deselectAll()">
              <span *ngIf="!isMultiSelector">{{
                "msg.comm.ui.list.desel" | translate
              }}</span>
              <span *ngIf="isMultiSelector">{{
                "msg.comm.ui.list.desel.all" | translate
              }}</span>
            </a>
          </li>
        </ul>
        <span class="ddp-data-total">
          {{ "msg.comm.ui.total" | translate }}
          <strong>{{ totalCount }}</strong>
        </span>
      </div>

      <ul class="ddp-list-checktype ddp-list-padd" *ngIf="isMultiSelector">
        <li *ngFor="let item of pageCandidateList">
          <label class="ddp-label-checkbox">
            <input
              type="checkbox"
              (click)="onSelected(item.name, $event)"
              [checked]="filter.valueList.indexOf(item.name) > -1"
            />
            <i class="ddp-icon-checkbox ddp-dark"></i>
            <span class="ddp-txt-checkbox">
              {{
                null === item.name || "null" === item.name
                  ? "(Null)"
                  : "" === item.name || undefined === item.name
                  ? "(Empty)"
                  : item.name
              }}
              <span class="ddp-data-valuenum">{{ item.count }}</span>
            </span>
          </label>
        </li>
      </ul>
      <ul class="ddp-list-checktype ddp-list-padd" *ngIf="!isMultiSelector">
        <li *ngFor="let item of pageCandidateList">
          <label class="ddp-label-radio">
            <input
              type="radio"
              name="{{ filter.field }}"
              (click)="onSelected(item.name, $event)"
              [checked]="filter.valueList.indexOf(item.name) > -1"
            />
            <i class="ddp-icon-radio"></i>
            <span class="ddp-txt-radio">
              {{
                null === item.name || "null" === item.name
                  ? "(Null)"
                  : "" === item.name || undefined === item.name
                  ? "(Empty)"
                  : item.name
              }}
              <span class="ddp-data-valuenum">{{ item.count }}</span>
            </span>
          </label>
        </li>
      </ul>

      <div class="ddp-ui-values">
        <div class="ddp-data-values">
          <label class="ddp-label-check-value">
            <input
              (click)="
                filter.showSelectedItem = !filter.showSelectedItem;
                setCandidatePage(1, true)
              "
              [checked]="filter.showSelectedItem"
              type="checkbox"
            />
            <i></i>
          </label>
          <ng-container *ngIf="filter.showSelectedItem">
            {{
              "msg.board.filter.selected-values"
                | translate: { value: filter.valueList.length }
            }}
          </ng-container>
          <ng-container *ngIf="!filter.showSelectedItem">
            {{
              "msg.board.filter.all-values"
                | translate: { value: candidateListSize }
            }}
          </ng-container>
        </div>

        <div class="ddp-ui-page">
          <a
            (click)="setCandidatePage(currentPage - 1)"
            [class.ddp-disabled]="1 === currentPage"
            href="javascript:"
            class="ddp-btn-prev"
          ></a>
          <span class="ddp-data-page">
            <em>{{ currentPage }}</em>
            /
            <em>{{ lastPage }}</em>
          </span>
          <a
            (click)="setCandidatePage(currentPage + 1)"
            [class.ddp-disabled]="lastPage === currentPage"
            href="javascript:"
            class="ddp-btn-next"
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
