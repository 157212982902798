import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { BoundFilter } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { DatasourceService } from '../../../datasource/service/datasource.service';

declare let $;

@Component({
  selector: 'bound-filter',
  templateUrl: './bound-filter.component.html',
  styleUrls: ['./bound-filter.component.scss'],
})
export class BoundFilterComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {
  public filter: BoundFilter;
  public formControlRange!: FormControl<[number, number]>;

  @Input('filter')
  public inputFilter: BoundFilter;

  @Output()
  public changeFilterData: EventEmitter<BoundFilter> = new EventEmitter();

  constructor(
    protected datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    const minValue = this.inputFilter.min || this.inputFilter.minValue;
    const maxValue = this.inputFilter.max || this.inputFilter.maxValue;
    this.formControlRange = new FormControl<[number, number]>([minValue, maxValue]);
    this.formControlRange.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged((prve, current) => {
          return prve[0] === current[0] && prve[1] === current[1];
        }),
      )
      .subscribe((value: [number, number]) => {
        this.filter.min = value[0];
        this.filter.max = value[1];
        this.changeFilterData.emit(this.filter);
      });
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const filterChanges: SimpleChange = changes.inputFilter;
    if (filterChanges && filterChanges.currentValue) {
      const currFilter: BoundFilter = _.cloneDeep(filterChanges.currentValue);
      this.filter = currFilter;
      this._setSlider(currFilter.minValue, currFilter.maxValue);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getData(): BoundFilter {
    return this.filter;
  }

  public setFilter(filter: BoundFilter) {
    this.filter = _.cloneDeep(filter);
    this._setSlider(filter.min, filter.max);
  }

  public onRangeChange($event) {
    console.log($event);
  }

  public setMinValue(inputValue: string) {
    if (/^[+-]?\d+(\.\d+)?$/gi.test(inputValue)) {
      let numValue: number = +inputValue;

      if (numValue < this.filter.minValue) numValue = this.filter.minValue;
      if (numValue > this.filter.maxValue) numValue = this.filter.maxValue;

      if (numValue !== this.filter.min) {
        numValue > this.filter.max && (this.filter.max = numValue);
        this.filter.min = numValue;
        this.changeFilterData.emit(this.filter);
        this._setSlider(this.filter.min, this.filter.max);
      }
    }
  }

  public setMaxValue(inputValue: string) {
    if (/^[+-]?\d+(\.\d+)?$/gi.test(inputValue)) {
      let numValue: number = +inputValue;
      if (numValue < this.filter.minValue) numValue = this.filter.minValue;
      if (numValue > this.filter.maxValue) numValue = this.filter.maxValue;

      if (numValue !== this.filter.max) {
        numValue < this.filter.min && (this.filter.min = numValue);
        this.filter.max = numValue;
        this.changeFilterData.emit(this.filter);
        this._setSlider(this.filter.min, this.filter.max);
      }
    }
  }

  private _setSlider(from: number, to: number) {
    this.formControlRange?.setValue([from, to]);
  }

  private _updateBoundValue(data: { min: number; max: number; from: number; to: number }): void {
    this.filter.min = data.from;
    this.filter.max = data.to;
    this.safelyDetectChanges();
  }
}
