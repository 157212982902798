import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import $ from 'jquery';
import * as _ from 'lodash';

import { AbstractComponent } from '../../../../common/component/abstract.component';

@Component({
  selector: 'color-picker-layer',
  templateUrl: './color.picker.layer.component.html',
})
export class ColorPickerLayerComponent extends AbstractComponent {
  private DEFAULT_TITLE: string = this.translateService.instant('msg.page.chart.analysis.color.palette');

  private SELECTED_CLASS_NAME = 'ddp-selected';

  private selectedColor: object = {};

  private callBackFn: Function = () => {};

  private param: any;

  public colorList: string[];

  @Input()
  public colors: { [key: string]: string } = {};

  @Input()
  private divisionShare: number;

  @Input()
  public offsetX: string;

  @Input()
  public offsetY: string;

  @Output()
  private selected: EventEmitter<object> = new EventEmitter();

  @Output()
  private close: EventEmitter<object> = new EventEmitter();

  public isShow = true;

  @Input()
  public title: string = this.DEFAULT_TITLE;

  public colorGroup = [];

  @HostListener('document:click', ['$event.target']) clickedOutside($event) {
    if (this.isShow) {
      this.hide();
    }
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    Promise.resolve()
      .then(() => this.initialize())
      .then(() => this.createColorGroup())
      .catch((e) => console.error(e));
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public clickedInside($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
  }

  public getHeight(): number {
    const colorPickerLayerHeight: number | undefined = $('#ddpPopColorPicker').height();
    return colorPickerLayerHeight;
  }

  public show(colorHex: string = '', callBackFn: Function = () => {}, param?: any): void {
    this.param = param;

    setTimeout(() => {
      Promise.resolve()
        .then(() => {
          this.callBackFn = callBackFn;

          if ('' !== colorHex) {
            this.colorSelected(colorHex, false);
          }
        })
        .then(() => (this.isShow = true))
        .catch((e) => console.error(e));
    }, 1);
  }

  public hide(): void {
    this.close.emit({ data: _.cloneDeep(this.selectedColor), fn: this.callBackFn, param: this.param });
    this.setIsShow(false);
  }

  public initialize(): void {
    this.selectedColor = {};

    this.colorList = [
      '#c94819',
      '#007e78',
      '#02936f',
      '#4c95ce',
      '#9678bc',
      '#d57295',
      '#0d0d0d',
      '#f2f2f2',
      '#fb7100',
      '#00a99c',
      '#17d18e',
      '#589ed7',
      '#a992c7',
      '#e898b2',
      '#595959',
      '#d9d9d9',
      '#ffbe69',
      '#01cbbb',
      '#54df9a',
      '#81b8e1',
      '#c7a9c7',
      '#e898b2',
      '#7f7f7f',
      '#bfbfbf',
      '#ffd37d',
      '#70dec3',
      '#cee6d2',
      '#d3e4ef',
      '#e3d4e2',
      '#fae2ea',
      '#8f8f8f',
      '#afafaf',
    ];

    this.colors = {
      'ddp-bg1-color1': '#c94819',
      'ddp-bg1-color2': '#fb7100',
      'ddp-bg1-color3': '#ffbe69',
      'ddp-bg1-color4': '#ffd37d',

      'ddp-bg2-color1': '#007e78',
      'ddp-bg2-color2': '#00a99c',
      'ddp-bg2-color3': '#01cbbb',
      'ddp-bg2-color4': '#70dec3',

      'ddp-bg3-color1': '#02936f',
      'ddp-bg3-color2': '#17d18e',
      'ddp-bg3-color3': '#54df9a',
      'ddp-bg3-color4': '#cee6d2',

      'ddp-bg4-color1': '#4c95ce',
      'ddp-bg4-color2': '#589ed7',
      'ddp-bg4-color3': '#81b8e1',
      'ddp-bg4-color4': '#d3e4ef',

      'ddp-bg5-color1': '#9678bc',
      'ddp-bg5-color2': '#a992c7',
      'ddp-bg5-color3': '#c7a9c7',
      'ddp-bg5-color4': '#e3d4e2',

      'ddp-bg6-color1': '#d57295',
      'ddp-bg6-color2': '#e898b2',
      'ddp-bg6-color3': '#e898b2',
      'ddp-bg6-color4': '#fae2ea',

      'ddp-bg7-color1': '#0d0d0d',
      'ddp-bg7-color2': '#595959',
      'ddp-bg7-color3': '#7f7f7f',
      'ddp-bg7-color4': '#8f8f8f',

      'ddp-bg8-color1': '#f2f2f2',
      'ddp-bg8-color2': '#d9d9d9',
      'ddp-bg8-color3': '#bfbfbf',
      'ddp-bg8-color4': '#afafaf',
    };

    this.divisionShare = 4;

    this.offsetX = '0px';

    this.offsetY = '0px';

    this.isShow = false;

    this.title = this.DEFAULT_TITLE;

    this.colorGroup = [];
  }

  public colorSelected(color: string, emitEventUseFl: boolean = true): void {
    Promise.resolve()
      .then(() => {
        const className: string = this.findClass(color);

        this.selectedColor = {};

        this.setSelectedColor(className, color);

        this.allUnSelection();

        this.addClassByClassName(className);

        if (emitEventUseFl) {
          this.onSelected();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  private findClass(colorHex: string): string {
    let className = '';

    this.colorGroup.forEach((color) => {
      color.forEach((group) => {
        group.forEach((c) => {
          if (c[1] === colorHex) {
            className = c[0];
          }
        });
      });
    });

    return className;
  }

  private allUnSelection(): void {
    this.colorGroup.forEach((color) => {
      color.forEach((group) => {
        group.forEach((c) => {
          const className: string = c[0];
          this.$element.find(`#${className}`).removeClass(this.SELECTED_CLASS_NAME);
        });
      });
    });
  }

  private createColorGroup(): void {
    this.colorGroup = [];

    const array = Object.keys(this.colors).map((key) => {
      return [key, this.colors[key]];
    });

    let colorGroup = [];
    array.forEach((color, index) => {
      index++;

      colorGroup.push(color);

      if (index !== 0 && index % this.divisionShare === 0) {
        this.colorGroup.push([colorGroup]);
        colorGroup = [];
      } else {
        if (array.length / this.divisionShare === this.colorGroup.length) {
          if (index % this.divisionShare === this.divisionShare - 1) {
            this.colorGroup.push([colorGroup]);
          }
        }
      }
    });
  }

  private onSelected(): void {
    this.selected.emit({ data: _.cloneDeep(this.selectedColor), fn: this.callBackFn, param: this.param });
    this.setIsShow(false);
  }

  private addClassByClassName(className: string): void {
    if (!_.isEmpty(className)) {
      this.$element.find(`#${className}`).addClass(this.SELECTED_CLASS_NAME);
    }
  }

  private setSelectedColor(className: string, color: string): void {
    if (!_.isEmpty(className) && !_.isEmpty(color)) {
      this.selectedColor = { className: className, colorHex: color };
    }
  }

  private setIsShow(isShow: boolean): void {
    this.isShow = isShow;
  }
}
