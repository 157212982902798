export enum FieldFormatType {
  DATE_TIME = 'time_format',
  UNIX_TIME = 'time_unix',
  TEMPORARY_TIME = 'time_temporary',

  GEO_POINT = 'geo_point',
  GEO_LINE = 'geo_line',
  GEO_POLYGON = 'geo_polygon',
}

export enum FieldFormatUnit {
  SECOND = 'SECOND',
  MILLISECOND = 'MILLISECOND',
}
