<div class="">
  <div class="grid table-row">
    <div class="col-12">
      <selfai-platform-variables-table></selfai-platform-variables-table>
      <p class="validation-error" *ngIf="validationErrors">{{ validationErrors }}</p>
    </div>
  </div>
  <div class="grid button-row">
    <div class="col-12">
      <button pButton type="button" class="p-button p-button-primary mr-3" (click)="saveVariables()">Save</button>
      <button pButton type="button" class="p-button p-button-secondary mr-3" (click)="closeEditor()">Cancel</button>
    </div>
  </div>
</div>
