import { BaseOption, UIOption } from '@selfai-platform/bi-domain';
import { Observable } from 'rxjs';

/**
 * @deprecated use config builders ans UiSettingsService instead
 */
export abstract class ChartOptionsService {
  /**
   * @deprecated use intead getEChartOptions() or chartOptions$ for observable
   */
  // eslint-disable-next-line deprecation/deprecation
  abstract initEChartOptions(): BaseOption;
  abstract setUiOptions(uiOptions: UIOption): void;
  abstract getUiOptions(): UIOption;
  abstract setEChartOptions(chartOptions: BaseOption): void;
  abstract getEChartOptions(): BaseOption;
  abstract uiOptions$: Observable<UIOption>;
  abstract chartOptions$: Observable<BaseOption>;
}
