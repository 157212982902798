<div class="ddp-ui-option-down ddp-lnb-down ddp-selected" [class.ddp-selected]="'cluster' == dataSubLayerKey">
  <div class="ddp-ui-down-title" (click)="clickDataSubPanel('cluster', $event)">
    <em class="ddp-icon-menumove"></em>
    {{ 'msg.page.analysis.cluster.title' | translate }}
  </div>
  <div class="ddp-ui-down-contents">
    <div class="ddp-ui-active">
      <div class="ddp-wrap-option-slider">
        <span class="ddp-label-slider">{{ 'msg.page.prediction.span.active' | translate }}</span>

        <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
          <input type="checkbox" value="None" id="check2" name="check" />
          <label for="check2"><span class="ddp-slide"></span></label>
        </div>
      </div>

      <div class="ddp-data-detail">
        {{ 'msg.page.analysis.cluster.allow.use.description' | translate }}
        <br />
        - {{ 'msg.page.ui.scatter' | translate }}
      </div>
    </div>

    <div class="ddp-wrap-option-checkbox ddp-clear">
      <div class="ddp-col-8">
        <label class="ddp-label-type">
          {{ 'msg.page.analysis.cluster.allow.section.count' | translate }}
        </label>
      </div>
      <div class="ddp-col-4">
        <component-select [array]="sectionList" (onSelected)="onChangeSection($event)"></component-select>
      </div>
    </div>

    <ul class="ddp-list-type">
      <li *ngFor="let item of clusterList; let index = index">
        <span class="ddp-box-color ddp-selected" style="background: #5fd7a5"></span>
        <span class="ddp-data-name">{{ 'msg.page.analysis.cluster.title' | translate }}{{ index + 1 }}</span>
      </li>
    </ul>
  </div>
</div>
