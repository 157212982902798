import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import enTranslates from '../../assets/i18n/en.json';
import ruTranslates from '../../assets/i18n/ru.json';

export class PreImportedTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<Record<string, string>> {
    if (lang === 'en') {
      return of(enTranslates);
    }

    return of(ruTranslates);
  }
}
