import { isUndefined } from 'lodash';

import {
  Dashboard,
  BoardConfiguration,
  DashboardLayout,
  LayoutWidgetInfo,
  createBoardConfiguration,
  createLayoutWidgetInfo,
  createDashboardLayout,
  createBoardGlobalOptions,
  Widget,
  PageWidgetConfiguration,
  FilterWidgetConfiguration,
  TimeFilter,
  Filter,
  IntervalFilter,
  TimeUnit,
} from '@selfai-platform/bi-domain';

import { DashboardUtil } from '../../../../dashboard/util/dashboard.util';
import { FilterUtil } from '../../../../dashboard/util/filter.util';

export function convertSpecToUI(boardInfo: Dashboard): Dashboard {
  boardInfo.configuration || (boardInfo.configuration = createBoardConfiguration());
  boardInfo.configuration.options || (boardInfo.configuration.options = createBoardGlobalOptions());

  const boardConf: BoardConfiguration = boardInfo.configuration;

  const layoutData: DashboardLayout = createDashboardLayout();
  boardConf.content && (layoutData.content = boardConf.content);
  boardConf.layout = layoutData;

  let layoutWidgets: LayoutWidgetInfo[] = boardConf.widgets;
  layoutWidgets || (layoutWidgets = []);

  let widgets: Widget[] = boardInfo.widgets;
  widgets || (widgets = []);

  layoutWidgets.forEach((item) => {
    isUndefined(item.isSaved) && (item.isSaved = true);
    isUndefined(item.isInLayout) && (item.isInLayout = true);
  });

  if (layoutWidgets.length !== widgets.length) {
    widgets.forEach((item) => {
      const idx: number = layoutWidgets.findIndex((widgetItem) => widgetItem.ref === item.id);
      -1 === idx && layoutWidgets.push(createLayoutWidgetInfo(item.id, item.type));
    });
  }

  boardConf.widgets = layoutWidgets;

  let filters = boardConf.filters;
  filters || (filters = []);

  filters.forEach((item: Filter, idx: number) => {
    if ('interval' === item.type) {
      filters[idx] = FilterUtil.convertIntervalToTimeFilter(<IntervalFilter>item, boardInfo);
    } else if (FilterUtil.isTimeFilter(item)) {
      const timeFilter: TimeFilter = <TimeFilter>item;
      timeFilter.timeUnit || (timeFilter.timeUnit = TimeUnit.NONE);
      timeFilter.clzField = DashboardUtil.getFieldByName(boardInfo, item.dataSource, item.field);
    }
  });

  boardInfo.configuration = boardConf;

  boardInfo.widgets.forEach((item) => {
    if ('filter' === item.type) {
      const conf = <FilterWidgetConfiguration>item.configuration;
      const filter: Filter = conf.filter;
      if (FilterUtil.isTimeFilter(filter)) {
        const timeFilter: TimeFilter = <TimeFilter>filter;
        timeFilter.timeUnit || (timeFilter.timeUnit = TimeUnit.NONE);
        timeFilter.clzField = DashboardUtil.getFieldByName(boardInfo, filter.dataSource, timeFilter.field);
      } else if ('interval' === filter.type) {
        conf.filter = FilterUtil.convertIntervalToTimeFilter(<IntervalFilter>filter, boardInfo);
      }
    } else if ('page' === item.type) {
      const conf = <PageWidgetConfiguration>item.configuration;
      if (conf.filters) {
        conf.filters.forEach((filter: Filter, idx: number) => {
          if (FilterUtil.isTimeFilter(filter)) {
            const timeFilter: TimeFilter = <TimeFilter>filter;
            timeFilter.timeUnit || (timeFilter.timeUnit = TimeUnit.NONE);
            timeFilter.clzField = DashboardUtil.getFieldByName(boardInfo, conf.dataSource.engineName, timeFilter.field);
          } else if ('interval' === filter.type) {
            conf.filters[idx] = FilterUtil.convertIntervalToTimeFilter(<IntervalFilter>filter, boardInfo);
          }
        });
      }
    }
  });

  return boardInfo;
}
