import { Injector } from '@angular/core';
import {
  AppInitService,
  ConfigService,
  PermissionService,
  SelfaiAuthService,
  resolveIdentityProvider,
} from '@selfai-platform/shared';

export function onAppInitModule(
  configService: ConfigService,
  permissionService: PermissionService,
  injector: Injector,
  identityProviderImplentationService: SelfaiAuthService,
  appInitService: AppInitService,
) {
  return async () => {
    await configService.init();
    await resolveIdentityProvider(configService, permissionService, injector, identityProviderImplentationService);
    appInitService.setIdentityProviderLoaded();
  };
}
