/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterViewInit, Component, OnInit } from '@angular/core';

import {
  Axis,
  AxisType,
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartSelectMode,
  ChartType,
  DataZoomType,
  Pivot,
  PointShape,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartAxis,
  UIChartAxisGrid,
  UIChartAxisLabelValue,
  UIChartFormat,
  UIOption,
  UIScatterChart,
  UiChartDataLabelDisplayType,
  createChartSelectInfo,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import {
  ComposeOption,
  DataZoomComponentOption,
  MarkLineComponentOption,
  PiecewiseVisualMapComponentOption,
  ScatterSeriesOption,
  SeriesOption,
  ToolboxComponentOption,
  TooltipComponentOption,
} from 'echarts';
import * as _ from 'lodash';
import { AxisOptionConverter, FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

import optGen = OptionGenerator;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'scatter-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class ScatterChartComponent extends BaseChart implements OnInit, AfterViewInit {
  public override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) >
        0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) == 0
    );
  }

  public override draw(isKeepRange?: boolean): void {
    this.fieldInfo.aggs = this.pivot.aggregations.map((item) => {
      return !_.isEmpty(item.alias) ? item.alias : item.name;
    });

    const symbolNameList = this.data.columns.map((column) => {
      return column.name;
    });

    this.pivotInfo = createPivotTableInfo([], [], symbolNameList);

    super.draw(isKeepRange);
  }

  public override addChartMultiSelectEventListener(): void {
    this.chart.off('brushDragEnd');
    this.chart.on('brushDragEnd', (params: any) => {
      const selectDataList = [];

      const selectedBrushData: any = params.brushSelectData[0].selected;

      if (!selectedBrushData.some((item) => item.dataIndex && 0 < item.dataIndex.length)) {
        this.chart.dispatchAction({
          type: 'brush',
          command: 'clear',
          areas: [],
        });

        return;
      }

      // TODO: check to work or not
      this.chart.dispatchAction({
        type: 'brush',
        command: 'clear',
        areas: [],
      });

      this.chartOption = this.selectionAdd(this.chartOption, selectedBrushData, true);

      const cols = this.pivotInfo.cols;

      const aggs = this.pivotInfo.aggs;

      selectedBrushData.map((selected) => {
        const colIdxList = selected.dataIndex;
        colIdxList.map((colIdx) => {
          const dataName = !_.isEmpty(cols) ? cols[colIdx] : aggs[colIdx];

          _.each(_.split(dataName, CHART_STRING_DELIMITER), (name, idx) => {
            const shelveData = !_.isEmpty(cols) ? this.pivot.columns[idx] : this.pivot.aggregations[idx];

            if (
              -1 ===
              _.findIndex(selectDataList, (obj) => {
                return obj.name === shelveData.name;
              })
            ) {
              selectDataList.push(shelveData);
            }

            selectDataList[idx].data = _.union(selectDataList[idx].data, [name]);
          });
        });
      });

      if (this.params.externalFilters) this.params.externalFilters = false;

      this.apply(false);
      this.lastDrawSeries = _.cloneDeep(this.chartOption['series'] as SeriesOption[]);

      this.params['selectType'] = 'MULTI';
      this.chartSelectInfo.emit(createChartSelectInfo(ChartSelectMode.ADD, selectDataList, this.params));
    });
  }

  public override addLegendSelectEventListener(): void {
    this.chart.off('legendselectchanged');
    this.chart.on('legendselectchanged', (params: any) => {
      // TODO: doesn't have this typings
      // if (!(this.chartOption.legend as LegendComponentOption).seriesSync) {
      //   const series = this.chartOption.series;
      //   const selectedName = params.name;
      //   const isSelected = params.selected[selectedName];
      //   let fieldIdx: number;
      //   let pivotType: any;
      //   if (_.eq(this.uiOption.color.type, ChartColorType.DIMENSION)) {
      //     const targetField = (<UIChartColorByDimension>this.uiOption.color).targetField;
      //     _.forEach(this.fieldOriginInfo, (value, key) => {
      //       if (_.indexOf(value, targetField) > -1) {
      //         fieldIdx = _.indexOf(value, targetField);
      //         pivotType = _.eq(key, ChartPivotType.COLS)
      //           ? ChartPivotType.COLS
      //           : _.eq(key, ChartPivotType.ROWS)
      //           ? ChartPivotType.ROWS
      //           : ChartPivotType.AGGS;
      //       }
      //     });
      //   } else if (_.eq(this.uiOption.color.type, ChartColorType.SERIES)) {
      //     pivotType = ChartPivotType.AGGS;
      //   }
      //   if(Array.isArray(series)){
      //     series.map((obj) => {
      //       obj.data.map((valueData, idx) => {
      //         const compareName = _.split(valueData.name, CHART_STRING_DELIMITER)[fieldIdx];
      //         if (_.eq(compareName, selectedName)) {
      //           if (_.isObject(valueData)) {
      //             const originValue = _.isUndefined(obj.originData[idx].value)
      //               ? obj.originData[idx]
      //               : obj.originData[idx].value;
      //             obj.data[idx].value = isSelected ? originValue : null;
      //           } else {
      //             obj.data[idx] = isSelected ? obj.originData[idx] : null;
      //           }
      //         }
      //       });
      //       return obj;
      //     })
      //   }
      //   this.apply(false);
      // }
    });
  }

  protected override initOption(): ComposeOption<ToolboxComponentOption> {
    return {
      type: ChartType.SCATTER,
      grid: [OptionGenerator.Grid.bothMode(10, 0, 0, 0, false, true)],
      legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
      xAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      yAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      dataZoom: [
        OptionGenerator.DataZoom.horizontalDataZoom(),
        OptionGenerator.DataZoom.verticalDataZoom(),
        OptionGenerator.DataZoom.verticalInsideDataZoom(),
        OptionGenerator.DataZoom.horizontalInsideDataZoom(),
      ],
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      brush: OptionGenerator.Brush.selectBrush(),
      series: [],
    };
  }

  protected override convertSeriesData(): ComposeOption<ToolboxComponentOption> {
    this.chartOption.series = [
      {
        name: _.join(this.fieldInfo.aggs, CHART_STRING_DELIMITER),
        type: SeriesType.SCATTER,
        large: true,
        largeThreshold: 5000,
        data: [],
        symbolSize: 15,
        symbol: String((<UIScatterChart>this.uiOption).pointShape),
        itemStyle: {},
        label: {
          show: false,
          position: Position.TOP,
        },
      },
    ];

    this.chartOption.series[0].data = this.data.columns.map((item) => {
      item.selected = false;
      item.itemStyle = optGen.ItemStyle.opacity1();

      return item;
    });

    this.setUIData();

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.symbolFill();

    this.chartOption = this.symbolType();

    if (Array.isArray(this.chartOption.series)) {
      this.chartOption.series.forEach((series: ScatterSeriesOption) => {
        series.label.formatter = (params): any => {
          const option = this.chartOption.series[params.seriesIndex];

          let uiData = _.cloneDeep(option.data);

          if (uiData && uiData instanceof Array) uiData = option.data[params.dataIndex];

          return this.getFormatScatterValueSeries(params, this.uiOption.valueFormat, this.uiOption, series, uiData);
        };
      });
    }

    return this.chartOption;
  }

  protected override additionalTooltip(): BaseOption {
    (this.chartOption.tooltip as TooltipComponentOption).formatter = (params) => {
      const option = this.chartOption.series[params.seriesIndex];

      let uiData = _.cloneDeep(option.data);

      if (uiData && uiData instanceof Array) uiData = option.data[params.dataIndex];

      return this.getFormatScatterValueSeriesTooltip(params, this.uiOption.valueFormat, this.uiOption, option, uiData);
    };

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
    this.addChartMultiSelectEventListener();
    this.addLegendSelectEventListener();
  }

  protected override additionalDataZoom(): BaseOption {
    return this.chartOption;
  }

  protected override convertXAxisData(): BaseOption {
    const xAxis = this.fieldInfo.cols;

    const xAxisName = this.uiOption.xAxis.customName
      ? this.uiOption.xAxis.customName
      : _.join(xAxis, CHART_STRING_DELIMITER);

    this.chartOption.xAxis[0].name = xAxisName;

    this.chartOption.xAxis[0].axisName = _.join(xAxis, CHART_STRING_DELIMITER);

    return this.chartOption;
  }

  protected override convertYAxisData(): BaseOption {
    const yAxis = this.fieldInfo.rows;

    const yAxisName = this.uiOption.yAxis.customName
      ? this.uiOption.yAxis.customName
      : _.join(yAxis, CHART_STRING_DELIMITER);

    this.chartOption.yAxis[0].name = yAxisName;

    this.chartOption.yAxis[0].axisName = _.join(yAxis, CHART_STRING_DELIMITER);

    return this.chartOption;
  }

  protected override setUIData(): any {
    _.each(this.data.columns, (data, index) => {
      data.seriesName = _.cloneDeep(data.name);

      data.xAxisValue = _.cloneDeep(this.originalData.columns[index].value[0]);
      data.yAxisValue = _.cloneDeep(this.originalData.columns[index].value[1]);
    });

    return this.data.columns;
  }

  protected override calculateBaseline(baseline: number, result: any, isYAsis: boolean): void {
    const seriesList = [];
    result.data.columns.map((column, index) => {
      const nameArr = _.split(column.name, CHART_STRING_DELIMITER);
      let name = '';
      if (nameArr.length > 1) {
        nameArr.map((temp, index) => {
          if (index < nameArr.length - 1) {
            if (index > 0) {
              name += CHART_STRING_DELIMITER;
            }
            name += temp;
          }
        });
      } else {
        name = nameArr[0];
      }

      let isAlready = false;
      seriesList.map((series, index) => {
        if (series == name) {
          isAlready = true;

          return false;
        }
      });

      if (!isAlready) {
        seriesList.push(name);
      }
    });

    if (!result.data.categories || result.data.categories.length == 0) {
      result.data.columns.map((column, index) => {
        column.value.map((value, index) => {
          if ((isYAsis && index == 1) || (!isYAsis && index == 0)) {
            if (value > 0) {
              column.value[index] = value - baseline;
            } else {
              column.value[index] = (Math.abs(value) + Math.abs(baseline)) * -1;
            }
          }
        });
      });
    } else {
      const categoryVal = [];
      const categoryPer = [];
      for (let num = 0; num < result.data.categories.length; num++) {
        const category = result.data.categories[num];
        for (let num2 = 0; num2 < category.value.length; num2++) {
          const value = category.value[num2];
          const index = num * category.value.length + num2;
          const baselineGap = Math.abs(value - baseline);
          const baselinePer = baselineGap / Math.abs(value);
          categoryVal[index] = value;
          categoryPer[index] = baselinePer;
        }
      }

      result.data.columns.map((column, index) => {
        column.value.map((value, index) => {
          if ((isYAsis && index == 1) || (!isYAsis && index == 0)) {
            if (categoryVal[index] < baseline) {
              column.value[index] = Math.abs(value) * categoryPer[index] * -1;
            } else {
              column.value[index] = Math.abs(value) * categoryPer[index];
            }
          }
        });
      });
    }
  }

  public override addChartDatazoomEventListener(): void {
    this.chart.off('datazoom');
    this.chart.on('datazoom', (param: any) => {
      (this.chartOption.dataZoom as DataZoomComponentOption[]).map((zoom, index) => {
        if (
          _.eq(zoom.type, DataZoomType.SLIDER) &&
          !_.isUndefined(param) &&
          !_.isUndefined(param.dataZoomId) &&
          param.dataZoomId.indexOf(index) != -1
        ) {
          this.uiOption.chartZooms[index].start = param.start;
          this.uiOption.chartZooms[index].end = param.end;
        }
      });
    });
  }

  private symbolFill(): BaseOption {
    const opacity: number = (<UIScatterChart>this.uiOption).pointTransparency;

    if (!opacity) return this.chartOption;

    const series = this.chartOption.series;

    _.each(series, (obj: MarkLineComponentOption) => {
      if (_.isUndefined(obj.itemStyle)) obj.itemStyle = {};
      obj.itemStyle.opacity = opacity;
    });

    return this.chartOption;
  }

  private symbolType(): BaseOption {
    const type: PointShape = (<UIScatterChart>this.uiOption).pointShape;

    if (!type) return this.chartOption;

    const series: ScatterSeriesOption[] = this.chartOption.series as ScatterSeriesOption[];
    _.each(series, (obj) => {
      obj.symbol = type.toString().toLowerCase();
    });

    const visualMap = this.chartOption.visualMap as PiecewiseVisualMapComponentOption | undefined;
    if (visualMap?.pieces) {
      visualMap.pieces.forEach((item) => {
        item.symbol = type.toString().toLowerCase() as SymbolType;
      });
    }

    return this.chartOption;
  }

  private getFormatScatterValueSeries(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;

      let result: string[] = [];

      if (
        uiData['seriesName'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)
      ) {
        const seriesNameList = _.split(uiData['seriesName'], CHART_STRING_DELIMITER);
        result = FormatOptionConverter.getTooltipName(seriesNameList, this.pivot.aggregations, result);
        isUiData = true;
      }
      if (
        uiData['xAxisValue'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.XAXIS_VALUE)
      ) {
        const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(uiOption, AxisType.X);
        const value = FormatOptionConverter.getFormatValue(
          uiData['xAxisValue'],
          axisFormat ? axisFormat : uiOption.valueFormat,
        );
        result.push(value);
        isUiData = true;
      }
      if (
        uiData['yAxisValue'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.YAXIS_VALUE)
      ) {
        const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(uiOption, AxisType.Y);
        const value = FormatOptionConverter.getFormatValue(
          uiData['yAxisValue'],
          axisFormat ? axisFormat : uiOption.valueFormat,
        );
        result.push(value);
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }

        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }

  private getFormatScatterValueSeriesTooltip(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);

      let result: string[] = [];

      if (
        uiData['seriesName'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)
      ) {
        const seriesNameList = _.split(uiData['seriesName'], CHART_STRING_DELIMITER);
        result = FormatOptionConverter.getTooltipName(seriesNameList, this.pivot.aggregations, result, true);
      }
      if (
        uiData['xAxisValue'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.XAXIS_VALUE)
      ) {
        const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(uiOption, AxisType.X);
        const seriesValue = FormatOptionConverter.getTooltipValue(
          this.fieldInfo.cols[0],
          this.pivot.columns,
          axisFormat ? axisFormat : uiOption.valueFormat,
          uiData['xAxisValue'],
        );

        result.push(seriesValue);
      }
      if (
        uiData['yAxisValue'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.YAXIS_VALUE)
      ) {
        const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(uiOption, AxisType.Y);
        const seriesValue = FormatOptionConverter.getTooltipValue(
          this.fieldInfo.rows[0],
          this.pivot.rows,
          axisFormat ? axisFormat : uiOption.valueFormat,
          uiData['yAxisValue'],
        );

        result.push(seriesValue);
      }

      return result.join('<br/>');
    }

    return FormatOptionConverter.noUIDataFormatTooltip(uiOption, params, format, this.fieldInfo);
  }

  protected override calculateMinMax(grid: UIChartAxisGrid, result: any, isYAsis: boolean): void {}

  protected override additionalYAxis(): BaseOption {
    const axisOption: UIChartAxis[] = AxisOptionConverter.getAxisOption(this.uiOption, AxisType.Y);

    const axis: Axis[] = this.chartOption[AxisType.Y] as Axis[];

    _.each(axis, (option: Axis, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        _.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid
      ) {
        let min = null;
        let max = null;
        let calculateMin = null;
        this.data.columns.map((column, index) => {
          column.value.map((value, index) => {
            if (index == 1) {
              if (min == null || value < min) {
                min = value;
              }
              if (max == null || value > max) {
                max = value;
              }
            }
          });
        });

        calculateMin = Math.ceil(min - (max - min) * 0.05);

        AxisOptionConverter.axisMinMax[AxisType.Y].min = min;
        AxisOptionConverter.axisMinMax[AxisType.Y].max = max;

        let baseline = 0;
        if (axisOption[index].baseline && axisOption[index].baseline != 0) {
          baseline = <number>axisOption[index].baseline;
        }

        if (baseline == 0 && axisOption[index].grid.autoScaled) {
          delete option.min;
          delete option.max;
          option.scale = true;
        } else {
          delete option.scale;
        }
      }
    });

    return this.chartOption;
  }

  protected override additionalXAxis(): BaseOption {
    const axisOption: UIChartAxis[] = AxisOptionConverter.getAxisOption(this.uiOption, AxisType.X);

    const axis: Axis[] = this.chartOption[AxisType.X] as Axis[];

    _.each(axis, (option: Axis, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        _.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid
      ) {
        let min = null;
        let max = null;
        let calculateMin = null;
        this.data.columns.map((column, index) => {
          column.value.map((value, index) => {
            if (index == 0) {
              if (min == null || value < min) {
                min = value;
              }
              if (max == null || value > max) {
                max = value;
              }
            }
          });
        });

        calculateMin = Math.ceil(min - (max - min) * 0.05);

        AxisOptionConverter.axisMinMax[AxisType.X].min = min;
        AxisOptionConverter.axisMinMax[AxisType.X].max = max;

        let baseline = 0;
        if (axisOption[index].baseline && axisOption[index].baseline != 0) {
          baseline = <number>axisOption[index].baseline;
        }

        if (baseline == 0 && axisOption[index].grid.autoScaled) {
          delete option.min;
          delete option.max;
          option.scale = true;
        } else {
          delete option.scale;
        }
      }
    });

    return this.chartOption;
  }
}
