import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { KtdGridModule } from '@katoid/angular-grid-layout';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { BiChartEngineModule, GraphChartComponentModule } from '@selfai-platform/bi-chart-engine';
import { BiSharedModule } from '@selfai-platform/bi-shared';
import { AppAuthGuard } from '@selfai-platform/shared';

import { CommonModule } from '../common/common.module';
import { DataPreviewModule } from '../common/data.preview.module';
import { ImageService } from '../common/service/image.service';
import { TimezoneService } from '../data-storage/service/timezone.service';
import { DatasourceShareModule } from '../datasource/datasource-share.module';
import { DatasourceService } from '../datasource/service/datasource.service';
import { MetadataService } from '../meta-data-management/metadata/service/metadata.service';
import { AnalysisPredictionService } from '../page/component/analysis/service/analysis.prediction.service';
import { PageShareModule } from '../page/page-share.module';

import { CreateBoardCompleteComponent } from './component/create-dashboard/create-board-complete.component';
import { CreateBoardDsInfoComponent } from './component/create-dashboard/create-board-ds-info.component';
import { CreateBoardDsNetworkComponent } from './component/create-dashboard/create-board-ds-network.component';
import { CreateBoardDsRelationComponent } from './component/create-dashboard/create-board-ds-relation.component';
import { CreateBoardPopDsSelectComponent } from './component/create-dashboard/create-board-pop-ds-select.component';
import { CreateBoardPopJoinComponent } from './component/create-dashboard/create-board-pop-join.component';
import { CreateBoardPopRelationComponent } from './component/create-dashboard/create-board-pop-relation.component';
import { CreateBoardComponent } from './component/create-dashboard/create-board.component';
import { DashboardWidgetComponent } from './component/dashboard-layout/dashboard.widget.component';
import { DashboardWidgetHeaderComponent } from './component/dashboard-layout/dashboard.widget.header.component';
import { SelectionFilterComponent } from './component/selection-filter/selection-filter.component';
import { DashboardLayoutConfigComponent } from './component/update-dashboard/dashboard.layout.config.component';
import { DatasourcePanelComponent } from './component/update-dashboard/datasource-panel.component';
import { PageRelationComponent } from './component/update-dashboard/page-relation.component';
import { TextWidgetPanelComponent } from './component/update-dashboard/text-widget-panel.component';
import { UpdateDatasourceComponent } from './component/update-dashboard/update-datasource.component';
import { DashboardShareModule } from './dashboard-share.module';
import { DashboardComponent } from './dashboard.component';
import { ConfigureFiltersBoundComponent } from './filters/bound-filter/configure-filters-bound.component';
import { ConfigureFiltersInclusionComponent } from './filters/inclusion-filter/configure-filters-inclusion.component';
import { ParameterComponent } from './filters/parameter/parameter.component';
import { ConfigureFiltersTimeComponent } from './filters/time-filter/configure-filters-time.component';
import { DashboardApiLegacyService } from './service/dashboard-api.service';
import { WidgetService } from './service/widget.service';
import { UpdateDashboardComponent } from './update-dashboard.component';
import { ErrorWidgetComponent } from './widgets/error-widget.component';
import { FilterWidgetComponent } from './widgets/filter-widget/filter-widget.component';
import { PageWidgetComponent } from './widgets/page-widget/page-widget.component';
import { TextWidgetComponent } from './widgets/text-widget/text-widget.component';
import { TextWidgetUpdateComponent } from './widgets/text-widget/text-widget.update.component';

const dashboardRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AppAuthGuard],
  },
];

@NgModule({
  imports: [
    CommonModule,
    PageShareModule,
    DatasourceShareModule,
    DragulaModule,
    DashboardShareModule,
    InfiniteScrollModule,
    DataPreviewModule,
    KtdGridModule,
    DragDropModule,
    ButtonModule,
    DropdownModule,
    DialogModule,
    ToastModule,
    TranslateModule,
    TooltipModule,
    ChipModule,
    RouterModule.forChild(dashboardRoutes),
    BiChartEngineModule,
    EditorModule,
    FormsModule,
    ReactiveFormsModule,
    BiSharedModule,
    GraphChartComponentModule,
    ToolbarModule,
    SplitButtonModule,
    InputTextModule,
    ToggleButtonModule,
    MenuModule,
    OverlayPanelModule,
    TableModule,
    SpeedDialModule,
    InputTextareaModule,
  ],
  declarations: [
    DashboardComponent,
    CreateBoardComponent,
    CreateBoardCompleteComponent,
    CreateBoardDsNetworkComponent,
    CreateBoardDsInfoComponent,
    CreateBoardDsRelationComponent,
    CreateBoardPopDsSelectComponent,
    CreateBoardPopJoinComponent,
    CreateBoardPopRelationComponent,
    UpdateDatasourceComponent,
    UpdateDashboardComponent,
    SelectionFilterComponent,
    TextWidgetComponent,
    TextWidgetUpdateComponent,
    FilterWidgetComponent,
    PageWidgetComponent,
    ErrorWidgetComponent,
    ParameterComponent,
    TextWidgetPanelComponent,
    DatasourcePanelComponent,
    DashboardLayoutConfigComponent,
    DashboardWidgetComponent,
    DashboardWidgetHeaderComponent,
    PageRelationComponent,
  ],
  providers: [
    DashboardApiLegacyService,
    MetadataService,
    DatasourceService,
    WidgetService,
    ImageService,
    TimezoneService,
    AnalysisPredictionService,
  ],
  exports: [
    PageWidgetComponent,
    DashboardComponent,
    DashboardWidgetComponent,
    UpdateDashboardComponent,
    CreateBoardComponent,
    SelectionFilterComponent,
  ],
  bootstrap: [
    DashboardWidgetComponent,
    DashboardWidgetHeaderComponent,
    ConfigureFiltersTimeComponent,
    ConfigureFiltersInclusionComponent,
    ConfigureFiltersBoundComponent,
  ],
})
export class DashboardModule {}
