import { Injectable } from '@angular/core';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { WorkflowSettingsContainerComponent } from '../containers';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WorkflowSettingsService {
  constructor(private readonly dialogService: DialogService, private readonly translateService: TranslateService) {}

  public showEditor(data: any): Observable<any> {
    return this.dialogService.showDialog(WorkflowSettingsContainerComponent, {
      header: this.translateService.instant('msg.workspace.settings.schedules.modal.title'),
      width: '1200px',
      height: '75%',
      data: data,
    });
  }
}
