import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AggregatorRouteApiModel } from '@selfai-platform/pipeline-common';
import { AlertService, readJsonFile } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-platform-aggregator-router-add',
  templateUrl: './aggregator-route-add.component.html',
  styleUrls: ['./aggregator-route-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatorRouteAddComponent {
  form = new FormGroup({
    name: new FormControl('', Validators.required),
  });

  storeRoute?: AggregatorRouteApiModel;

  constructor(
    protected readonly dialogService: DialogService<{ routeName: string; routeData?: AggregatorRouteApiModel }>,
    private readonly alertService: AlertService,
    private cdr: ChangeDetectorRef,
  ) {}

  submit(): void {
    this.dialogService.close({
      routeName: this.form.value.name as string,
      routeData: this.storeRoute,
    });
  }

  closeDialog(): void {
    this.dialogService.close();
  }

  onError(): void {
    this.alertService.error(`Error. Can't upload file`);
  }

  onUpload(event: { files: File[] }): void {
    const { files } = event;

    readJsonFile<AggregatorRouteApiModel>(files[0]).subscribe({
      next: (data: AggregatorRouteApiModel) => {
        this.storeRoute = data;
        this.form.controls.name.setValue(data.name);
        this.cdr.markForCheck();
      },
      error: (err: Error) => {
        this.alertService.error(err.message);
      },
    });
  }
}
