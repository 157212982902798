
import { ShelveFieldType } from '../../../../chart';
import { AggregationType } from '../../../../datasource';
import { PivotField } from '../pivot.model';
import { createDashboardField } from './field.model';

export interface MeasureField extends PivotField {
  aggregationType: AggregationType;
}

export function createMeasureField(params: Partial<MeasureField> = {}): MeasureField {
  return {
    ...createDashboardField(),
    type: ShelveFieldType.MEASURE,
    aggregationType: AggregationType.SUM,
    ...params,
  };
}
