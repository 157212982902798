import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'component-sort-drop-box',
  templateUrl: './sort-drop-box.component.html',
  host: {
    '(document:click)': 'onClickHost($event)',
  },
})
export class SortDropBoxComponent extends AbstractComponent implements OnInit {
  public isShowSelect = false;

  @Input() public array: any[];

  @Input() public viewKey: string;

  @Input() public defaultIndex = -1;

  @Output() public onSelected = new EventEmitter();

  public selectedItem: any;

  public unselectedMessage = this.translateService.instant('msg.comm.ui.please.select');

  public isSelectListShow = false;

  public isStringArray = false;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    if (this.array != null && this.array.length > 0) {
      if (typeof this.array[0] === 'string') {
        this.isStringArray = true;
      }

      if (this.defaultIndex > -1) {
        this.selectedItem = this.array[this.defaultIndex];
      }
    }

    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public selected(item: any) {
    this.isShowSelect = false;

    this.selectedItem = item;

    this.onSelected.emit(item);
  }

  public getSelectedItem() {
    return this.selectedItem;
  }

  public onClickHost(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowSelect = false;
    }
  }
}
