<p-confirmPopup></p-confirmPopup>
<p-treeTable
  #table
  styleClass="p-treetable-sm"
  [value]="normalizeLibraryFiles"
  [scrollable]="true"
  [paginator]="true"
  [rows]="pageSize"
  [lazy]="false"
  [customSort]="false"
  [globalFilterFields]="['name']"
  dataKey="name"
>
  <ng-template pTemplate="caption">
    <div class="toolbar-left-side flex gap-2">
      <div class="ml-auto">
        {{file.files?.[0]?.name}}
      </div>
      <div>
        <p-fileUpload
          #file
          mode="basic"
          name="demo[]"
          chooseIcon="pi pi-upload"
          [customUpload]="true"
          (uploadHandler)="onUpload($event)"
          [auto]="true"
          chooseLabel="Upload"
        ></p-fileUpload>
      </div>
      <div class="toolbar-left-side flex gap-2">
        <div class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input #search pInputText type="text" (input)="onFilter(search.value)" placeholder="Search" />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr class="tr-head">
      <th pSortableColumn="name">Name</th>
      <th class="button-cell"></th>
      <th class="button-cell"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr [ttRow]="rowNode">
      <td class="pl-45">
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        <a (click)="onSelect(rowData)">{{ rowData.name }}</a>
      </td>
      <td class="button-cell">
        <button
          pButton
          type="button"
          icon="pi pi-download"
          class="p-button-text p-button-rounded"
          pTooltip="Download"
          tooltipPosition="left"
          (click)="onDownload(rowData.name)"
        ></button>
      </td>
      <td class="button-cell">
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-text p-button-rounded p-button-danger delete-button"
          pTooltip="Delete"
          tooltipPosition="left"
          (click)="onConfirmDelete($event, rowData)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
