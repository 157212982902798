import { PageWidgetConfiguration } from '../../../widget';
import { Dashboard } from '../../models';

export function shelfMigration(widgetConfiguration: PageWidgetConfiguration, boardInfo: Dashboard) {
  if (widgetConfiguration.shelf?.layers) {
    widgetConfiguration.shelf.layers = widgetConfiguration.shelf?.layers.map((layer, idx: number) => {
      if (Array === layer.constructor) {
        return {
          name: 'layer' + (idx + 1),
          ref: widgetConfiguration.dataSource?.engineName as string,
          fields: [].concat(layer),
        };
      } else {
        if ('' === layer.ref) {
          layer.ref = boardInfo.configuration.fields?.find((field) => field.name === layer.fields[0].name)
            ?.dataSource as string;
        }

        return layer;
      }
    });
  }

  widgetConfiguration.dataSource = boardInfo.configuration.dataSource;

  return widgetConfiguration;
}
