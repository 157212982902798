import { Injectable } from '@angular/core';
import {
  GraphNodeOptionSerialized,
  GraphNodeUpdateParameterValuesOptions,
  GraphNodeUpdatePropertyOptions,
  KeRefButtonItem,
  PipelineWorkflowVariableItem,
  WorkflowEditorActions,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';
import { AngularJsBridgeService } from '../../angularjs-services/services/AngularJsBridgeService';

@Injectable({ providedIn: 'root' })
export class WorkflowEditorFacadeService {
  constructor(private readonly angularBridgeService: AngularJsBridgeService) {}

  openNodeModaldialogUnselectedNode(nodeId: string): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.OpenNodeModalDialogUnselectedNode, nodeId);
  }

  openNodeModaldialogSelectedNode(nodeId: string): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.OpenNodeModalDialogSelectedNode, nodeId);
  }

  moveToNodes(nodeIds: string[]): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.MoveToNode, nodeIds);
  }

  selectNodes(nodeIds: string[]): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.SelectNode, nodeIds);
  }

  unselectNodes(): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.UnselectNode);
  }

  restoreNodes(nodeIds: string[]): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.RestoreNodes, nodeIds);
  }

  removeNodesFromRecycleBin(nodeIds: string[]): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.RemoveFromRecycleBin, nodeIds);
  }

  addNode(cubeData: GraphNodeOptionSerialized, cursorPosition: { x: number; y: number }): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.AddNode, cubeData, cursorPosition);
  }

  reOpenWorkflow(): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.ReOpen);
  }

  saveAndOpenWorkflow(workflow: WorkflowSerialized): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.SaveAndOpen, workflow);
  }

  updateNodeProperties(data: GraphNodeUpdatePropertyOptions): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.UpdateNodeProperties, data);
  }

  updateNodeParamterValues(graphNodeUpdateOptions: GraphNodeUpdateParameterValuesOptions): void {
    this.angularBridgeService.emitEventToAngularjs(
      WorkflowEditorActions.UpdateNodeParamterValues,
      graphNodeUpdateOptions,
    );
  }

  saveNodeComment(data: { nodeId: string; comment: string }): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.CubeCommentSave, data);
  }

  saveWorkflowVariables(data: PipelineWorkflowVariableItem[] | undefined): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.SaveVariables, data);
  }

  saveRefrenceButtons(data: KeRefButtonItem[] | undefined): void {
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.SaveReferenceButtons, data);
  }
}
