import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import has from 'lodash/has';
import isUndefined from 'lodash/isUndefined';
import split from 'lodash/split';
import uniqBy from 'lodash/unionBy';
import { takeUntil } from 'rxjs';

import {
  BoardDataSource,
  CustomField,
  DatasourceField as Field,
  FieldRole,
  createCustomField,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import { SelfaiTranslateService } from '@selfai-platform/shell';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { ConfirmModalComponent } from '../../../common/component/modal/confirm/confirm.component';
import { Modal } from '../../../common/domain/modal';
import { StringUtil } from '../../../common/util/string.util';
import { CommonCode } from '../../../domain/code/common-code';
import { DashboardApiLegacyService } from '../../service/dashboard-api.service';

declare let $: any;

enum ColumnType {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
  PARAMETER = 'PARAMETER',
}
@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  providers: [DestroyService],
})
export class CustomFieldComponent extends AbstractComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(ConfirmModalComponent, { static: true })
  private confirmModalComponent: ConfirmModalComponent;

  @ViewChild('calculationInput', { static: true })
  public calculationInput: ElementRef;

  @Input()
  public fields: Field[] = [];
  @Input()
  public dataSource: BoardDataSource;
  @Input()
  public customFields: CustomField[] = [];
  @Input()
  public customField: CustomField;
  @Input()
  public selectedColumnType: ColumnType;

  @Output()
  public closeDialog = new EventEmitter();
  @Output()
  public updateColumn = new EventEmitter();

  public isShow = false;
  public isEditMode = false;
  public columnType = ColumnType;
  public pageSize = 8;
  public currentPage = 1;
  public lastPage = 0;
  public columnName: string;
  public oriColumnName = '';
  public isOrdering = false;
  public orderingMode = 'DATA';
  public selectedParamColumn: Field;
  public defaultParamValue = 0;
  public minPramValue = 0;
  public maxPramValue = 0;
  public aggregated = false;
  public calculationFunctions: any[];
  public oriCalculationCategory: any[];
  public selectedCategory: CommonCode;
  public isCalFuncSuccess: any;
  public calFuncSearchText: string;
  public calculationCategory: any[];
  public selCalculationCategory: any[];
  public categoryDescription: CommonCode;
  public selectedFunction: CommonCode;
  public orderingFields: Field[];
  public pagedFields: Field[];
  public filters: Field[];
  public autocompletor: any;
  public categoryDefaultIndex: number;
  public validButtonDisabled: boolean;
  public expr: string;

  private _$calculationInput: JQuery;
  protected expressionStringDelimiter = '#';

  constructor(
    private dashboardService: DashboardApiLegacyService,
    private readonly selfaiTranslateService: SelfaiTranslateService,
    private readonly destroy$: DestroyService,

    protected elementRef: ElementRef,

    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this._$calculationInput = $('#calculationInput');
    this.calculationFunctions = [];
    this.oriCalculationCategory = [];
    this.selectedCategory = new CommonCode();
    this.setFieldPage(1);
    this.setFilters();
    this.setCalculationFunction();

    this.selfaiTranslateService.$onLanguageChange.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setCalculationFunction();
    });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public ngAfterViewInit() {
    if (this.customField) {
      this.isEditMode = true;
      this.columnName = this.customField.name;
      this.oriColumnName = this.customField.name;
      if (this.customField.role === FieldRole.DIMENSION) this.selectedColumnType = ColumnType.DIMENSION;
      else if (this.customField.role === FieldRole.MEASURE) this.selectedColumnType = ColumnType.MEASURE;
      else this.selectedColumnType = ColumnType.PARAMETER;
      this._$calculationInput.text(StringUtil.unescapeCustomColumnExpr(this.customField.expr));
      this.calValidButtonCheck();
    } else {
      this._$calculationInput.text('');
      this.setColumnName();
    }
    this.setAutoComplete();
    this._$calculationInput.trigger('focus');
    this._$calculationInput.attr(
      'placeholder',
      this.translateService.instant('msg.board.custom.ui.content.placeholder'),
    );
    this._$calculationInput.on('input', () => {
      this.calValidButtonCheck();
      this.isCalFuncSuccess = null;
    });
    this.safelyDetectChanges();
  }

  public setSearchText(srchText: string) {
    this.calFuncSearchText = srchText;
    $('div.ddp-ui-dropdown').addClass('ddp-selected');
  }

  public setSelectedCategory(selectedCategory: CommonCode) {
    this.selectedCategory = selectedCategory;
    $('div.ddp-ui-dropdown').addClass('ddp-selected');
  }

  public setFunctionsInCategory(init: boolean = false) {
    if (init) {
      if (this.oriCalculationCategory.length > 0) {
        this.oriCalculationCategory.forEach((category) => {
          category['calculationFunctions'] = filter(this.calculationFunctions, {
            commonCodeType: category.commonCodeType,
          });
        });
      }
    }
    if (this.selectedColumnType === ColumnType.DIMENSION) {
      this.calculationCategory = this.oriCalculationCategory.filter((item) => {
        return item.commonCodeType !== 'AGGREGATION' && item.commonCodeType !== 'WINDOW';
      });
    } else {
      this.calculationCategory = this.oriCalculationCategory;
    }
    this.selCalculationCategory = cloneDeep(this.calculationCategory).sort((c1, c2) => {
      if (c2.commonCodeType === 'ETC' || c1.commonCodeType === 'all') {
        return -1;
      }
      if (c1.commonCodeType === 'ETC' || c2.commonCodeType === 'all') {
        return 1;
      }
      return c1.commonCodeType > c2.commonCodeType ? 1 : -1;
    });
  }

  public addAllCategory(oriCalculationCategory: CommonCode[]) {
    const allCategory = new CommonCode();
    allCategory.commonCodeType = 'all';
    allCategory.commonCodeNm = 'ALL';
    oriCalculationCategory.push(allCategory);
    this.selectedCategory = allCategory;
  }

  public setCalculationDesciption(category: CommonCode, flag: boolean) {
    if (flag) {
      this.categoryDescription = category;
    } else if (!flag && this.selectFunction != null) {
      this.categoryDescription = this.selectedFunction;
    } else {
      this.categoryDescription = new CommonCode();
    }
  }

  public selectedCalculationFunction(functionItem: CommonCode) {
    this.selectedFunction = functionItem;
  }

  public setFieldPage(page: number, type?: string) {
    this.orderingFields = cloneDeep(this.fields);
    if (this.orderingMode != 'DATA') {
      this.orderingFields = this.orderingFields.sort((a: Field, b: Field) => {
        if (this.orderingMode === 'AZ') {
          return a.name > b.name ? 1 : -1;
        } else {
          return a.name < b.name ? 1 : -1;
        }
      });
    }
    if (type === 'prev') {
      if (page <= 0) return;
    } else if (type === 'next') {
      if (this.lastPage < page) return;
    }
    this.currentPage = page;
    let start = 0;
    let end = 0;
    if (has(this.orderingFields, 'length')) {
      const totalFieldsSize: number = this.orderingFields.length;
      this.lastPage = Math.ceil(totalFieldsSize / this.pageSize);
      start = (page - 1) * this.pageSize;
      end = start + this.pageSize;
      this.pagedFields = this.orderingFields.slice(start, end);
    }
  }

  public setOrdering(ordering: string) {
    this.orderingMode = ordering;
    this.setFieldPage(1);
  }

  public setFilters() {
    this.filters = this.fields;
    if (this.selectedColumnType === ColumnType.DIMENSION) {
      this.filters = this.filters.concat(
        this.calculationFunctions.filter((item) => {
          return item.commonCodeType !== 'AGGREGATION' && item.commonCodeType !== 'WINDOW';
        }),
      );
    } else {
      this.filters = this.filters.concat(this.calculationFunctions);
    }
  }

  public setAutoComplete() {
    const calculationInput = this._$calculationInput;
    if (has(calculationInput, 'length') && this.filters && this.calculationFunctions) {
      this.autocompletor = $('#calculationInput').atwho({
        at: '',
        data: this.filters,
        limit: 100,
        minLen: 2,
        acceptSpaceBar: true,
        displayTpl: '<li><a href="javascript:"><em class="${class}"></em>${name}</a></li>',
        callbacks: {
          beforeInsert: this.beforeInsertCallback,
          afterInsert: this.afterInsertCallback,
        },
      });
      $('.atwho-view').hide();
    }
  }

  public beforeInsertCallback(value: string, $li: any) {
    const data = $li.data().itemData;
    if (data.commonCodeType) {
      return '<span >' + value + '( <span id="focusElement"></span> )</span>';
    } else {
      let color = '#439fe5';
      if (data.role === FieldRole.DIMENSION) {
        color = '#5fd7a5';
      }
      if (has(value, 'indexOf') && value.indexOf('[') === 0) {
        return '<span style="color:' + color + '">[' + value.substring(1) + ']</span>';
      } else {
        return '<span style="color:' + color + '">[' + value + ']</span>';
      }
    }
  }

  public afterInsertCallback() {
    const sel = window.getSelection();
    const range = document.createRange();
    const focusElement = document.getElementById('focusElement');
    if (focusElement) {
      range.selectNode(focusElement);
      range.deleteContents();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  public setCalculationFunction() {
    this.addAllCategory(this.oriCalculationCategory);
    this.pageLoaderService.show();
    this.dashboardService
      .getCalculationFunction(this.dataSource.id)
      .then((result) => {
        if (result != null) {
          this.calculationFunctions = result;
          this.calculationFunctions.forEach((item) => {
            item['name'] = item.commonValue;
          });
          this.oriCalculationCategory = cloneDeep(
            this.oriCalculationCategory.concat(uniqBy(this.calculationFunctions, 'commonCodeType')),
          );
          this.categoryDefaultIndex = 0;
        } else {
          this.calculationFunctions = [];
          this.oriCalculationCategory = [];
        }
        this.setFunctionsInCategory(true);
        this.setFilters();
        this.setAutoComplete();
        this.pageLoaderService.hide();
      })
      .catch(() => {
        this.pageLoaderService.hide();
      });
  }

  public selecteParamColumn(column: Field) {
    this.selectedParamColumn = column;
    this.maxPramValue = 10;
    this.minPramValue = 5;
    this.defaultParamValue = 7;
  }

  public done() {
    const columnName = this.columnName?.trim();
    const isReservedFieldName = this.isReservedFieldName(columnName);
    const isCalFuncSuccess = this.isCalFuncSuccess === 'S';

    if (!isCalFuncSuccess || !columnName || isReservedFieldName) {
      return;
    }

    if (columnName.length > 50) {
      this.alertPrimeService.warn(
        this.translateService.instant('msg.board.custom.alert.column.length', { length: 50 }),
      );
      return;
    }

    if (this.existColumnName(this.columnName) && (!this.isEditMode || this.columnName !== this.oriColumnName)) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.custom.alert.column.overlap'));
      return;
    }

    if (
      this.isEditMode &&
      !isUndefined(this.customField.aggregated) &&
      this.customField.aggregated !== this.aggregated
    ) {
      this.setCustomFieldNotionPopup();
      return;
    }

    this.setCustomFieldData();
  }

  public selectFunction(functionItem: CommonCode) {
    this.insertAtCursor('<span>' + functionItem.commonValue + '( <span id="focusElement"></span> )</span>');
    this.calValidButtonCheck();
    event.stopPropagation();
    event.preventDefault();
  }

  public selectColumn(column: Field) {
    let color = '#439fe5';
    if (column.role === FieldRole.DIMENSION) {
      color = '#5fd7a5';
    }
    let inserColumn = '<span style="color: ' + color + '">';
    if (column.ref) {
      inserColumn += '[' + column.ref + '.' + column.name + '] <span id="focusElement"></span>';
    } else {
      inserColumn += '[' + column.name + '] <span id="focusElement"></span>';
    }
    inserColumn += '</span>';
    this.insertAtCursor(inserColumn);
    this.calValidButtonCheck();
  }

  public insertAtCursor(innerHtml) {
    this._$calculationInput.trigger('focus');
    let sel;
    let range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        const newSapn = document.createElement('span');
        newSapn.innerHTML = innerHtml;
        range.insertNode(newSapn);
      }
    }
    const focusElement = document.getElementById('focusElement');
    if (focusElement) {
      range.selectNode(focusElement);
      range.deleteContents();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  public calValidButtonCheck() {
    this.validButtonDisabled = !(
      has(this._$calculationInput.text(), 'length') && this._$calculationInput.text().length > 0
    );
  }

  public calculationValidation() {
    if (has(this._$calculationInput.text(), 'length') && this._$calculationInput.text().length > 0) {
      let expr = this._$calculationInput.text();
      expr = expr.replace(/[[\]]/g, '"');
      expr = expr.replace(/" *"\d"/g, (text) => {
        return '"[' + text.substring(text.indexOf('"', 1) + 1, text.lastIndexOf('"')) + ']';
      });
      expr = StringUtil.trim(expr);
      expr = StringUtil.replaceNbsps(expr);
      const validateParameters = {
        expr,
        dataSource: {
          type: this.dataSource.type,
          name: this.dataSource.engineName || this.dataSource.name,
          temporary: this.dataSource.temporary,
        },
      };
      this.dashboardService
        .validate(validateParameters)
        .then((result) => {
          this.aggregated = result.aggregated;
          this.isCalFuncSuccess = 'S';
          this.expr = expr;
        })
        .catch(() => {
          this.isCalFuncSuccess = 'F';
          this.expr = '';
        });
    }
  }

  public existColumnName(columnName: string) {
    let idx = findIndex(this.fields, { name: columnName });
    if (idx > -1) return true;
    idx = findIndex(this.customFields, { name: columnName });
    return idx > -1;
  }

  public setCustomFieldData(): void {
    const isDimensionOrMeasure = [ColumnType.DIMENSION, ColumnType.MEASURE].includes(this.selectedColumnType);
    const isParameter = this.selectedColumnType === ColumnType.PARAMETER;
    const isCalFuncSuccess = this?.isCalFuncSuccess !== 'F';

    if (isDimensionOrMeasure && isCalFuncSuccess) {
      let customField: CustomField;
      if (this.isEditMode) {
        customField = this.customField;
        customField.oriColumnName = this.oriColumnName;
      } else {
        customField = createCustomField();
        customField.role = this.selectedColumnType === ColumnType.MEASURE ? FieldRole.MEASURE : FieldRole.DIMENSION;
        customField.dataSource = this.dataSource.engineName ? this.dataSource.engineName : this.dataSource.name;
      }
      customField.alias = this.columnName;
      customField.name = this.columnName;
      customField.expr = StringUtil.trim(this.expr);
      customField.expr = StringUtil.replaceNbsps(this.expr);
      customField.aggregated = !!this.aggregated;

      this.updateColumn.emit({ customField, isEdit: this.isEditMode });
      return;
    }

    if (!isParameter) {
      return;
    }

    if (this.defaultParamValue == null) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.custom.alert.default.value'));
      return;
    }
    if (this.minPramValue == null) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.custom.alert.min.value'));
      return;
    }
    if (this.maxPramValue == null) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.custom.alert.max.value'));
      return;
    }
    this.alertPrimeService.warn(this.translateService.instant('msg.board.custom.alert.in.process'));
    return;
  }

  public isReservedFieldName(name: string): boolean {
    if (name === 'count' || name === '__time' || name === 'timestamp') {
      return true;
    } else {
      return false;
    }
  }

  public getDescription(commonCode: CommonCode): string {
    return commonCode.description;
  }

  public getSyntaxHtml(commonCode: CommonCode): string {
    const syntaxLabel = this.translateService.instant('msg.board.custom.th.add.function.syntax');
    let html = `<span class="ddp-ui-det-title">${split(syntaxLabel, this.expressionStringDelimiter)}</span>`;
    const syntaxList: string[] = split(commonCode.syntax, this.expressionStringDelimiter);
    html += syntaxList[0];
    if (syntaxList.length > 1) {
      for (let num = 1; num < syntaxList.length; num++) {
        html += '<br/>';
        html += syntaxList[num];
      }
    }
    if (StringUtil.isEmpty(commonCode.param)) {
      return html;
    } else {
      const paramList: string[] = split(commonCode.param, this.expressionStringDelimiter);
      html += '<ul class="ddp-list-det">';
      for (let num = 0; num < paramList.length; num++) {
        html += '<li>';
        html += paramList[num];
        html += '</li>';
      }
      html += '</ul>';
      return html;
    }
  }

  public getExampleHtml(commonCode: CommonCode): string {
    const exampleLabel = this.translateService.instant('msg.board.custom.th.add.function.example');
    let html = `<span class="ddp-ui-det-title">${split(exampleLabel, this.expressionStringDelimiter)}</span>`;
    const exampleList: string[] = split(commonCode.example, this.expressionStringDelimiter);
    for (let num = 0; num < exampleList.length; num++) {
      html += '<div class="ddp-txt-list">';
      html += exampleList[num];
      html += '</div>';
    }
    return html;
  }

  public clickCategory($event) {
    const $targetElm = $($event.target).parent();
    if (!$targetElm.hasClass('ddp-selected')) {
      $targetElm.addClass('ddp-selected');
    } else {
      $targetElm.removeClass('ddp-selected');
    }
  }

  public clickFunction($event) {
    const $targetElm = $($event.target).parent();
    $targetElm.parent().parent().parent().find('li').removeClass('ddp-selected');
    $targetElm.addClass('ddp-selected');
  }

  public checkCategory(calFunction: any[]): string {
    if (!this.calFuncSearchText) return 'block';

    return calFunction.filter(
      (item) => item['commonValue']?.toLowerCase().indexOf(this.calFuncSearchText?.toLowerCase()) !== -1,
    ).length > 0
      ? 'block'
      : 'none';
  }

  private setColumnName() {
    if (this.selectedColumnType === ColumnType.DIMENSION) {
      let index = 1;
      let columnName = '';
      while (index > 0) {
        columnName = 'DIMENSION_' + index;
        if (!this.existColumnName(columnName)) {
          this.columnName = columnName;
          index = -1;
        }
        index++;
      }
    } else if (this.selectedColumnType === ColumnType.MEASURE) {
      let index = 1;
      let columnName = '';
      while (index > 0) {
        columnName = 'MEASURE_' + index;
        if (!this.existColumnName(columnName)) {
          this.columnName = columnName;
          index = -1;
        }
        index++;
      }
    }
  }

  private setCustomFieldNotionPopup() {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.custom.filter.popup.change.aggregation.title');
    modal.description = this.translateService.instant('msg.custom.filter.popup.change.aggregation.description');
    modal.isShowCancel = true;
    modal.btnName = this.translateService.instant('msg.comm.ui.continue');
    modal.data = {
      type: 'applyAllCustomField',
    };
    this.confirmModalComponent.init(modal);
  }
}
