import { FieldRole, IngestionRule, LogicalType } from '../../../../datasource';
import { DashobardFieldType } from '../../enums/field-type.enum';
import { JoinMapping } from '../datasource';
import { DashboardField } from './field.model';
import { UserDefinedField, createUserDefinedField } from './user-defined-field.model';

export interface CustomField extends UserDefinedField {
  dataSource?: string;
  expr?: string;
  role?: FieldRole;
  dataType?: any;
  aggregated?: boolean;
  oriColumnName?: string;
  useChart: boolean;
  useFilter: boolean;
  useChartFilter: boolean;
  id?: string;
  logicalType?: LogicalType;
  seq?: number;
  type: DashobardFieldType;
  partitioned?: boolean;
  filtering?: boolean;
  filteringSeq?: number;
  filteringOptions?: any;
  mappedField?: DashboardField[];
  ingestionRule?: IngestionRule;
  description?: string;
  join?: JoinMapping;
}

export function createCustomField(params: Partial<CustomField> = {}): CustomField {
  return {
    ...createUserDefinedField(),
    oriColumnName: '',
    useFilter: false,
    useChartFilter: false,
    useChart: false,
    type: DashobardFieldType.CUSTOM,
    ...params,
  };
}
