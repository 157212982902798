import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class KdCookieGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService
      .setCredentialsFromFromProvider()
      .then(() => true)
      .catch(() => true);
  }
}
