import { Pipe, PipeTransform } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { MenuItemsService } from '../menu';

@Pipe({
  name: 'selfaiPlatformRouteIsActive',
  standalone: true,
})
export class RouteIsActivePipe implements PipeTransform {
  transform(link: string | string[]): Observable<boolean> {
    return this.menuItemsService.menu$.pipe(
      map((menu) => menu.map((item) => item.items || []).flat(1)),
      map((menu) => {
        return menu.filter((item) =>
          this.router.isActive(this.linkToUrl(item.routerLink), {
            paths: 'subset',
            queryParams: 'ignored',
            fragment: 'ignored',
            matrixParams: 'ignored',
          }),
        );
      }),
      map((activeMenuItems) => {
        if (activeMenuItems.length > 1) {
          // Find the item whose route has the most matching segments with the current route
          const mostSpecificMatch = activeMenuItems.reduce((prev, curr) => {
            const prevMatchSegments = this.countMatchingSegments(prev.routerLink);
            const currMatchSegments = this.countMatchingSegments(curr.routerLink);
            return currMatchSegments > prevMatchSegments ? curr : prev;
          });

          return this.linkToUrl(mostSpecificMatch.routerLink) === this.linkToUrl(link);
        }

        return this.router.isActive(this.linkToUrl(link), {
          paths: 'subset',
          queryParams: 'ignored',
          fragment: 'ignored',
          matrixParams: 'ignored',
        });
      }),
    );
  }

  constructor(private readonly menuItemsService: MenuItemsService, private readonly router: Router) {}

  // Method to count the number of matching segments between the link and the current active URL
  private countMatchingSegments(link: string | string[]): number {
    const currentUrlTree = this.router.parseUrl(this.router.url);
    const routerLinkTree = this.createUrlTree(link);

    const currentSegments = currentUrlTree.root.children['primary']?.segments || [];
    const routerLinkSegments = routerLinkTree.root.children['primary']?.segments || [];

    // Count the number of matching segments
    let matches = 0;
    for (let i = 0; i < Math.min(currentSegments.length, routerLinkSegments.length); i++) {
      if (currentSegments[i].path === routerLinkSegments[i].path) {
        matches++;
      } else {
        break; // Stop if segments no longer match
      }
    }

    return matches;
  }

  private createUrlTree(link: string | string[]): UrlTree {
    if (Array.isArray(link)) {
      return this.router.createUrlTree(link);
    }

    return this.router.parseUrl(link);
  }

  private linkToUrl(link: string | string[]): string {
    if (Array.isArray(link)) {
      return '/' + link.filter((v) => v !== '/').join('/');
    }

    return link.startsWith('/') ? link : '/' + link;
  }
}
