import { Inject, Injectable } from "@angular/core";
import { SELFAI_AVALIABLE_LANGUAGES } from "./avaliable-languages.token";
import { UiLanguages } from "./ui-languages";
import { BehaviorSubject } from "rxjs";
import { LanguageDefinition } from "./language-definition";
import { SELFAI_LANGUAGES } from "./language-definitions";

@Injectable()
export class LanguagesForSelectService extends BehaviorSubject<LanguageDefinition[]>{
    constructor(@Inject(SELFAI_AVALIABLE_LANGUAGES) avaliableLanguages: UiLanguages[]) {
        const avaliableDefinitions = SELFAI_LANGUAGES.filter(t => avaliableLanguages.includes(t.value));
        super(avaliableDefinitions);
    }
}