import * as _ from 'lodash';

import { isNullOrUndefined } from '@selfai-platform/shared';

import { CommonConstant } from '../constant/common.constant';
import { CookieConstant } from '../constant/cookie.constant';
import { Modal } from '../domain/modal';

export class CommonUtil {
  public static getLoginUserId(): string {
    return CommonConstant.cookieService.get(CookieConstant.KEY.LOGIN_USER_ID);
  }

  public static getMsgCodeBySystemRole(name: string): string {
    switch (name) {
      case '__ADMIN':
        return 'msg.permission.ui.sys-perm.system-setting';
      case '__DATA_MANAGER':
        return 'msg.permission.ui.sys-perm.data-mngt';
      case '__PERMISSION_MANAGER':
        return 'msg.permission.ui.sys-perm.ws-manager';
      case '__PRIVATE_USER':
        return 'msg.permission.ui.sys-perm.personal-ws';
      case '__SHARED_USER':
        return 'msg.permission.ui.sys-perm.shared-ws';
      case '__GUEST':
        return 'msg.permission.ui.sys-perm.guest';
      default:
        return name;
    }
  }

  public static waterfallPromise(funcList: Function[]): Promise<any[]> {
    if (funcList && 0 < funcList.length) {
      return funcList.reduce((acc, currVal) => {
        return acc.then((res) => {
          return currVal().then((result) => {
            res.push(result);
            return res;
          });
        });
      }, Promise.resolve([]));
    } else {
      throw new Error('SubClass should implements changeLock method');
    }
  }

  public static confirm(confirmData: Modal) {
    const $popupContainer = $('.sys-global-confirm-popup');

    const $popupBox = $popupContainer.children();
    $popupBox.removeClass('ddp-box-popup-type1 ddp-box-popup-type3');
    const $scrollContentsBox = $popupContainer.find('.ddp-box-detail');
    const $nonScrollContentsBox = $popupContainer.find('.ddp-pop-detail');
    const $scrollContentsBox1 = $popupContainer.find('.ant-modal-confirm-content');
    const $nonScrollContentsBox1 = $popupContainer.find('.ant-modal-confirm-content');
    if (confirmData.isScroll) {
      $popupBox.addClass('ddp-box-popup-type3');
      $nonScrollContentsBox.hide();
      $scrollContentsBox.show();
    } else {
      $popupBox.addClass('ddp-box-popup-type1');
      $scrollContentsBox.hide();
      $nonScrollContentsBox.show();
    }

    $popupContainer.find('.ddp-btn-close').one('click', function () {
      $popupContainer.hide();
    });

    if (confirmData.name) {
      $popupContainer.find('.ant-modal-confirm-title').html(confirmData.name);
      $popupContainer.find('.ddp-pop-title').html(confirmData.name);
    } else {
      $popupContainer.find('.ddp-pop-title').html('');
      $popupContainer.find('.ant-modal-confirm-title').html(confirmData.name);
    }

    let description = '';
    confirmData.description && (description = description + confirmData.description);
    confirmData.subDescription && (description = description + '<br/>' + confirmData.subDescription);
    if (confirmData.isScroll) {
      $scrollContentsBox.find('.ddp-txt-detail').html(description);
      $scrollContentsBox1.find('.ant-modal-confirm-content').html(description);
    } else {
      $nonScrollContentsBox.html(description);
      $nonScrollContentsBox1.html(description);
    }

    const $btnCancel = $popupContainer.find('.ddp-btn-type-popup:first');
    const $btnCancel1 = $popupContainer.find('.ant-btn');
    const $btnDone = $btnCancel.next();
    const $btnDone1 = $btnCancel1.next();
    if (confirmData.isShowCancel) {
      $btnCancel.show();
      $btnCancel.text(confirmData.btnCancel ? confirmData.btnCancel : $btnCancel.attr('data-defaultMsg'));
      $btnCancel.off('click').one('click', function () {
        $popupContainer.hide();
      });
      $btnCancel1.show();

      $btnCancel1.off('click').one('click', function () {
        $popupContainer.hide();
      });
    } else {
      $btnCancel.hide();
      $btnCancel1.hide();
    }

    $btnDone.text(confirmData.btnName ? confirmData.btnName : $btnDone.attr('data-defaultMsg'));

    $btnDone.off('click').one('click', function () {
      confirmData.afterConfirm && confirmData.afterConfirm(confirmData);
      $popupContainer.hide();
    });
    $btnDone1.text(confirmData.btnName ? confirmData.btnName : $btnDone.attr('data-defaultMsg'));

    $btnDone1.off('click').one('click', function () {
      confirmData.afterConfirm && confirmData.afterConfirm(confirmData);
      $popupContainer.hide();
    });

    $popupContainer.show();
  }

  public static getByte(str: string) {
    return str.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, '$&$1$2').length;
  }

  public static formatBytes(size: number, floatPos: number) {
    if (0 == size) return '0 Bytes';
    const c = 1024,
      d = floatPos || 2,
      e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      f = Math.floor(Math.log(size) / Math.log(c));
    return parseFloat((size / Math.pow(c, f)).toFixed(d)) + ' ' + e[f];
  }

  public static numberWithCommas(num: number): string {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0';
  }

  public static objectToUrlString(obj: any) {
    if (obj) {
      let params = '';
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          params += `&${key}=${encodeURIComponent(obj[key])}`;
        }
      }

      if (_.startsWith(params, '&')) {
        params = params.substring(1);
      }

      return params;
    }
    return '';
  }

  public static objectToArray(obj: any) {
    if (isNullOrUndefined(obj)) {
      return [];
    } else if (obj.forEach) {
      return obj;
    } else {
      return Object.keys(obj).reduce((acc, currVal) => {
        acc.push(obj[currVal]);
        return acc;
      }, []);
    }
  }

  public static getUUID() {
    return (
      this.s4() +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      '-' +
      this.s4() +
      this.s4() +
      this.s4()
    );
  }

  private static s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  public static convertMilliseconds(ms: number) {
    if (ms === undefined) {
      return 0 + 'ms';
    }

    if (ms < 1000) {
      return ms + 'ms';
    }

    const min = Math.floor((ms / 1000 / 60) << 0);
    const sec = Math.floor((ms / 1000) % 60);
    return min !== 0 ? min + ' min ' + sec + ' sec' : sec + ' sec';
  }

  public static getLocalStorage(key: string): any {
    if (localStorage) {
      return localStorage.getItem(key);
    } else {
      return null;
    }
  }

  public static setLocalStorage(key: string, value: any): void {
    if (localStorage) {
      localStorage.setItem(key, value);
    }
  }
}
