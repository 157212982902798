import { ChartType, GeoField } from '@selfai-platform/bi-domain';
import * as _ from 'lodash';

export class ChartUtil {
  static getAggregationAlias(field: any): string {
    let fieldName: string = !_.isEmpty(field.alias) ? field.alias : field.name;
    if (field['alias'] && field['alias'] !== field.name) {
      fieldName = field['alias'];
    } else {
      // aggregation type과 함께 alias 설정
      const alias: string = field['fieldAlias']
        ? field['fieldAlias']
        : field['logicalName']
        ? field['logicalName']
        : field['name'];
      fieldName = field.aggregationType ? field.aggregationType + `(${alias})` : `${alias}`;
    }

    return fieldName;
  }

  static returnNameFromField(fields: any[]): string[] {
    if (!fields || 0 == fields.length) return [];
    const returnList: string[] = [];
    fields.forEach((item) => {
      returnList.push(ChartUtil.getAggregationAlias(item));
    });

    return returnList;
  }

  static getAlias(field: any): string {
    let alias1: string = field['name'];
    let alias2: string = field['fieldAlias'] ? field['fieldAlias'] : '';
    const alias3: string = field['pivotAlias'] ? field['pivotAlias'] : '';
    if (field.aggregationType && field.aggregationType != '') {
      alias1 = field.aggregationType + '(' + alias1 + ')';
      alias2 = alias2 ? field.aggregationType + '(' + alias2 + ')' : '';
    } else if (field.format && field.format.unit && field.format.unit != '') {
      alias1 = field.format.unit + '(' + alias1 + ')';
      alias2 = alias2 ? field.format.unit + '(' + alias2 + ')' : '';
    }
    const alias: string = alias3 ? alias3 : alias2 ? alias2 : alias1;

    return alias;
  }

  static getFieldAlias(name: string, layers: GeoField[], aggregationType?: string): string {
    let alias: string = name;
    _.each(layers, (field: GeoField) => {
      if (
        _.eq(name, field['name']) &&
        (!aggregationType || (aggregationType && _.eq(aggregationType, field['aggregationType'])))
      ) {
        alias = ChartUtil.getAlias(field);

        return false;
      }
    });

    return alias;
  }

  static isUsingLimitOption(type: ChartType) {
    return (
      ChartType.GRAPH !== type &&
      ChartType.GRAPHV2 !== type &&
      ChartType.SANKEY !== type &&
      ChartType.GAUGE !== type &&
      ChartType.TREEMAP !== type &&
      ChartType.LABEL !== type
    );
  }
}
