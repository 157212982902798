import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { ErrorDialogComponent } from './error-dialog.component';
@NgModule({
  imports: [CommonModule, PanelModule, MessageModule],
  declarations: [ErrorDialogComponent],
  exports: [ErrorDialogComponent],
})
export class ErrorDialogComponentModule {}
