import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { ToolbarAction } from '../models';

@Injectable({ providedIn: 'root' })
export class MenuItemsNormalizerService {
  constructor(private readonly translate: TranslateService) {}

  mapToolbarActionsToMenu(toolbarActions: ToolbarAction[]): Observable<MenuItem[]> {
    return this.getLabelsTranslation(toolbarActions).pipe(
      map((labels) =>
        toolbarActions.map((action) => ({
          icon: action.icon,
          styleClass: action.buttonClass,
          label: labels[action.labelTranslate],
          command: (): void => {
            action.action();
          },
        })),
      ),
    );
  }

  private getLabelsTranslation(actions: ToolbarAction[]): Observable<Record<string, string>> {
    return this.translate.stream(actions.map((action) => action.labelTranslate));
  }
}
