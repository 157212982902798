import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgModel, ValidatorFn } from '@angular/forms';
import { PipelineWorkflowVariableItem } from '@selfai-platform/pipeline-common';
import { DestroyService, USER_PROFILE_UNKNOWN_NAME, UserProfileService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { v4 as uuidv4 } from 'uuid';
import { VariableItemsService } from '../../services/variable-item.service';
import { VariablesUsedInWorkflowService } from '../../services/variables-used-in-workflow.service';

@Component({
  selector: 'selfai-platform-variables-table',
  templateUrl: './variables-table.component.html',
  styleUrls: ['./variables-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, VariablesUsedInWorkflowService],
})
export class VariablesTableComponent implements OnInit {
  viewItems: PipelineWorkflowVariableItem[] = [];

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;

  usedInMap$ = this.variablesUsedInWorkflowService.usedInMap$;

  readonly options: NgModel['options'] = { standalone: true, updateOn: 'blur' } as const;

  constructor(
    private readonly variableService: VariableItemsService,
    private readonly userService: UserProfileService,
    private readonly variablesUsedInWorkflowService: VariablesUsedInWorkflowService,
  ) {}

  readonly required: ValidatorFn = ({ value }) => {
    return value ? null : { required: 'Value cannot be empty' };
  };

  ngOnInit(): void {
    this.viewItems = this.variableService.value;
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  // TODO: fix on enter keydown save values
  onValueChange<K extends keyof PipelineWorkflowVariableItem>(
    value: PipelineWorkflowVariableItem[K],
    key: K,
    current: PipelineWorkflowVariableItem,
  ): void {
    const updated: PipelineWorkflowVariableItem = {
      ...current,
      [key]: value,
      modified: new Date(),
      modifiedAuthor: this.userService.value ? this.userService.value.username : USER_PROFILE_UNKNOWN_NAME,
    };
    const updatedItems = this.variableService.value.map((item) => (item === current ? updated : item));
    this.variableService.next(updatedItems);
    this.viewItems = this.viewItems?.map((item) => (item === current ? updated : item));
  }

  onEnterClick(event: Event): void {
    event.stopPropagation();
  }

  removeItem(itemForRemove: PipelineWorkflowVariableItem): void {
    const updatedItems = this.variableService.value.filter((item) => item !== itemForRemove);
    this.variableService.next(updatedItems);
    this.viewItems = this.viewItems?.filter((item) => item !== itemForRemove);
  }

  addVariable(): void {
    const newItem: PipelineWorkflowVariableItem = {
      description: '',
      key: '',
      modified: new Date(),
      modifiedAuthor: 'admin',
      value: '',
      id: uuidv4(),
    };

    const updatedItems = [...this.variableService.value, newItem];
    this.variableService.next(updatedItems);
    this.viewItems = [...this.viewItems, newItem];
  }

  toHintContent(names: string[]): string {
    return names.join('\n');
  }
}
