import { Injectable } from '@angular/core';
import { AggregatorRouteFormField, FormFieldsValueNode, Step, StepType } from '@selfai-platform/pipeline-common';
import { AggregatorRouteListService } from './aggregator-camel-list.service';

@Injectable()
export class AggregatorCamelValidationService {
  constructor(private aggregatorRouteListService: AggregatorRouteListService) {}

  hasDuplicateValue(fieldName: string, fieldValue: string): boolean {
    const routes: AggregatorRouteFormField[] = this.aggregatorRouteListService.getRoutesData();
    const fieldValuesBySteps = routes
      .filter((route: AggregatorRouteFormField) => route?.steps)
      .map((route: AggregatorRouteFormField) => this.findValues(fieldName, fieldValue, route.steps))
      .filter((stepValues) => stepValues.length > 0);

    return fieldValuesBySteps.length > 1;
  }

  // get id of routes where there is invalid form or false if all valid
  validateAll(): string[] | false {
    if (this.aggregatorRouteListService.formGroupRoutes.valid) {
      return false;
    }

    return Object.entries(this.aggregatorRouteListService.formGroupRoutes.controls)
      .filter(([, formGroup]) => formGroup.invalid)
      .map(([uuid]) => uuid);
  }

  private findValues(fieldName: string, fieldValue: string, steps?: FormFieldsValueNode[]): unknown[] {
    return (steps || []).reduce((values: unknown[], step) => {
      if (Object.prototype.hasOwnProperty.call(step, fieldName) && step[fieldName as keyof Step] === fieldValue) {
        values.push(step[fieldName as keyof FormFieldsValueNode]);
      }

      if (step.type === StepType.FILTER && step.steps?.length) {
        values.push(...this.findValues(fieldName, fieldValue, step.steps));
      }

      return values;
    }, []);
  }
}
