import {
  ChartColorType,
  ChartType,
  FontSize,
  UiChartFormatNumericAlias,
  UiChartGraphSettings,
} from '@selfai-platform/bi-domain';

export const graphChartInitialSettings: UiChartGraphSettings = {
  common: {
    fontSize: FontSize.NORMAL,
    limit: 10000,
  },
  color: { schema: 'SC1', type: ChartColorType.DIMENSION },
  format: {
    decimal: 2,
    abbr: UiChartFormatNumericAlias.AUTO,
    type: 'number',
    useThousandsSep: true,
  },
  dataLabel: {
    showValue: false,
  },
  tooltip: {
    displayTypes: [],
  },
  showLegend: true,
  type: ChartType.GRAPH,
};
