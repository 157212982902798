<form [formGroup]="form">
  <selfai-platform-form-field label="Attribute name">
    <ng-container [formGroup]="filterAttrName">
      <selfai-platform-type-field-switch formControlName="type">
        <selfai-platform-json-path-field formControlName="value"></selfai-platform-json-path-field>
        <selfai-platform-constant-value-field formControlName="value"></selfai-platform-constant-value-field>
        <selfai-platform-spel-value-field formControlName="value"></selfai-platform-spel-value-field>
      </selfai-platform-type-field-switch>
    </ng-container>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="Condition">
    <p-dropdown
      [options]="filterTypeItems"
      formControlName="filterCondition"
      placeholder="Choose filter type"
    ></p-dropdown>
    <div class="mt-3">
      <ng-container [ngSwitch]="form.controls.filterCondition.value">
        <ng-container
          *ngSwitchCase="filterType.BEGINS_FROM"
          [ngTemplateOutlet]="beginFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.NOT_BEGINS_FROM"
          [ngTemplateOutlet]="beginFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.ENDS_WITH"
          [ngTemplateOutlet]="endWithFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.NOT_ENDS_WITH"
          [ngTemplateOutlet]="endWithFrom"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.CONTAINS"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.LESS_EQUALS_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.LESS_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.MORE_EQUALS_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.MORE_THAN"
          [ngTemplateOutlet]="compareValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.REGEXP_LIKE"
          [ngTemplateOutlet]="regExp"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.REGEXP_NOT_LIKE"
          [ngTemplateOutlet]="regExp"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.EQUALS_TO"
          [ngTemplateOutlet]="equalTo"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.NOT_EQUALS_TO"
          [ngTemplateOutlet]="equalTo"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.DATE_LESS_EQUALS_THAN"
          [ngTemplateOutlet]="dateValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.DATE_MORE_EQUALS_THAN"
          [ngTemplateOutlet]="dateValue"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.DAYS_DIFF_MORE_EQUALS_THAN"
          [ngTemplateOutlet]="daysDiff"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
        <ng-container
          *ngSwitchCase="filterType.DAYS_DIFF_LESS_EQUALS_THAN"
          [ngTemplateOutlet]="daysDiff"
          [ngTemplateOutletContext]="{ form: form }"
        ></ng-container>
      </ng-container>
    </div>
  </selfai-platform-form-field>
</form>

<ng-template #beginFrom let-form="form">
  <ng-container [formGroup]="form" ]>
    <selfai-platform-form-field label="Start position">
      <p-inputNumber formControlName="filterStartPosition" mode="decimal"></p-inputNumber>
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Value collection">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #endWithFrom let-form="form">
  <ng-container [formGroup]="form" ]>
    <selfai-platform-form-field label="Start position">
      <p-inputNumber formControlName="filterStartPosition" mode="decimal"></p-inputNumber>
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Value collection">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #compareValue let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Compare value">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #regExp let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="RegExp pattern">
      <ng-container [formGroup]="form.controls.filterValue">
        <selfai-platform-code-editor
          [language]="codeLanguage.REGEXP"
          formControlName="value"
        ></selfai-platform-code-editor>
      </ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #equalTo let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Case option">
      <div class="field-checkbox">
        <p-checkbox
          name="filterIgnoreCase"
          [binary]="true"
          formControlName="filterIgnoreCase"
          inputId="filterIgnoreCase"
        ></p-checkbox>
        <label for="filterIgnoreCase">Ignore case</label>
      </div>
    </selfai-platform-form-field>
    <selfai-platform-form-field label="Value collection">
      <ng-container [ngTemplateOutlet]="filterValueControl" [ngTemplateOutletContext]="{ form: form }"></ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #dateValue let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Compare value">
      <ng-container [formGroup]="form.controls.filterValue">
        <selfai-platform-date-value-field formControlName="value"></selfai-platform-date-value-field>
      </ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>

<ng-template #filterValueControl let-form="form">
  <ng-container [formGroup]="form">
    <ng-container [formGroup]="form.controls.filterValue">
      <selfai-platform-type-field-switch formControlName="type">
        <selfai-platform-constant-value-field formControlName="value"></selfai-platform-constant-value-field>
        <selfai-platform-json-path-field formControlName="value"></selfai-platform-json-path-field>
        <selfai-platform-spel-value-field formControlName="value"></selfai-platform-spel-value-field>
      </selfai-platform-type-field-switch>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #daysDiff let-form="form">
  <ng-container [formGroup]="form">
    <selfai-platform-form-field label="Compare value ">
      <ng-container [formGroup]="form.controls.filterValue">
        <p-inputNumber formControlName="value" mode="decimal"></p-inputNumber>
      </ng-container>
    </selfai-platform-form-field>
  </ng-container>
</ng-template>
