import { EntityMetadataMap } from '@ngrx/data';
import { WORKBOOK_DASHBOARD_LIST_ENTITY_NAME } from '../../dashboard/const';
import { WORKBOOK_ENTITY_NAME, WORKBOOK_LIST_ENTITY_NAME, WORKBOOK_PINS_ENTITY_NAME } from '../const';
import { WorkbookDashboardListState, WorkbookListState, WorkbookPinsState } from '../models';

const entityMetadata: EntityMetadataMap = {
  [WORKBOOK_PINS_ENTITY_NAME]: {
    selectId: (entity: WorkbookPinsState) => entity.workbookId,
  },
  [WORKBOOK_ENTITY_NAME]: {},
  [WORKBOOK_LIST_ENTITY_NAME]: {
    selectId: (entity: WorkbookListState) => entity.workspaceId,
  },
  [WORKBOOK_DASHBOARD_LIST_ENTITY_NAME]: {
    selectId: (entity: WorkbookDashboardListState) => entity.workbookId,
  },
};

export const biDomainWorkbookEntityConfig = {
  entityMetadata,
};
