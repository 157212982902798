import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumList',
})
@Injectable()
export class EnumListPipe implements PipeTransform {
  transform(enumClass, args: string[]): any {
    const keys = [];
    for (const enumMember in enumClass) {
      keys.push(enumMember);
    }
    return keys;
  }
}
