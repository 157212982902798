import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataListItemAction, DataListViewItem } from '../models';

// Service for providing actions for item in data list view
// We can't use @Input because we need to provide different actions for each item
@Injectable()
export abstract class ActionsForItemService<T extends DataListViewItem> {
  abstract getActionsForItem(item: T): Observable<DataListItemAction[]>;
}
