import { ModuleWithProviders, NgModule } from '@angular/core';
import { KdBackendApiService } from './common';
import { DashboardApiService } from './dashboard';
import { DataconnectionApiService, DatasourceApiService } from './datasource';
import { FavoriteApiService, FavoriteByDomainApiService } from './favorite';
import { BackendInfoApiService } from './info';
import { ChartPdfDownloadApiService } from './pdf/services';
import { RecycleApiService } from './recycle';
import { WidgetApiService, WidgetDataSettingsDataService } from './widgets';
import { WorkbookApiService } from './workbook';
import { WorkspaceApiService } from './workspace';

@NgModule({
  providers: [],
})
export class BiApiModule {
  static forRoot(): ModuleWithProviders<BiApiModule> {
    return {
      ngModule: BiApiModule,
      providers: [
        WidgetApiService,
        WorkbookApiService,
        DashboardApiService,
        WorkspaceApiService,
        FavoriteByDomainApiService,
        KdBackendApiService,
        RecycleApiService,
        DataconnectionApiService,
        DatasourceApiService,
        BackendInfoApiService,
        ChartPdfDownloadApiService,
        WidgetDataSettingsDataService,
        FavoriteApiService,
      ],
    };
  }
}
