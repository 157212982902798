import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { JsonPathFieldComponent } from './json-path-field.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputTextModule, ButtonModule, TooltipModule],
  declarations: [JsonPathFieldComponent],
  exports: [JsonPathFieldComponent],
})
export class JsonPathFieldComponentModule {}
