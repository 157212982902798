<p-toast></p-toast>
<p-card>
  <ng-container [ngSwitch]="templateType">
    <selfai-platform-data-sources-library
      *ngSwitchCase="'libraryFile'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-library>
    <selfai-platform-data-sources-db
      *ngSwitchCase="'jdbc'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-db>
    <selfai-platform-data-sources-file
      *ngSwitchCase="'externalFile'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-file>
    <selfai-platform-data-sources-hdfs
      *ngSwitchCase="'hdfs'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-hdfs>
    <selfai-platform-data-sources-google-spreadsheet
      *ngSwitchCase="'googleSpreadsheet'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-google-spreadsheet>
    <selfai-platform-data-sources-kafka
      *ngSwitchCase="'kafka'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-kafka>
    <selfai-platform-data-sources-s3
      *ngSwitchCase="'s3'"
      [(dataSource)]="dataSource"
      [dataSourceList]="dataSourceList"
      [additionalList]="additionalList"
      [editMode]="editMode"
    ></selfai-platform-data-sources-s3>
  </ng-container>
</p-card>

<p-toolbar *ngIf="!form.disabled" aria-label="Actions">
  <ng-template pTemplate="start">
    <p-button label="Cancel" [outlined]="true" severity="secondary" (click)="onBackEvent()"></p-button>
    <p-button label="Save" (click)="onSubmit()" [disabled]="form.invalid"></p-button>
  </ng-template>
</p-toolbar>
