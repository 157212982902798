import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import {
  DataType,
  ItemUsedInNodes,
  JsonToColumnItem,
  JsonToColumnItemFormGroup,
  JsonToColumnTransformationDataForSave,
  JsonToColumnTransformationFormGroup,
  WorkflowJsonToColumnTransformationData,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { filter, Observable, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { normalizeToLegacyDataJsonToColumnTransormation } from '../../../converters';
import { DialogHelperService } from '../../../../dialog';
import { SaveConfirmationService, SelectionStoreService } from '../../../services';
import { AbstractCubeDialogFormWithTableComponent } from '../../abstract-cube-dialog-form-with-table.component';
import { JsonToColumnTransformationComponentService } from './json-to-column-transformation-component.service';
import { JsonToColumnTransformationUsedColumnsService } from './json-to-column-transofrmation-used-columns.services';
import { jsonPathToColumnNameWithNull } from './jsonpath-to-column-name';
import { SearhcRowItem } from './search-row-item';

@Component({
  selector: 'selfai-platform-json-to-column-transformation',
  templateUrl: './json-to-column-transformation.component.html',
  styleUrls: ['./json-to-column-transformation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    DialogHelperService,
    JsonToColumnTransformationComponentService,
    SelectionStoreService,
    SaveConfirmationService,
    JsonToColumnTransformationUsedColumnsService,
  ],
})
export class JsonToColumnTransformationComponent extends AbstractCubeDialogFormWithTableComponent implements OnInit {
  override itemsMap = new Map<string, FormGroup<JsonToColumnItemFormGroup>>();
  override form: FormGroup<JsonToColumnTransformationFormGroup> =
    this.jsonToColumnTransformationComponentService.getFormGroup();
  dataTypeItems = Object.values(DataType);

  usedInItems$: Observable<Map<string, ItemUsedInNodes[]>>;

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;

  override initialItem: JsonToColumnItem = {
    id: '',
    dataType: '' as DataType,
    jsonPath: '',
    columnName: null,
  };

  get dataForWorkflow(): WorkflowJsonToColumnTransformationData {
    const { dateFormat, dateTimeFormat, justTypeTransformation, sourceColumn } = this.form
      .value as JsonToColumnTransformationDataForSave;

    return normalizeToLegacyDataJsonToColumnTransormation({
      dateFormat,
      dateTimeFormat,
      justTypeTransformation,
      sourceColumn,
      items: this.items.map(({ value }: FormGroup<JsonToColumnItemFormGroup>) => ({
        ...value,
        columnName: value.columnName as string,
        dataType: value.dataType as DataType,
        jsonPath: value.jsonPath as string,
      })),
    });
  }

  constructor(
    private readonly jsonToColumnTransformationComponentService: JsonToColumnTransformationComponentService,
    public readonly usedinService: JsonToColumnTransformationUsedColumnsService,
    injector: Injector,
  ) {
    super(injector);
    this.usedInItems$ = this.usedinService.usedInMap;
  }

  ngOnInit(): void {
    this.jsonToColumnTransformationComponentService
      .getItemsFormGroups()
      .pipe(take(1), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((items) => {
        items.forEach((formGroup) => {
          this.itemsMap.set(formGroup.controls.id.value, formGroup);
        });
        this.pushColumns();
        this.markFormAsInitialized();
      });

    this.jsonToColumnTransformationComponentService
      .getNodeId()
      .pipe(takeUntil(this.destroy$))
      .subscribe((nodeId) => {
        this.usedinService.cubeID.next(nodeId);
      });

    this.items.forEach((t) => {
      this.setOnchangesSubscribe(t);
    });
  }

  setOnchangesSubscribe(form: UntypedFormGroup): void {
    form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.pushColumns();
    });
  }

  pushColumns(): void {
    const columnsArray: SearhcRowItem[] = [];
    this.itemsMap.forEach((item) => {
      const colName = item.get('columnName')?.value || jsonPathToColumnNameWithNull(item.get('jsonPath')?.value);
      const id = item.get('id')?.value;
      if (colName && id) {
        columnsArray.push({
          columnName: colName,
          id: id,
        });
      }
    });
    this.usedinService.columns.next(columnsArray);
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  override copyItem(id: string): void {
    const data = this.itemsMap.get(id)?.value;
    if (data) {
      const id = uuidv4();
      const item: JsonToColumnItem = { ...data, id } as JsonToColumnItem;
      const form = this.mapItemToFormGroup(item);
      this.setOnchangesSubscribe(form);
      this.itemsMap.set(id, form);
    }
  }

  override addItem(): void {
    const id = uuidv4();
    const item = { ...this.initialItem, id };
    const form = this.mapItemToFormGroup(item);
    this.setOnchangesSubscribe(form);
    this.itemsMap.set(id, form);
  }

  mapItemToFormGroup(item: JsonToColumnItem): FormGroup<JsonToColumnItemFormGroup> {
    return this.jsonToColumnTransformationComponentService.mapItemToFormGroup(item);
  }

  toHintContent(columnName: string): string {
    const usedIn = this.usedinService.value.get(columnName);
    if (!usedIn) {
      return '';
    }

    return usedIn.map((t) => t.title).join('\n');
  }
}
