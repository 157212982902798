import { EntityMetadataMap } from '@ngrx/data';

export const KE_CURRENT_USER = 'keCurrentUser';

const entityMetadata: EntityMetadataMap = {
  [KE_CURRENT_USER]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keCurrentUserEntityConfig = {
  entityMetadata,
};
