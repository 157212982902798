import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TreeNode } from '@selfai-platform/pipeline-common';
import { LoadingService } from '@selfai-platform/shared';
import {
  isMirroredNode,
  isReferenceNode,
  isRegularNode,
  RegularNode,
  SchemaFragment,
  SchemaNode,
  SchemaTree as JsonSchemaTree,
} from '@stoplight/json-schema-tree';
import { BehaviorSubject, catchError, EMPTY, map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { AggregatorJsonSchemaApiService } from '../../aggregator-camel/services/api';
import { handleHttpErrorResponse } from '../../utils';
import { LOADING_JSON_SCHEMA } from '../tokens';

@Injectable()
export class JsonSchemaTreeService {
  nodes$ = new ReplaySubject<TreeNode[]>(1);
  isLoaded$ = new BehaviorSubject(false);

  constructor(
    private readonly aggregatorJsonSchemaApiService: AggregatorJsonSchemaApiService,
    @Inject(LOADING_JSON_SCHEMA) private readonly loadingJsonSchema: LoadingService,
  ) {}

  initTree(schema: SchemaFragment): void {
    const jsonSchemaTree = new JsonSchemaTree(schema, {
      mergeAllOf: true,
    });

    jsonSchemaTree.populate();
    this.nodes$.next(
      this.findRoot(jsonSchemaTree.root.children[0] as RegularNode)
        .parent?.children?.map(this.mapNode.bind(this))
        .filter(Boolean) as TreeNode[],
    );
  }

  getNodes(): Observable<TreeNode[]> {
    return this.nodes$.asObservable();
  }

  getRootNode(): Observable<TreeNode> {
    return this.getNodes().pipe(map((nodes: TreeNode[]) => nodes[0]));
  }

  mapNode(node: SchemaNode): TreeNode | null {
    if ((isReferenceNode(node) && node.error) || isMirroredNode(node)) {
      return null;
    }

    if (isRegularNode(node)) {
      return {
        id: this.extractId(node),
        types: node.types?.map((v) => v.toString()),
        title: node.fragment.title as string,
        description: node.fragment.description as string,
        path: this.extractPath(node),
        children: node.children?.map(this.mapNode.bind(this)).filter(Boolean) as TreeNode[],
      };
    }

    return {
      id: this.extractId(node),
      title: node.fragment.title as string,
      description: node.fragment.description as string,
      path: this.extractPath(node),
    };
  }

  loadJsonSchema(): Observable<void> {
    this.loadingJsonSchema.setLoading();

    return this.isLoaded$.pipe(
      switchMap((isLoaded) => {
        if (!isLoaded) {
          return this.aggregatorJsonSchemaApiService.loadJsonSchema().pipe(
            tap((schema) => this.initTree(schema)),
            tap(() => {
              this.loadingJsonSchema.setSuccess();

              this.isLoaded$.next(true);
              this.isLoaded$.complete();
            }),
            switchMap(() => EMPTY),
            catchError((error: HttpErrorResponse) => {
              this.loadingJsonSchema.setError(handleHttpErrorResponse(error));

              return EMPTY;
            }),
          );
        }

        return EMPTY;
      }),
    );
  }

  private extractId(node: SchemaNode): string | undefined {
    const { subpath, parent } = node;
    const id = subpath.slice(-1)[0];
    // if the node is just items of array
    if (id === 'items' && Boolean(parent?.fragment.items)) {
      return undefined;
    }

    return id;
  }

  private extractPath(node: SchemaNode): string[] {
    let path: (string | undefined)[] = [];
    if (!node.parent) {
      path = [this.extractId(node)];
    } else {
      path = [...this.extractPath(node.parent), this.extractId(node)];
    }

    return path.filter(Boolean) as string[];
  }

  private findRoot(node: RegularNode): RegularNode {
    if (isRegularNode(node)) {
      if (!node.types && node.children?.length) {
        return this.findRoot(node.children[0] as RegularNode);
      }
    }

    return node;
  }
}
