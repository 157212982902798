export const API_RESPONSE_MESSAGES = {
  CREATE_SUCCESS: 'Function successfully created!',
  CREATE_ERROR: 'Error while updating user function',
  UPDATE_SUCCESS: 'Function successfully changed!',
  UPDATE_ERROR: 'Error while updating user function',
  DELETE_SUCCESS: 'Function successfully deleted!',
  DELETE_ERROR: 'Error while deleting user function',
  IMPORT_SUCCESS: 'Functions successfully imported!',
  IMPORT_ERROR: 'Error while importing user functions',
  EXPORT_SUCCESS: 'Functions successfully exported!',
  EXPORT_ERROR: 'Error while exporting user function',
  CLONE_SUCCESS: 'Functions successfully cloned!',
  CLONE_ERROR: 'Error while cloning user function',
};
