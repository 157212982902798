import { BoardDataSource, Dashboard, Datasource } from '@selfai-platform/bi-domain';

import { isSameDataSource } from './is-same-datasource';

export function getMainDataSources(board: Dashboard): Datasource[] {
  const boardDs: BoardDataSource = board.configuration.dataSource;
  if ('multi' === boardDs.type) {
    return board.dataSources.filter((item) => {
      return (
        boardDs.dataSources.findIndex((item2) => {
          return isSameDataSource(item2, item);
        }) > -1
      );
    });
  }
  const filteredDs = board.dataSources.filter((item) => isSameDataSource(boardDs, item));
  return filteredDs;
}
