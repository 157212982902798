import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DataSource } from '../model';
import { DataSourceStore } from '../store';

@Injectable({ providedIn: 'root' })
export class DataSourceDomainService {
  constructor(private dataSourceStore: DataSourceStore) {}

  getDataSource(id: string): Observable<DataSource> {
    return this.dataSourceStore.entityMap$.pipe(map((dataSources) => dataSources?.[id]));
  }

  loadDataSource(id: string): Observable<DataSource> {
    return this.dataSourceStore.getByKey(id);
  }

  getDataSourceLoading(): Observable<boolean> {
    return this.dataSourceStore.loading$;
  }

  getDataSourceError(): Observable<unknown> {
    return this.dataSourceStore.errors$;
  }

  deleteDataSource(id: string): Observable<string | number> {
    return this.dataSourceStore.delete(id);
  }

  updateDataSource(dataSource: DataSource): Observable<DataSource> {
    return this.dataSourceStore.update(dataSource);
  }

  createDataSource(dataSource: Omit<DataSource, 'ownerId' | 'ownerName' | 'creationDateTime'>): Observable<DataSource> {
    return this.dataSourceStore.add(dataSource, { isOptimistic: false });
  }
}
