export enum BiPermission {
  KD_DATASOURCE_VIEW_ALL = 'KD_DATASOURCE_VIEW_ALL',
  KD_DATASOURCE_CREATE = 'KD_DATASOURCE_CREATE',
  KD_DATASOURCE_EDIT_ALL = 'KD_DATASOURCE_EDIT_ALL',
  KD_DATASOURCE_DELETE_ALL = 'KD_DATASOURCE_DELETE_ALL',

  KD_DATASET_VIEW_ALL = 'KD_DATASET_VIEW_ALL',
  KD_DATASET_CREATE = 'KD_DATASET_CREATE',
  KD_DATASET_EDIT_ALL = 'KD_DATASET_EDIT_ALL',
  KD_DATASET_DELETE_ALL = 'KD_DATASET_DELETE_ALL',

  KD_DATAFLOW_VIEW_ALL = 'KD_DATAFLOW_VIEW_ALL',
  KD_DATAFLOW_CREATE = 'KD_DATAFLOW_CREATE',
  KD_DATAFLOW_EDIT_ALL = 'KD_DATAFLOW_EDIT_ALL',
  KD_DATAFLOW_DELETE_ALL = 'KD_DATAFLOW_DELETE_ALL',

  KD_WORKSPACE_VIEW_ALL = 'KD_WORKSPACE_VIEW_ALL',
  KD_WORKSPACE_CREATE = 'KD_WORKSPACE_CREATE',
  KD_WORKSPACE_EDIT_ALL = 'KD_WORKSPACE_EDIT_ALL',
  KD_WORKSPACE_DELETE_ALL = 'KD_WORKSPACE_DELETE_ALL',
  KD_WORKSPACE_VIEW_DAC = 'KD_WORKSPACE_VIEW_DAC',
  KD_WORKSPACE_MY = 'KD_WORKSPACE_MY',

  KD_RECYCLEBIN_ADMIN = 'KD_RECYCLEBIN_ADMIN',
}

export enum BiEntityPermission {
  WORKBOOK_VIEW = 'WORKBOOK_VIEW',
  WORKBOOK_CREATE = 'WORKBOOK_CREATE',
  WORKBOOK_EDIT = 'WORKBOOK_EDIT',
  WORKBOOK_DELETE = 'WORKBOOK_DELETE',
  DASHBOARD_VIEW = 'DASHBOARD_VIEW',
  DASHBOARD_CREATE = 'DASHBOARD_CREATE',
  DASHBOARD_EDIT = 'DASHBOARD_EDIT',
  DASHBOARD_DELETE = 'DASHBOARD_DELETE',
  WORKSPACE_MANAGE_ROLES = 'WORKSPACE_MANAGE_ROLES',
  WORKSPACE_METADATA_EDIT = 'WORKSPACE_METADATA_EDIT',
  WORKSPACE_MANAGE_ACCESS = 'WORKSPACE_MANAGE_ACCESS',
  WORKSPACE_DATASOURCES_VIEW = 'WORKSPACE_DATASOURCES_VIEW',
}
