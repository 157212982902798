import { Filter, createFilter } from './filter.model';

export interface SpatialFilter extends Filter {
  lowerCorner?: string;
  upperCorner?: string;
}

export function createSpatialFilter(params: Partial<SpatialFilter> = {}): SpatialFilter {
  return {
    ...createFilter(),
    type: 'spatial_bbox',
    ...params,
  };
}
