import { PageWidgetConfiguration } from '@selfai-platform/bi-domain';

export function useCustomField(conf: PageWidgetConfiguration): boolean {
  let useCustomField = false;
  if (useCustomField || conf.pivot.aggregations.some((field) => field.ref && 'user_defined' === field.ref)) {
    useCustomField = true;
  }
  if (useCustomField || conf.pivot.rows.some((field) => field.ref && 'user_defined' === field.ref)) {
    useCustomField = true;
  }
  if (useCustomField || conf.pivot.columns.some((field) => field.ref && 'user_defined' === field.ref)) {
    useCustomField = true;
  }
  return useCustomField;
}
