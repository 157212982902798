import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IsActiveMatchOptions, NavigationEnd, Router, UrlTree } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';
import { MenuMode, MenuService } from '../menu';

@Component({
  selector: 'selfai-platform-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent {
  @Input() set menu(menu: MenuItem[]) {
    this.menuItems = menu;
    this.updateExpandedStatus();
  }

  get menu(): MenuItem[] {
    return this.menuItems;
  }

  @Input() menuMode: MenuMode | null;
  activeMenuItem$ = this.menuService.activeMenuItem$;
  menuMode$ = this.menuService.menuMode$;

  private menuItems!: MenuItem[];

  constructor(private readonly menuService: MenuService, private readonly router: Router) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      if (this.menuItems) {
        this.updateExpandedStatus();
      }
    });
  }

  onMouseEnter(item: HTMLElement): void {
    this.menuService.setActiveMenuItem(item);
  }

  toogleMenu(item: MenuItem): void {
    item.expanded = !item.expanded;
  }

  isChildRouteActive(root: MenuItem): boolean | undefined {
    const matchOptions: IsActiveMatchOptions = {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    };

    return root.items?.some((item) => {
      if (!item.routerLink) {
        return false;
      }

      const urlTree: UrlTree = Array.isArray(item.routerLink)
        ? this.router.createUrlTree(item.routerLink)
        : this.router.parseUrl(item.routerLink);

      return this.router.isActive(urlTree, matchOptions);
    });
  }

  updateExpandedStatus(): void {
    this.menuItems = this.menuItems.map((rootMenuItem: MenuItem) => {
      return {
        ...rootMenuItem,
        expanded: this.isChildRouteActive(rootMenuItem),
      };
    });
  }
}
