declare const Slick: any;

export class GridOption {
  dualSelectionActivate = false;
  cellExternalCopyManagerActivate = false;

  columnGroup = false;

  frozenTotal = false;

  explicitInitialization = false;
  rowHeight = 25;
  defaultColumnWidth = 80;
  enableAddRow = false;
  leaveSpaceForNewRows = false;
  editable = false;
  asyncEditorLoading = false;
  asyncEditorLoadDelay = 100;
  asyncPostRenderDelay = 50;
  autoEdit = true;
  autoHeight = false;

  cellFlashingCssClass = 'flashing';
  dataItemColumnValueExtractor = null;
  defaultFormatter: any = Slick.defaultFormatter;

  editorFactory = null;
  editorLock: any = Slick.GlobalEditorLock;

  enableAsyncPostRender = false;
  enableCellNavigation = true;
  enableColumnReorder = true;
  enableTextSelectionOnCells = false;
  forceFitColumns = false;
  forceSyncScrolling = false;

  formatterFactory = null;

  fullWidthRows = false;
  headerRowHeight = 25;
  multiColumnSort = false;
  multiSelect = true;
  selectedCellCssClass = 'selected';
  showHeaderRow = false;
  topPanelHeight = 25;

  cellHighlightCssClass = 'selected';
  editCommandHandler: any;

  enableCellRangeSelection = null;

  enableRowReordering = null;

  syncColumnCellResize = false;
  showTopPanel = false;
  frozenBottom = false;
  frozenColumn = -1;
  frozenRow = -1;
  enableHeaderClick = false;
  nullCellStyleActivate = false;
  enableHeaderMenu = false;
  enableSeqSort = true;
  enableMultiSelectionWithCtrlAndShift = false;
  rowSelectionActivate = false;

  constructor() {}

  RowSelectionActivate(value: boolean): GridOption {
    this.rowSelectionActivate = value;
    return this;
  }

  DualSelectionActivate(value: boolean): GridOption {
    this.dualSelectionActivate = value;
    return this;
  }

  CellExternalCopyManagerActivate(value: boolean): GridOption {
    this.cellExternalCopyManagerActivate = value;
    return this;
  }

  ColumnGroup(value: boolean): GridOption {
    this.columnGroup = value;
    return this;
  }

  FrozenTotal(value: boolean): GridOption {
    this.frozenTotal = value;
    return this;
  }

  ExplicitInitialization(value: boolean): GridOption {
    this.explicitInitialization = value;
    return this;
  }

  RowHeight(value: number): GridOption {
    this.rowHeight = value;
    return this;
  }

  DefaultColumnWidth(value: number): GridOption {
    this.defaultColumnWidth = value;
    return this;
  }

  EnableAddRow(value: boolean): GridOption {
    this.enableAddRow = value;
    return this;
  }

  LeaveSpaceForNewRows(value: boolean): GridOption {
    this.leaveSpaceForNewRows = value;
    return this;
  }

  Editable(value: boolean): GridOption {
    this.editable = value;
    return this;
  }

  AsyncEditorLoading(value: boolean): GridOption {
    this.asyncEditorLoading = value;
    return this;
  }

  AsyncEditorLoadDelay(value: number): GridOption {
    this.asyncEditorLoadDelay = value;
    return this;
  }

  AsyncPostRenderDelay(value: number): GridOption {
    this.asyncPostRenderDelay = value;
    return this;
  }

  AutoEdit(value: boolean): GridOption {
    this.autoEdit = value;
    return this;
  }

  AutoHeight(value: boolean): GridOption {
    this.autoHeight = value;
    return this;
  }

  CellFlashingCssClass(value: string): GridOption {
    this.cellFlashingCssClass = value;
    return this;
  }

  DataItemColumnValueExtractor(value: string): GridOption {
    this.dataItemColumnValueExtractor = value;
    return this;
  }

  DefaultFormatter(value: any): GridOption {
    this.defaultFormatter = value;
    return this;
  }

  EditorFactory(value: string): GridOption {
    this.editorFactory = value;
    return this;
  }

  EditorLock(value: any): GridOption {
    this.editorLock = value;
    return this;
  }

  EnableAsyncPostRender(value: boolean): GridOption {
    this.enableAsyncPostRender = value;
    return this;
  }

  EnableCellNavigation(value: boolean): GridOption {
    this.enableCellNavigation = value;
    return this;
  }

  EnableColumnReorder(value: boolean): GridOption {
    this.enableColumnReorder = value;
    return this;
  }

  EnableTextSelectionOnCells(value: boolean): GridOption {
    this.enableTextSelectionOnCells = value;
    return this;
  }

  ForceFitColumns(value: boolean): GridOption {
    this.forceFitColumns = value;
    return this;
  }

  ForceSyncScrolling(value: boolean): GridOption {
    this.forceSyncScrolling = value;
    return this;
  }

  FormatterFactory(value: string): GridOption {
    this.formatterFactory = value;
    return this;
  }

  FullWidthRows(value: boolean): GridOption {
    this.fullWidthRows = value;
    return this;
  }

  HeaderRowHeight(value: number): GridOption {
    this.headerRowHeight = value;
    return this;
  }

  MultiColumnSort(value: boolean): GridOption {
    this.multiColumnSort = value;
    return this;
  }

  MultiSelect(value: boolean): GridOption {
    this.multiSelect = value;
    return this;
  }

  SelectedCellCssClass(value: string): GridOption {
    this.selectedCellCssClass = value;
    return this;
  }

  ShowHeaderRow(value: boolean): GridOption {
    this.showHeaderRow = value;
    return this;
  }

  TopPanelHeight(value: number): GridOption {
    this.topPanelHeight = value;
    return this;
  }

  CellHighlightCssClass(value: string): GridOption {
    this.cellHighlightCssClass = value;
    return this;
  }

  EditCommandHandler(value: any): GridOption {
    this.editCommandHandler = value;
    return this;
  }

  EnableCellRangeSelection(value: string): GridOption {
    this.enableCellRangeSelection = value;
    return this;
  }

  EnableRowReordering(value: string): GridOption {
    this.enableRowReordering = value;
    return this;
  }

  SyncColumnCellResize(value: boolean): GridOption {
    this.syncColumnCellResize = value;
    return this;
  }

  ShowTopPanel(value: boolean): GridOption {
    this.showTopPanel = value;
    return this;
  }

  FrozenBottom(value: boolean): GridOption {
    this.frozenBottom = value;
    return this;
  }

  FrozenColumn(value: number): GridOption {
    this.frozenColumn = value;
    return this;
  }

  FrozenRow(value: number): GridOption {
    this.frozenRow = value;
    return this;
  }

  EnableHeaderClick(value: boolean): GridOption {
    this.enableHeaderClick = value;
    return this;
  }

  NullCellStyleActivate(value: boolean): GridOption {
    this.nullCellStyleActivate = value;
    return this;
  }

  EnableHeaderMenu(value: boolean): GridOption {
    this.enableHeaderMenu = value;
    return this;
  }

  EnableSeqSort(value: boolean): GridOption {
    this.enableSeqSort = value;
    return this;
  }

  EnableMultiSelectionWithCtrlAndShift(value: boolean): GridOption {
    this.enableMultiSelectionWithCtrlAndShift = value;
    return this;
  }

  build(): Option {
    return new Option(this);
  }
}

export class Option {
  constructor(builder: GridOption) {
    if (typeof builder.dualSelectionActivate !== 'undefined') {
      this.dualSelectionActivate = builder.dualSelectionActivate;
    }
    if (typeof builder.cellExternalCopyManagerActivate !== 'undefined') {
      this.cellExternalCopyManagerActivate = builder.cellExternalCopyManagerActivate;
    }
    if (typeof builder.columnGroup !== 'undefined') {
      this.columnGroup = builder.columnGroup;
    }
    if (typeof builder.explicitInitialization !== 'undefined') {
      this.explicitInitialization = builder.explicitInitialization;
    }
    if (typeof builder.frozenTotal !== 'undefined') {
      this.frozenTotal = builder.frozenTotal;
    }
    if (typeof builder.rowHeight !== 'undefined') {
      this.rowHeight = builder.rowHeight;
    }
    if (typeof builder.defaultColumnWidth !== 'undefined') {
      this.defaultColumnWidth = builder.defaultColumnWidth;
    }
    if (typeof builder.enableAddRow !== 'undefined') {
      this.enableAddRow = builder.enableAddRow;
    }
    if (typeof builder.leaveSpaceForNewRows !== 'undefined') {
      this.leaveSpaceForNewRows = builder.leaveSpaceForNewRows;
    }
    if (typeof builder.editable !== 'undefined') {
      this.editable = builder.editable;
    }
    if (typeof builder.asyncEditorLoading !== 'undefined') {
      this.asyncEditorLoading = builder.asyncEditorLoading;
    }
    if (typeof builder.asyncEditorLoadDelay !== 'undefined') {
      this.asyncEditorLoadDelay = builder.asyncEditorLoadDelay;
    }
    if (typeof builder.asyncPostRenderDelay !== 'undefined') {
      this.asyncPostRenderDelay = builder.asyncPostRenderDelay;
    }
    if (typeof builder.autoEdit !== 'undefined') {
      this.autoEdit = builder.autoEdit;
    }
    if (typeof builder.autoHeight !== 'undefined') {
      this.autoHeight = builder.autoHeight;
    }
    if (typeof builder.cellFlashingCssClass !== 'undefined') {
      this.cellFlashingCssClass = builder.cellFlashingCssClass;
    }
    if (typeof builder.dataItemColumnValueExtractor !== 'undefined') {
      this.dataItemColumnValueExtractor = builder.dataItemColumnValueExtractor;
    }
    if (typeof builder.defaultFormatter !== 'undefined') {
      this.defaultFormatter = builder.defaultFormatter;
    }
    if (typeof builder.editorFactory !== 'undefined') {
      this.editorFactory = builder.editorFactory;
    }
    if (typeof builder.editorLock !== 'undefined') {
      this.editorLock = builder.editorLock;
    }
    if (typeof builder.enableAsyncPostRender !== 'undefined') {
      this.enableAsyncPostRender = builder.enableAsyncPostRender;
    }
    if (typeof builder.enableCellNavigation !== 'undefined') {
      this.enableCellNavigation = builder.enableCellNavigation;
    }
    if (typeof builder.enableColumnReorder !== 'undefined') {
      this.enableColumnReorder = builder.enableColumnReorder;
    }
    if (typeof builder.enableTextSelectionOnCells !== 'undefined') {
      this.enableTextSelectionOnCells = builder.enableTextSelectionOnCells;
    }
    if (typeof builder.forceFitColumns !== 'undefined') {
      this.forceFitColumns = builder.forceFitColumns;
    }
    if (typeof builder.forceSyncScrolling !== 'undefined') {
      this.forceSyncScrolling = builder.forceSyncScrolling;
    }
    if (typeof builder.formatterFactory !== 'undefined') {
      this.formatterFactory = builder.formatterFactory;
    }
    if (typeof builder.fullWidthRows !== 'undefined') {
      this.fullWidthRows = builder.fullWidthRows;
    }
    if (typeof builder.headerRowHeight !== 'undefined') {
      this.headerRowHeight = builder.headerRowHeight;
    }
    if (typeof builder.multiColumnSort !== 'undefined') {
      this.multiColumnSort = builder.multiColumnSort;
    }
    if (typeof builder.multiSelect !== 'undefined') {
      this.multiSelect = builder.multiSelect;
    }
    if (typeof builder.selectedCellCssClass !== 'undefined') {
      this.selectedCellCssClass = builder.selectedCellCssClass;
    }
    if (typeof builder.showHeaderRow !== 'undefined') {
      this.showHeaderRow = builder.showHeaderRow;
    }
    if (typeof builder.topPanelHeight !== 'undefined') {
      this.topPanelHeight = builder.topPanelHeight;
    }
    if (typeof builder.cellHighlightCssClass !== 'undefined') {
      this.cellHighlightCssClass = builder.cellHighlightCssClass;
    }
    if (typeof builder.editCommandHandler !== 'undefined') {
      this.editCommandHandler = builder.editCommandHandler;
    }
    if (typeof builder.enableCellRangeSelection !== 'undefined') {
      this.enableCellRangeSelection = builder.enableCellRangeSelection;
    }
    if (typeof builder.enableRowReordering !== 'undefined') {
      this.enableRowReordering = builder.enableRowReordering;
    }
    if (typeof builder.syncColumnCellResize !== 'undefined') {
      this.syncColumnCellResize = builder.syncColumnCellResize;
    }
    if (typeof builder.showTopPanel !== 'undefined') {
      this.showTopPanel = builder.showTopPanel;
    }
    if (typeof builder.frozenBottom !== 'undefined') {
      this.frozenBottom = builder.frozenBottom;
    }
    if (typeof builder.frozenColumn !== 'undefined') {
      this.frozenColumn = builder.frozenColumn;
    }
    if (typeof builder.frozenRow !== 'undefined') {
      this.frozenRow = builder.frozenRow;
    }
    if (typeof builder.enableHeaderClick !== 'undefined') {
      this.enableHeaderClick = builder.enableHeaderClick;
    }
    if (typeof builder.nullCellStyleActivate !== 'undefined') {
      this.nullCellStyleActivate = builder.nullCellStyleActivate;
    }
    if (typeof builder.enableHeaderMenu !== 'undefined') {
      this.enableHeaderMenu = builder.enableHeaderMenu;
    }
    if (typeof builder.enableSeqSort !== 'undefined') {
      this.enableSeqSort = builder.enableSeqSort;
    }
    if (typeof builder.enableMultiSelectionWithCtrlAndShift !== 'undefined') {
      this.enableMultiSelectionWithCtrlAndShift = builder.enableMultiSelectionWithCtrlAndShift;
    }
    if (typeof builder.rowSelectionActivate !== 'undefined') {
      this.enableRowSelected = builder.rowSelectionActivate;
    }
  }

  columnGroup: boolean;
  frozenTotal: boolean;

  explicitInitialization: boolean;
  rowHeight: number;
  defaultColumnWidth;
  enableAddRow: boolean;
  leaveSpaceForNewRows: boolean;
  editable: boolean;
  asyncEditorLoading: boolean;
  asyncEditorLoadDelay: number;
  asyncPostRenderDelay: number;
  autoEdit: boolean;
  autoHeight: boolean;
  cellFlashingCssClass: string;
  dataItemColumnValueExtractor: string;
  defaultFormatter: any;
  editorFactory: string;
  editorLock: any;
  enableAsyncPostRender: boolean;
  enableCellNavigation: boolean;
  enableColumnReorder: boolean;
  enableTextSelectionOnCells: boolean;
  forceFitColumns: boolean;
  forceSyncScrolling: boolean;
  formatterFactory: string;
  fullWidthRows: boolean;
  headerRowHeight: number;
  multiColumnSort: boolean;
  multiSelect: boolean;
  selectedCellCssClass: string;
  showHeaderRow: boolean;
  topPanelHeight: number;

  cellHighlightCssClass: string;
  editCommandHandler: any;
  enableCellRangeSelection: string;
  enableRowReordering: string;
  syncColumnCellResize: boolean;

  showTopPanel: boolean;
  frozenBottom: boolean;
  frozenColumn: number;
  frozenRow: number;
  enableHeaderClick: boolean;
  nullCellStyleActivate: boolean;
  enableHeaderMenu: boolean;
  enableSeqSort: boolean;

  dualSelectionActivate: boolean;
  enableRowSelected: boolean;
  rowSelectionActivate: boolean;
  cellExternalCopyManagerActivate: boolean;
  enableMultiSelectionWithCtrlAndShift: boolean;
}
