import { KtdGridLayoutItem } from '@katoid/angular-grid-layout';

import { DashboardLayout, DashboardLayoutContentSettings } from '@selfai-platform/bi-domain';

export function getGridItemSettingsFromLayoutSettings(
  layoutInfo: DashboardLayout | undefined,
): Record<string, KtdGridLayoutItem> {
  if (!layoutInfo || !layoutInfo.content || !layoutInfo.content[0]) {
    return {};
  }
  const content = layoutInfo.content[0];
  content.type;

  switch (content.type) {
    case 'column':
      return createConfigForColumn(content);
    case 'row':
      return createConfigForRow(content);
    case 'ktdgrid':
      return createConfigFromCtdGrid(content);
    default:
      return {};
  }
}

function createConfigForColumn(settings: DashboardLayoutContentSettings): Record<string, KtdGridLayoutItem> {
  const result: Record<string, KtdGridLayoutItem> = {};
  return result;
}

function createConfigForRow(settings: DashboardLayoutContentSettings): Record<string, KtdGridLayoutItem> {
  const result: Record<string, KtdGridLayoutItem> = {};
  return result;
}

function createConfigFromCtdGrid(settings: DashboardLayoutContentSettings): Record<string, KtdGridLayoutItem> {
  const result: Record<string, KtdGridLayoutItem> = {};
  settings.content.forEach((item) => {
    const splittedXy = item.title.split(';');
    const gridItem: KtdGridLayoutItem = {
      h: item.height,
      w: item.width,
      id: item.content[0].id,
      x: parseInt(splittedXy[0], 10),
      y: parseInt(splittedXy[1], 10),
    };
    result[gridItem.id] = gridItem;
  });
  return result;
}
