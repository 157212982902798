// todo move to ./facades and rename WorkflowPresetsDomainService -> WorkflowPresetsFacade
import { WorkflowPresetsStore } from '../store/workflow-presets.store';
import { map, Observable } from 'rxjs';
import { WorkflowPresetsInterface } from '../interfaces/workflow-presets.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsDomainService {
  workflowPresets$!: Observable<WorkflowPresetsInterface[]>;
  loading$!: Observable<boolean>;
  loaded$!: Observable<boolean>;
  errors$!: Observable<boolean>;

  constructor(private workflowPresetsStore: WorkflowPresetsStore) {
    this.workflowPresets$ = workflowPresetsStore.entities$;
    this.loading$ = workflowPresetsStore.loading$;
    this.loaded$ = workflowPresetsStore.loaded$;
  }

  presetsEntities$(): Observable<WorkflowPresetsInterface[]> {
    return this.workflowPresetsStore.entities$;
  }

  getListObservable(): Observable<{ presets: WorkflowPresetsInterface[] }> {
    return this.workflowPresetsStore.getAll().pipe(map((presets) => ({ presets })));
  }

  getList(): void {
    this.workflowPresetsStore.getAll();
  }

  getItem(id: number): void {
    this.workflowPresetsStore.getByKey(id);
  }

  add(data: WorkflowPresetsInterface): void {
    this.workflowPresetsStore.add(data);
  }

  update(data: WorkflowPresetsInterface): void {
    this.workflowPresetsStore.update(data);
  }

  delete(id: number): void {
    this.workflowPresetsStore.delete(id);
  }
}
