import { Injectable } from '@angular/core';
import { AggrtConfig } from '@selfai-platform/pipeline-common';
import { AlertService, PipelineConfigService } from '@selfai-platform/shared';

@Injectable({
  providedIn: 'root',
})
export class AggrtConfigService {
  constructor(
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly alertService: AlertService,
  ) {}

  getConfig(): AggrtConfig {
    try {
      const config = this.pipelineConfigService.getConfig();

      return { ...config.features.aggregation, host: config.hosts.aggregates };
    } catch (e: unknown) {
      this.alertService.error('Configuration for Aggregators is not available. Check config.json');
      throw e;
    }
  }
}
