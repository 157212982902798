import { FormControl } from '@angular/forms';

export interface WriteDataFrameTransitParameters {
  dataSourceId: string | null;
  overwrite: boolean | null;
  saveMode: WriteDataFrameTransitSaveMode | null;
}

export interface WriteDataFrameTransitFormGroup {
  dataSourceId: FormControl<string | null>;
  overwrite: FormControl<boolean | null>;
  saveMode: FormControl<WriteDataFrameTransitSaveMode | null>;
}

export interface WriteDataFrameTransitRawParameters {
  // Data source uuid
  'data source': string | null;
  overwrite: boolean | null;
  'Save mode (only for JDBC)': Partial<Record<WriteDataFrameTransitSaveMode, {}>> | null;
}

export const WriteDataFrameTransitSaveMode = {
  Append: 'Append',
  Overwrite: 'Overwrite',
  ErrorIfExists: 'ErrorIfExists',
  Ignore: 'Ignore',
  OverwriteTrancateTable: 'Overwrite (truncate table)',
} as const;

export type WriteDataFrameTransitSaveMode =
  typeof WriteDataFrameTransitSaveMode[keyof typeof WriteDataFrameTransitSaveMode];
