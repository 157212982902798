
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { TimeFilter, ByTimeUnit, TimeUnit } from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { FilterUtil } from '../../util/filter.util';

@Component({
  selector: 'timeUnit-select',
  templateUrl: './timeUnit-select.component.html',
})
export class TimeUnitSelectComponent extends AbstractComponent implements OnInit, OnDestroy {
  public filter: TimeFilter;

  public dpContinuousList: { name: string; value: string }[] = [
    { name: 'msg.storage.li.dsource.granularity-second', value: 'SECOND' },
    { name: 'msg.storage.li.dsource.granularity-minute', value: 'MINUTE' },
    { name: 'msg.storage.li.dsource.granularity-hour', value: 'HOUR' },
    { name: 'msg.storage.li.dsource.granularity-day', value: 'DAY' },
    { name: 'msg.storage.li.dsource.granularity-week', value: 'WEEK' },
    { name: 'msg.storage.li.dsource.granularity-month', value: 'MONTH' },
    { name: 'msg.storage.li.dsource.granularity-year', value: 'YEAR' },
    { name: 'msg.storage.li.dsource.granularity-none', value: 'NONE' },
  ];
  public dpDiscontinuousList: any[] = [
    { name: 'msg.storage.li.dsource.granularity-day-week', unit: 'DAY', byUnit: 'WEEK' },
    { name: 'msg.storage.li.dsource.granularity-day-month', unit: 'DAY', byUnit: 'MONTH' },
    { name: 'msg.storage.li.dsource.granularity-day-year', unit: 'DAY', byUnit: 'YEAR' },
    { name: 'msg.storage.li.dsource.granularity-week-month', unit: 'WEEK', byUnit: 'MONTH' },
    { name: 'msg.storage.li.dsource.granularity-week-year', unit: 'WEEK', byUnit: 'YEAR' },
    { name: 'msg.storage.li.dsource.granularity-month-year', unit: 'MONTH', byUnit: 'YEAR' },
    { name: 'msg.storage.li.dsource.granularity-year', unit: 'YEAR' },
  ];
  public timeUnitLabel = 'NONE';

  public isOpenOpts = false;

  @Input()
  public mode = 'CHANGE';

  @Input('filter')
  public inputFilter: TimeFilter;

  @Output()
  public change: EventEmitter<TimeUnitSelectResult> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const dataChanges: SimpleChange = changes.inputFilter;
    if (dataChanges) {
      this.filter = dataChanges.currentValue;
      if (isNullOrUndefined(this.filter.timeUnit)) {
        this.filter.timeUnit = TimeUnit.NONE;
      }
      const timeUnit = this.translateService.instant(`msg.storage.granularity.${this.filter.timeUnit.toString()}`);
      const granularityName = this.translateService.instant(`msg.granular`);
      this.timeUnitLabel = `${granularityName}:${timeUnit}`;
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public isSelectedContinuous(item: string): boolean {
    return !FilterUtil.isDiscontinuousTimeFilter(this.filter) && this.filter.timeUnit === TimeUnit[item.toUpperCase()];
  }

  public isSelectedDiscontinuous(item: { name: string; unit: string; byUnit: string }): boolean {
    if (FilterUtil.isDiscontinuousTimeFilter(this.filter)) {
      if (item.byUnit) {
        return (
          this.filter.timeUnit === TimeUnit[item.unit.toUpperCase()] &&
          this.filter.byTimeUnit === ByTimeUnit[item.byUnit.toUpperCase()]
        );
      } else {
        return this.filter.timeUnit === TimeUnit[item.unit.toUpperCase()];
      }
    } else {
      return false;
    }
  }

  public selectTimeUnit(discontinuous: boolean = false, unit: string, byUnit?: string) {
    this.isOpenOpts = false;
    this.change.emit(
      new TimeUnitSelectResult(
        discontinuous,
        TimeUnit[unit.toUpperCase()],
        byUnit ? ByTimeUnit[byUnit.toUpperCase()] : null,
      ),
    );
  }
}

export class TimeUnitSelectResult {
  public discontinuous: boolean;
  public unit: TimeUnit;
  public byUnit?: ByTimeUnit;

  constructor(discontinuous?: boolean, unit?: TimeUnit, byUnit?: ByTimeUnit) {
    this.unit = isNullOrUndefined(unit) ? TimeUnit.NONE : unit;
    isNullOrUndefined(byUnit) || (this.byUnit = byUnit);
    isNullOrUndefined(discontinuous) || (this.discontinuous = discontinuous);
  }
}
