import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourceUniqueNameComponent, DataSourceVisibilityComponent } from '../data-source-form-inputs';
import { DataSourcesBasic } from '../data-sources-basic';

interface KafkaParamsForm {
  brokers: FormControl<string>;
  topic: FormControl<string>;
  sslEnabled: FormControl<boolean>;
  sslKeystoreLocation?: FormControl<string>;
  sslKeystorePassword?: FormControl<string>;
  sslTruststoreLocation?: FormControl<string>;
  sslTruststorePassword?: FormControl<string>;
}
@Component({
  selector: 'selfai-platform-data-sources-kafka',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
    DataSourcesAdditionsComponent,
    DataSourceUniqueNameComponent,
    DataSourceVisibilityComponent,
  ],
  templateUrl: './data-sources-kafka.component.html',
  styleUrls: ['./data-sources-kafka.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesKafkaComponent extends DataSourcesBasic implements OnChanges, OnInit {
  paramsForm = new FormGroup<KafkaParamsForm>({
    brokers: new FormControl('', Validators.required),
    topic: new FormControl('', Validators.required),
    sslEnabled: new FormControl(false),
  });

  constructor() {
    super('kafka');
  }

  override ngOnInit(): void {
    this.paramsForm
      .get('sslEnabled')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value: boolean) => {
          this.switchSsl(value);
        },
      });

    super.ngOnInit();
  }

  switchSsl(value: boolean): void {
    const kafkaParams = this.form.get('params') as FormGroup;
    if (value) {
      kafkaParams.addControl('sslKeystoreLocation', this.fb.control(''));
      kafkaParams.addControl('sslKeystorePassword', this.fb.control(''));
      kafkaParams.addControl('sslTruststoreLocation', this.fb.control(''));
      kafkaParams.addControl('sslTruststorePassword', this.fb.control(''));
    } else {
      kafkaParams.removeControl('sslKeystoreLocation');
      kafkaParams.removeControl('sslKeystorePassword');
      kafkaParams.removeControl('sslTruststoreLocation');
      kafkaParams.removeControl('sslTruststorePassword');
    }
  }
}
