export enum BroadcastKey {
  DATASOURCE_CREATED_FIELD = 'DATASOURCE_CREATED_FIELD',
  DATASOURCE_CHANGED_SELECTED_FIELD = 'DATASOURCE_CHANGED_SELECTED_FIELD',
  // list
  DATASOURCE_CHANGED_FIELD_LIST = 'DATASOURCE_CHANGED_FIELD_LIST',
  DATASOURCE_CHANGED_CHECKED_FIELD_LIST = 'DATASOURCE_CHANGED_CHECKED_FIELD_LIST',
  // filter
  DATASOURCE_CHANGED_FIELD_LIST_FILTER = 'DATASOURCE_CHANGED_FIELD_LIST_FILTER',
}

export enum IngestionStep {
  START_INGESTION_JOB = 'START_INGESTION_JOB',
  PREPARATION_HANDLE_LOCAL_FILE = 'PREPARATION_HANDLE_LOCAL_FILE',
  PREPARATION_LOAD_FILE_TO_ENGINE = 'PREPARATION_LOAD_FILE_TO_ENGINE',
  ENGINE_INIT_TASK = 'ENGINE_INIT_TASK',
  ENGINE_RUNNING_TASK = 'ENGINE_RUNNING_TASK',
  ENGINE_REGISTER_DATASOURCE = 'ENGINE_REGISTER_DATASOURCE',
  END_INGESTION_JOB = 'END_INGESTION_JOB',
  FAIL_INGESTION_JOB = 'FAIL_INGESTION_JOB',
}

export enum FilterKey {
  ROLE = 'ROLE',
  TYPE = 'TYPE',
  SEARCH = 'SEARCH',
}

export enum TimestampType {
  CURRENT = 'CURRENT',
  FIELD = 'FIELD',
}

export enum SourceType {
  FILE = 'FILE',
  HDFS = 'HDFS',
  HIVE = 'HIVE',
  JDBC = 'JDBC',
  S3 = 'S3',
  REALTIME = 'REALTIME',
  IMPORT = 'IMPORT',
  SNAPSHOT = 'SNAPSHOT',
  NONE = 'NONE',
  ENGINE = 'ENGINE',
  STAGEDB = 'STAGEDB',
}

export enum IngestionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  PASS = 'PASS',
}

export enum DataSourceType {
  MASTER = 'MASTER',
  JOIN = 'JOIN',
  VOLATILITY = 'VOLATILITY',
}

export enum ConnectionType {
  ENGINE = 'ENGINE',
  LINK = 'LINK',
}

export enum TempDsStatus {
  ENABLE = 'ENABLE',
  PREPARING = 'PREPARING',
  DISABLE = 'DISABLE',
}

export enum DatasourceStatus {
  ENABLED = 'ENABLED',
  PREPARING = 'PREPARING',
  FAILED = 'FAILED',
  BAD = 'BAD',
  DISABLED = 'DISABLED',
  BAD_RU = 'BAD',
}
