<div
  *ngIf="dataSources"
  (click)="toggleDataSourceList($event)"
  (clickOutside)="isShowDataSourceOpts = false"
  [class.ddp-selected]="isShowDataSourceOpts"
  class="ddp-data-in"
>
  <em class="ddp-icon-database3"></em>
  <span class="ddp-txt-namesub">{{ selectedDataSource?.name }}</span>
  <em class="ddp-icon-view"></em>
  <a *ngIf="isEnableInfo" (click)="triggerShowInfo()" href="javascript:" class="ddp-btn-info"></a>

  <div class="ddp-box-layout4 {{ isEnableEditAssociationJoin ? 'ddp-datasearch' : 'ddp-datasource' }}">
    <component-input
      [compType]="'search'"
      [value]="searchText"
      [immediately]="true"
      [optionalStyle]="'width:86%;'"
      [placeHolder]="'msg.board.custom.ui.placeholder' | translate"
      (changeValue)="searchText = $event"
    ></component-input>

    <div class="ddp-ui-list-type">
      <ul class="ddp-list-type ddp-list-popup">
        <li
          *ngFor="let dsInfo of dataSources | baseFilter: ['name', searchText]"
          (click)="$event.stopPropagation(); selectDataSource(dsInfo)"
          [class.ddp-selected]="dsInfo.valid && selectedDataSource?.id === dsInfo.id"
          [class.ddp-disabled]="!dsInfo.valid"
          title="{{ dsInfo.name }}"
        >
          <a href="javascript:">
            <em class="ddp-icon-database-s"></em>
            <span class="ddp-data-name">{{ dsInfo.name }}</span>
            <em class="ddp-icon-check"></em>
          </a>
        </li>
      </ul>
    </div>

    <a *ngIf="isEnableEditAssociationJoin" href="javascript:" class="ddp-link-join" (click)="updateBoardDataSource()">
      {{ 'msg.board.custom.ui.edit.association.join' | translate }}
      <em class="ddp-btn-option"></em>
    </a>
  </div>
</div>
