<p-table class="table" #tableRef [value]="viewItems" [globalFilterFields]="['key']">
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="filter($event)" placeholder="Search" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="title">
        Title
        <p-sortIcon field="title"></p-sortIcon>
      </th>
      <th>Link</th>
      <th pSortableColumn="color">
        Color
        <p-sortIcon field="color"></p-sortIcon>
      </th>
      <th pSortableColumn="modified">
        Modified
        <p-sortIcon field="modified"></p-sortIcon>
      </th>
      <th pSortableColumn="modifiedAuthor">
        Author
        <p-sortIcon field="modifiedAuthor"></p-sortIcon>
      </th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td pEditableColumn class="editable-cell p-0">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              required
              name="title"
              #key="ngModel"
              [ngModel]="item.title"
              [ngModelOptions]="options"
              (keydown.enter)="onEnterClick($event)"
              (ngModelChange)="onValueChange($event, 'title', item)"
            >
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.title }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn class="editable-cell p-0">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText 
              required
              name="link"
              [ngModel]="item.link"
              [ngModelOptions]="options"
              (keydown.enter)="onEnterClick($event)"
              (ngModelChange)="onValueChange($event, 'link', item)"
            >
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.link }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn class="editable-cell p-0">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <p-dropdown 
              [options]="colors" 
              [ngModel]="item.color"
              [ngModelOptions]="options"
              (keydown.enter)="onEnterClick($event)"
              (ngModelChange)="onValueChange($event, 'color', item)"
              [appendTo]="'body'"
              placeholder="Select color">
            </p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.color }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ item.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
      <td>{{ item.modifiedAuthor }}</td>
      <td>
        <button
          *ngIf="!item.usedInCurrentWorkflow"
          type="button"
          pButton
          class="p-button-text p-button-link p-button-danger p-0"
          appearance="icon"
          icon="pi pi-trash"
          (click)="removeItem(item)"
          pTooltip="Remove"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="add-row">
  <button
    label="Add variable"
    pButton
    pRipple
    icon="pi pi-plus"
    type="button"
    class="p-button-text p-button-sm"
    (click)="addVariable()"
  ></button>
</div>
