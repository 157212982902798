import { Directive, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import { ChartType, Pivot } from '@selfai-platform/bi-domain';

import { BaseOptionComponent } from './base-option.component';

@Directive()
export class LabelBaseOptionComponent extends BaseOptionComponent implements OnInit, OnDestroy {
  @Input()
  public pivot: Pivot;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public checkCategoryDisable(): boolean {
    let categoryDisable = false;

    switch (this.uiOption.type) {
      case ChartType.BAR:
        if (this.pivot.aggregations.length > 1 || this.pivot.rows.length >= 1) {
          categoryDisable = true;
        }
        break;

      case ChartType.LINE:
      case ChartType.COMBINE:
        if (this.pivot.aggregations.length > 1) categoryDisable = true;
        break;
    }

    return categoryDisable;
  }

  public checkSeriesDisable(displayType?: string): boolean {
    let seriesDisable = false;

    switch (this.uiOption.type) {
      case ChartType.BAR:
        if (this.pivot.aggregations.length <= 1 && this.pivot.rows.length < 1) {
          seriesDisable = true;
        }

        if (displayType === 'SERIES_PERCENT') {
          seriesDisable = true;
        }
        break;

      case ChartType.LINE:
      case ChartType.COMBINE:
        if (this.pivot.aggregations.length <= 1) seriesDisable = true;
        break;
    }

    return seriesDisable;
  }

  public checkSeriesNameDisable(): boolean {
    let seriesNameDisable = false;

    switch (this.uiOption.type) {
      case ChartType.HEATMAP:
        if (this.pivot.rows.length < 1) {
          seriesNameDisable = true;
        }
        break;
    }

    return seriesNameDisable;
  }

  public checkCategoryNameDisable(): boolean {
    let categoryNameShow = false;

    switch (this.uiOption.type) {
      case ChartType.GAUGE:
        if (this.pivot.rows.length > 1) categoryNameShow = true;
        break;
      default:
        categoryNameShow = true;
        break;
    }

    return categoryNameShow;
  }
}
