<selfai-platform-workflow-settings-metadata
  [workflow]="dialogService.data"
  (submitEvent)="editMetadata($event)"
></selfai-platform-workflow-settings-metadata>
<selfai-platform-workflow-settings-schedules
  [presets]="workflowPresetsDomainService.workflowPresets$ | async"
  [schedules]="workflowSettingsSchedulesDomainService.workflowSettingsSchedules$ | async"
  [workflowId]="workflowId"
  (addSchedule)="onAddSchedule($event)"
  (editSchedule)="onEditSchedule($event)"
  (deleteSchedule)="onDeleteSchedule($event)"
></selfai-platform-workflow-settings-schedules>
<selfai-platform-workflow-settings-presets
  [presets]="workflowPresetsDomainService.workflowPresets$ | async"
  [workflowId]="workflowId"
  [currentPresetId]="(workflowSettingsPresetsDomainService.workflowSettingsPresets$ | async)?.[0]?.id"
  (submitEvent)="setPreset($event)"
></selfai-platform-workflow-settings-presets>
