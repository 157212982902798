import { Injectable } from '@angular/core';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { Observable } from 'rxjs';
import { DataSourcesAdditions } from '../model';
import { DataSourcesAdditionsStore } from '../store';
import { dataSourcesAdditionsParamsTypeNormalize } from '../utilities';

@Injectable({ providedIn: 'root' })
export class DataSourceAdditionsDomainService {
  constructor(private dataSourcesAdditionsStore: DataSourcesAdditionsStore) {}

  getAdditionParams(): Observable<DataSourcesAdditions[]> {
    return this.dataSourcesAdditionsStore.entities$;
  }

  loadAdditionParams(type: DatasourceType): Observable<DataSourcesAdditions[]> {
    return this.dataSourcesAdditionsStore.getWithQuery(dataSourcesAdditionsParamsTypeNormalize(type));
  }

  getAdditionParamsLoading(): Observable<boolean> {
    return this.dataSourcesAdditionsStore.loading$;
  }

  getAdditionParamsError(): Observable<unknown> {
    return this.dataSourcesAdditionsStore.errors$;
  }
}
