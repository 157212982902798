import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE } from '@selfai-platform/shell';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { Table } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { DataSourcesLibraryDataFrameNormalizeInterface } from '../../model/data-sources-library-data-frame-normalize.model';
import { DataSourcesLibraryFilesInterface } from '../../model/data-sources-library-files.model';

@Component({
  selector: 'selfai-platform-data-sources-library-data-frame-list',
  standalone: true,
  imports: [CommonModule, TreeTableModule, InputTextModule, ButtonModule, FileUploadModule, ConfirmPopupModule],
  providers: [ConfirmationService],
  templateUrl: './data-sources-library-data-frame-list.component.html',
  styleUrls: ['./data-sources-library-data-frame-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesLibraryDataFrameListComponent implements OnChanges {
  normalizeLibraryFiles!: TreeNode[];
  pageSize = UI_SETTINGS_VIEW_PAGE_DEFAULT_SIZE;

  @Input() libraries: DataSourcesLibraryFilesInterface[];
  @Output() selectEvent = new EventEmitter<string>();
  @Output() deleteEvent = new EventEmitter<string>();
  @Output() downloadEvent = new EventEmitter<string>();
  @Output() uploadEvent = new EventEmitter<{ files: File[] }>();
  @ViewChild('table') table: Table;

  constructor(private changeDetectorRef: ChangeDetectorRef, private confirmationService: ConfirmationService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['libraries']?.currentValue) {
      this.normalizeLibraryFiles = this.libraries.map((el) => {
        return { data: { name: el.name, kind: el.kind }, children: el?.children || [] };
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  onFilter(event: string): void {
    this.table.filterGlobal(event, 'contains');
  }

  onClick(id: string): void {}

  onSelect(item: DataSourcesLibraryFilesInterface): void {
    this.selectEvent.emit(item.name);
  }

  onDelete(name: string): void {
    this.deleteEvent.emit(name);
  }

  onConfirmDelete(event: Event, item: DataSourcesLibraryFilesInterface): void {
    this.confirmationService.confirm({
      target: event.target,
      message: `Are you sure you want to delete the file ${item.name}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onDelete(item.name);
      },
    });
  }

  onDownload(fileName: string): void {
    this.downloadEvent.emit(fileName);
  }

  onUpload(event: any): void {
    this.uploadEvent.emit(event);
  }
}
