import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { GeoField, UiChartDataLabelDisplayType, UIChartFormat, UIOption } from '@selfai-platform/bi-domain';
import { FormatOptionConverter } from './format-option-converter';

export class TooltipOptionConverter {
  constructor(private _ts: TranslateService) {}

  public static setTooltipPreviewList(uiOption: UIOption): object[] {
    uiOption.toolTip.previewList = [];

    let format: UIChartFormat = uiOption.valueFormat;

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(uiOption);
    if (axisFormat) format = axisFormat;

    const numValue = FormatOptionConverter.getFormatValue(1000, format);

    if (uiOption.toolTip.displayTypes) {
      for (const type of uiOption.toolTip.displayTypes) {
        switch (type) {
          case UiChartDataLabelDisplayType.CATEGORY_NAME:
            uiOption.toolTip.previewList.push({
              name: 'Category: Category name',
              value: UiChartDataLabelDisplayType.CATEGORY_NAME,
            });
            break;
          case UiChartDataLabelDisplayType.CATEGORY_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Category value: ' + numValue,
              value: UiChartDataLabelDisplayType.CATEGORY_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.CATEGORY_PERCENT:
            uiOption.toolTip.previewList.push({
              name: 'Category %: 100%',
              value: UiChartDataLabelDisplayType.CATEGORY_PERCENT,
            });
            break;
          case UiChartDataLabelDisplayType.SERIES_NAME:
            uiOption.toolTip.previewList.push({
              name: 'Series: Series name',
              value: UiChartDataLabelDisplayType.SERIES_NAME,
            });
            break;
          case UiChartDataLabelDisplayType.SERIES_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Series value: ' + numValue,
              value: UiChartDataLabelDisplayType.SERIES_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.SERIES_PERCENT:
            uiOption.toolTip.previewList.push({
              name: 'Series %: 100%',
              value: UiChartDataLabelDisplayType.SERIES_PERCENT,
            });
            break;
          case UiChartDataLabelDisplayType.XAXIS_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'X axis Value: ' + numValue,
              value: UiChartDataLabelDisplayType.XAXIS_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.YAXIS_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Y axis Value: ' + numValue,
              value: UiChartDataLabelDisplayType.YAXIS_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Value: ' + numValue,
              value: UiChartDataLabelDisplayType.VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.NODE_NAME:
            uiOption.toolTip.previewList.push({
              name: 'Node: Node name',
              value: UiChartDataLabelDisplayType.NODE_NAME,
            });
            break;
          case UiChartDataLabelDisplayType.LINK_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Link value: ' + numValue,
              value: UiChartDataLabelDisplayType.LINK_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.NODE_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Node value: ' + numValue,
              value: UiChartDataLabelDisplayType.NODE_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.HIGH_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'High: ' + numValue,
              value: UiChartDataLabelDisplayType.HIGH_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.THREE_Q_VALUE:
            uiOption.toolTip.previewList.push({
              name: '3Q: ' + numValue,
              value: UiChartDataLabelDisplayType.THREE_Q_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.MEDIAN_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Median: ' + numValue,
              value: UiChartDataLabelDisplayType.MEDIAN_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.FIRST_Q_VALUE:
            uiOption.toolTip.previewList.push({
              name: '1Q: ' + numValue,
              value: UiChartDataLabelDisplayType.FIRST_Q_VALUE,
            });
            break;
          case UiChartDataLabelDisplayType.LOW_VALUE:
            uiOption.toolTip.previewList.push({
              name: 'Low: ' + numValue,
              value: UiChartDataLabelDisplayType.LOW_VALUE,
            });
            break;
        }
      }
    }

    const filteredDisplayTypes = _.cloneDeep(_.filter(uiOption.toolTip.displayTypes));
    const categoryValIdx = filteredDisplayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE);
    const categoryPerIdx = filteredDisplayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT);
    if (-1 !== categoryValIdx && -1 !== categoryPerIdx) {
      uiOption.toolTip.previewList[categoryValIdx]['name'] = 'Category Value: ' + numValue + '(100%)';
      uiOption.toolTip.previewList.splice(categoryPerIdx, 1);
    }

    let seriesValIdx = filteredDisplayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE);
    let seriesPerIdx = filteredDisplayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT);
    if (-1 !== seriesValIdx && -1 !== seriesPerIdx) {
      if (-1 !== categoryValIdx && -1 !== categoryPerIdx) {
        seriesValIdx += -1;
        seriesPerIdx += -1;
      }
      uiOption.toolTip.previewList[seriesValIdx]['name'] = 'Series Value: ' + numValue + '(100%)';
      uiOption.toolTip.previewList.splice(seriesPerIdx, 1);
    }

    return uiOption.toolTip.previewList;
  }

  public static returnTooltipDataValue(layerItems: any[]): GeoField[] {
    layerItems = layerItems.filter((item) => {
      return (
        'user_expr' == item.field.type ||
        (item.field.logicalType && -1 == item.field.logicalType.toString().indexOf('GEO'))
      );
    });
    const groupList = _.groupBy(layerItems, { type: 'measure' });

    groupList['false'] = _.uniqBy(groupList['false'], 'name');
    layerItems = _.union(groupList['true'], groupList['false']);

    return layerItems;
  }
}
