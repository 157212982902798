import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppInitService, PipelineConfigService } from '@selfai-platform/shared';
import { Observable, filter, switchMap } from 'rxjs';
import { KeMqInfoResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class KeMqInfoApiService {
  private apiurl: string;
  private readonly mqPAth = '/mq-info';

  constructor(
    private readonly http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly appInitService: AppInitService,
  ) {
    appInitService.pipelineLoaded$.pipe(filter(Boolean)).subscribe(() => {
      const config = this.pipelineConfigService.getConfig();
      this.apiurl = `${config.hosts.api}/${config.versions.url}`;
    });
  }

  getMqInfo(): Observable<KeMqInfoResponse> {
    return this.appInitService.pipelineLoaded$.pipe(
      filter(Boolean),
      switchMap(() => {
        return this.http.get<KeMqInfoResponse>(`${this.apiurl}${this.mqPAth}`);
      }),
    );
  }
}
