<form [formGroup]="form">
  <selfai-platform-form-field label="To">
    <p-dropdown
      [options]="(connectorTypes$ | async) || []"
      formControlName="connectorType"
      placeholder="Choose connector type"
    ></p-dropdown>
  </selfai-platform-form-field>
  <selfai-platform-form-field label="To">
    <input type="text" pInputText formControlName="to" />
  </selfai-platform-form-field>
</form>
