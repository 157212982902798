import moment from 'moment';
import { DatasourceField } from '../../../../datasource';
import { TimeUnit } from '../field/timestamp-field.model';
import { TimeFilter, createTimeFilter } from './time-filter.model';

export interface TimeRelativeFilter extends TimeFilter {
  tense?: TimeRelativeTense;
  relTimeUnit?: TimeUnit;
  value?: number;
  timeZone: string | undefined;
}

export function createTimeRelativeFilter(field: DatasourceField): TimeRelativeFilter {
  return {
    ...createTimeFilter(field),
    type: 'time_relative',
    timeZone: moment.tz.guess(),
  };
}

export enum TimeRelativeTense {
  PREVIOUS = 'PREVIOUS',
  CURRENT = 'CURRENT',
  NEXT = 'NEXT',
}
