import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity/src/models';
import { KE_DATA_SOURCES_LIST_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';
import { DataSource } from '../model/data-source.model';
import { DataSourcesApiService } from '../services/data-sources-api.service';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesDataService extends DefaultDataService<DataSource> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly dataSourcesApiService: DataSourcesApiService,
  ) {
    super(KE_DATA_SOURCES_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<DataSource[]> {
    return this.dataSourcesApiService
      .loadList()
      .pipe(map((items) => items.map((item) => ({ ...item, name: item.params.name }))));
  }

  override getById(id: string): Observable<DataSource> {
    return this.dataSourcesApiService.loadDatasource(id).pipe(map((item) => ({ ...item, name: item.params.name })));
  }

  override add(datasourceParams: DataSource): Observable<DataSource> {
    return this.dataSourcesApiService.addDatasource(datasourceParams).pipe(map(() => datasourceParams));
  }

  override update(data: Update<DataSource>): Observable<DataSource> {
    return this.dataSourcesApiService.updateDatasource(data).pipe(map((item) => ({ ...item, name: item.params.name })));
  }

  override delete(id: string): Observable<string> {
    return this.dataSourcesApiService.deleteDatasource(id);
  }
}
