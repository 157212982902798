import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { UserFunctionsDataService } from './user-functions-data.service';
import { KE_USER_FUNCTIONS_LIST_ENTITY_NAME } from '@selfai-platform/store';
import { UserFunctionsListItem } from '../models';

@Injectable({ providedIn: 'root' })
export class UserFunctionsListStore extends EntityCollectionServiceBase<UserFunctionsListItem> {
  constructor(
    public entityDataService: EntityDataService,
    public userFunctionsService: UserFunctionsDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_USER_FUNCTIONS_LIST_ENTITY_NAME, userFunctionsService);
    super(KE_USER_FUNCTIONS_LIST_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
