<div *ngIf="isShow" class="ddp-wrap-setting">
  <div class="ddp-wrap-edit">
    <label class="ddp-label-type">{{ 'msg.board.li.sel.type' | translate }}</label>
    <div class="ddp-ui-edit-option">
      <ul class="ddp-list-buttons">
        <li
          [ngClass]="{ 'ddp-selected': isSingleSelect(targetFilter) }"
          (click)="setSelectorType(targetFilter, 'SINGLE')"
        >
          {{ 'msg.board.li.single' | translate }}
        </li>
        <li
          [ngClass]="{ 'ddp-selected': !isSingleSelect(targetFilter) }"
          (click)="setSelectorType(targetFilter, 'MULTI')"
        >
          {{ 'msg.board.li.multiple' | translate }}
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="isShow" class="ddp-filter-form">
  <div class="ddp-option-type">
    <div class="ddp-option-in">
      <div class="ddp-option-btn">
        <div
          (clickOutside)="targetFilter['isShowCandidateFilter'] = false"
          [class.ddp-selected]="targetFilter['isShowCandidateFilter']"
          class="ddp-wrap-edit-set"
        >
          <a
            (click)="toggleConfigFilteringLayer(targetFilter)"
            href="javascript:"
            class="ddp-btn-filter ddp-btn-box"
          ></a>
          <div class="ddp-ui-tooltip-info">
            <em class="ddp-icon-view-top"></em>
            {{ 'msg.board.ui.filter.filtering' | translate }}
          </div>
          <div class="ddp-ui-edit-set">
            <label class="ddp-label-title">Matcher</label>
            <ul class="ddp-list-buttons" *ngFor="let type of matcherTypeList">
              <li
                [ngClass]="{ 'ddp-selected': selectedMatcherType.value === type.value }"
                (click)="onChangeMatcherType(type)"
              >
                {{ type.label }}
              </li>
            </ul>
            <div class="ddp-edit-set-contents">
              <div class="ddp-form-edit-set ddp-clear">
                <div *ngIf="isWildCardMatcher()">
                  <label>
                    <span *ngIf="isSetWildCard(wildcard)" class="ddp-data-info">
                      {{ getWildCardDesc(wildcard) }}
                      <span class="ddp-data2">'{{ wildcard.value }}'</span>
                    </span>
                    <a
                      *ngIf="isSetWildCard(wildcard)"
                      (click)="$event.stopPropagation(); resetWildcard(wildcard)"
                      href="javascript:"
                      class="ddp-btn-reset ddp-hover-tooltip"
                    >
                      <div class="ddp-ui-tooltip-info ddp-down">
                        <em class="ddp-icon-view-top"></em>
                        {{ 'msg.page.btn.reset' | translate }}
                      </div>
                    </a>
                  </label>
                  <div class="ddp-form-edit-set ddp-clear">
                    <div class="ddp-col-4">
                      <input type="text" class="ddp-input-typebasic" [(ngModel)]="wildcard.value" />
                    </div>
                    <div class="ddp-col-3">
                      <component-select
                        #wildCardContains
                        [array]="wildCardTypeList"
                        [viewKey]="'name'"
                        (onSelected)="wildcard.contains = $event ? $event['value'] : null"
                      ></component-select>
                    </div>
                  </div>
                </div>
                <div *ngIf="isRegularExpressionMatcher()">
                  <input type="text" class="ddp-input-typebasic" [(ngModel)]="regExpr.expr" />
                </div>
              </div>
            </div>
            <label class="ddp-label-title">
              {{ 'msg.board.th.filter.cond' | translate }}
              <span *ngIf="isSetCondition(condition)" class="ddp-data-info">
                <span class="ddp-data">{{ condition.field ? condition.field : '' }}</span>
                {{ getConditionDesc(condition) }}
                <span class="ddp-data2">{{ condition.value }}</span>
              </span>
              <a
                *ngIf="isSetCondition(condition)"
                (click)="$event.stopPropagation(); resetCondition(condition)"
                href="javascript:"
                class="ddp-btn-reset"
              >
                <div class="ddp-ui-tooltip-info ddp-down">
                  <em class="ddp-icon-view-top"></em>
                  {{ 'msg.page.btn.reset' | translate }}
                </div>
              </a>
            </label>
            <div class="ddp-form-edit-set ddp-clear">
              <div class="ddp-col-4">
                <component-select
                  #conditionField
                  [array]="measureFields"
                  [viewKey]="'name'"
                  [usePlaceholder]="true"
                  [unselectedMessage]="'msg.board.filter.ui.select-measure' | translate"
                  (onSelected)="condition.field = $event ? $event['name'] : null"
                ></component-select>
              </div>
              <div class="ddp-col-3">
                <component-select
                  #conditionAggregation
                  [array]="aggregationTypeList"
                  [viewKey]="'name'"
                  (onSelected)="condition.aggregation = $event ? $event['value'] : null"
                ></component-select>
              </div>
              <div class="ddp-col-2">
                <component-select
                  #conditionInequality
                  [array]="conditionTypeList"
                  [viewKey]="'name'"
                  (onSelected)="condition.inequality = $event ? $event['value'] : null"
                ></component-select>
              </div>
              <div class="ddp-col-3">
                <input type="text" input-mask="number" class="ddp-input-typebasic" [(ngModel)]="condition.value" />
              </div>
            </div>
            <label class="ddp-label-title">
              {{ 'msg.board.ui.filter.limitation' | translate }}
              <span *ngIf="isSetLimit(limitation)" class="ddp-data-info">
                <span class="ddp-data">{{ getLimitDesc1(limitation) }} {{ limitation.value }} of&nbsp;</span>
                <span class="ddp-data2">{{ getLimitDesc2(limitation) }} ( {{ limitation.field }} )</span>
              </span>
              <a
                *ngIf="isSetLimit(limitation)"
                (click)="$event.stopPropagation(); resetLimitation(limitation)"
                href="javascript:"
                class="ddp-btn-reset"
              >
                <div class="ddp-ui-tooltip-info ddp-down">
                  <em class="ddp-icon-view-top"></em>
                  {{ 'msg.page.btn.reset' | translate }}
                </div>
              </a>
            </label>
            <div class="ddp-form-edit-set ddp-clear">
              <div class="ddp-col-3">
                <component-select
                  #limitPosition
                  [array]="limitTypeList"
                  [viewKey]="'name'"
                  (onSelected)="limitation.position = $event ? $event['value'] : null"
                ></component-select>
              </div>
              <div class="ddp-col-2">
                <input type="text" input-mask="number" class="ddp-input-typebasic" [(ngModel)]="limitation.value" />
              </div>
              <div class="ddp-col-4">
                <component-select
                  #limitField
                  [array]="measureFields"
                  [viewKey]="'name'"
                  [usePlaceholder]="true"
                  [unselectedMessage]="'msg.board.filter.ui.select-measure' | translate"
                  (onSelected)="limitation.field = $event ? $event['name'] : null"
                ></component-select>
              </div>
              <div class="ddp-col-3">
                <component-select
                  #limitAggregation
                  [array]="aggregationTypeList"
                  [viewKey]="'name'"
                  (onSelected)="limitation.aggregation = $event ? $event['value'] : null"
                ></component-select>
              </div>
            </div>
            <div class="ddp-pop-buttons">
              <a (click)="resetAll()" href="javascript:" class="ddp-btn-pop" style="margin-right: 2px">
                <em class="ddp-btn-reset"></em>
                {{ 'msg.page.btn.reset' | translate }}
              </a>
              <a (click)="candidateWithValidation()" href="javascript:" class="ddp-btn-pop ddp-bg-gray">
                <em class="ddp-icon-checked"></em>
                {{ 'msg.page.btn.apply' | translate }}
              </a>
            </div>
          </div>
        </div>
        <div
          (clickOutside)="targetFilter['isShowCandidateSort'] = false"
          [class.ddp-selected]="targetFilter['isShowCandidateSort']"
          class="ddp-ui-sort ddp-btn-box"
        >
          <a
            (click)="targetFilter['isShowCandidateSort'] = !targetFilter['isShowCandidateSort']"
            href="javascript:"
            class="ddp-icon-sort"
          ></a>
          <div class="ddp-ui-tooltip-info">
            <em class="ddp-icon-view-top"></em>
            {{ 'msg.comm.ui.soring.order' | translate }}
          </div>
          <div class="ddp-wrap-popup2">
            <ul class="ddp-list-popup">
              <li
                [class.ddp-selected]="
                  sortBy.COUNT === targetFilter.sort.by && sortDirection.ASC === targetFilter.sort.direction
                "
              >
                <a href="javascript:" (click)="sortCandidateValues(targetFilter, sortBy.COUNT, sortDirection.ASC)">
                  {{ 'msg.comm.ui.soring.frequency.asc' | translate }}
                  <em class="ddp-icon-check"></em>
                </a>
              </li>
              <li
                [class.ddp-selected]="
                  sortBy.COUNT === targetFilter.sort.by && sortDirection.DESC === targetFilter.sort.direction
                "
              >
                <a href="javascript:" (click)="sortCandidateValues(targetFilter, sortBy.COUNT, sortDirection.DESC)">
                  {{ 'msg.comm.ui.soring.frequency.desc' | translate }}
                  <em class="ddp-icon-check"></em>
                </a>
              </li>
              <li
                [class.ddp-selected]="
                  sortBy.TEXT === targetFilter.sort.by && sortDirection.ASC === targetFilter.sort.direction
                "
              >
                <a href="javascript:" (click)="sortCandidateValues(targetFilter, sortBy.TEXT, sortDirection.ASC)">
                  {{ 'msg.comm.ui.soring.alphnumeric.asc' | translate }}
                  <em class="ddp-icon-check"></em>
                </a>
              </li>
              <li
                [class.ddp-selected]="
                  sortBy.TEXT === targetFilter.sort.by && sortDirection.DESC === targetFilter.sort.direction
                "
              >
                <a href="javascript:" (click)="sortCandidateValues(targetFilter, sortBy.TEXT, sortDirection.DESC)">
                  {{ 'msg.comm.ui.soring.alphnumeric.desc' | translate }}
                  <em class="ddp-icon-check"></em>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="ddp-option-search">
        <div
          (clickOutside)="inactiveSearchInput()"
          [class.ddp-search]="targetFilter['isShowSearch']"
          [class.ddp-focus]="targetFilter['isShowSearch']"
          [class.ddp-warning]="isOverCandidateWarning"
          [class.ddp-nofilter]="isNoFiltering() && targetFilter['isShowSearch']"
          class="ddp-search-value"
        >
          <a (click)="candidateFromSearchText()" href="javascript:" class="ddp-btn-line">
            {{ 'msg.comm.ui.search-all' | translate }}
          </a>
          <div class="ddp-txt-nofilter">{{ 'msg.comm.ui.no.filtering' | translate }}</div>
          <div class="ddp-data-value">
            <em class="icon-search-p"></em>
            <input
              #inputSearch
              type="text"
              placeholder="{{ 'msg.board.ui.search.item.name' | translate }}"
              [(ngModel)]="searchText"
              (focus)="targetFilter['isShowSearch'] = true"
              (keyup)="setCandidatePage(1, true)"
            />
            <a
              *ngIf="searchText"
              (click)="searchText = ''; candidateFromSearchText()"
              href="javascript:"
              class="ddp-btn-del"
            ></a>
            <em class="icon-warning"></em>
            <div
              class="ddp-pop-warning"
              [innerHTML]="'msg.board.filter.limmited-alert' | translate: { value: candidateListSize }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ddp-select-setting ddp-clear">
    <span class="ddp-data-total">
      {{ 'msg.comm.ui.total' | translate }}
      <strong>{{ totalCount }}</strong>
    </span>
    <ul class="ddp-list-select">
      <li *ngIf="!isSingleSelect(targetFilter)">
        <a href="javascript:" (click)="candidateSelectAll()">{{ 'msg.comm.ui.list.sel.all' | translate }}</a>
      </li>
      <li>
        <a href="javascript:" (click)="candidateDeselectAll()">
          <span *ngIf="isSingleSelect(targetFilter)">{{ 'msg.comm.ui.list.desel' | translate }}</span>
          <span *ngIf="!isSingleSelect(targetFilter)">{{ 'msg.comm.ui.list.desel.all' | translate }}</span>
        </a>
      </li>
    </ul>
    <div class="ddp-setting">
      <div class="ddp-label-setting">
        <em class="ddp-icon-eye"></em>
        Turn all
      </div>
      <div class="ddp-setting-check">
        <a (click)="toggleAllCandidateValues(true)" href="javascript:">on</a>
        <a (click)="toggleAllCandidateValues(false)" href="javascript:">off</a>
      </div>
    </div>
  </div>
  <div class="ddp-box-item-search ddp-type-filter" [style.top.px]="useDefineValue ? 188 : 0">
    <ng-container *ngTemplateOutlet="candidateListTemplate"></ng-container>

    <div
      class="ddp-data-result2"
      [ngStyle]="{ bottom: useDefineValue ? '60px' : '' }"
      [class.sys-essential-result]="!useDefineValue"
    >
      <div class="ddp-ui-values">
        <div class="ddp-data-values">
          <label class="ddp-label-check-value">
            <input
              (click)="targetFilter.showSelectedItem = !targetFilter.showSelectedItem; setCandidatePage(1, true)"
              [checked]="targetFilter.showSelectedItem"
              type="checkbox"
            />
            <i></i>
          </label>
          <ng-container *ngIf="targetFilter.showSelectedItem">
            {{ 'msg.board.filter.selected-values' | translate: { value: selectedValues.length } }}
          </ng-container>
          <ng-container *ngIf="!targetFilter.showSelectedItem">
            {{ 'msg.board.filter.all-values' | translate: { value: candidateListSize } }}
          </ng-container>
        </div>
        <div class="ddp-ui-page">
          <a
            (click)="setCandidatePage(currentPage - 1)"
            [class.ddp-disabled]="1 === currentPage"
            href="javascript:"
            class="ddp-btn-prev"
          ></a>
          <span class="ddp-data-page">
            <em>{{ currentPage }}</em>
            /
            <em>{{ lastPage }}</em>
          </span>
          <a
            (click)="setCandidatePage(currentPage + 1)"
            [class.ddp-disabled]="lastPage === currentPage"
            href="javascript:"
            class="ddp-btn-next"
          ></a>
        </div>
      </div>
    </div>
    <div class="ddp-box-search">
      <component-input
        [value]="newCandidateName"
        [inputClass]="'ddp-input-typebasic'"
        [placeHolder]="'msg.board.ui.filter.defined-value.ph' | translate"
        (changeValue)="newCandidateName = $event"
      ></component-input>
      <a href="javascript:" class="ddp-btn-solid3" (click)="addNewCandidateValue()">
        {{ 'msg.board.btn.add' | translate }}
      </a>
    </div>
  </div>
</div>

<ng-template #candidateListTemplate>
  <ul class="ddp-list-checktype ddp-type-list" [style.bottom.px]="useDefineValue ? 95 : ''">
    <ng-container *ngIf="isPageCandidateListEmpty">
      <li>
        <label class="no-rslt">
          {{ 'msg.comm.ui.no.rslt' | translate }}
        </label>
      </li>
    </ng-container>

    <ng-container *ngFor="let pageCandidate of pageCandidateList">
      <li>
        <label class="ddp-label-checkbox" *ngIf="!isSingleSelect(targetFilter)">
          <input
            type="checkbox"
            (click)="candidateSelect(pageCandidate, $event)"
            [checked]="isCheckedItem(pageCandidate)"
          />
          <i class="ddp-icon-checkbox"></i>
          <span class="ddp-txt-checkbox">
            {{
              null === pageCandidate.name || 'null' === pageCandidate.name
                ? '(Null)'
                : '' === pageCandidate.name || undefined === pageCandidate.name
                ? '(Empty)'
                : pageCandidate.name
            }}
            <span class="ddp-data-valuenum">{{ pageCandidate.count }}</span>
          </span>
        </label>

        <label class="ddp-label-radio" *ngIf="isSingleSelect(targetFilter)">
          <input
            type="radio"
            name="candidateSingle_{{ compUUID }}"
            (click)="candidateSelect(pageCandidate, $event)"
            [checked]="isCheckedItem(pageCandidate)"
          />
          <i class="ddp-icon-radio"></i>
          <span class="ddp-txt-radio">
            {{
              null === pageCandidate.name || 'null' === pageCandidate.name
                ? '(Null)'
                : '' === pageCandidate.name || undefined === pageCandidate.name
                ? '(Empty)'
                : pageCandidate.name
            }}
            <span class="ddp-data-valuenum">{{ pageCandidate.count }}</span>
          </span>
        </label>

        <em
          (click)="candidateShowToggle(pageCandidate)"
          [class.ddp-icon-eyes2-on]="isShowItem(pageCandidate)"
          [class.ddp-icon-eyes2-none]="!isShowItem(pageCandidate)"
        ></em>

        <ng-container *ngIf="pageCandidate.isDefinedValue">
          <em (click)="deleteDefinedValue(pageCandidate)" class="ddp-icon-sclose2"></em>
        </ng-container>
      </li>
    </ng-container>
  </ul>
</ng-template>
