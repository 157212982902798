import { Observable, Subject } from 'rxjs';

export function readJsonFile<T = object>(file: File, encoding = 'UTF-8'): Observable<T> {
  const sub = new Subject<T>();

  const fileReader = new FileReader();
  fileReader.readAsText(file, encoding);
  fileReader.onload = (evt) => {
    const jsonString = evt.target?.result;
    if (typeof jsonString === 'string') {
      try {
        const data = JSON.parse(jsonString);
        sub.next(data);
        sub.complete();
      } catch {
        sub.error(new Error('Error parsing JSON'));
      }
    }
  };

  fileReader.onerror = () => {
    sub.error(new Error(`Error. Can't upload file`));
  };

  return sub.asObservable();
}
