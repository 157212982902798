import { AggregationType } from '../../../../datasource';

import { Filter, createFilter } from './filter.model';

export interface MeasureInequalityFilter extends Filter {
  aggregation?: AggregationType;
  inequality?: InequalityType;
  value?: number;
}

export function createMeasureInequalityFilter(params: Partial<MeasureInequalityFilter> = {}): MeasureInequalityFilter {
  return {
    ...createFilter(),
    type: 'measure_inequality',
    ...params,
  };
}

export enum InequalityType {
  EQUAL_TO = 'EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  EQUAL_GREATER_THAN = 'EQUAL_GREATER_THAN',
  EQUAL_LESS_THAN = 'EQUAL_LESS_THAN',
}
