import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { FavoriteListApi, FavoriteListQueryParams } from '../models';

@Injectable()
export class FavoriteApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  loadFavoriteList(params: FavoriteListQueryParams): Observable<FavoriteListApi> {
    return this.kdBackendApiService.get<FavoriteListApi>(this.kdBackendApiService.buildPath('favorites', params));
  }

  remove(id: string): Observable<string> {
    return this.kdBackendApiService
      .delete<void>(this.kdBackendApiService.buildPath(`favorites/${id}`))
      .pipe(map(() => id));
  }
}
