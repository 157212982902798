import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SettingsMenuComponent } from './settings-menu.component';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, RadioButtonModule, DropdownModule, ReactiveFormsModule, TranslateModule],
  declarations: [SettingsMenuComponent],
  exports: [SettingsMenuComponent],
})
export class SettingsMenuComponentModule {}
