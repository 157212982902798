<div class="ddp-wrap-divide">
  <div class="ddp-divide2">
    <div class="ddp-list-label">{{ 'msg.page.format.format.title' | translate }}</div>
    <component-select
      #typeListSelect
      [array]="typeList"
      [viewKey]="'name'"
      (onSelected)="onTypeChange($event)"
    ></component-select>
  </div>

  <div class="ddp-divide2" *ngIf="selectedType['value'] == 'currency'">
    <component-select
      #signListSelect
      [array]="currencySignList"
      [viewKey]="'name'"
      (onSelected)="onSignChange($event)"
    ></component-select>
  </div>

  <div
    class="ddp-divide2"
    *ngIf="selectedType['value'] != 'currency'"
    [class.ddp-disabled]="selectedType?.value == 'origin'"
  >
    <div class="ddp-list-label">{{ 'msg.page.th.decimal.place' | translate }}</div>

    <div class="ddp-input-number">
      <input
        (keyup)="$event.keyCode == 13 ? onDigitValid() : null"
        (clickOutside)="onDigitValid()"
        [(ngModel)]="decimalCopy"
        type="number"
        maxlength="1"
      />
      <div class="ddp-input-number-nav">
        <div (click)="onDigitChange(false)" class="ddp-input-number-button ddp-input-number-down"></div>
        <div (click)="onDigitChange(true)" class="ddp-input-number-button ddp-input-number-up"></div>
      </div>
    </div>
  </div>

  <div class="ddp-divide2">
    <div class="ddp-list-label">{{ 'msg.page.format.numeric.alias' | translate }}</div>

    <component-select
      #numericAliasListSelect
      [array]="numericAliasList"
      [viewKey]="'name'"
      (onSelected)="onNumericAliasChange($event)"
    ></component-select>
  </div>

  <div class="ddp-divide2">
    <div class="ddp-part-check">
      <label (click)="$event.preventDefault(); onThousandsSepChange()" class="ddp-label-checkbox ddp-check-full">
        <input [checked]="useThousandsSep" type="checkbox" />
        <i class="ddp-icon-checkbox"></i>
        <span class="ddp-txt-checkbox" style="width: 125px">{{ 'msg.page.th.thousand.sep' | translate }}</span>
      </label>
    </div>
  </div>
</div>

<div class="ddp-wrap-divide">
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider" style="width: 100px; display: block">
      {{ 'msg.page.format.custom.symbol' | translate }}
    </span>

    <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
      <input type="checkbox" id="customSymbol" [ngModel]="customSymbol" (click)="showCustomSymbol()" />
      <label for="customSymbol"><span class="ddp-slide"></span></label>
    </div>
  </div>

  <div *ngIf="customSymbol" class="ddp-list-sub2">
    <div class="ddp-divide2">
      <div class="ddp-list-label" style="width: 100px; display: block">
        {{ 'msg.page.format.custom.symbol.enter' | translate }}
      </div>
      <component-input
        [inputClass]="'ddp-input-typebasic'"
        [value]="customSymbol['value']"
        [isTrim]="false"
        (changeValue)="customSymbol['value'] = $event; changeSymbol()"
      ></component-input>
    </div>
    <div class="ddp-divide2">
      <div class="ddp-list-label" style="width: 100px; display: block">
        {{ 'msg.page.format.custom.symbol.position' | translate }}
      </div>

      <component-select
        [array]="positionList"
        [viewKey]="'name'"
        [defaultIndex]="'BEFORE' === customSymbol?.pos?.toString() ? 0 : 1"
        (onSelected)="changePosition($event)"
      ></component-select>
    </div>
  </div>
</div>

<div class="ddp-wrap-divide">
  <div class="ddp-divide2">
    <div class="ddp-list-label">{{ 'msg.page.format.preview' | translate }}</div>
    <div class="ddp-preview">{{ preview }}</div>
  </div>
</div>
