<selfai-platform-dialog-header
  [header]="'workflow.cubes.read-data-frame.modal-header' | translate"
  [nodeId]="nodeId"
  (clickSave)="onSubmit()"
  (closeDialog)="onCloseDialog()"
></selfai-platform-dialog-header>
@if(hasParameters) {
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <selfai-platform-data-source-selector
    [control]="form.controls.dataSourceId"
    class="h-25rem"
  ></selfai-platform-data-source-selector>
</form>
} @else {
<p-message text="{{ 'workflow.cubes.read-data-frame.no-parameters' | translate }}" severity="error"></p-message>
}
