import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NumberValueAccessor } from '@angular/forms';
import { DestroyService } from '@selfai-platform/shared';

import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'bi-shared-settings-number-counter',
  templateUrl: './number-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '(input)': 'onChange($event.target.value)', '(blur)': 'onTouched()' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiSharedSettingsNumberCounterComponent),
      multi: true,
    },
    DestroyService,
  ],
})
export class UiSharedSettingsNumberCounterComponent extends NumberValueAccessor implements OnInit {
  @Input() min?: number;
  @Input() max?: number;
  @Input() step? = 1;
  @Input() value?: number;

  debouncedValue$ = new Subject<number>();

  constructor(
    @Inject(DestroyService) protected readonly destroy$: DestroyService,
    _renderer: Renderer2,
    _elementRef: ElementRef,
  ) {
    super(_renderer, _elementRef);
  }

  ngOnInit(): void {
    this.debouncedValue$
      .pipe(
        tap((value) => (this.value = value)),
        debounceTime(300),
        takeUntil(this.destroy$),
      )

      .subscribe((value) => this.onChange(value));
  }

  override writeValue(value: number): void {
    const normalizedValue = value == null ? '' : value;
    this.value = this.correctValue(normalizedValue || 0);
  }

  override registerOnChange(fn: (_: number | null) => void): void {
    this.onChange = (value: string) => {
      const normalizedValue = value == '' ? null : this.correctValue(parseFloat(value));
      this.value = normalizedValue;
      fn(normalizedValue);
    };
  }

  onClickDown(): void {
    const newValue = (this.value || 0) - this.step;
    this.debounceChange(this.correctValue(newValue));
  }

  onClickUp(): void {
    const newValue = (this.value || 0) + this.step;
    this.debounceChange(this.correctValue(newValue));
  }

  private correctValue(value: number): number {
    if (this.min != null && value < this.min) {
      return this.min;
    }

    if (this.max != null && value > this.max) {
      return this.max;
    }

    return value;
  }

  private debounceChange(value: number): void {
    this.debouncedValue$.next(value);
  }
}
