<ul class="recycle-menu" [ngClass]="menuMode$ | async">
  <ng-container *ngIf="hasAccessToUserRecycleBin">
    <li class="recycle-menu_element">
      <a
        [routerLink]="['kd', PATH_RECYCLEBIN_USER]"
        [routerLinkActive]="['recycle-menu_link-active']"
        [routerLinkActiveOptions]="{ exact: true }"
        class="recycle-menu_link"
      >
        <i class="pi pi-trash"></i>
        {{ 'recycle.ui.title' | translate }}
      </a>
    </li>
  </ng-container>

  <ng-container *ngIf="hasAccessToAdminRecycleBin$ | async">
    <li class="recycle-menu_element">
      <a
        [routerLink]="['kd', PATH_RECYCLEBIN_ADMIN]"
        [routerLinkActive]="['recycle-menu_link-active']"
        [routerLinkActiveOptions]="{ exact: true }"
        class="recycle-menu_link"
      >
        <i class="pi pi-trash"></i>
        {{ 'recycle.ui.admin-title' | translate }}
      </a>
    </li>
  </ng-container>
</ul>
