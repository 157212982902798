import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PipelineConfigService } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { KeBackendInfoResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class KeBackendInfoService {
  private apiurl: string;
  private readonly pathVersion = '/version';

  constructor(private readonly http: HttpClient, private readonly pipelineConfigService: PipelineConfigService) {
    pipelineConfigService.configLoaded$.subscribe((loaded) => {
      if (!loaded) return;
      const config = this.pipelineConfigService.getConfig();
      this.apiurl = `${config.hosts.api}/${config.versions.url}`;
    });
  }

  getVersion(): Observable<string> {
    return this.http
      .get<KeBackendInfoResponse>(`${this.apiurl}${this.pathVersion}`)
      .pipe(map((versionResponse) => versionResponse.version));
  }
}
