import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FavoriteCubeAddComponent } from './favorite-cube-add.component';

@NgModule({
  imports: [CommonModule, InputTextModule, ButtonModule, ReactiveFormsModule, AutoFocusModule],
  declarations: [FavoriteCubeAddComponent],
  exports: [FavoriteCubeAddComponent],
})
export class FavoriteCubeAddComponentModule {}
