import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Injector, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AppInitService,
  DestroyService,
  KeBuildVersionService,
  PipelineConfigService,
  ScriptService,
  SharedModule,
} from '@selfai-platform/shared';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { filter, switchMap, tap } from 'rxjs';
import { AggregatorCamelModule } from './aggregator-camel/aggregator-camel.module';
import { KeBackendInfoService } from './backend-info/services/ke-backend-info.service';
import {
  CmsFormCreatorComponentModule,
  CodeEditorTransformationComponentModule,
  CreateTriggerComponentModule,
  CubeListSidebarComponentModule,
  ExpressionToolComponentModule,
  FavoriteCubeAddComponentModule,
  JsonToColumnTransformationComponentModule,
  ReadDataFrameComponent,
} from './cubes/components';
import { WriteDataFrameTransitComponent } from './cubes/components/dialogs/write-data-frame-transit';
import { KeMqInfoService } from './mq/services/ke-mq-info.service';
import { PipelineRbacService } from './rbac';
import { pipelineModuleRoutes } from './routes';
import { PipelineVariablesEditorModule } from './variables-editor/pipeline-variables-editor-component.module';
import { PipelineVersionManagerModule } from './versions-manager';
import { WorkflowEditorComponent } from './workflow-editor/components';
import { UserFunctionManagementGuard } from './user-functions';

@NgModule({
  declarations: [WorkflowEditorComponent],
  exports: [
    ExpressionToolComponentModule,
    CreateTriggerComponentModule,
    JsonToColumnTransformationComponentModule,
    ReadDataFrameComponent,
    WriteDataFrameTransitComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    AggregatorCamelModule,
    CommonModule,
    RouterModule.forChild(pipelineModuleRoutes),
    SharedModule,
    PipelineVariablesEditorModule,
    CreateTriggerComponentModule,
    JsonToColumnTransformationComponentModule,
    ReadDataFrameComponent,
    WriteDataFrameTransitComponent,
    ConfirmDialogModule,
    PipelineVersionManagerModule,
    ExpressionToolComponentModule,
    CubeListSidebarComponentModule,
    DragDropModule,
    FavoriteCubeAddComponentModule,
    CodeEditorTransformationComponentModule,
    CmsFormCreatorComponentModule,
  ],
  providers: [
    ScriptService,
    DestroyService,
    PipelineConfigService,
    PipelineRbacService,
    KeMqInfoService,
    UserFunctionManagementGuard,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class PipelineModuleModule {
  constructor(
    readonly pipelineRbacService: PipelineRbacService,
    readonly appInitService: AppInitService,
    readonly injector: Injector,
  ) {
    appInitService.appInitialiazed$
      .pipe(
        filter((loaded) => loaded),
        switchMap(() => {
          const kdBackendService = injector.get(KeBackendInfoService);

          return kdBackendService.getVersion();
        }),
        tap((info) => {
          const versionSvc = injector.get(KeBuildVersionService);
          versionSvc.next(info);
        }),
        switchMap(() => {
          return pipelineRbacService.loadRbacInfo();
        }),
      )
      .subscribe(() => {
        this.appInitService.setPipelineLoaded();
      });
  }
}
