<span 
    *ngIf="usedInNodes" 
    appearance="icon"
    pButton
    class="p-button-text p-button-link p-button-success p-0" 
    icon="pi pi-check-circle"
    (click)="op.toggle($event)">
</span>

<p-overlayPanel #op [dismissable]="false" [showCloseIcon]="true">
    <ng-template pTemplate>
        <div class="p-d-flex p-flex-column p-justify-center p-align-center">
            <ul class="p-m-0 p-text-left nodes-list">
                <li *ngFor="let node of usedInNodes" (click)="navigateToNode(node)">{{ node.title }}</li>
            </ul>
        </div>
    </ng-template>
</p-overlayPanel>
