import { Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import { BoundFilter, CustomField, Dashboard, DatasourceField as Field } from '@selfai-platform/bi-domain';

import { DatasourceService } from '../../../datasource/service/datasource.service';
import { AbstractFilterPopupComponent } from '../abstract-filter-popup.component';

import { BoundFilterComponent } from './bound-filter.component';

@Component({
  selector: 'app-config-filter-bound',
  templateUrl: './configure-filters-bound.component.html',
})
export class ConfigureFiltersBoundComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(BoundFilterComponent)
  private _boundFilterComp: BoundFilterComponent;

  public isShow = false;

  public targetFilter: BoundFilter;
  public targetField: Field | CustomField;
  public dashboard: Dashboard;

  constructor(
    private datasourceService: DatasourceService,

    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public showComponent(board: Dashboard, targetFilter: BoundFilter, targetField?: Field | CustomField) {
    this.pageLoaderService.show();
    this.datasourceService
      .getCandidateForFilter(targetFilter, board, [], targetField)
      .then((result) => {
        this._setBoundFilter(result, targetFilter);
        this.dashboard = _.cloneDeep(board);
        this.targetField = _.cloneDeep(targetField);
        this.targetFilter = _.cloneDeep(targetFilter);
        this.isShow = true;
        this.safelyDetectChanges();
        this.pageLoaderService.hide();
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  public getData(): BoundFilter {
    return this._boundFilterComp.getData();
  }

  private _setBoundFilter(result: any, targetFilter: BoundFilter) {
    const boundFilter: BoundFilter = targetFilter;
    if (result && result.hasOwnProperty('maxValue')) {
      if ((boundFilter.min === 0 && boundFilter.max === 0) || boundFilter.min == null) {
        boundFilter.min = result.minValue;
        boundFilter.max = result.maxValue;
      }
      boundFilter.maxValue = result.maxValue;
      boundFilter.minValue = result.minValue;
    } else {
      boundFilter.min = null;
      boundFilter.max = null;
      boundFilter.maxValue = null;
      boundFilter.minValue = null;
    }
  }
}
