import {
  AxisLabelType,
  AxisType,
  BarMarkType,
  BaseOption,
  CHART_STRING_DELIMITER,
  FontSize,
  LineMarkType,
  Orient,
  PivotTableInfo,
  UIOption,
  UIOrient,
} from '@selfai-platform/bi-domain';
import { Axis, LineSeriesOption } from 'echarts';
import * as _ from 'lodash';
import { OptionGenerator } from '../utils';

import {
  DataZoomComponentOption,
  LegendComponentOption,
  VisualMapComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';

export class CommonOptionConverter {
  public static convertCommonAxis(
    chartOption: BaseOption,
    uiOption: UIOption,
    axisType: AxisType,
    fieldInfo: PivotTableInfo,
  ): BaseOption {
    if (uiOption['align'] === undefined) {
      return chartOption;
    }

    const orientChart = uiOption['align'];
    const xAxis: XAXisComponentOption[] = chartOption.xAxis as XAXisComponentOption[];
    const yAxis: YAXisComponentOption[] = chartOption.yAxis as YAXisComponentOption[];

    chartOption.yAxis[0].name = uiOption.yAxis.customName
      ? uiOption.yAxis.customName
      : fieldInfo.aggs.join(CHART_STRING_DELIMITER);
    chartOption.yAxis[0].axisName = fieldInfo.aggs.join(CHART_STRING_DELIMITER);

    const needRotate =
      orientChart === Orient.VERTICAL ? xAxis[0].type === AxisType.VALUE : xAxis[0].type === AxisType.CATEGORY;

    if (needRotate && axisType === AxisType.X) {
      this.convertAxisRotate(chartOption, orientChart, yAxis, xAxis);
    }

    if (needRotate && axisType === AxisType.Y) {
      this.convertAxisRotate(chartOption, orientChart, xAxis, yAxis);
    }

    this.convertAxisRotateName(chartOption, uiOption, fieldInfo, axisType);

    return chartOption;
  }

  public static convertAxisRotateName(
    chartOption: BaseOption,
    uiOption: UIOption,
    fieldInfo: PivotTableInfo,
    axisType: AxisType,
  ): BaseOption {
    if (!uiOption || uiOption['align'] === undefined) return chartOption;

    const axisList = _.compact(_.concat(uiOption.xAxis, uiOption.yAxis, uiOption.secondaryAxis));
    const orientChart = uiOption['align'];
    const targetAxis = axisType === AxisType.X ? uiOption.yAxis : uiOption.xAxis;
    const aggOrCols = axisType === AxisType.X ? 'aggs' : 'cols';
    const axisLabelType = axisType === AxisType.X ? AxisLabelType.COLUMN : AxisLabelType.ROW;
    const axisLabelTypeSub = axisType === AxisType.X ? AxisLabelType.SUBCOLUMN : AxisLabelType.SUBROW;

    const yAxis = axisList.filter((item) => {
      return item.mode === axisLabelType || item.mode === axisLabelTypeSub;
    });

    const copiedOption = _.cloneDeep(chartOption);
    const yAxisType = orientChart === Orient.VERTICAL ? AxisType.VALUE : AxisType.CATEGORY;

    if (
      orientChart === Orient.VERTICAL &&
      copiedOption.yAxis[0].type == AxisType.VALUE &&
      copiedOption.xAxis[0].type == AxisType.CATEGORY
    ) {
      return chartOption;
    }

    const axisName = targetAxis.customName ? targetAxis.customName : fieldInfo[aggOrCols].join(CHART_STRING_DELIMITER);

    (copiedOption[axisType] as YAXisComponentOption[] | XAXisComponentOption[]).forEach((axis, axisIndex) => {
      (
        chartOption[axisType === AxisType.X ? 'xAxis' : 'yAxis'] as YAXisComponentOption[] | XAXisComponentOption[]
      ).forEach((item, index) => {
        if (axis.type == yAxisType && copiedOption[axisType][index].axisName) {
          item.axisName = axisName;

          if (!yAxis[axisIndex].customName && copiedOption[axisType][index].name) {
            item.name = axisName;
          }
        }
      });
    });

    if (orientChart === Orient.HORIZONTAL) {
      chartOption.yAxis[0].name = axisName;
    }

    return chartOption;
  }

  public static convertAxisRotate(chartOption: BaseOption, orient: Orient, categoryAxis, valueAxis): BaseOption {
    if (orient === undefined) return chartOption;

    const subAxis: Axis[] = [];
    const axisType = orient === Orient.VERTICAL ? AxisType.CATEGORY : AxisType.VALUE;

    valueAxis.map((axis, idx) => {
      if (idx === 0) {
        axis.type = axisType;
        axis.data = _.cloneDeep(categoryAxis[0].data);
      } else {
        subAxis.push(axis);
      }
    });

    chartOption[orient === Orient.VERTICAL ? 'xAxis' : 'yAxis'] = [valueAxis[0]];
    chartOption[orient !== Orient.VERTICAL ? 'xAxis' : 'yAxis'] = [...categoryAxis, ...subAxis];

    if (orient === Orient.VERTICAL) {
      delete chartOption.yAxis[0].inverse;
    } else {
      chartOption.yAxis[0].inverse = true;
    }

    return chartOption;
  }

  public static convertCommonSeries(
    chartOption: BaseOption,
    uiOption: UIOption,
    fieldInfo: PivotTableInfo,
  ): BaseOption {
    if (!uiOption || !uiOption['mark']) return chartOption;

    const type = uiOption['mark'];

    const series = chartOption.series as LineSeriesOption[];
    series.map((obj) => {
      obj.areaStyle = _.eq(type, LineMarkType.AREA) ? OptionGenerator.AreaStyle.customAreaStyle(0.5) : undefined;

      let stackName = '';

      if (_.eq(uiOption['mark'], BarMarkType.STACKED)) {
        stackName = _.last(_.split(obj.name as string, CHART_STRING_DELIMITER));
        obj.stack = _.isEmpty(fieldInfo.rows) ? 'measureStack' : stackName;
      } else {
        delete obj.stack;
      }
    });

    return chartOption;
  }

  public static convertCommonFont(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    if (!uiOption.fontSize) return chartOption;

    const uiFontSize = uiOption.fontSize;
    let fontSize: number;

    switch (uiFontSize) {
      case FontSize.NORMAL:
        fontSize = 13;
        break;
      case FontSize.SMALL:
        fontSize = 11;
        break;
      case FontSize.LARGE:
        fontSize = 15;
        break;
    }

    _.each(chartOption.xAxis, (item: XAXisComponentOption) => {
      item.axisLabel.fontSize = fontSize;
      item.nameTextStyle.fontSize = fontSize;
    });

    _.each(chartOption.yAxis, (item: YAXisComponentOption) => {
      item.axisLabel.fontSize = fontSize;
      item.nameTextStyle.fontSize = fontSize;
    });

    if (chartOption.legend) (chartOption.legend as LegendComponentOption).textStyle.fontSize = fontSize;

    _.each(chartOption.series, (item: any) => {
      if (item.label) {
        if (item.label.rich && item.label.rich['align']) {
          item.label.rich['align']['fontSize'] = fontSize;
        } else {
          item.label.fontSize = fontSize;
        }
      }
    });

    if (chartOption.visualMap) {
      if (!(chartOption.visualMap as VisualMapComponentOption).textStyle)
        (chartOption.visualMap as VisualMapComponentOption).textStyle = {};

      (chartOption.visualMap as VisualMapComponentOption).textStyle.fontSize = fontSize;
    }

    if (
      _.eq(FontSize.LARGE, uiFontSize) &&
      (!uiOption['align'] || (uiOption['align'] && _.eq(UIOrient.VERTICAL, uiOption['align'])))
    ) {
      if (chartOption.dataZoom && (chartOption.dataZoom as DataZoomComponentOption[]).length > 0)
        chartOption.dataZoom[0].bottom = chartOption.dataZoom[0].bottom - 5;
    }

    return chartOption;
  }
}
