import { Injectable } from '@angular/core';

import { cloneDeep, eq, isNil } from 'lodash';

import { ChartSelectInfo, ChartSelectMode, Filter } from '@selfai-platform/bi-domain';

@Injectable()
export class PageWidgetSelectionFilterService {
  private _selectFilterList: any[] = [];
  private _currentSelectionFilters: Filter[] = [];
  public currentSelectionFilterString = '';

  get selectFilterList(): any[] {
    return this._selectFilterList;
  }

  get currentSelectionFilters(): Filter[] {
    return this._currentSelectionFilters;
  }

  set currentSelectionFilters(value: Filter[]) {
    this._currentSelectionFilters = value;
  }

  public changeSelectFilterList(data: ChartSelectInfo): void {
    if (eq(data.mode, ChartSelectMode.ADD)) {
      data.data.map((field) => {
        let isAlreadyFilter = false;
        this._selectFilterList.map((filter) => {
          if (eq(field.alias, filter.alias)) {
            isAlreadyFilter = true;

            field.data.map((fieldData) => {
              let isAlreadyData = false;
              filter.data.map((filterData) => {
                if (eq(filterData, fieldData)) {
                  isAlreadyData = true;
                }
              });

              if (!isAlreadyData) {
                filter.data.push(fieldData);
              }
            });
          }
        });

        if (!isAlreadyFilter) {
          this._selectFilterList.push(cloneDeep(field));
        }
      });
    } else if (eq(data.mode, ChartSelectMode.SUBTRACT)) {
      for (let num = this._selectFilterList.length - 1; num >= 0; num--) {
        const filter = this._selectFilterList[num];
        data.data.map((field) => {
          if (eq(field.alias, filter.alias)) {
            for (let num2 = field.data.length - 1; num2 >= 0; num2--) {
              const data1 = field.data[num2];
              filter.data.map((data2) => {
                if (eq(data1, data2)) {
                  filter.data.splice(num, 1);
                }
              });
            }

            if (filter.data.length == 0) {
              this._selectFilterList.splice(num, 1);
            }
          }
        });
      }
    } else if (eq(data.mode, ChartSelectMode.CLEAR)) {
      data.mode = ChartSelectMode.SUBTRACT;
      data.data = this._selectFilterList;

      this._selectFilterList = [];
    }
  }

  public changeExternalFilterList(externalFilters?: Filter[]): Filter[] {
    isNil(externalFilters) && (externalFilters = []);

    for (let num = this._selectFilterList.length - 1; num >= 0; num--) {
      const filter = this._selectFilterList[num];
      let isNotFilter = true;
      externalFilters.map((externalFilter) => {
        if (eq(externalFilter.field, filter.alias)) {
          isNotFilter = false;

          for (let num2 = filter.data.length - 1; num2 >= 0; num2--) {
            const data1 = filter.data[num2];
            let isNotData = true;
            externalFilter['valueList'].map((data2) => {
              if (eq(data1, data2)) {
                isNotData = false;
              }
            });
            if (isNotData) {
              filter.data.splice(num, 1);
            }
          }

          if (filter.data.length == 0) {
            this._selectFilterList.splice(num, 1);
          }
        }
      });

      if (isNotFilter) {
        this._selectFilterList.splice(num, 1);
      }
    }

    if (externalFilters) {
      externalFilters = cloneDeep(externalFilters);

      for (let num = externalFilters.length - 1; num >= 0; num--) {
        const filter = externalFilters[num];
        this._selectFilterList.map((field) => {
          if (eq(field.alias, filter.field)) {
            externalFilters.splice(num, 1);
          }
        });
      }
    }

    return externalFilters;
  }
}
