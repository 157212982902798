import {
  Component,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  OnChanges,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';

import { TimeRelativeFilter, TimeRelativeTense, TimeUnit } from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { relativeFilterGetPreview } from './relative-filter-format-preview';

export interface TimeRelativeFilterValue {
  tense: TimeRelativeTense;
  relTimeUnit: TimeUnit;
  value: number;
}

@Component({
  selector: 'selfai-bi-time-relative-filter',
  templateUrl: './time-relative-filter.component.html',
  providers: [DestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeRelativeFilterComponent implements OnChanges, OnInit {
  @Input()
  public filter: TimeRelativeFilter;

  @Input()
  public mode: 'WIDGET' | 'CHANGE' | 'PANEL' = 'CHANGE';

  @Output()
  public changeFilter: EventEmitter<TimeRelativeFilter> = new EventEmitter();

  public filterGroup: FormGroup;

  public timeUnits: { name: string; value: TimeUnit }[] = [];
  public tenses: { name: string; value: TimeRelativeTense }[] = [];
  public preview: string;

  constructor(private readonly destroyService: DestroyService, private readonly translateService: TranslateService) {
    this.initFilterForm();
    this.setTranslationForSelects();
  }

  public ngOnInit() {
    this.updatePreview();
    this.filterGroup.valueChanges.pipe(takeUntil(this.destroyService)).subscribe((value) => {
      //TODO Remove mutations
      this.filter.tense = value.tense;
      this.filter.relTimeUnit = value.relTimeUnit;
      this.filter.value = value.value;
      this.updatePreview();
      this.changeFilter.emit(this.filter);
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filter && !this.filterValuesAreEqual()) {
      this.setFormGroupValue();
    }
  }

  public filterValuesAreEqual() {
    return (
      this.filter.tense === this.filterGroup.get('tense').value &&
      this.filter.relTimeUnit === this.filterGroup.get('relTimeUnit').value &&
      this.filter.value === this.filterGroup.get('value').value
    );
  }

  public setData(filter: TimeRelativeFilter) {
    this.filter = filter;
    this.setFormGroupValue();
    this.updatePreview();
  }

  public getData(): TimeRelativeFilter {
    return this.filter;
  }

  public updatePreview(): void {
    this.preview = relativeFilterGetPreview(this.filter);
  }

  private setFormGroupValue() {
    this.filterGroup.get('tense').setValue(this.filter.tense);
    this.filterGroup.get('relTimeUnit').setValue(this.filter.relTimeUnit);
    this.filterGroup.get('value').setValue(this.filter.value || 1);
  }

  private setTranslationForSelects() {
    this.translateService
      .getStreamOnTranslationChange([
        'msg.current',
        'msg.previous',
        'msg.next.period',
        'msg.board.filter.ui.timeunit.minutes',
        'msg.board.filter.ui.timeunit.hours',
        'msg.board.filter.ui.timeunit.days',
        'msg.board.filter.ui.timeunit.weeks',
        'msg.board.filter.ui.timeunit.months',
        'msg.board.filter.ui.timeunit.years',
      ])
      .pipe(takeUntil(this.destroyService))
      .subscribe((data) => {
        this.tenses = [
          { name: data['msg.current'], value: TimeRelativeTense.CURRENT },
          { name: data['msg.previous'], value: TimeRelativeTense.PREVIOUS },
          { name: data['msg.next.period'], value: TimeRelativeTense.NEXT },
        ];

        this.timeUnits = [
          { name: data['msg.board.filter.ui.timeunit.minutes'], value: TimeUnit.MINUTE },
          { name: data['msg.board.filter.ui.timeunit.hours'], value: TimeUnit.HOUR },
          { name: data['msg.board.filter.ui.timeunit.days'], value: TimeUnit.DAY },
          { name: data['msg.board.filter.ui.timeunit.weeks'], value: TimeUnit.WEEK },
          { name: data['msg.board.filter.ui.timeunit.months'], value: TimeUnit.MONTH },
          { name: data['msg.board.filter.ui.timeunit.years'], value: TimeUnit.YEAR },
        ];
      });
  }

  private initFilterForm() {
    this.filterGroup = new FormGroup({
      tense: new FormControl<TimeRelativeTense>(TimeRelativeTense.CURRENT),
      relTimeUnit: new FormControl<TimeUnit>(TimeUnit.WEEK),
      value: new FormControl<number>(1),
    });
  }
}
