import { FieldType, TypeFieldValues } from '@selfai-platform/pipeline-common';

export function typeFieldIsNotEmpty(valueObj: unknown): boolean {
  return valueObj !== null && typeof valueObj === 'object' && Object.keys(valueObj).length > 0;
}

export function normalizeTypeField<T extends TypeFieldValues>(valueObj: T, defaultFieldType: FieldType): T {
  // some times backend can send empty object
  return !valueObj?.type
    ? ({ type: defaultFieldType, value: '' } as T)
    : ({ type: valueObj.type, value: valueObj.value || '' } as T);
}
