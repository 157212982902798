import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DestroyService } from '@selfai-platform/shared';
import { filter, take, takeUntil } from 'rxjs';
import { MenuMode, MenuService } from '../menu';
import { SelfaiTranslateService, UiLanguages } from '../translate';
import { LanguagesForSelectService } from '../translate/languages-for-select.service';

@Component({
  selector: 'selfai-platform-settings-menu,[selfaiPlatformSettingsMenu]',
  templateUrl: './settings-menu.component.html',
  styleUrls: ['./settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, LanguagesForSelectService],
})
export class SettingsMenuComponent implements OnInit {
  menuModeForm = new FormGroup({
    menuMode: new FormControl<MenuMode>(MenuMode.VERTICAL),
  });

  selectLaguageForm = new FormGroup({
    currentLanguage: new FormControl<UiLanguages | null>(null),
  });

  menuModes = MenuMode;

  constructor(
    public readonly languages: LanguagesForSelectService,
    private readonly menuService: MenuService,
    private readonly selfaiTranslateService: SelfaiTranslateService,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    this.menuService.menuMode$.pipe(take(1), takeUntil(this.destroy$)).subscribe((mode: MenuMode) => {
      this.menuModeForm.controls.menuMode.setValue(mode);
    });

    this.menuModeForm.controls.menuMode.valueChanges
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((mode: MenuMode) => this.menuService.setMenuMode(mode));

    this.selectLaguageForm.controls.currentLanguage.setValue(this.selfaiTranslateService.getCurrentLanguage());

    this.selectLaguageForm.controls.currentLanguage.valueChanges
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((language) => {
        this.selfaiTranslateService.setLanguage(language);
      });
  }
}
