import { Component, ElementRef, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import { FormatOptionConverter, TooltipOptionConverter } from '@selfai-platform/bi-chart-engine';
import { UIOption, UiChartDataLabelDisplayType } from '@selfai-platform/bi-domain';

import { LabelBaseOptionComponent } from './labelbase-option.component';

@Component({
  selector: 'tooltip-option',
  templateUrl: './tooltip-option.component.html',
})
export class TooltipOptionComponent extends LabelBaseOptionComponent {
  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = this.onUioptionChanged(uiOption);
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public toggleDisplayType(displayType: string, typeIndex: number): void {
    const displayTypes = this.uiOption.toolTip.displayTypes ? [...this.uiOption.toolTip.displayTypes] : [];

    let isFind = false;
    _.each(displayTypes, (type, index) => {
      if (_.eq(type, displayType)) {
        isFind = true;
        displayTypes[index] = null;
      }
    });

    if (!isFind) {
      displayTypes[typeIndex] = UiChartDataLabelDisplayType[displayType];
    }

    this.uiOption.toolTip.displayTypes = displayTypes;
    this.uiOption.toolTip.previewList = TooltipOptionConverter.setTooltipPreviewList(this.uiOption);

    this.apply();
  }

  public apply(): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { toolTip: this.uiOption.toolTip });
    this.update();
  }

  public changeUseDefaultFormat(): void {
    this.uiOption.toolTip.useDefaultFormat = !this.uiOption.toolTip.useDefaultFormat;
    this.apply();
  }

  private onUioptionChanged(uiOption: UIOption) {
    if (!uiOption.toolTip) {
      uiOption.toolTip = {};
    }

    if (!uiOption.toolTip.displayTypes && this.pivot) {
      uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type, this.pivot as any);
    }

    uiOption.toolTip.previewList = TooltipOptionConverter.setTooltipPreviewList(uiOption);

    if (typeof uiOption.toolTip.useDefaultFormat === 'undefined') uiOption.toolTip.useDefaultFormat = true;
    return uiOption;
  }
}
