import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'color-template',
  templateUrl: './color-template.component.html',
  styles: ['.sys-inverted {transform: scaleX(-1);}'],
})
export class ColorTemplateComponent extends AbstractComponent {
  @Input()
  public show: boolean;

  @Input()
  public colorType: string;

  @Input()
  public schema: string;

  @Input()
  public layerType: string;

  @Output()
  public notiChangeColor = new EventEmitter();

  public defaultColorList: Object[] = [
    { index: 1, colorNum: 'SC1' },
    { index: 2, colorNum: 'SC2' },
    { index: 3, colorNum: 'SC3' },
    { index: 4, colorNum: 'SC4' },
    { index: 5, colorNum: 'SC5' },
    { index: 6, colorNum: 'SC6' },
    { index: 7, colorNum: 'SC7' },
    { index: 8, colorNum: 'SC8' },
    { index: 9, colorNum: 'SC9' },
  ];

  public measureColorList: Object[] = [
    { index: 1, colorNum: 'VC1' },
    { index: 2, colorNum: 'VC2' },
    { index: 3, colorNum: 'VC3' },
    { index: 4, colorNum: 'VC4' },
    { index: 5, colorNum: 'VC5' },
    { index: 6, colorNum: 'VC6' },
    { index: 7, colorNum: 'VC7' },
  ];

  public measureReverseColorList: Object[] = [
    { index: 8, colorNum: 'VC8' },
    { index: 9, colorNum: 'VC9' },
    { index: 10, colorNum: 'VC10' },
    { index: 11, colorNum: 'VC11' },
    { index: 12, colorNum: 'VC12' },
    { index: 13, colorNum: 'VC13' },
    { index: 14, colorNum: 'VC14' },
    { index: 15, colorNum: 'VC15' },
    { index: 16, colorNum: 'VC16' },
    { index: 17, colorNum: 'VC17' },
    { index: 18, colorNum: 'VC18' },
    { index: 19, colorNum: 'VC19' },
    { index: 20, colorNum: 'VC20' },
    { index: 21, colorNum: 'VC21' },
    { index: 22, colorNum: 'VC22' },
    { index: 23, colorNum: 'VC23' },
    { index: 24, colorNum: 'VC24' },
    { index: 25, colorNum: 'VC25' },
    { index: 26, colorNum: 'VC26' },
    { index: 27, colorNum: 'VC27' },
  ];

  public mapHeatmapColorList: Object[] = [
    { index: 1, colorNum: 'HC1' },
    { index: 2, colorNum: 'HC2' },
    { index: 3, colorNum: 'HC3' },
    { index: 4, colorNum: 'HC4' },
    { index: 5, colorNum: 'HC5' },
    { index: 6, colorNum: 'HC6' },
    { index: 7, colorNum: 'HC7' },
  ];

  public isTemplateColorInverted: boolean = undefined;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public changeColor(colorObj: Object) {
    const color = _.cloneDeep(colorObj);
    if ($('input#invertColor').is(':checked')) {
      color['colorNum'] = 'R' + color['colorNum'];
    }

    this.notiChangeColor.emit(color);
  }

  public invertColor() {
    event.stopPropagation();

    if ($('input#invertColor').is(':checked')) {
      this.isTemplateColorInverted = true;
    } else {
      this.isTemplateColorInverted = false;
    }

    let colorList: Object[] = [];

    colorList = colorList.concat(this.measureColorList);
    colorList = colorList.concat(this.measureReverseColorList);

    for (const item of colorList) {
      if (this.isChartColorSelected(item)) {
        this.changeColor(item);
      }
    }
  }

  public isChartColorInverted() {
    return this.schema.indexOf('R') === 0;
  }

  public isChartColorSelected(item) {
    return this.schema.endsWith(item['colorNum']);
  }
}
