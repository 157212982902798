<div class="ddp-wrap-layout-popup" *ngIf="isShow">
  <div class="ddp-box-popup ddp-box-popup-type1">
    <a href="javascript:" class="ddp-btn-close" (click)="close()"></a>

    <div class="ddp-pop-title">{{ modal.name }}</div>

    <div class="ddp-pop-detail" *ngIf="modal.description">
      {{ modal.description }}
      <br />
      {{ modal.subDescription }}
    </div>

    <div class="ddp-ui-buttons">
      <a href="javascript:" class="ddp-btn-type-popup" (click)="close()">{{ 'msg.comm.btn.cancl' | translate }}</a>

      <a *ngIf="!modal.btnName" href="javascript:" class="ddp-btn-type-popup ddp-bg-black" (click)="done()">
        {{ 'msg.comm.btn.modal.done' | translate }}
      </a>
      <a *ngIf="modal.btnName" href="javascript:" class="ddp-btn-type-popup ddp-bg-black" (click)="done()">
        {{ modal.btnName }}
      </a>
    </div>
  </div>
</div>
