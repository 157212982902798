import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { CubeListComponentModule } from '../cube-list/cube-list.module';
import { FavoriteCubeListComponentModule } from '../favorite-cube-list/favorite-cube-list.module';
import { OperationListComponentModule } from '../operation-list/operation-list.module';
import { CubeListSidebarComponent } from './cube-list-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    TabViewModule,
    TabMenuModule,
    FavoriteCubeListComponentModule,
    CubeListComponentModule,
    OperationListComponentModule,
  ],
  declarations: [CubeListSidebarComponent],
  exports: [CubeListSidebarComponent],
})
export class CubeListSidebarComponentModule {}
