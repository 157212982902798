import { Injectable } from '@angular/core';
import { PermissionService, PipelinePermission } from '@selfai-platform/shared';
import { map, Observable, of } from 'rxjs';
import { KeCurrentUserService } from '../../current-user';

@Injectable({
  providedIn: 'root',
})
export class DataSourcePermissionService {
  constructor(
    private readonly permissionService: PermissionService,
    private readonly keCurrentUserService: KeCurrentUserService,
  ) {}

  private getDatasourcePermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('datasources'));
  }

  private equalCurrentUser(userId: string): Observable<boolean> {
    return this.keCurrentUserService.getById().pipe(map((data) => data.externalId === userId));
  }

  hasCurrentPermission(permission: PipelinePermission): boolean {
    return this.getDatasourcePermissions().includes(permission);
  }

  canDeleteSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteOwn)),
    );
  }

  canUpdateSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateOwn)),
    );
  }

  canCreateSource(): boolean {
    return this.hasCurrentPermission(PipelinePermission.DatasourcesCreateAny);
  }
}
