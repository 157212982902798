import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@selfai-platform/shared';
import { ActionsForItemService, convertMessageToHtml, DataListItemAction } from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { catchError, combineLatest, map, Observable, take, throwError } from 'rxjs';
import { DataSourceListItem } from '../model';
import { DataSourceListDomainService } from './data-source-list-domain.service';
import { DataSourcePermissionService } from './data-source-permission.service';

@Injectable()
export class DataSourceActionsForItemService extends ActionsForItemService<DataSourceListItem> {
  constructor(
    private readonly dataSourceListDomainService: DataSourceListDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly dataSourcePermissionService: DataSourcePermissionService,
    private readonly alertService: AlertService,
  ) {
    super();
  }

  override getActionsForItem(dataSource: DataSourceListItem): Observable<DataListItemAction[]> {
    return combineLatest({
      canDelete: this.dataSourcePermissionService.canDeleteSource(dataSource.ownerId),
      tooltipTranslate: this.translate.get('data-source-list.actions.delete'),
    }).pipe(
      map(({ canDelete, tooltipTranslate }) => {
        if (canDelete) {
          return [
            {
              label: tooltipTranslate,
              icon: 'pi pi-trash',
              action: () => this.delete(dataSource),
            },
          ];
        }

        return [];
      }),
    );
  }
  private delete(dataSource: DataSourceListItem): void {
    // TODO: Move to the confirmation with key = dialog to data list view
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(this.translate.instant('data-source-list.actions.delete.confirmation')),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.dataSourceListDomainService
          .deleteDataSource(dataSource.id)
          .pipe(
            take(1),
            catchError((error) => {
              this.alertService.error(this.translate.instant('data-source-list.actions.delete.error'));
              return throwError(() => error);
            }),
          )
          .subscribe(() => {
            this.alertService.success(this.translate.instant('data-source-list.actions.delete.success'));
          });
      },
    });
  }
}
