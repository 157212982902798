import moment, { DurationInputArg2 } from 'moment';

import { TimeRelativeFilter, TimeRelativeTense, TimeUnit } from '@selfai-platform/bi-domain';

export function relativeFilterGetPreview(targetFilter: TimeRelativeFilter) {
  let strFormat = '';
  let strManipulateKey: DurationInputArg2 | null = null;
  switch (targetFilter.relTimeUnit) {
    case TimeUnit.YEAR:
      strFormat = 'YYYY';
      strManipulateKey = 'y';
      break;
    case TimeUnit.QUARTER:
      strFormat = 'YYYY [Q]Q';
      strManipulateKey = 'Q';
      break;
    case TimeUnit.MONTH:
      strFormat = 'YYYY-MM';
      strManipulateKey = 'M';
      break;
    case TimeUnit.WEEK:
      strFormat = 'YYYY-MM [W]ww';
      strManipulateKey = 'w';
      break;
    case TimeUnit.DAY:
      strFormat = 'YYYY-MM-DD';
      strManipulateKey = 'd';
      break;
    case TimeUnit.HOUR:
      strFormat = 'YYYY-MM-DD HH';
      strManipulateKey = 'h';
      break;
    case TimeUnit.MINUTE:
      strFormat = 'YYYY-MM-DD HH:mm';
      strManipulateKey = 'm';
      break;
    case TimeUnit.SECOND:
      strFormat = 'YYYY-MM-DD HH:mm:ss';
      strManipulateKey = 's';
      break;
  }

  const objDate = moment();
  let strPreview = '';
  switch (targetFilter.tense) {
    case TimeRelativeTense.PREVIOUS:
      objDate.subtract(targetFilter.value, <DurationInputArg2>strManipulateKey);
      strPreview = objDate.format(strFormat);
      strPreview = strPreview + ' ~ ' + moment().format(strFormat);
      break;
    case TimeRelativeTense.NEXT:
      objDate.add(targetFilter.value, <DurationInputArg2>strManipulateKey);
      strPreview = objDate.format(strFormat);
      strPreview = moment().format(strFormat) + ' ~ ' + strPreview;
      break;
    default:
      strPreview = objDate.format(strFormat);
      break;
  }

  return strPreview;
}
