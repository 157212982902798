<div class="layout-config" [ngClass]="{ 'layout-config-active': visible$ | async }">
  <button class="layout-config-button" (click)="visible$.next(!visible$.value)">
    <i class="pi pi-cog"></i>
  </button>
  <div class="layout-config-content">
    <button
      pButton
      icon="pi pi-times"
      type="button"
      appearance="icon"
      class="button-close p-button-text p-button-rounded p-button-link"
      pButton
      (click)="visible$.next(false)"
    ></button>

    <p-tabView>
      <p-tabPanel header="Workflows">
        <ng-template pTemplate="content">
          <selfai-platform-cube-list></selfai-platform-cube-list>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Add cube">
        <ng-template pTemplate="content">
          <selfai-platform-operation-list></selfai-platform-operation-list>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel header="Favorites">
        <ng-template pTemplate="content">
          <selfai-platform-favorite-cube-list></selfai-platform-favorite-cube-list>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>
