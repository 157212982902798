import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreeNode } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { provideDialogService } from '@selfai-platform/shell';
import { takeUntil } from 'rxjs';
import { JsonPathService } from '../../../../json-path/services/json-path.service';
import { AggregatorCamelTreeService } from '../../../services/aggregator-camel-tree.service';

@Component({
  selector: 'selfai-platform-spel-value-field',
  templateUrl: './spel-value-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpelValueFieldComponent),
      multi: true,
    },
    DestroyService,
    ...provideDialogService(AggregatorCamelTreeService),
  ],
})
export class SpelValueFieldComponent implements ControlValueAccessor {
  value = '';
  disabled = false;

  @Input() label!: string;
  @Input() placeholder = 'Type expression or add jsonPath';

  constructor(
    private readonly aggregatorCamelService: AggregatorCamelTreeService,
    private readonly destroy$: DestroyService,
    private readonly jsonPathService: JsonPathService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  private onChange!: (_: string) => void;
  private onTouched!: () => void;

  writeValue(value: string): void {
    this.value = value || '';
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  inputOnChange(value: string): void {
    this.updateValue(value);
  }

  showJsonTreeSchemaView(): void {
    this.aggregatorCamelService
      .showJsonTreeSchemaView()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (item?: TreeNode) => {
          if (item) {
            this.updateValue((this.value || '') + this.jsonPathService.getJsonPath(item.path));
          }
        },
      });
  }

  private updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
    this.onTouched();
    this.cdr.markForCheck();
  }
}
