import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserFunctionsPermissionService } from '../services';
import { Observable, of } from 'rxjs';

@Injectable()
export class UserFunctionManagementGuard implements CanActivate {
  constructor(
    private readonly userFunctionPermissionService: UserFunctionsPermissionService,
    private readonly router: Router,
  ) {}

  public canActivate(): Observable<boolean> {
    const canActivate = this.userFunctionPermissionService.canCreateUserFunction();

    if (!canActivate) {
      this.router.navigate(['access-denied']).then();
    }

    return of(canActivate);
  }
}
