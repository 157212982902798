import { CreateDashboardApiModel, UpdateDashboardApiModel } from '@selfai-platform/bi-api';
import { omit } from 'lodash';
import { Dashboard, createBoardGlobalOptions } from '../models';

export function normalizeUpdateDashboardBody(
  dashboard: Partial<Dashboard> & Pick<Dashboard, 'configuration'>,
): UpdateDashboardApiModel {
  const workBook = dashboard.workBook ? `/api/workbooks/${dashboard.workBook.id}` : undefined;

  return {
    ...omit(dashboard, ['_links']),
    workBook,
    dataSources: dashboard.dataSources?.map(({ id }) => id),
    widgets: dashboard.widgets?.map(({ id }) => id),
  } as any;
}

export function normalizeAddDashboardBody({
  workbookId,
  dashboard,
}: {
  workbookId: string;
  dashboard: Dashboard;
}): CreateDashboardApiModel {
  return {
    ...omit(dashboard, [
      'createdBy',
      'createdTime',
      'modifiedBy',
      'modifiedTime',
      'id',
      'dataSource',
      'widgets',
      'dataSources',
      '_links',
    ]),
    workBook: `/api/workbooks/${workbookId}`,
    configuration: {
      ...createBoardGlobalOptions(),
      ...dashboard.configuration,
    } as any,
    widgets: dashboard.widgets?.map(({ id }) => `/api/widgets/${id}`),
    dataSources: dashboard.dataSources?.map(({ id }) => `/api/datasources/${id}`),
  };
}
