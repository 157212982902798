import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AggregatorRoute } from '@selfai-platform/pipeline-common';
import { Observable, of } from 'rxjs';
import { AggregatorDictionariesService } from '../../../services/aggregator-dictionaries.service';

type Item = Pick<AggregatorRoute, 'name' | 'uuid'>;

@Component({
  selector: 'selfai-platform-step-output',
  templateUrl: './step-output.component.html',
  styleUrls: ['./step-output.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepOutputComponent implements OnInit {
  @Input()
  form!: UntypedFormGroup;
  @Input() activeItemId!: string;

  connectorTypes$: Observable<string[]> = of([]);

  constructor(private readonly aggregatorDictionariesService: AggregatorDictionariesService) {}

  ngOnInit(): void {
    this.connectorTypes$ = this.aggregatorDictionariesService.getDictionary('outputConnectorType');
  }
}
