import {
  Dashboard,
  DatasourceField as Field,
  Datasource,
  FieldNameAlias,
  FieldValueAlias,
} from '@selfai-platform/bi-domain';

export function setFieldAttribute(
  fieldItem: Field,
  masterDsInfo: Datasource,
  dsInfo: Datasource,
  boardInfo?: Dashboard,
  nameAliasList?: FieldNameAlias[],
  valueAliasList?: FieldValueAlias[],
): Field {
  fieldItem.granularity = dsInfo.granularity ? dsInfo.granularity : dsInfo.segGranularity;
  fieldItem.segGranularity = dsInfo.segGranularity ? dsInfo.segGranularity : dsInfo.granularity;
  fieldItem.dataSource = masterDsInfo.engineName;
  fieldItem.dsId = dsInfo.id;
  if (boardInfo) {
    fieldItem.boardId = boardInfo.id;
    fieldItem.nameAlias = nameAliasList.find((alias) => alias.fieldName === fieldItem.name);
    fieldItem.valueAlias = valueAliasList.find((alias) => alias.fieldName === fieldItem.name);
  }

  if (dsInfo.uiMetaData && dsInfo.uiMetaData.columns && 0 < dsInfo.uiMetaData.columns.length) {
    const logicalInfo = dsInfo.uiMetaData.columns.find((logical) => fieldItem.name === logical.physicalName);
    logicalInfo && (fieldItem.uiMetaData = logicalInfo);
  }

  return fieldItem;
}
