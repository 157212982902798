<p-panel header="User function">
  <div *ngIf="userFunctionForm" class="user-function-form" [formGroup]="userFunctionForm">
    <div class="grid">
      <div class="col">
        <div class="mb-2 flex align-items-center">
          <label for="name" class="mr-2 block w-2">Name</label>
          <input id="name" class="w-8" pInputText formControlName="name" [disabled]="true">
        </div>

        <div class="mb-2 flex align-items-center">
          <label for="scopes" class="mr-2 block w-2">Scopes</label>
          <p-treeSelect
            id="scopes"
            class="w-8"
            containerStyleClass="w-full"
            formControlName="scopes"
            display="chip"
            selectionMode="checkbox"
            placeholder="Select Item"
            [showClear]="true"
            [metaKeySelection]="false"
            [filter]="true"
            [filterInputAutoFocus]="true"
            [options]="scopeList"
          />
        </div>
      </div>

      <div class="col">
        <div class="mb-2 flex align-items-center">
          <label for="functionType" class="mr-2 block w-2">Function type</label>
          <p-dropdown
            id="functionType"
            class="function-type w-10"
            formControlName="functionType"
            optionLabel="name"
            optionValue="value"
            placeholder="Select function type"
            [options]="functionTypes"
          ></p-dropdown>
        </div>

        <div class="mb-2 flex align-items-center">
          <label for="description" class="mr-2 block w-2">Description</label>
          <textarea
            id="description"
            class="w-10"
            pInputTextarea
            formControlName="description"
            [rows]="5"
            [autoResize]="true"
          ></textarea>
        </div>

        <div class="mb-3 flex align-items-center">
          <label for="tags" class="mr-2 block w-2">Tags</label>
          <p-autoComplete
            id="tags"
            class="w-10"
            formControlName="tags"
            optionLabel="name"
            variant="filled"
            [multiple]="true"
            [suggestions]="selectedTags"
            (completeMethod)="complete($event)"
            (keydown.enter)="addTag($event)"
          />
        </div>
      </div>
    </div>

    <div class="mb-3">
      <selfai-platform-code-editor
        formControlName="body"
        [language]="codeLanguage.SCALA"
      ></selfai-platform-code-editor>
    </div>

    <div class="flex">
      <p-button
        class="mr-2"
        label="Back"
        severity="secondary"
        [outlined]="true"
        (click)="onBackEvent()"
      ></p-button>

      <p-button
        label="Save"
        severity="success"
        [disabled]="userFunctionForm.invalid"
        (click)="onSubmitEvent()"
      ></p-button>
    </div>
  </div>
</p-panel>

