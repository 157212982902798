import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'trend-line-component',
  templateUrl: './trend.line.component.html',
})
export class TrendLineComponent implements OnInit {
  @Input()
  public dataSubLayerKey = '';

  constructor() {}

  ngOnInit() {}

  public clickDataSubPanel(dataSubLayerKey: string, event?: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (JSON.stringify(this.dataSubLayerKey) === JSON.stringify(dataSubLayerKey)) {
      this.dataSubLayerKey = '';
    } else {
      this.dataSubLayerKey = dataSubLayerKey;
    }
  }
}
