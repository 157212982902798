import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemUsedInNodes } from '@selfai-platform/pipeline-common';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor/services/workflow-editor-facade.service';

@Component({
  selector: 'selfai-platform-used-in-panel',
  templateUrl: './used-in-panel.component.html',
  styleUrls: ['./used-in-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ButtonModule, TooltipModule, OverlayPanelModule],
  standalone: true,
})
export class UsedInPanelComponent {
  @Input()
  usedInNodes: ItemUsedInNodes[] | undefined = undefined;

  constructor(private readonly workflowEditorService: WorkflowEditorFacadeService) {}

  navigateToNode(node: ItemUsedInNodes): void {
    this.workflowEditorService.openNodeModaldialogUnselectedNode(node.id);
  }
}
