<form class="pt-4" [formGroup]="form">
  <ng-container>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.brokers" />
      <label>Brokers</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.topic" />
      <label>Topic</label>
    </div>
    <div class="mb-5">
      <label class="cursor-pointer">
        <p-checkbox class="mr-2" [formControl]="paramsForm.controls.sslEnabled" [binary]="true"></p-checkbox>
        SSL Enabled
      </label>
    </div>

    <ng-container *ngIf="this.paramsForm.controls.sslEnabled.value">
      <div class="p-float-label mb-5">
        <p-password
          styleClass="w-full"
          inputStyleClass="w-full"
          [formControl]="paramsForm.controls.sslKeystoreLocation"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
        <label>SSL Keystore Location</label>
      </div>
      <div class="p-float-label mb-5">
        <p-password
          styleClass="w-full"
          inputStyleClass="w-full"
          [formControl]="paramsForm.controls.sslKeystorePassword"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
        <label>SSL Keystore Password</label>
      </div>
      <div class="p-float-label mb-5">
        <p-password
          styleClass="w-full"
          inputStyleClass="w-full"
          [formControl]="paramsForm.controls.sslTruststoreLocation"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
        <label>SSL Truststore Location</label>
      </div>
      <div class="p-float-label mb-5">
        <p-password
          styleClass="w-full"
          inputStyleClass="w-full"
          [formControl]="paramsForm.controls.sslTruststorePassword"
          [toggleMask]="true"
          [feedback]="false"
        ></p-password>
        <label>SSL Truststore Password</label>
      </div>
    </ng-container>
  </ng-container>

  <div class="mb-5">
    <selfai-platform-data-source-visibility
      [control]="form.controls.visibility"
    ></selfai-platform-data-source-visibility>
  </div>

  <div class="mb-5">
    <selfai-platform-data-source-unique-name [control]="form.controls.name"></selfai-platform-data-source-unique-name>
  </div>

  <selfai-platform-data-sources-additionals
    [properties]="dataSource?.params?.properties"
    [additionalList]="additionalList"
    (changeEvent)="onPropertiesValue($event)"
  ></selfai-platform-data-sources-additionals>
</form>
