import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { UiSettingsEntity, UiSettingsState } from '../../models';
import { UiSettingsActions } from '../actions';

export const uiSettingsEntityAdapter: EntityAdapter<UiSettingsEntity> = createEntityAdapter<UiSettingsEntity>({
  selectId: (entity) => entity.widgetId,
});

export const initialState: UiSettingsState = uiSettingsEntityAdapter.getInitialState({});

export const settingsReducer = createReducer(
  initialState,

  on(UiSettingsActions.loadUiSettings, (state, { widgetId, settings }) => {
    return uiSettingsEntityAdapter.setOne({ widgetId, settings }, state);
  }),

  on(UiSettingsActions.setUiSettings, (state, { widgetId, settings }) => {
    return uiSettingsEntityAdapter.setOne({ widgetId, settings }, state);
  }),

  on(UiSettingsActions.clearUiSettings, (state, { widgetId }) => {
    return uiSettingsEntityAdapter.removeOne(widgetId, { ...state });
  }),
);

const { selectIds, selectEntities, selectAll } = uiSettingsEntityAdapter.getSelectors();

export const selectWidgetIds = selectIds;

export const selectUiSettingsEntities = selectEntities;

export const selectAllWidgetsUiSettings = selectAll;
