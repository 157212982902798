import { DashobardFieldType } from '../../enums/field-type.enum';
import { DashboardField, createDashboardField } from './field.model';

export interface DimensionField extends DashboardField {
  granularity?: any;
  timeExprUnit?: any;
  timeZone?: string;
  locale?: string;
}

export function createDimensionField(params: Partial<DimensionField> = {}): DimensionField {
  return {
    ...createDashboardField(),
    type: DashobardFieldType.DIMENSION,
    ...params,
  };
}
