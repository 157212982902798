<ng-container *ngTemplateOutlet="rangeTemplate; context: { colorRange: colorRange }"></ng-container>

<ng-template #rangeTemplate let-colorRange="colorRange">
  <div *ngIf="isShowAdd" class="ddp-list-blank" (click)="addColorRange.emit(true)">
    {{ 'msg.page.chart.color.measure.new.range.add.description' | translate }}
  </div>
  <form [formGroup]="form">
    <div class="color-range">
      <div class="box-color">
        <color-picker
          [showAlpha]="true"
          [color]="colorRange.color"
          [showInitial]="true"
          [showInput]="true"
          [showUserColor]="true"
          #colorPicker
          (selected)="paletteSelected($event)"
        ></color-picker>
      </div>
      <div class="color-range-input">
        <ng-container
          *ngTemplateOutlet="
            inputRangeTemplate;
            context: {
              isFix: colorRange.fixMin,
              controlName: 'from'
            }
          "
        ></ng-container>
        <div class="ddp-bar">~</div>
        <ng-container
          *ngTemplateOutlet="
            inputRangeTemplate;
            context: {
              isFix: colorRange.fixMax,
              controlName: 'to'
            }
          "
        ></ng-container>
      </div>
      <em *ngIf="isShowRemove" class="ddp-icon-listdelete" (click)="removeRange()"></em>
    </div>
  </form>
</ng-template>

<ng-template #inputRangeTemplate let-controlName="controlName" let-isFix="isFix">
  <div class="block-input-range" [style.visibility]="isFix ? 'visible' : 'hidden'">
    <input type="number" [formControl]="form.get(controlName)" (blur)="changeControl(controlName)" />
  </div>
</ng-template>
