import { cloneDeep } from 'lodash';
import { PredictionAnalysis } from '../../chart';
import { BoardDataSource } from '../../dashboard';
import { DashboardField, Filter, Limit, Pivot, Shelf, UserDefinedField } from '../../workbook';

export interface SearchQueryRequest {
  dataSource?: any;
  filters?: Filter[];
  selectionFilters?: Filter[];
  pivot?: Pivot;
  shelf?: Shelf;
  projections?: DashboardField[];
  userFields?: UserDefinedField[];
  limits?: Limit;
  resultFormat?: {
    type: 'chart' | 'graph';
    // type of chart
    mode?: string;
    options?: {
      addMinMax?: boolean;
      isOriginal?: boolean;
      isCumulative?: boolean;
      showPercentage?: boolean;
      showCategory?: boolean;
    };
    columnDelimeter?: string;
    mergeNode?: boolean;
    useLinkCount?: boolean;
  };
  resultForward?: any;
  analysis?: PredictionAnalysis;
  context?: any;
  aliases?: SearchQueryAlias[];
}

export interface SearchQueryPivotOptionsV2 {
  includeAvg?: boolean;
  percentileRanks?: number[];
}

export function getDownloadFilters(searchQueryRequest: SearchQueryRequest): Filter[] {
  let downloadFilters: Filter[];
  if (searchQueryRequest.filters) {
    downloadFilters = cloneDeep(searchQueryRequest.filters);
  } else {
    downloadFilters = [];
  }

  return searchQueryRequest.selectionFilters
    ? downloadFilters.concat(searchQueryRequest.selectionFilters)
    : downloadFilters;
}

export function createSearchQueryRequest(params: Partial<SearchQueryRequest> = {}): SearchQueryRequest {
  return {
    ...params,
  };
}

export interface SearchQueryAlias {
  type?: string;
  codes?: any;
  ref?: string;
}

export interface MapDataSource {
  type: string;
  dataSources: BoardDataSource[];
}
