import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { UsedInPanelComponent } from '../common';
import { PipelineVariablesEditorComponent, VariablesTableComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    TableModule,
    TooltipModule,
    InputTextareaModule,
    InputTextModule,
    UsedInPanelComponent,
  ],
  declarations: [PipelineVariablesEditorComponent, VariablesTableComponent],
  exports: [PipelineVariablesEditorComponent],
})
export class PipelineVariablesEditorModule {}
