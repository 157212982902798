import { ActionReducerMap } from '@ngrx/store';
import { UiSettingsState } from '../../models';

import { settingsReducer } from './ui-settings.reducer';

export interface BiEngineState {
  uiSettings: UiSettingsState;
}

export const reducers: ActionReducerMap<BiEngineState> = {
  uiSettings: settingsReducer,
};
