import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DestroyService, LocalStorageKey } from '@selfai-platform/shared';
import { BehaviorSubject, combineLatest, filter, fromEvent, takeUntil } from 'rxjs';
import { MenuMode } from './menu-mode.enum';

interface MenuStorageData {
  opened: boolean;
  menuMode: MenuMode;
}

@Injectable({ providedIn: 'root' })
export class MenuService {
  private opened$ = new BehaviorSubject<boolean>(false);
  private menuModeSubject$ = new BehaviorSubject<MenuMode>(MenuMode.VERTICAL);
  private activeMenuItemSubject$ = new BehaviorSubject<HTMLElement | null>(null);

  isOpened$ = this.opened$.asObservable();
  menuMode$ = this.menuModeSubject$.asObservable();
  activeMenuItem$ = this.activeMenuItemSubject$.asObservable();

  constructor(private readonly router: Router, private readonly destroy$: DestroyService) {
    this.handleRouteChange();
    this.handleOutsideClick();
    this.initFromStorage();
  }

  handleOutsideClick() {
    fromEvent(document.body, 'click')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        const target = e.target as HTMLElement;
        if (!this.isSameElement(target)) {
          this.clearActiveMenuItem();
        }
      });
  }

  toggleMenu(force?: boolean): void {
    if (typeof force !== 'undefined') {
      this.opened$.next(force);
    } else {
      this.opened$.next(!this.opened$.value);
    }
  }

  setMenuMode(menuMode: MenuMode): void {
    this.menuModeSubject$.next(menuMode);
  }

  setActiveMenuItem(activeTopbarItem: HTMLElement): void {
    this.activeMenuItemSubject$.next(activeTopbarItem);
  }

  clearActiveMenuItem(): void {
    this.activeMenuItemSubject$.next(null);
  }

  private handleRouteChange(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.clearActiveMenuItem();
      });
  }

  private isSameElement(target: HTMLElement): boolean {
    if (!this.activeMenuItemSubject$.value) {
      return true;
    }

    return this.activeMenuItemSubject$.value?.contains(target) || target === this.activeMenuItemSubject$.value;
  }

  private initFromStorage(): void {
    const data = this.getFromLocalStorage();
    if (data) {
      const { menuMode, opened }: MenuStorageData = data;
      this.toggleMenu(opened);
      this.setMenuMode(menuMode);
    }

    this.syncStorage();
  }

  private syncStorage(): void {
    combineLatest([this.isOpened$, this.menuMode$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([opened, menuMode]) => {
        this.setToLocalStorage({ menuMode, opened });
      });
  }

  private getFromLocalStorage(): MenuStorageData | undefined {
    try {
      const dataString = window.localStorage.getItem(LocalStorageKey.MENU_STATE);
      if (dataString) {
        return JSON.parse(dataString);
      }
      // if crashes when parsed json string to count the storage is empty
      // eslint-disable-next-line no-empty
    } catch {}

    return undefined;
  }

  private setToLocalStorage(data: MenuStorageData): void {
    window.localStorage.setItem(LocalStorageKey.MENU_STATE, JSON.stringify(data));
  }
}
