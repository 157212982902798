<div class="layout-content">
  <!-- <h1 class="text-center">Welcome!</h1> -->

  <div class="grid align-items-stretch">
    <ng-container *ngFor="let config of widgetConfigs">
      <selfai-platform-home-page-base-widget
        class="block col md:col-4"
        [config]="config"
      ></selfai-platform-home-page-base-widget>
    </ng-container>
  </div>
</div>
