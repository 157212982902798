import { FactoryProvider, Injector, ValueProvider } from '@angular/core';
import { PipelineFeatureEnableService, PipelineFeatureName } from '@selfai-platform/shared';
import { provideMainMenuChunkFactory } from '@selfai-platform/shell';

export function provideAggregationMainMenuChunk(): FactoryProvider | ValueProvider {
  return provideMainMenuChunkFactory((injector: Injector) => {
    const featureEnableService = injector.get(PipelineFeatureEnableService);

    if (!featureEnableService.isEnabledFeature(PipelineFeatureName.AGGREGATION)) {
      return null;
    }

    return {
      id: 'engine',
      label: 'shell.menu.knowledge-engine',
      state: { sort: 300 },
      items: [
        {
          label: 'shell.menu.aggregation',
          icon: 'pi pi-fw pi-calculator',
          routerLink: '/aggregators',
        },
      ],
    };
  });
}
