import { ChartType } from '../../../chart';
import { PageWidgetConfiguration } from '../../../widget';
import { Dashboard } from '../../models';
import { shelfMigration } from './shelf-migration';

export function migrationFromOldSpec(boardInfo: Dashboard) {
  boardInfo.widgets?.forEach((widget) => {
    const widgetConfiguration = widget.configuration as PageWidgetConfiguration;
    if ('page' === widget.type && ChartType.MAP === widgetConfiguration.chart?.type) {
      shelfMigration(widgetConfiguration, boardInfo);
    }
  });

  return boardInfo;
}
