<div class="p-inputgroup">
  <input
    type="text"
    class="w-full"
    [ngClass]="{ 'ng-invalid': invalid, 'ng-valid': !invalid, 'ng-touched': true, 'ng-untouched': false }"
    pInputText
    [disabled]="disabled"
    [placeholder]="label || 'jsonPath for attribute'"
    [ngModel]="value"
    (ngModelChange)="onChange($event)"
  />
  <button
    pTooltip="Choose from json tree"
    icon="pi pi-sitemap"
    pButton
    pRipple
    type="button"
    (click)="showJsonTreeSchemaView()"
  ></button>
</div>
