import { Injectable } from '@angular/core';
import {
  Dashboard,
  DashboardDomainService,
  Filter,
  FilterCollectionServiceBase,
  GlobalActiveFiltersService,
  PageWidget,
  WidgetDomainService,
} from '@selfai-platform/bi-domain';
import { Observable, combineLatest, filter, switchMap } from 'rxjs';
import { WidgetFiltersStore } from '../../stores';
import { FilterSelectionService } from './filter-selection.service';
import { WidgetFilterLegacyService } from './widget-filter-legacy.service';

@Injectable({
  providedIn: 'root',
})
export class WidgetActiveFiltersService extends FilterCollectionServiceBase<Filter> {
  constructor(
    private readonly globalActiveFiltersService: GlobalActiveFiltersService,
    private readonly widgetFilterLegacyService: WidgetFilterLegacyService,
    private readonly filterSelectionService: FilterSelectionService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly widgetDomainService: WidgetDomainService,
    store: WidgetFiltersStore,
  ) {
    super(store);
  }

  getEffectiveFilters(widgetId: string, contextId?: string): Observable<Filter[]> {
    return this.dashboardDomainService.getDashboardByWidgetId(widgetId).pipe(
      filter(Boolean),
      switchMap((dashboard) => {
        return combineLatest({
          globalFilters: this.globalActiveFiltersService.getFilters(dashboard.id, contextId),
          widgetFilters: this.getWidgetFilters(widgetId, contextId),
          selections: this.filterSelectionService.getSelection(dashboard.id, contextId),
          widget: this.widgetDomainService.getPageWidget(widgetId, contextId),
        }).pipe(
          switchMap(({ globalFilters, widgetFilters, selections, widget }) => {
            const selectionFilters: Filter[] = selections
              .filter((selection) => selection.widgetId !== widgetId)
              .map(this.filterSelectionService.mapSelectionToApiFilter.bind(this));

            return this.mergeFilters(dashboard, widget, globalFilters, widgetFilters, selectionFilters);
          }),
        );
      }),
    );
  }

  toggleWidgetFilter(widgetId: string, filter: Filter, contextId?: string): void {
    super.toggleValue(widgetId, filter, contextId);
  }

  getWidgetFilters(widgetId: string, contextId?: string): Observable<Filter[]> {
    return super.getFields(widgetId, contextId);
  }

  resetWidgetAllFilters(widgetId: string, contextId?: string): void {
    super.resetAllFields(widgetId, contextId);
  }

  private mergeFilters(
    dashboard: Dashboard,
    widget: PageWidget,
    globalFilters: Filter[],
    chartFilters: Filter[],
    selectionFilters: Filter[],
  ): Observable<Filter[]> {
    return this.widgetFilterLegacyService.calculateSearchFilters(
      dashboard,
      widget,
      [...globalFilters, ...chartFilters],
      selectionFilters,
    );
  }
}
