<div class="ddp-ui-option-down ddp-lnb-down" [class.ddp-selected]="'prediction' === dataSubLayerKey">
  <div class="ddp-ui-down-title" (click)="clickDataSubPanel('prediction', $event)">
    <em class="ddp-icon-menumove"></em>

    {{ LABEL.SUB_MENU }}
  </div>

  <div class="ddp-ui-down-contents">
    <div class="ddp-ui-active ddp-disabled">
      <div class="ddp-wrap-option-slider">
        <span class="ddp-label-slider">{{ LABEL.ACTIVE }}</span>

        <div
          class="ddp-checkbox-slide ddp-checkbox-automatic2"
          [ngClass]="{ 'ddp-disabled': !data.isPredictionLineDisabled }"
        >
          <input
            type="checkbox"
            id="predictionCheckbox"
            name="predictionLineCheckBox"
            [checked]="data.isPredictionLineActive"
            (click)="predictionLineClick()"
          />
          <label for="predictionCheckbox"><span class="ddp-slide"></span></label>
        </div>
      </div>

      <div class="ddp-data-detail">
        {{ LABEL.ACTIVE_FIRST_DESCRIPTION }}
        <br />
        {{ LABEL.HYPHEN + ' ' + LABEL.ACTIVE_LAST_DESCRIPTION }}
      </div>
    </div>

    <div *ngIf="data.isPredictionLineActive" class="ddp-list-part">
      <div class="ddp-ui-label">
        {{ LABEL.ALGORITHM_NAME }}
      </div>

      <div class="ddp-list-part-sub">
        <div class="ddp-form-multy">
          <span class="ddp-txt-label">{{ LABEL.INTERVAL }}</span>

          <div class="ddp-box-multy ddp-clear">
            <component-input
              [value]="data.analysis.analysis.interval"
              [valueType]="'number'"
              [inputClass]="'ddp-input-typebasic'"
              (changeValue)="keyupEnterPredictionInterval($event)"
            ></component-input>
            <div class="ddp-type-selectbox ddp-non-select">
              <span class="ddp-txt-selectbox">{{ data.analysis.analysis.timeUnit }}</span>
            </div>
          </div>
        </div>

        <div class="ddp-ui-slider-type type-dark type-per">
          <span class="ddp-txt-label">{{ LABEL.SLIDER_TITLE }}</span>

          <range-slider-component
            #slider
            [hideFromTo]="predictionLineSliderHideFromTo"
            [hideMinMax]="predictionLineSliderHideMinMax"
            [keyboard]="predictionLineSliderKeyboard"
            [min]="predictionLineSliderMin"
            [max]="predictionLineSliderMax"
            [from]="predictionLineSliderFrom"
            [type]="predictionLineSliderType"
            [step]="predictionLineSliderStep"
            [grid]="predictionLineSliderGrid"
            (onFinish)="sliderCallBack(predictionLineSliderObject, $event)"
          ></range-slider-component>
        </div>

        <div class="ddp-ui-link ddp-setup" [ngClass]="{ 'ddp-selected': data.isAnalysisSettingsActive === false }">
          <a href="javascript:" class="ddp-link-option" (click)="changeForecastParametersAreaDisplayNoneBlockFlag()">
            {{ LABEL.ANALYSIS_SETTING }}
          </a>

          <div
            class="ddp-ui-option"
            [ngStyle]="{ display: data.isAnalysisSettingsActive === false ? 'block' : 'none' }"
          >
            <div class="ddp-ui-option-check">
              <div class="ddp-checkbox-slide ddp-checkbox-automatic3" [ngClass]="{ 'ddp-en': currentLang !== 'ko' }">
                <input
                  type="checkbox"
                  value="None"
                  id="check2"
                  name="check"
                  [checked]="selectedForecastParameter.isAuto"
                  (click)="selectedForecastCheckBoxFlag()"
                />
                <label for="check2"><span class="ddp-slide"></span></label>
              </div>
            </div>

            <component-select
              #selectComponent
              [array]="forecastParameters"
              [(defaultIndex)]="forecastParametersSelectedIndex"
              (onSelected)="onChangeForecastParameter($event)"
            ></component-select>

            <div class="ddp-wrap-option-checkbox ddp-clear">
              <div class="ddp-col-8">
                <label class="ddp-label-checkbox ddp-type3">
                  <input
                    type="checkbox"
                    [disabled]="selectedForecastParameter.isAlphaDisabled"
                    [checked]="selectedForecastParameter.isAlphaSelected"
                    [(ngModel)]="selectedForecastParameter.isAlphaSelected"
                  />
                  <i class="ddp-icon-checkbox"></i>

                  <span class="ddp-txt-checkbox">{{ LABEL.ALPHA }}</span>
                </label>
              </div>
              <div class="ddp-col-4">
                <component-input
                  [value]="selectedForecastParameter.alpha"
                  [valueType]="'number'"
                  [inputClass]="'ddp-input-typebasic ddp-text-right'"
                  [placeHolder]="'0.000'"
                  [disabled]="selectedForecastParameter.isAlphaDisabled || !selectedForecastParameter.isAlphaSelected"
                  [beforeChangeValue]="validateForecastParameterAlpha()"
                  (changeValue)="setForecastParameterAlpha($event)"
                ></component-input>
              </div>
            </div>

            <div class="ddp-wrap-option-checkbox ddp-clear">
              <div class="ddp-col-8">
                <label class="ddp-label-checkbox ddp-type3">
                  <input
                    type="checkbox"
                    [disabled]="selectedForecastParameter.isBetaDisabled"
                    [checked]="selectedForecastParameter.isBetaSelected"
                    [(ngModel)]="selectedForecastParameter.isBetaSelected"
                  />
                  <i class="ddp-icon-checkbox"></i>

                  <span class="ddp-txt-checkbox">{{ LABEL.BETA + '(' + LABEL.TREND + ')' }}</span>
                </label>
              </div>
              <div class="ddp-col-4">
                <component-input
                  [value]="selectedForecastParameter.beta"
                  [valueType]="'number'"
                  [inputClass]="'ddp-input-typebasic ddp-text-right'"
                  [placeHolder]="'0.000'"
                  [disabled]="selectedForecastParameter.isBetaDisabled || !selectedForecastParameter.isBetaSelected"
                  [beforeChangeValue]="validateForecastParameterBeta()"
                  (changeValue)="setForecastParameterBeta($event)"
                ></component-input>
              </div>
            </div>

            <div class="ddp-wrap-option-checkbox ddp-clear">
              <div class="ddp-col-8">
                <label class="ddp-label-checkbox ddp-type3">
                  <input
                    type="checkbox"
                    [disabled]="selectedForecastParameter.isGammaDisabled"
                    [checked]="selectedForecastParameter.isGammaSelected"
                    [(ngModel)]="selectedForecastParameter.isGammaSelected"
                    (change)="changeSelectedForecastParameterGamma()"
                  />
                  <i class="ddp-icon-checkbox"></i>

                  <span class="ddp-txt-checkbox">{{ LABEL.GAMMA + '(' + LABEL.SEASONAL + ')' }}</span>
                </label>
              </div>
              <div class="ddp-col-4">
                <component-input
                  [value]="selectedForecastParameter.gamma"
                  [valueType]="'number'"
                  [inputClass]="'ddp-input-typebasic ddp-text-right'"
                  [placeHolder]="'0.000'"
                  [disabled]="selectedForecastParameter.isGammaDisabled || !selectedForecastParameter.isGammaSelected"
                  [beforeChangeValue]="validateForecastParameterGamma()"
                  (changeValue)="setForecastParameterGamma($event)"
                ></component-input>
              </div>
            </div>

            <div class="ddp-wrap-option-checkbox ddp-clear">
              <div class="ddp-col-8">
                <label class="ddp-label-checkbox ddp-type3">
                  <input
                    type="checkbox"
                    [disabled]="selectedForecastParameter.isAuto"
                    [ngModel]="selectedForecastParameter.isPeriodSelected"
                    (change)="changeSelectedForecastParameterPeriod()"
                  />
                  <i class="ddp-icon-checkbox"></i>

                  <span class="ddp-txt-checkbox">period</span>
                </label>
              </div>
              <div class="ddp-col-4">
                <component-input
                  [value]="selectedForecastParameter.period"
                  [valueType]="'number'"
                  [inputClass]="'ddp-input-typebasic ddp-text-right'"
                  [placeHolder]="'0.000'"
                  [disabled]="selectedForecastParameter.isAuto || !selectedForecastParameter.isPeriodSelected"
                  (changeValue)="changeAdvancedPeriod($event)"
                ></component-input>
              </div>
            </div>
          </div>
        </div>

        sub in
        <div [ngStyle]="{ display: !data.isAnalysisSettingsActive ? 'block' : 'none' }" class="ddp-list-sub-in">
          <div *ngIf="selectedForecastParameter.isGammaDisabled" class="ddp-ui-check-type ddp-inline">
            <label class="ddp-label-radio ddp-dark">
              <input type="radio" name="gamma" disabled />
              <i class="ddp-icon-radio"></i>

              <span class="ddp-txt-radio">{{ LABEL.GAMMA_ADDITIVE }}</span>
            </label>
            <label class="ddp-label-radio ddp-dark">
              <input type="radio" name="gamma" disabled />
              <i class="ddp-icon-radio"></i>

              <span class="ddp-txt-radio">{{ LABEL.GAMMA_MULTIPLICATIVE }}</span>
            </label>
          </div>

          <div
            *ngIf="!selectedForecastParameter.isGammaDisabled && !selectedForecastParameter.isGammaSelected"
            class="ddp-ui-check-type ddp-inline"
          >
            <label class="ddp-label-radio ddp-dark">
              <input type="radio" name="gamma" disabled />
              <i class="ddp-icon-radio"></i>

              <span class="ddp-txt-radio">{{ LABEL.GAMMA_ADDITIVE }}</span>
            </label>
            <label class="ddp-label-radio ddp-dark">
              <input type="radio" name="gamma" disabled />
              <i class="ddp-icon-radio"></i>

              <span class="ddp-txt-radio">{{ LABEL.GAMMA_MULTIPLICATIVE }}</span>
            </label>
          </div>

          <div
            *ngIf="!selectedForecastParameter.isGammaDisabled && selectedForecastParameter.isGammaSelected"
            class="ddp-ui-check-type ddp-inline"
          >
            <label class="ddp-label-radio ddp-dark">
              <input
                type="radio"
                name="gamma"
                [checked]="!selectedForecastParameter.multiple"
                (click)="changeSelectedForecastParameterGammaInMultipleValue(false)"
              />
              <i class="ddp-icon-radio"></i>

              <span class="ddp-txt-radio">{{ LABEL.GAMMA_ADDITIVE }}</span>
            </label>
            <label class="ddp-label-radio ddp-dark">
              <input
                type="radio"
                name="gamma"
                [checked]="selectedForecastParameter.multiple"
                (click)="changeSelectedForecastParameterGammaInMultipleValue(true)"
              />
              <i class="ddp-icon-radio"></i>

              <span class="ddp-txt-radio">{{ LABEL.GAMMA_MULTIPLICATIVE }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="data.isPredictionLineActive" class="ddp-list-part">
      <div class="ddp-list-part-sub">
        <label class="ddp-label-checkbox ddp-type3">
          <input
            type="checkbox"
            [(ngModel)]="data.isSelectedForecast"
            [checked]="data.isSelectedForecast"
            [disabled]="data.isSelectedForecast && !data.isSelectedConfidence"
            (change)="changeUseForecast()"
          />
          <i class="ddp-icon-checkbox"></i>

          <span class="ddp-txt-checkbox">{{ LABEL.FORECAST }}</span>
        </label>

        <div class="ddp-list-sub-in">
          <div class="ddp-ui-color-select" [ngClass]="{ 'ddp-disabled': !data.isSelectedForecast }">
            <span
              class="ddp-box-color"
              [ngStyle]="{
                background:
                  data.analysis.analysis.forecast.style.color === '' ? '' : data.analysis.analysis.forecast.style.color
              }"
              #forecastColorPicker
              (click)="forecastColorPickerShow(forecastColorPicker, data.analysis.analysis.forecast.style.color)"
            ></span>

            <div class="ddp-size">
              <component-select
                #selectLineTypeComponent
                [ngStyle]="{ display: 'inline-block' }"
                [array]="LINE_TYPE_LIST"
                [defaultIndex]="selectLineTypeComponentDefaultIndex"
                (onSelected)="onChangeLineType($event)"
              ></component-select>
            </div>

            <component-select
              #selectLineComponent
              [ngStyle]="{ display: 'inline-block' }"
              [array]="LINE_WIDTH_LIST"
              [defaultIndex]="selectLineComponentDefaultIndex"
              (onSelected)="onChangeLineWidth($event)"
            ></component-select>
          </div>
        </div>

        <label *ngIf="data.isPredictionLineActive" class="ddp-label-checkbox ddp-type3">
          <input
            type="checkbox"
            [checked]="data.isSelectedConfidence"
            [(ngModel)]="data.isSelectedConfidence"
            [disabled]="data.isSelectedConfidence && !data.isSelectedForecast"
            (change)="changeUseConfidence()"
          />
          <i class="ddp-icon-checkbox"></i>

          <span class="ddp-txt-checkbox">{{ LABEL.CONFIDENCE }}</span>
        </label>

        <div class="ddp-list-sub-in">
          <div
            class="ddp-ui-color-select ddp-mgb0 ddp-clear"
            [ngClass]="{ 'ddp-disabled': !data.isSelectedConfidence }"
          >
            <div class="ddp-col-8">
              <div class="ddp-txt-label">
                <span
                  class="ddp-box-color"
                  #confidenceColorPicker
                  [ngStyle]="{
                    background:
                      data.analysis.analysis.confidence.style.color === ''
                        ? ''
                        : data.analysis.analysis.confidence.style.color
                  }"
                  (click)="
                    confidenceColorPickerShow(confidenceColorPicker, data.analysis.analysis.confidence.style.color)
                  "
                ></span>
                {{ LABEL.OPACITY }}
              </div>
            </div>
            <div class="ddp-col-4">
              <div class="ddp-box-option-input">
                <component-input
                  [value]="data.analysis.analysis.confidence.style.transparency"
                  [valueType]="'number'"
                  [beforeChangeValue]="validateConfidenceTransparency()"
                  (changeValue)="setConfidenceTransparency($event)"
                ></component-input>
                <span class="ddp-txt-input ddp-dark">{{ LABEL.PROCENT }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <color-picker-layer
    [offsetX]="colorPickerLayerOffsetX"
    [offsetY]="colorPickerLayerOffsetY"
    (selected)="colorPickerLayerSelected($event)"
  ></color-picker-layer>
</div>
