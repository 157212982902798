export enum AngularHookNames {
  NavigateTo = 'NavigateTo',
  GoToWorkflow = 'GoToWorkflow',
  ShowErrorWindow = 'ShowErrorWindow',
  ShowVariables = 'ShowVariables',
  ShowSettings = 'ShowSettings',
  CubeCreateTrigger = 'CubeCreateTrigger',
  CubeReadDataFrame = 'CubeReadDataFrame',
  CubeWriteDataFrameTransit = 'CubeWriteDataFrameTransit',
  CubeWriteDataFrame = 'CubeWriteDataFrame',
  CubeCreateDataFrame = 'CubeCreateDataFrame',
  CubeExternalSqlQuery = 'CubeExternalSqlQuery',
  CubeShowJsonToColumnTransformation = 'CubeShowJsonToColumnTransformation',
  CubeExpressionTool = 'CubeExpressionTool',
  CubeCodeEditorTransformation = 'CubeCodeEditorTransformation',
  CubeCmsFormCreator = 'CubeCmsFormCreator',
  CubeComment = 'CubeComment',
  AddToFavorite = 'AddToFavorite',
  ShowVersions = 'ShowVersions',
  AlertMessage = 'AlertMessage',
  ShowButtonEditor = 'ShowButtonEditor',
}
