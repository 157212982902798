/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Axis,
  AxisType,
  BarMarkType,
  BaseOption,
  CHART_STRING_DELIMITER,
  DataZoomType,
  Pivot,
  PositionLabel,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  UIChartAxis,
  UIChartAxisGrid,
  UIChartAxisLabelValue,
  UIOption,
} from '@selfai-platform/bi-domain';

import { DestroyService } from '@selfai-platform/shared';
import { BarSeriesOption } from 'echarts';
import { DataZoomComponentOption } from 'echarts/components';
import cloneDeep from 'lodash/cloneDeep';
import each from 'lodash/each';
import eq from 'lodash/eq';
import gt from 'lodash/gt';
import isUndefined from 'lodash/isUndefined';
import min from 'lodash/min';
import remove from 'lodash/remove';
import split from 'lodash/split';
import sum from 'lodash/sum';
import { BaseChart } from '..';
import { AxisOptionConverter } from '../../converters';
import { BarChartOptionsService, ChartOptionsService, provideBaseChartServices } from '../../services';
import { EChartService } from '../../services/echart.service';
import { OptionGenerator } from '../../utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'bar-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [
    ...provideBaseChartServices(),
    {
      provide: ChartOptionsService,
      useClass: BarChartOptionsService,
    },
  ],
})
export class BarChartComponent extends BaseChart implements OnInit, OnDestroy, AfterViewInit {
  private prevPivot: Pivot;

  @Output()
  histogramUpdate = new EventEmitter();

  constructor(
    private chartOptionsService: ChartOptionsService,
    elementRef: ElementRef<HTMLElement>,
    destroy$: DestroyService,
    eChartService: EChartService,
    injector: Injector,
  ) {
    super(elementRef, destroy$, eChartService, injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.chartOption = this.initOption();
  }

  override isValid(pivot: Pivot): boolean {
    return (
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) >
        0 &&
      (this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) > 0 ||
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) > 0) &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.CALCULATED) == 0
    );
  }

  override draw(isKeepRange?: boolean): void {
    this.setStackMinMaxValue();
    this.pivot = this.changeShelveData(this.pivot);
    this.pivot = this.moveDimensionFromAggregationsToRows(this.pivot);
    this.setFieldInfo();
    super.draw(isKeepRange);
    this.pivot = cloneDeep(this.originPivot);
    this.histogramUpdate.emit(this.chartOptionsService.getUiOptions());
  }

  override addChartDatazoomEventListener(): void {
    this.chart.off('datazoom');
    this.chart.on('datazoom', (param: any) => {
      (this.chartOption.dataZoom as DataZoomComponentOption[]).map((zoom, index) => {
        if (eq(zoom.type, DataZoomType.SLIDER)) {
          this.uiOption.chartZooms[index].start = param.start;
          this.uiOption.chartZooms[index].end = param.end;
        }
      });
    });
  }

  protected override initOption(): BaseOption {
    return this.chartOptionsService.initEChartOptions();
  }

  protected override convertSeriesData(): BaseOption {
    this.chartOption.series = this.data.columns.map((columnElement) => {
      const column = cloneDeep(columnElement);

      const dataList = [];
      for (let item of column.value) {
        if (item == 0) {
          item = null;
        }
        dataList.push(item);
      }
      column.value = dataList;

      return {
        type: SeriesType.BAR,
        name: column.name,
        data: column.value.map((val, idx) => {
          return {
            name: column.seriesName[idx],
            value: val,
            selected: false,
            itemStyle: OptionGenerator.ItemStyle.opacity1(),
          };
        }),
        uiData: column,
        originData: cloneDeep(column.value),
        itemStyle: OptionGenerator.ItemStyle.auto(),
        label: OptionGenerator.LabelStyle.defaultLabelStyle(false, PositionLabel.TOP),
      };
    });

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    return this.chartOption;
  }

  protected override additionalDataZoom(): BaseOption {
    if (
      (!isUndefined(this.uiOption.chartZooms) && !isUndefined(this.uiOption.size) && this.uiOption.limitCheck) ||
      (this.uiOption.chartZooms && isUndefined(this.uiOption.chartZooms[0].start))
    ) {
      this.convertDataZoomAutoRange(this.chartOption, 20, 500, 10, this.existTimeField);
    }

    return this.chartOption;
  }

  protected override convertDataZoomAutoRange(
    option: BaseOption,
    count: number,
    limit: number,
    percent: number,
    isTime: boolean,
    idx?: number,
  ): BaseOption {
    if (isUndefined(option.dataZoom)) return option;

    const series = option.series as BarSeriesOption[];

    const dataZoomIdx = isUndefined(idx) ? 0 : idx;

    let colCount = !isUndefined(option.xAxis[0].data) ? option.xAxis[0].data.length : option.yAxis[0].data.length;

    let startValue = 0;
    let endValue = count - 1;
    const isStackMode = eq(series[0].type, SeriesType.BAR) && !isUndefined(series[0].stack);
    const seriesLength = series.length;

    if (!isTime) {
      colCount = isStackMode ? colCount : colCount * seriesLength;
    }

    if (gt(colCount, limit)) {
      endValue = seriesLength >= 20 ? 0 : Math.floor(colCount * (percent / 100)) - 1;
    }

    endValue = Math.floor(endValue / seriesLength);

    endValue = eq(colCount, 1) ? 0 : eq(endValue, 0) ? 1 : endValue;

    if (isTime) {
      startValue = colCount - cloneDeep(endValue);
      endValue = colCount - 1;
    }

    option.dataZoom[dataZoomIdx].startValue = startValue;
    option.dataZoom[dataZoomIdx].endValue = endValue;
    delete option.dataZoom[dataZoomIdx].start;
    delete option.dataZoom[dataZoomIdx].end;

    (option.dataZoom as DataZoomComponentOption[]).map((obj) => {
      if (eq(obj.type, DataZoomType.INSIDE)) {
        obj.startValue = startValue;
        obj.endValue = endValue;
        delete obj.start;
        delete obj.end;
      }
    });

    return option;
  }

  protected override selection(): void {
    this.originPivot = cloneDeep(this.pivot);

    this.pivot = this.moveDimensionFromAggregationsToRows(this.pivot);

    this.addChartSelectEventListener();
    this.addChartMultiSelectEventListener();
    this.addLegendSelectEventListener();

    this.pivot = cloneDeep(this.originPivot);
  }

  protected override setDataLabel(): UIOption {
    const checkChangeSeries = (): boolean => {
      if (!this.prevPivot) return true;

      const prevSeriesMulti: boolean =
        this.prevPivot.aggregations.length > 1 || this.prevPivot.rows.length >= 1 ? true : false;

      const currentSeriesMulti: boolean =
        this.pivot.aggregations.length > 1 || this.pivot.rows.length >= 1 ? true : false;

      if (prevSeriesMulti !== currentSeriesMulti) {
        return true;
      }

      return false;
    };

    this.uiOption = this.setAxisDataLabel(this.prevPivot, checkChangeSeries());

    this.prevPivot = this.pivot;

    return this.uiOption;
  }

  protected override additionalYAxis(): BaseOption {
    this.convertAxisAutoScale(AxisType.Y);

    return this.chartOption;
  }

  protected override additionalXAxis(): BaseOption {
    this.convertAxisAutoScale(AxisType.X);

    return this.chartOption;
  }

  protected convertAxisAutoScale(axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = AxisOptionConverter.getAxisOption(this.uiOption, axisType);

    const axis: Axis[] = this.chartOption[axisType] as Axis[];

    each(axis, (option: Axis, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid
      ) {
        let min = null;
        let max = null;
        let calculateMin = null;
        if (this.isStacked() && this.originalData.categories && this.originalData.categories.length > 0) {
          each(this.originalData.categories, (category) => {
            each(category.value, (value) => {
              if (min == null || value < min) {
                min = value;
              }
              if (max == null || value > max) {
                max = value;
              }
            });
          });
          calculateMin = Math.ceil(min - (max - min) * 0.05);

          max = max == null ? 0 : max;
        } else {
          calculateMin = Math.ceil(
            this.originalData.info.minValue -
              (this.originalData.info.maxValue - this.originalData.info.minValue) * 0.05,
          );
          min = this.originalData.info.minValue;
          max = this.originalData.info.maxValue;
        }

        AxisOptionConverter.axisMinMax[axisType].min = min;
        AxisOptionConverter.axisMinMax[axisType].max = max;

        let baseline = 0;
        if (axisOption[index].baseline && axisOption[index].baseline != 0) {
          baseline = <number>axisOption[index].baseline;
        }

        if (baseline == 0 && axisOption[index].grid.autoScaled) {
          delete option.min;
          delete option.max;
          option.scale = true;
        } else {
          delete option.scale;
        }
      }
    });

    return this.chartOption;
  }

  protected override calculateMinMax(grid: UIChartAxisGrid, result: any, isYAsis: boolean): void {
    if (grid.autoScaled) {
      if (this.isStacked() && result.data.categories && result.data.categories.length > 0) {
        let min = null;
        let max = null;
        each(result.data.categories, (category) => {
          each(category.value, (value) => {
            if (min == null || value < min) {
              min = value;
            }
            if (max == null || value > max) {
              max = value;
            }
          });
        });
        grid.min = min > 0 ? Math.ceil(min - (max - min) * 0.05) : min;
        grid.max = max;
      } else {
        grid.min =
          result.data.info.minValue > 0
            ? Math.ceil(result.data.info.minValue - (result.data.info.maxValue - result.data.info.minValue) * 0.05)
            : result.data.info.minValue;
        grid.max = result.data.info.maxValue;
      }
    }

    if ((isUndefined(grid.min) || grid.min == 0) && (isUndefined(grid.max) || grid.max == 0)) {
      return;
    }

    const seriesList = [];
    result.data.columns.map((column, index) => {
      const nameArr = split(column.name, CHART_STRING_DELIMITER);
      let name = '';
      if (nameArr.length > 1) {
        nameArr.map((temp, index) => {
          if (index < nameArr.length - 1) {
            if (index > 0) {
              name += CHART_STRING_DELIMITER;
            }
            name += temp;
          }
        });
      } else {
        name = nameArr[0];
      }

      let isAlready = false;
      seriesList.map((series, index) => {
        if (series == name) {
          isAlready = true;

          return false;
        }
      });

      if (!isAlready) {
        seriesList.push(name);
      }
    });

    if (!this.isStacked() || !result.data.categories || result.data.categories.length == 0) {
      result.data.columns.map((column) => {
        column.value.map((value, index) => {
          if (value < grid.min) {
            column.value[index] = grid.min;
          } else if (value > grid.max) {
            column.value[index] = grid.max;
          }
        });
      });
    } else {
      each(result.data.categories, (category, categoryIndex) => {
        const totalValue = [];
        const seriesValue = [];
        result.data.columns.map((column, index) => {
          if (column.name.indexOf(category.name) == -1) {
            return true;
          }

          column.value.map((value, index) => {
            if (isUndefined(totalValue[index]) || isNaN(totalValue[index])) {
              totalValue[index] = 0;
              seriesValue[index] = 0;
            }

            if (totalValue[index] > grid.max) {
              column.value[index] = 0;
            } else if (totalValue[index] + value > grid.max) {
              if (seriesValue[index] <= 0) {
                column.value[index] = grid.max;
              } else {
                column.value[index] = <number>grid.max - totalValue[index];
              }
            } else if (totalValue[index] + value < grid.min) {
              column.value[index] = 0;
            } else if (totalValue[index] < grid.min && totalValue[index] + value > grid.min) {
              column.value[index] = totalValue[index] + value;
            } else {
              column.value[index] = value;
            }
            seriesValue[index] += column.value[index];
            totalValue[index] += value;
          });
        });

        each(totalValue, (value, valueIndex) => {
          if (value < grid.min) {
            result.data.columns.map((column, index) => {
              column.value.map((value, index) => {
                if (index == valueIndex) {
                  column.value[index] = 0;
                }
              });
            });
          }
        });
      });
    }
  }

  private changeShelveData(shelve: Pivot): Pivot {
    if (!this.uiOption['mark']) return shelve;

    if (shelve.rows && shelve.rows.length == 0) return shelve;

    if (BarMarkType.STACKED === this.uiOption['mark']) {
      return this.moveDimensionFromAggregationsToRows(shelve);
    }

    return this.moveDimensionFromRowsToAggregations(shelve);
  }

  private setStackMinMaxValue(): void {
    const series = cloneDeep(this.data.columns);
    const uiOption = cloneDeep(this.uiOption);

    delete uiOption.stackedMinValue;
    delete uiOption.stackedMaxvalue;

    if ((series && uiOption['mark'] == BarMarkType.STACKED && series.length > 1) || this.data.info.minValue < 0) {
      uiOption.stackedMinValue = 0;

      for (let num = series[0].value.length; num--; ) {
        let maxValue = 0;
        let minValue = 0;
        const minArray = [];

        for (const item of series) {
          if (item.value) {
            minArray.push(item.value[num]);
            maxValue += item.value[num];
          }
        }

        if (min(minArray) < 0) {
          minValue = sum(
            remove(minArray, (data) => {
              return data < 0;
            }),
          );
        } else {
          minValue = sum(minArray);
        }

        if (this.data.info.minValue < 0) {
          uiOption.stackedMinValue = uiOption.stackedMinValue > minValue ? minValue : uiOption.stackedMinValue;
        }

        uiOption.stackedMaxvalue =
          isUndefined(uiOption.stackedMaxvalue) || uiOption.stackedMaxvalue < maxValue
            ? maxValue
            : uiOption.stackedMaxvalue;
      }
    }

    this.chartOptionsService.setUiOptions(uiOption);
  }

  private moveDimensionFromAggregationsToRows(shelve: Pivot): Pivot {
    const clonedShelve = cloneDeep(shelve);
    for (let num = clonedShelve.aggregations.length; num--; ) {
      const item = clonedShelve.aggregations[num];

      if (ShelveFieldType.DIMENSION === item.type) {
        clonedShelve.aggregations.splice(num, 1);

        clonedShelve.rows.push(item);
      }
    }

    return clonedShelve;
  }

  private moveDimensionFromRowsToAggregations(shelve: Pivot): Pivot {
    const clonedShelve = cloneDeep(shelve);
    for (let num = clonedShelve.aggregations.length; num--; ) {
      const item = clonedShelve.aggregations[num];

      if (item.type === String(ShelveFieldType.DIMENSION)) {
        clonedShelve.rows.splice(num, 1);

        clonedShelve.aggregations.push(item);
      }
    }

    return clonedShelve;
  }

  private isStacked(): boolean {
    let stacked = false;

    this.originPivot.rows.forEach((item) => {
      if (item.type === String(ShelveFieldType.DIMENSION)) {
        stacked = true;
      }
    });

    return stacked;
  }
}
