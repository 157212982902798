/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component } from '@angular/core';

import * as _ from 'lodash';

import {
  Axis as AxisDefine,
  AxisType,
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartColorList,
  ChartColorType,
  ChartType,
  ColorCustomMode,
  DataZoomType,
  LineCornerType,
  LineStyleType,
  LineType,
  Pivot,
  Position,
  PositionLabel,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartAxis,
  UIChartAxisGrid,
  UIChartAxisLabelValue,
  UIChartColorBySeries,
  UILineChart,
  UIOption,
} from '@selfai-platform/bi-domain';
import { DataZoomComponentOption, LegendComponentOption, LineSeriesOption } from 'echarts';
import { predictionLineTypeAdditional } from '../../const';
import { AxisOptionConverter, ColorOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';
import { LineChartSplit } from './line-chart.split';

import optGen = OptionGenerator;
import Legend = OptionGenerator.Legend;
import Grid = OptionGenerator.Grid;
import Axis = OptionGenerator.Axis;
import DataZoom = OptionGenerator.DataZoom;
import Tooltip = OptionGenerator.Tooltip;
import Brush = OptionGenerator.Brush;
import Toolbox = OptionGenerator.Toolbox;

const transparentSymbolImage: string =
  'image://' + window.location.origin + '/assets/bi/images/icon_transparent_symbol.png';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'line-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class LineChartComponent extends BaseChart {
  private split: LineChartSplit = new LineChartSplit();

  private prevPivot!: Pivot;

  public override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) >
        0 &&
      (this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) > 0 ||
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) > 0) &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0
    );
  }

  public override draw(isKeepRange?: boolean): void {
    if (this.split.isSplit(this.uiOption)) {
      this.chartOption = this.split.setSplitData(this.data, this.pivot, this.uiOption, this.chartOption);

      this.apply(false);
    } else {
      this.setFieldInfo();

      this.pivot = this.editPivotByColorType();

      super.draw(isKeepRange);
    }
  }

  public predictionDraw(): void {
    this.draw(true);
  }

  public changeForecast(): void {
    this.chartOption = this.predictionLineLineStyleColorBySeries();
    this.draw(true);
  }

  public changeConfidence(): void {
    this.chartOption = this.predictionLineAreaStyleColorBySeries();
    this.draw(true);
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.LINE,
      grid: [Grid.verticalMode(10, 0, 0, 10, false, true, false)],
      xAxis: [Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
      yAxis: [Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      legend: Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20, 5),
      dataZoom: [DataZoom.horizontalDataZoom(), DataZoom.horizontalInsideDataZoom()],
      tooltip: Tooltip.itemTooltip(),
      toolbox: Toolbox.hiddenToolbox(),
      brush: Brush.selectBrush(),
      series: [],
    };
  }

  protected override convertSeriesData(): BaseOption {
    this.chartOption.series = this.data.columns.map((column) => {
      return {
        type: SeriesType.LINE,
        name: column.name,
        data: column.value.map((val, idx) => {
          return {
            name: column.seriesName ? column.seriesName[idx] : '',
            value: val,
            selected: false,
            itemStyle: optGen.ItemStyle.opacity1(),
          };
        }),
        originData: _.cloneDeep(column.value),
        connectNulls: true,
        showAllSymbol: true,
        symbol: SymbolType.CIRCLE,

        itemStyle: optGen.ItemStyle.auto(),
        label: optGen.LabelStyle.defaultLabelStyle(false, PositionLabel.TOP),
        uiData: column,
      };
    });

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertLineStyle();

    this.chartOption = this.convertCornerStyle();

    this.chartOption = this.convertColor();

    if (this.isAnalysisPredictionLineEmpty() === false) {
      const count = 20 + Number(this.analysis.interval);
      this.convertDataZoomAutoRange(this.chartOption, count, 500, 10, this.existTimeField);
    }

    return this.chartOption;
  }

  protected override additionalLegend(): BaseOption {
    if (this.uiOption.color.type == ChartColorType.DIMENSION) {
      (this.chartOption.legend as LegendComponentOption).data = (this.chartOption.series as LineSeriesOption[]).map(
        (item) => item.name as string,
      );
    }

    return this.chartOption;
  }

  public override addChartDatazoomEventListener(): void {
    this.chart.off('datazoom');
    this.chart.on('datazoom', (param: any) => {
      (this.chartOption.dataZoom as DataZoomComponentOption[]).map((zoom, index) => {
        if (_.eq(zoom.type, DataZoomType.SLIDER)) {
          this.uiOption.chartZooms[index].start = param.start;
          this.uiOption.chartZooms[index].end = param.end;
        }
      });
    });
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
    this.addChartMultiSelectEventListener();
    this.addLegendSelectEventListener();
  }

  protected override setDataLabel(): UIOption {
    const checkChangeSeries = (): boolean => {
      if (!this.prevPivot) return true;

      const prevSeriesMulti: boolean =
        this.prevPivot.aggregations.length > 1 || this.prevPivot.rows.length > 0 ? true : false;

      const currentSeriesMulti: boolean =
        this.pivot.aggregations.length > 1 || this.pivot.rows.length > 0 ? true : false;

      if (prevSeriesMulti !== currentSeriesMulti) {
        return true;
      }

      return false;
    };

    this.uiOption = this.setAxisDataLabel(this.prevPivot, checkChangeSeries());

    this.prevPivot = this.pivot;

    return this.uiOption;
  }

  protected override additionalYAxis(): BaseOption {
    this.convertAxisAutoScale(AxisType.Y);

    return this.chartOption;
  }

  protected override additionalXAxis(): BaseOption {
    this.convertAxisAutoScale(AxisType.X);

    return this.chartOption;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected override calculateMinMax(grid: UIChartAxisGrid, result: any, isYAsis: boolean): void {}

  protected override calculateBaseline(baseline: number, result: any, isYAsis: boolean): void {
    const seriesList = [];
    result.data.columns.map((column, index) => {
      const nameArr = _.split(column.name, CHART_STRING_DELIMITER);
      let name = '';
      if (nameArr.length > 1) {
        nameArr.map((temp, index) => {
          if (index < nameArr.length - 1) {
            if (index > 0) {
              name += CHART_STRING_DELIMITER;
            }
            name += temp;
          }
        });
      } else {
        name = nameArr[0];
      }

      let isAlready = false;
      seriesList.map((series, index) => {
        if (series == name) {
          isAlready = true;

          return false;
        }
      });

      if (!isAlready) {
        seriesList.push(name);
      }
    });

    result.data.columns.map((column, index) => {
      column.value.map((value, index) => {
        if (value > 0) {
          column.value[index] = value - baseline;
        } else {
          column.value[index] = (Math.abs(value) + Math.abs(baseline)) * -1;
        }
      });
    });
  }

  protected override selectSeriesData(seriesData: LineSeriesOption) {
    seriesData.itemStyle.opacity = 1;
    seriesData.symbolSize = 10;
    // seriesData.selected = true;
  }

  protected override unselectSeriesData(seriesData: LineSeriesOption) {
    seriesData.itemStyle.opacity = 0.2;
    seriesData.symbolSize = 4;
    // seriesData.selected = false;
  }

  protected override clearSelectSeriesData(seriesData: LineSeriesOption) {
    seriesData.itemStyle.opacity = 1;
    seriesData.symbolSize = 4;
    // seriesData.selectType = false;
  }

  private convertAxisAutoScale(axisType: AxisType): BaseOption {
    const axisOption: UIChartAxis[] = AxisOptionConverter.getAxisOption(this.uiOption, axisType);

    const axis: AxisDefine[] = this.chartOption[axisType] as AxisDefine[];

    _.each(axis, (option: AxisDefine, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        _.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid
      ) {
        let min = null;
        let max = null;
        let calculateMin = null;
        if (this.originalData.categories || this.originalData.categories.length > 0) {
          _.each(this.originalData.columns, (column) => {
            _.each(column.value, (value) => {
              if (min == null || value < min) {
                min = value;
              }
              if (max == null || value > max) {
                max = value;
              }
            });
          });
          calculateMin = Math.ceil(
            this.originalData.info.minValue -
              (this.originalData.info.maxValue - this.originalData.info.minValue) * 0.05,
          );
        } else {
          calculateMin = Math.ceil(
            this.originalData.info.minValue -
              (this.originalData.info.maxValue - this.originalData.info.minValue) * 0.05,
          );
          min = this.originalData.info.minValue;

          max = this.originalData.info.maxValue;
        }

        AxisOptionConverter.axisMinMax[axisType].min = min;
        AxisOptionConverter.axisMinMax[axisType].max = max;

        let baseline = 0;
        if (axisOption[index].baseline && axisOption[index].baseline != 0) {
          baseline = <number>axisOption[index].baseline;
        }

        if (baseline == 0 && axisOption[index].grid.autoScaled) {
          delete option.min;
          delete option.max;
          option.scale = true;
        } else {
          delete option.scale;
        }
      }
    });

    return this.chartOption;
  }

  private convertLineStyle(): BaseOption {
    const lineUIOption = <UILineChart>this.uiOption;

    if (this.isAnalysisPredictionLineEmpty()) {
      const series = this.chartOption.series as LineSeriesOption[];

      series.map((obj) => {
        const dataLength = obj.data.filter((value) => {
          return value != null;
        }).length;

        const transparentSymbolImage: string =
          'image://' + window.location.origin + '/assets/bi/images/icon_transparent_symbol.png';
        obj.symbol =
          obj.data.length === 1 || dataLength === 1 || LineStyleType.LINE !== lineUIOption.lineStyle
            ? 'circle'
            : transparentSymbolImage;

        if (_.isUndefined(obj.lineStyle)) obj.lineStyle = {};

        if (!_.isUndefined(obj.lineStyle) && !_.isUndefined(obj.lineStyle))
          obj.lineStyle.opacity = LineStyleType.POINT !== lineUIOption.lineStyle ? 1 : 0;
      });
    } else {
      this.chartOption = this.exceptPredictionLineShowLine(lineUIOption.lineStyle);
    }

    return this.chartOption;
  }

  private convertCornerStyle(): BaseOption {
    const lineUIOption = <UILineChart>this.uiOption;
    const series = this.chartOption.series as LineSeriesOption[];

    series.map((obj) => {
      obj.smooth = _.eq(lineUIOption.curveStyle, LineCornerType.SMOOTH);
    });

    return this.chartOption;
  }

  private editPivotByColorType(): Pivot {
    const shelve = this.pivot;

    for (let num = shelve.aggregations.length; num--; ) {
      const item = shelve.aggregations[num];

      if (String(ShelveFieldType.DIMENSION) == item.type) {
        shelve.aggregations.splice(num, 1);

        shelve.rows.push(item);
      }
    }

    return this.pivot;
  }

  private convertLineColorByDimension(): BaseOption {
    if (this.uiOption.color.type == ChartColorType.DIMENSION) {
      const option = this.chartOption;
      const schema = (<UIChartColorBySeries>this.uiOption.color).schema;
      const codes = _.cloneDeep(ChartColorList[schema]);
      const optionSeries = option.series as LineSeriesOption[];

      if (!_.isUndefined(option.visualMap)) delete option.visualMap;

      const seriesName: any = _.cloneDeep(optionSeries.map((item) => item.name));

      _.each(optionSeries, (obj) => {
        const aggName = obj.name;

        const fieldIdx = _.indexOf(seriesName, aggName);

        const colorIdx = fieldIdx >= codes['length'] ? fieldIdx % codes['length'] : fieldIdx;

        obj.itemStyle.color = codes[colorIdx];
      });
    }

    return this.chartOption;
  }

  private isAnalysisPredictionLineEmpty(): boolean {
    return _.isUndefined(this.analysis) || _.isEmpty(this.analysis);
  }

  private predictionLineLineStyleColorBySeries(): BaseOption {
    const convertLineTypeEnum = (lineType: string | LineType): string | LineType => {
      if (lineType === 'SOLID') {
        lineType = LineType.SOLID;
      } else if (lineType === 'DASHED') {
        lineType = LineType.DASHED;
      } else if (lineType === 'DOTTED') {
        lineType = LineType.DOTTED;
      }

      return lineType;
    };

    const series = this.chartOption.series as LineSeriesOption[];

    this.analysis.forecast.parameters.forEach((parameter) => {
      series
        .filter((obj) => {
          return obj.name === parameter.field;
        })
        .map((obj) => {
          const index = series.findIndex((data) => {
            return data.name === parameter.field;
          });

          let color: string = this.analysis.forecast.style.color;

          if (!this.analysis.forecast.style.predictColorUseFl) {
            color = obj.itemStyle.color as string;
          }

          const lineThickness: number = this.analysis.forecast.style.lineThickness;
          const lineType: LineType = convertLineTypeEnum(this.analysis.forecast.style.lineType) as LineType;

          const lowerSeries = series[index + 1];
          const observationsSeries = series[index + 2];
          const upperSeries = series[index + 3];
          const addSeries = series[index + 4];

          if (_.isUndefined(lowerSeries.lineStyle)) {
            lowerSeries.lineStyle = {};
          }

          if (_.isUndefined(observationsSeries.lineStyle)) {
            observationsSeries.lineStyle = {};
          }

          if (_.isUndefined(upperSeries.lineStyle)) {
            upperSeries.lineStyle = {};
          }

          lowerSeries.lineStyle.color = color;
          lowerSeries.lineStyle.type = lineType;
          lowerSeries.lineStyle.width = lineThickness;
          lowerSeries.lineStyle.opacity = 0;
          lowerSeries.showSymbol = false;

          observationsSeries.lineStyle.color = color;
          observationsSeries.lineStyle.type = lineType;
          observationsSeries.lineStyle.width = lineThickness;
          observationsSeries.showSymbol = false;

          upperSeries.lineStyle.color = color;
          upperSeries.lineStyle.type = lineType;
          upperSeries.lineStyle.width = lineThickness;
          upperSeries.lineStyle.opacity = 0;
          upperSeries.showSymbol = false;

          if (_.isUndefined(addSeries) === false) {
            if (addSeries.name === `${obj.name} - ${predictionLineTypeAdditional}`) {
              addSeries.lineStyle = {};
              addSeries.lineStyle.color = color;
              addSeries.lineStyle.type = lineType;
              addSeries.lineStyle.width = lineThickness;
              addSeries.lineStyle.opacity = 0;
              addSeries.showSymbol = false;
            }
          }
        });
    });

    return this.chartOption;
  }

  private predictionLineAreaStyleColorBySeries(): BaseOption {
    const setSeriesAreaStyleColor = (series: LineSeriesOption, color: string): void => {
      if (_.isUndefined(series.areaStyle)) {
        series.areaStyle = {};
      }
      series.areaStyle.color = color;
    };

    const transparencyDivisionShare = 100;

    const confidenceOpacityCalculator = (): number => {
      return this.analysis.confidence.style.transparency / transparencyDivisionShare;
    };

    const setSeriesAreaStyleOpacity = (series: LineSeriesOption, opacity: number): void => {
      series.areaStyle.opacity = opacity;
    };

    const series = this.chartOption.series as LineSeriesOption[];

    this.analysis.forecast.parameters.forEach((parameter) => {
      series
        .filter((obj) => {
          return obj.name === parameter.field;
        })
        .map((obj) => {
          const index = series.findIndex((data) => {
            return data.name === parameter.field;
          });

          let color: string = this.analysis.confidence.style.color;

          if (!this.analysis.confidence.style.predictColorUseFl) {
            color = series[index].itemStyle.color as string;
          }

          const lowerSeries = series[index + 1];
          const upperSeries = series[index + 3];
          const addSeries = series[index + 4];

          lowerSeries.stack = upperSeries.name as string;
          upperSeries.stack = upperSeries.name as string;

          const opacity: number = confidenceOpacityCalculator();

          setSeriesAreaStyleColor(upperSeries, color);

          setSeriesAreaStyleOpacity(upperSeries, opacity);

          if (_.isUndefined(addSeries) === false) {
            if (addSeries.name === `${obj.name} - ${predictionLineTypeAdditional}`) {
              setSeriesAreaStyleColor(addSeries, color);

              setSeriesAreaStyleOpacity(addSeries, opacity);
            }
          }
        });
    });

    return this.chartOption;
  }

  private exceptPredictionLineShowLine(lineStyle: LineStyleType): BaseOption {
    const series = this.chartOption.series as LineSeriesOption[];
    this.analysis.forecast.parameters.forEach((parameter) => {
      series
        .filter((obj) => {
          return obj.name === parameter.field;
        })
        .map((obj) => {
          if (_.eq(obj.type, SeriesType.LINE)) {
            const dataLength = obj.data.filter((value) => {
              return value != null;
            }).length;

            obj.symbol =
              obj.data.length === 1 || dataLength === 1 || LineStyleType.LINE !== lineStyle
                ? 'circle'
                : transparentSymbolImage;

            if (_.isUndefined(obj.lineStyle)) obj.lineStyle = {};

            if (!_.isUndefined(obj.lineStyle)) {
              obj.lineStyle.opacity = LineStyleType.POINT !== lineStyle ? 1 : 0;
            }
          }
        });
    });

    return this.chartOption;
  }

  private exceptPredictionLineColorBySeries(): BaseOption {
    const color = <UIChartColorBySeries>this.uiOption.color;
    const schema = color.schema;
    const list: any = _.cloneDeep(ChartColorList[schema]);

    if ((<UIChartColorBySeries>color).mapping) {
      Object.keys((<UIChartColorBySeries>color).mapping).forEach((key, index) => {
        list[index] = (<UIChartColorBySeries>color).mapping[key];
      });
    }

    if (this.uiOption.color.type !== ChartColorType.MEASURE) delete this.chartOption.visualMap;

    const series = this.chartOption.series as LineSeriesOption[];

    this.analysis.forecast.parameters.forEach((parameter, parameterIdx) => {
      series
        .filter((obj) => {
          return obj.name === parameter.field;
        })
        .map((obj) => {
          const aggName = _.last(_.split(obj.name as string, CHART_STRING_DELIMITER));
          const mappingInfo = (<UIChartColorBySeries>this.uiOption.color).mapping;

          if (_.isUndefined(obj.itemStyle)) obj.itemStyle = {};

          if (mappingInfo && mappingInfo[aggName]) {
            if (!_.isUndefined(obj.itemStyle.borderWidth) && obj.itemStyle.borderWidth > 0) {
              obj.itemStyle.borderColor = mappingInfo[aggName];
              delete obj.itemStyle.color;
            } else {
              obj.itemStyle.color = mappingInfo[aggName];
            }

            // if (!_.isUndefined(obj.textStyle)) obj.textStyle.color = mappingInfo[aggName];
          } else {
            const fieldIdx = _.indexOf(this.fieldInfo.aggs, aggName);

            const colorIdx = fieldIdx >= list.length ? fieldIdx % list.length : fieldIdx;

            if (!_.isUndefined(obj.itemStyle.borderWidth) && obj.itemStyle.borderWidth > 0) {
              obj.itemStyle.borderColor = list[colorIdx];
              delete obj.itemStyle.color;
            } else {
              obj.itemStyle.color = list[parameterIdx];
            }

            // if (!_.isUndefined(obj.textStyle)) obj.textStyle.color = list[colorIdx];
          }
        });
    });

    return this.chartOption;
  }

  private convertColor(): BaseOption {
    const color = this.uiOption.color;

    switch (color.type) {
      case ChartColorType.SERIES: {
        const schema = (<UIChartColorBySeries>color).schema;
        const colorCodes = _.cloneDeep(ChartColorList[schema]);

        if ((<UIChartColorBySeries>color).mapping) {
          Object.keys((<UIChartColorBySeries>color).mapping).forEach((key, index) => {
            colorCodes[index] = (<UIChartColorBySeries>color).mapping[key];
          });
        }

        if (this.isAnalysisPredictionLineEmpty()) {
          this.chartOption = ColorOptionConverter.convertColorBySeries(this.chartOption, this.fieldInfo, colorCodes);
        } else {
          this.chartOption = this.exceptPredictionLineColorBySeries();
          this.chartOption = this.predictionLineLineStyleColorBySeries();
          this.chartOption = this.predictionLineAreaStyleColorBySeries();
        }

        break;
      }
      case ChartColorType.DIMENSION: {
        this.chartOption = this.convertLineColorByDimension();
        if (!this.isAnalysisPredictionLineEmpty()) {
          this.chartOption = this.exceptPredictionLineColorBySeries();
          this.chartOption = this.predictionLineLineStyleColorBySeries();
          this.chartOption = this.predictionLineAreaStyleColorBySeries();
        }
        break;
      }
      case ChartColorType.MEASURE: {
        if (this.uiOption.color['customMode'] && ColorCustomMode.GRADIENT == this.uiOption.color['customMode']) {
          this.chartOption = ColorOptionConverter.convertColorByValueGradation(this.chartOption, this.uiOption);
        } else {
          this.chartOption = ColorOptionConverter.convertColorByValue(this.chartOption, this.uiOption);
        }

        if (!this.isAnalysisPredictionLineEmpty()) {
          this.chartOption = this.exceptPredictionLineColorBySeries();
          this.chartOption = this.predictionLineLineStyleColorBySeries();
          this.chartOption = this.predictionLineAreaStyleColorBySeries();
        }
        break;
      }
    }

    return this.chartOption;
  }
}
