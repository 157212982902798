import { Dashboard, Filter } from '@selfai-platform/bi-domain';

export function removeDuplicateFilters(boardInfo: Dashboard) {
  const filters: Filter[] = boardInfo.configuration.filters;
  if (filters) {
    boardInfo.configuration.filters = filters.reduce((acc: Filter[], curr: Filter) => {
      if (!acc.some((item) => item.dataSource === curr.dataSource && item.field === curr.field)) {
        acc.push(curr);
      }
      return acc;
    }, []);
  }
}
