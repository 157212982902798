import { DataSourcesVisibilityEnum } from '../enums/data-sources-visibility.enum';

export function getVisibility(visibility: string): 'private' | 'public' | '' {
  switch (visibility) {
    case DataSourcesVisibilityEnum.PUBLIC:
      return 'public';
    case DataSourcesVisibilityEnum.PRIVATE:
      return 'private';
    default:
      return '';
  }
}
