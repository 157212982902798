import _ from 'lodash';

import {
  UIMapOption,
  Shelf,
  PageWidgetConfiguration,
  Widget,
  FieldRole,
  CustomField,
} from '@selfai-platform/bi-domain';

export function syncCustomFieldInWidgetShelf(widget: Widget, customFields: CustomField[]): Shelf {
  const layerNum = (<UIMapOption>(<PageWidgetConfiguration>widget.configuration).chart).layerNum;
  const shelf: Shelf = (<PageWidgetConfiguration>widget.configuration).shelf;
  if (customFields) {
    customFields.forEach((field: CustomField) => {
      if (FieldRole.DIMENSION === field.role) {
        shelf.layers[layerNum].fields.some((layer) => {
          if (layer.name === field['oriColumnName']) {
            layer.field = _.merge(layer.field, field);
            layer['expr'] = field['expr'];
            layer['name'] = field['name'];
            return true;
          }
        });
      } else if (FieldRole.MEASURE === field.role) {
        const customFieldPivotIdxs: number[] = [];
        shelf.layers[layerNum].fields.forEach((agg, idx: number) => {
          if (agg.name === field['oriColumnName']) {
            customFieldPivotIdxs.push(idx);
          }
        });
        if (1 < customFieldPivotIdxs.length) {
          customFieldPivotIdxs.splice(0, 1);
          customFieldPivotIdxs.reverse().forEach((idx) => {
            shelf.layers[layerNum].fields.splice(idx, 1);
          });
        }
        shelf.layers[layerNum].fields.forEach((agg) => {
          if (agg.name === field['oriColumnName']) {
            agg.field = _.merge(agg.field, field);
            agg['expr'] = field['expr'];
            agg['name'] = field['name'];
            agg['aggregated'] = field['aggregated'];
            agg['aggregationType'] = field['aggregated'] ? null : 'SUM';
            return true;
          }
        });
      }
    });
  }
  return shelf;
}
