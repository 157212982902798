import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AlertService, readJsonFile } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-platform-aggregator-import-form',
  templateUrl: './aggregator-import-form.component.html',
  styleUrls: ['./aggregator-import-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregatorImportFormComponent {
  get showReplaceWarn(): boolean {
    return Boolean(this.dialogService.data?.showReplaceWarn);
  }

  constructor(
    private readonly alertService: AlertService,
    private readonly dialogService: DialogService<unknown, { showReplaceWarn?: boolean }>,
  ) {}

  onError(): void {
    this.alertService.error(`Error. Can't upload file`);
  }

  onUpload(event: { files: File[] }): void {
    const { files } = event;

    readJsonFile(files[0]).subscribe({
      next: (data) => {
        this.dialogService.close(data);
      },
      error: (err: Error) => {
        this.alertService.error(err.message);
      },
    });
  }
}
