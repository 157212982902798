import { Injectable } from '@angular/core';
import { AlertService, HistoryBackService } from '@selfai-platform/shared';
import { KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH } from '@selfai-platform/shell';

@Injectable({
  providedIn: 'root'
})
export class FunctionsResponseCallbacksService {
  constructor(
    private readonly alertService: AlertService,
    private readonly history: HistoryBackService
  ) {}

  public handleErrorCallback(alertMessage: string, errJson: string): void {
    const err = JSON.parse(errJson);
    this.alertService.error(alertMessage + '. ' + (err['message'] ? err['message'] : ''));
  }

  public handleNextCallback(message: string, isBack: boolean = true): void {
    this.alertService.success(message);
    isBack && this.history.back(['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH]);
  }
}
