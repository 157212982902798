import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile } from '@selfai-platform/pipeline-common';
import { AppInitService, PipelineConfigService } from '@selfai-platform/shared';
import { Observable, filter, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeUserApiService {
  private apiurl: string;
  private readonly pathProfile = '/profile';

  constructor(
    private readonly http: HttpClient,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly appInitService: AppInitService,
  ) {
    appInitService.pipelineLoaded$.pipe(filter((loaded) => loaded)).subscribe(() => {
      const config = this.pipelineConfigService.getConfig();
      this.apiurl = `${config.hosts.api}/${config.versions.url}`;
    });
  }

  getCurrentUserProfile(): Observable<Profile> {
    return this.appInitService.pipelineLoaded$.pipe(
      filter((loaded) => loaded),
      switchMap(() => {
        return this.http.get<Profile>(`${this.apiurl}${this.pathProfile}`);
      }),
    );
  }
}
