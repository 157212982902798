import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { WidgetService } from '../dashboard/service/widget.service';
import { TimezoneService } from '../data-storage/service/timezone.service';
import { DatasourceAliasService } from '../datasource/service/datasource-alias.service';

import { CommonModule } from './common.module';
import { DataDownloadComponent } from './component/data-download/data.download.component';
import { DataPreviewComponent } from './component/data-preview/data.preview.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [DataPreviewComponent, DataDownloadComponent],
  exports: [DataPreviewComponent, DataDownloadComponent],
  providers: [DatasourceAliasService, WidgetService, TimezoneService],
})
export class DataPreviewModule {}
