import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { DASHBOARD_ENTITY_NAME } from '../const';
import { DashboardState } from '../models';
import { DashboardDataService } from '../services';

@Injectable({ providedIn: 'root' })
export class DashboardStore extends EntityCollectionServiceBase<DashboardState> {
  constructor(
    entityDataService: EntityDataService,
    dashboardDataService: DashboardDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(DASHBOARD_ENTITY_NAME, dashboardDataService);
    super(DASHBOARD_ENTITY_NAME, serviceElementsFactory);
  }
}
