<div [ngClass]="{ 'ddp-selectbox-disabled': disabled }">
  <div
    class="ddp-type-selectbox"
    [ngClass]="{ 'ddp-selected': isShowSelectList && isShowSelectListForOutSide }"
    (click)="toggleSelectList()"
  >
    <span class="ddp-txt-selectbox">{{ viewText }}</span>
    <ul
      [ngClass]="{ 'ddp-selectdown': isUpSelect === false, 'ddp-pass': isOptionToLeft, 'ddp-pass2': !isOptionToLeft }"
      class="ddp-list-selectbox"
    >
      <li *ngFor="let item of array">
        <a href="javascript:" (click)="$event.stopPropagation()">
          <label class="ddp-label-checkbox">
            <input type="checkbox" (change)="selected(item, $event)" />
            <i class="ddp-icon-checkbox ddp-dark"></i>
            <span class="ddp-txt-checkbox">{{ isStringArray ? item : item[viewKey] }}</span>
          </label>
        </a>
      </li>
    </ul>
  </div>
</div>
