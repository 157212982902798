import { Filter, createFilter } from './filter.model';

export interface RegExprFilter extends Filter {
  expr?: string;
}

export function createRegExprFilter(params: Partial<RegExprFilter> = {}): RegExprFilter {
  return {
    ...createFilter(),
    type: 'regexpr',
    ...params,
  };
}
