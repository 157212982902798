import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { AggregatorRouteAddComponentModule } from '../aggregator-route-add';

import { TranslateModule } from '@ngx-translate/core';
import { DataListViewComponent, DataListViewTemplateNameDirective } from '@selfai-platform/shell';
import { AggregatorRouteListComponent } from './aggregator-route-list.component';

@NgModule({
  imports: [
    CommonModule,
    AggregatorRouteAddComponentModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    ButtonModule,
    ConfirmPopupModule,
    InputSwitchModule,
    DataListViewComponent,
    DataListViewTemplateNameDirective,
    RouterModule.forChild([
      {
        path: '',
        component: AggregatorRouteListComponent,
      },
    ]),
    TranslateModule,
  ],
  declarations: [AggregatorRouteListComponent],
  exports: [AggregatorRouteListComponent],
})
export class AggregatorRouteListComponentModule {}
