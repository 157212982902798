import { DatasourceField } from '../../datasource';
import { DashboardPageRelation } from '../../widget';
import { CustomField, Filter } from '../../workbook';
import { BoardDataSource, createBoardSource } from './board-datasource';
import { BoardGlobalOptions, createBoardGlobalOptions } from './board-options';
import { DashboardLayout, DashboardLayoutContentSettings, createDashboardLayout } from './dashboard-layout';
import { LayoutWidgetInfo } from './layout';

export interface BoardConfiguration {
  dataSource: BoardDataSource;
  options: BoardGlobalOptions;
  widgets: LayoutWidgetInfo[];
  content?: DashboardLayoutContentSettings[];
  relations?: DashboardPageRelation[];
  customFields?: CustomField[];
  userDefinedFields?: CustomField[];
  filters: Filter[];

  fields?: DatasourceField[];
  layout: DashboardLayout;
}

export function createBoardConfiguration(
  boardConfigurationOptions: Partial<BoardConfiguration> = {},
): BoardConfiguration {
  return {
    options: createBoardGlobalOptions(),
    layout: createDashboardLayout(),
    dataSource: createBoardSource(),
    widgets: [],
    filters: [],
    // TODO: check mapping in DashboardUtil.convertSpecToUI()
    customFields: boardConfigurationOptions.userDefinedFields,
    ...boardConfigurationOptions,
  };
}
