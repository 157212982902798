import { BoardDataSourceRelationUI, createBoardDataSourceRelationUI } from './board-datasource-relation-ui';

export interface BoardDataSourceRelation {
  id?: string;
  source?: string;
  target?: string;
  columnPair: { [key: string]: string };
  ui: BoardDataSourceRelationUI;
}

export function createBoardDataSourceRelation(
  boardDataSourceRelationOptions: Partial<BoardDataSourceRelation> = {},
): BoardDataSourceRelation {
  return {
    ui: createBoardDataSourceRelationUI(),
    columnPair: {},
    ...boardDataSourceRelationOptions,
  };
}
