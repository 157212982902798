import { FormControl } from '@angular/forms';

export enum FieldType {
  JSONPATH = 'jsonpath',
  CONSTANT = 'constant',
  SPEL = 'spel',
}

export interface JsonPathApiModel {
  [FieldType.JSONPATH]: string;
}

export interface JsonPathFieldValue {
  type: FieldType.JSONPATH;
  value: string | null;
}

export interface ConstantApiModel {
  [FieldType.CONSTANT]: string;
}

export interface ConstantFieldValue {
  type: FieldType.CONSTANT;
  value: string | null;
}

export interface SpelApiModel {
  [FieldType.SPEL]: string;
}

export interface SpelFieldValue {
  type: FieldType.SPEL;
  value: string | null;
}

export type TypeValuesApiModel = JsonPathApiModel | ConstantApiModel | SpelApiModel;
export type TypeFieldValues = JsonPathFieldValue | ConstantFieldValue | SpelFieldValue;

// FormGroup types
export interface JsonPathFormGroup {
  type: FormControl<FieldType.JSONPATH | null>;
  value: FormControl<string | null>;
}

export interface ConstantValueFormGroup {
  type: FormControl<FieldType.CONSTANT | null>;
  value: FormControl<string | null>;
}
export interface SpelValueFormGroup {
  type: FormControl<FieldType.SPEL | null>;
  value: FormControl<string | null>;
}

export type TypeValuesFormGroup = JsonPathFormGroup | ConstantValueFormGroup | SpelValueFormGroup;
