import { Modal } from '../domain/modal';

import { CommonUtil } from './common.util';

declare let toastr;

export class Alert {
  public static ERROR_NAME: string;
  public static MORE_BTN_DESC: string;
  public static CLOSE_BTN: string;

  private static _setDefaultOpts() {
    toastr.options = {
      closeButton: true,
      onlyShowNewest: true,
      newestOnTop: false,
      escapeHtml: true,
      positionClass: 'toast-top-center',
      showMethod: 'slideDown',
      showEasing: 'linear',
      hideMethod: 'slideUp',
      showDuration: 300,
      hideDuration: 300,
      timeOut: 3000,
      onCustomAction: null,
    };
  }

  public static info(message: string): void {
    this._setDefaultOpts();
    setTimeout(() => {
      toastr.info(message, 'Information');
    }, Math.random() * 1000);
  }

  public static success(message: string): void {
    this._setDefaultOpts();
    setTimeout(() => {
      toastr.success(message, 'Done');
    }, Math.random() * 1000);
  }

  public static warning(message: string): void {
    this._setDefaultOpts();
    setTimeout(() => {
      toastr.warning(message, 'Warning');
    }, Math.random() * 1000);
  }

  public static fail(message: string): void {
    this._setDefaultOpts();
    setTimeout(() => {
      toastr.warning(message, 'Error');
    }, Math.random() * 1000);
  }

  public static error(message: string, isShow: boolean = false): void {
    if (location.href.indexOf('user/login') < 0 || isShow) {
      this._setDefaultOpts();
      setTimeout(() => {
        toastr.error(message, 'Error');
      }, Math.random() * 1000);
    }
  }

  public static errorDetail(message: string, detailMsg: string): void {
    this._setDefaultOpts();
    toastr.options.escapeHtml = false;
    toastr.options.onCustomAction = () => {
      const modal = new Modal();
      modal.name = this.ERROR_NAME;
      modal.description = detailMsg;
      modal.isShowCancel = false;
      modal.isScroll = true;
      modal.btnName = this.CLOSE_BTN;
      CommonUtil.confirm(modal);
    };
    setTimeout(() => {
      toastr.error(
        message + `<br/><a class="toast-custom-action-button" href="javascript:" >` + this.MORE_BTN_DESC + `</a>`,
        'Error',
      );
    }, Math.random() * 1000);
  }
}
