<div class="ddp-pop-preview ddp-type" *ngIf="isShow">
  <div class="ddp-ui-top">
    <div class="ddp-data-name">
      {{ log.title }}

      <div class="ddp-wrap-info" *ngIf="subTitles">
        <span class="ddp-data-info" *ngFor="let sub of subTitles">{{ sub }}</span>
      </div>

      <a href="javascript:" class="ddp-link-top" (click)="copyToClipboard()" *ngIf="log.isShowCopy">
        {{ 'msg.comm.btn.copy.clipboard' | translate }}
      </a>
    </div>

    <em class="ddp-btn-popup-close" (click)="close()"></em>
  </div>

  <div class="ddp-ui-preview-contents ddp-type-preview">
    {{ log.data }}
  </div>
</div>
