import _ from 'lodash';
import { Datasource, DatasourceField, FieldRole } from '../../../datasource';
import { JoinMapping } from '../../../workbook';
import { BoardDataSource, Dashboard } from '../../models';
import { setFieldAttribute } from './set-field-attribute';
import { getDataSourceFromBoardDataSource } from './get-datasource-from-board-datasource';

export function enreachFieldsWithJoin(
  fields: DatasourceField[],
  dashboard: Dashboard,
  datasource: Datasource,
): DatasourceField[] {
  if (dashboard.configuration.dataSource.type === 'default') {
    return fields;
  }

  if (dashboard.configuration.dataSource.type === 'mapping') {
    return processMappingDatasource(fields, dashboard, dashboard.configuration.dataSource);
  }

  if (dashboard.configuration.dataSource.type === 'multi') {
    const dashboardSource = dashboard.configuration.dataSource.dataSources.find((ds) => ds.id === datasource.id);

    return processMappingDatasource(fields, dashboard, dashboardSource);
  }

  return fields;
}

function processMappingDatasource(fields: DatasourceField[], dashboard: Dashboard, dashboardSource: BoardDataSource) {
  if (dashboardSource.type !== 'mapping' || !dashboardSource.joins || dashboardSource.joins.length === 0) {
    return fields;
  }

  const objJoinValueKey: Record<string, string[]> = {};
  const masterDsInfo: Datasource = getDataSourceFromBoardDataSource(dashboard, dashboardSource);
  let fieldsWithJoin: DatasourceField[] = [...fields];
  dashboardSource.joins.forEach((joinItem: JoinMapping) => {
    {
      const oneDepthDsInfo: Datasource = dashboard.dataSources.find((dsItem) => dsItem.id === joinItem.id);

      joinItem = _.merge(joinItem, { engineName: oneDepthDsInfo.engineName });

      objJoinValueKey[joinItem.id] = Object.keys(joinItem.keyPair).map((keyItem: string) => joinItem.keyPair[keyItem]);

      fieldsWithJoin = fieldsWithJoin.concat(
        oneDepthDsInfo.fields
          .map((fieldItem) => {
            if (
              fieldItem.role !== FieldRole.TIMESTAMP &&
              fieldItem.name !== 'count' &&
              -1 === objJoinValueKey[joinItem.id].indexOf(fieldItem.name)
            ) {
              fieldItem = setFieldAttribute(fieldItem, masterDsInfo, oneDepthDsInfo);
              fieldItem.ref = joinItem.joinAlias;
              fieldItem.join = joinItem;

              return { ...fieldItem, join: joinItem };
            } else {
              return null;
            }
          })
          .filter((fieldItem) => fieldItem !== null),
      );
    }

    if (joinItem.join) {
      const twoDepthDsInfo: Datasource = dashboard.dataSources.find((dsItem) => dsItem.id === joinItem.join.id);

      joinItem.join = _.merge(joinItem.join, { engineName: twoDepthDsInfo.engineName });

      objJoinValueKey[joinItem.join.id] = Object.keys(joinItem.join.keyPair).map(
        (keyItem: string) => joinItem.join.keyPair[keyItem],
      );

      fieldsWithJoin = fieldsWithJoin.concat(
        twoDepthDsInfo.fields
          .map((fieldItem) => {
            if (
              fieldItem.role !== FieldRole.TIMESTAMP &&
              fieldItem.name !== 'count' &&
              -1 === objJoinValueKey[joinItem.join.id].indexOf(fieldItem.name)
            ) {
              fieldItem = setFieldAttribute(fieldItem, masterDsInfo, twoDepthDsInfo);
              fieldItem.ref = joinItem.joinAlias;

              return { ...fieldItem, join: joinItem };
            } else {
              return null;
            }
          })
          .filter((fieldItem) => fieldItem !== null),
      );
    }
  });

  return fieldsWithJoin;
}
