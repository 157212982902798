import { DatasourceField } from '../../../datasource';
import { BoardDataSource } from './board-datasource';

export interface BoardDataSourceRelationUI {
  source?: BoardDataSource;
  target?: BoardDataSource;
  sourceField?: DatasourceField;
  targetField?: DatasourceField;
}

export function createBoardDataSourceRelationUI(
  boardDataSourceRelationUIOptions: Partial<BoardDataSourceRelationUI> = {},
): BoardDataSourceRelationUI {
  return {
    ...boardDataSourceRelationUIOptions,
  };
}
