import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BaseOption,
  ChartType,
  LineMarkType,
  Pivot,
  PivotField,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartFormat,
  UIOption,
  UiChartDataLabelDisplayType,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';

import { RadarComponentOption, RadarSeriesOption, TooltipComponentOption } from 'echarts';
import * as _ from 'lodash';
import { FormatOptionConverter, LabelOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'radar-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class RadarChartComponent extends BaseChart implements OnInit, OnDestroy, AfterViewInit {
  public override isValid(pivot: Pivot): boolean {
    return (
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 1 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0 &&
      (this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) > 0 ||
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) > 0)
    );
  }

  public override draw(isKeepRange?: boolean): void {
    if (!this.isValid(this.pivot)) {
      this.noData.emit();

      return;
    }

    this.chartOption = this.initOption();

    this.chartOption = this.convertBasic();

    this.chartOption = this.convertDataInfo();

    this.chartOption = this.convertSeries();

    this.chartOption = this.convertTooltip();

    this.chartOption = this.convertLegend();

    this.chartOption = this.convertGrid();

    this.chartOption = this.convertEtc();

    this.apply();

    this.drawFinish();

    if (!this.isPage) {
      this.selection();
    }
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.RADAR,
      radar: { indicator: [] },
      legend: OptionGenerator.Legend.custom(true, true, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      series: [],
    };
  }

  protected override convertBasic(): BaseOption {
    this.setMeasureList();

    const aggs: string[] = [];

    const max = this.data.info.maxValue + this.data.info.maxValue * 0.1;

    (this.chartOption.radar as RadarComponentOption).indicator = [];
    this.data.rows.map((row) => {
      if (_.indexOf(aggs, row) < 0) {
        (this.chartOption.radar as RadarComponentOption).indicator.push({ max, name: row });
      }
    });

    return this.chartOption;
  }

  protected override convertSeries(): BaseOption {
    this.chartOption = this.convertSeriesData();

    this.chartOption = LabelOptionConverter.convertLabel(this.chartOption, this.uiOption);

    this.chartOption = this.convertRadarFormatSeries(this.chartOption, this.uiOption);

    this.chartOption = this.additionalSeries();

    return this.chartOption;
  }

  protected override convertSeriesData(): BaseOption {
    this.chartOption.series = [];
    this.chartOption.series.push({
      type: SeriesType.RADAR,
      name: 'radar',
      data: this.data.columns.map((column) => {
        return column;
      }),
    });

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertViewType();

    return this.chartOption;
  }

  protected override additionalTooltip(): BaseOption {
    let format: UIChartFormat = this.uiOption.valueFormat;

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(this.uiOption);
    if (axisFormat) format = axisFormat;

    if (_.isUndefined(this.chartOption.tooltip)) {
      this.chartOption.tooltip = {};
    }
    (this.chartOption.tooltip as TooltipComponentOption).formatter = (params): any => {
      const option = this.chartOption.series[params.seriesIndex];

      let uiData = _.cloneDeep(option.uiData);

      if (uiData && uiData instanceof Array) uiData = option.uiData[params.dataIndex];

      return this.getFormatRadarValueSeriesTooltip(params, format, this.uiOption, option, uiData);
    };

    return this.chartOption;
  }

  protected override convertDataInfo(): BaseOption {
    this.chartOption['dataInfo'] = {
      minValue: this.data.info.minValue,
      maxValue: this.data.info.maxValue,
    };

    return this.chartOption;
  }

  protected override setPivotInfo(): void {
    const aggs: string[] = [];

    this.data.rows.map((row) => {
      if (_.indexOf(aggs, row) < 0) {
        aggs.push(row);
      }
    });

    this.pivotInfo = createPivotTableInfo([], [], aggs);
  }

  protected override additionalEtc(): BaseOption {
    return this.chartOption;
  }

  private convertViewType(): BaseOption {
    if (_.isUndefined(this.chartOption.series)) {
      return this.chartOption;
    }

    const type: LineMarkType = this.uiOption['mark'];

    _.each(this.chartOption.series as RadarSeriesOption[], (obj) => {
      if (_.eq(obj.type, SeriesType.RADAR)) {
        obj.areaStyle = _.eq(type, LineMarkType.AREA)
          ? OptionGenerator.AreaStyle.radarItemAreaStyle('default')
          : undefined;
      }
    });

    return this.chartOption;
  }

  private convertRadarFormatSeries(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    let format: UIChartFormat = uiOption.valueFormat;
    if (_.isUndefined(format)) {
      return chartOption;
    }

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormat(uiOption);
    if (axisFormat) format = axisFormat;

    const series: RadarSeriesOption[] = chartOption.series as RadarSeriesOption[];

    _.each(series, (option, index) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (_.isUndefined(option.label)) {
        option.label = {};
      }

      option.label.formatter = (params): any => {
        let uiData: any = _.cloneDeep(option.data);

        if (uiData instanceof Array) uiData = option.data[params.dataIndex];

        return this.getFormatRadarValueSeries(params, format, uiOption, option, uiData);
      };
    });

    return chartOption;
  }

  private getFormatRadarValueSeries(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;
      const result: string[] = [];
      if (
        uiData['categoryName'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        const valueIndex = uiData['value'].indexOf(params.value);
        result.push(uiData['categoryName'][valueIndex]);
        isUiData = true;
      }
      if (uiData['value'] && -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.VALUE)) {
        result.push(FormatOptionConverter.getFormatValue(params.value, format));
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.normal && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }

        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }

  private getFormatRadarValueSeriesTooltip(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);

      let result: string[] = [];

      const categoryNameList = _.filter(this.pivot.aggregations, { type: 'dimension' }) as PivotField[];
      const categoryValueList = _.filter(this.pivot.aggregations, { type: 'measure' }) as PivotField[];

      if (
        uiData['categoryName'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME) &&
        uiData['value'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.VALUE)
      ) {
        while (uiData['categoryName'].length > categoryNameList.length) {
          categoryNameList.push(categoryNameList[0]);
        }

        _.each(uiData['categoryName'], (item, index: number) => {
          result = FormatOptionConverter.getTooltipName([item], categoryNameList, result, true);

          const seriesValue = FormatOptionConverter.getTooltipValue(
            params.name,
            categoryValueList,
            format,
            uiData['value'][index],
          );
          result.push(seriesValue);
        });
      } else {
        if (
          uiData['categoryName'] &&
          -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)
        ) {
          _.each(uiData['categoryName'], (item) => {
            result = FormatOptionConverter.getTooltipName([item], categoryNameList, result, true);
          });
        }
        if (uiData['value'] && -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.VALUE)) {
          _.each(params.value, (item, index: number) => {
            const seriesValue = FormatOptionConverter.getTooltipValue(params.name, categoryValueList, format, item);
            result.push(seriesValue);
          });
        }
      }

      return result.join('<br/>');
    }

    return FormatOptionConverter.noUIDataFormatTooltip(uiOption, params, format, this.fieldInfo);
  }
}
