import { Injectable } from '@angular/core';
import { PipelineWorkflowVariableItem } from '@selfai-platform/pipeline-common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VariableItemsService extends BehaviorSubject<PipelineWorkflowVariableItem[]> {
  constructor() {
    super([]);
  }
}
