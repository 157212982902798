import { v4 as uuidv4 } from 'uuid';
import { Filter } from '../../../workbook';

export interface LayoutWidgetInfo {
  id: string;
  type: string;
  ref: string;
  title: boolean;

  isSaved: boolean;
  isInLayout: boolean;
  isLoaded: boolean;
  filter?: Filter;
}
export function createLayoutWidgetInfo(widgetId: string, widgetType: string): LayoutWidgetInfo {
  return {
    id: uuidv4(),
    type: widgetType,
    ref: widgetId,
    title: 'page' !== widgetType,
    isSaved: false,
    isInLayout: false,
    isLoaded: false,
  };
}
