export enum WorkflowPermissionAction {
  List = 'list',
  Create = 'create',
  Upload = 'upload',
  Clone = 'clone',
  Delete = 'delete',
  Execute = 'execute',
  Run = 'run',
  Get = 'get',
  Stop = 'stop',
  Update = 'update',
  Export = 'export',
}

export enum DatasourcePermissionAction {
  Delete = 'delete',
  Update = 'update',
  Create = 'create',
  Get = 'get',
}

export type PermissionAction = WorkflowPermissionAction | DatasourcePermissionAction;
