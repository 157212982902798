import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WIDGET_DASHBOARD_ENTITY_NAME } from '../const';
import { WidgetDashboardState } from '../models';
import { WidgetDashboardDataService } from '../services';

@Injectable({ providedIn: 'root' })
export class WidgetDashboardStore extends EntityCollectionServiceBase<WidgetDashboardState> {
  constructor(
    entityDataService: EntityDataService,
    widgetDashboardDataService: WidgetDashboardDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(WIDGET_DASHBOARD_ENTITY_NAME, widgetDashboardDataService);
    super(WIDGET_DASHBOARD_ENTITY_NAME, serviceElementsFactory);
  }
}
