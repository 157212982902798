import { Injectable } from '@angular/core';
import { LoaderUtil } from '@selfai-platform/shared';
import { Observable, filter, map } from 'rxjs';
import { HazelCastApiService } from './api';

@Injectable({ providedIn: 'root' })
export class HazelCastService extends LoaderUtil<Record<string, unknown>> {
  constructor(private readonly hazelCastApiService: HazelCastApiService) {
    super(undefined);
  }

  loadHazelCast(name: string, key: string): void {
    this.loadFromSource(this.hazelCastApiService.loadHazelCast(name, key));
  }

  getHazelCast(): Observable<Record<string, unknown>> {
    return this.asObservable().pipe(
      map(({ value }) => value),
      filter(Boolean),
    );
  }
}
