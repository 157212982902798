import { HomePageWidgets } from '../home-page-widgets';
import { HomePageWidgetConfig } from './home-page-base-widget-config';

export const PipelineWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.Pipeline,
  description: 'shell.homepage.widgets.pipeline.description',
  letter: 'shell.homepage.widgets.pipeline.letter',
  link: '/pipeline',
  linkTitle: 'shell.homepage.widgets.pipeline.linkTitle',
  title: 'shell.homepage.widgets.pipeline.title',
};

export const BiWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.SelfaiBi,
  description: 'shell.homepage.widgets.kd.description',
  letter: 'shell.homepage.widgets.kd.letter',
  link: '/kd',
  linkTitle: 'shell.homepage.widgets.kd.linkTitle',
  title: 'shell.homepage.widgets.kd.title',
};

export const AggregatorsWidgetConfig: HomePageWidgetConfig = {
  name: HomePageWidgets.Aggregation,
  description: 'shell.homepage.widgets.aggregation.description',
  letter: 'shell.homepage.widgets.aggregation.letter',
  link: '/aggregators',
  linkTitle: 'shell.homepage.widgets.aggregation.linkTitle',
  title: 'shell.homepage.widgets.aggregation.title',
};

const allwidgets = {
  [HomePageWidgets.Pipeline]: PipelineWidgetConfig,
  [HomePageWidgets.SelfaiBi]: BiWidgetConfig,
  [HomePageWidgets.Aggregation]: AggregatorsWidgetConfig,
};

export function getStartPageWidgetsConfigs(widgetNames: HomePageWidgets[]) {
  return widgetNames.map((wName) => allwidgets[wName]);
}
