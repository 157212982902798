import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CubeEditResult,
  CubeWorkflowData,
  JsonToColumnItem,
  JsonToColumnItemFormGroup,
  JsonToColumnTransformationData,
  JsonToColumnTransformationFormGroup,
  WorkflowJsonToColumnTransformationData,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { BehaviorSubject, Observable, map } from 'rxjs';
import {
  extractSelectionItemsJsonToColumnTransofrmation,
  normalizeDataJsonToColumnTransofrmation,
} from '../../../converters/json-to-column-transformation.normalizer';
import { SelectionStoreService } from '../../../services/selection-store.service';

interface StoreData {
  nodeId: string;
  data: JsonToColumnTransformationData;
}

@Injectable()
export class JsonToColumnTransformationComponentService extends BehaviorSubject<StoreData> {
  constructor(
    dialogService: DialogService<CubeEditResult, CubeWorkflowData<WorkflowJsonToColumnTransformationData>>,
    selectionStoreService: SelectionStoreService,
  ) {
    let data: StoreData = { data: {} } as StoreData;
    if (dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = dialogService.data;
      if (parameters) {
        data = { nodeId, data: normalizeDataJsonToColumnTransofrmation(parameters.serialize()) };

        const selectionColumns = extractSelectionItemsJsonToColumnTransofrmation(parameters);
        if (selectionColumns) {
          selectionStoreService.setSelectionsColumns(selectionColumns);
        }
      }
    }

    super(data);
  }

  setData(data: Partial<StoreData>): void {
    this.next({ ...this.value, ...data });
  }

  // rest fields to except items
  getFormGroup(): FormGroup<JsonToColumnTransformationFormGroup> {
    const {
      data: { sourceColumn, justTypeTransformation, dateTimeFormat, dateFormat },
    } = this.value;

    return new FormGroup<JsonToColumnTransformationFormGroup>({
      sourceColumn: new FormControl(sourceColumn),
      justTypeTransformation: new FormControl(justTypeTransformation),
      dateTimeFormat: new FormControl(dateTimeFormat),
      dateFormat: new FormControl(dateFormat),
    });
  }

  getItemsFormGroups(): Observable<FormGroup<JsonToColumnItemFormGroup>[]> {
    return this.asObservable().pipe(map(({ data: { items } }) => items?.map(this.mapItemToFormGroup)));
  }

  getNodeId(): Observable<string> {
    return this.asObservable().pipe(map(({ nodeId }) => nodeId));
  }

  mapItemToFormGroup(item: JsonToColumnItem): FormGroup<JsonToColumnItemFormGroup> {
    return new FormGroup<JsonToColumnItemFormGroup>({
      id: new FormControl(item.id) as FormControl<string>,
      jsonPath: new FormControl(item.jsonPath, [Validators.required]),
      dataType: new FormControl(item.dataType, [Validators.required]),
      columnName: new FormControl(item.columnName),
    });
  }
}
