import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RxjsOfPipe } from './rxjs-of.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [RxjsOfPipe],
  exports: [RxjsOfPipe],
})
export class RxjsOfPipeModule {}
