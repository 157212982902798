export class Modal {
  public name: string;
  public description: string;
  public subDescription?: string;
  public btnName: string;
  public btnCancel?: string;
  public isShowCancel = true;
  public isScroll = false;
  public data?: any;
  public afterConfirm?: Function;
  public zIndex?: number;
}

export class Log {
  public title: string;
  public subTitle: string[];
  public data: string;
  public isShowCopy = false;
}
