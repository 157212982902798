<ng-container *ngIf="componentData$ | async as componentData">
  <div class="toolbar">
    <div class="toolbar-left-side flex gap-2">
      <p-selectButton
        *ngIf="layoutTypes.length > 1"
        optionValue="value"
        [allowEmpty]="false"
        [options]="layoutOptions"
        [ngModel]="componentData.selectedLayoutType"
        (onChange)="onChangeViewType($event.value)"
      >
        <ng-template let-item pTemplate>
          <i [ngClass]="[item.label]"></i>
        </ng-template>
      </p-selectButton>
      <ng-container *ngIf="componentData.selectedLayoutType === 'grid'">
        <p-dropdown
          [options]="calculatedOrderByOptions$ | async"
          [ngModel]="componentData.pageParams.sortField"
          (ngModelChange)="onChangeSortField($event)"
        ></p-dropdown>
        <p-dropdown
          [options]="sortBy$ | async"
          [ngModel]="componentData.pageParams.sortOrder"
          (ngModelChange)="onChangeSortOrder($event)"
        ></p-dropdown>
      </ng-container>
    </div>
    <div class="toolbar-right-side flex gap-2">
      <ng-container *ngIf="selectedItems$ | async as selectedItems">
        <ng-container *ngIf="selectedItems.length > 0 && groupActions.length > 0">
          <ng-container *ngFor="let groupAction of groupActions">
            <button
              (click)="groupAction.action($event.target)"
              type="button"
              pButton
              [ngClass]="{ 'p-button-primary': !groupAction.buttonClass }"
              [class]="groupAction.buttonClass"
              appearance="icon"
              [disabled]="groupAction.disabled"
              icon="{{ groupAction.icon }}"
              tooltipPosition="top"
              pTooltip="{{
                groupAction.tooltipTranslate || groupAction.labelTranslate
                  ? (groupAction.tooltipTranslate || groupAction.labelTranslate | translate)
                  : undefined
              }}"
            ></button>
          </ng-container>
        </ng-container>
      </ng-container>
      <div class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input
          class="search-input"
          style="width: 230px"
          pAutoFocus
          [autofocus]="true"
          [ngModel]="querySearch$ | async"
          [ngModelOptions]="{ standalone: true }"
          pInputText
          type="text"
          (input)="onSearch($event)"
          placeholder="{{ 'data-list-view.search.input-placeholder' | translate }}"
        />
      </div>
      <p-dropdown
        [options]="pageSizes"
        [ngModel]="componentData.pageParams.pageSize"
        (ngModelChange)="onPageChange({ pageSize: $event, pageNumber: 1 })"
      ></p-dropdown>
      <ng-container *ngFor="let action of toolbarActions">
        <button
          (click)="action.action()"
          type="button"
          pButton
          [ngClass]="{ 'p-button-primary': !action.buttonClass }"
          [class]="action.buttonClass"
          appearance="icon"
          icon="{{ action.icon }}"
          tooltipPosition="top"
          [disabled]="action.disabled"
          pTooltip="{{
            action.tooltipTranslate || action.labelTranslate
              ? (action.tooltipTranslate || action.labelTranslate | translate)
              : undefined
          }}"
        ></button>
      </ng-container>
      <ng-content [select]="slotSelectors.toolbarAction"></ng-content>

      <ng-container *ngIf="moreActions$ | async as moreActions">
        <ng-container *ngIf="moreActions.length">
          <button
            type="button"
            pButton
            class="p-button-primary"
            appearance="icon"
            (click)="menu.show($event)"
            icon="pi pi-ellipsis-v"
            tooltipPosition="top"
            pTooltip="{{ 'msg.menu-actions-more.button-tooltip' | translate }}"
          ></button>
          <p-menu #menu [model]="moreActions" [popup]="true"></p-menu>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
