<div class="ddp-area-filter ddp-flex">
  <div
    class="ddp-type-selectbox ddp-type-search-select"
    #ddpOffSet
    [ngClass]="{
      'ddp-del': searchText !== '',
      'ddp-selected': isShowSelectList && isShowSelectListForOutSide,
      'ddp-offset': viewType === 'widget'
    }"
    (click)="toggleSelectList($event)"
  >
    <ng-container *ngIf="noSelected">
      <span class="filter-view-string"> {{ 'msg.board.custom.ui.placeholder' | translate }}</span>
    </ng-container>
    <ng-container *ngIf="!noSelected">
      <!-- <ng-container *ngFor="let viewText of viewItems">
        <p-chip [label]="viewText"></p-chip>
      </ng-container> -->
      <span 
        class="filter-view-string" 
        [pTooltip]="viewItems ? viewItems.join(', ') : ''" 
        tooltipPosition="top"
      >
        {{ viewItems ? viewItems.join(', ') : '' }}
      </span>
    </ng-container>

    <input
      type="text"
      class="ddp-input-selectbox"
      [placeholder]="'msg.board.custom.ui.placeholder' | translate"
      [(ngModel)]="searchText"
      [disabled]="isMockup"
      (click)="onSearchClick($event)"
      (keyup)="onSearchKeyUp()"
    />
    <span style="cursor: pointer;" class="ddp-btn-del" (click)="clearSearch()"></span>
  </div>
  <p-overlay
    [(visible)]="overlayVisible"
    [responsive]="{ direction: 'bottom' }"
    (onShow)="onPopupOpen($event)"
    (onHide)="closeList()"
    [appendTo]="attachElement"
  >
    <div class="ddp-wrap-popup2 ddp-scroll-none">
      <div class="ddp-no-result" *ngIf="pageCandidateList === null || pageCandidateList.length === 0">
        <div class="ddp-wrap-info">
          <div class="ddp-ui-info">
            <em class="ddp-icon-info"></em>
            {{ 'msg.board.filter.ui.search-all' | translate }}
          </div>
        </div>
        <span class="ddp-txt-noresult">{{ 'msg.comm.ui.no.rslt' | translate }}</span>
      </div>

      <div *ngIf="filter.showSelectedItem || (pageCandidateList !== null && pageCandidateList.length > 0)">
        <div (click)="$event.stopPropagation()" class="ddp-select-setting">
          <ul class="ddp-list-select">
            <li *ngIf="isSelectorTypeMulti()">
              <a href="javascript:" (click)="checkAll()">{{ 'msg.comm.ui.list.sel.all' | translate }}</a>
            </li>
            <li>
              <a href="javascript:" (click)="deselectCandidate()">
                <span *ngIf="isSelectorTypeSingle()">{{ 'msg.comm.ui.list.desel' | translate }}</span>
                <span *ngIf="isSelectorTypeMulti()">{{ 'msg.comm.ui.list.desel.all' | translate }}</span>
              </a>
            </li>
          </ul>
          <span class="ddp-data-total">
            {{ 'msg.comm.ui.total' | translate }}
            <strong>{{ totalCount }}</strong>
          </span>
        </div>

        <ul
          class="ddp-list-popup"
          infinite-scroll
          [scrollWindow]="false"
          [infiniteScrollDistance]="5"
          [infiniteScrollThrottle]="150"
          (scrolled)="onScroll()"
        >
          <ng-container *ngFor="let item of pageCandidateList">
            <li [ngClass]="{ 'ddp-value-search': item.isTemporary }">
              <ng-container *ngIf="isSelectorTypeSingle()">
                <span>
                  <label class="ddp-label-radio">
                    <input type="radio" [checked]="isSelectedItem(item)" (change)="selectCandidateItem(item)" />
                    <i class="ddp-icon-radio"></i>
                    <span class="ddp-txt-radio">{{ isStringArray ? item : item[viewKey] }}</span>
                  </label>
                </span>
              </ng-container>

              <ng-container *ngIf="isSelectorTypeMulti()">
                <span>
                  <label class="ddp-label-checkbox">
                    <input type="checkbox" [checked]="isSelectedItem(item)" (change)="selectCandidateItem(item)" />
                    <i class="ddp-icon-checkbox"></i>
                    <span class="ddp-txt-checkbox">
                      {{
                        null === item.name || 'null' === item.name
                          ? '(Null)'
                          : '' === item.name || undefined === item.name
                          ? '(Empty)'
                          : item.name
                      }}
                    </span>
                  </label>
                </span>
              </ng-container>
            </li>
          </ng-container>
        </ul>

        <div class="ddp-data-result2">
          <div class="ddp-data-values">
            <label (click)="$event.stopImmediatePropagation()" class="ddp-label-check-value">
              <input
                (click)="filter.showSelectedItem = !filter.showSelectedItem; setCandidatePage(1, true)"
                [checked]="filter.showSelectedItem"
                type="checkbox"
              />
              <i></i>
            </label>
            <ng-container *ngIf="filter.showSelectedItem">
              {{ 'msg.board.filter.selected-values' | translate: { value: selectedItems.length } }}
            </ng-container>
            <ng-container *ngIf="!filter.showSelectedItem">
              {{ 'msg.board.filter.all-values' | translate }}
            </ng-container>
          </div>

          <div class="ddp-ui-page">
            <a
              (click)="$event.stopPropagation(); setCandidatePage(currentPage - 1)"
              [class.ddp-disabled]="1 === currentPage"
              href="javascript:"
              class="ddp-btn-prev"
            ></a>
            <span class="ddp-data-page">
              <em>{{ currentPage }}</em>
              /
              <em>{{ lastPage }}</em>
            </span>
            <a
              (click)="$event.stopPropagation(); setCandidatePage(currentPage + 1)"
              [class.ddp-disabled]="lastPage === currentPage"
              href="javascript:"
              class="ddp-btn-next"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </p-overlay>

  <a
    (click)="candidateFromSearchText()"
    class="ddp-btn-line"
    [ngStyle]="
      viewType === 'widget' && '' !== searchText && isShowSelectList ? { display: 'block' } : { display: 'none' }
    "
  >
    {{ 'msg.comm.ui.search-all' | translate }}
  </a>
</div>
