<div class="popup-dashboard-dataview ddp-pop-preview ddp-type">
  <div class="ddp-ui-top">
    <div *ngIf="isDashboard" class="ddp-wrap-source-name">
      <dashboard-datasource-combo
        [dataSources]="datasources"
        [initialValue]="mainDatasource"
        (selectOption)="selectDataSource($event)"
      ></dashboard-datasource-combo>
      <div class="ddp-txt-description">
        {{ "msg.storage.ui.title.data.detail" | translate }}
        <span class="ddp-txt-detail">
          -
          {{
            "msg.storage.ui.dsource.preview.usedin"
              | translate: { value: source.name }
          }}
        </span>
      </div>
    </div>

    <div *ngIf="!isDashboard" class="ddp-wrap-source-name">
      <div class="ddp-txt-description">{{ mainDatasource.name }}</div>
    </div>

    <em class="ddp-btn-popup-close" (click)="close()"></em>
  </div>

  <div class="ddp-ui-preview-contents ddp-type-preview ddp-pop-datadetail">
    <div class="ddp-type-top-option">
      <ul class="ddp-ui-tab ddp-type">
        <li [ngClass]="{ 'ddp-selected': isShowDataGrid }">
          <a href="javascript:" (click)="onChangeTab(true)">{{
            "msg.storage.ui.title.data.grid" | translate
          }}</a>
        </li>
        <li [ngClass]="{ 'ddp-selected': !isShowDataGrid }">
          <a href="javascript:" (click)="onChangeTab(false)">{{
            "msg.storage.ui.title.column.detail" | translate
          }}</a>
        </li>
        <li
          *ngIf="isDashboard"
          (click)="isShowInfoLayer = true"
          (clickOutside)="isShowInfoLayer = false"
        >
          <em class="ddp-icon-infotab"></em>

          <div
            class="ddp-box-layout4 ddp-information"
            [style.display]="isShowInfoLayer ? 'block' : 'none'"
            [ngStyle]="isJoin ? {} : { height: '200px' }"
          >
            <a
              href="javascript:"
              class="ddp-btn-close"
              (click)="isShowInfoLayer = false; $event.stopPropagation()"
            ></a>
            <div class="ddp-data-title">
              {{ "msg.storage.ui.title.board.data.info" | translate }}
            </div>
            <table class="ddp-table-info">
              <colgroup>
                <col width="95px" />
                <col width="" />
              </colgroup>
              <tbody>
                <tr *ngIf="mainDsSummary">
                  <th>
                    {{ "msg.storage.ui.dsource.preview.size" | translate }}
                  </th>
                  <td>{{ commonUtil.formatBytes(mainDsSummary.size, 1) }}</td>
                </tr>
                <tr *ngIf="mainDsSummary">
                  <th>
                    {{ "msg.storage.ui.dsource.preview.summary" | translate }}
                  </th>
                  <td>
                    {{ columns.length }}
                    {{
                      "msg.storage.ui.dsource.create.file.columns" | translate
                    }}
                    /
                    {{ commonUtil.numberWithCommas(mainDsSummary.count) }}
                    {{ "msg.storage.ui.dsource.preview.rows" | translate }}
                  </td>
                </tr>
                <tr>
                  <th>{{ "msg.comm.th.role" | translate }}</th>
                  <td>{{ getRoleStr() }}</td>
                </tr>
                <tr>
                  <th>{{ "msg.storage.ui.column.type" | translate }}</th>
                  <td>
                    <span
                      class="ddp-data-type"
                      *ngFor="let colType of colTypes"
                    >
                      <selfai-bi-shared-field-icon
                        [type]="colType.type"
                        [view]="'FIELD'"
                      ></selfai-bi-shared-field-icon>
                      {{ colType.cnt }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{
                      "msg.storage.ui.dsource.preview.datasource" | translate
                    }}
                  </th>
                  <td>{{ getDatasourceNames() }}</td>
                </tr>
                <tr *ngIf="isJoin">
                  <th>
                    {{ "msg.storage.ui.dsource.preview.join" | translate }}
                  </th>
                  <td>
                    {{ getDatasourceCnt() }}
                    {{
                      "msg.storage.ui.dsource.preview.datasources" | translate
                    }}
                  </td>
                </tr>
              </tbody>
            </table>

            <div *ngIf="isJoin" class="ddp-view-join">
              <span class="ddp-data-name">
                <em class="ddp-icon-database-w"></em>
                {{ mainDatasource.name }}
              </span>
              <ul class="ddp-list-join">
                <li *ngFor="let joinInfo of joinMappings">
                  <div class="ddp-clear">
                    <div class="ddp-data-joinname">
                      <em
                        [ngClass]="
                          {
                            LEFT_OUTER: 'ddp-icon-join-left',
                            RIGHT_OUTER: 'ddp-icon-join-right',
                            INNER: 'ddp-icon-join-inner',
                            FULL: 'ddp-icon-join-outer'
                          }[joinInfo.type]
                        "
                      ></em>
                      <span class="ddp-list-name">{{ joinInfo.name }}</span>
                    </div>
                    <div
                      *ngIf="joinInfo.join && joinInfo.join.id"
                      class="ddp-data-joinname ddp-2depth"
                    >
                      <em
                        [ngClass]="
                          {
                            LEFT_OUTER: 'ddp-icon-join-left',
                            RIGHT_OUTER: 'ddp-icon-join-right',
                            INNER: 'ddp-icon-join-inner',
                            FULL: 'ddp-icon-join-outer'
                          }[joinInfo.join.type]
                        "
                      ></em>
                      <span class="ddp-list-name">{{
                        joinInfo.join.name
                      }}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>

      <div *ngIf="isShowDataGrid" class="ddp-ui-rightoption ddp-type-option">
        <div
          class="ddp-box-btn2 ddp-box-selected"
          (click)="downloadData($event)"
        >
          <a href="javascript:" class="ddp-link-txt">
            <em class="ddp-icon-widget-gridsave"></em>
            Download
          </a>
        </div>
      </div>
    </div>

    <div class="ddp-wrap-tab-contents">
      <div class="ddp-ui-tab-contents">
        <div
          class="ddp-box-message ddp-type"
          *ngIf="isShowDataGrid && connType === 'LINK' && isExistDerivedField"
        >
          <div class="ddp-box-synch type-info">
            <div class="ddp-txt-synch">
              <em class="ddp-icon-info"></em>
              {{
                "msg.storage.ui.linked.source.preview.include.geo" | translate
              }}
            </div>
          </div>
        </div>
        <div
          *ngIf="isShowDataGrid && timestampField"
          class="ddp-wrap-grid-option ddp-top-option ddp-clear"
        >
          <component-period
            [title]="
              timestampField.alias ? timestampField.alias : timestampField.name
            "
            [useDefaultAllRange]="true"
            [startDateDefault]="mainDsSummary?.ingestionMinTime"
            [endDateDefault]="mainDsSummary?.ingestionMaxTime"
            [roundSecond]="true"
            [containerClass]="'ddp-fleft'"
            (changeDate)="onChangeDate($event)"
          ></component-period>
        </div>

        <div
          [class.ddp-setting]="isShowDataGrid && timestampField"
          class="ddp-wrap-grid-option ddp-setting ddp-clear"
        >
          <div class="ddp-fleft">
            <component-input
              [compType]="'search'"
              [value]="srchText"
              [showClear]="true"
              [placeHolder]="
                (isShowDataGrid
                  ? 'msg.storage.ui.search.data'
                  : 'msg.storage.ui.search.column'
                ) | translate
              "
              (changeValue)="searchData($event)"
            ></component-input>

            <div class="ddp-wrap-edit3">
              <label class="ddp-label-type">{{
                "msg.comm.th.role" | translate
              }}</label>

              <div class="ddp-ui-edit-option">
                <div class="ddp-ui-option-in">
                  <label class="ddp-label-radio">
                    <input
                      type="radio"
                      name="radioRole"
                      [checked]="selectedFieldRole === fieldRoleType.ALL"
                      (change)="changeFieldRole(fieldRoleType.ALL)"
                    />
                    <i class="ddp-icon-radio"></i>
                    <span class="ddp-txt-radio">{{
                      "msg.comm.ui.list.all" | translate
                    }}</span>
                  </label>
                  <label class="ddp-label-radio">
                    <input
                      type="radio"
                      name="radioRole"
                      [checked]="selectedFieldRole === fieldRoleType.DIMENSION"
                      (change)="changeFieldRole(fieldRoleType.DIMENSION)"
                    />
                    <i class="ddp-icon-radio"></i>
                    <span class="ddp-txt-radio">{{
                      "msg.comm.name.dim" | translate
                    }}</span>
                  </label>
                  <label class="ddp-label-radio">
                    <input
                      type="radio"
                      name="radioRole"
                      [checked]="selectedFieldRole === fieldRoleType.MEASURE"
                      (change)="changeFieldRole(fieldRoleType.MEASURE)"
                    />
                    <i class="ddp-icon-radio"></i>
                    <span class="ddp-txt-radio">{{
                      "msg.comm.name.mea" | translate
                    }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="ddp-form-label2">
              <label class="ddp-label-type">{{
                "msg.storage.ui.type" | translate
              }}</label>
              <div
                class="ddp-wrap-dropdown"
                (click)="isShowTypeComboOpts = !isShowTypeComboOpts"
                (clickOutside)="isShowTypeComboOpts = false"
              >
                <div
                  class="ddp-type-selectbox"
                  [ngClass]="{ 'ddp-selected': isShowTypeComboOpts }"
                >
                  <span class="ddp-txt-selectbox">
                    {{ selectedLogicalType.label }}
                  </span>
                  <div class="ddp-wrap-popup2 ddp-types">
                    <ul class="ddp-list-popup">
                      <li
                        [ngClass]="{
                          'ddp-selected':
                            selectedLogicalType.value === type.value
                        }"
                        (click)="onChangeLogicalType($event, type)"
                        *ngFor="let type of logicalTypes"
                      >
                        <a href="javascript:">
                          <selfai-bi-shared-field-icon
                            [type]="type.value"
                            [view]="'FIELD'"
                          ></selfai-bi-shared-field-icon>
                          {{ type.label }}
                          <em
                            class="ddp-icon-check"
                            *ngIf="selectedLogicalType.value === type.value"
                          ></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <a
              href="javascript:"
              class="ddp-btn-reset3"
              (click)="resetFilter()"
            ></a>
          </div>

          <div *ngIf="isShowDataGrid" class="ddp-fright">
            <div class="ddp-data-form">
              <input
                type="text"
                class="ddp-data-input"
                [ngModel]="rowNum"
                (keyup)="changeRowNum($event)"
                input-mask="number"
              />
              <strong *ngIf="downloadPreview"
                >/
                {{ commonUtil.numberWithCommas(downloadPreview.count) }}</strong
              >
              {{ "msg.storage.ui.row" | translate }}
            </div>
          </div>
        </div>

        <div
          grid-component
          #main
          [style.display]="isShowDataGrid ? 'block' : 'none'"
          [class.meta-grid]="isExistTimestampField()"
          [class.meta-grid-type]="isEnableSecondHeader()"
          [class.ddp-type]="timestampField"
          [class.ddp-info]="connType === 'LINK' && isExistDerivedField"
          (onHeaderRowCellRendered)="createMetaDataHeader($event)"
          class="ddp-ui-grid2"
        ></div>

        <div
          [style.display]="isShowDataGrid ? 'none' : 'block'"
          class="ddp-data-details"
        >
          <div class="ddp-wrap-sourcename">
            <ng-container *ngFor="let source of joinDataSources; let i = index">
              <div class="ddp-label-title">
                <em class="ddp-icon-database-b" *ngIf="i === 0"></em>
                <em class="ddp-icon-sub" *ngIf="i !== 0"></em>
                {{ source.name }}
                <span *ngIf="isExistMetaData(source)"
                  >({{ source.uiMetaData.name }})</span
                >
              </div>

              <div class="ddp-wrap-table">
                <table
                  class="ddp-table-form ddp-table-type2 ddp-table-view ddp-inherit"
                >
                  <thead>
                    <tr>
                      <th>{{ "msg.metadata.th.physical.name" | translate }}</th>
                      <th *ngIf="isExistMetaData(source)">
                        {{ "msg.metadata.th.name" | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      [ngClass]="{
                        'ddp-selected':
                          selectedField &&
                          selectedField.name === field.name &&
                          selectedField.dsId === field.dsId
                      }"
                      *ngFor="
                        let field of getColumnList(source.fields);
                        let i = index
                      "
                      (click)="onSelectedField(field, source)"
                    >
                      <td>
                        <div
                          class="ddp-wrap-value-icon"
                          *ngIf="!isExistMetaData(source)"
                        >
                          <div
                            class="ddp-wrap-datarecommend"
                            *ngIf="field.filtering"
                          >
                            {{ field.filteringSeq + 1 }}
                            <em class="ddp-icon-recommend-s"></em>
                          </div>

                          <em
                            class="ddp-icon-time"
                            *ngIf="field.role === 'TIMESTAMP'"
                          ></em>
                        </div>

                        <div class="ddp-td-schema">
                          <div class="ddp-type-icon">
                            <em
                              class="{{
                                field.role === 'MEASURE'
                                  ? getFieldMeasureTypeIconClass(
                                      field.logicalType
                                    )
                                  : getFieldDimensionTypeIconClass(
                                      field.logicalType
                                    )
                              }}"
                            ></em>
                          </div>

                          <div class="ddp-form-name">
                            <span
                              class="ddp-data-name"
                              title="{{ field.name }}"
                              >{{ field.name }}</span
                            >
                          </div>
                        </div>
                      </td>

                      <td *ngIf="isExistMetaData(source)">
                        <div class="ddp-wrap-value-icon">
                          <div
                            class="ddp-wrap-datarecommend"
                            *ngIf="field.filtering"
                          >
                            {{ field.filteringSeq + 1 }}
                            <em class="ddp-icon-recommend-s"></em>
                          </div>

                          <em
                            class="ddp-icon-time"
                            *ngIf="field.role === 'TIMESTAMP'"
                          ></em>
                        </div>

                        <div class="ddp-td-schema">
                          <div class="ddp-form-name ddp-type">
                            <span
                              class="ddp-data-name"
                              title="{{ field['logicalName'] }}"
                            >
                              {{ field["logicalName"] }}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </div>

          <div class="ddp-wrap-detail ddp-type" *ngIf="selectedField">
            <div class="ddp-ui-detail ddp-clear ddp-padt0">
              <div class="ddp-col-12">
                <div class="ddp-ui-title">
                  {{ "msg.storage.ui.column.information" | translate }}
                </div>
                <table class="ddp-table-detail2">
                  <colgroup>
                    <col width="175px" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        {{ "msg.metadata.th.physical.name" | translate }}
                      </th>
                      <td>
                        {{ selectedField.name }}
                      </td>
                    </tr>

                    <tr>
                      <th>
                        {{ "msg.storage.ui.th.role" | translate }}
                      </th>
                      <td>
                        <span
                          class="ddp-box-tag-value {{
                            getFieldRoleTypeIconClass(
                              selectedField.role.toString()
                            )
                          }}"
                        >
                          {{
                            (selectedField.role.toString() === "MEASURE"
                              ? "msg.comm.name.mea"
                              : "msg.comm.name.dim"
                            ) | translate
                          }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        {{ "msg.storage.ui.type" | translate }}
                      </th>
                      <td>
                        <span class="ddp-icon-type-txt">
                          <selfai-bi-shared-field-icon
                            [type]="selectedField.logicalType"
                            [view]="'FIELD'"
                          ></selfai-bi-shared-field-icon>
                          {{
                            getColumnTypeLabel(
                              selectedField.logicalType,
                              logicalTypes
                            )
                          }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="ddp-ui-detail ddp-clear">
              <div class="ddp-col-12">
                <div class="ddp-ui-title">
                  {{ "msg.storage.ui.column.settings" | translate }}
                </div>
                <table class="ddp-table-detail2">
                  <colgroup>
                    <col width="175px" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <ng-container
                      *ngIf="selectedField.logicalType === 'TIMESTAMP'"
                    >
                      <tr>
                        <th>
                          {{ "msg.storage.ui.th.display.format" | translate }}
                        </th>
                        <td>
                          {{
                            selectedField.format
                              ? selectedField.format.format
                              : selectedField.format
                          }}
                        </td>
                      </tr>
                      <tr *ngIf="isEnableTimezone(selectedField)">
                        <th>
                          {{ "msg.storage.ui.timezone" | translate }}
                        </th>
                        <td>
                          {{ getTimezoneLabelInColumn(selectedField.format) }}
                        </td>
                      </tr>
                    </ng-container>
                    <tr>
                      <th>
                        {{ "msg.storage.ui.th.missing" | translate }}
                      </th>
                      <td>
                        <ng-container *ngIf="!selectedField.ingestionRule">
                          {{ "msg.storage.btn.no.apply" | translate }}
                        </ng-container>
                        <ng-container
                          *ngIf="
                            selectedField.ingestionRule &&
                            selectedField.ingestionRule.type === 'replace'
                          "
                        >
                          {{ "msg.storage.ui.replace.with" | translate }}
                          {{
                            selectedField.ingestionRule.value !== ""
                              ? selectedField.ingestionRule.value
                              : "Empty string"
                          }}
                        </ng-container>
                        <ng-container
                          *ngIf="
                            selectedField.ingestionRule &&
                            selectedField.ingestionRule.type === 'discard'
                          "
                        >
                          {{ "msg.storage.btn.discard" | translate }}
                        </ng-container>
                      </td>
                    </tr>
                    <tr *ngIf="isEnableFiltering(selectedField)">
                      <th>
                        {{
                          (isLinkedTypeSource(selectedSource)
                            ? "msg.storage.btn.essential.filter"
                            : "msg.storage.btn.recommend.filter"
                          ) | translate
                        }}
                      </th>
                      <td>
                        <div class="ddp-wrap-datarecommend">
                          {{ selectedField.filteringSeq + 1 }}
                          <em class="ddp-icon-recommend-s"></em>
                        </div>
                        <ng-container *ngIf="selectedField.filteringOptions">
                          {{
                            (selectedField.filteringOptions.type.toString() ===
                            "TIME"
                              ? "msg.storage.btn.sel.recent.period"
                              : "msg.storage.btn.sel.single.item"
                            ) | translate
                          }}
                        </ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div
              class="ddp-ui-detail ddp-clear"
              *ngIf="isExistMetaData(selectedSource)"
            >
              <div class="ddp-col-12">
                <div class="ddp-ui-title">
                  {{ "msg.storage.ui.meta.data" | translate }}
                </div>
                <table class="ddp-table-detail2">
                  <colgroup>
                    <col width="175px" />
                    <col width="*" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        {{
                          "msg.metadata.th.dictionary.logical.name" | translate
                        }}
                      </th>
                      <td>
                        {{ selectedField.logicalName }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {{ "msg.metadata.th.dictionary" | translate }}
                      </th>
                      <td>
                        {{ selectedField.dictionary?.logicalName }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {{ "msg.storage.ui.th.code.table" | translate }}
                      </th>
                      <td>
                        {{ selectedField.codeTable?.name }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {{ "msg.storage.ui.th.description" | translate }}
                      </th>
                      <td>
                        {{ selectedField.description }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <ng-container
              *ngIf="
                !isLinkedTypeSource(selectedSource) && !isGeoType(selectedField)
              "
            >
              <div class="ddp-ui-detail ddp-clear">
                <div class="ddp-ui-title2">
                  {{ "msg.storage.ui.dsource.preview.statistic" | translate }}
                </div>

                <div class="ddp-col-6 ddp-border-none">
                  <table class="ddp-table-detail2">
                    <colgroup>
                      <col width="140px" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          {{ "msg.storage.ui.th.row.count" | translate }}
                        </th>
                        <td>
                          {{ getLabel("count") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th class="ddp-th-valid">
                          {{
                            "msg.storage.ui.dsource.preview.valid" | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("valid") }}
                        </td>
                      </tr>
                      <tr
                        class="ddp-tr-sub"
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.unique" | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("unique") }}
                        </td>
                      </tr>
                      <tr
                        class="ddp-tr-sub"
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.outliers"
                              | translate
                          }}
                          <em class="ddp-icon-error-s"></em>
                        </th>
                        <td>
                          {{ getLabel("outliers") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th class="ddp-th-missing">
                          {{
                            "msg.storage.ui.dsource.preview.missing" | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("missing") }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="ddp-col-6"
                  *ngIf="
                    selectedField.role.toString() === 'MEASURE' ||
                    selectedField.logicalType.toString() === 'TIMESTAMP'
                  "
                >
                  <table class="ddp-table-detail2">
                    <colgroup>
                      <col width="140px" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <th>
                          {{ "msg.storage.ui.dsource.preview.min" | translate }}
                        </th>
                        <td>
                          {{ getLabel("min") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.quartile.lower"
                              | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("lower") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.median" | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("median") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.quartile.upper"
                              | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("upper") }}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {{ "msg.storage.ui.dsource.preview.max" | translate }}
                        </th>
                        <td>
                          {{ getLabel("max") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{ "msg.storage.ui.dsource.preview.avr" | translate }}
                        </th>
                        <td>
                          {{ getLabel("average") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.deviation"
                              | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("standard") }}
                        </td>
                      </tr>
                      <tr
                        *ngIf="
                          selectedField.logicalType.toString() !== 'TIMESTAMP'
                        "
                      >
                        <th>
                          {{
                            "msg.storage.ui.dsource.preview.skewness"
                              | translate
                          }}
                        </th>
                        <td>
                          {{ getLabel("skewness") }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="ddp-ui-detail ddp-clear"
                *ngIf="
                  selectedField.role.toString() === 'DIMENSION' &&
                  selectedField.logicalType.toString() !== 'TIMESTAMP'
                "
              >
                <div class="ddp-col-12">
                  <div class="ddp-ui-title">
                    {{ "msg.storage.ui.dsource.preview.value.li" | translate }}
                  </div>
                  <table class="ddp-table-detail2">
                    <colgroup>
                      <col width="175px" />
                      <col width="*" />
                    </colgroup>
                    <tbody>
                      <tr *ngFor="let item of getValueList()">
                        <th>
                          {{ item.value }}
                        </th>
                        <td>
                          {{ item.count }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<data-download></data-download>
