import { Injectable } from '@angular/core';
import { downloadJson } from '@selfai-platform/shared';
import { AggregatorRouteListService } from './aggregator-camel-list.service';
import { AggregatorItemService } from './aggregator-item.service';
import { AggregatorAdapter } from './api';

@Injectable()
export class AggregatorExportService {
  constructor(
    private readonly aggregatorRouteListService: AggregatorRouteListService,
    private readonly aggregatorAdapter: AggregatorAdapter,
    private readonly aggregatorItemService: AggregatorItemService,
  ) {}

  exportAllRoutes(): void {
    const data = this.aggregatorRouteListService.getRoutesData();

    downloadJson(data, 'routes.json');
  }

  exportRoute(uuid: string): void {
    const data = this.aggregatorItemService.getRouteById(uuid)?.value;
    if (data) {
      downloadJson(data, `${data.name}.json`);
    }
  }

  exportProcess(uuid: string): void {
    this.aggregatorAdapter.loadProcessWithRoutes(uuid).subscribe((process) => {
      downloadJson(process, `${process.process.processName}.json`);
    });
  }

  exportAllProcesses(): void {
    this.aggregatorAdapter.loadProcessListFull().subscribe((processes) => {
      downloadJson(processes, 'processes.json');
    });
  }
}
