import { FilterPathNode, FilterPathRootNode } from '../models';

import { getNodes } from './get-nodes';

export function getFilterPathNodesByDsId(fiterPath: FilterPathRootNode, dsId: string): FilterPathNode[] {
  const result: FilterPathNode[] = [];

  const allNodes: FilterPathNode[] = getNodes(fiterPath);
  let nodeDsId = allNodes.find((node) => node.datasourceId === dsId);
  if (!nodeDsId) {
    return result;
  }
  const startdsId = fiterPath.datasourceId;
  let currentDsId = dsId;

  do {
    result.push(nodeDsId);
    currentDsId = nodeDsId.parentDsId;
    nodeDsId = allNodes.find((node) => node.datasourceId === currentDsId);
  } while (nodeDsId && nodeDsId.datasourceId && nodeDsId.datasourceId !== startdsId);

  return result;
}
