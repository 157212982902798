
import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { isNullOrUndefined } from '@selfai-platform/shared';

import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';
import { Page } from '../../domain/common/page';
import { Criteria } from '../../domain/datasource/criteria';

@Injectable()
export class DataconnectionService extends AbstractService {
  private URL_CONNECTIONS = this.API_URL + 'connections';

  constructor(protected injectior: Injector) {
    super(injectior);
  }

  public getDataconnections(param: any, projection: string = 'list'): Promise<any[]> {
    let url = this.API_URL + `connections`;

    if (param.hasOwnProperty('implementor') && param.hasOwnProperty('name')) {
      url += '/search/nameAndimplementor';
    } else if (param.hasOwnProperty('implementor')) {
      url += '/search/implementor';
    } else if (param.hasOwnProperty('name')) {
      url += '/search/name';
    }

    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }

    return this.get(url + '&projection=' + projection);
  }

  public getAllDataconnections(param: any, projection: string = 'list'): Promise<any[]> {
    let url = this.API_URL + `connections`;

    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }

    return this.get(url + '&projection=' + projection);
  }

  public getDataconnectionDetail(connectionId: string, projection: string = 'default'): Promise<any> {
    const url = this.API_URL + `connections/${connectionId}`;

    return this.get(url + '?projection=' + projection);
  }

  public getDatabases(connectionId: string, page?: Page, searchName?: string): Promise<any> {
    let url: string = this.API_URL + `connections/${connectionId}/databases`;
    if (page || searchName) {
      const param: any = {};

      if (page) {
        param.sort = page.sort;
        param.page = page.page;
        param.size = page.size;
      }
      searchName && (param.databaseName = searchName);

      url += '?' + CommonUtil.objectToUrlString(param);
    }
    return this.get(url);
  }

  public getDatabaseListInConnection(connectionId: string, params: any): Promise<any> {
    let url: string = this.API_URL + `connections/${connectionId}/databases`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getTableListInConnection(connectionId: string, databaseName: string, params: any): Promise<any> {
    let url: string = this.API_URL + `connections/${connectionId}/databases/${databaseName}/tables`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getTableListInConnectionQuery(dataconnection: any, param: any): Promise<any> {
    let url: string = this.API_URL + `connections/query/tables`;
    if (param) {
      url += '?' + CommonUtil.objectToUrlString(param);
    }
    const params: any = {};
    const connInfo: any = {};
    connInfo.implementor = dataconnection.implementor;

    if (connInfo.authenticationType != 'USERINFO') {
      connInfo.username = dataconnection.username;
      connInfo.password = dataconnection.password;
    }

    connInfo.authenticationType = dataconnection.authenticationType;
    connInfo.hostname = dataconnection.hostname;
    connInfo.port = dataconnection.port;
    connInfo.database = dataconnection.connectionDatabase;
    connInfo.sid = dataconnection.sid;
    connInfo.catalog = dataconnection.catalog;
    connInfo.url = dataconnection.url;

    if (!isNullOrUndefined(dataconnection.properties)) {
      connInfo.properties = dataconnection.properties;
    }

    params.connection = connInfo;
    params.database = dataconnection.database;
    params.table = param.tableName;

    return this.post(url, params);
  }

  public getDatabasesWithoutId(param: any): Promise<any> {
    return this.post(this.API_URL + 'connections/query/databases', param);
  }

  public getTables(connectionId: string, databaseName: string, page?: Page): Promise<any> {
    let url: string = this.API_URL + `connections/${connectionId}/databases/${databaseName}/tables`;
    if (page) {
      const param: any = {};

      if (page) {
        param.sort = page.sort;
        param.page = page.page;
        param.size = page.size;
      }

      url += '?' + CommonUtil.objectToUrlString(param);
    }
    return this.get(url);
  }

  public getTablesWitoutId(param: any): Promise<any> {
    return this.post(this.API_URL + 'connections/query/tables?size=5000', param);
  }

  public getTableDetailWitoutId(param: any, extractColumnName: boolean, limit: number = 50): Promise<any> {
    return this.post(
      this.API_URL + `connections/query/data?extractColumnName=${extractColumnName}&limit=${limit}`,
      param,
    );
  }

  public checkConnection(param: any): Promise<any> {
    return this.post(this.API_URL + 'connections/query/check', param);
  }

  public createConnection(param: any): Promise<any> {
    return this.post(this.API_URL + 'connections', param);
  }

  public updateConnection(connectionId: string, param: any): Promise<any> {
    return this.patch(this.API_URL + `connections/${connectionId}`, param);
  }

  public addConnectionWorkspaces(connectionId: string, param: any): Promise<any> {
    const connIds = param
      .map((id) => {
        return '/api/workspaces/' + id;
      })
      .join('\n');
    return this.patch(this.API_URL + `connections/${connectionId}/workspaces`, connIds, 'text/uri-list');
  }

  public deleteConnectionWorkspaces(connectionId: string, param: any): Promise<any> {
    const connIds = param
      .map((connection) => {
        return connection;
      })
      .join(',');
    return this.delete(this.API_URL + `connections/${connectionId}/workspaces/${connIds}`);
  }

  public deleteConnection(connectionId: string): Promise<any> {
    return this.delete(this.API_URL + `connections/${connectionId}`);
  }

  public getColumnList(
    connectionId: string,
    databaseName: string,
    tableName: string,
    columnNamePattern: string,
    webSocketId: string,
    page: Page,
  ) {
    let url =
      this.API_URL +
      `connections/${connectionId}/databases/${databaseName}/tables/${tableName}/columns?columnNamePattern=${columnNamePattern}&webSocketId=${webSocketId}`;
    url += '&' + CommonUtil.objectToUrlString(page);
    return this.get(url);
  }

  public getTableInfomation(
    connectionId: string,
    databaseName: string,
    tableName: string,
    webSocketId: string,
    page: Page,
  ) {
    let url =
      this.API_URL +
      `connections/${connectionId}/databases/${databaseName}/tables/${tableName}/information?webSocketId=${webSocketId}`;
    url += '&' + CommonUtil.objectToUrlString(page);
    return this.get(url);
  }

  public setDatabaseShema(connection: string, databaseName: string, webSocket: string) {
    const params = {
      webSocketId: webSocket,
    };
    const url = this.API_URL + `connections/${connection}/databases/${databaseName}/change`;
    return this.post(url, params);
  }

  public getDatabaseForHive(): Promise<any> {
    return this.post(this.API_URL + 'connections/query/hive/databases', null);
  }

  public getTableForHive(databaseName: string): Promise<any> {
    const params = {
      database: databaseName,
    };
    return this.post(this.API_URL + 'connections/query/hive/tables?size=5000', params);
  }

  public getTableDataForHive(params, extractColumnName: boolean = false) {
    return this.post(`${this.API_URL}connections/query/hive/data?extractColumnName=${extractColumnName}`, params);
  }

  public getSchemaListWithCancel(param): Observable<any> {
    return this.postObservable(this.URL_CONNECTIONS + '/query/databases', param);
  }

  public getTableListWitchCancel(params): Observable<any> {
    return this.postObservable(this.URL_CONNECTIONS + '/metadata/tables/jdbc', params);
  }

  public getTableDetailDataWithCancel(param: any, extractColumnName: boolean = false): Observable<any> {
    return this.postObservable(this.API_URL + 'connections/query/data?extractColumnName=' + extractColumnName, param);
  }

  public getSchemaListForHiveWithCancel(): Observable<any> {
    return this.postObservable(this.URL_CONNECTIONS + '/query/hive/databases', null);
  }

  public getTableListForHiveInMetadataWithCancel(databaseName: string): Observable<any> {
    return this.postObservable(this.URL_CONNECTIONS + '/metadata/tables/stage', { database: databaseName });
  }

  public getTableDetailDataForHiveWithCancel(params, extractColumnName: boolean = false): Observable<any> {
    return this.postObservable(
      this.URL_CONNECTIONS + '/query/hive/data?extractColumnName=' + extractColumnName,
      params,
    );
  }

  public partitionValidationForStagingDB(params: any): Promise<any> {
    return this.post(this.URL_CONNECTIONS + '/query/hive/partitions/validate', params);
  }

  public isStrictModeForStagingDB(): Promise<any> {
    return this.get(this.URL_CONNECTIONS + '/query/hive/partitions/enable');
  }

  public getCriterionListInConnection() {
    return this.get(this.API_URL + 'connections/criteria');
  }

  public getCriterionInConnection(criterionKey: Criteria.ListCriterionKey) {
    return this.get(this.API_URL + `connections/criteria/${criterionKey}`);
  }

  public getConnectionList(
    page: number,
    size: number,
    sort: string,
    params: any,
    projection: string = 'list',
  ): Promise<any> {
    return this.post(
      this.API_URL + `connections/filter?projection=${projection}&page=${page}&size=${size}&sort=${sort}`,
      params,
    );
  }

  public getEnabledConnectionTypes(): Promise<any> {
    return this.get(this.URL_CONNECTIONS + '/connections/types');
  }
}
