import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AggregatorRoute } from '@selfai-platform/pipeline-common';
import { Observable, map, of } from 'rxjs';
import { AggregatorProcessListService } from '../../../services';
import { AggregatorRouteListService } from '../../../services/aggregator-camel-list.service';

type Item = Pick<AggregatorRoute, 'name' | 'uuid'>;

@Component({
  selector: 'selfai-platform-step-other-process',
  templateUrl: './step-other-process.component.html',
  styleUrls: ['./step-other-process.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepOtherProcessComponent implements OnInit {
  @Input()
  form!: UntypedFormGroup;
  @Input() activeItemId!: string;

  toItems$: Observable<Item[]> = of([]);

  constructor(
    private readonly aggregatorProcessListService: AggregatorProcessListService,
    private readonly aggregatorRouteListService: AggregatorRouteListService,
  ) {}

  ngOnInit(): void {
    this.aggregatorProcessListService.loadProcessList();

    // exclude active process from the items
    this.toItems$ = this.aggregatorProcessListService
      .getProcessList()
      .pipe(
        map((processList) =>
          processList
            .filter(({ uuid }) => uuid !== this.aggregatorRouteListService.process.uuid)
            .map(({ processName, uuid }) => ({ name: processName, uuid })),
        ),
      );
  }
}
