<form class="pt-4" [formGroup]="form">
  <ng-container>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.googleSpreadsheetId" />
      <label>Google Spreadsheet ID / URL to Google Spreadsheet</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.googleServiceAccountCredentials" />
      <label>Google service account credentials JSON</label>
    </div>
    <div class="mb-5">
      <label class="cursor-pointer">
        <p-checkbox class="mr-2" [formControl]="paramsForm.controls.includeHeader" [binary]="true"></p-checkbox>
        First row includes column names
      </label>
    </div>
    <div class="mb-5">
      <label class="cursor-pointer">
        <p-checkbox class="mr-2" [formControl]="paramsForm.controls.convert01ToBoolean" [binary]="true"></p-checkbox>
        Convert 0 and 1 to boolean
      </label>
    </div>
  </ng-container>
  <div class="mb-5">
    <selfai-platform-data-source-visibility
      [control]="form.controls.visibility"
    ></selfai-platform-data-source-visibility>
  </div>
  <div class="mb-5">
    <selfai-platform-data-source-unique-name [control]="form.controls.name"></selfai-platform-data-source-unique-name>
  </div>

  <selfai-platform-data-sources-additionals
    [properties]="dataSource?.params?.properties"
    [additionalList]="additionalList"
    (changeEvent)="onPropertiesValue($event)"
  ></selfai-platform-data-sources-additionals>
</form>
