import { EntityMetadataMap } from '@ngrx/data';
import { AGGREGATOR_PROCESS_LIST_ENTITY_NAME } from '../consts';

const entityMetadata: EntityMetadataMap = {
  [AGGREGATOR_PROCESS_LIST_ENTITY_NAME]: {},
};

export const pipelineEntityConfig = {
  entityMetadata,
};
