<div class="version-manager-content">
  <p-overlayPanel #op>
    <ng-template pTemplate>
      <form [formGroup]="form" (ngSubmit)="saveNewVersionProcess()" class="desctiption-form">
        <div class="field">
          <input
            type="text"
            pInputText
            class="p-inputtext-sm"
            pAutoFocus
            [autofocus]="true"
            required
            formControlName="description"
            placeholder="Type the comment for snapshot"
          />
          <small *ngIf="form.controls.description.invalid" class="p-error block">Description is required</small>
        </div>
        <button pButton pRipple type="submit" label="Save version"></button>
      </form>
    </ng-template>
  </p-overlayPanel>
  <button pButton pRipple class="mb-3" type="button" label="Save version" (click)="op.toggle($event)"></button>
  <p-table [value]="(versions$ | async) || []" [scrollable]="true" scrollHeight="450px" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th>View</th>
        <th>Version</th>
        <th>Description</th>
        <th>Creation date</th>
        <th>Author</th>
        <th>Actions</th>
        <th>Branch</th>
        <th>Git Actions</th>
        <th>Tags</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-eye"
            class="p-button-rounded p-button-text"
            (click)="showVersion(item)"
          ></button>
        </td>
        <td>{{ item.versionNumber }}</td>
        <td>{{ item.description }}</td>
        <td>{{ item.created | date: 'dd.MM.yyyy HH:mm' }}</td>
        <td>{{ item.authorTitle }}</td>
        <td>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-directions"
            class="p-button-rounded p-button-success p-button-text"
            (click)="restoreVersion($event, item)"
            pTooltip="Restore"
            tooltipPosition="bottom"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-file-export"
            class="p-button-rounded p-button-text"
            (click)="exportVersion($event, item)"
            pTooltip="Export"
            tooltipPosition="bottom"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-copy"
            class="p-button-rounded p-button-text"
            (click)="cloneAsWorkflow($event, item)"
            pTooltip="Clone"
            tooltipPosition="bottom"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="removeVersion($event, item)"
            pTooltip="Remove"
            tooltipPosition="bottom"
          ></button>
        </td>
        <td>
          <button
            pButton
            pRipple
            [disabled]="true"
            type="button"
            icon="pi pi-plus-circle"
            class="p-button-rounded p-button-text"
            pTooltip="Add branch"
            tooltipPosition="bottom"
          ></button>
          <span class="branch-name">master</span>
        </td>
        <td>
          <button
            pButton
            pRipple
            [disabled]="true"
            type="button"
            icon="pi pi-share-alt"
            class="p-button-rounded p-button-text"
            pTooltip="Merge"
            tooltipPosition="bottom"
          ></button>
          <button
            pButton
            pRipple
            [disabled]="true"
            type="button"
            icon="pi pi-sort-alt"
            class="p-button-rounded p-button-text"
            pTooltip="Compare"
            tooltipPosition="bottom"
          ></button>
        </td>
        <td>
          <button
            pButton
            pRipple
            [disabled]="true"
            type="button"
            icon="pi pi-plus-circle"
            class="p-button-rounded p-button-text"
            pTooltip="Add tag"
            tooltipPosition="bottom"
          ></button>
          <p-chip label="default" icon="pi pi-tag"></p-chip>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-confirmPopup></p-confirmPopup>
</div>
