import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FieldIconComponent } from './field-icon/field-icon.component';
import { PageIsReadyInterceptor } from './page-is-ready';

@NgModule({
  declarations: [FieldIconComponent],
  imports: [CommonModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PageIsReadyInterceptor,
      multi: true,
    },
  ],
  exports: [FieldIconComponent],
})
export class BiSharedModule {}
