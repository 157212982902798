import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DataListViewComponent, DataListViewTemplateNameDirective } from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TooltipModule } from 'primeng/tooltip';
import { WorkflowListComponent } from './workflow-list.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    DataListViewComponent,
    DataListViewTemplateNameDirective,
    ConfirmDialogModule,
    TooltipModule,
    RouterModule.forChild([
      {
        path: '',
        component: WorkflowListComponent,
      },
    ]),
  ],
  providers: [ConfirmationService],
  declarations: [WorkflowListComponent],
  exports: [WorkflowListComponent],
})
export class WorkflowListComponentModule {}
