import { LayoutSettings, LayoutDimensions, createLayoutSettings, createLayoutDimensions } from "../layout";

import { DashboardLayoutContentSettings } from "./dashboard-layout-content-settings";

export interface DashboardLayout {
    settings: LayoutSettings;
    dimensions: LayoutDimensions;
    content: DashboardLayoutContentSettings[];
  }
  
  export function createDashboardLayout(dashboardLayoutOptions: Partial<DashboardLayout> = {}): DashboardLayout {
    return {
      settings: createLayoutSettings(),
      dimensions: createLayoutDimensions(),
      content: [],
      ...dashboardLayoutOptions
    }
  }