import { Injectable, Injector } from '@angular/core';

import * as _ from 'lodash';

import { AbstractService } from '../../../common/service/abstract.service';
import { CommonUtil } from '../../../common/util/common.util';

@Injectable()
export class MetadataService extends AbstractService {
  private readonly URL_METADATA = this.API_URL + 'metadatas';

  metadataDetailSelectedTab = 'information';

  constructor(protected injector: Injector) {
    super(injector);
  }

  public createMetaData(params: object): Promise<any> {
    return this.post(this.URL_METADATA + '/batch', params);
  }

  public getCreatorDetail(userName: string) {
    return this.get(this.URL_METADATA + `/datacreators/${userName}`);
  }

  public getDuplicatedMetadataNameList(params: string[]): Promise<string[]> {
    return this.post(this.URL_METADATA + '/name/duplicated', params);
  }

  public getDetailMetaData(metaDataId: string, projection: string = 'forDetailView'): Promise<any> {
    return this.get(this.URL_METADATA + `/${metaDataId}?projection=${projection}`);
  }

  public getMetaDataList(params: object, projection: string = 'forListView'): Promise<any> {
    let url: string = this.URL_METADATA;

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }

    return this.get(url + `&projection=${projection}`);
  }

  public getMetadataListByDataCreator(
    username: string,
    params: object,
    projection: string = 'forListView',
  ): Promise<any> {
    let url: string = this.URL_METADATA + `/datacreators/${username}/metadatas`;

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }

    return this.get(url + `&projection=${projection}`);
  }

  public deleteMetaData(id: string): Promise<any> {
    return this.delete(this.URL_METADATA + `/${id}`);
  }

  public updateMetadata(id: string, params: any): Promise<any> {
    return this.patch(this.URL_METADATA + `/${id}`, params);
  }

  public getDuplicateMetaDataName(name: string): Promise<any> {
    return this.get(this.URL_METADATA + `/name/${name}/duplicated`);
  }

  public getColumnSchemaListInMetaData(metaDataId: string, projection: string = 'forListView'): Promise<any> {
    return this.get(this.URL_METADATA + `/${metaDataId}/columns?projection=${projection}`);
  }

  public updateColumnSchema(metaDataId: string, params: any): Promise<any> {
    return this.patch(this.URL_METADATA + `/${metaDataId}/columns`, params);
  }

  public linkMetadataWithCatalog(metadataId, catalogId): Promise<any> {
    const catId = this.API_URL + 'catalogs/' + catalogId;
    return this.patch(this.URL_METADATA + `/${metadataId}/catalogs`, catId, 'text/uri-list');
  }

  public deleteCatalogLinkFromMetadata(metadataId, catalogId): Promise<any> {
    return this.delete(this.URL_METADATA + `/${metadataId}/catalogs/${catalogId}`);
  }

  public addTagToMetadata(metadataId, params): Promise<any> {
    return this.post(this.URL_METADATA + `/${metadataId}/tags/attach`, params);
  }

  public deleteTagFromMetadata(metadataId, params): Promise<any> {
    return this.post(this.URL_METADATA + `/${metadataId}/tags/detach`, params);
  }

  public getMetadataTags(): Promise<any> {
    return this.get(this.URL_METADATA + `/tags`);
  }

  public getPopularityTags(params: {
    scope?: string;
    domainType?: string;
    nameContains?: string;
    size?: number;
  }): Promise<any> {
    let url: string = this.API_URL + `/tags/popularity`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getMetadataSampleData(metadataId: string, limit: number = 50) {
    return this.get(this.URL_METADATA + '/' + metadataId + '/data?limit=' + limit);
  }

  public getDownloadMetadataSampleData(metadataId: string, fileName: string, limit: number = 50) {
    return this.get(this.URL_METADATA + '/' + metadataId + '/data/download?limit=' + limit + '&fileName=' + fileName);
  }

  public getMetadataForDataSource(
    sourceId: string,
    projection: 'forItemView' | 'forDetailView' = 'forItemView',
  ): Promise<any> {
    return this.post(
      this.URL_METADATA + `/metasources/${sourceId}?projection=${_.isNil(projection) ? 'forItemView' : projection}`,
      {},
    );
  }

  public getMetadataByConnection(
    connId: string,
    schemaName: string,
    tableName: object,
    projection: string = 'forItemView',
  ): Promise<any> {
    const param = {
      schema: schemaName,
      table: tableName,
    };
    return this.post(this.URL_METADATA + `/metasources/${connId}?projection=${projection}`, param);
  }

  public getMetadataSourceTypeCount() {
    return this.get(this.URL_METADATA + '/statistics/count/sourcetype');
  }

  public getMetadataListByPopularity(params) {
    let url: string = this.URL_METADATA + '/popularity';

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getMetadataListByMyFavorite(params, projection: string = 'forListView') {
    let url: string = this.URL_METADATA + '/favorite/my';

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url + `&projection=${projection}`);
  }

  public getCreatorList(params) {
    let url: string = this.URL_METADATA + '/datacreators';

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getMetadataListByFavoriteCreator(params) {
    let url: string = this.URL_METADATA + '/datacreators';

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getFavoriteCreatorList(params) {
    let url: string = this.URL_METADATA + '/datacreators';

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
  }

  public getMetadataListByRecommended(params) {
    let url: string = this.URL_METADATA + '/recommended';

    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getTopUserInMetadataDetail(metaDataId: string): Promise<any> {
    return this.get(this.URL_METADATA + `/${metaDataId}/users/frequency`);
  }

  public getRecentlyUpdatedInMetadataDetail(metaDataId: string): Promise<any> {
    return this.get(this.URL_METADATA + `/${metaDataId}/history`);
  }

  public getRecentlyUsedInMetadataDetail(metaDataId: string, params?): Promise<any> {
    let url: string = this.URL_METADATA + `/${metaDataId}/related?`;

    if (params) {
      url += CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getRecentlyQueriesInMetadataDetailForDatabase(
    dataConnectionId: string,
    page: number,
    size: number,
    sort: string,
  ): Promise<any> {
    return this.get(this.API_URL + `queryhistories?sort=${sort}&size=${size}&dataConnectionId=${dataConnectionId}`);
  }

  public getMetadataTagList(projection: string, params?): Promise<any> {
    let url: string = this.URL_METADATA + `/tags?projection=${projection}`;

    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public toggleMetadataFavorite(id: string, isFavorite: boolean) {
    let url: string = this.URL_METADATA + `/${id}/favorite/`;

    if (isFavorite) {
      url += 'detach';
    } else {
      url += 'attach';
    }
    return this.post(url, null);
  }

  public toggleCreatorFavorite(id: string, isFavorite: boolean) {
    let url: string = this.URL_METADATA + `/datacreators/${id}/favorite/`;

    if (isFavorite) {
      url += 'detach';
    } else {
      url += 'attach';
    }
    return this.post(url, null);
  }

  public isShowLineage(): Promise<any> {
    return this.get(this.API_URL + `extensions/lineage`);
  }

  public onSelectMetadataDetailTab(selectedTab: string) {
    this.metadataDetailSelectedTab = selectedTab;
  }
}
