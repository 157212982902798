import { Injectable } from '@angular/core';
import { PageParams } from '@selfai-platform/shared';
import { Observable } from 'rxjs';
import { SearchQueryResponse } from '../../associations';
import { Datasource, SearchQueryRequest } from '../models';
import { DatasourceList } from '../models/datasource-list.model';
import { DatasourceDomainApiService } from './datasource-domain-api.service';

@Injectable({ providedIn: 'root' })
export class DatasourceDomainService {
  constructor(private readonly datasourceDomainApiService: DatasourceDomainApiService) {}

  loadDatasourceList(workspaceId: string, pageParams?: PageParams): Observable<DatasourceList> {
    return this.datasourceDomainApiService.loadDatasourceList(workspaceId, pageParams);
  }

  loadDatasourceDetail(
    datasourceId: string,
    queryParams: { includeUnloadedField?: boolean } = {},
  ): Observable<Datasource> {
    return this.datasourceDomainApiService.loadDatasource(datasourceId, queryParams);
  }

  searchQuery(query: SearchQueryRequest): Observable<SearchQueryResponse> {
    return this.datasourceDomainApiService.searchQuery(query);
  }
}
