/**
 * @returns date format yyyy-mm-dd
 */
export function formatToShortIso(data: Date): string {
  return `${getNumberWithLeadZero(data.getDate())}-${getNumberWithLeadZero(data.getMonth() + 1)}-${data.getFullYear()}`;
}

/**
 * @returns date format dd.mm.yyyy
 */
export function formatToRussianFormat(date: Date): string {
  return `${getNumberWithLeadZero(date.getDate())}.${getNumberWithLeadZero(date.getMonth() + 1)}.${date.getFullYear()}`;
}

function getNumberWithLeadZero(number: number): string {
  return ('0' + number).slice(-2);
}
