<div class="ddp-association">
  <span class="ddp-label">{{ 'msg.board.create.association-key' | translate }}</span>

  <div class="ddp-box-data">
    <div class="ddp-bar"></div>
    <div class="ddp-box-name ddp-fleft">
      {{ relation.ui.source.name }}
      <span *ngIf="relation.ui.sourceField">
        |
        <strong>{{ relation.ui.sourceField.name }}</strong>
      </span>
    </div>
    <div class="ddp-box-name ddp-fright">
      {{ relation.ui.target.name }}
      <span *ngIf="relation.ui.targetField">
        |
        <strong>{{ relation.ui.targetField.name }}</strong>
      </span>
    </div>
  </div>

  <div class="ddp-buttons">
    <a (click)="editRelation()" href="javascript:" class="ddp-btn-delete-b"></a>
    <a (click)="deleteRelation()" href="javascript:" class="ddp-btn-edit-b"></a>
  </div>
</div>
