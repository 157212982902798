import { EntityMetadataMap } from '@ngrx/data';
import { getIdContextKey } from '@selfai-platform/shared';
import { DASHBOARD_ENTITY_NAME, WIDGET_DASHBOARD_ENTITY_NAME } from '../const';
import { WidgetDashboardState } from '../models';

const entityMetadata: EntityMetadataMap = {
  [WIDGET_DASHBOARD_ENTITY_NAME]: {
    selectId: (entity: WidgetDashboardState) => getIdContextKey(entity.widgetId, entity.contextId),
  },

  [DASHBOARD_ENTITY_NAME]: {},
};

export const biDomainDashboardEntityConfig = {
  entityMetadata,
};
