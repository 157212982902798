import { Injectable } from '@angular/core';
import { MainDashboardApiService } from '@selfai-platform/bi-api';
import { map, Observable } from 'rxjs';
import { Dashboard } from '../models';
import { normalizeDashboardApiModelToDashboard } from '../normalizers';

@Injectable({ providedIn: 'root' })
export class MainDashboardService {
  constructor(private readonly mainDashboardApiService: MainDashboardApiService) {}

  setMainDashboard(dashboardId: string): Observable<void> {
    return this.mainDashboardApiService.setMainDashboard(dashboardId);
  }

  loadMainDashboard(): Observable<Dashboard> {
    return this.mainDashboardApiService.getMainDashboard().pipe(map(normalizeDashboardApiModelToDashboard));
  }
}
