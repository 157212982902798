import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUrlGenerator } from '@ngrx/data';
import { PipelineConfigService } from '@selfai-platform/shared';
import { map, Observable, tap } from 'rxjs';
import { WorkflowPresetsInterface } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsApiService {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/${this.config.versions.url}/presets`;

  constructor(private readonly http: HttpClient, private pipelineConfigService: PipelineConfigService) {}

  getAllPresets(): Observable<WorkflowPresetsInterface[]> {
    return this.http.get<WorkflowPresetsInterface[]>(`${this.apiUrl}`);
  }

  gePresetById(id: number): Observable<WorkflowPresetsInterface> {
    return this.http.get<WorkflowPresetsInterface>(`${this.apiUrl}/${id}`);
  }

  addPreset(data: WorkflowPresetsInterface): Observable<WorkflowPresetsInterface> {
    return this.http
      .post(`${this.apiUrl}`, data, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return data;
        }),
      );
  }

  updatePreset(data: Partial<WorkflowPresetsInterface>): Observable<WorkflowPresetsInterface> {
    return this.http
      .post(`${this.apiUrl}/${data.id}`, data, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return data as WorkflowPresetsInterface;
        }),
      );
  }

  deletePreset(id: string): Observable<string> {
    return this.http
      .delete(`${this.apiUrl}/${id}`, {
        headers: {
          Accept: 'text/plain',
        },
        responseType: 'text',
      })
      .pipe(
        map(() => {
          return id;
        }),
      );
  }
}
