<ng-container [formGroup]="form">
  <div class="field-checkbox">
    <p-checkbox name="input" [binary]="true" formControlName="enabled" inputId="loggingEnabled"></p-checkbox>
    <label for="loggingEnabled">Enabled</label>
  </div>
  <selfai-platform-edit-selection-field
    *ngIf="form.controls.enabled.value"
    formControlName="column"
  ></selfai-platform-edit-selection-field>
</ng-container>
