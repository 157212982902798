import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OperationCatalogApiResponse,
  OperationCatalogList,
  OperationDetail,
  OperationDetailApiResponse,
  OperationHierarchy,
  OperationHierarchyApiResponse,
  OperationList,
  OperationListApiResponse,
} from '@selfai-platform/pipeline-common';
import { normalizeApiEndpoint, PipelineConfigService } from '@selfai-platform/shared';
import { map, Observable } from 'rxjs';
import { OperationsAdapter } from './operations.adapter';

const PATH_OPERATIONS = '/operations';
const PATH_CATALOG = '/operations/catalog';
const PATH_HIERARCHY = '/operations/hierarchy';

@Injectable({
  providedIn: 'root',
})
export class OperationsApiService extends OperationsAdapter {
  apiUrl: string;

  constructor(private readonly http: HttpClient, private readonly pipelineConfigService: PipelineConfigService) {
    super();

    const config = this.pipelineConfigService.getConfig();
    this.apiUrl = [config.hosts.api, config.versions.url].join('/');
  }

  loadList(): Observable<OperationList> {
    return this.http
      .get<OperationListApiResponse>(this.buildUrl(PATH_OPERATIONS))
      .pipe(map(({ operations }) => new Map(Object.entries(operations))));
  }

  loadById(id: string): Observable<OperationDetail> {
    return this.http
      .get<OperationDetailApiResponse>(this.buildUrl(`${PATH_OPERATIONS}/${id}`))
      .pipe(map(({ operation }) => operation));
  }

  loadHierarchy(): Observable<OperationHierarchy> {
    return this.http.get<OperationHierarchyApiResponse>(this.buildUrl(PATH_HIERARCHY));
  }

  loadCatalog(): Observable<OperationCatalogList> {
    return this.http.get<OperationCatalogApiResponse>(this.buildUrl(PATH_CATALOG));
  }

  buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }
}
