import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CodeEditorComponentModule } from '../../../../../code-editor/code-editor.module';
import { FormFieldComponentModule } from '../../../../../form-field/form-field.module';
import { CmsFormComponent } from './cms-form.component';

const fieldComponents: NgModule['imports'] = [InputTextModule, DropdownModule, CodeEditorComponentModule];

@NgModule({
  imports: [CommonModule, FormFieldComponentModule, FormsModule, ReactiveFormsModule, CardModule, ...fieldComponents],
  declarations: [CmsFormComponent],
  exports: [CmsFormComponent],
})
export class CmsFormComponentModule {}
