import { Injectable } from '@angular/core';
import { FavoriteCubeItem } from '@selfai-platform/pipeline-common';
import { map, Observable, ReplaySubject, withLatestFrom } from 'rxjs';
import { FavoriteCubeListAdapter } from './favorite-cube-list.adapter';

@Injectable()
export class FavoriteCubeListService extends ReplaySubject<FavoriteCubeItem[]> {
  constructor(private readonly favoriteCubeListAdapter: FavoriteCubeListAdapter) {
    super(1);
  }

  loadList(): void {
    this.favoriteCubeListAdapter.loadList().subscribe({
      next: this.next.bind(this),
    });
  }

  getList(): Observable<FavoriteCubeItem[]> {
    return this.asObservable();
  }

  addItem(item: Omit<FavoriteCubeItem, 'id'>): void {
    this.favoriteCubeListAdapter
      .add(item)
      .pipe(withLatestFrom(this.getList()))
      .subscribe({
        next: ([{ id }, cubes]) => {
          this.next([...cubes, { ...item, id }]);
        },
      });
  }

  removeItem(id: string): void {
    this.favoriteCubeListAdapter
      .remove(id)
      .pipe(withLatestFrom(this.getList()))
      .subscribe({
        next: ([, cubes]) => {
          this.next(cubes.filter((cube) => cube.id !== id));
        },
      });
  }

  getById(id: string): Observable<FavoriteCubeItem | undefined> {
    return this.getList().pipe(map((cubes) => cubes.find((cube) => cube.id === id)));
  }
}
