import { Injectable } from "@angular/core";
import { KdBackendApiService } from "../../common";
import { BackendInfoApiModel } from "../models";
import { Observable } from "rxjs";

@Injectable()
export class BackendInfoApiService {
    
    constructor(private readonly kdBackendService: KdBackendApiService) {}

    getVersion(): Observable<BackendInfoApiModel> {
        return this.kdBackendService.get<BackendInfoApiModel>("info");
    }
}