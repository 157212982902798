import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  CodeLanguage,
  DivisionZeroStrategy,
  Expression,
  ExpressionItemFormGroup,
  ExpressionSource,
  mapExpressionLegacy,
  mapExpressionSourceTypeLegacy,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { startWith, takeUntil } from 'rxjs';

@Component({
  selector: 'selfai-platform-expression-edit',
  templateUrl: './expression-edit.component.html',
  styleUrls: ['./expression-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class ExpressionEditComponent implements OnInit {
  @Input() form!: FormGroup<ExpressionItemFormGroup>;

  get formType(): ExpressionItemFormGroup['type'] {
    return this.form.controls.type;
  }

  kindItems: { label: string; value: string }[] = [];
  kindExpression = Expression;
  expressionSourceItems: { label: string; value: string }[] = [];
  expressionSource = ExpressionSource;
  divisionZeroStrategyItems = Object.values(DivisionZeroStrategy);
  codeLanguage = CodeLanguage;

  constructor(private readonly destroy$: DestroyService) {}

  ngOnInit(): void {
    this.kindItems = Object.entries(mapExpressionLegacy).map(([label, value]) => ({ label, value }));
    this.expressionSourceItems = Object.entries(mapExpressionSourceTypeLegacy).map(([label, value]) => ({
      label,
      value,
    }));

    this.handleChangeFormValues();
  }

  private handleChangeFormValues(): void {
    this.formType.controls.kind.valueChanges
      .pipe(startWith(this.formType.controls.kind.value), takeUntil(this.destroy$))
      .subscribe((kind: Expression | null) => {
        if (kind && kind !== Expression.EXPRESSION) {
          if (!this.formType.controls.sourceValue1.controls.type.value) {
            this.formType.controls.sourceValue1.controls.type.setValue(ExpressionSource.SELECT_EXISTING_COLUMN);
          }

          if (!this.formType.controls.sourceValue2.controls.type.value) {
            this.formType.controls.sourceValue2.controls.type.setValue(ExpressionSource.SELECT_EXISTING_COLUMN);
          }
        }
      });

    this.formType.controls.sourceValue1.controls.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.formType.controls.sourceValue1.controls.value.value) {
        this.formType.controls.sourceValue1.controls.value.reset();
      }
    });

    this.formType.controls.sourceValue2.controls.type.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.formType.controls.sourceValue2.controls.value.value) {
        this.formType.controls.sourceValue2.controls.value.reset();
      }
    });
  }
}
