import { TimeFilter, createTimeFilter } from './time-filter.model';
import { DatasourceField } from '../../../../datasource';

export interface TimeListFilter extends TimeFilter {
  valueList?: string[];
  candidateValues?: string[];
}

export function createTimeListFilter(field: DatasourceField): TimeListFilter {
  return {
    ...createTimeFilter(field),
    type: 'time_list',
  };
}
