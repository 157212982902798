import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, map, Observable } from 'rxjs';
import { catchError, filter, shareReplay, tap } from 'rxjs/operators';
import { dictionariesDefault } from '../const';
import { AggregatorDictionaries } from '../models';
import { AggregatorDictionariesApiService } from './api';

@Injectable()
export class AggregatorDictionariesService extends BehaviorSubject<AggregatorDictionaries | undefined> {
  private wasLoaded = false;

  constructor(private readonly aggregatorDictionariesApiService: AggregatorDictionariesApiService) {
    super(undefined);
  }

  loadDictionaries(): Observable<void> {
    return this.aggregatorDictionariesApiService.loadDictionaries().pipe(
      tap((dictionaries) => this.next(dictionaries)),
      map(() => void 0),
      catchError(() => {
        // default values for compabilites
        this.next(dictionariesDefault);

        return EMPTY;
      }),
    );
  }

  getDictionaries(): Observable<AggregatorDictionaries> {
    return this.asObservable().pipe(
      tap((dictionaries) => {
        if (!dictionaries && !this.wasLoaded) {
          this.wasLoaded = true;
          this.loadDictionaries().subscribe();
        }
      }),
      filter(Boolean),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  getDictionary<K extends keyof AggregatorDictionaries>(dictionaryCode: K): Observable<AggregatorDictionaries[K]> {
    return this.getDictionaries().pipe(
      map(
        (dictionaries) =>
          (dictionaries[dictionaryCode] || dictionariesDefault[dictionaryCode]) as AggregatorDictionaries[K],
      ),
    );
  }
}
