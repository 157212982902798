import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { PermissionService, PipelinePermissionEntity, WorkflowPermissionAction } from '@selfai-platform/shared';
import { ActionsForItemService, DataListItemAction, DialogService } from '@selfai-platform/shell';
import { WorkflowCloneDialogComponent } from '../../components';
import { WorkflowDataListViewService } from './workflow-data-list-view.service';
import { WorkflowListComponentService, WorkflowListItem } from './workflow-list-component.service';

@Injectable()
export class WorkspaceActionsForItemService extends ActionsForItemService<WorkflowListItem> {
  constructor(
    private readonly workflowDataListViewService: WorkflowDataListViewService,
    private readonly permissionService: PermissionService,
    private readonly workflowListComponentService: WorkflowListComponentService,
    private readonly dialogService: DialogService<WorkflowClonePayload, WorkflowInfo>,
    private readonly confirmationService: ConfirmationService,
    private readonly translateService: TranslateService,
  ) {
    super();
  }

  override getActionsForItem(workflow: WorkflowListItem): Observable<DataListItemAction[]> {
    return combineLatest({
      permissionClone: this.checkPermission(WorkflowPermissionAction.Clone, workflow.isOwner),
      permissionDelete: this.checkPermission(WorkflowPermissionAction.Delete, workflow.isOwner),
    }).pipe(
      map((permissions) => {
        const menuItems: DataListItemAction[] = [];

        if (permissions.permissionClone) {
          menuItems.push({
            labelTranslate: 'workflow-list.table.action.item.clone',
            icon: 'pi pi-copy',
            action: () => {
              this.dialogService
                .showDialog(WorkflowCloneDialogComponent, {
                  data: workflow,
                  width: '50%',
                })
                .pipe(
                  switchMap((workflow) => {
                    if (workflow) {
                      return this.workflowListComponentService.cloneWorkflow(workflow);
                    }

                    return of(null);
                  }),
                )
                .subscribe((result) => {
                  if (result !== null) {
                    this.workflowDataListViewService.triggerReloadData();
                  }
                });
            },
          });
        }

        if (permissions.permissionDelete) {
          menuItems.push({
            labelTranslate: 'workflow-list.table.action.item.delete',
            icon: 'pi pi-trash',
            action: () => {
              this.confirmationService.confirm({
                key: 'dialog',
                message: this.translateService.instant('workflow-list.toolbar.actions.delete-selected.confirmation'),
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                  this.workflowListComponentService.deleteWorkflow(workflow.id);
                },
              });
            },
          });
        }

        return menuItems;
      }),
    );
  }

  private checkPermission(permissionAction: WorkflowPermissionAction, isOwner?: boolean): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Workflows,
      isOwner,
      action: permissionAction,
    });
  }
}
