import { TableLazyLoadEvent } from 'primeng/table';

interface Filter {
  fieldName: string;
  value: string | number | boolean;
}

export function mapFiltersBiToPrime(filters: Filter[]): TableLazyLoadEvent['filters'] {
  if (!filters) {
    return {};
  }

  return filters.reduce((acc, { fieldName, value }) => {
    acc[fieldName] = { value };

    return acc;
  }, {} as TableLazyLoadEvent['filters']);
}

export function mapFiltersPrimeToBi(filters?: TableLazyLoadEvent['filters']): Filter[] {
  if (!filters) {
    return [];
  }

  return Object.entries(filters)
    .filter(([, filter]) => filter)
    .map(([fieldName, filter]) => ({
      fieldName,
      value: Array.isArray(filter) ? filter.map(({ value }) => value).join(',') : filter.value,
    }));
}

export function mapFiltersBiToApiQueryParams(filters?: Filter[]): Record<string, string> {
  if (!filters) {
    return {};
  }

  return filters.reduce((acc, { fieldName, value }) => {
    acc[fieldName] = value.toString();

    return acc;
  }, {} as Record<string, string>);
}
