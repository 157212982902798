import { Component, ElementRef, EventEmitter, Injector, Output } from '@angular/core';

import { AbstractComponent } from '../abstract.component';

declare const gradX: any;
declare const gradx: any;
declare const $: any;

@Component({
  selector: 'gradation-generator',
  templateUrl: './gradation-generator.component.html',
})
export class GradationGeneratorComponent extends AbstractComponent {
  @Output()
  private changeGradation: EventEmitter<Object> = new EventEmitter();

  public sliderList: Object[];

  public displaySliders: Object[];

  public gradxObj: any;

  public currentValue: string;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    gradx.destroy();

    super.ngOnDestroy();
  }

  public init(sliders: any, data: any, initFl?: boolean): Object {
    this.sliderList = sliders;

    this.gradxObj = gradX('#gradX2', {
      direction: 'left',
      sliders: Object.assign([], this.sliderList),
      min: data.min,
      max: data.max,
      positionMin: data.positionMin,
      positionMax: data.positionMax,
      separateValue: data.separateValue,
      type: 'linear',
      code_shown: false,
      change: (sliders, values, displaySliders, currentValue) => {
        this.displaySliders = displaySliders;

        this.sliderList = sliders;

        if (!initFl) {
          this.changeGradation.emit({
            ranges: this.sliderList,
            visualGradations: this.displaySliders,
            currentValue: currentValue,
            currentSliderIndex: gradx.sliders.indexOf(gradx.get_current_slider(gradx.current_slider_id)),
          });
        } else {
          initFl = false;
        }
      },
    });

    return { ranges: this.sliderList, visualGradations: this.displaySliders };
  }

  public changeGradationColor(sliderId: string, rgbColor: string): void {
    gradx.set_slider_id_color(sliderId, rgbColor);
  }

  public addNewRangeIndex(index: number): void {
    gradx.add_new_range_index(index);
  }

  public deleteRange(currentIndex): void {
    gradx.delete_range(currentIndex);
  }

  public equalizeRange(): void {
    gradx.equalize_slider();
  }

  public unselectSlider(sliderId?: number): void {
    gradx.remove_select_class(sliderId);
  }

  public changeSliderValue(value: string): void {
    gradx.change_value_current_index(value);
  }
}
