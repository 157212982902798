import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { UiSharedSettingsNumberCounterComponent } from './number-counter.component';

@NgModule({
  declarations: [UiSharedSettingsNumberCounterComponent],
  imports: [CommonModule, TranslateModule],
  exports: [UiSharedSettingsNumberCounterComponent],
})
export class UiSharedSettingsNumberCounterModule {}
