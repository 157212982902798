<div *ngIf="fieldTypes.length > 1" class="mb-1">
  <button
    *ngFor="let field of fieldTypes"
    (click)="changeType(field)"
    class="tab-switcher"
    [class.active]="field === activeFieldType"
  >
    {{ field }}
  </button>
</div>

<ng-container [ngSwitch]="activeFieldType">
  <ng-content *ngSwitchCase="fieldType.CONSTANT" select="selfai-platform-constant-value-field"></ng-content>
  <ng-content *ngSwitchCase="fieldType.SPEL" select="selfai-platform-spel-value-field"></ng-content>
  <ng-content *ngSwitchCase="fieldType.JSONPATH" select="selfai-platform-json-path-field"></ng-content>
</ng-container>
