<p-message class="block mb-3" severity="error" [text]="error.title"></p-message>

<p-panel header="Error message" class="block mb-3" [toggleable]="true">
  {{ error.message }}
</p-panel>

<p-panel header="Error stacktrace" [toggleable]="true">
  <pre>
    {{ error.details.stacktrace }}
  </pre>
</p-panel>
