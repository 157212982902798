import { Injector } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ConfigService } from '../config';
import { PermissionService } from '../permission';
import { NoneIdentityProviderService } from './none/identity-provider.service';
import { getRolesFromKeycloakToken, initializeKeycloak } from './keycloak';
import { SelfaiAuthService } from './selfai-auth.service';
import { getRolesForNoneProvider } from './none';

export function resolveIdentityProvider(
  configService: ConfigService,
  permissionService: PermissionService,
  injector: Injector,
  identityProviderImplentationService: SelfaiAuthService,
) {
  return new Promise<void>((resolve) => {
    configService.configLoaded$.subscribe((loaded) => {
      if (!loaded) {
        return;
      }
      const identityProvder = configService.getConfig().identityProvider?.provider;
      if (!identityProvder) {
        console.warn("Error when reading 'identityProvider' from config. Using 'none' as default.");
      }
      switch (identityProvder) {
        case 'keycloak':
          {
            const keycloakService = injector.get(KeycloakService);
            initializeKeycloak(keycloakService, configService)
              .then(() => {
                return keycloakService.getToken();
              })
              .then((token) => {
                const keycloakRoles = getRolesFromKeycloakToken(token, configService.getConfig());
                permissionService.setRoles(keycloakRoles);
                identityProviderImplentationService.setProvider(keycloakService);
                resolve();
              });
          }
          break;
        case 'none':
        default: {
          const noneProviderService = injector.get(NoneIdentityProviderService);
          const noneProviderRoles = getRolesForNoneProvider();
          identityProviderImplentationService.setProvider(noneProviderService);
          permissionService.setRoles(noneProviderRoles);
          resolve();
        }
      }
    });
  });
}
