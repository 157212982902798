import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { JsonPathFieldComponentModule } from '../../../../json-path/components/json-path-field/json-path-field.module';
import { StepParallelComponent } from './step-parallel.component';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    DropdownModule,
    InputNumberModule,
    ButtonModule,
    ReactiveFormsModule,
    JsonPathFieldComponentModule,
    FormFieldComponentModule,
  ],
  declarations: [StepParallelComponent],
  exports: [StepParallelComponent],
})
export class StepParallelComponentModule {}
