import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  VersionManagerActions,
  VersionsManagerRunResult,
  VersionsManagerWorkflowData,
  WorkflowSerialized,
  WorkflowVersion,
  WorkflowVersionCreate,
  WorkflowVersionFull,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { VERSION_MANAGER_API_TOKEN } from '../tokens/version-manager-api-token';
import { downloadFile, sortByKey } from '../utils';
import { IVersionsManagerApi } from './i-versions-manager-storage';
import { VersionsManagerService } from './versions-manager.service';

@Injectable()
export class VersionManagerItemsService extends BehaviorSubject<WorkflowVersion[]> implements OnDestroy {
  private apiSubscription = new Subscription();

  constructor(
    @Inject(VERSION_MANAGER_API_TOKEN) private readonly versionManagerApiService: IVersionsManagerApi,
    private readonly versionsManagerService: VersionsManagerService,
    private readonly dialogService: DialogService<VersionsManagerRunResult, VersionsManagerWorkflowData>,
  ) {
    super([]);
    if (this.versionsManagerService.workflowId) {
      this.setVersions(this.versionsManagerService.workflowId);
    }
  }

  addVersion(version: WorkflowVersionCreate): WorkflowVersionFull {
    const versionCreated: WorkflowVersionFull = {
      ...version,
      id: uuidv4(),
      created: new Date(),
      versionNumber: (this.getLatestVersionNumber() + 1).toString(),
    };
    this.next([...this.value, versionCreated]);

    if (this.versionsManagerService.workflowId) {
      this.apiSubscription.add(
        this.versionManagerApiService.createVersion(this.versionsManagerService.workflowId, versionCreated).subscribe(),
      );
    }

    return versionCreated;
  }

  restoreVersion(version: WorkflowVersion): void {
    if (this.versionsManagerService.workflowId) {
      this.apiSubscription.add(
        this.getVersionWithContent(version.id).subscribe((versionContent) => {
          if (versionContent) {
            const clonedVersion: WorkflowVersionCreate = {
              ...version,
              description: `Restore version from №${version.versionNumber}`,
              content: versionContent.content,
            };

            this.addVersion(clonedVersion);
            this.openWorkflow(versionContent.content);
          }
        }),
      );
    }
  }

  removeVersion(version: WorkflowVersion): void {
    const versions = this.value.filter((v) => v.id !== version.id);
    this.next(versions);

    if (this.versionsManagerService.workflowId) {
      this.apiSubscription.add(
        this.versionManagerApiService.removeVersion(this.versionsManagerService.workflowId, version.id).subscribe(),
      );
    }
  }

  exportVersion(workflowName: string, version: WorkflowVersion): void {
    //TODO add error handler
    if (this.versionsManagerService.workflowId) {
      this.apiSubscription.add(
        this.getVersionWithContent(version.id).subscribe((versionContent) => {
          if (versionContent) {
            downloadFile(`${workflowName}_v_${version.versionNumber}.json`, JSON.stringify(versionContent.content));
          }
        }),
      );
    }
  }

  getVersions(): Observable<WorkflowVersion[]> {
    return this.asObservable();
  }

  setVersions(workflowId: string) {
    this.apiSubscription.add(
      this.versionManagerApiService.getVersionsByWorkflowId(workflowId).subscribe((data) => {
        this.next(data);
      }),
    );
  }

  getLatestVersionNumber(): number {
    const sortedVersions = sortByKey(this.value, 'versionNumber');
    if (sortedVersions.length) {
      return parseInt(sortedVersions[sortedVersions.length - 1].versionNumber);
    }

    return 0;
  }

  getVersionWithContent(versionId: string): Observable<WorkflowVersionFull | undefined> {
    if (!this.versionsManagerService.workflowId) {
      return of();
    }

    return this.versionManagerApiService.getVersionContent(this.versionsManagerService.workflowId, versionId);
  }

  openWorkflow(workflow: WorkflowSerialized): void {
    this.dialogService.close({ workflow, action: VersionManagerActions.SaveAndOpenWorkflow });
  }

  closeDialog(): void {
    this.dialogService.close({ action: VersionManagerActions.None });
  }

  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe();
    this.complete();
  }
}
