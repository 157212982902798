import { Injectable } from '@angular/core';
import { FavoriteCubeItem } from '@selfai-platform/pipeline-common';
import { getDataFromLocalStorage, LocalStorageKey, saveDataToLocalStorage } from '@selfai-platform/shared';
import { Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FavoriteCubeListAdapter } from './favorite-cube-list.adapter';

@Injectable({
  providedIn: 'root',
})
export class FavoriteCubeListStorageService extends FavoriteCubeListAdapter {
  loadList(): Observable<FavoriteCubeItem[]> {
    const data = getDataFromLocalStorage<FavoriteCubeItem[]>(LocalStorageKey.FAVORITE_CUBES, []);

    return of(data);
  }

  add(item: FavoriteCubeItem): Observable<{ id: string }> {
    const data = getDataFromLocalStorage<FavoriteCubeItem[]>(LocalStorageKey.FAVORITE_CUBES, []);
    const id = uuidv4();

    saveDataToLocalStorage(LocalStorageKey.FAVORITE_CUBES, [...data, { ...item, id }]);

    return new Observable((observer) => {
      observer.next({ id });
      observer.complete();
    });
  }

  remove(id: string): Observable<void> {
    const data = getDataFromLocalStorage<FavoriteCubeItem[]>(LocalStorageKey.FAVORITE_CUBES, []);

    saveDataToLocalStorage(
      LocalStorageKey.FAVORITE_CUBES,
      data.filter((item) => item.id !== id),
    );

    return new Observable((observer) => {
      observer.next();
      observer.complete();
    });
  }
}
