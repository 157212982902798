import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

enum EElementType {
  Data = 'data',
  Chart = 'chart',
}

@Component({
  selector: 'selfai-platform-page-accordion',
  templateUrl: './page-accordion.component.html',
  styleUrls: ['./page-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageAccordionComponent {
  @Input() dataLayerKey: string;
  @Input() dimensionsCount: number;
  @Input() measuresCount: number;
  @Input() selectChartTranslate: number;

  selectPanel$ = new BehaviorSubject<EElementType>(null);

  EElementType = EElementType;

  get countData() {
    return {
      dimensions: this.dimensionsCount,
      measures: this.measuresCount,
    };
  }

  selectElement(type: EElementType) {
    const value = this.selectPanel$.getValue() === type ? null : type;
    this.selectPanel$.next(value);
  }
}
