import { ShelveFieldType } from '../../../chart';
import {
  DatasourceField,
  FieldPivot,
  FieldRole,
  GranularityType,
  SearchQueryPivotOptionsV2,
} from '../../../datasource';
import { Format } from './format.model';
import { DIRECTION } from './sort.model';

export interface Pivot {
  columns: PivotField[];
  rows: PivotField[];
  aggregations: PivotField[];
}

export interface PivotField {
  data?: any;
  role?: FieldRole;
  type?: ShelveFieldType | string;
  name?: string;
  alias?: string;
  fieldAlias?: string;
  pivotAlias?: string;
  ref?: string;
  subType?: string;
  subRole?: string;
  aggregationType?: any;
  aggregationTypeList?: any;
  options?: string;
  granularity?: GranularityType;
  segGranularity?: GranularityType;
  granularityList?: any;
  timeExprUnit?: GranularityType;
  direction?: DIRECTION;
  lastDirection?: boolean;
  showValue?: boolean;
  field?: DatasourceField;
  pivot?: FieldPivot[];
  format?: Format;
  currentPivot?: FieldPivot;
  aggregated?: boolean;
  isCustomField?: boolean;
  logicalType?: string;
  optionsV2?: SearchQueryPivotOptionsV2;
}

export function createPivot(params: Partial<Pivot> = {}): Pivot {
  return {
    columns: [],
    rows: [],
    aggregations: [],
    ...params,
  };
}
