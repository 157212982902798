import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { CubeEditResult, CubeWorkflowData, OperationParameter } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { DialogHelperService } from '../../../../dialog';
import { OperationDetailService } from '../../../services/operations/operation-detail.service';
import { SaveConfirmationService } from '../../../services/save-confirmation.service';
import { AbstractCubeDialogFormComponent } from '../../abstract-cube-dialog-form.component';
import { mapFormGroupValueToSerializedParameters, mapParametersToFormGroup } from './map-parameters-form-group';

@Component({
  selector: 'selfai-platform-cms-form-creator',
  templateUrl: './cms-form-creator.component.html',
  styleUrls: ['./cms-form-creator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService, DialogHelperService, SaveConfirmationService],
})
export class CmsFormCreatorComponent extends AbstractCubeDialogFormComponent implements OnInit {
  modalHeader!: string;
  operationLoading = true;

  errorMessage: string | null = null;

  override initialItem: Record<string, string> = {};

  fields: OperationParameter[] = [];

  constructor(
    protected override readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData<Record<string, unknown>>>,
    private readonly operationDetailService: OperationDetailService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (this.dialogService.data?.selectedNode.parameters) {
      const cubeParameters = this.dialogService.data.selectedNode.parameters.serialize();

      const { operationId } = this.dialogService.data.selectedNode;
      this.operationDetailService.getWithLoading(operationId).subscribe({
        next: ({ parameters, name }) => {
          this.modalHeader = name;
          this.fields = parameters.filter(({ name }) => name !== 'report type');

          mapParametersToFormGroup(this.fields, cubeParameters, this.form);
        },
        complete: () => {
          this.operationLoading = false;
          this.markFormAsInitialized();
          this.cdr.markForCheck();
        },
      });
    } else {
      this.errorMessage = 'Data from workflow is not available yet';
    }
  }

  get dataForWorkflow(): Record<string, unknown> {
    return mapFormGroupValueToSerializedParameters(this.form.value);
  }
}
