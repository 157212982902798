import { Injectable } from '@angular/core';
import { CubeEditResult, CubeError, CubeState, CubeWorkflowData } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';

@Injectable()
export class DialogHeaderComponentService {
  constructor(private dialogService: DialogService<CubeEditResult, CubeWorkflowData>) {}

  getCubeError(): CubeError | undefined {
    return this.getCubeState()?.error;
  }

  getCubeState(): CubeState | undefined {
    return this.dialogService.data?.selectedNode.state;
  }
}
