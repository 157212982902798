<div
  class="ddp-type-selectbox ddp-flow"
  [class.ddp-disabled]="isDisabledOptions"
  [class.ddp-type-search-select]="isSearchOptions"
  [class.ddp-selected]="isShowOptions"
  [class.ddp-flow]="isConnection"
  [class.ddp-result]="getResultOption"
  (click)="onOffShowOptions()"
>
  <input
    type="text"
    class="ddp-input-selectbox"
    placeholder="{{ searchPlaceHolderText }}"
    *ngIf="isSearchOptions"
    [(ngModel)]="searchText"
    [focus]="isShowOptions"
    (keyup.esc)="searchText = ''"
    (keyup)="onSearchItem()"
    (click)="$event.stopImmediatePropagation()"
  />

  <span class="ddp-txt-selectbox">{{ getSelectedItem }}</span>
  <ul
    class="ddp-list-selectbox"
    [ngClass]="{ 'ddp-selectdown': isUpSelect == false }"
    infinite-scroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="5"
    [infiniteScrollThrottle]="150"
    (scrolled)="onScroll()"
  >
    <li *ngIf="getArrayList == undefined || getArrayList.length === 0">
      <span class="ddp-noresult">{{ 'msg.comm.ui.no.rslt' | translate }}</span>
    </li>

    <li *ngFor="let item of getArrayList">
      <a href="javascript:" (click)="onSelectItem(item)">{{ getItem(item) }}</a>
    </li>
  </ul>
</div>
