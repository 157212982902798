import { ValueProvider } from '@angular/core';
import { StepType } from '@selfai-platform/pipeline-common';
import { AGGREGATOR_STEPS_CONFIG } from '../tokens/aggregator-steps-config.token';

// @using provideStepsConfig([StepType.AGGREGATOR, StepType.TO]) in your app will be available only two steps
export function provideStepsConfig(steps: StepType[]): ValueProvider {
  return {
    provide: AGGREGATOR_STEPS_CONFIG,
    useValue: steps,
  };
}
