import { Injectable } from '@angular/core';
import { KdConfigService } from './kd-config.service';
import { KdFeatureName } from './kd-feature-name.enum';

@Injectable({
  providedIn: 'root',
})
export class KdFeatureEnableService {
  constructor(private readonly kdConfigService: KdConfigService) {}

  isEnabledFeature(featureName: KdFeatureName): boolean {
    try {
      return Boolean(this.kdConfigService.getConfig().features[featureName].enabled);
    } catch (e: unknown) {
      console.warn(`Feature "${featureName}" enabling config has errors. Check config.json`, e);

      return false;
    }
  }
}
