import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  CubeWorkflowData,
  GraphNode,
  ReadDataFrameParameters,
  ReadDataFrameRawParameters,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { MessageModule } from 'primeng/message';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { DataSourceSelectorComponent } from '../../data-source-selector/data-source-selector.component';
import { DialogHeaderComponentModule } from '../../dialog-header';

@Component({
  selector: 'selfai-platform-read-data-frame',
  templateUrl: './read-data-frame.component.html',
  styleUrl: './read-data-frame.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MessageModule,
    DataSourceSelectorComponent,
    TranslateModule,
    DialogHeaderComponentModule,
    ReactiveFormsModule,
  ],
})
export class ReadDataFrameComponent implements OnInit {
  get node(): GraphNode<ReadDataFrameRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): ReadDataFrameParameters {
    return this.normalizeRawReadDataFrameParameters(this.node.parameters.serialize());
  }

  form = new FormGroup<{ dataSourceId: FormControl<string | null> }>({
    dataSourceId: new FormControl(null, [Validators.required]),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<ReadDataFrameRawParameters>>,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as ReadDataFrameParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeFormValuesToApiModel(params: ReadDataFrameParameters): ReadDataFrameRawParameters {
    return {
      'data source': params.dataSourceId,
    };
  }

  private normalizeRawReadDataFrameParameters(rawParameters: ReadDataFrameRawParameters): ReadDataFrameParameters {
    return {
      dataSourceId: rawParameters['data source'],
    };
  }
}
