import { DatasourceType } from '@selfai-platform/pipeline-common';

export function dataSourcesAdditionsParamsTypeNormalize(type: DatasourceType): string {
  switch (type) {
    case 'jdbc':
      return 'JDBC';
    case 'externalFile':
      return 'FILE';
    case 'hdfs':
      return 'HDFS';
    case 'googleSpreadsheet':
      return 'Google Sheet';
    case 'kafka':
      return 'KAFKA';
    case 's3':
      return 'S3';
    case 'libraryFile':
      return 'LIBRARY';
    default:
      return '';
  }
}
