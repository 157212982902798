<ng-container *ngIf="isShow">
  <div class="ddp-layout-popuptype">
    <em class="ddp-bg-popup"></em>
    <div class="ddp-pop-filter">
      <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>

      <div
        [class.ddp-filter1]="targetFilter.type !== 'bound'"
        [class.ddp-filter-range]="targetFilter.type === 'bound'"
      >
        <app-config-filter-inclusion></app-config-filter-inclusion>
        <app-config-filter-time></app-config-filter-time>
        <app-config-filter-bound></app-config-filter-bound>
        <ng-container *ngTemplateOutlet="buttonsTemplate"></ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #buttonsTemplate>
  <div class="ddp-ui-buttons">
    <a (click)="close()" href="javascript:" class="ddp-btn-type-popup">{{
      "msg.comm.btn.cancl" | translate
    }}</a>
    <a
      (click)="emitUpdateFilter()"
      href="javascript:"
      class="ddp-btn-type-popup ddp-bg-black"
    >
      {{ "msg.comm.btn.done" | translate }}
    </a>
  </div>
</ng-template>

<ng-template #filterTemplate>
  <div *ngIf="targetField && targetFilter" class="ddp-top-filter">
    <div class="ddp-ui-tags">
      <span
        *ngIf="widget && targetFilter.ui?.widgetId"
        class="ddp-data-chartname"
        >{{ getWidgetName() }}</span
      >
      <div
        *ngIf="false && widget"
        (click)="toggleFilterScope()"
        class="ddp-tag-type ddp-checkbox-slide"
      >
        <input
          [checked]="!targetFilter.ui?.widgetId"
          type="checkbox"
          value="None"
          id="check2"
          name="check"
        />
        <label for="check2"><span class="ddp-slide"></span></label>
        <span class="ddp-txt-slide"><em class="ddp-icon-global"></em></span>
      </div>
      <span *ngIf="!targetFilter.ui?.widgetId" class="ddp-tag-type ddp-type">
        <em class="ddp-icon-global"></em>
        Global
      </span>
      <span *ngIf="isRecommend()" class="ddp-tag-type ddp-type">
        <em class="ddp-icon-recommend"></em>
        recommended
      </span>
      <span *ngIf="isEssential()" class="ddp-tag-type ddp-type">
        <em class="ddp-icon-lock"></em>
        Essential
      </span>
      <span *ngIf="isTimestamp()" class="ddp-tag-type ddp-type">
        <em class="ddp-icon-time4"></em>
        Timestamp
      </span>
    </div>

    <div class="ddp-title">
      <div
        class="ddp-icons"
        style="height: 49px"
        [ngStyle]="isDirectEdit ? { 'padding-left': '7px' } : {}"
      >
        <a
          *ngIf="!isDirectEdit"
          (click)="clickBtnBack()"
          href="javascript:"
          class="ddp-btn-back"
        ></a>
        <span *ngIf="!isMeasure()" class="ddp-icon-box">
          <selfai-bi-shared-field-icon
            [view]="'DIMENSION'"
            [type]="targetField.type"
            [logicalType]="targetField.logicalType"
          ></selfai-bi-shared-field-icon>
        </span>
        <span *ngIf="isMeasure()" class="ddp-icon-box ddp-measure">
          <selfai-bi-shared-field-icon
            [view]="'MEASURE'"
            [type]="targetField.type"
            [logicalType]="targetField.logicalType"
          ></selfai-bi-shared-field-icon>
        </span>
      </div>

      <div class="ddp-txt-name ddp-type">
        <span class="ddp-txt-data">{{ targetField.name }}</span>
        <span #ddpTxtSub class="ddp-txt-sub">{{ dataSource.name }}</span>
      </div>
    </div>
  </div>
</ng-template>
