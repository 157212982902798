import { EntityMetadataMap } from '@ngrx/data';
import { FAVORITE_LIST_ENTITY_NAME } from '../constants';

const entityMetadata: EntityMetadataMap = {
  [FAVORITE_LIST_ENTITY_NAME]: {},
};

export const biDomainFavoriteEntityConfig = {
  entityMetadata,
};
