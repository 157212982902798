import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase } from '@ngrx/data';
import { getIdContextKey } from '@selfai-platform/shared';
import { Observable, map, take } from 'rxjs';
import { Filter } from '../../workbook';

@Injectable()
export class FilterCollectionServiceBase<FilterModel extends Filter> {
  constructor(protected readonly store: EntityCollectionServiceBase<{ id: string; filters: FilterModel[] }>) {}

  getField(entityId: string, searchedField: FilterModel, contextId?: string): Observable<FilterModel | undefined> {
    return this.getFields(entityId, contextId).pipe(
      map((fields) =>
        fields.find((field) => field.field === searchedField.field && field.dataSource === searchedField.dataSource),
      ),
    );
  }

  setFields(entityId: string, fields: FilterModel[], contextId?: string): void {
    this.store.upsertOneInCache({ id: getIdContextKey(entityId, contextId), filters: fields });
  }

  getFields(entityId: string, contextId?: string): Observable<FilterModel[]> {
    return this.store.entityMap$.pipe(
      map((entityMap) => {
        return entityMap[getIdContextKey(entityId, contextId)]?.filters || [];
      }),
    );
  }

  toggleValue(entityId: string, toggledField: FilterModel, contextId?: string): void {
    this.getFields(entityId, contextId)
      .pipe(take(1))
      .subscribe((fields) => {
        const searchedFilter = fields.find(
          ({ field, dataSource }) => field === toggledField.field && dataSource === toggledField.dataSource,
        );

        if (searchedFilter) {
          this.removeValue(entityId, toggledField, contextId);
        } else {
          this.addValue(entityId, toggledField, contextId);
        }
      });
  }

  addValue(entityId: string, addingField: FilterModel, contextId?: string): void {
    this.getFields(entityId, contextId)
      .pipe(take(1))
      .subscribe((fields) => {
        const searchedFilter = fields.find(
          ({ field, dataSource }) => field === addingField.field && dataSource === addingField.dataSource,
        );

        if (!searchedFilter) {
          const updatedFilters = [...fields, addingField];
          this.setFields(entityId, updatedFilters, contextId);
        }
      });
  }

  removeValue(entityId: string, removingField: FilterModel, contextId?: string): void {
    this.getFields(entityId, contextId)
      .pipe(take(1))
      .subscribe((fields) => {
        const searchedFilter = fields.find(
          ({ field, dataSource }) => field === removingField.field && dataSource === removingField.dataSource,
        );

        if (searchedFilter) {
          const updatedFilters = fields.filter(
            ({ field, dataSource }) => !(field === removingField.field && dataSource === removingField.dataSource),
          );
          this.setFields(entityId, updatedFilters, contextId);
        }
      });
  }

  resetAllFields(entityId: string, contextId?: string): void {
    this.store.upsertOneInCache({ id: getIdContextKey(entityId, contextId), filters: [] });
  }
}
