import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  CodeEditorConfig,
  CubeWorkflowData,
  ExternalSqlQueryFormGroup,
  ExternalSqlQueryParameters,
  ExternalSqlQueryRawParameters,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MessageModule } from 'primeng/message';
import { EditSelectionFieldComponentModule } from '../../../../edit-selection-field/edit-selection-field.module';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { DataSourceSelectorComponent } from '../../data-source-selector/data-source-selector.component';
import { DialogHeaderComponentModule } from '../../dialog-header';

@Component({
  selector: 'selfai-platform-external-sql-query',
  standalone: true,
  imports: [
    CommonModule,
    EditSelectionFieldComponentModule,
    ReactiveFormsModule,
    FormFieldComponentModule,
    DialogHeaderComponentModule,
    DataSourceSelectorComponent,
    TranslateModule,
    MessageModule,
    MonacoEditorModule,
  ],
  templateUrl: './external-sql-query.component.html',
  styleUrl: './external-sql-query.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalSqlQueryComponent {
  editorOptions: CodeEditorConfig = {
    theme: 'vs',
    language: 'sql',
    automaticLayout: true,
    minimap: { enabled: false },
    wordWrap: 'on',
  };

  get node(): GraphNode<ExternalSqlQueryRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): ExternalSqlQueryParameters {
    return this.normalizeRawParameters(this.node.parameters.serialize());
  }

  form = new FormGroup<ExternalSqlQueryFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    column: new FormControl(null, [Validators.required]),
    sqlQuery: new FormControl(null, [Validators.required]),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<ExternalSqlQueryRawParameters>>,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as ExternalSqlQueryParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeRawParameters(params: ExternalSqlQueryRawParameters): ExternalSqlQueryParameters {
    return {
      dataSourceId: params['data source'],
      // we don't support index type
      column:
        params['One of output columns'] && params['One of output columns'].type !== 'index'
          ? params['One of output columns'].value
          : null,
      sqlQuery: params['SQL query'],
    };
  }

  private normalizeFormValuesToApiModel(formValues: ExternalSqlQueryParameters): ExternalSqlQueryRawParameters {
    return {
      'data source': formValues.dataSourceId,
      'One of output columns': formValues.column ? { type: 'column', value: formValues.column } : null,
      'SQL query': formValues.sqlQuery,
    };
  }
}
