<div class="widget-container">
  <dashboard-widget
    *ngIf="widget$ | async as widget"
    [widget]="widget"
    [widgetInfo]="getWidgetInfo(widget) | async"
    [widgetOpts]="getWidgetOptions(widget) | async"
    layoutMode="VIEW"
    [readonly]="true"
    [isDashboardLoaded]="false"
  ></dashboard-widget>
</div>
