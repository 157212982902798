<selfai-platform-dialog-header
  [header]="'workflow.cubes.write-data-frame-transit.modal-header' | translate"
  [nodeId]="nodeId"
  (clickSave)="onSubmit()"
  (closeDialog)="onCloseDialog()"
></selfai-platform-dialog-header>
@if(hasParameters) {
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <selfai-platform-form-field
    label="{{ 'workflow.cubes.write-data-frame-transit.field-label.data-source' | translate }}"
  >
    <selfai-platform-data-source-selector
      [control]="form.controls.dataSourceId"
      class="h-15rem"
    ></selfai-platform-data-source-selector>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.write-data-frame-transit.field-label.overwrite' | translate }}">
    <p-checkbox [binary]="true" [formControl]="form.controls.overwrite"></p-checkbox>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="{{ 'workflow.cubes.write-data-frame-transit.field-label.save-mode' | translate }}">
    <p-dropdown
      [autoDisplayFirst]="false"
      [formControl]="form.controls.saveMode"
      appendTo="body"
      [options]="saveModeOptions"
    ></p-dropdown>
  </selfai-platform-form-field>
</form>
} @else {
<p-message
  text="{{ 'workflow.cubes.write-data-frame-transit.no-parameters' | translate }}"
  severity="error"
></p-message>
}
