import { EntityMetadataMap } from '@ngrx/data';

export const KE_WORKFLOWS_LIST_ENTITY_NAME = 'keWorkflows';

const entityMetadata: EntityMetadataMap = {
  [KE_WORKFLOWS_LIST_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keWorkflowsEntityConfig = {
  entityMetadata,
};
