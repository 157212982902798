import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CubeDndPlaceholderComponent } from './cube-dnd-placeholder.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CubeDndPlaceholderComponent],
  exports: [CubeDndPlaceholderComponent],
})
export class CubeDndPlaceholderComponentModule {}
