import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';

import {
  AssociationFilter,
  BoardDataSource,
  BoardDataSourceRelation,
  createAssociationDataRequest,
  createAssociationFilter,
  Dashboard,
  Datasource,
  DatasourceDomainService,
  Filter,
  filterPathContainsDatasource,
  FilterPathRootNode,
  getAllFiltersDsRelations,
  getDataSourceFromBoardDataSource,
  getFilterPath,
  getFilterPathNodesByDsId,
  PageWidget,
} from '@selfai-platform/bi-domain';

@Injectable({
  providedIn: 'root',
})
export class WidgetFilterLegacyService {
  constructor(private readonly datasourceDomainService: DatasourceDomainService) {}

  public calculateSearchFilters(
    dashboard: Dashboard,
    widget: PageWidget,
    globalFilters?: Filter[],
    currentSelectionFilters?: Filter[],
  ): Observable<Filter[]> {
    const widgetDataSource: Datasource = getDataSourceFromBoardDataSource(dashboard, widget.configuration.dataSource);

    if (!widgetDataSource) {
      throw new Error('GB0000');
    }

    if (!globalFilters) {
      globalFilters = getAllFiltersDsRelations(dashboard, widgetDataSource.engineName);
    }

    const externalFilters = currentSelectionFilters ? globalFilters.concat(currentSelectionFilters) : globalFilters;

    const dashboardDatasource: BoardDataSource = dashboard.configuration.dataSource;

    if ('multi' === dashboardDatasource.type && dashboardDatasource.associations) {
      const filterPath = getFilterPath(
        widget.configuration.dataSource.engineName || widget.configuration.dataSource.name,
        dashboard,
      );

      if (filterPath.relatedNodes.length > 0 && externalFilters.length > 0) {
        return from(externalFilters).pipe(
          mergeMap((filter) =>
            this.calculateFiltersForMultiDatasource(widget, dashboard, filterPath, externalFilters, filter),
          ),
          toArray(),
          map((filters) => [...filters, ...externalFilters]),
        );
      }
    }

    return of(externalFilters);
  }

  private calculateFiltersForMultiDatasource(
    widget: PageWidget,
    dashboard: Dashboard,
    filterPath: FilterPathRootNode,
    sourceFilters: Filter[],
    filter: Filter,
  ): Observable<Filter | AssociationFilter> {
    if (!filterPathContainsDatasource(filterPath, filter.dataSource)) {
      return of(filter);
    }

    const needValuesForCurrentWidget =
      widget.configuration.dataSource.engineName === filter.dataSource ||
      widget.configuration.dataSource.name === filter.dataSource;

    if (needValuesForCurrentWidget) {
      return of(filter);
    }

    return this.createAssociationFilterFromExternalFilter(widget, dashboard, filterPath, sourceFilters, filter);
  }

  private createAssociationFilterFromExternalFilter(
    widget: PageWidget,
    dashboard: Dashboard,
    filterPath: FilterPathRootNode,
    sourceFilters: Filter[],
    currentFilter: Filter,
  ): Observable<AssociationFilter> {
    const currentPathNode = getFilterPathNodesByDsId(filterPath, currentFilter.dataSource);

    return from(currentPathNode).pipe(
      mergeMap((currentNode, index) =>
        this.getFilterFromRelatedSource(
          widget,
          dashboard,
          currentNode.association,
          index > 0 ? [...sourceFilters, currentFilter] : sourceFilters,
          currentFilter,
        ),
      ),
      catchError(() => {
        return of(currentFilter as AssociationFilter);
      }),
    );
  }

  private getFilterFromRelatedSource(
    widget: PageWidget,
    dashboard: Dashboard,
    association: BoardDataSourceRelation,
    sourceFilters: Filter[],
    currentFilter: Filter | AssociationFilter,
  ): Observable<AssociationFilter> {
    const isSource = association.source === currentFilter.dataSource;
    const sourceKey = Object.keys(association.columnPair)[0];
    const targetKey = association.columnPair[sourceKey];

    return this.getValueListForSource(
      widget,
      dashboard,
      isSource ? association.source : association.target,
      sourceFilters,
      isSource ? sourceKey : targetKey,
    ).pipe(
      map((valueList) =>
        createAssociationFilter(
          isSource ? association.target : association.source,
          isSource ? targetKey : sourceKey,
          valueList,
        ),
      ),
      catchError(() => {
        return of(currentFilter as AssociationFilter);
      }),
    );
  }

  private getValueListForSource(
    widget: PageWidget,
    dashboard: Dashboard,
    datasourceName: string,
    sourceFilters: Filter[],
    key: string,
  ): Observable<string[]> {
    const request = createAssociationDataRequest(widget, dashboard, datasourceName, sourceFilters, key);

    return this.datasourceDomainService.searchQuery(request).pipe(
      map((response) => response.columns.find((column) => column.name === key)?.value || []),
      catchError(() => {
        return of([]);
      }),
    );
  }
}
