import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { StorageService } from '../../data-storage/service/storage.service';

@Injectable()
export class StagedbEnabledGuard {
  constructor(private storageService: StorageService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.storageService
      .checkEnableStageDB()
      .then(() => true)
      .catch(() => true);
  }
}
