import { KE_DATASOURCES_PATH, KE_ROOT_ROUTE, KE_WORKFLOW_PATH } from '@selfai-platform/shell';

export const dataSourceListRoute: string[] = ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH];

export function getWorkflowEditRoute(id: string): string[] {
  return ['/', KE_ROOT_ROUTE, KE_WORKFLOW_PATH, id];
}

export function getDataSourceEditRoute(id: string): string[] {
  return ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'edit', id];
}

export function getDataSourceCreateRoute(type: string): string[] {
  return ['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH, 'create', type];
}
