import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { KE_WORKFLOWS_LIST_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { WorkflowApiService } from '@selfai-platform/pipeline-module';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsListDataService extends DefaultDataService<WorkflowInfo> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly workflowApiService: WorkflowApiService,
  ) {
    super(KE_WORKFLOWS_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<WorkflowInfo[]> {
    return this.workflowApiService
      .getAllWorkflows()
      .pipe(map((items) => items.map((item) => ({ ...item, name: item.name }))));
  }
}
