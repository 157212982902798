import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  DatasourceField,
  LabelRotate,
  MarkLineSetting,
  MarkLineStyle,
  MarkLineType,
  MarkLineUiSetting,
} from '@selfai-platform/bi-domain';
import { FlatFormGroup } from '@selfai-platform/shared';
import { omit } from 'lodash';
import { Observable, debounceTime, map } from 'rxjs';

@Component({
  selector: 'selfai-platform-bi-chart-mark-line-setting',
  templateUrl: './chart-mark-line-setting.component.html',
  styleUrls: ['./chart-mark-line-setting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartMarkLineSettingComponent implements OnInit {
  @Input() index?: number;
  @Input() setting: MarkLineSetting;
  @Input() measureList: DatasourceField[];

  @Output() changeUiSetting = new EventEmitter<MarkLineUiSetting>();
  @Output() changeDataSetting = new EventEmitter<MarkLineSetting>();

  markLineTypeList$: Observable<{ name: string; value: MarkLineType }[]>;
  lineStyleList$: Observable<{ name: string; value: MarkLineStyle }[]>;
  labelRotateList$: Observable<{ name: string; value: LabelRotate }[]>;

  form = new FormGroup<FlatFormGroup<MarkLineSetting>>({
    measureName: new FormControl<string>(null, [Validators.required]),
    markLineType: new FormControl<MarkLineType>(null, [Validators.required]),
    percentileAmount: new FormControl<number>(95, [Validators.required, Validators.min(1), Validators.max(100)]),
    lineStyle: new FormControl<MarkLineStyle>(null, [Validators.required]),
    lineColor: new FormControl<string>(null, [Validators.required]),
    labelRotate: new FormControl<LabelRotate>(null, [Validators.required]),
  });

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initLists();

    // this.form.valueChanges
    //   .pipe(
    //     distinctUntilChanged(
    //       (prev, next) =>
    //         prev.measureName !== next.measureName ||
    //         prev.markLineType !== next.markLineType ||
    //         prev.percentileAmount !== next.percentileAmount,
    //     ),
    //     debounceTime(500),
    //   )
    //   .subscribe((value) => {
    //     this.changeDataSetting.emit(this.normalizeMarkLineSetting(value));
    //   });

    this.form.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.changeUiSetting.emit(this.normalizeMarkLineSetting(this.normalizeMarkLineUiSetting(value)));
    });
    // попробовать обрабатывать значение на уровне контрола
    this.form.patchValue(this.setting, { emitEvent: false });
  }

  private normalizeMarkLineUiSetting(value: Partial<MarkLineUiSetting>): MarkLineUiSetting {
    const exludeKeys: (keyof MarkLineUiSetting)[] = [];

    if (value.labelRotate === null) {
      exludeKeys.push('labelRotate');
    }

    return omit(value, exludeKeys) as MarkLineUiSetting;
  }

  private normalizeMarkLineSetting(value: Partial<MarkLineSetting>): MarkLineSetting {
    const normalizedValue = { ...value };
    const exludeKeys: (keyof MarkLineSetting)[] = [];

    if (value.markLineType !== 'percentile') {
      exludeKeys.push('percentileAmount');
    }

    return omit(normalizedValue, exludeKeys) as MarkLineSetting;
  }

  private initLists(): void {
    this.markLineTypeList$ = this.translateService
      .get([
        'bi.chart.mark-setting.mark-line-type-list.average',
        'bi.chart.mark-setting.mark-line-type-list.percentile',
      ])
      .pipe(
        map((translations: Record<string, string>) => ({
          avarage: translations['bi.chart.mark-setting.mark-line-type-list.average'],
          percentile: translations['bi.chart.mark-setting.mark-line-type-list.percentile'],
        })),
        map((translations) => [
          { name: translations.avarage, value: 'average' },
          { name: translations.percentile, value: 'percentile' },
        ]),
      );

    this.lineStyleList$ = this.translateService
      .get([
        'bi.chart.mark-setting.mark-line-style-list.solid',
        'bi.chart.mark-setting.mark-line-style-list.dashed',
        'bi.chart.mark-setting.mark-line-style-list.dotted',
      ])
      .pipe(
        map((translations: Record<string, string>) => ({
          solid: translations['bi.chart.mark-setting.mark-line-style-list.solid'],
          dashed: translations['bi.chart.mark-setting.mark-line-style-list.dashed'],
          dotted: translations['bi.chart.mark-setting.mark-line-style-list.dotted'],
        })),
        map((translations) => [
          { name: translations.solid, value: 'solid' },
          { name: translations.dashed, value: 'dashed' },
          { name: translations.dotted, value: 'dotted' },
        ]),
      );

    this.labelRotateList$ = this.translateService
      .get(['bi.chart.mark-setting.label-rotate-list.vertical', 'bi.chart.mark-setting.label-rotate-list.horizontal'])
      .pipe(
        map((translations: Record<string, string>) => ({
          vertical: translations['bi.chart.mark-setting.label-rotate-list.vertical'],
          horizontal: translations['bi.chart.mark-setting.label-rotate-list.horizontal'],
        })),
        map((translations) => [
          { name: translations.vertical, value: 'vertical' },
          { name: translations.horizontal, value: 'horizontal' },
        ]),
      );
  }
}
