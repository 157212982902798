import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';

import { Modal } from '../../../domain/modal';
import { AbstractComponent } from '../../abstract.component';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create.component.html',
})
export class CreateModalComponent extends AbstractComponent implements OnInit, OnDestroy {
  public isShow = false;

  public modal: Modal;

  @Output() public createConfirm = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(modal: Modal) {
    this.isShow = true;
    this.modal = modal;
  }

  public done() {
    this.isShow = false;
    this.createConfirm.emit(this.modal);
  }

  private close() {
    this.isShow = false;
  }
}
