import { Injectable } from '@angular/core';
import { PermissionService, PipelinePermissionEntity, WorkflowPermissionAction } from '@selfai-platform/shared';
import { Observable, combineLatestWith, filter, forkJoin, map, switchMap, take } from 'rxjs';
import { WorkflowDetailService } from '../workflow/workflow-detail.service';
import { KeUserService } from '../../../user';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPermissionService {
  get workflowPermissionsCore(): WorkflowPermissionAction[] {
    return Object.values(WorkflowPermissionAction);
  }

  constructor(
    private readonly workflowDetailService: WorkflowDetailService,
    private readonly permissionService: PermissionService,
    private readonly keUserProfileService: KeUserService,
  ) {}

  getWorkflowPermissions(workflowId: string): Observable<WorkflowPermissionAction[]> {
    return this.permissionService.pipelinePermissionsLoadedObs$.pipe(
      filter((loaded) => loaded),
      combineLatestWith(
        this.workflowDetailService.loadWorkflow(workflowId),
        this.keUserProfileService.getCurrentUserProfile(),
      ),
      switchMap(([loaded, workflow, userProfile]) => {
        const isOwner = workflow.workflowInfo.ownerId === userProfile.id;
        const permissionChecks$ = this.workflowPermissionsCore.map((permissionAction) =>
          this.permissionService
            .checkPermission({
              entity: PipelinePermissionEntity.Workflows,
              action: permissionAction,
              isOwner,
            })
            .pipe(
              map((hasPermission) => (hasPermission ? permissionAction : null)),
              take(1),
            ),
        );

        return forkJoin(permissionChecks$).pipe(map((results) => results.filter((result) => result !== null)));
      }),
    );
  }
}
