import { EntityMetadataMap } from '@ngrx/data';

export const KE_DATA_SOURCES_LIST_ENTITY_NAME = 'keDataSource';

const entityMetadata: EntityMetadataMap = {
  [KE_DATA_SOURCES_LIST_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const keDataSourceEntityConfig = {
  entityMetadata,
};
