import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ConstantValueFieldComponent } from './constant-value-field.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputTextModule, InputNumberModule],
  declarations: [ConstantValueFieldComponent],
  exports: [ConstantValueFieldComponent],
})
export class ConstantValueFieldComponentModule {}
