import { Injectable } from '@angular/core';
import {
  BiPermission,
  KdConfigService,
  KdFeatureEnableService,
  KdFeatureName,
  PermissionService,
} from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import {
  BI_ROOT_ROUTE,
  GUEST_WORKSPACE_ROUTE,
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  PATH_MAIN_DASHBOARD,
} from '../navigation';

@Injectable({ providedIn: 'root' })
export class KdMenuItemService {
  private kdMenuItems = new BehaviorSubject<MenuItem[]>([]);
  kdMenuItems$ = this.kdMenuItems.asObservable();

  constructor(
    private readonly kdConfigService: KdConfigService,
    private readonly kdEnbaledFeatureService: KdFeatureEnableService,
    private readonly permissionService: PermissionService,
  ) {
    this.permissionService.biPermessionsLoadedObs$.subscribe(() => {
      const kdMenu = [
        this.calculateMainDashboardMenuChunk(),
        this.calculateKdMenuChunk(),
        this.calculateDataPrepMenuChunk(),
      ].filter((t) => !!t);
      this.kdMenuItems.next(kdMenu);
    });
  }

  private calculateKdMenuChunk() {
    if (!this.kdConfigService.getConfig()?.enabled) {
      return null;
    }

    const items: MenuItem[] = [];

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.WORKBOOKS) &&
      this.permissionService.hasOneOfBiPermission([
        BiPermission.KD_WORKSPACE_VIEW_DAC,
        BiPermission.KD_WORKSPACE_MY,
        BiPermission.KD_WORKSPACE_EDIT_ALL,
        BiPermission.KD_WORKSPACE_VIEW_ALL,
        BiPermission.KD_WORKSPACE_CREATE,
        BiPermission.KD_WORKSPACE_DELETE_ALL,
      ])
    ) {
      items.push({
        label: 'shell.menu.all-workspaces',
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspaces'],
        icon: 'pi pi-users',
      });

      items.push({
        label: 'shell.menu.guest-workspace',
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', GUEST_WORKSPACE_ROUTE],
        icon: 'pi pi-share-alt',
      });

      if (this.permissionService.hasBiPermission(BiPermission.KD_WORKSPACE_MY)) {
        items.push({
          label: 'shell.menu.my-workspace',
          routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'workspace', 'my'],
          icon: 'pi pi-user',
        });
      }
    }

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DASHBOARDS) &&
      this.permissionService.hasOneOfBiPermission([BiPermission.KD_WORKSPACE_VIEW_DAC, BiPermission.KD_WORKSPACE_MY])
    ) {
      items.push({
        label: 'shell.menu.dashboards',
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'dashboards'],
        icon: 'pi pi-chart-line',
      });
    }

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.CHARTS) &&
      this.permissionService.hasOneOfBiPermission([BiPermission.KD_WORKSPACE_VIEW_DAC, BiPermission.KD_WORKSPACE_MY])
    ) {
      items.push({
        label: 'shell.menu.charts',
        routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'charts'],
        icon: 'pi pi-chart-bar',
      });
    }

    items.push({
      label: 'shell.menu.favorites',
      routerLink: ['/', KNOWLEDGE_DESIGNER_ROOT_ROUTE, 'favorites'],
      icon: 'pi pi-star',
    });

    if (items.length < 0) {
      return null;
    }

    return {
      id: 'projects',
      label: 'shell.menu.knowledge-designer',
      state: { sort: 200 },
      items,
    };
  }

  private calculateMainDashboardMenuChunk() {
    if (
      !this.kdConfigService.getConfig()?.enabled ||
      !this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.MAIN_DASHBOARD) ||
      !this.permissionService.hasBiPermission(BiPermission.KD_WORKSPACE_VIEW_DAC)
    ) {
      return null;
    }

    return {
      id: 'main',
      label: 'shell.menu.maindashboard',
      routerLink: ['/' + PATH_MAIN_DASHBOARD],
      state: {
        sort: 100,
      },
    };
  }

  private calculateDataPrepMenuChunk() {
    if (!this.kdConfigService.getConfig()?.enabled) {
      return null;
    }

    const items: MenuItem[] = [];

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DATASETS) &&
      this.permissionService.hasBiPermission(BiPermission.KD_DATASET_VIEW_ALL)
    ) {
      items.push({
        label: 'shell.menu.datasets',
        icon: 'pi pi-list',
        routerLink: [BI_ROOT_ROUTE, 'management', 'datapreparation', 'dataset'],
      });
    }

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DATAFLOWS) &&
      this.permissionService.hasBiPermission(BiPermission.KD_DATAFLOW_VIEW_ALL)
    ) {
      items.push({
        label: 'shell.menu.dataflows',
        icon: 'pi pi-chart-bar',
        routerLink: [BI_ROOT_ROUTE, 'management', 'datapreparation', 'dataflow'],
      });
    }

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.DATASOURCES) &&
      this.permissionService.hasBiPermission(BiPermission.KD_DATASOURCE_VIEW_ALL)
    ) {
      items.push({
        label: 'shell.menu.datasources',
        icon: 'pi pi-database',
        routerLink: [BI_ROOT_ROUTE, 'management', 'storage', 'datasource'],
      });
    }

    if (
      this.kdEnbaledFeatureService.isEnabledFeature(KdFeatureName.CONNECTIONS) &&
      this.permissionService.hasBiPermission(BiPermission.KD_DATASOURCE_VIEW_ALL)
    ) {
      items.push({
        label: 'shell.menu.connections',
        icon: 'pi pi-chevron-circle-right',
        routerLink: [BI_ROOT_ROUTE, 'management', 'storage', 'data-connection'],
      });
    }

    if (items.length < 0) {
      return null;
    }

    return {
      id: 'data-prep',
      label: 'shell.menu.datapreparation',
      state: { sort: 400 },
      items,
    };
  }
}
