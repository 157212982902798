import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { WorkflowSettingsSchedulesInterface } from '../../interface';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primeng/button';
import { CronJobsModule } from 'ngx-cron-jobs';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { WorkflowPresetsInterface } from '../../../workflow-presets';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash/fp';
import { CronJobsConfig, CronJobsValidationConfig } from 'ngx-cron-jobs/src/app/lib/contracts/contracts';

@Component({
  selector: 'selfai-platform-workflow-settings-schedules-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    Button,
    CronJobsModule,
    DropdownModule,
    OverlayPanelModule,
    FloatLabelModule,
    InputTextModule,
    FormsModule,
    DialogModule,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './workflow-settings-schedules-form.component.html',
  styleUrl: './workflow-settings-schedules-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowSettingsSchedulesFormComponent implements OnChanges, OnInit {
  public cronEditorVisible = false;
  defaultSchedule: WorkflowSettingsSchedulesInterface = {
    id: uuidv4(),
    schedule: {
      cron: '',
      timezone: '',
    },
    workflowId: '',
    executionInfo: {
      emailForReports: '',
      presetId: 1,
    },
  };

  currentSchedule: WorkflowSettingsSchedulesInterface;

  schedulesForm: FormGroup = this.fb.group({
    preset: ['', [Validators.required]],
    timeZone: ['', [Validators.required]],
    cron: ['', [Validators.required]],
  });

  cronConfig: CronJobsConfig = {
    quartz: false,
    multiple: true,
    bootstrap: true,
  };

  cronValidate: CronJobsValidationConfig = {
    validate: true,
  };

  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

  @Input() workflowId: string;
  @Input() schedule: WorkflowSettingsSchedulesInterface;
  @Input() presets!: WorkflowPresetsInterface[];
  @Output() cancelEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['schedule']?.currentValue) {
      this.currentSchedule = cloneDeep(this.schedule);
      this.schedulesForm.setValue({
        preset: this.schedule?.executionInfo?.presetId,
        timeZone: this.schedule?.schedule?.timezone,
        cron: this.schedule?.schedule?.cron,
      });
    }
  }

  ngOnInit(): void {
    if (!this.schedule) {
      this.currentSchedule = this.defaultSchedule;
      this.currentSchedule.workflowId = this.workflowId;
    }
  }

  generateTimeZones(): { name: string; id: string }[] {
    const schedulerOptions = [];
    for (let i = -12; i < 13; i++) {
      const value = i < 0 ? String(i).padStart(2, '0') : `+${String(i).padStart(2, '0')}`;
      schedulerOptions.push({ name: `GMT${value}:00`, id: `GMT${value}:00` });
    }

    return schedulerOptions;
  }

  onCancel(): void {
    this.cancelEvent.emit();
  }

  onSubmit(): void {
    this.schedulesForm.markAllAsTouched();
    this.changeDetectorRef.detectChanges();
    if (this.schedulesForm.valid) {
      this.currentSchedule.schedule.cron = this.schedulesForm.value.cron;
      this.currentSchedule.schedule.timezone = this.schedulesForm.value.timeZone;
      this.currentSchedule.executionInfo.presetId = this.schedulesForm.value.preset;
      this.submitEvent.emit(this.currentSchedule);
    }
  }

  public toggleEditCronDialog(): void {
    this.cronEditorVisible = !this.cronEditorVisible;
  }

  public applyCronFromEditor(): void {
    this.changeDetectorRef.markForCheck();
    const cron = this.schedulesForm.get('cron').value;
    this.schedulesForm.patchValue({ cron });
    this.cronEditorVisible = false;
  }
}
