import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  DataListViewComponent,
  GroupAction,
  provideDataListView,
  provideDialogService,
  SelectedItemsService,
} from '@selfai-platform/shell';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { DestroyService, downloadJson, readJsonFile } from '@selfai-platform/shared';
import { Observable, switchMap } from 'rxjs';
import { UserFunctionsDataListViewService } from '../../services';
import { UserFunctionsActionsForItemService } from '../../services/user-functions-actions-for-item.service';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { IFunctionTag, UserFunctionsListItem } from '../../models';
import { UserFunctionsApiService } from '../../services/user-functions-api.service';
import { FileUploadHandlerEvent, FileUploadModule } from 'primeng/fileupload';
import { UserFunctionApi } from '../../models/user-functions-api.model';
import { UserFunctionsPermissionService } from '../../services/user-functions-permission.service';

@Component({
  selector: 'selfai-platform-user-functions-list',
  templateUrl: './user-functions-list.component.html',
  styleUrls: ['./user-functions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslateModule, DataListViewComponent, MenuModule, ButtonModule, MultiSelectModule, FormsModule, FileUploadModule],
  standalone: true,
  providers: [
    DestroyService,
    ...provideDialogService(),
    ...provideDataListView(UserFunctionsDataListViewService, UserFunctionsActionsForItemService)
  ]
})
export class UserFunctionsListComponent {
  public canCreate = this.userFunctionPermissionService.canCreateUserFunction();
  public selectedTags: IFunctionTag[];
  public columns = this.userFunctionsDataListViewService.getColumns();
  public groupActions$: Observable<GroupAction[]> = this.userFunctionsDataListViewService.getGroupActions();

  @Input() tags: IFunctionTag[];

  constructor(
    private readonly userFunctionsDataListViewService: UserFunctionsDataListViewService,
    private readonly userFunctionsApiService: UserFunctionsApiService,
    private readonly userFunctionPermissionService: UserFunctionsPermissionService,
    private readonly selectedItemsService: SelectedItemsService<UserFunctionsListItem>,
  ) {}

  public onFilterByTags(event: MultiSelectChangeEvent): void {
    this.userFunctionsDataListViewService.filterData(event.value);
  }

  public onClearTagsFilter(): void {
    this.userFunctionsDataListViewService.filterData([]);
  }

  public importUserFunction(data: FileUploadHandlerEvent): void {
    const sub = readJsonFile(data.files[0])
      .pipe(switchMap((data) => this.userFunctionsApiService.importUserFunctions(data as UserFunctionApi[])))
      .subscribe(() => {
        this.userFunctionsDataListViewService.loadData();
        sub.unsubscribe();
      });
  }

  public exportUserFunction(): void {
    const sub = this.selectedItemsService
      .getSelectedItemIds()
      .pipe(switchMap(selectedItems => this.userFunctionsApiService.exportUserFunctions(selectedItems)))
      .subscribe((data) => {
        downloadJson(data as UserFunctionApi[], 'functions.json');
        sub.unsubscribe();
      });
  }
}
