import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';

import { UpdateStr } from '@ngrx/entity/src/models';
import { WidgetApiService } from '@selfai-platform/bi-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WIDGET_ENTITY_NAME } from '../constants';
import { Widget, WidgetState } from '../models';
import { widgetApiResponseToSpecWidgetsNormalizer } from '../normalizer';

@Injectable()
export class WidgetDataService extends DefaultDataService<WidgetState> {
  constructor(
    private readonly widgetApiService: WidgetApiService,
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
  ) {
    super(WIDGET_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(id: string): Observable<Widget> {
    return this.widgetApiService.getWidget(id).pipe(map(widgetApiResponseToSpecWidgetsNormalizer));
  }

  override delete(id: string): Observable<string> {
    return this.widgetApiService.deleteWidget(id).pipe(map(() => id));
  }

  override update(update: UpdateStr<WidgetState>): Observable<WidgetState> {
    return this.widgetApiService
      .updateWidget(update.id, update.changes)
      .pipe(map(widgetApiResponseToSpecWidgetsNormalizer));
  }

  override add(widget: WidgetState): Observable<WidgetState> {
    return this.widgetApiService
      .createWidget(widget, widget.dashBoardId)
      .pipe(map(widgetApiResponseToSpecWidgetsNormalizer));
  }
}
