<div class="flex-column">
  <ng-container *ngIf="index > 0 || index < count - 1">
    <button
      type="button"
      appearance="icon"
      pButton
      class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
      icon="pi pi-arrow-up"
      [disabled]="index === 0"
      (click)="orderUp.emit(index)"
    ></button>
    <button
      type="button"
      appearance="icon"
      pButton
      class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
      icon="pi pi-arrow-down"
      [disabled]="index === count - 1"
      (click)="orderDown.emit(index)"
    ></button>
  </ng-container>
  <button
    (click)="removeStep.emit(index)"
    pButton
    class="p-button-text p-button-rounded p-button-link mb-1 p-button-danger"
    appearance="icon"
    icon="pi pi-trash"
    type="button"
  ></button>
</div>
