<ng-container *ngIf="'default' === compType">
  <input
    #inputElm
    #styleElm
    placeholder="{{ placeHolder }}"
    (keyup)="keyupHandler($event)"
    (focus)="focusHandler()"
    (blur)="blurHandler()"
    (mousedown)="$event.stopPropagation()"
    (mousemove)="$event.stopPropagation()"
    class="{{ inputClass }} {{ optionalClass }}"
    type="text"
  />
  <a
    href="javascript:"
    class="ddp-btn-delete"
    *ngIf="isEnableDelete && isNotEmptyInput()"
    (click)="clearValue()"
    [style.display]="isNotEmptyInput() ? 'block' : 'none'"
  ></a>
</ng-container>

<ng-container *ngIf="'apply' === compType">
  <div #styleElm class="ddp-input-apply {{ optionalClass }}">
    <input
      #inputElm
      placeholder="{{ placeHolder }}"
      (keyup)="keyupHandler($event)"
      (focus)="focusHandler()"
      (blur)="blurHandler()"
      (mousedown)="$event.stopPropagation()"
      (mousemove)="$event.stopPropagation()"
      class="{{ inputClass }}"
      type="text"
    />
    <em (click)="setValue()" class="ddp-icon-apply"></em>
    <em class="ddp-icon-apply-loading"></em>
  </div>
</ng-container>

<ng-container *ngIf="'search' === compType">
  <div #styleElm class="ddp-form-search {{ optionalClass }}">
    <em class="ddp-icon-search"></em>
    <input
      #inputElm
      placeholder="{{ placeHolder }}"
      (keyup)="keyupHandler($event)"
      (focus)="focusHandler()"
      (blur)="blurHandler()"
      (mousedown)="$event.stopPropagation()"
      (mousemove)="$event.stopPropagation()"
      type="text"
    />
    <em *ngIf="showClear && isNotEmptyInput()" (click)="clearValue()" class="ddp-btn-search-close"></em>
  </div>
</ng-container>
