import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { ActionsForItemService, convertMessageToHtml, DataListItemAction } from '@selfai-platform/shell';
import { WorkflowPresetsPermissionService } from './workflow-presets-permission.service';
import { WorkflowPresetsDomainService } from './workflow-presets-domain.service';
import { WorkflowPresetsInterface } from '../interfaces/workflow-presets.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsActionForItemService extends ActionsForItemService<any>{
  constructor(
    private readonly workflowPresetsDomainService: WorkflowPresetsDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly workflowPresetsPermissionService: WorkflowPresetsPermissionService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  override getActionsForItem(preset: WorkflowPresetsInterface): Observable<DataListItemAction[]> {
    return combineLatest({
      canDelete: this.workflowPresetsPermissionService.canDeletePreset(),
      tooltipTranslate: this.translate.get('workflow-list.table.action.item.delete'),
    }).pipe(
      map(({ canDelete, tooltipTranslate }) => {
        if (canDelete) {
          return [
            {
              label: tooltipTranslate,
              icon: 'pi pi-trash',
              action: () => this.delete(preset.id),
            },
          ];
        }

        return [];
      }),
    );
  }

  private delete(id: number): void {
    // TODO: Move to the confirmation with key = dialog to data list view
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('workflow.preset.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.workflowPresetsDomainService.delete(id);
      },
    });
  }
}
