import { CommonModule as AngularCommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, COMPOSITION_BUFFER_MODE, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CalendarModule } from 'primeng/calendar';
import { ColorPickerModule } from 'primeng/colorpicker';
import * as SockJS from 'sockjs-client';

import { BiSharedModule } from '@selfai-platform/bi-shared';

import { DashboardDatasourceComboComponent } from '../dashboard/component/dashboard-datasource-combo.component';
import { MetadataTypeBoxTagComponent } from '../explore-data/explore-data/component/metadata-type-box-tag.component';

import { ColorTemplateComponent } from './component/color-picker/color-template.component';
import { ColorPickerComponent } from './component/color-picker/color.picker.component';
import { DropBoxComponent } from './component/drop-box/drop-box.component';
import { GradationGeneratorComponent } from './component/gradation/gradation-generator.component';
import { Grid2Component } from './component/grid/grid-2.component';
import { GridComponent } from './component/grid/grid.component';
import { SvgIconComponent } from './component/icon/svg-icon.component';
import { InputComponent } from './component/input/input.component';
import { LoadingComponent } from './component/loading/loading.component';
import { ConfirmRefModalComponent } from './component/modal/confirm/confirm-ref.component';
import { ConfirmModalComponent } from './component/modal/confirm/confirm.component';
import { ConfirmSmallComponent } from './component/modal/confirm-small/confirm-small.component';
import { CreateModalComponent } from './component/modal/create/create.component';
import { DeleteModalComponent } from './component/modal/delete/delete.component';
import { LogComponent } from './component/modal/log/log.component';
import { MultiSelectComponent } from './component/multi-select/multi-select.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { PagingSearchSelectComponent } from './component/paging-search-select/paging-search-select.component';
import { PagingSelectComponent } from './component/paging-select/paging-select.component';
import { PeriodComponent } from './component/period/period.component';
import { FilterSelectBoxComponent } from './component/select/filter-select-box.component';
import { SelectComponent } from './component/select/select.component';
import { SortDropBoxComponent } from './component/sort-drop-box/sort-drop-box.component';
import { UserInformationComponent } from './component/user-information/user-information.component';
import { CommonConstant } from './constant/common.constant';
import { FocusDirective } from './directive/focus.directive';
import { InputMaskDirective } from './directive/input-mask.directive';
import { InvokeDirective } from './directive/invoke.directive';
import { LoaderInterceptor } from './interceptor/loader-interceptor';
import { BaseFilter } from './pipe/base-filter';
import { BaseSort } from './pipe/base-sort';
import { EnumListPipe } from './pipe/enum-list.pipe';
import { KeysPipe } from './pipe/keys.pipe';
import { MomentDatePipe } from './pipe/moment.date.pipe';
import { MomentPipe } from './pipe/moment.pipe';
import { MomentmodifiedPipe } from './pipe/momentmodified.pipe';
import { NumberCommasPipe } from './pipe/number-commas.pipe';
import { RangePipe } from './pipe/range';
import { FilterSearchPipe } from './pipe/search';
import { ShortNamePipe } from './pipe/short-name.pipe';

export function socketProvider() {
  return new SockJS(CommonConstant.API_CONSTANT.URL + '/stomp');
}

@NgModule({
  imports: [
    AngularCommonModule,
    FormsModule,
    NgClickOutsideDirective,
    InfiniteScrollModule,
    TranslateModule,
    CalendarModule,
    ColorPickerModule,
    ReactiveFormsModule,
    BiSharedModule,
  ],
  declarations: [
    BaseFilter,
    BaseSort,
    KeysPipe,
    EnumListPipe,
    NumberCommasPipe,
    MomentPipe,
    MomentmodifiedPipe,
    MomentDatePipe,
    FilterSearchPipe,
    RangePipe,
    ShortNamePipe,
    SelectComponent,
    FilterSelectBoxComponent,
    MultiSelectComponent,
    PagingSelectComponent,
    PagingSearchSelectComponent,
    DropBoxComponent,
    SortDropBoxComponent,
    DeleteModalComponent,
    PeriodComponent,
    InputMaskDirective,
    FocusDirective,
    InvokeDirective,
    CreateModalComponent,
    ConfirmModalComponent,
    ConfirmRefModalComponent,
    LoadingComponent,
    GridComponent,
    Grid2Component,
    LogComponent,
    UserInformationComponent,
    ConfirmSmallComponent,
    ColorPickerComponent,
    GradationGeneratorComponent,
    DashboardDatasourceComboComponent,
    ColorTemplateComponent,
    SvgIconComponent,
    InputComponent,
    PaginationComponent,
    MetadataTypeBoxTagComponent,
  ],
  exports: [
    AngularCommonModule,
    FormsModule,
    NgClickOutsideDirective,
    BaseFilter,
    BaseSort,
    KeysPipe,
    EnumListPipe,
    NumberCommasPipe,
    MomentPipe,
    MomentDatePipe,
    MomentmodifiedPipe,
    FilterSearchPipe,
    RangePipe,
    ShortNamePipe,
    SelectComponent,
    FilterSelectBoxComponent,
    MultiSelectComponent,
    PagingSelectComponent,
    PagingSearchSelectComponent,
    DropBoxComponent,
    SortDropBoxComponent,
    DeleteModalComponent,
    CreateModalComponent,
    ConfirmModalComponent,
    ConfirmRefModalComponent,
    PeriodComponent,
    InputMaskDirective,
    FocusDirective,
    InvokeDirective,
    LoadingComponent,
    GridComponent,
    LogComponent,
    UserInformationComponent,
    ConfirmSmallComponent,
    ColorPickerComponent,
    GradationGeneratorComponent,
    DashboardDatasourceComboComponent,
    ColorTemplateComponent,
    SvgIconComponent,
    InputComponent,
    PaginationComponent,
    MetadataTypeBoxTagComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: COMPOSITION_BUFFER_MODE,
      useValue: false,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModule {}
