import { HistoryEntity } from '../../core';
import { JoinMapping } from '../../workbook';
import { AggregationType, FieldPivot, FieldRole, GranularityType, LogicalType } from '../enums';
import { DerivationRule } from './derivation-rule.model';
import { FieldFormat } from './field-format.model';
import { IngestionRule } from './ingestion.model';
import { MetadataColumn } from './metadata-column.model';

export interface DatasourceField {
  id?: number | string;
  name?: string;
  originalName?: string;
  logicalName?: string;
  description?: string;
  type?: string;
  logicalType?: LogicalType;
  role?: FieldRole;
  partitioned?: boolean;
  filtering?: boolean;
  filteringSeq?: number;
  filteringOptions?: any;
  aggrType?: AggregationType;
  unloaded?: boolean;
  seq?: number;
  derived?: boolean;
  derivationRule?: DerivationRule;
  ingestionRule?: IngestionRule;
  mappedField?: DatasourceField[];
  format?: FieldFormat;
  alias?: string;
  useFilter?: boolean;
  useChartFilter?: boolean;
  useChart?: boolean;
  ref?: string;
  join?: JoinMapping;
  expr?: string;
  aggregated?: boolean;
  pivot?: FieldPivot[];
  headerKey?: string;
  dsName?: string;
  granularity?: GranularityType;
  segGranularity?: GranularityType;
  isValidType?: boolean;
  isValidReplaceValue?: boolean;
  checked?: boolean;
  isEdit?: boolean;
  editName?: string;
  isInvalidName?: boolean;
  invalidNameMessage?: string;
  isShowTypeList?: boolean;
  dsId?: string;
  dataSource?: string;
  boardId?: string;
  nameAlias?: FieldNameAlias;
  valueAlias?: FieldValueAlias;
  uiMetaData?: MetadataColumn;
  physicalName?: string;
  op?: 'replace';
}

export function createDatasourceField(params: Partial<DatasourceField> = {}): DatasourceField {
  return {
    filteringSeq: 0,
    useChart: false,
    useChartFilter: false,
    useFilter: false,
    ...params,
  };
}

export interface FieldAlias extends HistoryEntity {
  id?: number;
  dataSourceId: string;
  dashBoardId: string;
  fieldName: string;
}

export interface FieldNameAlias extends FieldAlias {
  nameAlias: string;
}

export function createFieldNameAlias(
  params: Pick<FieldNameAlias, 'dataSourceId' | 'dashBoardId' | 'fieldName' | 'nameAlias'>,
): FieldNameAlias {
  return {
    ...params,
  };
}

export interface FieldValueAlias extends FieldAlias {
  valueAlias?: any;
}

export function createFieldValueAlias(
  params: Pick<FieldValueAlias, 'dataSourceId' | 'dashBoardId' | 'fieldName'>,
): FieldValueAlias {
  return {
    ...params,
  };
}
