import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { MessageModule } from 'primeng/message';
import { AggregatorImportFormComponent } from './aggregator-import-form.component';

@NgModule({
  imports: [CommonModule, FileUploadModule, CheckboxModule, FormsModule, MessageModule],
  declarations: [AggregatorImportFormComponent],
  exports: [AggregatorImportFormComponent],
})
export class AggregatorImportFormComponentModule {}
