import {
  OperationCatalogList,
  OperationDetail,
  OperationHierarchy,
  OperationList,
} from '@selfai-platform/pipeline-common';
import { Observable } from 'rxjs';

export abstract class OperationsAdapter {
  abstract loadList(): Observable<OperationList>;
  abstract loadById(id: string): Observable<OperationDetail>;
  abstract loadHierarchy(): Observable<OperationHierarchy>;
  abstract loadCatalog(): Observable<OperationCatalogList>;
}
