<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.yaxis.title' | translate }}
  </div>
  <div class="ddp-wrap-downmenu">
    <div *ngIf="possibleChartCheck('axis', uiOption.type)" class="ddp-box-down">
      <div class="ddp-wrap-divide" *ngIf="possibleChartCheck('yAxisTitle', uiOption.type)">
        <div class="ddp-divide2">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider" style="width: 100px; display: block">
              {{ 'msg.page.chart.axis.show.axis.title' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                (click)="showAxisName('column', !uiOption.yAxis.showName)"
                [ngModel]="uiOption.yAxis.showName"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <component-input
            [disabled]="!uiOption.yAxis.showName"
            [inputClass]="'ddp-input-typebasic'"
            [placeHolder]="'msg.page.ui.y-axis.title.ph' | translate"
            [value]="nameUiOption.yAxis.customName"
            (changeValue)="nameUiOption.yAxis.customName = $event; axisName('column', 1, $event)"
          ></component-input>
        </div>
      </div>

      <axis-category-option
        *ngIf="!isSecondaryAxis && 'category' == uiOption.yAxis?.label?.type.toString()"
        [axis]="uiOption.yAxis"
        [firstFl]="!possibleChartCheck('yAxisTitle', uiOption.type)"
        (changeCategoryAxis)="changeYAxisValue($event)"
      ></axis-category-option>

      <axis-value-option
        *ngIf="!isSecondaryAxis && 'value' == uiOption.yAxis?.label?.type.toString()"
        [axis]="uiOption.yAxis"
        [uiOption]="uiOption"
        (changeValueAxis)="changeYAxisValue($event)"
        (changeBaseline)="changeBaseline($event)"
      ></axis-value-option>
    </div>
  </div>
</div>
