import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Dashboard, Pivot, ShelveFieldType, WIDGET_CONTEXT_ID } from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import {
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  switchMap,
  takeUntil,
  throwError,
  withLatestFrom,
} from 'rxjs';
import {
  ChartDataService,
  ChartOptionsService,
  EChartEventsService,
  EChartService,
  GraphChartDataService,
  GraphChartDataSettingsService,
  GraphChartOptionsService,
  WidgetConfigService,
} from '../../../services';
import { GraphChartConfigBuilderService } from '../../../services/config-builders';
import { getCountAggregationsByFieldType, getCountColumnsByFieldType, getCountRowsByFieldType } from '../../../utils';

@Component({
  selector: 'selfai-bi-chart-graph-chart',
  templateUrl: './graph-chart.component.html',
  styleUrls: ['./graph-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DestroyService,
    {
      provide: ChartOptionsService,
      useClass: GraphChartOptionsService,
    },
    {
      provide: ChartDataService,
      useExisting: GraphChartDataService,
    },
  ],
})
export class GraphChartComponent implements OnInit, AfterViewInit {
  constructor(
    private readonly eChartService: EChartService,
    private readonly destroy$: DestroyService,
    private readonly chartDataService: ChartDataService,
    private readonly graphChartConfigBuilderService: GraphChartConfigBuilderService,
    private readonly graphChartDataSettingsService: GraphChartDataSettingsService,
    private readonly widgetConfigService: WidgetConfigService,
    private readonly eChartEventsService: EChartEventsService,
    @Inject(WIDGET_CONTEXT_ID) private contextId: string,
  ) {}

  @ViewChild('chartView', { read: ElementRef, static: true }) chartView: ElementRef<HTMLDivElement>;

  @Input() widgetId: string;
  @Input() isNew?: boolean;
  /**
   * Should be provided if widget is on dashboard
   */
  @Input() dashBoard?: Dashboard;

  ngOnInit(): void {
    if (this.allRequiredInputsArePresent()) {
      this.widgetConfigService
        .initWidgetSettings({ widgetId: this.widgetId, isNew: this.isNew, contextId: this.contextId })
        .pipe(takeUntil(this.destroy$))
        .subscribe();

      this.graphChartDataSettingsService
        .isSettingReadyForDisplay(this.widgetId, this.contextId)
        .pipe(
          distinctUntilChanged(),
          filter(Boolean),
          switchMap(() =>
            this.chartDataService
              .loadWidgetData({
                widgetId: this.widgetId,
                contextId: this.contextId,
              })
              .pipe(
                catchError((error) => {
                  return throwError(() => error);
                }),
              ),
          ),
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe();
    }
  }

  ngAfterViewInit(): void {
    // we should sure that container is rendered and it has width and height
    setTimeout(() => {
      combineLatest({
        echartInstance: this.eChartService.initChart(this.widgetId, this.chartView.nativeElement, this.contextId),
        config: this.graphChartConfigBuilderService.buildConfig(this.widgetId, this.contextId),
      })
        .pipe(takeUntil(this.destroy$))
        .subscribe(({ config, echartInstance }) => {
          if (echartInstance) {
            echartInstance.setOption(config, false, true);
            echartInstance.resize();
          }
        });

      fromEvent(window, 'resize')
        .pipe(
          debounceTime(500),
          withLatestFrom(this.eChartService.getInstance(this.widgetId, this.contextId)),
          takeUntil(this.destroy$),
        )
        .subscribe(([, echartInstance]) => {
          echartInstance.resize();
        });

      // TODO: add condition if chart in edit mode or alone we should not subscribe to this event
      this.eChartService
        .getEventFactory(this.widgetId, 'click', this.contextId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          this.eChartEventsService.handleClickEvent(event, this.widgetId, this.contextId);
        });

      this.eChartService
        .getEventFactory(this.widgetId, 'legendselectchanged', this.contextId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((event) => {
          console.log('legendselectchanged', event);
        });
    }, 500);
  }

  // TODO: need to remove this method. It is used to support legacy parent component
  isValid(pivot: Pivot): boolean {
    // TODO: need to check this condition and make it more clear
    return (
      getCountColumnsByFieldType(pivot, ShelveFieldType.DIMENSION) === 1 &&
      getCountColumnsByFieldType(pivot, ShelveFieldType.TIMESTAMP) === 0 &&
      getCountRowsByFieldType(pivot, ShelveFieldType.DIMENSION) === 1 &&
      getCountRowsByFieldType(pivot, ShelveFieldType.TIMESTAMP) === 0 &&
      getCountAggregationsByFieldType(pivot, ShelveFieldType.MEASURE) +
        getCountAggregationsByFieldType(pivot, ShelveFieldType.CALCULATED) ===
        1 &&
      getCountColumnsByFieldType(pivot, ShelveFieldType.MEASURE) === 0 &&
      getCountColumnsByFieldType(pivot, ShelveFieldType.CALCULATED) === 0 &&
      getCountRowsByFieldType(pivot, ShelveFieldType.MEASURE) === 0 &&
      getCountRowsByFieldType(pivot, ShelveFieldType.CALCULATED) === 0 &&
      getCountAggregationsByFieldType(pivot, ShelveFieldType.DIMENSION) === 0 &&
      getCountAggregationsByFieldType(pivot, ShelveFieldType.TIMESTAMP) === 0
    );
  }

  private allRequiredInputsArePresent(): boolean {
    return !!this.widgetId;
  }
}
