import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  PageParams,
  UrlPageParamsService,
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from '@selfai-platform/shared';
import pickBy from 'lodash/pickBy';
import { TableLazyLoadEvent } from 'primeng/table';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';

/**
 * @deprecated Use DataListViewComponentService instead
 */
@Injectable()
export class TableDataComponentService<T = unknown> implements OnDestroy {
  pageParams$: Observable<PageParams> = this.urlPageParamsService.getPageParams().pipe(
    filter(Boolean),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );

  data$: Observable<T[]>;

  readonly loading$ = new BehaviorSubject<boolean>(false);
  readonly layoutType$ = new BehaviorSubject<'table' | 'grid'>(
    getDataFromLocalStorage<'table' | 'grid'>(this.typeViewLocalStorageKey, 'table'),
  );

  private readonly totalItems$ = new BehaviorSubject<number | undefined>(undefined);
  private readonly reloadTrigger$ = new BehaviorSubject<void>(undefined);
  private readonly selectedItems$ = new BehaviorSubject<T[]>([]);
  private subscription = new Subscription();

  constructor(
    private readonly urlPageParamsService: UrlPageParamsService,
    @Inject(PREFIX_SETTINGS_LOCAL_STORAGE_KEY) private readonly typeViewLocalStorageKey: LocalStorageKey,
  ) {
    this.subscription.add(
      this.layoutType$.subscribe((layoutType) => {
        saveDataToLocalStorage(this.typeViewLocalStorageKey, layoutType);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  init(loadDataCallback: (pageParams: PageParams) => Observable<{ items: T[]; totalItems: number }>): void {
    this.data$ = combineLatest({
      pageParams: this.pageParams$,
      reloadTrigger: this.reloadTrigger$,
    }).pipe(
      tap(() => this.resetSelectedItems()),
      tap(() => this.loading$.next(true)),
      switchMap(({ pageParams }) => {
        return loadDataCallback(pageParams).pipe(
          catchError((e) => {
            this.loading$.next(false);

            return throwError(() => e);
          }),
        );
      }),
      map(({ items, totalItems }) => {
        this.setTotalItems(totalItems);

        return items;
      }),
      tap(() => {
        this.loading$.next(false);
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    const pageParams: Pick<PageParams, 'pageNumber' | 'pageSize' | 'sortField' | 'sortOrder' | 'filters'> = {
      pageNumber: this.calcPageNumber(event.first, event.rows),
      pageSize: event.rows,
      sortField: event.sortField as string,
      sortOrder: event.sortOrder > 0 ? 'asc' : 'desc',
    };
    this.urlPageParamsService.setPageParams(pageParams);
  }

  onPageChange({ first, rows }: { first: number; rows: number }): void {
    this.pageParams$.pipe(take(1)).subscribe(({ sortField, sortOrder }) => {
      this.onLazyLoad({
        first,
        rows,
        sortField: sortField,
        sortOrder: sortOrder === 'asc' ? 1 : -1,
      });
    });
  }

  onSort({ sortField, sortOrder }: { sortField?: string; sortOrder?: 'asc' | 'desc' }): void {
    const sortParams = pickBy({ sortField, sortOrder }, (value) => value !== undefined);
    this.urlPageParamsService.setPageParams(sortParams);
  }

  getSelectedItems(): Observable<T[]> {
    return this.selectedItems$.asObservable();
  }

  getSelectedItemIds<K extends keyof T>(idKey: K): Observable<T[K][]> {
    return this.getSelectedItems().pipe(map((items) => items.map((item) => item[idKey])));
  }

  setSelectedItems(selectedItems: T[]): void {
    this.selectedItems$.next(selectedItems);
  }

  resetSelectedItems(): void {
    this.selectedItems$.next([]);
  }

  getTotalItems(): Observable<number> {
    return this.totalItems$.asObservable().pipe(filter(Boolean));
  }

  setTotalItems(totalItems: number): void {
    this.totalItems$.next(totalItems);
  }

  triggerReloadData(): void {
    this.reloadTrigger$.next(undefined);
  }

  updateFilter(filterFieldName: string, filterValue: number | string | boolean): void {
    this.pageParams$.pipe(take(1)).subscribe((pageParams) => {
      const filters = pageParams.filters.filter((filter) => filter.fieldName !== filterFieldName);
      filters.push({ fieldName: filterFieldName, value: filterValue });
      this.urlPageParamsService.setPageParams({ ...pageParams, filters });
    });
  }

  getFilterValue(filterFieldName: string): Observable<number | string | boolean | undefined> {
    return this.pageParams$.pipe(
      map((pageParams) => {
        const filter = pageParams.filters.find((filter) => filter.fieldName === filterFieldName);

        return filter?.value;
      }),
    );
  }

  changeViewType(valueType: 'table' | 'grid'): void {
    this.layoutType$.next(valueType);
  }

  private calcPageNumber(first: number, rows: number): number {
    return Math.round(first / rows) + 1;
  }
}
