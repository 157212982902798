<selfai-platform-dialog-header
  header="Expression Tool"
  [nodeId]="nodeId"
  (clickSave)="onClickSave()"
  (closeDialog)="closeDialog()"
></selfai-platform-dialog-header>

<p-tabView *ngIf="formLogging; else mainTemplate">
  <p-tabPanel header="Expressions">
    <ng-template pTemplate="content">
      <ng-container [ngTemplateOutlet]="mainTemplate"></ng-container>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Logging">
    <ng-template pTemplate="content">
      <selfai-platform-logging [form]="formLogging"></selfai-platform-logging>
    </ng-template>
  </p-tabPanel>
</p-tabView>

<ng-template #mainTemplate>
  <ng-container *ngIf="!dataIsEmpty; else workflowEmptyData">
    <p-table
      class="table"
      dataKey="controls.id.value"
      #tableRef
      responsiveLayout="scroll"
      [value]="items"
      (onRowReorder)="items = tableRef.value"
      rowExpandMode="single"
      [globalFilterFields]="['controls.resultColumn.value']"
    >
      <ng-template pTemplate="caption">
        <div class="flex">
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="filter($event)" placeholder="Search" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="w-3rem"></th>
          <th>Result column</th>
          <th>Input Expression</th>
          <th class="w-10rem"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-expanded="expanded" let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td>
            <span class="pi pi-bars" pReorderableRowHandle></span>
          </td>
          <td [pRowToggler]="item">
            <input
              [readonly]="expanded"
              [disabled]="expanded"
              class="w-full"
              type="text"
              [(ngModel)]="item.controls.resultColumn.value"
              pInputText
            />
          </td>
          <td [pRowToggler]="item">
            <input
              [readonly]="expanded"
              [disabled]="expanded"
              class="w-full"
              type="text"
              [(ngModel)]="item.controls.type.controls.sqlExpression.value"
              pInputText
            />
          </td>
          <td>
            <button
              type="button"
              pButton
              class="p-button-text p-button-link p-button-danger p-0"
              appearance="icon"
              (click)="removeItem(item.controls.id.value)"
              icon="pi pi-trash"
              pTooltip="Remove"
            ></button>
            <button
              type="button"
              pButton
              class="p-button-text p-button-link p-0"
              appearance="icon"
              (click)="copyItem(item.controls.id.value)"
              icon="pi pi-copy"
              pTooltip="Clone this row"
            ></button>
            <button
              type="button"
              pButton
              class="p-button-text p-button-link p-0"
              appearance="icon"
              [icon]="expanded ? 'pi pi-times' : 'pi pi-pencil'"
              [pTooltip]="expanded ? 'Close condition' : 'Edit condition'"
              [pRowToggler]="item"
            ></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-item>
        <tr>
          <td colspan="6">
            <div [@rowExpansionTrigger]="'active'">
              <selfai-platform-expression-edit [form]="item"></selfai-platform-expression-edit>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">No data</td>
        </tr>
      </ng-template>
    </p-table>

    <button
      label="add group"
      pButton
      pRipple
      icon="pi pi-plus"
      type="button"
      class="mr-3 mt-3 p-button-sm"
      (click)="addItem()"
    ></button>
  </ng-container>
</ng-template>

<ng-template #workflowEmptyData>
  <p-messages severity="error">
    <ng-template pTemplate>Data of workflow isn't available yet. Try again later</ng-template>
  </p-messages>
</ng-template>
