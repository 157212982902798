import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WorkflowPresetsInterface } from '../interfaces/workflow-presets.interface';
import { WorkflowPresetsService } from './workflow-presets.service';
import { KE_WORKFLOW_PRESETS_ENTITY_NAME } from '@selfai-platform/store';

@Injectable({ providedIn: 'root' })
export class WorkflowPresetsStore extends EntityCollectionServiceBase<WorkflowPresetsInterface> {
  constructor(
    public entityDataService: EntityDataService,
    public workflowPresetsService: WorkflowPresetsService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_WORKFLOW_PRESETS_ENTITY_NAME, workflowPresetsService);
    super(KE_WORKFLOW_PRESETS_ENTITY_NAME, serviceElementsFactory);
  }
}
