import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'selfai-platform-form-array-sort-action-buttons',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './form-array-sort-action-buttons.component.html',
  styleUrl: './form-array-sort-action-buttons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormArraySortActionButtonsComponent {
  @Input({ required: true }) count: number;
  @Input({ required: true }) index: number;

  @Output() orderUp = new EventEmitter<number>();
  @Output() orderDown = new EventEmitter<number>();
  @Output() removeStep = new EventEmitter<number>();
}
