import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { DatasourceField, getFieldName } from '@selfai-platform/bi-domain';

import { StringUtil } from '../../../common/util';

@Component({
  selector: 'selfai-platform-page-section-data',
  templateUrl: './page-section-data.component.html',
  styleUrls: ['./page-section-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PageSectionDataComponent {
  @Input() dataSourceList: any;
  @Input() dataSource: any;
  @Input() fieldSearchText: any;
  @Input() isDataDimensionLayerShow: any;
  @Input() pageDimensions: DatasourceField[];
  @Input() dimensionPageNumber: number;
  @Input() dimensionTotalPage: any;
  @Input() isDataMeasureLayerShow: any;
  @Input() pageMeasures: DatasourceField[];
  @Input() measurePageNumber: number;
  @Input() measureTotalPage: any;
  @Input() isContainSearchText: any;

  @Output() selectDataSource = new EventEmitter<any>();
  @Output() onDataPreviewPopup = new EventEmitter<any>();
  @Output() setFieldTotalPage = new EventEmitter<any>();
  @Output() openCustomFieldPopup = new EventEmitter<any>();
  @Output() fieldPrev = new EventEmitter<any>();
  @Output() fieldNext = new EventEmitter<any>();
  @Output() onPivotSelect = new EventEmitter<any>();
  @Output() isCustomMeasureField = new EventEmitter<any>();
  @Output() toggleFilter = new EventEmitter<any>();
  @Output() openFieldDetailLayer = new EventEmitter<any>();
  @Output() changeFieldSearchText = new EventEmitter<any>();
  @Output() changeIsDataDimensionLayerShow = new EventEmitter<any>();
  @Output() changeIsDataMeasureLayerShow = new EventEmitter<any>();
  @Output() changeShowFieldIconsFl = new EventEmitter<any>();

  get dimensions(): DatasourceField[] {
    return this.pageDimensions.map((field) => ({ ...field, name: this.getDataAttributeFieldName(field) }));
  }

  unescapeCustomColumnExpr(expr: string) {
    return StringUtil.unescapeCustomColumnExpr(expr);
  }

  getDataAttributeFieldName(field: DatasourceField) {
    return getFieldName(field);
  }
}
