
import { Directive, ElementRef, inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { LayoutMode, Widget } from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';
import { BI_ROOT_ROUTE } from '@selfai-platform/shell';

import { AbstractComponent } from '../../common/component/abstract.component';
import { EventBroadcaster } from '../../common/event/event.broadcaster';

@Directive()
export abstract class AbstractWidgetComponent extends AbstractComponent implements OnInit, OnDestroy {
  public widget: Widget;

  public isEditMode = false;
  public isViewMode = false;
  public isAuthMgmtViewMode = false;

  public isVisibleScrollbar = false;

  public isMissingDataSource = false;
  public isError = false;
  public errorInfo: { show?: boolean; code?: string; details?: string };

  @Input()
  public layoutMode: LayoutMode;

  @Input()
  public isShowTitle = false;

  protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);

  constructor(protected broadCaster: EventBroadcaster, protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    this.isViewMode =
      this.layoutMode === LayoutMode.VIEW ||
      this.layoutMode === LayoutMode.VIEW_AUTH_MGMT ||
      this.layoutMode === LayoutMode.STANDALONE;
    this.isAuthMgmtViewMode = this.layoutMode === LayoutMode.VIEW_AUTH_MGMT;
    this.isEditMode = this.layoutMode === LayoutMode.EDIT;
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public editWidget(mode: string) {
    if (this.isMissingDataSource) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.can-not-edit-missing-datasource'));
      return;
    }

    if (mode === 'EDIT_WIDGET') {
      this.broadCaster.broadcast(mode, { widgetId: this.widget.id, widgetType: this.widget.type });
    }

    if (mode === 'MOVE_EDIT_WIDGET') {
      this.router.navigate(
        [
          '/',
          BI_ROOT_ROUTE,
          'workbook',
          this.activatedRoute.snapshot.paramMap.get('workbookId'),
          'edit',
          this.widget.dashBoardId,
        ],
        {
          queryParams: { cmd: 'MODIFY', type: this.widget.type, id: this.widget.id },
        },
      );
    }
  }

  public processStart() {
    this.broadCaster.broadcast('START_PROCESS', { widgetId: this.widget.id });
  }

  public processEnd() {
    this.broadCaster.broadcast('STOP_PROCESS', { widgetId: this.widget.id });
  }

  protected _showError(error: { show?: boolean; code?: string; details?: string }) {
    if (this.isEditMode) {
      this.commonExceptionHandler(error);
    } else {
      (isNullOrUndefined(error) || typeof error != 'object') && (error = {});
      error.show = false;
      this.errorInfo = error;
    }
    this.isError = true;
  }

  protected _hideError() {
    this.errorInfo = undefined;
    this.isError = false;
  }
}
