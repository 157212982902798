import {
  DataType,
  GraphNode,
  JsonToColumnItem,
  JsonToColumnTransformationData,
  JsonToColumnTransformationDataForSave,
  Parameter,
  SelectionColumnItem,
  SelectorParameter,
  WorkflowJsonToColumnTransformationData,
  WorkflowJsonToColumnTransformationItemData,
} from '@selfai-platform/pipeline-common';
import { v4 as uuidv4 } from 'uuid';

export function normalizeDataJsonToColumnTransofrmation(
  workflowData: WorkflowJsonToColumnTransformationData,
): JsonToColumnTransformationData {
  return {
    sourceColumn: (workflowData['Source column with JSON string']?.value as string) || '',
    justTypeTransformation: Boolean(workflowData['Just types transformation']),
    dateTimeFormat: workflowData['Datetime format'],
    dateFormat: workflowData['Date format'],
    items: workflowData['Json to DataFrame Column mappings'].map(normalizeItem),
  };
}

function normalizeItem(workflowData: WorkflowJsonToColumnTransformationItemData): JsonToColumnItem {
  return {
    columnName: workflowData['Column name(optional, if different from the column name'],
    dataType: workflowData['Column DataType'] ? (Object.keys(workflowData['Column DataType'])[0] as DataType) : null,
    jsonPath: workflowData['json path to column value'] || '',
    id: uuidv4(),
  };
}

export function normalizeToLegacyDataJsonToColumnTransormation(
  data: JsonToColumnTransformationDataForSave,
): WorkflowJsonToColumnTransformationData {
  return {
    'Date format': data.dateFormat,
    'Datetime format': data.dateTimeFormat,
    'Just types transformation': data.justTypeTransformation,
    'Source column with JSON string': { type: 'column', value: data.sourceColumn },
    'Json to DataFrame Column mappings': data.items.map(normalizeToLegacyItem),
  };
}

function normalizeToLegacyItem(item: Omit<JsonToColumnItem, 'id'>): WorkflowJsonToColumnTransformationItemData {
  return {
    'Column DataType': { [item.dataType as DataType]: {} },
    'Column name(optional, if different from the column name': item.columnName,
    'json path to column value': item.jsonPath,
  };
}

export function extractSelectionItemsJsonToColumnTransofrmation(
  parameters: GraphNode<WorkflowJsonToColumnTransformationData>['parameters'],
): SelectionColumnItem[] | undefined {
  if (!parameters) {
    return undefined;
  }

  const parameter: SelectorParameter | undefined = parameters.parameters?.find((parameter) =>
    isSelectorParameter(parameter),
  ) as SelectorParameter;

  return parameter?.dataFrameSchema?.fields.map(({ name }, index) => ({ name, index }));
}

function isSelectorParameter(parameter: Parameter): parameter is SelectorParameter {
  return Object.prototype.hasOwnProperty.call(parameter as SelectorParameter, 'dataFrameSchema');
}
