<div class="ddp-layout-popuptype" *ngIf="isShow">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-ui-popup ddp-ui-popup-join">
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">
        {{ 'msg.comm.ui.sel.ds.description' | translate }}
      </span>

      <div class="ddp-ui-pop-buttons">
        <a (click)="close()" href="javascript:" class="ddp-btn-pop">{{ 'msg.comm.btn.cancl' | translate }}</a>
        <a (click)="done()" href="javascript:" class="ddp-btn-pop ddp-bg-black">
          {{ 'msg.comm.btn.done' | translate }}
        </a>
      </div>
    </div>

    <div class="ddp-popup-dashboard ddp-type">
      <div class="ddp-type-top-option ddp-clear">
        <component-input
          [compType]="'search'"
          [value]="searchText"
          [optionalClass]="'ddp-fleft'"
          [placeHolder]="'msg.comm.search.ds' | translate"
          (changeValue)="searchEvent($event)"
        ></component-input>

        <div class="ddp-ui-rightoption">
          <label class="ddp-label-checkbox" (click)="togglePublished($event)">
            <input type="checkbox" checked="checked" [checked]="searchPublished" />
            <i class="ddp-icon-checkbox"></i>
            <span class="ddp-txt-checkbox">{{ 'msg.board.th.allowance' | translate }}</span>
          </label>
        </div>
      </div>

      <div class="ddp-wrap-grid" [class.ddp-selected]="'' !== summaryTargetDsId">
        <div class="ddp-wrap-viewtable">
          <div class="ddp-box-viewtable">
            <div class="ddp-ui-gridhead">
              <table class="ddp-table-form ddp-table-type2">
                <colgroup>
                  <col width="40px" />
                  <col width="*" />
                  <col width="30%" />
                </colgroup>
                <thead>
                  <tr>
                    <th class="ddp-txt-center"></th>
                    <th (click)="changeOrder('name')">
                      {{ 'msg.comm.th.ds' | translate }}
                      <em
                        class="ddp-icon-array-default2"
                        *ngIf="selectedContentSort.key !== 'name' || selectedContentSort.sort === 'default'"
                      ></em>
                      <em
                        class="ddp-icon-array-asc2"
                        *ngIf="selectedContentSort.key === 'name' && selectedContentSort.sort === 'asc'"
                      ></em>
                      <em
                        class="ddp-icon-array-des2"
                        *ngIf="selectedContentSort.key === 'name' && selectedContentSort.sort === 'desc'"
                      ></em>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>

            <div class="ddp-ui-gridbody">
              <table class="ddp-table-form ddp-table-type2 ddp-table-select">
                <colgroup>
                  <col width="40px" />
                  <col width="*" />
                  <col width="30%" />
                </colgroup>

                <tbody>
                  <tr
                    *ngFor="let item of dataSources"
                    (click)="selectDatasource(item)"
                    [ngClass]="{ 'ddp-selected': summaryTargetDsId == item.id }"
                  >
                    <td class="ddp-txt-center">
                      <div class="ddp-ui-checkbox">
                        <input type="checkbox" [checked]="isSelectedDatasource(item)" />
                        <i class="ddp-icon-checkbox"></i>
                      </div>
                    </td>
                    <td>
                      <div class="ddp-txt-long" [class.ddp-global]="item.published">
                        {{ item.name }}
                        <span class="ddp-txt-colortype" *ngIf="item.description">- {{ item.description }}</span>
                        <em class="ddp-tag-global" *ngIf="item.published">
                          {{ 'msg.comm.ui.list.ds.opendata' | translate }}
                        </em>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            (click)="getMoreList()"
            [ngClass]="{ 'ddp-disabled': !(page.page < pageResult.totalPages) }"
            class="ddp-box-add-link3"
          >
            <span class="ddp-link-type">
              {{ 'msg.comm.ui.more' | translate }}
              <em class="ddp-icon-view"></em>
            </span>
          </div>
        </div>
      </div>

      <app-datasource-summary
        *ngIf="'' !== summaryTargetDsId"
        [datasource]="summaryTargetDsId"
        (close)="onCloseSummary()"
      ></app-datasource-summary>
    </div>
  </div>
</div>
