<div class="ddp-popup-side" *ngIf="show">
  <div class="ddp-pop-side-top">
    {{ 'msg.page.ui.color.palette' | translate }}
    <a href="javascript:" class="ddp-btn-close" (click)="show = false"></a>
  </div>

  <div *ngIf="'heatmap' !== layerType && 'MEASURE' !== colorType" class="ddp-pop-side-contents">
    <ul class="ddp-list-series">
      <li *ngFor="let item of defaultColorList" (click)="changeColor(item)">
        <button
          class="ddp-btn-color-series{{ item['index'] }} ddp-type"
          [ngClass]="{ 'ddp-selected': item['colorNum'] == schema }"
        ></button>
      </li>
    </ul>
  </div>

  <div *ngIf="'heatmap' !== layerType && 'MEASURE' === colorType" class="ddp-pop-side-contents">
    <div class="ddp-box-slider">
      <div class="ddp-wrap-option-slider">
        <span class="ddp-label-slider">{{ 'msg.page.ui.color.invert' | translate }}</span>

        <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
          <input
            type="checkbox"
            id="invertColor"
            name="check"
            [checked]="isChartColorInverted()"
            (click)="invertColor()"
          />
          <label for="invertColor"><span class="ddp-slide"></span></label>
        </div>
      </div>
    </div>
    <span class="ddp-pop-sub-title">{{ 'msg.page.th.seq.display' | translate }}</span>
    <ul class="ddp-list-series">
      <li *ngFor="let item of measureColorList">
        <button
          class="ddp-btn-color-type{{ item['index'] }} ddp-type"
          [ngClass]="{
            'ddp-selected': isChartColorSelected(item),
            'sys-inverted': isTemplateColorInverted === undefined ? isChartColorInverted() : isTemplateColorInverted
          }"
          (click)="changeColor(item)"
        ></button>
      </li>
    </ul>

    <span class="ddp-pop-sub-title">{{ 'msg.page.th.contrast.display' | translate }}</span>
    <ul class="ddp-list-series">
      <li *ngFor="let item of measureReverseColorList">
        <button
          class="ddp-btn-color-type{{ item['index'] }} ddp-type"
          [ngClass]="{
            'ddp-selected': isChartColorSelected(item),
            'sys-inverted': isTemplateColorInverted === undefined ? isChartColorInverted() : isTemplateColorInverted
          }"
          (click)="changeColor(item)"
        ></button>
      </li>
    </ul>
  </div>

  <div *ngIf="'heatmap' === layerType" class="ddp-pop-side-contents">
    <ul class="ddp-list-series">
      <li *ngFor="let item of mapHeatmapColorList" (click)="changeColor(item)">
        <button
          class="ddp-btn-color-heatmap{{ item['index'] }}"
          [ngClass]="{ 'ddp-selected': item['colorNum'] === schema }"
        ></button>
      </li>
    </ul>
  </div>
</div>
