import { CommonConstant } from '../../common/constant/common.constant';

export class Page {
  public page = 0;
  public size: number = CommonConstant.API_CONSTANT.PAGE_SIZE;
  public sort: string = CommonConstant.API_CONSTANT.PAGE_SORT_MODIFIED_TIME_DESC;
  public column = '';
}

export class PageResult {
  public number: number;
  public size: number;
  public totalElements: number;
  public totalPages: number;
}
