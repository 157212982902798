import { Injectable } from '@angular/core';
import { ConfigurationDataSourceModel, WidgetQuery } from '@selfai-platform/bi-api';
import { DashboardDomainService } from '@selfai-platform/bi-domain';
import { Observable, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { DataSettingsService } from './data-settings.service';
import { WidgetActiveFiltersService } from './filters/widget-active-filters.service';
import { UiSettingsService } from './ui-settings.service';

@Injectable({ providedIn: 'root' })
export class WidgetQueryBuilderService {
  constructor(
    private readonly uiSettingsService: UiSettingsService,
    private readonly chartActiveFiltersService: WidgetActiveFiltersService,
    private readonly dataSettingsService: DataSettingsService,
    private readonly dashboardDomainService: DashboardDomainService,
  ) {}

  getQuery({ widgetId, contextId }: { widgetId: string; contextId?: string }): Observable<WidgetQuery> {
    return combineLatest({
      chartActiveFilters: this.chartActiveFiltersService.getEffectiveFilters(widgetId, contextId),
      uiSettings: this.uiSettingsService.getSettings(widgetId, contextId).pipe(
        filter(Boolean),
        distinctUntilChanged((prev, next) => {
          // need to compare only used fields
          return (
            JSON.stringify(prev.color) === JSON.stringify(next.color) &&
            JSON.stringify(prev.dataLabel) === JSON.stringify(next.dataLabel) &&
            JSON.stringify(prev.tooltip) === JSON.stringify(next.tooltip) &&
            JSON.stringify(prev.format) === JSON.stringify(next.format) &&
            prev.showLegend === next.showLegend &&
            prev.type === next.type &&
            prev.common.limit === next.common.limit
          );
        }),
      ),
      dataSettings: this.dataSettingsService.getSettings(widgetId, contextId).pipe(filter(Boolean)),
      dashboard: this.dashboardDomainService.getDashboardByWidgetId(widgetId).pipe(filter(Boolean)),
    }).pipe(
      map(({ chartActiveFilters, uiSettings, dataSettings, dashboard }) => {
        return {
          // TODO: add logic for multiple data sources and joins
          dataSource: {
            ...dashboard.configuration.dataSource,
            name: dashboard.configuration.dataSource.engineName,
          } as ConfigurationDataSourceModel,
          filters: chartActiveFilters,
          aliases: [
            {
              type: 'value',
              ref: dashboard.id,
            },
          ],
          context: {
            'discovery.dashboard.id': dashboard.id,
            'discovery.route.uri': location.pathname,
            'discovery.widget.id': widgetId,
          },
          limits: {
            limit: uiSettings.common.limit || 1000,
            sort: [],
          },
          // TODO: fix typings and remove any
          pivot: dataSettings.pivot as any,
          resultFormat: {
            format: uiSettings.format,
            type: uiSettings.type === 'graph' || uiSettings.type === 'graphv2' ? 'graph' : 'chart',
            mode: uiSettings.type !== 'graph' && uiSettings.type !== 'graphv2' ? uiSettings.type : undefined,
            mergeNode: true,
            useLinkCount: true,
          },
          userFields: dashboard.configuration.customFields,
        };
      }),
    );
  }
}
