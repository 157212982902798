import { Injectable } from '@angular/core';
import {
  EntityActionOptions,
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
  EntityDataService,
} from '@ngrx/data';
import { getIdContextKey } from '@selfai-platform/shared';
import { WIDGET_ENTITY_NAME } from '../constants';
import { WidgetState } from '../models';
import { WidgetDataService } from '../services';

@Injectable({ providedIn: 'root' })
export class WidgetStore extends EntityCollectionServiceBase<WidgetState> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    entityDataService: EntityDataService,
    widgetDataService: WidgetDataService,
  ) {
    // NOTE: This is the place where we register the service for the entity because our ConfigService is not available at module constructor yet
    entityDataService.registerService(WIDGET_ENTITY_NAME, widgetDataService);

    super(WIDGET_ENTITY_NAME, serviceElementsFactory);
  }

  override addOneToCache(widget: WidgetState): void {
    super.addOneToCache({ ...widget, id: getIdContextKey(widget.id, widget.contextId) });
  }

  override updateOneInCache(entity: Partial<WidgetState>, options?: EntityActionOptions): void {
    super.updateOneInCache({ ...entity, id: getIdContextKey(entity.id, entity.contextId) }, options);
  }

  override upsertOneInCache(entity: Partial<WidgetState>, options?: EntityActionOptions): void {
    super.upsertOneInCache({ ...entity, id: getIdContextKey(entity.id, entity.contextId) }, options);
  }

  override removeOneFromCache(entityOrKey: unknown, options?: EntityActionOptions): void {
    if (typeof entityOrKey === 'number' || typeof entityOrKey === 'string') {
      super.removeOneFromCache(entityOrKey, options);
    } else {
      const entity = entityOrKey as WidgetState;
      super.removeOneFromCache({ ...entity, id: getIdContextKey(entity.id, entity.contextId) }, options);
    }
  }
}
