import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowAddDialogComponent } from '../workflow-add-dialog/workflow-add-dialog.component';

@Component({
  selector: 'selfai-platform-workflow-clone-dialog',
  templateUrl: './workflow-clone-dialog.component.html',
  styleUrls: ['./workflow-clone-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowCloneDialogComponent extends WorkflowAddDialogComponent implements OnInit {
  get dialogData(): WorkflowInfo {
    return this.dialogService.data as WorkflowInfo;
  }

  constructor(override readonly dialogService: DialogService<WorkflowClonePayload, WorkflowInfo>) {
    super(dialogService);
  }

  ngOnInit(): void {
    this.form.setValue({
      name: `Copy of ${this.dialogData.name}`,
      description: this.dialogData.description,
      isPublic: true,
    });
  }

  override submit(): void {
    this.dialogService.close({
      name: this.form.value.name as string,
      description: this.form.value.description || '',
      id: this.dialogData.id as string,
      isPublic: this.form.value.isPublic as boolean,
    });
  }
}
