import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingService } from '@selfai-platform/shared';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';
import { TreeModule } from 'primeng/tree';
import { LOADING_JSON_SCHEMA } from '../../tokens/loading-json-schema.token';
import { JsonSchemaTreeViewComponent } from './json-schema-tree-view.component';

@NgModule({
  imports: [CommonModule, TreeModule, ButtonModule, SkeletonModule, MessagesModule, ReactiveFormsModule],
  declarations: [JsonSchemaTreeViewComponent],
  exports: [JsonSchemaTreeViewComponent],
  providers: [
    {
      provide: LOADING_JSON_SCHEMA,
      useClass: LoadingService,
    },
  ],
})
export class JsonSchemaTreeViewComponentModule {}
