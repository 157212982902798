import { Datasource, DatasourceField, FieldNameAlias, FieldValueAlias } from '../../../datasource';
import { Dashboard } from '../../models';

export function setFieldAttribute(
  sourceFieldItem: DatasourceField,
  masterDsInfo: Datasource,
  dsInfo: Datasource,
  boardInfo?: Dashboard,
  nameAliasList?: FieldNameAlias[],
  valueAliasList?: FieldValueAlias[],
): DatasourceField {
  const targetField: DatasourceField = { ...sourceFieldItem };

  targetField.granularity = dsInfo.granularity ? dsInfo.granularity : dsInfo.segGranularity;
  targetField.segGranularity = dsInfo.segGranularity ? dsInfo.segGranularity : dsInfo.granularity;
  targetField.dataSource = masterDsInfo.engineName;
  targetField.dsId = dsInfo.id;

  if (boardInfo) {
    targetField.boardId = boardInfo.id as string;
    targetField.nameAlias = nameAliasList?.find((alias) => alias.fieldName === sourceFieldItem.name);
    targetField.valueAlias = valueAliasList?.find((alias) => alias.fieldName === sourceFieldItem.name);
  }

  if (dsInfo.uiMetaData && dsInfo.uiMetaData.columns && 0 < dsInfo.uiMetaData.columns.length) {
    const logicalInfo = dsInfo.uiMetaData.columns.find((logical) => sourceFieldItem.name === logical.physicalName);
    logicalInfo && (targetField.uiMetaData = logicalInfo);
  }

  return targetField;
}
