import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserFunctionComponent } from '../../components/user-function/user-function.component';
import { ActivatedRoute } from '@angular/router';
import { UserFunctionsListStore } from '../../store';
import { Observable } from 'rxjs';
import { IFunctionTag, INamespace, UserFunctionsListItem } from '../../models';
import { KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH } from '@selfai-platform/shell';
import { AsyncPipe } from '@angular/common';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { HistoryBackService } from '@selfai-platform/shared';
import { WorkflowsListStore } from '../../store/workflows-list.store';
import { NamespacesListStore } from '../../store/namespaces-list.store';
import { UserFunctionsTagsStore } from '../../store/user-functions-tags.store';

@Component({
  selector: 'selfai-platform-user-function-container',
  templateUrl: './user-function-container.component.html',
  styleUrls: ['./user-function-container.component.scss'],
  standalone: true,
  imports: [
    UserFunctionComponent,
    AsyncPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFunctionContainerComponent implements OnInit {
  public userFunctions$!: Observable<UserFunctionsListItem[]>;
  public workflows$!: Observable<WorkflowInfo[]>;
  public namespaces$!: Observable<INamespace[]>;
  public tags$!: Observable<IFunctionTag[]>;
  public loading$!: Observable<boolean>;
  public id: string = this.route?.snapshot?.params['id'];
  public editMode = !!this.id;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userFunctionsStore: UserFunctionsListStore,
    private readonly workflowsStore: WorkflowsListStore,
    private readonly namespacesStore: NamespacesListStore,
    private readonly tagsStore: UserFunctionsTagsStore,
    private readonly history: HistoryBackService,
  ) {
    this.userFunctions$ = this.userFunctionsStore.entities$;
    this.workflows$ = this.workflowsStore.entities$;
    this.namespaces$ = this.namespacesStore.entities$;
    this.tags$ = this.tagsStore.entities$;
    this.loading$ = this.userFunctionsStore.loading$;
  }

  public ngOnInit(): void {
    if (this.editMode) {
      this.userFunctionsStore.getByKey(this.id);
    }
    this.workflowsStore.getAll();
    this.namespacesStore.getAll();
    this.tagsStore.getAll();
  }

  public onSubmit(userFunction: UserFunctionsListItem): void {
    if (this.editMode) {
      this.userFunctionsStore.update(userFunction);
    } else {
      this.userFunctionsStore.add(userFunction);
    }
  }

  public onBack(): void {
    this.history.back(['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH]);
  }
}
