import { UntypedFormArray } from '@angular/forms';

export function orderIndentUp(formArray: UntypedFormArray, index: number): void {
  orderIndent(formArray, index, true);
}

export function orderIndentDown(formArray: UntypedFormArray, index: number): void {
  orderIndent(formArray, index, false);
}

function orderIndent(formArray: UntypedFormArray, index: number, up: boolean): void {
  const control = formArray.at(index);
  formArray.removeAt(index);
  formArray.insert(index + (up ? -1 : 1), control);
}
