import { omit } from 'lodash';
import { PageWidgetConfiguration } from './page-widget-configuration.model';
import { Widget } from './widget.model';

export interface DashboardPageRelation {
  ref: string;
  pageRef?: string;
  children?: DashboardPageRelation[];
}

export class DashboardWidgetRelation {
  private readonly _widgets: Widget[] = [];

  public id = '';
  public name?: string;
  public type?: string;
  public chartType?: string;
  public children: DashboardWidgetRelation[] = [];

  constructor(rel?: DashboardPageRelation, widgets?: Widget[]) {
    if (rel && widgets) {
      this._widgets = widgets;
      this._toWidgetRelation(rel);
    }
  }

  public toPageRelation(): DashboardPageRelation {
    const pageRel: DashboardPageRelation = {
      ref: this.id,
    };
    if (this.children && 0 < this.children.length) {
      pageRel.children = this.children.map((item) => item.toPageRelation());
    }

    return pageRel;
  }

  private _toWidgetRelation(rel: DashboardPageRelation) {
    if (rel && rel.ref && this._widgets.length) {
      if (!rel.ref && rel['pageRef']) {
        rel.ref = rel['pageRef'];
      }
      rel = omit(rel, 'pageRef');
      const widget: Widget = this._widgets.filter((item) => item.id === rel.ref)[0];
      this.id = rel.ref;
      this.name = widget.name;
      this.type = 'page';
      this.chartType = this._getWidgetType(widget);
      if (rel.children && 0 < rel.children.length) {
        this.children = rel.children.map((item) => new DashboardWidgetRelation(item, this._widgets));
      }
    }
  }

  private _getWidgetType(widget: Widget): string {
    let strType = widget.type as string;
    if ('page' === widget.type) {
      strType = (<PageWidgetConfiguration>widget?.configuration)?.chart?.type?.toString() || '';
    }

    return strType;
  }
}
