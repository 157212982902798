import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  WorkflowApi,
  WorkflowClonePayload,
  WorkflowCreationAnswer,
  WorkflowCreationParams,
  WorkflowInfo,
  WorkflowModel,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';
import { PipelineConfigService } from '@selfai-platform/shared';
import { Observable } from 'rxjs';

const API_TYPE = 'batch';

@Injectable({
  providedIn: 'root',
})
export class WorkflowApiService {
  private apiurl: string;
  private apiVersion: string;
  private readonly pathWorkflows = '/workflows';

  constructor(private readonly http: HttpClient, private readonly pipelineConfigService: PipelineConfigService) {
    const config = this.pipelineConfigService.getConfig();
    this.apiurl = `${config.hosts.api}/${config.versions.url}`;
    this.apiVersion = config.versions.api;
  }

  getAllWorkflows() {
    return this.http.get<WorkflowInfo[]>(`${this.apiurl}${this.pathWorkflows}`);
  }

  getWorkflow(workflowId: string) {
    return this.http.get<WorkflowModel>(`${this.apiurl}${this.pathWorkflows}/${workflowId}`);
  }

  createWorkflow(params: WorkflowCreationParams) {
    const data = {
      metadata: {
        type: API_TYPE,
        apiVersion: this.apiVersion,
      },
      workflow: {
        nodes: [] as unknown[],
        connections: [] as unknown[],
      },
      thirdPartyData: {
        gui: {
          name: params.name,
          description: params.description,
        },
      },
    };

    return this.http.post<WorkflowCreationAnswer>(`${this.apiurl}${this.pathWorkflows}`, data);
  }

  cloneWorkflow(workflowToClone: WorkflowClonePayload) {
    const data: WorkflowCreationParams & { isPublic: boolean } = {
      description: workflowToClone.description,
      name: workflowToClone.name,
      isPublic: workflowToClone.isPublic,
    };

    return this.http.post<WorkflowCreationAnswer>(
      `${this.apiurl}${this.pathWorkflows}/${workflowToClone.id}/clone`,
      data,
    );
  }

  deleteWorkflow(workflowId: string) {
    return this.http.delete(`${this.apiurl}${this.pathWorkflows}/${workflowId}`, {
      responseType: 'text',
    });
  }

  updateWorkflow(serializedWorkflow: WorkflowSerialized, loadTimestamp: Date) {
    const data = {
      workflowId: serializedWorkflow.id,
      workflow: { ...serializedWorkflow },
    };

    data.workflow.metadata = {
      type: API_TYPE,
      apiVersion: this.apiVersion,
    };

    //this.ServerCommunication.sendUpdateWorkflowToWorkflowExchange(data, loadTimestamp);
  }

  getDownloadWorkflowMethodUrl(workflowId: string, includeDatasources: boolean) {
    return `${this.apiurl}${
      this.pathWorkflows
    }/${workflowId}/download?format=json&export-datasources=${!!includeDatasources}`;
  }

  getUploadWorkflowMethodUrl() {
    return `${this.apiurl}${this.pathWorkflows}/upload`;
  }

  cloneNotebookNode(workflowId: string, sourceNodeId: string, destinationNodeId: string) {
    return this.http.post<unknown>(
      `${this.apiurl}${this.pathWorkflows}/${workflowId}/notebook/${sourceNodeId}/copy/${destinationNodeId}`,
      null,
    );
  }

  getPresetByWorkflowId(workflowId: string) {
    return this.http.get(`${this.apiurl}${this.pathWorkflows}/${workflowId}/preset`);
  }

  bindPresetToWorkflow(presetId: string, workflowId: string) {
    const data = {
      id: workflowId,
      presetId: presetId,
    };

    return this.http.post(`${this.apiurl}${this.pathWorkflows}/${workflowId}/preset`, data);
  }

  loadWorkflow(workflowId: string): Observable<WorkflowApi> {
    return this.http.get<WorkflowApi>(`${this.apiurl}${this.pathWorkflows}/${workflowId}`);
  }
}
