import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluralTranslateKey',
  standalone: true,
})
export class PluralTranslateKeyPipe implements PipeTransform {
  transform(count: number, keys: string[]): string {
    const cases = [2, 0, 1, 1, 1, 2];
    const wordType = count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)];

    return keys[wordType];
  }
}
