import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { createScriptStoreFromBaseHref, SELFAI_APP_SCRIPT_STORE } from './script/script-store';
import { SELFAI_APP_BASE_HREF } from './tokens/base-href.token';
import { CookieService } from 'ng2-cookies';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: SELFAI_APP_SCRIPT_STORE,
      useFactory: createScriptStoreFromBaseHref,
      deps: [SELFAI_APP_BASE_HREF],
    },
    CookieService,
  ],
})
export class SharedModule {}
