<div class="ddp-box-down">
  <div class="ddp-wrap-divide">
    <div class="ddp-divide2">
      <div class="ddp-list-label">{{ 'msg.page.chart.map.spatial.mainlayer' | translate }}</div>

      <component-select
        [array]="baseList.layers"
        [(defaultIndex)]="baseIndex"
        (onSelected)="onSelectBase($event)"
      ></component-select>
    </div>

    <div class="ddp-divide2">
      <div class="ddp-list-label">{{ 'msg.page.chart.map.spatial.comparelayer' | translate }}</div>

      <component-select
        [array]="compareList.layers"
        [(defaultIndex)]="compareIndex"
        (onSelected)="onSelectCompare($event)"
      ></component-select>
    </div>

    <div class="ddp-divide2">
      <div class="ddp-list-label">{{ 'msg.page.chart.map.spatial.analysis' | translate }}</div>

      <component-select
        [array]="calSpatialList"
        [(defaultIndex)]="calSpatialIndex"
        [viewKey]="'name'"
        (onSelected)="onSelectSpatial($event)"
      ></component-select>
    </div>

    <div
      class="ddp-wrap-slider-down"
      *ngIf="
        calSpatialList[calSpatialIndex].value != 'symmetricdiff' && calSpatialList[calSpatialIndex].value != 'dwithin'
      "
      [ngClass]="{ 'ddp-selected': isBufferOn }"
    >
      <div class="ddp-wrap-option-slider">
        <span class="ddp-label-slider type-label">Buffer</span>
        <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
          <input (click)="bufferBtn()" type="checkbox" value="None" id="check3" name="check" [checked]="isBufferOn" />
          <label for="check2"><span class="ddp-slide"></span></label>
        </div>
      </div>
      <div class="ddp-list-part-sub">
        <div class="ddp-divide2 ddp-form ddp-clear">
          <div class="ddp-col-6">
            <input
              type="text"
              class="ddp-input-typebasic ddp-txt-right"
              (keyup)="bufferInputChange()"
              [(ngModel)]="bufferInput"
            />
          </div>
          <div class="ddp-col-6">
            <component-select
              [array]="bufferList"
              [(defaultIndex)]="bufferIndex"
              [viewKey]="'name'"
              (onSelected)="onSelectBuffer($event)"
            ></component-select>
          </div>
        </div>
      </div>
    </div>

    <div class="ddp-divide2 ddp-form ddp-clear" *ngIf="calSpatialList[calSpatialIndex].value == 'dwithin'">
      <div class="ddp-col-6">
        <input type="text" class="ddp-input-typebasic ddp-txt-right" [(ngModel)]="unitInput" />
      </div>
      <div class="ddp-col-6">
        <component-select
          [array]="unitList"
          [(defaultIndex)]="unitIndex"
          [viewKey]="'name'"
          (onSelected)="onSelectUnit($event)"
        ></component-select>
      </div>
    </div>

    <div
      class="ddp-wrap-slider-down"
      *ngIf="calSpatialList[calSpatialIndex].value != 'symmetricdiff'"
      [ngClass]="{ 'ddp-selected': isChoroplethOn }"
    >
      <div class="ddp-wrap-option-slider">
        <span class="ddp-label-slider type-label">{{ 'msg.page.chart.map.spatial.step' | translate }}</span>

        <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
          <input
            (click)="choroplethBtn()"
            type="checkbox"
            value="None"
            id="check2"
            name="check"
            [checked]="isChoroplethOn"
          />
          <label for="check2"><span class="ddp-slide"></span></label>
        </div>
      </div>
      <div class="ddp-list-part-sub">
        <div class="ddp-divide2">
          <div class="ddp-list-label">Color by</div>

          <component-select
            [array]="fieldList['measureList']"
            [defaultIndex]="colorByIndex"
            [viewKey]="'name'"
            (onSelected)="selectColor($event)"
          ></component-select>
        </div>
        <div
          class="ddp-divide2"
          *ngIf="
            fieldList['measureList'].length > 1 &&
            fieldList['measureList'][colorByIndex]['field'] != undefined &&
            (fieldList['measureList'][colorByIndex]['field']['isCustomField'] == undefined ||
              fieldList['measureList'][colorByIndex]['field']['isCustomField'] == false)
          "
        >
          <div class="ddp-list-label">Aggregate by</div>

          <component-select
            [array]="aggregateTypes"
            [defaultIndex]="aggregateTypesIndex"
            [viewKey]="'name'"
            (onSelected)="selectAggregate($event)"
          ></component-select>
        </div>
      </div>
    </div>

    <div class="ddp-form-buttons ddp-clear" (click)="spatialAnalysisBtn()">
      <a
        href="javascript:"
        class="ddp-btn-blue"
        [ngClass]="{
          'ddp-disabled':
            uiOption['analysis'] != null &&
            uiOption['analysis']['use'] != null &&
            uiOption['analysis']['use'] == true &&
            (this.uiOption.analysis['isReAnalysis'] == null ||
              (this.uiOption.analysis['isReAnalysis'] != null && this.uiOption.analysis['isReAnalysis'] == false))
        }"
      >
        Analysis
      </a>
    </div>
  </div>
</div>
