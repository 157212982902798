import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { ConfigService } from '../../config';

export async function initializeKeycloak(
  keycloakSerivce: KeycloakService,
  configService: ConfigService,
): Promise<void> {
  const keycloakConfig = configService.getConfig().identityProvider?.keycloakConfig;

  keycloakSerivce.keycloakEvents$.subscribe(({ type }) => {
    if (type === KeycloakEventType.OnAuthRefreshError || type === KeycloakEventType.OnAuthLogout) {
      keycloakSerivce.getKeycloakInstance().login({
        redirectUri: window.location.href,
      });
    }
  });

  await keycloakSerivce.init({
    config: keycloakConfig,
    initOptions: {
      checkLoginIframe: false,
      redirectUri: window.location.href,
    },
    enableBearerInterceptor: true,
    loadUserProfileAtStartUp: true,
  });
}
