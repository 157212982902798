import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { DashboardModule } from '../../dashboard.module';

import { MainDashboardComponent } from './main-dashboard.component';

@NgModule({
  imports: [CommonModule, TranslateModule, DashboardModule],
  declarations: [MainDashboardComponent],
  exports: [MainDashboardComponent],
})
export class MainDashboardComponentModule {}
