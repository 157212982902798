export function validParse(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function validString(obj: object): boolean {
  try {
    JSON.stringify(obj);
  } catch (e) {
    return false;
  }

  return true;
}
