import { TypeProvider } from '@angular/core';
import { DialogService as PrimeDialogService } from 'primeng/dynamicdialog';
import { DialogService } from './dialog.service';

/**
 * TODO: make service as singleton or dispose of dependedServices
 * @param dependedServices services which to use DialogService
 */
export function provideDialogService(...dependedServices: readonly TypeProvider[]): TypeProvider[] {
  return [DialogService, PrimeDialogService, ...dependedServices] as TypeProvider[];
}
