import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, filter } from 'rxjs';

import { UIChartAxis } from '@selfai-platform/bi-domain';

@Injectable()
export class YAxisOptionsService {
  private axisConfig$ = new BehaviorSubject<UIChartAxis | undefined>(undefined);

  getAxisConfig(): Observable<UIChartAxis> {
    return this.axisConfig$.asObservable().pipe(filter(Boolean));
  }

  setAxisConfig(config: UIChartAxis): void {
    this.axisConfig$.next(config);
  }

  setAxisConfigPartly(config: UIChartAxis) {
    this.setAxisConfig({ ...(this.axisConfig$.value || {}), ...config });
  }
}
