import { Sort } from '../../../workbook';

export interface QueryParamLimit {
  limit: number;
  sort: Sort[];
}

export function createQueryParamLimit(queryParamLimitOptions: Partial<QueryParamLimit> = {}): QueryParamLimit {
  return {
    limit: 1000000,
    sort: [],
    ...queryParamLimitOptions,
  };
}
