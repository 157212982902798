<div class="ddp-layout-popuptype" *ngIf="isShow">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-ui-popup">
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">{{ 'msg.board.hierarchy.title' | translate }}</span>
      <span class="ddp-txt-sub-title">{{ 'msg.board.hierarchy.desc' | translate }}</span>

      <div class="ddp-ui-pop-buttons">
        <a href="javascript:" class="ddp-btn-pop" (click)="close()">{{ 'msg.comm.btn.cancl' | translate }}</a>
        <a href="javascript:" class="ddp-btn-pop ddp-bg-black" (click)="savePageRelation()">
          {{ 'msg.comm.btn.done' | translate }}
        </a>
      </div>
    </div>

    <div class="ddp-ui-popup-contents">
      <div class="ddp-wrap-order">
        <div class="ddp-ui-result">
          <span class="ddp-data-result">
            <strong>{{ cntWidgets }}</strong>
            widgets
          </span>
        </div>

        <div class="ddp-wrap-order-setting">
          <div class="sys-tree-container" (mousedown)="treeMouseDown($event)" (mouseup)="treeMouseUp($event)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
