<div class="chart-buttons-wrapper-dc">
  <p-button
    class="toggle-edit-mode-btn"
    style="font-size: 0.5rem"
    [icon]="isEditMode ? 'pi pi-play' : 'pi pi-pause'"
    [outlined]="true"
    (click)="toggleEditMode.emit(!isEditMode)"
  />
  <button class="btn-dc-close" (click)="close.emit()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-x-square"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <line x1="9" y1="9" x2="15" y2="15" />
      <line x1="15" y1="9" x2="9" y2="15" />
    </svg>
  </button>
  <button
    class="btn-dc-done"
    *ngIf="isDashboard"
    [ngClass]="{ 'ddp-disabled': !isChartShow || isError }"
    [disabled]="!isChartShow || isError"
    (click)="save.emit()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-save"
    >
      <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
      <polyline points="17 21 17 13 7 13 7 21" />
      <polyline points="7 3 7 8 15 8" />
    </svg>
  </button>
</div>
