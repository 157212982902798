<div class="ddp-wrap-widget dashboard-widget-header" (mouseover)="mouseOverHeader()" (mouseout)="mouseOutHeader()" style="cursor: move">
  <div #controlContainer class="ddp-box-widget" [class.ddp-box-size]="isMiniHeader">
    <div class="ddp-clear">
      <div class="ddp-top-control-in" [ngClass]="{ 'ddp-size1': !isMiniHeader, 'ddp-size2': isMiniHeader }">

<!--        <div class="ddp-ui-buttons" *ngIf="!isMiniHeader">-->
<!--          <div class="ddp-box-btn2" *ngIf="isRealTimeDashboard()">-->
<!--            <em (click)="toggleSync()" class="ddp-icon-widget-synch" [class.ddp-on]="isRealTimeWidget()"></em>-->

<!--            <div class="ddp-ui-tooltip-info">-->
<!--              <em class="ddp-icon-view-down"></em>-->
<!--              {{ 'msg.board.ui.sync' | translate }}-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="ddp-box-btn2" *ngIf="limitInfo.isShow">-->
<!--            <em class="ddp-icon-widget-info2"></em>-->

<!--            <div class="ddp-box-info ddp-limitation">-->
<!--              <em class="ddp-icon-info"></em>-->
<!--              <span-->
<!--                class="ddp-total"-->
<!--                [innerHTML]="-->
<!--                  'msg.page.ui.limit-msg-1'-->
<!--                    | translate-->
<!--                      : { currentCnt: limitInfo.currentCnt | numberCommas, maxCnt: limitInfo.maxCnt | numberCommas }-->
<!--                "-->
<!--              ></span>-->
<!--              {{ 'msg.page.ui.limit-msg-2' | translate }}-->
<!--            </div>-->
<!--          </div>-->

<!--          <div *ngIf="isDimensionFilterWidget" class="ddp-box-btn-hover">-->
<!--            <a href="javascript:" class="ddp-btn-tool">-->
<!--              <em-->
<!--                [ngClass]="{-->
<!--                  'ddp-icon-tool-list': isListDimensionFilter,-->
<!--                  'ddp-icon-tool-selectbox': !isListDimensionFilter-->
<!--                }"-->
<!--              ></em>-->
<!--            </a>-->
<!--            <a (click)="setSelectorTypeList()" href="javascript:" class="ddp-btn-tool">-->
<!--              <em class="ddp-icon-tool-list"></em>-->
<!--            </a>-->
<!--            <a (click)="setSelectorTypeCombo()" href="javascript:" class="ddp-btn-tool">-->
<!--              <em class="ddp-icon-tool-selectbox"></em>-->
<!--            </a>-->
<!--          </div>-->

<!--          <div class="ddp-ui-btnview" *ngIf="false">-->
<!--            <div class="ddp-box-btn" [class.ddp-selected]="!isGridModePageWidget" (click)="onChangeWidgetMode('chart')">-->
<!--              <em class="ddp-icon-widget-graph"></em>-->

<!--              <div class="ddp-ui-tooltip-info">-->
<!--                <em class="ddp-icon-view-down"></em>-->
<!--                {{ 'msg.book.widget.header.chartview' | translate }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <div-->
<!--              class="ddp-box-btn"-->
<!--              [class.ddp-selected]="isGridModePageWidget"-->
<!--              (click)="onChangeWidgetMode('grid')"-->
<!--              *ngIf="widget.type == 'page' && isAvailableGrid(widget)"-->
<!--            >-->
<!--              <em class="ddp-icon-widget-grid"></em>-->

<!--              <div class="ddp-ui-tooltip-info">-->
<!--                <em class="ddp-icon-view-down"></em>-->
<!--                {{ 'msg.book.widget.header.tableview' | translate }}-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <p-toolbar>
          <div class="p-toolbar-group-start" *ngIf="!isMiniHeader">
            <p-button
              class="mr-2"
              size="small"
              [pTooltip]="'msg.board.ui.tooltip.chart-name' | translate"
              tooltipPosition="bottom"
              icon="pi pi-tag"
              [outlined]="!isShowTitle"
              (click)="toggleTitle()"
            />
            <p-button
              class="mr-2"
              size="small"
              *ngIf="isValidLegend()"
              [pTooltip]="'msg.page.ui.legend' | translate"
              tooltipPosition="bottom"
              icon="pi pi-list"
              [outlined]="!isShowLegend()"
              (click)="toggleLegend()"
            />
            <p-button
              class="mr-2"
              size="small"
              *ngIf="isValidMiniMap()"
              [pTooltip]="'msg.page.ui.minimap' | translate"
              tooltipPosition="bottom"
              icon="pi pi-map"
              [outlined]="!isShowMiniMap()"
              (click)="toggleMiniMap()"
            />
          </div>
          <div class="p-toolbar-group-center flex-grow-1">
            <div *ngIf="isMiniHeader">
              <p-button
                #miniMenuButton
                class="mr-2"
                (click)="menu.toggle($event); $event.stopPropagation()"
                (clickOutside)="menu.hide()"
                icon="pi pi-ellipsis-v"/>
              <p-menu
                class="mini-header-menu"
                #menu
                [model]="miniHeaderMenuItems"
                [popup]="true"
                [appendTo]="miniMenuButton"/>
            </div>
            <div class="w-full" *ngIf="widget && !isTextWidget && isShowTitle">
              <input
                class="w-full"
                [value]="widget.name"
                pInputText
                maxlength="50"
                (mousedown)="$event.stopPropagation()"
                (change)="changeWidgetName($event.target.value)"
                [placeholder]="'msg.board.ui.name.ph' | translate"
              />
            </div>
          </div>
          <div class="p-toolbar-group-end" *ngIf="!isMiniHeader">
            <span
              class="pi pi-info-circle ml-2"
              [pTooltip]="tooltipWidgetInfoContent"
              tooltipStyleClass="tooltip-widget-info"
            ></span>
            <ng-template #tooltipWidgetInfoContent>
              <div class="flex flex-column">
                <div class="text-lg font-bold">{{ 'msg.board.ui.title.chartinfo' | translate }}</div>
                <br>
                <div>
                  <div><strong>{{ 'msg.page.ui.datasource' | translate }}</strong></div>
                  <div>{{ getDataSourceName() }}</div>
                  <br>
                </div>
                <div *ngIf="existPivot('columns')">
                  <div><strong>{{ 'msg.page.ui.col' | translate }}</strong></div>
                  <div>{{ getPivotFieldsStr('columns') }}</div>
                  <br>
                </div>
                <div *ngIf="existPivot('rows')">
                  <div><strong>{{ 'msg.page.ui.row' | translate }}</strong></div>
                  <div>{{ getPivotFieldsStr('rows') }}</div>
                  <br>
                </div>
                <div *ngIf="existPivot('aggregations')">
                  <div><strong>{{ 'msg.page.ui.intersect' | translate }}</strong></div>
                  <div>{{ getPivotFieldsStr('aggregations') }}</div>
                  <br>
                </div>
                <div *ngIf="existChartFilter()">
                  <div><strong>{{ 'msg.page.ui.filter' | translate }}</strong></div>
                  <div>{{ getChartFilterStr() }}</div>
                </div>
              </div>
            </ng-template>
            <p-button
              class="mr-2 ml-2"
              size="small"
              [pTooltip]="'msg.comm.ui.edit' | translate"
              tooltipPosition="bottom"
              *ngIf="isEditMode()"
              icon="pi pi-pencil"
              (click)="editWidget()"
            />
            <p-button
              class="mr-2"
              size="small"
              [pTooltip]="'msg.comm.ui.copy' | translate"
              tooltipPosition="bottom"
              *ngIf="isEditMode() && isPageWidget"
              icon="pi pi-copy"
              (click)="copyPageWidget()"
            />
            <p-button
              size="small"
              [pTooltip]="'msg.comm.ui.del' | translate"
              tooltipPosition="bottom"
              *ngIf="isEditMode()"
              icon="pi pi-times"
              (click)="removeWidget()"
            />
          </div>
        </p-toolbar>
      </div>
    </div>
  </div>
</div>
