import { Injectable } from '@angular/core';
import {
  DashboardApiModel,
  WorkbookApiService,
  WorkspaceApiParams,
  WorkspaceApiService,
} from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { Workbook, WorkbookAdd, WorkbookDetailProjections, WorkbookList } from '../models';
import { workbookListApiNormalizer } from '../normalizers';

@Injectable({ providedIn: 'root' })
export class WorkbookApiToDomainService {
  constructor(
    private readonly workbookApiService: WorkbookApiService,
    private readonly workspaceApiService: WorkspaceApiService,
  ) {}

  getWorkbookList(workspaceId: string, params: PageParams): Observable<WorkbookList> {
    let apiParams: WorkspaceApiParams = {
      limit: params.pageSize,
      offset: (params.pageNumber - 1) * params.pageSize,
      filter: params.sortField ? `${params.sortField}${params.sortOrder === 'desc' ? 'down' : 'up'}` : undefined,
      // TODO: add filters for favorites
    };
    if (params.query) {
      apiParams = { ...apiParams, search: params.query?.trim() };
    }

    return this.workspaceApiService
      .getWorkspaceById(workspaceId, apiParams)
      .pipe(map((res) => workbookListApiNormalizer(res)));
  }

  updateWorkbook(workbookId: string, workbook: Partial<Workbook>): Observable<void> {
    return this.workbookApiService.updateWorkbook(workbookId, workbook as any);
  }

  addWorkbook(workspaceId: string, workbook: WorkbookAdd): Observable<Workbook> {
    return this.workbookApiService.addWorkbook(workspaceId, workbook) as any;
  }

  loadWorkbook(workbookId: string): Observable<WorkbookDetailProjections> {
    return this.workbookApiService.loadWorkbook(workbookId) as any;
  }

  copyWorkbook(workbookId: string): Observable<Workbook> {
    return this.workbookApiService.copyWorkbook(workbookId) as any;
  }

  addAllPins(workbookId: string, pinsDashboard: string[]): Observable<unknown> {
    return this.workbookApiService.addAllPins(workbookId, pinsDashboard) as any;
  }

  loadPins(workbookId: string): Observable<DashboardApiModel[]> {
    return this.workbookApiService.getPins(workbookId).pipe(map((res) => res.dashBoard));
  }

  deleteDashboardFromPins(workbookId: string, dashboardId: string): Observable<void> {
    return this.workbookApiService.deleteDashboardFromPins(workbookId, dashboardId);
  }

  addDashboardToPins(workbookId: string, dashboardId: string): Observable<void> {
    return this.workbookApiService.addDashboardToPins(workbookId, dashboardId);
  }

  deleteAllPins(workbookId: string): Observable<void> {
    return this.workbookApiService.deleteAllPins(workbookId);
  }
}
