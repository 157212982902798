import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BiModule } from '@selfai-platform/bi';
import { StepType } from '@selfai-platform/pipeline-common';
import {
  PipelineModuleModule,
  PipelineProviderModule,
  aggregatorRouting,
  provideStepsConfig,
} from '@selfai-platform/pipeline-module';
import {
  ConfigService,
  PACKAGE_VERSION,
  SELFAI_APP_BASE_HREF,
  SelfaiAppModules,
  SharedModule,
} from '@selfai-platform/shared';
import { HomePageWidgets, ShellModule, UiLanguages, notFoundRoute } from '@selfai-platform/shell';
import packageJson from '../../package.json';
import { AppComponent } from './app.component';
import { mainMenuProviders } from './configs/main-menu-providers';
import { SelfaiStoreModule } from '@selfai-platform/store';
import { ToastModule } from 'primeng/toast';
import { PageIsReadyComponent } from '@selfai-platform/bi-shared';
@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [DragDropModule,
        CommonModule,
        BrowserModule,
        SelfaiStoreModule,
        ShellModule.forRoot({
            homePageWidgets: [HomePageWidgets.SelfaiBi, HomePageWidgets.Pipeline],
            languages: [UiLanguages.EN, UiLanguages.RU],
            modules: [SelfaiAppModules.KD, SelfaiAppModules.KE],
        }),
        PipelineProviderModule.forRoot(),
        PipelineModuleModule,
        BiModule,
        RouterModule.forRoot([...aggregatorRouting, notFoundRoute], {
            scrollPositionRestoration: 'enabled',
        })], providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: SELFAI_APP_BASE_HREF,
            useFactory: setAppBasehref,
            deps: [LocationStrategy],
        },
        ConfigService,
        {
            provide: PACKAGE_VERSION,
            useValue: packageJson.version,
        },
        ...mainMenuProviders,
        provideStepsConfig(Object.values(StepType)),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

export function setAppBasehref(locStrategy: LocationStrategy) {
  return locStrategy.getBaseHref();
}
