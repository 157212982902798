import { Injectable } from '@angular/core';

interface AngularJsBridgeServiceNg1 {
  registerHook(hookName: string, hook: unknown): void;
  emitEvent(event: string, ...args: unknown[]): void;
}

@Injectable({ providedIn: 'root' })
export class AngularJsBridgeService {
  private angularJsHookService: AngularJsBridgeServiceNg1 | null = null;

  setHookService(angularJsHookService: AngularJsBridgeServiceNg1): void {
    this.angularJsHookService = angularJsHookService;
  }

  clearHookService(): void {
    this.angularJsHookService = null;
  }

  registerHook(hookName: string, hook: { (args: unknown): void }): void {
    this.angularJsHookService?.registerHook(hookName, hook);
  }

  emitEventToAngularjs(event: string, data1?: unknown, data2?: unknown): void {
    this.angularJsHookService?.emitEvent(event, data1, data2);
  }
}
