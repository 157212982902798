<p-fileUpload
  #fileUpload
  mode="advanced"
  name="importFile"
  accept="application/json"
  [fileLimit]="1"
  chooseLabel="Choose a file"
  [auto]="true"
  [customUpload]="true"
  (uploadHandler)="onUpload($event)"
  (onError)="onError()"
>
  <ng-template let-file pTemplate="file">
    <div class="p-fileupload-row">
      <div class="p-fileupload-filename">{{ file.name }}</div>
      <div>{{ fileUpload.formatSize(file.size) }}</div>
    </div>
  </ng-template>
</p-fileUpload>

<ng-container *ngIf="showReplaceWarn">
  <p-message
    class="block mt-3"
    severity="warn"
    text="Warning! If you import you replace whole configuration and can't recover"
  ></p-message>
</ng-container>
