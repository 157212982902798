<div class="ddp-type-contents ddp-resize2 ddp-type2" *ngIf="isShow">
  <a href="javascript:" class="ddp-btn-close" (click)="closeComp()"></a>

  <div class="ddp-icon-name">
    <div class="ddp-ui-name2">
      {{ 'msg.board.create.title' | translate }}
      <span class="ddp-txt-info">{{ 'msg.board.create.cmplt' | translate }}</span>
      <em class="ddp-bg-order-line2-type2"></em>
    </div>
  </div>

  <div class="ddp-type-contents-in">
    <div class="ddp-box-popupcontents2">
      <div class="ddp-wrap-boxtype">
        <table class="ddp-wrap-boxdata">
          <colgroup>
            <col width="120px" />
            <col width="*" />
          </colgroup>
          <tbody>
            <tr>
              <th>{{ 'msg.comm.ui.sel.book' | translate }}</th>
              <td>{{ workbookName }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidName">
        <label class="ddp-label-type">{{ 'msg.comm.ui.name-dashboard' | translate }}</label>
        <input
          type="text"
          class="ddp-input-type"
          placeholder="{{ 'msg.comm.ui.create.name' | translate }}"
          [(ngModel)]="dashboard.name"
          (focus)="isInvalidName = false"
          maxlength="50"
        />
        <span class="ddp-ui-error">{{ errMsgName }}</span>
      </div>

      <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidDesc">
        <label class="ddp-label-type">{{ 'msg.comm.ui.description-dashboard' | translate }}</label>
        <input
          type="text"
          class="ddp-input-type"
          placeholder="{{ 'msg.comm.ui.create.desc' | translate }}"
          [(ngModel)]="dashboard.description"
          (focus)="isInvalidDesc = false"
          maxlength="150"
        />
        <span class="ddp-ui-error">{{ errMsgDesc }}</span>
      </div>

      <div class="ddp-wrap-edit2" [class.ddp-error]="isInvalidDesc">
        <label class="ddp-label-type">{{ 'msg.comm.ui.sel.ds' | translate }}</label>
        {{ getDatasourceNames() }}
      </div>
    </div>
  </div>

  <div class="ddp-ui-buttons">
    <a (click)="isShow = false" href="javascript:" class="ddp-btn-type-popup">
      {{ 'msg.comm.btn.previous' | translate }}
    </a>
    <a
      (click)="complete()"
      [class.ddp-disabled]="isInvalidName || isInvalidDesc || !dashboard.name || '' === dashboard.name"
      href="javascript:"
      class="ddp-btn-type-popup ddp-bg-black"
    >
      {{ 'msg.comm.btn.done' | translate }}
    </a>
  </div>
</div>
