import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@selfai-platform/shared';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { VersionsManagerComponent } from './components';
import { VersionsManagerService, VersionsManagerStorageService } from './services';
import { VERSION_MANAGER_API_TOKEN } from './tokens';
import { ChipModule } from 'primeng/chip';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    ButtonModule,
    OverlayPanelModule,
    InputTextModule,
    AutoFocusModule,
    ReactiveFormsModule,
    ConfirmPopupModule,
    TooltipModule,
    ChipModule,
    FormsModule,
  ],
  declarations: [VersionsManagerComponent],
  exports: [VersionsManagerComponent],
  providers: [
    VersionsManagerService,
    {
      provide: VERSION_MANAGER_API_TOKEN,
      useClass: VersionsManagerStorageService,
    },
  ],
})
export class PipelineVersionManagerModule {}
