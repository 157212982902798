import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys',
})
@Injectable()
export class KeysPipe implements PipeTransform {
  transform(enumClass, args: string[]): any {
    const keys = [];
    for (const enumMember in enumClass) {
      keys.push({ key: enumMember, value: enumClass[enumMember] });
    }
    return keys;
  }
}
