import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Dashboard, PresentationDashboard } from '@selfai-platform/bi-domain';

import { SubscribeArg } from '../domain/subscribe-arg';

@Injectable()
export class PopupService {
  public ptDashboards: Dashboard[];
  public ptStartDashboard: PresentationDashboard;
  public ptWorkbookId: string;
  public ptSelectedDashboard;
  public ptSelectedIdx;
  public ptLoading = false;
  public ptModalClose: boolean | 'locked' | 'unlocked' | 'limited' = false;

  private popupSource: Subject<SubscribeArg> = new Subject<SubscribeArg>();
  private filterSource: Subject<SubscribeArg> = new Subject<SubscribeArg>();

  public view$ = this.popupSource.asObservable();
  public filterView$ = this.filterSource.asObservable();

  public notiPopup(data: SubscribeArg) {
    this.popupSource.next(data);
  }

  public notiFilter(data: SubscribeArg) {
    this.filterSource.next(data);
  }
}
