export enum StepType {
  OUTPUT = 'output',
  FILTER = 'filter',
  AGGREGATOR = 'aggrt',
  AGGREGATOR_IN_TIME = 'aggrtInTime',
  PARALLEL = 'parallel',
  TO = 'to',
  OTHER_PROCESS = 'otherProcess',
  VARIABLE = 'variable',
  MAX_VALUE_AGGREGATION = 'maxAggrt',
  MAX_VALUE_AGGREGATION_IN_TIME = 'maxAggrtInTime',
  UNIQ_VALUES_AGGREGATION = 'uniqueAggrt',
  UNIQ_VALUES_AGGREGATION_IN_TIME = 'uniqueAggrtInTime',
}
