import { ChangeDetectorRef, EventEmitter, Injector } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CubeEditResult,
  CubeWorkflowData,
  GraphNode,
  GraphNodeOptionSerialized,
  GraphNodeUpdateParameterValuesOptions,
  LoggingApi,
  LoggingFormGroup,
  LoggingFormValue,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';
import { AlertService, DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { EMPTY, switchMap, take, takeUntil, tap } from 'rxjs';
import { DialogHelperService } from '../../dialog';
import { WorkflowEditorFacadeService } from '../../workflow-editor/services/workflow-editor-facade.service';
import { normalizeLoggingFromApiModel, normalizeLoginToApiModel } from '../converters/logging.normalizer';
import { SaveConfirmationService } from '../services/save-confirmation.service';

export abstract class AbstractCubeDialogFormComponent {
  form: UntypedFormGroup = new FormGroup({});
  formLogging?: LoggingFormGroup;
  // if we don't have data from workflow to create form then show error message
  dataIsEmpty = !Object.values(this.form.controls);
  nodeId!: string;

  abstract initialItem: object;

  abstract get dataForWorkflow(): object;

  get dataWithLogging(): object & LoggingApi {
    return {
      ...this.dataForWorkflow,
      ...(this.formLogging ? normalizeLoginToApiModel(this.formLogging.value as LoggingFormValue) : {}),
    };
  }

  protected readonly destroy$: DestroyService = this.injector.get(DestroyService);
  protected readonly alertService: AlertService = this.injector.get(AlertService);
  protected readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData> = this.injector.get(DialogService);
  protected readonly dialogHelperService: DialogHelperService = this.injector.get(DialogHelperService);
  protected readonly saveConfirmationService: SaveConfirmationService = this.injector.get(SaveConfirmationService);
  protected readonly workflowEditorFacadeService: WorkflowEditorFacadeService =
    this.injector.get(WorkflowEditorFacadeService);
  protected readonly workflowStateService: WorkflowStateService = this.injector.get(WorkflowStateService);
  protected readonly cdr: ChangeDetectorRef = this.injector.get(ChangeDetectorRef);

  private formInitialized = new EventEmitter<void>();

  constructor(protected readonly injector: Injector) {
    if (this.dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = this.dialogService.data;
      if (parameters) {
        this.nodeId = nodeId;
        this.initFormLogging(parameters);

        this.formInitialized.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
          this.saveConfirmationService.setNodeInitState(nodeId, this.dataWithLogging as Record<string, unknown>);
        });
      }
    }

    this.dialogHelperService.bindDocumentEscapeListener(this.closeDialog.bind(this));
  }

  // it uses to save init state of form data
  markFormAsInitialized(): void {
    this.formInitialized.emit(void 0);
    this.formInitialized.complete();
  }

  closeDialog(): void {
    this.saveConfirmationService
      .stateIsChanged(this.dataWithLogging as GraphNodeOptionSerialized['parameters'])
      .pipe(
        switchMap((isChanged) => {
          if (isChanged) {
            return this.dialogHelperService.closeDialogWithConfirmation(this.validation.bind(this)).pipe(
              take(1),
              takeUntil(this.destroy$),
              tap(() => {
                this.saveDataToWorkflow();
              }),
            );
          } else {
            this.dialogService.close({ needSave: false });
          }

          return EMPTY;
        }),
      )
      .subscribe();
  }

  onClickSave(): void {
    if (!this.validation()) {
      return;
    }

    this.saveDataToWorkflow();
    this.dialogService.close({ needSave: true });
  }

  maximizeDialog(): void {
    this.dialogHelperService.maximizeDialog();
  }

  saveDataToWorkflow(): void {
    const graphNodeUpdateOptions: GraphNodeUpdateParameterValuesOptions = {
      id: this.nodeId,
      parameters: this.dataWithLogging,
    };
    this.workflowEditorFacadeService.updateNodeParamterValues(graphNodeUpdateOptions);
  }

  protected validation(): boolean {
    let valid = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      valid = false;
    }

    return valid;
  }

  protected injectDataToWorkflow(workflow: WorkflowSerialized): WorkflowSerialized {
    return {
      ...workflow,
      workflow: {
        ...workflow.workflow,
        nodes: workflow.workflow.nodes.map((node) => {
          if (node.id === this.nodeId) {
            return {
              ...node,
              parameters: {
                ...node.parameters,
                ...this.dataWithLogging,
              },
            };
          }

          return node;
        }),
      },
    };
  }

  protected initFormLogging(parameters: GraphNode<unknown>['parameters']): void {
    if (Object.prototype.hasOwnProperty.call(parameters.serialize(), 'Logging')) {
      this.formLogging = new FormGroup({
        enabled: new FormControl(false),
        column: new FormControl(),
      });

      this.formLogging.controls.enabled.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((enabled) => {
        const formLogging = this.formLogging as LoggingFormGroup;
        if (enabled) {
          formLogging.controls.column.setValidators(Validators.required);
        } else {
          formLogging.controls.column.clearValidators();
        }
        formLogging.controls.column.updateValueAndValidity();
      });

      const logging = normalizeLoggingFromApiModel(parameters.serialize().Logging);
      if (logging) {
        const { enabled, column } = logging;
        this.formLogging.controls.enabled.setValue(enabled);
        this.formLogging.controls.column.setValue(column);
      }
    }
  }
}
