import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonPathService {
  getJsonPath(path: string[] | ReadonlyArray<string>): string {
    return `$.${path.join('.')}`;
  }
}
