/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-empty-function */
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartColorList,
  ChartSelectMode,
  ChartType,
  Pivot,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  UIChartColorByDimension,
  UIChartFormat,
  UIChartFormatItem,
  UIOption,
  UiChartDataLabelDisplayType,
  createChartSelectInfo,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';

import { SankeySeriesOption, SeriesOption } from 'echarts';
import * as _ from 'lodash';
import { FormatOptionConverter, LabelOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sankey-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class SankeyChartComponent extends BaseChart implements OnInit, OnDestroy, AfterViewInit {
  @Output()
  private notAllNode = new EventEmitter();

  public override isValid(pivot: Pivot): boolean {
    return (
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) > 1 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  public override draw(isKeepRange?: boolean): void {
    if (!this.isValid(this.pivot)) {
      this.noData.emit();

      return;
    }
    this.chartOption = this.initOption();
    this.chartOption = this.convertSeries();
    this.chartOption = this.convertTooltip();
    this.chartOption = this.convertEtc();
    this.chartOption = this.convertSelectionData();
    this.apply();
    this.drawFinish();
    if (!this.isPage) {
      this.selection();
    }
  }

  public override addChartSelectEventListener(): void {
    this.chart.off('click');
    this.chart.on('click', (params) => {
      if (params.dataType === 'node') {
        return;
      }
      if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
        const strScript = '(' + this.userCustomFunction + ')';
        try {
          // eslint-disable-next-line no-eval
          if (eval(strScript)({ name: 'SelectionEvent', data: params ? params.name : '' })) {
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }
      let selectMode: ChartSelectMode;
      const selectDataList = [];
      const series = this.chartOption.series;
      if (this.isSelected && _.isNull(params)) {
        selectMode = ChartSelectMode.CLEAR;
        (series as SankeySeriesOption[]).forEach((seriesItem) => {
          seriesItem.data.forEach((item) => {
            item['itemStyle']['opacity'] = 1;
            delete item['selected'];
          });
          seriesItem.links.forEach((item) => {
            item['lineStyle']['opacity'] = 0.2;
          });
        });
        this.isSelected = false;
      } else if (params != null) {
        const isSelectedNode = false;
        const seriesIndex = params.seriesIndex;
        const seriesNodeList = series[seriesIndex].data;
        const seriesEdgeList = series[seriesIndex].links;
        const selectedRowValues: string[] = [];
        let sourceDataIndex;
        let targetDataIndex;
        let isSelectMode;
        if (isSelectedNode) {
          sourceDataIndex = params.dataIndex;
          isSelectMode = _.isUndefined(seriesNodeList[params.dataIndex].selected);
          const sourceName = seriesNodeList[params.dataIndex].name;
          if (isSelectMode) {
            selectMode = ChartSelectMode.ADD;
            seriesNodeList[params.dataIndex].selected = true;
            seriesEdgeList.forEach((item) => {
              if (item.source === sourceName) {
                item.selected = true;
              }
            });
          } else {
            selectMode = ChartSelectMode.SUBTRACT;
            delete seriesNodeList[params.dataIndex].selected;
            seriesEdgeList.forEach((item) => {
              if (item.source === sourceName) {
                delete item.selected;
              }
            });
          }
        } else {
          const source: string = seriesEdgeList[params.dataIndex]['source'];
          sourceDataIndex = seriesNodeList.findIndex((item) => item.name == source);
          const target: string = seriesEdgeList[params.dataIndex]['target'];
          targetDataIndex = seriesNodeList.findIndex((item) => item.name == target);
          isSelectMode = _.isUndefined(seriesEdgeList[params.dataIndex].selected);
          if (isSelectMode) {
            selectMode = ChartSelectMode.ADD;
            seriesEdgeList[params.dataIndex].selected = true;
            seriesNodeList[sourceDataIndex].selected = true;
            seriesNodeList[targetDataIndex].selected = true;
          } else {
            selectMode = ChartSelectMode.SUBTRACT;
            delete seriesEdgeList[params.dataIndex].selected;
            if (!seriesEdgeList.some((item) => (item.source === source || item.target === source) && item.selected)) {
              delete seriesNodeList[sourceDataIndex].selected;
            }
            if (!seriesEdgeList.some((item) => (item.source === target || item.target === target) && item.selected)) {
              delete seriesNodeList[targetDataIndex].selected;
            }
          }
        }
        let isEdgeSelected = false;
        seriesEdgeList.forEach((item) => {
          if (item.selected) {
            item['lineStyle']['opacity'] = 0.6;
            isEdgeSelected = true;
          } else {
            item['lineStyle']['opacity'] = 0.2;
          }
        });
        seriesNodeList.forEach((item) => {
          if (isEdgeSelected) {
            item['itemStyle']['opacity'] = item.selected ? 1 : 0.2;
          } else {
            item['itemStyle']['opacity'] = 1;
          }
        });
        this.isSelected = isSelectMode;
        const data: any[] = this.setSelectData(params, [params.name], selectedRowValues);
        const sourceValue: any = seriesNodeList[sourceDataIndex];
        const targetValue: any = seriesNodeList[targetDataIndex];
        if (data.length > 0 && sourceValue) {
          for (const item of data) {
            if (item.name == sourceValue.field && (this.isSelected ? sourceValue.selected : !sourceValue.selected)) {
              item.data = [sourceValue.originalName];
              selectDataList.push(item);
            } else if (
              targetValue &&
              targetValue.field == item.name &&
              (this.isSelected ? targetValue.selected : !targetValue.selected)
            ) {
              item.data = [targetValue.originalName];
              selectDataList.push(item);
            }
          }
        }
      } else {
        return;
      }
      if (this.params.externalFilters) this.params.externalFilters = false;
      this.apply(false);
      this.lastDrawSeries = _.cloneDeep(this.chartOption['series'] as SeriesOption[]);
      this.chartSelectInfo.emit(createChartSelectInfo(selectMode, selectDataList, this.params));
    });
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.SANKEY,
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      series: [],
    };
  }

  protected override setPivotInfo(): void {}

  protected override convertSeries(): BaseOption {
    this.setUIData();
    this.chartOption = this.convertSeriesData();
    this.chartOption = this.convertSankeyFormatSeries(this.chartOption, this.uiOption);
    if (!this.uiOption.dataLabel) {
      this.uiOption.dataLabel = { showValue: true };
    }
    if (typeof this.uiOption.dataLabel.showValue === 'undefined') {
      this.uiOption.dataLabel.showValue = true;
    }
    this.chartOption = LabelOptionConverter.convertLabel(this.chartOption, this.uiOption);
    this.chartOption = this.additionalSeries();

    return this.chartOption;
  }

  protected override convertSeriesData(): BaseOption {
    const nodes = [];
    const counter: number[] = [0, 0, 0];
    let isNotAll = false;
    for (let num = 0; num < this.pivot.columns.length; num++) {
      const field = this.pivot.columns[num];
      for (const node of this.data.nodes) {
        if (_.eq(field.alias, node.field)) {
          if (counter[num] >= 50) {
            isNotAll = true;
            break;
          }
          counter[num]++;
          nodes.push(node);
        }
      }
    }
    this.data.nodes = nodes;
    this.notAllNode.emit(isNotAll);
    const links = [];
    for (const link of this.data.links) {
      let isSource = false;
      let isTarget = false;
      for (const node of nodes) {
        if (link.source == node.name) {
          isSource = true;
        }
        if (link.target == node.name) {
          isTarget = true;
        }
      }
      if (isSource && isTarget) {
        links.push(link);
      }
    }
    this.data.links = links;
    const schema = (<UIChartColorByDimension>this.uiOption.color).schema;
    const colorCodes: string[] = _.cloneDeep(ChartColorList[schema]);
    let totalColorIndex = 0;
    for (const item of this.data.nodes) {
      const colorIndex: number =
        totalColorIndex >= colorCodes.length ? totalColorIndex - colorCodes.length : totalColorIndex;
      totalColorIndex++;
      item.itemStyle = {
        color: colorCodes[colorIndex],
      };
    }
    for (const item of this.data.links) {
      item.lineStyle = {
        opacity: 0.2,
      };
    }
    for (const item of this.data.links) {
      item.sourceValue = item.originalSource;
      item.targetValue = item.originalTarget;
    }
    const format: UIChartFormatItem =
      !this.uiOption.valueFormat.isAll && this.uiOption.valueFormat.each.length > 0
        ? this.uiOption.valueFormat.each[0]
        : this.uiOption.valueFormat;
    this.chartOption.series = [
      {
        name: String(SeriesType.SANKEY),
        type: SeriesType.SANKEY,
        data: this.data.nodes,
        links: this.data.links,
        lineStyle: {
          color: 'source',
          curveness: 0.6,
        },
        right: '10%',
      },
    ];
    const cols: string[] = [];
    const aggs: string[] = [];
    for (const node of this.data.nodes) {
      cols.push(node.name);
    }
    this.uiOption.fieldList = [];
    for (const field of this.pivot.columns) {
      const fieldName: string = !_.isEmpty(field.alias) ? field.alias : field.name;
      this.uiOption.fieldList.push(fieldName);
      aggs.push(fieldName);
    }
    (<UIChartColorByDimension>this.uiOption.color).targetField = _.last(this.uiOption.fieldList);
    this.pivotInfo = createPivotTableInfo(cols, [], aggs);

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  protected override setUIData(): any {
    for (const node of this.data.nodes) {
      if (!node.originalName) {
        node.originalName = node.name;
      }
      node.name = node.field + CHART_STRING_DELIMITER + node.originalName;
    }
    for (const link of this.data.links) {
      if (!link.originalSource) {
        link.originalSource = link.source;
      }
      if (!link.originalTarget) {
        link.originalTarget = link.target;
      }
      link.source = link.sourceField + CHART_STRING_DELIMITER + link.originalSource;
      link.target = link.targetField + CHART_STRING_DELIMITER + link.originalTarget;
    }
    _.each(this.data.nodes, (node) => {
      node.categoryName = _.cloneDeep(node.field);
      node.nodeName = _.cloneDeep(node.originalName);
      let sumValue;
      if (0 == _.findIndex(this.pivot.columns, { alias: node.field })) {
        sumValue = _.sumBy(
          _.filter(this.data.links, (data) => {
            if (-1 !== data.source.indexOf(node.name)) {
              return data.value;
            }
          }),
          'value',
        );
      } else {
        sumValue = _.sumBy(
          _.filter(this.data.links, (data) => {
            if (-1 !== data.target.indexOf(node.name)) {
              return data.value;
            }
          }),
          'value',
        );
      }
      node.nodeValue = _.cloneDeep(sumValue);
    });
  }

  protected override additionalTooltip(): BaseOption {
    const format: UIChartFormat = this.uiOption.valueFormat;
    if (_.isUndefined(this.chartOption.tooltip)) {
      this.chartOption.tooltip = {};
    }

    if (!Array.isArray(this.chartOption.tooltip)) {
      this.chartOption.tooltip.formatter = (params): any => {
        const option = this.chartOption.series[params.seriesIndex];
        let uiData = _.cloneDeep(option.data);
        if (uiData && uiData instanceof Array) uiData = option.data[params.dataIndex];

        return this.getFormatSankeyValueSeriesTooltip(params, format, this.uiOption, option, uiData);
      };
    }

    return this.chartOption;
  }

  private getFormatSankeyValueSeriesTooltip(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (!params.data.sourceValue || !params.data.targetValue) return '';
    if (uiData) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);
      let result: string[] = [];
      let targetColumn;
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)) {
        targetColumn = _.find(this.pivot.columns, { alias: params.data.sourceField });
        result = FormatOptionConverter.getTooltipName([params.data.sourceValue], this.pivot.columns, result, true);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_NAME)) {
        targetColumn = _.find(this.pivot.columns, { alias: params.data.targetField });
        result = FormatOptionConverter.getTooltipName([params.data.targetValue], [targetColumn], result, true);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_VALUE)) {
        const name = this.pivot.aggregations[0].alias;
        result.push(FormatOptionConverter.getTooltipValue(name, this.pivot.aggregations, format, params.value));
      }

      return result.join('<br/>');
    }
  }

  private convertSankeyFormatSeries(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    let format: UIChartFormat = uiOption.valueFormat;
    if (_.isUndefined(format)) {
      return chartOption;
    }
    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormat(uiOption);
    if (axisFormat) format = axisFormat;
    const series = chartOption.series as SankeySeriesOption[];
    _.each(series, (option) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      option.label.formatter = (item) => {
        const uiData = _.cloneDeep(option.data);

        return this.getFormatSankeyValueSeries(item, format, uiOption, option, uiData);
      };
    });

    return chartOption;
  }

  private getFormatSankeyValueSeries(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';
      let isUiData = false;
      const result: string[] = [];
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)) {
        result.push(params.data.categoryName);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_NAME)) {
        result.push(params.data.nodeName);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_VALUE)) {
        result.push(FormatOptionConverter.getFormatValue(params.data.nodeValue, format));
        isUiData = true;
      }
      let label = '';
      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }

        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }
}
