<div class="ddp-box-layout4" [class.ddp-left]="isLeft">
  <div class="ddp-wrap-data-detail">
    <div class="ddp-ui-top-title">
      {{ 'msg.spaces.spaces.detail.user.info.title' | translate }}
    </div>
    <table class="ddp-table-pop">
      <colgroup>
        <col width="" />
        <col width="" />
      </colgroup>
      <tbody>
        <tr>
          <th>{{ 'msg.spaces.spaces.detail.user.fullname' | translate }}</th>
          <td>{{ invalidUserName() ? UNKNOWN_USER : userName }}</td>
        </tr>
        <tr>
          <th>{{ 'msg.spaces.spaces.detail.user.name' | translate }}</th>
          <td>{{ invalidUserId() ? UNKNOWN_USER : userId }}</td>
        </tr>
        <tr *ngIf="userEmail">
          <th>{{ 'msg.spaces.spaces.detail.user.email' | translate }}</th>
          <td>
            <a href="mailto:{{ userEmail }}">{{ userEmail }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <a href="javascript:" class="ddp-link-view" *ngIf="validUserName()" (click)="onClickViewDetail()">
      <em class="ddp-icon-view"></em>
      {{ 'msg.spaces.spaces.detail.user.view' | translate }}
    </a>
  </div>
</div>
