<form class="pt-4" [formGroup]="form">
  <ng-container>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.hdfsPath" />
      <label>HDFS path</label>
    </div>

    <h5>Format</h5>

    <div class="flex gap-3 mb-5 align-items-center">
      <div class="flex-1">
        <p-dropdown
          styleClass="w-full"
          [options]="format"
          [formControl]="paramsForm.controls.fileFormat"
          (onChange)="changeFormat($event.value)"
        ></p-dropdown>
      </div>
    </div>

    <ng-container *ngIf="paramsForm.controls.csvFileFormatParams as csvFileFormatParamsForm">
      <div class="mb-5">
        <label class="cursor-pointer" *ngIf="customParamsVisible">
          <p-checkbox
            class="mr-2"
            [formControl]="csvFileFormatParamsForm.controls.includeHeader"
            [binary]="true"
          ></p-checkbox>
          First row includes column names
        </label>
      </div>

      <div class="mb-5">
        <label class="cursor-pointer" *ngIf="customParamsVisible">
          <p-checkbox
            class="mr-2"
            [formControl]="csvFileFormatParamsForm.controls.convert01ToBoolean"
            [binary]="true"
          ></p-checkbox>
          Convert 0 and 1 to boolean
        </label>
      </div>

      <div class="flex gap-3 mb-5" *ngIf="customParamsVisible">
        <div class="flex align-items-center" *ngFor="let separator of separators">
          <p-radioButton
            [label]="separator.label"
            [value]="separator.id"
            [formControl]="csvFileFormatParamsForm.controls.separatorType"
          ></p-radioButton>
        </div>

        <div class="p-float-label">
          <input class="w-full" pInputText [formControl]="csvFileFormatParamsForm.controls.customSeparator" />
          <label>Custom</label>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div class="mb-5">
    <selfai-platform-data-source-visibility
      [control]="form.controls.visibility"
    ></selfai-platform-data-source-visibility>
  </div>

  <div class="mb-5">
    <selfai-platform-data-source-unique-name [control]="form.controls.name"></selfai-platform-data-source-unique-name>
  </div>

  <selfai-platform-data-sources-additionals
    [properties]="dataSource?.params?.properties"
    [additionalList]="additionalList"
    (changeEvent)="onPropertiesValue($event)"
  ></selfai-platform-data-sources-additionals>
</form>
