import { ElementRef } from '@angular/core';

export class RangeSliderResult {
  min: any;
  max: any;
  from: number;
  fromPercent: number;
  fromValue: number;
  to: number;
  toPercent: number;
  toValue: number;
  currentElement: JQuery;
  currentIndex: number;
}
