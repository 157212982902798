import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IFunctionTag, UserFunctionsList, UserFunctionsListItem } from '../models';
import { UserFunctionsListStore } from '../store';
import { UserFunctionApi } from '../models/user-functions-api.model';

@Injectable({ providedIn: 'root' })
export class UserFunctionsListDomainService {
  constructor(private userFunctionsListStore: UserFunctionsListStore) {}

  public getFilteredFunctionsList(): Observable<UserFunctionApi[]> {
    return this.userFunctionsListStore.filteredEntities$;
  }

  public loadUserFunctionsList(): Observable<UserFunctionsList> {
    return this.userFunctionsListStore.getAll()
      .pipe(map((userFunctions) => ({ userFunctions })));
  }

  public filterFunctionList(tags: IFunctionTag[]) {
    return this.userFunctionsListStore.setFilter(tags);
  }

  public getUserFunctionsListLoading(): Observable<boolean> {
    return this.userFunctionsListStore.loading$;
  }

  public getUserFunctionsListError(): Observable<unknown> {
    return this.userFunctionsListStore.errors$;
  }

  public getUserFunctionsListLoaded(): Observable<boolean> {
    return this.userFunctionsListStore.loaded$;
  }

  public copyUserFunction(userFunction: UserFunctionsListItem): Observable<unknown> {
    return this.userFunctionsListStore.add(userFunction);
  }

  public deleteUserFunction(id: string): void {
    this.userFunctionsListStore.delete(id);
  }
}
