import { EntityMetadataMap } from '@ngrx/data';

export const KE_WORKFLOW_SCHEDULES_ENTITY_NAME = 'workflow-schedules';

const entityMetadata: EntityMetadataMap = {
  [KE_WORKFLOW_SCHEDULES_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
  },
};

export const workflowSchedulesEntityConfig = {
  entityMetadata,
};
