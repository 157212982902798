import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { FileUploadModule } from 'ng2-file-upload';

import { CommonModule } from './common.module';

@NgModule({
  imports: [CommonModule, TranslateModule, FileUploadModule],
  declarations: [],
  exports: [FileUploadModule],
  providers: [],
})
export class FileModule {}
