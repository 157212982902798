import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { PermissionService } from '@selfai-platform/shared';
import { RbacApiService } from './rbac-api.service';

@Injectable({
  providedIn: 'root',
})
export class PipelineRbacService {
  constructor(private readonly rbacApiService: RbacApiService, private readonly permissionService: PermissionService) {}

  loadRbacInfo(): Observable<boolean> {
    return this.rbacApiService.getRbac().pipe(
      tap((rbacModel) => {
        this.permissionService.setPipelineRbacModel(rbacModel);
      }),
      catchError((err) => {
        console.error("Cannot load RBAC model from API. Can't check permissions");
        console.error(err);
        this.permissionService.setPipelineRbacModel({});

        return of(null);
      }),
      switchMap(() => of(true)),
    );
  }

  canActivate(): Observable<boolean> {
    return this.loadRbacInfo().pipe(map(() => true));
  }
}
