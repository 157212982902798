import { Component, HostBinding, Injector } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent,
} from '@angular/router';

import * as _ from 'lodash';

import { BI_ROOT_ROUTE } from '@selfai-platform/shell';

import { EventBroadcaster } from './common/event/event.broadcaster';
import { PopupService } from './common/service/popup.service';

@Component({
  selector: 'selfai-bi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  routerLoading = false;

  @HostBinding('class') incapsulationClass = '';

  constructor(
    private broadCaster: EventBroadcaster,
    public _popupService: PopupService,
    private router: Router,
    protected injector: Injector,
  ) {
    this._popupService.ptLoading = false;

    this.broadCaster.on<any>('ENTER_LAYOUT_MODULE').subscribe(() => {
      this._popupService.ptLoading = false;
    });

    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof ActivationEnd) {
        this.incapsulationClass = this.isLegacyBi(event.snapshot) ? 'bi-incapsulation' : '';
      }
    });

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);

      let url: string = event['url'];
      if (url && url.includes('index.html?path=')) {
        url = decodeURIComponent(url.split('?path=')[1]);
        if (_.startsWith(url, '/') === false) {
          url = '/' + url;
        }
        this.router.navigate([BI_ROOT_ROUTE, ...url.split('/')]);
      }
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this._popupService.ptLoading = true;
      this.routerLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this._popupService.ptLoading = false;
      this.routerLoading = false;
    }

    if (event instanceof NavigationCancel) {
      this._popupService.ptLoading = false;
      this.routerLoading = false;
    }
    if (event instanceof NavigationError) {
      this._popupService.ptLoading = false;
      this.routerLoading = false;
    }
  }

  private isLegacyBi(snapshot: ActivatedRouteSnapshot): boolean {
    if (snapshot.queryParams['dashboardId']) {
      return false;
    }

    return true;
  }
}
