import { Injectable } from '@angular/core';

import { Observable, map, tap } from 'rxjs';

import { downloadFile } from '@selfai-platform/shared';
import { KdBackendApiService } from '../../common';

@Injectable()
export class ChartPdfDownloadApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  downloadChartPdf(fileName = 'chart.pdf', widgetId: string): Observable<void> {
    return this.downloadPdf(fileName, 'chart', widgetId);
  }

  downloadDashboardPdf(fileName = 'dashboard.pdf', dashboardId: string): Observable<void> {
    return this.downloadPdf(fileName, 'dashboard', dashboardId);
  }

  private downloadPdf(filename: string, domain: 'dashboard' | 'chart', itemId: string): Observable<void> {
    return this.kdBackendApiService
      .getBlob(this.kdBackendApiService.buildPath('/images/load/pdf', { domain, itemId }))
      .pipe(
        tap((content: Blob) => {
          downloadFile(filename, content);
        }),
        map(() => void 0),
      );
  }
}
