<form [formGroup]="form">
  <h5>
    {{ 'bi.chart.mark-setting.title' | translate }}
    <ng-container *ngIf="index >= 0">&nbsp;#{{ index + 1 }}</ng-container>
  </h5>

  <div class="mb-3">
    <p-dropdown
      formControlName="measureName"
      [options]="measureList"
      optionLabel="alias"
      optionValue="name"
    ></p-dropdown>
  </div>

  <div class="mb-3">
    <p-dropdown
      formControlName="markLineType"
      [options]="markLineTypeList$ | async"
      optionLabel="name"
      optionValue="value"
    ></p-dropdown>

    <ng-container *ngIf="form.controls.markLineType.value === 'percentile'">
      <input
        pInputText
        class="ml-3 w-3rem"
        pKeyFilter="int"
        type="number"
        formControlName="percentileAmount"
        placeholder="{{ 'bi.chart.mark-setting.percentile-amount.placeholder' | translate }}"
      />
    </ng-container>
  </div>
  <h5>{{ 'bi.chart.mark-setting.line-setting.title' | translate }}</h5>

  <div>
    <div class="mb-3">
      <p-selectButton
        [options]="lineStyleList$ | async"
        formControlName="lineStyle"
        optionLabel="name"
        optionValue="value"
      ></p-selectButton>
    </div>

    <div class="flex align-items-center mb-3">
      <p-colorPicker formControlName="lineColor"></p-colorPicker>
      <label class="ml-3">{{ 'bi.chart.mark-setting.line-color.label' | translate }}</label>
    </div>

    <div>
      <div class="mb-1">
        <label>{{ 'bi.chart.mark-setting.label-rotate.label' | translate }}</label>
      </div>
      <p-selectButton
        [options]="labelRotateList$ | async"
        formControlName="labelRotate"
        optionLabel="name"
        optionValue="value"
      ></p-selectButton>
    </div>
  </div>
</form>
