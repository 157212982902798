import { LanguageDefinition } from "./language-definition";
import { UiLanguages } from "./ui-languages";

export const SELFAI_LANGUAGES: LanguageDefinition[] = [
    {
        label: 'English',
        value: UiLanguages.EN
    },
    {
        label: 'Русский',
        value: UiLanguages.RU
    }
]