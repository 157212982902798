import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from 'primeng/button';
import { DataListViewComponent, GroupAction, provideDataListView, provideDialogService } from '@selfai-platform/shell';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { WorkflowPresetsListViewService } from '../../services/workflow-presets-list-view.service';
import { DestroyService, LocalStorageKey, PREFIX_SETTINGS_LOCAL_STORAGE_KEY } from '@selfai-platform/shared';
import { WorkflowPresetsActionForItemService } from '../../services/workflow-presets-action-for-item.service';
import { Router } from '@angular/router';
import { getPresetsPathCreatePath } from '../../functions';

@Component({
  selector: 'selfai-platform-workflow-presets-list',
  standalone: true,
  imports: [CommonModule, ButtonDirective, DataListViewComponent, MenuModule, TooltipModule, TranslateModule],
  providers: [
    DestroyService,
    ...provideDataListView(<any>WorkflowPresetsListViewService, <any>WorkflowPresetsActionForItemService),
    provideDialogService(),
    {
      provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
      useValue: LocalStorageKey.DATA_SOURCE_LIST_PREFIX_SETTINGS,
    },
  ],
  templateUrl: './workflow-presets-list.component.html',
  styleUrl: './workflow-presets-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowPresetsListComponent {
  columns = this.workflowPresetsListViewService.getColumns();
  groupActions$: Observable<GroupAction[]> = this.workflowPresetsListViewService.getGroupActions();

  constructor(private router: Router, public workflowPresetsListViewService: WorkflowPresetsListViewService) {}

  createPreset(): void {
    this.router.navigate(getPresetsPathCreatePath()).then();
  }
}
