import { GraphSeriesOption } from 'echarts';

export function mapGraphChartDataLabelPosition(position: string): GraphSeriesOption['label']['position'] {
  switch (position.toLowerCase()) {
    case 'center':
      return 'inside';
    default:
      return position.toLowerCase() as GraphSeriesOption['label']['position'];
  }
}
