import { Inject, Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieKeys, DestroyService, LocalStorageKey, getDataFromLocalStorage, saveDataToLocalStorage } from '@selfai-platform/shared';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';
import { SELFAI_AVALIABLE_LANGUAGES } from './avaliable-languages.token';
import { UiLanguages } from './ui-languages';
import { CookieService } from 'ng2-cookies';

@Injectable({
  providedIn: 'root',
})
export class SelfaiTranslateService implements OnDestroy {
  private onLanguageChangeSubject: Subject<UiLanguages> = new Subject();
  $onLanguageChange = this.onLanguageChangeSubject.asObservable();

  constructor(
    private readonly translateService: TranslateService,
    private readonly config: PrimeNGConfig,
    private readonly cookieService: CookieService,
    private readonly destroy$: DestroyService,
    @Inject(SELFAI_AVALIABLE_LANGUAGES) private readonly avaliableLanguages: UiLanguages[],
  ) {}

  ngOnDestroy(): void {
    this.onLanguageChangeSubject.complete();
  }

  getCurrentLanguage(): UiLanguages {
    const initData: CurrentUserLocaleState = {
      language: this.avaliableLanguages.length > 0 ? this.avaliableLanguages[0] : UiLanguages.EN,
    };
    const savedLanguage = getDataFromLocalStorage<CurrentUserLocaleState>(
      LocalStorageKey.CURRENT_USER_LANGUAGE,
      initData,
    );
    if (!this.avaliableLanguages.includes(savedLanguage.language)) {
      savedLanguage.language = this.avaliableLanguages[0];
      saveDataToLocalStorage<CurrentUserLocaleState>(LocalStorageKey.CURRENT_USER_LANGUAGE, savedLanguage);
    }

    return savedLanguage.language;
  }

  setLanguage(language: UiLanguages) {
    this.setTranslation(language);
    this.onLanguageChangeSubject.next(language);
    saveDataToLocalStorage<CurrentUserLocaleState>(LocalStorageKey.CURRENT_USER_LANGUAGE, {
      language,
    });
  }

  initTranslation() {
    const currentLang = this.getCurrentLanguage();
    this.translateService.setDefaultLang(currentLang);
    this.setTranslation(currentLang);
  }

  private setTranslation(language: UiLanguages) {
    this.translateService.use(language).pipe(takeUntil(this.destroy$)).subscribe();
    this.translateService.get('primeng').pipe(takeUntil(this.destroy$)).subscribe((res) => this.config.setTranslation(res));
    this.cookieService.set(CookieKeys.LOCALE, language, 0, '/');
  }
}

export interface CurrentUserLocaleState {
  language: UiLanguages;
}
