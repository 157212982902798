import { Injectable } from '@angular/core';
import { Session } from '@selfai-platform/pipeline-common';
import { PipelineConfigService } from '@selfai-platform/shared';
import { SessionListStateService } from '@selfai-platform/storage';
import { interval, Observable, ReplaySubject, takeUntil, withLatestFrom } from 'rxjs';
import { SessionsManagerAdapter } from './sessions-manager.adapter';

@Injectable({
  providedIn: 'root',
})
export class SessionsManagerService {
  destroy$ = new ReplaySubject(1);

  constructor(
    private readonly sessionListStateService: SessionListStateService,
    private readonly sessionsManagerAdapter: SessionsManagerAdapter,
    private readonly pipelineConfigService: PipelineConfigService,
  ) {}

  loadSessionList(): void {
    this.sessionsManagerAdapter
      .loadList()
      .subscribe((sessionList) => this.sessionListStateService.setSessionList(sessionList));
  }

  getSessionList(): Observable<Session[] | undefined> {
    return this.sessionListStateService.getSessionList();
  }

  stopSession(workflowId: string): void {
    this.sessionsManagerAdapter
      .stopByWorkflowId(workflowId)
      .pipe(withLatestFrom(this.sessionListStateService.getSessionList()))
      .subscribe(([, sessionList]) => {
        this.sessionListStateService.setSessionList(
          (sessionList || []).filter((session) => session.workflowId !== workflowId),
        );
      });
  }

  startPollingSession(): void {
    interval(this.getPollinInterval())
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadSessionList();
      });
  }

  stopPollingSession(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getPollinInterval(): number {
    const config = this.pipelineConfigService.getConfig();

    return config.sessions.pollingInterval;
  }
}
