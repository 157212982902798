import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';

import { saveAs } from 'file-saver';
import * as _ from 'lodash';

import { isNumeric } from '../../../shared/utils';

import { header, SlickGridHeader } from './grid.header';
import { GridOption, Option } from './grid.option';

declare const Slick: any;

@Component({
  selector: '[grid-component], grid-component',
  templateUrl: './grid.component.html',
  styleUrls: [
    '../../../../assets/grid/slick.grid.css',
    '../../../../assets/grid/slick.columnpicker.css',
    '../../../../assets/grid/slick.grid.override.css',
    '../../../../assets/grid/slick.headerbuttons.css',
    '../../../../assets/grid/slick.headermenu.css',
  ],
})
export class GridComponent implements AfterViewInit, OnDestroy {
  private clickEnabled = true;

  private GRID_TARGET_ID = 'myGrid';

  private GRID_TOTAL_ID = 'total';

  private ROW_EMPTY = -1;

  private ID_PROPERTY = '_idProperty_';

  public grid;
  public dataView;
  private option: Option;
  private fields: string[] = [];
  private isGridCreated: boolean;
  private isError: boolean;
  private GRID_DEFAULT_OPTION: Option;
  private DATA_VIEW_DEFAULT_OPTION = {
    groupItemMetadataProvider: null,
    inlineFilters: false,
  };
  private gridSelectionModelType = '';

  private _selectColumnIds: string[] = [];

  private columnResized = false;

  @Output() private selectedEvent = new EventEmitter();

  @Output() private sortingEvent = new EventEmitter();

  @Output() private selectedHeaderEvent = new EventEmitter();

  @Output() private selectedHeaderMenuEvent = new EventEmitter();

  @Output() private onColumnResize = new EventEmitter();

  @Output() private onHeaderRowCellRendered = new EventEmitter();

  @Output() private onContextMenuClick = new EventEmitter();

  constructor(public elementRef: ElementRef) {
    this.createGridUniqueId();
  }

  public ngAfterViewInit(): void {
    this.GRID_DEFAULT_OPTION = new GridOption().SyncColumnCellResize(true).MultiColumnSort(true).build();
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public invalidateAllRows() {
    this.grid.invalidateAllRows();
  }

  public destroy() {
    if (this.grid) {
      $(window).off();
      this.grid.invalidate();
      this.grid.destroy();
    }
    this.fields = [];
    this.grid = null;
    this.dataView = null;
  }

  public csvDownload(fileName: string = ''): void {
    const rows: any[] = [];

    const header: any[] = [];
    this.fields.forEach((headerName, index) => {
      header.push('"' + headerName + '"');
    });

    rows.push(header.join(','));

    this.getRows().forEach((column) => {
      const obj: any[] = [];
      this.fields.forEach((headerName, index) => {
        obj.push('"' + column[headerName] + '"');
      });
      rows.push(obj.join(','));
    });

    this.downloadCSV(rows.join('\n'), _.isEmpty(fileName) ? this.createTimeStamp() : fileName);
  }

  public excelDownload(fileName: string = ''): void {
    const rows: any[] = [];

    const header: any[] = [];
    this.fields.forEach((headerName, index) => {
      header.push('"' + headerName + '"');
    });

    rows.push(header.join(','));

    this.getRows().forEach((column) => {
      const obj: any[] = [];
      this.fields.forEach((headerName, index) => {
        obj.push('"' + column[headerName] + '"');
      });
      rows.push(obj.join(','));
    });

    this.downloadExcel(rows.join('\n'), _.isEmpty(fileName) ? this.createTimeStamp() : fileName);
  }

  saveDataPreparation() {
    localStorage.setItem('share', JSON.stringify(this.getGridRows()));
  }

  public resetActiveCell() {
    $(this.grid.getActiveCellNode()).removeClass('ddp-selected');
    this.grid.resetActiveCell();
  }

  public getGridRows(): any[] {
    return _.cloneDeep(this.dataView.getItems());
  }

  public getSelectedRows(scope: any = null): any[] {
    const fnScope: any = scope === null ? this : scope;
    let rows: any[] = fnScope.grid.getSelectedRows().map((rowIndex) => fnScope.dataView.getItem(rowIndex));

    if (true === fnScope.option.frozenTotal) {
      rows = rows.filter((row) => row.id.toString() !== fnScope.GRID_TOTAL_ID);
    }

    return _.cloneDeep(rows);
  }

  public getRows(scope: any = null): any[] {
    const fnScope: any = scope === null ? this : scope;

    let rRows: any[] = [];

    const gridRowLength = fnScope.dataView.getLength();
    for (let index = 0; index < gridRowLength; index += 1) {
      const row = fnScope.dataView.getItem(index);
      if (!('undefined' === typeof row)) {
        rRows.push(row);
      }
    }

    if (true === fnScope.option.frozenTotal) {
      rRows = rRows.filter((row) => row.id.toString() !== fnScope.GRID_TOTAL_ID);
    }

    return _.cloneDeep(rRows);
  }

  public columnAllSelection(scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;
    fnScope.grid.getColumns().forEach((item) => {
      this.columnSelection(item.id, true);
    });
  }

  public columnAllUnSelection(scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;
    fnScope.grid.getColumns().forEach((item) => {
      this.columnUnSelection(item.id, false);
    });
  }

  public columnSelection(column: number | string, scope: any = null): void {
    this.selectColumn(column, true);
  }

  public selectRowActivate(column: number | string, scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;

    const selectedRows: any[] = [];
    selectedRows.push(column);
    fnScope.grid.setSelectedRows(selectedRows);
  }

  public setCurrentSortColumns(isAsc: boolean, scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;
    const arr = [];
    const columnsList = fnScope.grid.getColumns();
    for (let index = 0; index < columnsList.length; index++) {
      const obj = {
        columnId: columnsList[index]['id'],
        sortAsc: isAsc,
      };
      arr.push(obj);
    }
    fnScope.grid.setSortColumns(arr, isAsc);
  }

  public columnUnSelection(column: number | string, scope: any = null): void {
    this.selectColumn(column, false);
  }

  public columnSelectionToggle(column: number | string, scope: any = null): void {
    this.selectColumn(column, 'TOGGLE');
  }

  public rowAllSelection(scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;

    let rRows: any[] = [];

    const gridRowLength = fnScope.dataView.getLength();
    for (let index = 0; index < gridRowLength; index += 1) {
      rRows.push(index);
    }

    if (true === fnScope.option.frozenTotal) {
      rRows = rRows.filter((rowIndex) => {
        const row: any = fnScope.dataView.getItem(rowIndex);
        return row.id.toString() !== fnScope.GRID_TOTAL_ID;
      });
    }

    fnScope.grid.setSelectedRows(rRows);
  }

  public rowSelection(index): void {
    if (this.gridSelectionModelType === 'cell') {
      this.rowAllUnSelection();
    }
    this.gridSelectionModelType = 'row';
    this.grid.setSelectedRows(index);
  }

  public rowAllUnSelection(scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;
    fnScope.grid.setSelectedRows([]);
  }

  public resize(scope: any = null): void {
    const fnScope: any = scope === null ? this : scope;

    fnScope.grid.resizeCanvas();
  }

  public create(headers: header[], rows: any[], option: Option = null): boolean {
    try {
      this.destroy();

      rows.forEach((row, index) => (row._idProperty_ = index + 1));

      if (rows.length > 0) {
        if (typeof rows[0].id === 'undefined') {
          rows.forEach((row) => (row.id = row._idProperty_));
        }
      }

      this.isGridCreated = false;

      this.fields = [];

      this.validationParams(headers, option);

      this.createSearchFields(headers);

      if (this.option.dualSelectionActivate) {
        headers.unshift(
          new SlickGridHeader()
            .Id(this.ID_PROPERTY)
            .Name(!this.option.enableSeqSort ? '' : 'No.')
            .Field(this.ID_PROPERTY)
            .Behavior('select')
            .CssClass(`dual_selection${this.ID_PROPERTY}`)
            .CssClass('txt-center')
            .Width(this.option.enableSeqSort ? 60 : 0)
            .CannotTriggerInsert(true)
            .Resizable(true)
            .Unselectable(true)
            .Sortable(this.option.enableSeqSort)
            .Formatter(
              (function (scope) {
                return function (row, cell, value, columnDef, dataContext) {
                  if (scope.option.enableHeaderClick && columnDef.id === '_idProperty_') {
                    return "<div style='line-height:30px;'>" + '&middot;' + '</div>';
                  } else {
                    return value;
                  }
                };
              })(this),
            )
            .build(),
        );
      }

      this.createGrid(headers, rows);

      this.bindingGridEvents();
    } catch (e) {
      this.isGridCreated = true;

      $(window).off();

      console.error(e);
    }

    return this.isGridCreated;
  }

  public noShowData() {
    this.destroy();
    return true;
  }

  public search(searchText: string = '', searchFields: string[] = []): boolean {
    try {
      this.isError = false;

      this.validationSearchFields(searchFields);

      this.grid.setSelectedRows([]);

      this.executeFilter(searchText, GridComponent.isAllSearch(searchFields) ? this.fields : searchFields);

      this.dataView.refresh();

      this.grid.invalidate();
    } catch (e) {
      this.isError = true;

      console.error(e);
    }

    return this.isError;
  }

  public allSelection(): void {
    if (this.getSelectedRows().length > 0) {
      this.rowAllUnSelection();
    } else {
      this.rowAllSelection();
    }

    this.clickGridCanvasElement(this);
  }

  public addRow(row: any[]): boolean {
    try {
      this.isError = false;

      if (0 === row.length || 1 < row.length) {
        throw new Error(`Only one array length is allowed. Array length : ${row.length}`);
      }

      const aRow: any = row[0];

      const rowId = aRow.id;

      const gridRows: any[] = this.getGridRows();
      if (0 < gridRows.length) {
        const row: any[] = gridRows.filter((row) => String(row.id) === String(rowId));

        if (0 < row.length) {
          throw new Error(`An already existing ID has been used. ID: ${rowId}`);
        } else {
          gridRows.push(aRow);

          this.dataView.setItems(gridRows);
        }
      } else {
        this.dataView.setItems([aRow]);
      }

      this.dataView.refresh();

      this.grid.invalidate();
    } catch (e) {
      this.isError = true;

      console.error(e);
    }

    return this.isError;
  }

  public deleteRowByRowId(rowId: string): boolean {
    try {
      this.isError = false;

      if (_.isEmpty(rowId)) {
        throw new Error(`Row id is empty. ID: ${rowId}`);
      }

      this.dataView.deleteItem(rowId);

      this.dataView.setItems(this.dataView.getItems());

      this.dataView.refresh();

      this.grid.invalidate();
    } catch (e) {
      this.isError = true;

      console.error(e);
    }

    return this.isError;
  }

  public selectColumn(
    column: number | string,
    isSelectOrToggle: boolean | string,
    scope: any = null,
    isShiftKeyPressed?: boolean,
    isCtrlKeyPressed?: boolean,
    type?: string,
  ): void {
    const fnScope: any = scope === null ? this : scope;

    let columnId = '';

    if ('string' === typeof column) {
      columnId = column;
    } else if ('number' === typeof column) {
      columnId = fnScope.grid.getColumns()[column].id;
    } else {
      throw new Error('Invalid column information!!');
    }

    let isSelect = false;
    if ('string' === typeof isSelectOrToggle && 'TOGGLE' === isSelectOrToggle) {
      isSelect = 0 === this._selectColumnIds.filter((item) => item === columnId).length;
    } else {
      isSelect = <boolean>isSelectOrToggle;
    }

    if (this.option.multiSelect === false) {
      this._selectColumnIds = isSelect ? [columnId] : [];
    } else {
      this._selectColumnIds = this._selectColumnIds.filter((item) => item !== columnId);
      isSelect && this._selectColumnIds.push(columnId);
    }

    fnScope.grid.getColumns().forEach((item) => {
      item['select'] = -1 < this._selectColumnIds.indexOf(item.id);
    });

    const selectedColumnData = {
      id: columnId,
      isSelect: isSelect,
      selectColumnIds: this._selectColumnIds,
      shiftKey: isShiftKeyPressed,
      ctrlKey: isCtrlKeyPressed,
    };

    if (type) {
      selectedColumnData['type'] = type;
    }

    fnScope.selectedHeaderEvent.emit(selectedColumnData);

    this.grid.invalidate();
    this.grid.render();
  }

  private createSearchFields(headers: header[]): void {
    headers.forEach((header) => {
      const fieldName: string = header['field'];
      if (!('undefined' === typeof fieldName)) {
        this.fields.push(fieldName);
      }
    });
  }

  private createGrid(headers: header[], rows: any[]): void {
    this.dataView = new Slick.Data.DataView(this.DATA_VIEW_DEFAULT_OPTION);
    this.dataView.beginUpdate();
    this.dataView.setItems(rows);
    this.dataView.endUpdate();

    this.elementRef.nativeElement.children[0].id = this.GRID_TARGET_ID;

    this.grid = new Slick.Grid(`#${this.GRID_TARGET_ID}`, this.dataView, headers, this.option);

    const autoTooltips = new Slick.AutoTooltips({ enableForHeaderCells: true });
    this.grid.registerPlugin(autoTooltips);

    const autoSize = new Slick.AutoColumnSize(true);
    this.grid.registerPlugin(autoSize);

    if (true === this.option.columnGroup) {
      const plugin: any = new Slick.ColumnGroup();
      this.grid.registerPlugin(plugin);
    }

    if (this.isCellExternalCopyManagerActivate()) {
      if (false === this.option.enableCellNavigation) {
        this.option.enableCellNavigation = true;
      }

      this.initCellExternalCopyManager(this);
    } else {
      this.grid.setSelectionModel(new Slick.RowSelectionModel({ selectActiveRow: false }));
    }

    if (true === this.option.frozenTotal) {
      const rowsHeight: number = this.getRowsHeight();
      if (rows.length > rowsHeight) {
        this.option.frozenRow = 1;
        this.option.frozenBottom = true;
      } else {
        this.option.frozenRow = -1;
        this.option.frozenBottom = false;
      }

      this.grid.setOptions(this.option);
    }

    if (this.option.enableHeaderMenu) {
      const headerButtonsPlugin = new Slick.Plugins.HeaderButtons();
      headerButtonsPlugin.onCommand.subscribe(
        (function (scope) {
          return function (e, args) {
            scope.onContextMenuClick.emit({
              columnName: args.button.command,
              index: args.button.index,
              left: e.pageX,
              top: e.pageY,
              columnType: args.button.type,
            });
            scope.grid.invalidate();
          };
        })(this),
      );
      this.grid.registerPlugin(headerButtonsPlugin);
    }
  }

  private bindingGridEvents(): void {
    if (this.option.rowSelectionActivate) {
      this.grid.onClick.subscribe(
        (function (scope) {
          return function (event, args) {
            const rowIndex: number = args.row;

            if (args.cell !== 0) {
              scope.gridSelectionModelType = 'cell';

              scope.columnAllUnSelection();

              scope.rowAllUnSelection();

              scope.initCellExternalCopyManager(scope);
              return;
            }

            scope.gridSelectionModelType = 'row';

            scope.initRowSelectionModel(scope);
            scope.selectRowActivate(rowIndex);
          };
        })(this),
      );
    }

    this.grid.onSort.subscribe(
      (function (scope) {
        return function (event, args) {
          try {
            if (scope.isCellExternalCopyManagerActivate()) {
              scope.grid.setSelectedRows([]);
            } else {
              scope.dataView.syncGridSelection(scope.grid, true);
            }

            let totalRow: any = null;

            if (true === scope.option.frozenTotal) {
              const rowsHeight: number = this.getRowsHeight();

              if (scope.dataView.getLength() > rowsHeight) {
                scope.option['frozenRow'] = 1;
                scope.option['frozenBottom'] = true;
              } else {
                scope.option['frozenRow'] = -1;
                scope.option['frozenBottom'] = false;
              }

              scope.grid.setOptions(scope.option);
              scope.grid.invalidate();

              totalRow = _.cloneDeep(scope.getRowByRowIndex(scope.getRowIndexByRowId(scope.GRID_TOTAL_ID)));

              if (!(-1 === totalRow)) {
                scope.deleteRowByRowId(scope.GRID_TOTAL_ID);
              }
            }

            const cols = args.sortCols;

            const sort = () =>
              scope.dataView.sort((row1, row2) => {
                for (let index = 0; index < cols.length; index += 1) {
                  const field = cols[index].sortCol.field;
                  const sign = cols[index].sortAsc ? 1 : -1;

                  if (_.isNil(row1[field]) || row1[field] === '') {
                    row1[field] = ' ';
                  }

                  if (_.isNil(row2[field]) || row2[field] === '') {
                    row2[field] = ' ';
                  }

                  const value1 = isNumeric(row1[field]) ? Number(row1[field]) : row1[field];
                  const value2 = isNumeric(row2[field]) ? Number(row2[field]) : row2[field];

                  const result = (value1 === value2 ? 0 : value1 > value2 ? 1 : -1) * sign;
                  if (!(0 === result)) {
                    return result;
                  }
                }
                return 0;
              });

            if (scope.option.dualSelectionActivate) {
              if (scope.isHeaderFieldIdProperty(args)) {
                scope.dataView.sort((row1, row2) => {
                  for (let index = 0; index < cols.length; index += 1) {
                    const field = cols[index].sortCol.field;
                    const sign = cols[index].sortAsc ? 1 : -1;
                    const value1 = Number(row1[field]);
                    const value2 = Number(row2[field]);
                    const result = (value1 === value2 ? 0 : value1 > value2 ? 1 : -1) * sign;
                    if (!(0 === result)) {
                      return result;
                    }
                  }
                  return 0;
                });
              } else {
                sort();
              }
            } else {
              sort();
            }

            if (true === scope.option.frozenTotal) {
              if (!(null === totalRow) && !(-1 === totalRow)) {
                scope.addRow([totalRow]);
                scope.dataView.getItemMetadata(scope.getRows().length - 1);
              }
            }

            scope.grid.invalidate();
            scope.grid.render();

            scope.clickGridCanvasElement(scope);
          } catch (e) {
            console.error(e);
          } finally {
            scope.sortingEvent.emit();
          }
        };
      })(this),
    );

    if (!this.isCellExternalCopyManagerActivate() || this.option.dualSelectionActivate) {
      this.grid.onClick.subscribe(
        (function (scope) {
          return function (event, args) {
            if (!scope.clickEnabled) {
              return;
            }

            setTimeout(() => (scope.clickEnabled = true), 300);

            scope.clickEnabled = false;

            const result = {
              event,
              row: null,
              selected: null,
              error: false,
            };

            try {
              const rowIndex: number = args.row;

              const row: any = scope.dataView.getItem(rowIndex);

              if (row === scope.ROW_EMPTY) {
                throw Error('Row is empty.');
              }

              if (scope.option.dualSelectionActivate) {
                if (args.cell === 0) {
                  if (scope.gridSelectionModelType === 'cell') {
                    scope.gridSelectionModelType = 'row';

                    scope.rowAllUnSelection();
                  }

                  scope.initRowSelectionModel(scope);

                  scope.option.enableMultiSelectionWithCtrlAndShift
                    ? scope.rowClickWithCtrlShiftOption(scope, row, result, rowIndex)
                    : scope.onClickRowSelection(scope, row, result, rowIndex);
                } else {
                  scope.gridSelectionModelType = 'cell';

                  scope.columnAllUnSelection();

                  scope.rowAllUnSelection();

                  scope.initCellExternalCopyManager(scope);
                }
              } else {
                scope.option.enableMultiSelectionWithCtrlAndShift
                  ? scope.rowClickWithCtrlShiftOption(scope, row, result, rowIndex)
                  : scope.onClickRowSelection(scope, row, result, rowIndex);
              }

              result.row = row;
              scope.grid.invalidate();
              scope.grid.render();
            } catch (e) {
              result.error = true;

              console.error(e);
            } finally {
              scope.selectedEvent.emit(result);
            }
          };
        })(this),
      );
    }

    this.dataView.onRowCountChanged.subscribe(
      (function (scope) {
        return function () {
          if (true === scope.option.frozenTotal) {
            const rowsHeight: number = this.getRowsHeight();

            if (scope.dataView.getLength() > rowsHeight) {
              scope.option.frozenRow = 1;
              scope.option.frozenBottom = true;
            } else {
              scope.option.frozenRow = -1;
              scope.option.frozenBottom = false;
            }

            scope.grid.setOptions(scope.option);
            scope.grid.invalidate();
          }

          scope.grid.updateRowCount();
          scope.grid.render();
        };
      })(this),
    );

    if (this.option.enableHeaderClick) {
      this.grid.onHeaderClick.subscribe(
        (function (scope) {
          return function (event, args) {
            if (scope.columnResized) {
              scope.columnResized = false;
              return;
            }

            if (scope.gridSelectionModelType === 'cell') {
              scope.rowAllUnSelection();
              scope.initRowSelectionModel(scope);
            }

            if (scope.option.enableMultiSelectionWithCtrlAndShift) {
              if (event.metaKey === false && event.ctrlKey === false && event.shiftKey === false) {
                scope.columnAllUnSelection(scope);
              }
            }

            if (args.column.name === '') {
              return;
            }

            if (args.column.columnType === 'MAP' || args.column.columnType === 'ARRAY') {
              scope.selectColumn(
                args.column.id,
                'TOGGLE',
                null,
                event.shiftKey,
                event.metaKey || event.ctrlKey,
                args.column.columnType,
              );
            } else {
              scope.selectColumn(args.column.id, 'TOGGLE', null, event.shiftKey, event.metaKey || event.ctrlKey);
            }
            scope.columnResized = false;
          };
        })(this),
      );
    }

    if (this.option.editable) {
      this.grid.onActiveCellChanged.subscribe(
        (function (scope) {
          return function (event, args) {
            setTimeout(() => {
              if (scope.grid && args.row && args.cell) {
                $(scope.grid.getCellNode(args.row, args.cell)).find('input').focus();
              }
            }, 500);
          };
        })(this),
      );
    }

    if (this.option.showHeaderRow) {
      if (this.option.headerRowHeight !== 25) {
        $('.slick-viewport').css('top', this.option.headerRowHeight + 'px');
      }
      this.grid.onHeaderRowCellRendered.subscribe(
        (function (scope) {
          return function (event, args) {
            scope.onHeaderRowCellRendered.emit(args);
          };
        })(this),
      );
    }

    this.grid.onColumnsResized.subscribe(
      (function (scope) {
        return function (event, args) {
          for (let i = 0, totI = scope.grid.getColumns().length; i < totI; i++) {
            const column = scope.grid.getColumns()[i];
            scope.columnResized = true;

            if (column.width != column.previousWidth) {
              scope.onColumnResize.emit({ idx: i, name: column.id, width: column.width });
              setTimeout(function () {
                scope.columnResized = false;
              }, 300);
            }
          }
        };
      })(this),
    );

    $(window).resize(() => {
      setTimeout(() => {
        if (typeof this.grid !== 'undefined') {
          this.resize();
        }
      }, 500);
    });
  }

  private initRowSelectionModel(scope) {
    scope.grid.setSelectionModel(new Slick.RowSelectionModel({ selectActiveRow: false }));
  }

  private static isAllSearch(searchFields: string[]): boolean {
    return searchFields.length === 0;
  }

  private executeFilter(searchText: string, fields: string[]): void {
    if (_.isEmpty(searchText) && 0 === searchText.length) {
      this.dataView.setFilter(() => {
        return true;
      });
    } else {
      this.dataView.setFilter((row) => {
        let isValue = false;
        fields.forEach((key) => {
          if (-1 < String(row[key]).toLocaleUpperCase().indexOf(searchText.toLocaleUpperCase())) {
            isValue = true;
          }

          if (this.GRID_TOTAL_ID === row.id) {
            isValue = this.option.frozenTotal === true;
          }
        });
        return isValue;
      });
    }
  }

  private validationSearchFields(fields: string[]): void {
    fields.forEach((argSearchField) => {
      let isCheckedField = false;
      this.fields.forEach((field) => {
        if (argSearchField === field) {
          isCheckedField = true;
        }
      });

      if (!isCheckedField) {
        throw new Error(`Not found '${argSearchField}' field name.`);
      }
    });
  }

  private getRowByRowIndex(rowIndex: number, scope: any = null): any | number {
    const fnScope: any = scope === null ? this : scope;
    const fnRowIndex: number = typeof rowIndex !== 'number' ? Number(rowIndex) : rowIndex;
    const row = fnScope.dataView.getItemByIdx(fnRowIndex);
    return typeof row === 'undefined' ? fnScope.ROW_EMPTY : row;
  }

  private getRowIndexByRowId(rowId: any): any | number {
    const rowIndex: number = this.dataView.getIdxById(rowId);
    return typeof rowIndex === 'undefined' ? this.ROW_EMPTY : rowIndex;
  }

  private createGridUniqueId(): void {
    const date: Date = new Date();
    const timestamp: string =
      date.getFullYear().toString() + (date.getMonth() + 1).toString() + date.getDate().toString();
    this.GRID_TARGET_ID = this.GRID_TARGET_ID + '_' + timestamp + '_' + this.generateUUID();
  }

  private getRowsHeight(): number {
    return Math.floor($(this.GRID_TARGET_ID).find('.slick-pane-top').height() / this.option.rowHeight);
  }

  private isCellExternalCopyManagerActivate(): boolean {
    return true === this.option.cellExternalCopyManagerActivate;
  }

  private validationParams(headers: header[], option: Option): void {
    if (0 === headers.length) {
      throw new TypeError('Invalid header value.');
    }

    if (!(null === option)) {
      this.option = JSON.stringify(option) === '{}' ? this.GRID_DEFAULT_OPTION : option;
    } else {
      this.option = this.GRID_DEFAULT_OPTION;
    }
  }

  private initCellExternalCopyManager(scope): void {
    scope.grid.setSelectionModel(new Slick.CellSelectionModel({ selectActiveRow: false }));

    const cellCopyManager = new Slick.CellExternalCopyManager();
    cellCopyManager.init(scope.grid);
  }

  private onClickRowSelection(
    scope: any,
    row: any,
    result: { event: any; row: any; selected: any; error: boolean },
    rowIndex: number,
  ): void {
    const hasRow: any[] = scope
      .getSelectedRows()
      .filter((selectedRow) => String(selectedRow[this.ID_PROPERTY]) === String(row[this.ID_PROPERTY]));

    result.selected = hasRow.length <= 0;
    if (result.selected) {
      if (this.option.multiSelect === false) {
        const selectedRows: any[] = [];
        selectedRows.push(rowIndex);
        scope.grid.setSelectedRows(selectedRows);
      } else {
        const selectedRows: any[] = scope.grid.getSelectedRows();
        selectedRows.push(rowIndex);

        scope.grid.setSelectedRows(selectedRows);
      }
    } else {
      let selectedRows: any[] = scope.grid.getSelectedRows();
      selectedRows = selectedRows.filter((selectedRowIndex) => String(selectedRowIndex) !== String(rowIndex));

      scope.grid.setSelectedRows(selectedRows);
    }
  }

  private isHeaderFieldIdProperty(args): boolean {
    return args.sortCols[0].sortCol.field === this.ID_PROPERTY;
  }

  private clickGridCanvasElement(scope): void {
    scope.elementRef.nativeElement.querySelector('.slick-viewport').querySelector('.grid-canvas').click();
  }

  private isIdPropertyAreaDrag(e): boolean {
    return e.target.className.indexOf('dual_selection_idProperty') === -1;
  }

  private createTimeStamp(): string {
    const date: Date = new Date();
    const timestamp: string =
      date.getFullYear().toString() +
      (date.getMonth() + 1).toString() +
      date.getDate().toString() +
      date.getHours().toString() +
      date.getMinutes().toString() +
      date.getMilliseconds().toString();
    return timestamp;
  }

  private generateUUID(): string {
    let d = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  private downloadCSV(csv, filename): void {
    saveAs(new Blob(['\ufeff' + csv], { type: 'application/csv;charset=utf-8' }), filename + '.csv');
  }

  private downloadExcel(xlsx, filename): void {
    saveAs(new Blob(['\ufeff' + xlsx], { type: 'application/vnd.ms-excel;charset=charset=utf-8' }), filename + '.xls');
  }

  private rowClickWithCtrlShiftOption(
    scope: any,
    row: any,
    result: { event: any; row: any; selected: any; error: boolean },
    rowIndex: number,
  ): void {
    let selectedRows: any[] = [];
    const hasRow: any[] = scope
      .getSelectedRows()
      .filter((selectedRow) => String(selectedRow[this.ID_PROPERTY]) === String(row[this.ID_PROPERTY]));
    result.selected = hasRow.length <= 0;

    if (result.selected) {
      if (this.option.multiSelect === false) {
        if (!(result.event.metaKey === false && result.event.ctrlKey === false && result.event.shiftKey === false)) {
          selectedRows = scope.grid.getSelectedRows();
        }
        selectedRows.push(rowIndex);
      } else {
        if (result.event.metaKey === false && result.event.ctrlKey === false && result.event.shiftKey === false) {
          selectedRows = scope.grid.getSelectedRows();
          selectedRows.push(rowIndex);
        }
      }
      scope.grid.setSelectedRows(selectedRows);
    } else {
      if (result.event.metaKey === false && result.event.ctrlKey === false && result.event.shiftKey === false) {
        if (scope.grid.getSelectedRows().indexOf(row['_idProperty_'] - 1) > -1) {
          scope.grid.setSelectedRows([row['_idProperty_'] - 1]);
        }
      } else {
        selectedRows = scope.grid
          .getSelectedRows()
          .filter((selectedRowIndex) => String(selectedRowIndex) !== String(rowIndex));
        scope.grid.setSelectedRows(selectedRows);
      }
    }
  }
}
