import { ShelveFieldType } from '../../chart';
import { Dashboard } from '../../dashboard';
import { SearchQueryRequest } from '../../datasource';
import { PageWidget } from '../../widget';
import { Filter, PivotField, DashobardFieldType } from '../../workbook';

export function createAssociationDataRequest(
  widget: PageWidget,
  dashboard: Dashboard,
  sourceName: string,
  filters: Filter[],
  primaryKey: string,
) {
  const dataSource = dashboard.dataSources.find(
    (dataSource) => dataSource.name === sourceName || dataSource.engineName === sourceName,
  );

  const boardDataSource = dashboard.configuration.dataSource.dataSources.find((t) => t.id === dataSource.id);

  const columns: PivotField[] = dataSource.fields
    .filter((field) => field.name === primaryKey)
    .map((field) => {
      return {
        aggregationType: field.aggrType,
        name: field.name,
        alias: field.alias || field.name,
        subRole: field.role,
        subType: field.type,
        type: field.role.toLowerCase() as DashobardFieldType,
        format: field.format as any,
      };
    });

  const request: SearchQueryRequest = {
    context: {
      'discovery.route.uri': '/bi/workbook/' + dashboard?.workBook?.id || '',
      'discovery.dashboard.id': dashboard.id,
      'discovery.widget.id': widget.id,
    },
    userFields: dashboard.configuration.customFields?.filter((t) => t.dataSource === dataSource.engineName) || [],
    filters: filters.filter(
      (filter) => filter.dataSource === dataSource.engineName || filter.dataSource === dataSource.name,
    ),
    pivot: {
      columns: columns,
      rows: [],
      aggregations: [
        {
          type: ShelveFieldType.MEASURE,
          aggregationType: 'NONE',
          name: 'count',
          subType: 'STRING',
          subRole: 'MEASURE',
          alias: 'COUNT(count)',
          format: {
            type: 'number',
            sign: 'KRW',
            decimal: 2,
            useThousandsSep: true,
            abbr: 'NONE',
            customSymbol: null,
          },
          aggregationTypeList: [],
        },
      ],
    },
    dataSource: { ...boardDataSource, name: dataSource.engineName || dataSource.name },
    limits: { limit: 1000000, sort: [] },
    aliases: [],
    resultFormat: {
      type: 'chart',
      mode: 'grid',
      options: { addMinMax: true, isOriginal: true },
      columnDelimeter: '―',
    },
  };

  return request;
}
