import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { WIDGET_FILTERS_ENTITY_NAME } from '../const';
import { WidgetFiltersState } from '../models';

@Injectable({ providedIn: 'root' })
export class WidgetFiltersStore extends EntityCollectionServiceBase<WidgetFiltersState> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(WIDGET_FILTERS_ENTITY_NAME, serviceElementsFactory);
  }
}
