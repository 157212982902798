import { Injectable } from '@angular/core';

import { isNil } from 'lodash';

import {
  AssociationFilter,
  BoardDataSource,
  BoardDataSourceRelation,
  ChartType,
  Dashboard,
  Datasource,
  Filter,
  FilterPathRootNode,
  PageWidget,
  SearchQueryResponse,
  createAssociationDataRequest,
  createAssociationFilter,
  filterPathContainsDatasource,
  getFilterPath,
  getFilterPathNodesByDsId,
} from '@selfai-platform/bi-domain';

import { DashboardUtil } from '../../..';
import { CommonConstant } from '../../../../common/constant/common.constant';
import { DatasourceService } from '../../../../datasource/service/datasource.service';

@Injectable()
export class WidgetFilterService {
  constructor(private readonly datasourceService: DatasourceService) {}

  private calculateFiltersForMap(
    dashboard: Dashboard,
    widget: PageWidget,
    globalFilters?: Filter[],
    currentSelectionFilters?: Filter[],
  ): Filter[] {
    let resultFilters: Filter[] = [];
    if (
      widget.configuration.shelf.layers
        .filter((layer) => layer.name !== CommonConstant.MAP_ANALYSIS_LAYER_NAME)
        .some((layer) => {
          return isNil(dashboard.dataSources.find((item) => item.engineName === layer.ref));
        })
    ) {
      throw new Error('GB0000');
    }

    if (isNil(globalFilters)) {
      globalFilters = [];
      widget.configuration.shelf.layers
        .filter((layer) => layer.name !== CommonConstant.MAP_ANALYSIS_LAYER_NAME)
        .forEach((layer) => {
          globalFilters = globalFilters.concat(DashboardUtil.getAllFiltersDsRelations(dashboard, layer.ref));
        });
    }

    {
      const externalFilters = currentSelectionFilters ? globalFilters.concat(currentSelectionFilters) : globalFilters;
      widget.configuration.shelf.layers
        .filter((layer) => layer.name !== CommonConstant.MAP_ANALYSIS_LAYER_NAME)
        .forEach((layer) => {
          resultFilters = DashboardUtil.getAllFiltersDsRelations(dashboard, layer.ref, externalFilters).concat(
            resultFilters,
          );
        });
    }
    return resultFilters;
  }

  public async calculateSearchFilters(
    dashboard: Dashboard,
    widget: PageWidget,
    globalFilters?: Filter[],
    currentSelectionFilters?: Filter[],
  ): Promise<Filter[]> {
    let resultFilters: Filter[] = [];

    if (ChartType.MAP === widget.configuration.chart.type) {
      return this.calculateFiltersForMap(dashboard, widget, globalFilters, currentSelectionFilters);
    }

    const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
      dashboard,
      widget.configuration.dataSource,
    );

    if (isNil(widgetDataSource)) {
      throw new Error('GB0000');
    }

    if (isNil(globalFilters)) {
      globalFilters = DashboardUtil.getAllFiltersDsRelations(dashboard, widgetDataSource.engineName, null, widgetDataSource.name);
    }

    const dashboardDatasource: BoardDataSource = dashboard.configuration.dataSource;

    let externalFilters = currentSelectionFilters ? globalFilters.concat(currentSelectionFilters) : globalFilters;

    if ('multi' === dashboardDatasource.type && dashboardDatasource.associations) {
      const filterPath = getFilterPath(
        widget.configuration.dataSource.engineName || widget.configuration.dataSource.name,
        dashboard,
      );

      if (filterPath.relatedNodes.length > 0 && externalFilters.length > 0) {
        const updatedByAssocitionFilters: Promise<AssociationFilter | Filter>[] = [];
        for (let i = 0; externalFilters.length > i; i++) {
          const filter = externalFilters[i];
          const resultFilter = this.calculateFiltersForMultiDatasource(
            widget,
            dashboard,
            filterPath,
            externalFilters,
            filter,
          );
          updatedByAssocitionFilters.push(resultFilter);
        }

        externalFilters = (await Promise.all(updatedByAssocitionFilters)) as Filter[];
      }
    }

    resultFilters = externalFilters
      .concat(resultFilters)
      .filter((t) => t.dataSource === widgetDataSource.engineName || t.dataSource === widgetDataSource.name);
    return resultFilters;
  }

  private async calculateFiltersForMultiDatasource(
    widget: PageWidget,
    dashboard: Dashboard,
    fitlerPath: FilterPathRootNode,
    sourceFilters: Filter[],
    filter: Filter,
  ) {
    try {
      if (
        !filterPathContainsDatasource(fitlerPath, filter.dataSource) ||
        !filter.valueList ||
        filter.valueList.length < 1
      ) {
        return filter;
      }

      const needValuesForCurrentWidget =
        widget.configuration.dataSource.engineName === filter.dataSource ||
        widget.configuration.dataSource.name === filter.dataSource;

      if (needValuesForCurrentWidget) {
        return filter;
      }

      return await this.createAssociationFilterFromExternalFilter(widget, dashboard, fitlerPath, sourceFilters, filter);
    } catch (err) {
      console.warn('Failed to load associations', err);
      return filter;
    }
  }

  private async createAssociationFilterFromExternalFilter(
    widget: PageWidget,
    dashboard: Dashboard,
    filterPath: FilterPathRootNode,
    sourceFilters: Filter[],
    currentFilter: Filter,
  ): Promise<AssociationFilter> {
    const currentPathNode = getFilterPathNodesByDsId(filterPath, currentFilter.dataSource);

    let filter: Filter | AssociationFilter = currentFilter;
    for (let i = 0; i < currentPathNode.length; i++) {
      const currentNode = currentPathNode[i];
      filter = await this.getFilterFromRelatedSource(
        widget,
        dashboard,
        currentNode.association,
        i > 0 ? [...sourceFilters, filter as Filter] : sourceFilters,
        filter,
      );
    }

    return filter as AssociationFilter;
  }

  private async getFilterFromRelatedSource(
    widget: PageWidget,
    dashboard: Dashboard,
    association: BoardDataSourceRelation,
    sourceFilters: Filter[],
    currentFilter: Filter | AssociationFilter,
  ): Promise<AssociationFilter> {
    const isSource = association.source === currentFilter.dataSource;
    const sourceKey = Object.keys(association.columnPair)[0];
    const targetKey = association.columnPair[sourceKey];

    const fieldNameForSearchValues = isSource ? sourceKey : targetKey;
    const dataSourceNameForSearchValues = isSource ? association.source : association.target;
    const valueList = await this.getValueListForSource(
      widget,
      dashboard,
      dataSourceNameForSearchValues,
      sourceFilters,
      fieldNameForSearchValues,
    );

    const fieldNameForFilter = isSource ? targetKey : sourceKey;
    const dataSourceNameForFilter = isSource ? association.target : association.source;
    const associationFilter = createAssociationFilter(dataSourceNameForFilter, fieldNameForFilter, valueList);
    return associationFilter;
  }

  private async getValueListForSource(
    widget: PageWidget,
    dashboard: Dashboard,
    datasourceName: string,
    sourceFilter: Filter[],
    key: string,
  ): Promise<string[]> {
    const request = createAssociationDataRequest(widget, dashboard, datasourceName, sourceFilter, key);
    const response = (await this.datasourceService.searchQuery(request, false)) as SearchQueryResponse;
    return response.columns.find((column) => column.name === key)?.value || [];
  }
}
