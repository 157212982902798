import { InjectionToken } from '@angular/core';

export const SELFAI_APP_SCRIPT_STORE = new InjectionToken<IScript[]>('selfaiScriptStore', {
  factory: () => {
    return ScriptStore;
  },
});

export interface IScript {
  name: string;
  src: string;
}

export const ScriptNames = {
  PplLegacyVendor: 'pplLegacyVendor',
  PplLegacyUtils: 'pplLegacyUtils',
  PplLegacyApp: 'pplLegacyApp',
};

export function createScriptStoreFromBaseHref(baseHref: string): IScript[] {
  return ScriptStore.map((t) => {
    return {
      ...t,
      src: baseHref + t.src,
    };
  });
}

const ScriptStore: IScript[] = [
  { name: ScriptNames.PplLegacyVendor, src: 'pipelinelegacy/pplvendor.js' },
  { name: ScriptNames.PplLegacyUtils, src: 'pipelinelegacy/pplutils.js' },
  { name: ScriptNames.PplLegacyApp, src: 'pipelinelegacy/pplapp.js' },
];
