import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import $ from 'jquery';

declare const html2canvas: any;
@Injectable()
export class ImageService {
  private _http: HttpClient;

  constructor( protected injector: Injector) {
    this._http = injector.get(HttpClient);
  }

  public getBase64(element: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let $element;
      if (typeof element === 'string') {
        $element = $(element);
      } else if (typeof element === 'object') {
        $element = element;
      }

      if (!$element || $element.length === 0) {
        reject('element not found.');
      }

      html2canvas($element.get(0), { useCORS: true, allowTaint: true, logging: false })
        .then((result) => {
          const dataUrl = result.toDataURL('image/jpeg');
          resolve(dataUrl);
        })
        .catch((err) => reject(err));
    });
  }

  public downloadElementImage(element: any, fileName: string) {
    let $element;
    if (typeof element === 'string') {
      $element = $(element);
    } else if (typeof element === 'object') {
      $element = element;
    }

    if (!$element || $element.length === 0) {
      throw new Error('element not found.');
    }

    setTimeout(() => {
      this.getBase64(element).then((data) => {
        const link = document.createElement('a');

        link.href = data;

        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      });
    }, 500);
  }

  public downloadImageFromUrl(url: string): Promise<Blob> {
    const imgUrl: string = this.buildUrlImage(url);

    const headers = new HttpHeaders({
      Accept: 'image/webp,image/apng,image*;',
      'Content-Type': 'application/octet-binary',
    });
    return this._http.get(imgUrl, { headers: headers, responseType: 'blob' }).toPromise();
  }

  buildUrlImage(url: string): string {
    return '/api/images/load/url?url=' + encodeURIComponent(url);
  }
}
