<div
  *ngIf="menuMode$ | async as menuMode"
  class="layout-wrapper layout-menu-light layout-topbar-light"
  [ngClass]="{
    'layout-static': menuMode === 'vertical',
    'layout-horizontal': menuMode === 'horizontal',
    'layout-static-active': menuIsOpened$ | async
  }"
>
  <selfai-platform-header [logoPath]="logoPath"></selfai-platform-header>
  <selfai-platform-main-menu
    *ngIf="menu$ | async as menu"
    [menu]="menu"
    [menuMode]="menuMode$ | async"
  ></selfai-platform-main-menu>
  <div class="layout-main">
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<selfai-platform-page-loader></selfai-platform-page-loader>
