import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AggregatorRouteApiModel, AggregatorRouteFormGroup } from '@selfai-platform/pipeline-common';
import { AggregatorRouteListService } from './aggregator-camel-list.service';

@Injectable()
export class AggregatorItemService {
  constructor(private readonly aggregatorRouteListService: AggregatorRouteListService) {}

  updateRoute(uuid: string, route: AggregatorRouteApiModel): void {
    this.aggregatorRouteListService.isSaved$.next(false);
    this.aggregatorRouteListService.formGroupRoutes.get(uuid)?.setValue(route);
  }

  updatePartialRoute(uuid: string, newRouteData: Partial<AggregatorRouteApiModel>): void {
    this.aggregatorRouteListService.isSaved$.next(false);
    const routeFormControl = this.aggregatorRouteListService.formGroupRoutes.get(uuid);
    if (routeFormControl) {
      Object.entries(newRouteData).map(([key, value]) => {
        routeFormControl.get(key)?.setValue(value);
      });

      routeFormControl.updateValueAndValidity();
    }
  }

  cloneRoute(uuid: string): void {
    const sourceRoute = this.getRouteById(uuid)?.value as AggregatorRouteApiModel;

    if (sourceRoute) {
      this.aggregatorRouteListService.addRoute({
        ...sourceRoute,
        name: `(Copy) ${sourceRoute.name}`,
      });
    }
  }

  getRouteById(uuid: string): FormGroup<AggregatorRouteFormGroup> | undefined {
    return this.aggregatorRouteListService.formGroupRoutes.get(uuid) as FormGroup<AggregatorRouteFormGroup>;
  }

  setActiveStatusRoute(uuid: string, isActive: boolean): void {
    this.updatePartialRoute(uuid, { isActive });
  }

  toggleActiveStatusRoute(uuid: string) {
    const route = this.getRouteById(uuid)?.value;

    this.setActiveStatusRoute(uuid, !route?.isActive);
  }
}
