<div [formGroup]="filterGroup" *ngIf="filter" class="card flex flex-column md:flex-row gap-3 p-fluid">
  <div>
    <span class="ddp-txt-preview">{{ preview }}</span>
  </div>
  <div>
    <p-dropdown formControlName="tense" [options]="tenses" optionLabel="name" optionValue="value"></p-dropdown>
  </div>
  <div class="flex flex-1 flex-row md:flex-row gap-3 p-fluid">
    <div class="flex-1">
      <p-inputNumber formControlName="value"></p-inputNumber>
    </div>
    <div class="flex-1">
      <p-dropdown formControlName="relTimeUnit" [options]="timeUnits" optionLabel="name" optionValue="value"></p-dropdown>
    </div>
  </div>
</div>
