import { ShelveFieldType, ShelveType } from '@selfai-platform/bi-domain';
import { ChartData } from './chart-data.service';

export function getFieldTypeCount(pivot: ChartData, shelveType: ShelveType, fieldType: ShelveFieldType): number {
  return (
    pivot[shelveType]?.filter((field: any) => {
      if (field.type === ShelveFieldType.DIMENSION && field.format?.unit) {
        field.type = ShelveFieldType.TIMESTAMP;
      }

      return field.type === fieldType;
    }).length || 0
  );
}
