export function quarterToMonth(quarter: string, isStart: boolean): string {
  switch (quarter) {
    case 'Q1':
      return isStart ? '01' : '03';
    case 'Q2':
      return isStart ? '04' : '06';
    case 'Q3':
      return isStart ? '07' : '09';
    case 'Q4':
      return isStart ? '10' : '12';
  }
}
