export enum Condition {
  BEGINS_FROM = 'begins_from',
  NOT_BEGINS_FROM = 'not_begins_from',
  ENDS_WITH = 'ends_with',
  NOT_ENDS_WITH = 'not_ends_with',
  EQUALS_TO = 'equals_to',
  NOT_EQUALS_TO = 'not_equals_to',
  IS_EMPTY = 'is_empty',
  NON_EMPTY = 'non_empty',
  CONTAINS = 'contains',
  REGEXP = 'regexp',
  LESS_THAN = 'less_than',
  MORE_THAN = 'more_than',
  MORE_EQUALS_THAN = 'more_equals_than',
  LESS_EQUALS_THAN = 'less_equals_than',
  BETWEEN = 'between',
  EXPRESSION = 'expression',
}

export const ConditionLegacy = {
  'begins from': 'begins_from',
  'not begins from': 'not_begins_from',
  'ends with': 'ends_with',
  'not ends with': 'not_ends_with',
  'equals to': 'equals_to',
  'not equals to': 'not_equals_to',
  'is empty': 'is_empty',
  'not is empty': 'not_is_empty',
  contains: 'contains',
  regexp: 'regexp',
  'less than': 'less_than',
  'more than': 'more_than',
  'more equals than': 'more_equals_than',
  'less equals than': 'less_equals_than',
  between: 'between',
  expression: 'expression',
};

export type ConditionKeyLegacy = keyof typeof ConditionLegacy;
