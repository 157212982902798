import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  CreateDataColumnType,
  CreateDataFrameColumnMappingFormGroup,
  CreateDataFrameFormGroup,
  CreateDataFrameParameters,
  CreateDataFrameRawParameters,
  CubeWorkflowData,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { orderIndentDown, orderIndentUp } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { DataSourceSelectorComponent } from '../../data-source-selector';
import { DialogHeaderComponentModule } from '../../dialog-header';
import { FormArraySortActionButtonsComponent } from '../../form-array-sort-action-buttons';
import { CreateDataFrameColumnMapingComponent } from './create-data-frame-column-maping';

@Component({
  selector: 'selfai-platform-create-data-frame',
  templateUrl: './create-data-frame.component.html',
  styleUrl: './create-data-frame.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponentModule,
    DataSourceSelectorComponent,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    MessageModule,
    DialogHeaderComponentModule,
    CreateDataFrameColumnMapingComponent,
    FormArraySortActionButtonsComponent,
    ButtonModule,
    DividerModule,
  ],
})
export class CreateDataFrameComponent implements OnInit {
  get node(): GraphNode<CreateDataFrameRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): CreateDataFrameParameters {
    return this.normalizeRawCreateDataFrameParameters(this.node.parameters.serialize());
  }

  form = new FormGroup<CreateDataFrameFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    dataOfFrame: new FormControl(null, [Validators.required]),
    columnMapping: new FormArray<FormGroup<CreateDataFrameColumnMappingFormGroup>>([
      new FormGroup<CreateDataFrameColumnMappingFormGroup>({
        columnName: new FormControl(null, [Validators.required]),
        columnType: new FormControl(null, [Validators.required]),
      }),
    ]),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<CreateDataFrameRawParameters>>,
  ) {}

  ngOnInit(): void {
    this.prepareColumnMappingFormArray();
    this.form.patchValue(this.nodeParameters);
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as CreateDataFrameParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  onRemoveColumnMapping(index: number) {
    this.form.controls.columnMapping.removeAt(index);
  }

  onOrderDownColumnMapping(index: number) {
    orderIndentDown(this.form.controls.columnMapping, index);
  }

  onOrderUpColumnMapping(index: number) {
    orderIndentUp(this.form.controls.columnMapping, index);
  }

  onAddColumnMapping() {
    this.form.controls.columnMapping.push(
      new FormGroup<CreateDataFrameColumnMappingFormGroup>({
        columnName: new FormControl(null, [Validators.required]),
        columnType: new FormControl(null, [Validators.required]),
      }),
    );
  }

  private normalizeFormValuesToApiModel(params: CreateDataFrameParameters): CreateDataFrameRawParameters {
    return {
      'data source': params.dataSourceId,
      'Data of dataframe': params.dataOfFrame,
      'Column mappings of dataframe': params.columnMapping?.map(({ columnName, columnType }) => ({
        'Column name': columnName,
        'Column type': { [columnType]: {} },
      })),
    };
  }

  private normalizeRawCreateDataFrameParameters(
    rawParameters: CreateDataFrameRawParameters,
  ): CreateDataFrameParameters {
    return {
      dataSourceId: rawParameters['data source'],
      dataOfFrame: rawParameters['Data of dataframe'],
      columnMapping: (rawParameters['Column mappings of dataframe'] || []).map((c) => ({
        columnName: c['Column name'],
        columnType:
          c['Column type'] !== null && typeof c['Column type'] === 'object'
            ? (Object.keys(c['Column type'])[0] as CreateDataColumnType)
            : null,
      })),
    };
  }

  private prepareColumnMappingFormArray() {
    if (this.nodeParameters.columnMapping.length > 1) {
      for (let i = 1; i < this.nodeParameters.columnMapping.length; i++) {
        this.form.controls.columnMapping.push(
          new FormGroup<CreateDataFrameColumnMappingFormGroup>({
            columnName: new FormControl(null, [Validators.required]),
            columnType: new FormControl(null, [Validators.required]),
          }),
        );
      }
    }
  }
}
