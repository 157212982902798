export const POSSIBLE_CHART_OBJECT = {
  common: [
    'bar',
    'line',
    'grid',
    'scatter',
    'heatmap',
    'pie',
    'control',
    'label',
    'boxplot',
    'waterfall',
    'combine',
    'treemap',
    'radar',
    'graph',
    'graphv2',
    'sankey',
    'gauge',
  ],

  color: [
    'bar',
    'grid',
    'line',
    'scatter',
    'control',
    'pie',
    'wordcloud',
    'boxplot',
    'radar',
    'heatmap',
    'combine',
    'gauge',
    'graph',
    'graphv2',
    'sankey',
    'treemap',
    'label'
  ],

  format: [
    'bar',
    'line',
    'scatter',
    'heatmap',
    'pie',
    'control',
    'label',
    'boxplot',
    'waterfall',
    'combine',
    'treemap',
    'radar',
    'gauge',
    'graph',
    'graphv2',
    'sankey',
    'grid',
  ],

  split: [],

  legend: [],
  yAxis: ['bar', 'line', 'scatter', 'heatmap', 'gauge', 'control', 'boxplot', 'waterfall', 'combine'],
  xAxis: ['bar', 'line', 'scatter', 'heatmap', 'gauge', 'control', 'boxplot', 'waterfall', 'combine'],
  dataLabel: [
    'bar',
    'line',
    'scatter',
    'heatmap',
    'pie',
    'gauge',
    'control',
    'waterfall',
    'combine',
    'radar',
    'graph',
    'graphv2',
    'sankey',
    'treemap',
  ],
  tooltip: [
    'bar',
    'line',
    'scatter',
    'heatmap',
    'pie',
    'control',
    'boxplot',
    'waterfall',
    'combine',
    'treemap',
    'radar',
    'graph',
    'graphv2',
    'sankey',
    'gauge',
  ],
  calculatedRow: ['grid'],
  secondaryIndicator: ['label'],
  secondaryAxis: ['combine'],
  mapCommon: ['map'],
  mapLayer: ['map'],
  mapLayer1: ['map'],
  mapLayer2: ['map'],
  mapLayer3: ['map'],
  mapLegend: ['map'],
  mapFormat: ['map'],
  mapTooltip: ['map'],
};
